import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentGroup } from 'pkg/identity';

import Row from 'components/layout/row';

import * as DataCard from 'design/data_card';

interface InstallmentsDataProps {
	subscription: models.subscription.Subscription;
}

export default function InstallmentsData({
	subscription,
}: InstallmentsDataProps) {
	const group = useCurrentGroup();

	const installmentCount = subscription.installmentCount;

	const installmentsPaid = subscription.installmentsPaid
		? subscription.installmentsPaid
		: 0;

	const paidAmount = subscription.installmentPaidAmount
		? subscription.installmentPaidAmount / 100
		: 0;

	const paidOrdersAmount = formatToCurrency(paidAmount, group.currency);

	const totalAmount = formatToCurrency(
		subscription.installmentTotalAmount / 100,
		group.currency
	);

	return (
		<Row collapseOnSmallScreens>
			<DataCard.Base
				title={t('Amount paid')}
				heading={paidOrdersAmount}
				headingSuffix={t('of total {totalAmount}', {
					totalAmount,
				})}
				titleIcon="info"
				titleIconTooltip={t(
					'Total is calculated on all paid installments, including any one-off products on the first invoice.'
				)}
			/>

			<DataCard.Base
				title={t('Installments paid')}
				heading={installmentsPaid.toString()}
				headingSuffix={t('of total {installmentCount}', {
					installmentCount,
				})}
			/>
		</Row>
	);
}
