import { Fragment } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import useMixedState from 'pkg/hooks/useMixedState';
import * as actions from 'pkg/actions';

import PayoutRowTable from 'routes/payments/reports/payouts/single/table';

import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';

import Button from 'design/button';

interface SinglePayoutProps {
	id: string;
}

export default function SinglePayout({ id }: SinglePayoutProps) {
	const [sort, setSort] = useMixedState<{
		by: string;
		order: 'desc' | 'asc';
	}>({
		by: 'amount',
		order: 'desc',
	});

	const payoutRowsCollection = useCollection<models.payoutRow.PayoutRow>(
		endpoints.PayoutRow.Index(),
		{
			queryParams: new URLSearchParams({
				payout_id: id.toString(),
				sort_by: sort.by,
				sort_by_order: sort.order,
			}),
		}
	);

	const handleSortClick = (field: string, sortBy: 'asc' | 'desc') => {
		setSort({
			by: field,
			order: sortBy,
		});
	};

	const onCreateExport = async () => {
		const [ok, text] = await actions.payoutRows.exportPayoutRows(id);

		if (ok) {
			const fileName = `${id}-payouts.csv`;

			const url = URL.createObjectURL(new Blob([text]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		}
	};

	return (
		<Fragment>
			<ActionBar.Bar>
				<Button icon="download" label={t('Export')} onClick={onCreateExport} />
			</ActionBar.Bar>
			<PayoutRowTable
				payoutRowsCollection={payoutRowsCollection}
				handleSortClick={handleSortClick}
				sort={sort}
			/>
			<Pagination {...payoutRowsCollection.pagination} />
		</Fragment>
	);
}
