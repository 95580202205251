import { JSX, Fragment } from 'react';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentOrganization } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';

import DataList, {
	DataListItem,
} from 'routes/organization/user-profile/data-list';

import CollapsiblePanel from 'components/sidebar/collapsible-panel';

interface CategoryGroup {
	heading: string;
	order: number;
	fields: models.user.Field[];
}

const userFieldsGroupedByCategory = (
	userFields: models.user.Field[],
	userFieldCategories: models.userFieldCategories.UserFieldCategory[],
	ungroupedLabel: string
): CategoryGroup[] => {
	const grouped: CategoryGroup[] = [];

	const categoryKeys: string[] = [
		...new Set(
			userFieldCategories.map((ufc) => ufc?.userFields.map((f) => f.key)).flat()
		),
	];

	const uncategorized: models.user.Field[] = [];

	userFields?.forEach((f) => {
		if (
			!categoryKeys.includes(f.key) &&
			f.visibility.includes(models.userFields.Visibility.Profile)
		) {
			uncategorized.push(f);
		}
	});

	userFieldCategories.forEach((ufc) => {
		if (ufc.userFields?.length > 0) {
			const fieldKeys = ufc.userFields.map((uf) => uf.key).flat();
			const fields = userFields?.filter(
				(f) =>
					fieldKeys.includes(f.key) &&
					f.visibility.includes(models.userFields.Visibility.Profile)
			);

			grouped.push({
				heading: ufc.title,
				order: ufc.sortOrder ?? 0,
				fields,
			});
		}
	});

	if (uncategorized.length > 0) {
		grouped.push({
			heading: ungroupedLabel,
			order: -1,
			fields: uncategorized,
		});
	}

	return grouped.sort((a, b) => b.order - a.order);
};

interface CustomCategoriesProps {
	user: models.user.User;
}

export default function CustomCategories({
	user,
}: CustomCategoriesProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const { records: userFieldCategories, isLoading } =
		useCollection<models.userFieldCategories.UserFieldCategory>(
			endpoints.UserFieldCategories.Index(org.id)
		);

	if (isLoading) {
		return null;
	}

	const groupedByCategory = userFieldsGroupedByCategory(
		user?.fields,
		userFieldCategories,
		t('Other fields')
	);

	return (
		<Fragment>
			{groupedByCategory.map((category, n) => (
				<CustomCategory
					key={n}
					heading={category.heading}
					userFields={category.fields}
				/>
			))}
		</Fragment>
	);
}

interface CustomCategoryProps {
	heading: string;
	userFields: models.user.Field[];
}

function CustomCategory({
	heading,
	userFields,
}: CustomCategoryProps): JSX.Element {
	const items: DataListItem[] = userFields?.map((field) => {
		return { label: field.label, value: field.values.join(', ') };
	});

	if (!items || items?.length === 0) {
		return null;
	}

	return (
		<CollapsiblePanel defaultCollapsed heading={heading}>
			<DataList items={items} />
		</CollapsiblePanel>
	);
}
