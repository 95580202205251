import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { FilterConfig, useFilters } from 'pkg/filters/use_filters';
import { FilterOperator } from 'pkg/filters';

import Dialog from 'components/Dialog';
import AssetImage from 'components/AssetImage';
import Icon from 'components/icon';
import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';
import RadioButton from 'components/form/RadioButton';

import * as Table from 'design/table';
import Button from 'design/button';

interface SelectContactProps {
	groupId: number;
	selectedContactId?: number;
	canCreateNew?: boolean;
	onConfirm: (userId: number) => void;
	onCreateNew?: () => void;
	onClose: () => void;
}

const SelectContacts: React.FC<React.PropsWithChildren<SelectContactProps>> = ({
	groupId,
	selectedContactId,
	canCreateNew,
	onConfirm,
	onCreateNew,
	onClose,
}) => {
	const filters = useFilters({ groups: {} } as FilterConfig);

	let defaultSelectedRecords: number[] = [];

	if (selectedContactId) {
		defaultSelectedRecords = [selectedContactId];
	}

	const {
		records: users,
		isLoading,
		pagination,
		selection,
	} = useCollection<models.user.User>(
		endpoints.Organizations.ListUsers(groupId),
		{
			queryParams: filters.queryParam,
			defaultSelectedRecords,
		}
	);

	const handleConfirm = (): void => {
		onConfirm(selection.selectedRecords[0] as number);
		onClose();
	};

	const emptyState = {
		title: t('No users found'),
		content: t('No users found'),
		image: <AssetImage src="img/missing-entities/post.svg" />,
	};

	return (
		<Dialog
			heading={t('Select billing contact')}
			confirmButton={
				<Button
					primary
					disabled={!selection.selectedRecords.length}
					icon="add"
					testid="contacts.single.select_billing_contact"
					onClick={handleConfirm}>
					{t('Add selected contact')}
				</Button>
			}
			cancelButton={<Button transparent>{t('Cancel')}</Button>}
			noSpacing
			onConfirm={handleConfirm}
			onClose={onClose}>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search
						placeholder={t('Search')}
						filter={{
							type: 'text',
							operator: FilterOperator.Contains,
							property: 'name',
						}}
						filterSetter={filters.setFilter}
					/>
				</ActionBar.PrimaryAction>
				{canCreateNew && (
					<Button
						onClick={onCreateNew}
						testid="contacts.single.create_new_billing_contact">
						<Icon name="user-add" /> {t('New contact')}
					</Button>
				)}
			</ActionBar.FilterBar>

			<Table.Table
				emptyState={emptyState}
				columns={[
					{ content: '', width: 'max-content' },
					{
						content: t('Name'),
					},
					{
						content: t('Billing email'),
					},
				]}
				isLoading={isLoading}>
				{users.map((user) => (
					<Table.Row
						onClick={() => selection.selectOnlyOne(user.id)}
						key={user.id}>
						<Table.Cell>
							<RadioButton
								checked={selection.isSelected(user.id)}
								value={user.id}
							/>
						</Table.Cell>
						<Table.Cell>{models.user.fullName(user)}</Table.Cell>
						<Table.Cell>{user.email}</Table.Cell>
					</Table.Row>
				))}
			</Table.Table>
			<Pagination {...pagination} />
		</Dialog>
	);
};

export default SelectContacts;
