import {
	JSX,
	Fragment,
	PointerEvent,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCollection } from 'pkg/api/use_collection';
import * as objects from 'pkg/objects';
import * as arrays from 'pkg/arrays';

import Icon from 'components/icon';
import Label from 'components/label';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';
import { Spinner } from 'components/loaders/spinner';

import useTableSet from 'design/table/use_table_set';
import * as Table from 'design/table';

import * as css from './styles.css';

const NoOp = (): any => null;

type SelectCallback = (...groups: models.group.Group[]) => void;

type ChangeCallback = (groups: models.group.Group[]) => void;

interface SelectTableProps {
	groupId: number;
	singleSelect?: boolean;
	defaultSelectedGroupIds?: number[];

	onChange?: ChangeCallback;
	onSelect?: SelectCallback;
	onDeselect?: SelectCallback;

	renderLoadingWith?: ReactNode;
}

interface SelectTableHook {
	Component: ReactNode;

	isLoading: boolean;
	selectedGroups: models.group.Group[];
}

export default function useGroupSelectTable({
	groupId,
	defaultSelectedGroupIds = [],
	singleSelect = false,

	onChange = NoOp,
	onSelect = NoOp,
	onDeselect = NoOp,

	renderLoadingWith,
}: SelectTableProps): SelectTableHook {
	const [selectedGroups, setSelectedGroups] = useState<models.group.Group[]>(
		[]
	);

	const { record: root, isLoading: isLoadingRoot } =
		useEndpoint<models.group.Group>(endpoints.Groups.Show(groupId), {
			queryParams: new URLSearchParams({
				recursive: '1',
				include_sub_group_count: '1',
			}),
		});

	const { records: children, isLoading: isLoadingChildren } =
		useCollection<models.group.Group>(endpoints.Groups.ShowChildren(groupId), {
			queryParams: new URLSearchParams({
				recursive: '1',
				include_sub_group_count: '1',
			}),
		});

	const isLoading: boolean = isLoadingRoot || isLoadingChildren;

	const groupList: models.group.Group[] = [root, ...children];

	const groupTree = models.group.buildGroupTree(
		root,
		children
	) as models.group.Group[];

	useEffect(() => {
		if (!isLoading && groupList.length > 0 && defaultSelectedGroupIds) {
			let groups = defaultSelectedGroupIds
				.map((groupId: number) =>
					groupList.find((group: models.group.Group) => group.id === groupId)
				)
				.filter(
					(group: models.group.Group): group is models.group.Group =>
						group !== undefined
				);

			if (singleSelect && groups.length > 0) {
				groups = [groups[0]];
			}

			setSelectedGroups(groups);
		}
	}, [isLoading]);

	const handleSelect: SelectCallback = (...groups: models.group.Group[]) => {
		let nextGroups: models.group.Group[] = [...selectedGroups, ...groups];

		if (singleSelect) {
			// This will always be *one* group, but via a spread.
			nextGroups = [...groups];
		}

		nextGroups = arrays.filterDuplicatesByKey(nextGroups, 'id');

		setSelectedGroups(nextGroups);
		onSelect(...nextGroups);

		onChange(nextGroups);
	};

	const handleDeselect: SelectCallback = (...groups: models.group.Group[]) => {
		// Single selects cannot be unselected
		if (singleSelect) return;

		setSelectedGroups(groups);
		onDeselect(...groups);

		onChange(groups);
	};

	const tree = useTableSet<models.group.Group>(groupTree, {
		defaultSortKey: 'name',
		pagination: false,
		expandOn: 'children',
		defaultOpenThreshold: 3,
		columns: [
			{
				content: t('Name'),
				columnKey: 'name',
				renderItemWith: (group: models.group.Group) => {
					return (
						<SelectTableRow
							group={group}
							groups={groupList}
							singleSelect={singleSelect}
							selectedGroups={selectedGroups}
							onSelect={handleSelect}
							onDeselect={handleDeselect}
						/>
					);
				},
			},
			{
				content: t('Groups'),
				columnKey: 'children',
				align: 'center',
				width: 'max-content',
				renderItemWith: (group: models.group.Group) => {
					const numChildren = group?.totalChildGroupCount || 0;

					return (
						<Table.Cell>
							{numChildren !== 0 && (
								<span className={css.cell}>{numChildren}</span>
							)}
						</Table.Cell>
					);
				},
			},
		],
	});

	let Component: ReactNode;

	if (isLoadingRoot || isLoadingChildren) {
		Component = renderLoadingWith || <Spinner />;
	}

	Component = (
		<Fragment>
			<header className={css.header}>
				<Label color="gray">
					{t('{num} groups selected', {
						num: selectedGroups.length,
					})}
				</Label>
			</header>
			{tree.Table}
		</Fragment>
	);

	return {
		Component,

		isLoading,
		selectedGroups,
	};
}

interface SelectTableRowProps {
	group: models.group.Group;
	groups: models.group.Group[];
	selectedGroups: models.group.Group[];

	singleSelect: boolean;
	autoSelectChildGroups?: boolean;

	onSelect?: SelectCallback;
	onDeselect?: SelectCallback;
}

function SelectTableRow({
	group,
	groups,
	selectedGroups,

	singleSelect,
	autoSelectChildGroups = true,

	onSelect,
	onDeselect,
}: SelectTableRowProps): JSX.Element {
	const includeGroupIds: number[] = selectedGroups.map(
		(group: models.group.Group) => group.id
	);

	const isSelected = includeGroupIds.includes(group.id);

	if (singleSelect) {
		// Disable autoselect for single selects
		autoSelectChildGroups = false;
	}

	let childGroupIds: number[] = [];

	if (group.children?.length > 0) {
		childGroupIds = objects.recursiveKeysOf(group, {
			key: 'id',
			on: 'children',
		}) as number[];
	}

	const hasSelectedChildren =
		arrays.intersects(includeGroupIds, childGroupIds).length > 0;

	const handleSelect = () => {
		if (includeGroupIds.includes(group.id)) {
			const nextGroups = arrays.filterByKey<models.group.Group>(
				selectedGroups,
				'id',
				group.id
			);

			onDeselect(...nextGroups);
		} else {
			if (autoSelectChildGroups && group.children?.length > 0) {
				const childGroups = childGroupIds.map((groupId: number) =>
					groups.find((group: models.group.Group) => group.id === groupId)
				);

				onSelect(...[group, ...childGroups]);
			} else {
				onSelect(group);
			}
		}
	};

	const handleDeselectGroupChildren = (
		event: PointerEvent<HTMLSpanElement>
	) => {
		event.stopPropagation();

		const groups: models.group.Group[] = childGroupIds
			.map((groupId: number) =>
				selectedGroups.find((group: models.group.Group) => group.id === groupId)
			)
			.filter((n) => n);

		const nextGroups: models.group.Group[] = objects.diff<models.group.Group>(
			selectedGroups,
			groups,
			'id'
		);

		onDeselect(...nextGroups);
	};

	return (
		<Table.Cell>
			<Row
				align="center"
				justify="start"
				justifyContent="start"
				columns="auto 1fr auto"
				className={css.row}
				onClick={handleSelect}>
				<Input.Control
					standalone
					type={singleSelect ? 'radio' : 'checkbox'}
					value={group.id}
					checked={isSelected}
					onChange={handleSelect}
				/>
				<span>{group.name}</span>
				{hasSelectedChildren && !singleSelect && (
					<Fragment>
						<LargeScreen>
							<span
								onClick={handleDeselectGroupChildren}
								className={css.deselect}>
								{t('Deselect child groups')}
							</span>
						</LargeScreen>
						<SmallScreen>
							<span
								onClick={handleDeselectGroupChildren}
								className={css.deselect}>
								<Icon name="list-clear" size={1.5} />
							</span>
						</SmallScreen>
					</Fragment>
				)}
			</Row>
		</Table.Cell>
	);
}
