import { JSX } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { getTypeTranslation } from 'pkg/api/models/event';
import DateTime from 'pkg/datetime';
import { useQueryParams } from 'pkg/hooks/query-state';

import { getItemResources } from 'routes/scheduling/utils';

import MaterialSymbol from 'components/material-symbols';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface BookingProps {
	booking: models.booking.Booking;
	group?: models.group.Group;
	hasConflicts?: boolean;
}

export default function Booking({
	booking,
	group,
	hasConflicts,
}: BookingProps): JSX.Element {
	const view = useQueryParams()?.view ?? 'group';
	const isResourceView = view === 'resource';

	const startsAt = DateTime.fromTimestamp(booking.startsAt);
	const endsAt = DateTime.fromTimestamp(booking.endsAt);

	const sameYearBooking = startsAt.getYear() === endsAt.getYear();
	const sameMonthBooking =
		sameYearBooking && startsAt.getMonth() === endsAt.getMonth();
	const sameDayBooking = startsAt.sameDay(endsAt.getTimestamp());

	const startsAtFormatOptions: Intl.DateTimeFormatOptions = {
		weekday: 'short',
		month: 'short',
		year: 'numeric',
	};

	const endsAtFormatOptions: Intl.DateTimeFormatOptions = {
		weekday: 'short',
		month: 'short',
		year: 'numeric',
	};

	if (sameYearBooking) {
		delete startsAtFormatOptions['year'];
		delete endsAtFormatOptions['year'];
	}

	if (sameMonthBooking) {
		delete startsAtFormatOptions['month'];
		delete endsAtFormatOptions['month'];
	}

	const startsAtTimeString = startsAt.toLocaleTimeString({
		hour: '2-digit',
		minute: '2-digit',
	});

	const startsAtDateString = startsAt.toLocaleDateString(startsAtFormatOptions);

	const endsAtTimeString = endsAt.toLocaleTimeString({
		hour: '2-digit',
		minute: '2-digit',
	});

	const endsAtDateString = endsAt.toLocaleDateString(endsAtFormatOptions);

	let bookingDateSpan: string = `${startsAtTimeString} - ${endsAtTimeString}`;

	if (!sameDayBooking) {
		bookingDateSpan = `${startsAtDateString}, ${startsAtTimeString} → ${endsAtDateString}, ${endsAtTimeString}`;
	}

	const resources = getItemResources(booking);
	const hasResources = booking.resource || resources?.length > 0;
	const hasLocation = !!booking.resource?.location;

	return (
		<div className={css.bookingItem} data-booking-type={booking.type}>
			{hasConflicts && (
				<span className={css.bookingConflict}>{t('Conflicts')}</span>
			)}
			<span className={css.bookingType}>
				{getTypeTranslation(booking.type)}
			</span>
			<time className={css.bookingDates} dateTime={bookingDateSpan}>
				{bookingDateSpan}
			</time>
			<span className={css.bookingTitle}>{booking.title}</span>
			{isResourceView && group && <span>{group.name}</span>}
			{hasResources && (
				<Column spacing={styles.spacing._2} className={css.bookingResources}>
					{hasLocation && (
						<Row columns="auto 1fr" spacing={styles.spacing._1} align="center">
							<MaterialSymbol variant="location_on" scale={0.5} />
							<span className={css.bookingLocation}>
								{booking.resource.location.title}
							</span>
						</Row>
					)}

					{resources?.map((resource: models.resource.Resource) => (
						<div key={`${booking.id}:${resource.id}`}>{resource.title}</div>
					))}
				</Column>
			)}
		</div>
	);
}
