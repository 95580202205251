import { JSX, ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { useCurrentAccount } from 'pkg/identity';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRoute } from 'pkg/router/hooks';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useDebouncedValue } from 'pkg/timings';
import * as objects from 'pkg/objects';

import SectionDescription from 'components/SectionDescription';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';
import { useStepModalContext } from 'components/step-modal';

import Column from 'components/layout/column';
import {
	flashErrorMessage,
	useCreateMembership,
	useJoinModalContext,
} from 'components/group/join-modal';
import * as Input from 'components/form/inputs';
import JoinWidget from 'components/group/join-widget';

import * as css from './styles.css';

export default function GroupCode(): JSX.Element {
	const currentAccount = useCurrentAccount();
	const joinContext = useJoinModalContext();
	const { withNext, withPrev, setCanGoNext, setBusy } = useStepModalContext();
	const route = useCurrentRoute();
	const createMembership = useCreateMembership();

	const groupCode = useDebouncedValue<string>(joinContext.groupCode, 500);

	const isSelf =
		(!joinContext.targetAccount && !joinContext.isCreatingAccount) ||
		currentAccount.id === joinContext.targetAccount?.id;

	const [isOrganization, setIsOrganization] = useState<boolean>(false);

	useComponentDidMount(() => {
		if (route.hasOwnProperty('groupCode')) {
			joinContext.set('groupCode', route.groupCode);
		}
	});

	useEffect(() => {
		setCanGoNext(
			joinContext.groupCode?.length >= 6 &&
				!objects.empty(joinContext.connectionInfo)
		);
	}, [joinContext.groupCode]);

	withNext(async () => {
		let groupName: string;

		if (!joinContext.connectionInfo) {
			const [request, info] = await api.get<models.onboardingInfo.Group>(
				endpoints.Accounts.OnboardingInfo() +
					`?invite_code=${joinContext.groupCode}`
			);

			if (request.ok) {
				joinContext.set('connectionInfo', info);
				groupName = info.groupName;
			} else {
				flashErrorMessage(request.status);

				return Promise.resolve(false);
			}
		}

		if (!isSelf && joinContext.targetAccount) {
			const { statusCode, membership } = await createMembership(
				joinContext.groupCode,
				models.membership.MembershipRole.User,
				joinContext.targetAccount.id
			);

			if (statusCode >= 400 && statusCode !== 409) {
				flashErrorMessage(statusCode);
				return Promise.resolve(false);
			}

			// If the user is already a member of the group, just hide the modal
			if (statusCode === 409) {
				actions.flashes.show(
					{
						title: t('Already a member'),
						message: t('{name} is already a member of {group}.', {
							name: joinContext.targetAccount?.firstName,
							group: groupName,
						}),
					},
					statusCode
				);

				return Promise.resolve(false);
			}

			// @NOTE Joining an org does not return a valid membership
			if (!membership.hasOwnProperty('message')) {
				joinContext.set('membership', membership);
			}
		} else if (isSelf && isOrganization) {
			const { statusCode } = await createMembership(
				joinContext.groupCode,
				null
			);

			if (statusCode >= 400 && statusCode !== 409) {
				flashErrorMessage(statusCode);
				return Promise.resolve(false);
			}

			if (statusCode === 409) {
				actions.flashes.show(
					{
						title: t('Already a member'),
						message: t('You are already a member of {group}.', {
							group: joinContext.connectionInfo.groupName,
						}),
					},
					statusCode
				);

				return Promise.resolve(false);
			}
		}

		return Promise.resolve(true);
	});

	withPrev(async () => {
		joinContext.set('groupCode', null);

		return Promise.resolve(true);
	});

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		joinContext.set('groupCode', event.currentTarget.value.toLocaleUpperCase());
	};

	const handleBeforeLoad = () => {
		setCanGoNext(false);

		if (groupCode?.length >= 6) {
			setBusy(true);
		}
	};

	const handleDidLoad = (groupInfo: models.onboardingInfo.Group) => {
		setIsOrganization(groupInfo.isOrganization);
		setCanGoNext(true);
		setBusy(false);
	};

	const handleError = () => {
		setCanGoNext(false);
		setBusy(false);
	};

	let description: ReactNode | string = t(
		'Enter the group code of the group you want to join, ask your group admin for the group code.'
	);

	if (joinContext.isCreatingAccount) {
		description = t(
			'Enter the group code to the team where your child should join as a player. Ask the group admin for a group code.'
		);
	} else if (!isSelf) {
		description = (
			<T
				_str="Enter the group code to the team where {name} should join as a player. Ask the group admin for a group code."
				name={
					<strong>{models.account.fullName(joinContext.targetAccount)}</strong>
				}
			/>
		);
	}

	return (
		<Column spacing={styles.spacing._6}>
			<Column spacing={styles.spacing._2}>
				<SectionTitle medium centered>
					{t('Enter group code')}
				</SectionTitle>
				<SectionDescription centered maxWidth="440px">
					{description}
				</SectionDescription>
			</Column>

			<Input.Field
				groupCode
				placeholder="ABC123"
				value={joinContext.groupCode}
				onChange={handleChange}
				className={css.group__code}
			/>

			<JoinWidget
				badgeSize="35px"
				groupCode={!joinContext.connectionInfo ? groupCode : null}
				groupInfo={joinContext.connectionInfo}
				onBeforeLoad={handleBeforeLoad}
				onDidLoad={handleDidLoad}
				onError={handleError}
				renderErrorWith={
					<SectionDescription centered maxWidth="440px">
						<Icon name="error" /> {t('Invalid group code')}
					</SectionDescription>
				}
			/>
		</Column>
	);
}
