import styled from 'styled-components';
import { JSX, MouseEvent } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { VideoSource, VideoType } from 'pkg/api/models/video';
import { useCurrentGroup } from 'pkg/identity';
import * as models from 'pkg/api/models';

import AssetImage from 'components/AssetImage';
import FeatureSelect from 'components/feature_select';
import { useStepModalContext } from 'components/step-modal';

import {
	VideoPayload,
	VideoPayloadSetter,
} from 'components/video-library/modals/Video';
import Column from 'components/layout/column';

const SourceImages = styled.div`
	display: grid;
	justify-content: center;
	align-content: center;
	gap: var(--spacing-6);
	grid-auto-flow: column;
`;

const SourceImage = styled(AssetImage)`
	width: 40px;
	height: 40px;
`;

const SourceTitle = styled.div`
	text-align: center;
	color: var(--palette-black);
	font-weight: var(--font-weight-semibold);
`;

const SourceDescription = styled.div`
	margin: 0 auto;
	width: 70%;
	text-align: center;
	color: var(--palette-gray-500);

	@media ${styles.breakpoint.small} {
		width: auto;
	}
`;

interface SourceItem {
	type: string;
	title: string;
	description: string;
	sources: string[];
}

interface SourceProps {
	payload: VideoPayload;
	setPayload: VideoPayloadSetter;
}
export default function Source({
	payload,
	setPayload,
}: SourceProps): JSX.Element {
	const { setCanGoNext } = useStepModalContext();
	const group = useCurrentGroup();

	const sources: SourceItem[] = [];

	if (models.group.hasVideoSource(group, VideoSource.Link)) {
		sources.push({
			type: 'public',
			title: t('Link from a public source', { _context: 'video source' }),
			description: t(
				'Use a link to any YouTube, Vimeo or self-hosted MP4 video.',
				{ _context: 'video source' }
			),
			sources: ['youtube', 'vimeo', 'link'],
		});
	}

	if (models.group.hasVideoSource(group, VideoSource.Veo)) {
		sources.push({
			type: 'veo',
			title: t('Import from Veo', { _context: 'video source' }),
			description: t(
				'Import a publicly available video from your Veo library.',
				{ _context: 'video source' }
			),
			sources: ['veo'],
		});
	}

	if (models.group.hasVideoSource(group, VideoSource.Wingfield)) {
		sources.push({
			type: 'wingfield',
			title: t('Import from Wingfield', { _context: 'video source' }),
			description: t('Import a video from your connected Wingfield library.', {
				_context: 'video source',
			}),
			sources: ['wingfield'],
		});
	}

	const handleSetType = (event: MouseEvent<HTMLDivElement>) => {
		const { type } = event.currentTarget.dataset;

		setPayload({
			source: type,
			type: type === VideoType.Wingfield ? VideoType.Wingfield : null,
		});

		setCanGoNext(true);
	};

	useComponentDidMount(() => {
		setCanGoNext(payload.source ? true : false);
	});

	return (
		<Column spacing={styles.spacing._6}>
			{sources.map(
				({ type, title, description, sources }: SourceItem, index: number) => (
					<FeatureSelect
						key={index}
						active={payload.source === type}
						data-type={type}
						onClick={handleSetType}>
						<Column>
							<SourceImages>
								{sources.map((source: string) => (
									<SourceImage
										key={source}
										src={`img/video-options/${source}.svg`}
									/>
								))}
							</SourceImages>
							<SourceTitle>{title}</SourceTitle>
							<SourceDescription>{description}</SourceDescription>
						</Column>
					</FeatureSelect>
				)
			)}
		</Column>
	);
}
