import { JSX } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { MembershipRole } from 'pkg/api/models/membership';

import FeatureSelect from 'components/feature_select';
import Icon from 'components/icon';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './styles.css';

interface RoleStepProps {
	setRole: (role: number) => void;
	role: number;
}

export const RoleStep = ({ setRole, role }: RoleStepProps): JSX.Element => {
	return (
		<Column spacing={styles.spacing._4}>
			<SectionTitle>{t('Select role')}</SectionTitle>

			<FeatureSelect onClick={() => setRole(1)} active={role === 1}>
				<Row columns="40px 1fr" align="center">
					<div className={css.roleIcon}>
						<Icon name="user" size={2} />
					</div>

					<Column spacing={styles.spacing._1}>
						<strong>
							{models.membership.roleToTranslatedString(MembershipRole.User)}
						</strong>
						<span className={css.roleDescription}>
							{t('A player in the team.')}
						</span>
					</Column>
				</Row>
			</FeatureSelect>

			<FeatureSelect onClick={() => setRole(5)} active={role === 5}>
				<Row columns="40px 1fr" align="center">
					<div className={css.roleIcon}>
						<Icon name="whistle" size={2} />
					</div>

					<Column spacing={styles.spacing._1}>
						<strong>
							{models.membership.roleToTranslatedString(MembershipRole.Staff)}
						</strong>
						<span className={css.roleDescription}>
							{t('Coaches and managers. Cannot handle group settings.')}
						</span>
					</Column>
				</Row>
			</FeatureSelect>

			<FeatureSelect onClick={() => setRole(2)} active={role === 2}>
				<Row columns="40px 1fr" align="center">
					<div className={css.roleIcon}>
						<Icon name="key" size={2} />
					</div>

					<Column spacing={styles.spacing._1}>
						<strong>
							{models.membership.roleToTranslatedString(MembershipRole.Admin)}
						</strong>
						<span className={css.roleDescription}>
							{t('Administrator for the group. Can handle group settings.')}
						</span>
					</Column>
				</Row>
			</FeatureSelect>
		</Column>
	);
};
