import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { useCurrentAccount, useCurrentAccountWards } from 'pkg/identity';
import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as routes from 'pkg/router/routes';
import { replaceState } from 'pkg/router/state';
import { useWardsInCurrentGroup } from 'pkg/hooks/useWardsInCurrentGroup';

import FeatureSelect from 'components/feature_select';
import SectionDescription from 'components/SectionDescription';
import SectionTitle from 'components/SectionTitle';
import { useStepModalContext } from 'components/step-modal';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import Row from 'components/layout/row';
import {
	flashErrorMessage,
	useCreateMembership,
	useJoinModalContext,
} from 'components/group/join-modal';
import JoinWidget from 'components/group/join-widget';
import { CreateWard } from 'components/group/join-modal/create-ward';

import * as css from './styles.css';

interface AccountSelectItemProps {
	account: models.account.Account;
}

function AccountSelectItem({ account }: AccountSelectItemProps): JSX.Element {
	const joinContext = useJoinModalContext();
	const currentAccount = useCurrentAccount();

	const isSelf = account.id === currentAccount.id;

	const isActive =
		!joinContext.isCreatingAccount &&
		joinContext.targetAccount?.id === account.id;

	const select = () => {
		joinContext.setAll({
			isCreatingAccount: false,
			targetAccount: account,
		});
	};

	let title = models.account.fullName(account);
	let subTitle = '';

	if (account.email) {
		subTitle = account.email;
	}

	if (isSelf) {
		subTitle = title;
		title = t('Joining as myself');
	}

	useComponentDidMount(() => {
		joinContext.setAll({ group: null, groupCode: null });
	});

	return (
		<FeatureSelect active={isActive} onClick={select}>
			<Row columns="40px 1fr" align="center">
				<Avatar account={account} />
				<Column spacing={styles.spacing._2}>
					<strong>{title}</strong>
					<span className={css.subtitle}>{subTitle}</span>
				</Column>
			</Row>
		</FeatureSelect>
	);
}

interface AccountSelectProps {
	showCurrentAccount?: boolean;
	allowAccountCreate: boolean;
	filterWardsInCurrentGroup?: boolean;
}

export default function AccountSelect({
	showCurrentAccount = true,
	allowAccountCreate,
	filterWardsInCurrentGroup,
}: AccountSelectProps): JSX.Element {
	const { withNext } = useStepModalContext();
	const currentAccount = useCurrentAccount();
	const joinContext = useJoinModalContext();
	const createMembership = useCreateMembership();
	const modalContext = useStepModalContext();

	const wardIdsInCurrentGroup = useWardsInCurrentGroup()?.map(
		(user: models.user.User) => user.accountId
	);

	let addableWards = useCurrentAccountWards();

	if (filterWardsInCurrentGroup) {
		addableWards = addableWards?.filter(
			(rel: models.accountRelation.AccountRelation) =>
				!wardIdsInCurrentGroup.includes(rel.targetAccountId)
		);
	}

	const handleLoadedGroup = (groupInfo: models.onboardingInfo.Group) => {
		joinContext.set('connectionInfo', groupInfo);

		if (Object.keys(groupInfo).length === 0) {
			replaceState(routes.Organization.Home(groupInfo.organizationId));

			modalContext.close();
		}
	};

	withNext(async () => {
		if (
			joinContext.autoJoinWithGroupCode === false ||
			!joinContext.targetAccount ||
			!joinContext.groupCode
		) {
			return Promise.resolve(true);
		}

		// Only set role if joining a non-organization
		const groupRole = !joinContext.connectionInfo?.isOrganization
			? joinContext.groupRole
			: null;

		const isSelf = joinContext.targetAccount?.id === currentAccount.id;
		const targetAccountId = !isSelf ? joinContext.targetAccount.id : null;

		const { statusCode, membership } = await createMembership(
			joinContext.groupCode,
			groupRole,
			targetAccountId
		);

		if (statusCode >= 400 && statusCode !== 409) {
			flashErrorMessage(statusCode);
			return Promise.resolve(false);
		}

		// If the user is already a member of the group, just hide the modal
		if (statusCode === 409) {
			modalContext.close();
		}

		// @NOTE Joining an org does not return a valid membership
		if (!membership.hasOwnProperty('message')) {
			joinContext.set('membership', membership);
		}

		return Promise.resolve(true);
	});

	return (
		<Column spacing={styles.spacing._5}>
			{joinContext.groupCode && (
				<JoinWidget
					badgeSize="50px"
					groupCode={joinContext.groupCode}
					onDidLoad={handleLoadedGroup}
				/>
			)}

			{addableWards?.length > 0 && (
				<Fragment>
					<SectionTitle large>{t('Who is joining?')}</SectionTitle>
					<Column spacing={styles.spacing._1}>
						<SectionTitle>{t('Your child')}</SectionTitle>
						<SectionDescription>
							{t(
								'You automatically gain access to all groups that your child joins.'
							)}
						</SectionDescription>
					</Column>

					<Column spacing={styles.spacing._4}>
						{addableWards.map((rel: models.accountRelation.AccountRelation) => (
							<AccountSelectItem key={rel.id} account={rel.targetAccount} />
						))}

						{allowAccountCreate && <CreateWard />}
					</Column>
					<SectionTitle>{t('You')}</SectionTitle>
				</Fragment>
			)}

			{showCurrentAccount && <AccountSelectItem account={currentAccount} />}

			{allowAccountCreate && addableWards?.length === 0 && <CreateWard />}
		</Column>
	);
}
