import { Dateable } from 'pkg/api/models/dateable';
import { Author } from 'pkg/api/models/author';
import {
	Attachment,
	AttachmentStatus,
	AttachmentType,
} from 'pkg/api/models/attachment';
import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';
import { Tag } from 'pkg/api/models/tag';

export interface ExerciseDrawing extends Record, Dateable {
	execiseId: number;
	title: string;
	description: string;
	version: string;
	data: string;
}

export interface Exercise extends Linkable, Record, Dateable {
	groupId: number;
	authorId: number;
	userCreated: boolean;
	title: string;
	introduction: string;
	description: string;
	keyPoints: string;
	materialRequirements: string;
	areaWidth: number;
	areaLength: number;
	minAge: number;
	maxAge: number;
	minTime: number;
	maxTime: number;
	minParticipants: number;
	maxParticipants: number;
	userId?: number;
	tags: Tag[];
	meta: { key: string; value: string }[];
	attachments: Attachment[];
	exerciseDrawings: ExerciseDrawing[];
	thumbnailAttachmentId: number;
	thumbnail: Attachment;
	// ratingItems: number[];

	author?: Author;
}

export function isNew(exercise: Exercise): boolean {
	return exercise.createdAt + 3600 * 24 > Math.floor(Date.now() / 1000);
}

export function coverImage(exercise: Exercise): Attachment {
	const defaultAttachment: Attachment = {
		id: 0,
		accountId: 0,
		userId: 0,
		title: '',
		description: '',
		status: AttachmentStatus.Processing,
		type: AttachmentType.Image,
		url: '',
		previewUrl: '',
		size: 0,
	};

	if (exercise.thumbnail) {
		// Return uploaded or automatically selected thumbnail
		return exercise.thumbnail;
	} else if (exercise.attachments?.length > 0) {
		// Finds the first viable attachment that is an image, a video (legacy exercises without specific thumbnails)
		const allowedAttachmentTypes = [AttachmentType.Image, AttachmentType.Video];

		const attachment = exercise.attachments.find((item: Attachment) =>
			allowedAttachmentTypes.includes(item?.type)
		);

		if (attachment) {
			return attachment;
		}
	}

	return defaultAttachment;
}
