import { JSX, ChangeEvent, useState } from 'react';
import { ErrorBoundary } from '@sentry/react';

import spacing from 'pkg/config/spacing';
import * as styles from 'pkg/config/styles';

import { useQueryState } from 'pkg/hooks/query-state';
import { cssClasses } from 'pkg/css/utils';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { localeIncludes } from 'pkg/strings';
import { delay } from 'pkg/timings';
import { useCurrentOrganization } from 'pkg/identity';

import {
	DateFilterHookStory,
	DatePickerStory,
	DateTimePickerStory,
} from 'routes/kitchen_sink/views/date_pickers';
import { LabelsView } from 'routes/kitchen_sink/views/labels';
import ActionBarView from 'routes/kitchen_sink/views/action_bar';
import Buttons from 'routes/kitchen_sink/views/buttons';
import ChartsView from 'routes/kitchen_sink/views/charts';
import ClientIdentity from 'routes/kitchen_sink/views/client_identity';
import CountrySelectView from 'routes/kitchen_sink/views/form/country_select';
import DataCardsView from 'routes/kitchen_sink/views/data_cards';
import DismissableView from 'routes/kitchen_sink/views/dismissable';
import DragAndDrop from 'routes/kitchen_sink/views/dnd';
import FeatureSelectView from 'routes/kitchen_sink/views/feature_select';
import FeatureSpotlightView from 'routes/kitchen_sink/views/feature_spotlight';
import DataManagement from 'routes/kitchen_sink/views/data_management';
import FlashesView from 'routes/kitchen_sink/views/flashes';
import FormColorPickerController from 'routes/kitchen_sink/views/form/color-picker';
import Registration from 'routes/kitchen_sink/views/application/registrations';
import FormProgressBar from 'routes/kitchen_sink/views/form/progress-bar';
import FormRangeSlider from 'routes/kitchen_sink/views/form/range-slider';
import FormSelectedStatus from 'routes/kitchen_sink/views/form/selected-status';
import FormSlider from 'routes/kitchen_sink/views/form/slider';
import FormTable from 'routes/kitchen_sink/views/form/table';
import Icons from 'routes/kitchen_sink/views/icons';
import InfoBoxView from 'routes/kitchen_sink/views/info-box';
import Inputs from 'routes/kitchen_sink/views/form/inputs';
import Markdown from 'routes/kitchen_sink/components/Markdown';
import TableView from 'routes/kitchen_sink/views/table';
import TooltipView from 'routes/kitchen_sink/views/tooltip';
import UseFiltersView from 'routes/kitchen_sink/views/filters';
import Dialogs from 'routes/kitchen_sink/views/dialogs';
import TabBars from 'routes/kitchen_sink/views/tab-bars';
import Printable from 'routes/kitchen_sink/views/printable';
import UserSelectModalView from 'routes/kitchen_sink/views/user-select-modal';
import CardListItem from 'routes/kitchen_sink/views/card_list_item';
import { useNorrsken } from 'routes/kitchen_sink/components/norrsken';
import Emojis from 'routes/kitchen_sink/views/emojis';
import ActionSheet from 'routes/kitchen_sink/views/actionsheet';
import MaterialSymbols from 'routes/kitchen_sink/views/material_symbols';
import LayoutPortalView from 'routes/kitchen_sink/views/layout-portal';
import AvatarStackView from 'routes/kitchen_sink/views/avatar_stack';
import Typography from 'routes/kitchen_sink/views/typography';

import Icon, { IconName } from 'components/icon';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './styles.css';
import README from '../README.md';
import README_RELEASING from '../RELEASING.md';
import README_CONTRIBUTING from '../CONTRIBUTING.md';
import README_DOCUMENTING from '../DOCUMENTING.md';
import README_TRANSLATING from '../TRANSLATING.md';

export enum KitchenSinkViews {
	// Design System
	Buttons = 'buttons',
	Badge = 'badge',
	Icons = 'icons',

	// Application
	DatePicker = 'date-picker',
	DateTimePicker = 'date-time-picker',
	UseDateFilter = 'use-date-filter',
	Flashes = 'flashes',
}

export interface NavigationItem {
	name: string;
	skipSort?: boolean;
	component?: JSX.Element;
	path?: string;
	icon?: IconName;
	iconSize?: number;
	children?: NavigationItem[];
}

export const navigationItems: NavigationItem[] = [
	{
		name: '',
		skipSort: true,
		children: [
			{
				name: 'Getting started',
				icon: 'flash',
				path: '',
				component: <Markdown raw={README} />,
			},
			{
				name: 'Contributing',
				icon: 'add-conversation',
				path: 'contributing',
				component: <Markdown raw={README_CONTRIBUTING} />,
			},
			{
				name: 'Translating',
				icon: 'language',
				iconSize: 1.2,
				path: 'translating',
				component: <Markdown raw={README_TRANSLATING} />,
			},
			{
				name: 'Releasing',
				icon: 'plane',
				iconSize: 1,
				path: 'releasing',
				component: <Markdown raw={README_RELEASING} />,
			},
			{
				name: 'Documenting',
				icon: 'edit',
				iconSize: 1.4,
				path: 'documenting',
				component: <Markdown raw={README_DOCUMENTING} />,
			},
		],
	},
	{
		name: 'Application',
		children: [
			{
				name: 'Data Management',
				path: 'data-management',
				icon: 'database',
				component: <DataManagement />,
			},
			{
				name: 'Client Identity',
				path: 'client-identity',
				icon: 'user',
				component: <ClientIdentity />,
			},
			{
				name: 'Filters',
				path: 'filters',
				component: <UseFiltersView />,
			},
			{
				name: 'Print Styles',
				path: 'print-styles',
				icon: 'print',
				component: <Printable />,
			},
			{
				name: 'Emojis',
				path: 'emojis',
				component: <Emojis />,
			},
			{
				name: 'Registration',
				path: 'registration',
				component: <Registration />,
			},
		],
	},
	{
		name: 'Components',
		children: [
			{
				name: 'Date pickers',
				children: [
					{
						name: 'Date picker',
						path: KitchenSinkViews.DatePicker,
						component: <DatePickerStory />,
					},
					{
						name: 'Date time picker',
						path: KitchenSinkViews.DateTimePicker,
						component: <DateTimePickerStory />,
					},
					{
						name: 'useDateFilter',
						path: KitchenSinkViews.UseDateFilter,
						component: <DateFilterHookStory />,
					},
				],
			},
			{
				name: 'Flashes',
				path: KitchenSinkViews.Flashes,
				component: <FlashesView />,
			},
			{
				name: 'Tooltip',
				path: 'tooltip',
				component: <TooltipView />,
			},
			{
				name: 'Dismissable',
				path: 'dismissable',
				component: <DismissableView />,
			},
			{
				name: 'Drag & Drop',
				path: 'dnd',
				component: <DragAndDrop />,
			},
			{
				name: 'User Specific',
				children: [
					{
						name: 'User Select Modal',
						path: 'user-select-modal',
						component: <UserSelectModalView />,
					},
				],
			},
		],
	},
	{
		name: 'Design System',
		children: [
			{
				name: 'Buttons',
				path: KitchenSinkViews.Buttons,
				component: <Buttons />,
			},
			{
				name: 'Typography',
				path: 'typopgrahy',
				component: <Typography />,
			},
			{
				name: 'Iconography',
				icon: 'at',
				children: [
					{
						name: 'Material Symbols',
						path: 'material-symbols',
						component: <MaterialSymbols />,
					},
					{
						name: 'Icons (Legacy)',
						path: KitchenSinkViews.Icons,
						component: <Icons />,
					},
				],
			},
			{
				name: 'Info box',
				path: 'info-box',
				component: <InfoBoxView />,
			},
			{
				name: 'Form',
				// Views prefix with form gets a white background, this is set in kitchen_sink/index
				children: [
					{
						name: 'Inputs',
						path: 'form-inputs',
						component: <Inputs />,
					},
					{
						name: 'Table',
						path: 'form-table',
						component: <FormTable />,
					},
					{
						name: 'Progress bar',
						path: 'form-progress-bar',
						component: <FormProgressBar />,
					},
					{
						name: 'Slider',
						path: 'form-slider',
						component: <FormSlider />,
					},
					{
						name: 'Range slider',
						path: 'form-range-slider',
						component: <FormRangeSlider />,
					},
					{
						name: 'Color picker',
						path: 'form-color-picker',
						component: <FormColorPickerController />,
					},
					{
						name: 'Selected status',
						path: 'form-selected-status',
						component: <FormSelectedStatus />,
					},
					{
						name: 'Country Select',
						path: 'country-select',
						component: <CountrySelectView />,
					},
				],
			},
			{
				name: 'Table',
				path: 'table',
				component: <TableView />,
			},
			{
				name: 'Labels',
				path: 'labels',
				component: <LabelsView />,
			},
			{
				name: 'Action bars',
				path: 'action-bar',
				component: <ActionBarView />,
			},
			{
				name: 'Feature Select',
				path: 'feature-select',
				component: <FeatureSelectView />,
			},
			{
				name: 'Feature Spotlight',
				path: 'feature-spotlight',
				component: <FeatureSpotlightView />,
			},
			{
				name: 'Data Cards',
				path: 'data-cards',
				component: <DataCardsView />,
			},
			{
				name: 'Charts',
				path: 'data-card-charts',
				component: <ChartsView />,
			},
			{
				name: 'Dialogs',
				path: 'dialogs',
				component: <Dialogs />,
			},
			{
				name: 'Tab Bars',
				path: 'tab-bars',
				component: <TabBars />,
			},
			{
				name: 'Card List',
				path: 'card-list',
				component: <CardListItem />,
			},
			{
				name: 'ActionSheet',
				path: 'actionsheet',
				component: <ActionSheet />,
			},
			{
				name: 'LayoutPortal',
				path: 'layout-portal',
				component: <LayoutPortalView />,
			},
			{
				name: 'AvatarStack',
				path: 'avatar-stack',
				component: <AvatarStackView />,
			},
		],
	},
];

interface NavigationBranchProps {
	item: NavigationItem;
	root?: boolean;
	search?: string;
}

function NavigationBranch({
	item,
	root,
	search,
}: NavigationBranchProps): JSX.Element {
	const qs = useQueryState();

	const [open, setOpen] = useState<boolean>(false);

	const hasChildren = item?.children?.length > 0;
	const isActive =
		qs.is('view', item.path) ||
		(!qs.has('view') && item.name === 'Getting started');

	const handleBranchClick = () => {
		if (hasChildren) {
			setOpen((prev) => !prev);
		}

		if (item.path === '') {
			qs.remove('view');
			qs.commit();
		}

		if (item.path) {
			qs.set('view', item.path);
			qs.commit();
		}
	};

	if (root) {
		const items = item.skipSort
			? item.children
			: item.children.sort((a, b) => a.name.localeCompare(b.name));

		return (
			<Column spacing={spacing._3} className={css.root}>
				<div className={css.label}>{item.name}</div>
				<Column spacing={spacing._1} className={css.root__node}>
					{items.map((item, i) => (
						<NavigationBranch key={i} item={item} search={search} />
					))}
				</Column>
			</Column>
		);
	}

	let itemIcon: IconName = hasChildren ? 'collapse-reverse' : 'collection';

	if (item.icon) {
		itemIcon = item.icon;
	}

	if (
		item.name.length > 0 &&
		search?.length > 0 &&
		!localeIncludes(item.name, search)
	) {
		return null;
	}

	return (
		<div
			className={cssClasses(
				css.branch,
				open ? css.open : '',
				isActive ? css.active : ''
			)}>
			<Row
				align="center"
				columns="24px 1fr auto"
				spacing={spacing._1}
				className={cssClasses(css['branch-label'], isActive ? css.active : '')}
				onClick={handleBranchClick}>
				<Icon name={itemIcon} size={item.iconSize || 1.5} />
				{item.name}
				{hasChildren ? <Icon name={open ? 'remove' : 'add'} /> : null}
			</Row>
			{open && (
				<Column spacing={spacing._1} className={css.twig}>
					{item.children
						?.sort((a, b) => a.name.localeCompare(b.name))
						.map((c, i) => (
							<NavigationBranch key={i} item={c} search={search} />
						))}
				</Column>
			)}
		</div>
	);
}

export default function Navigation(): JSX.Element {
	const [search, setSearch] = useState<string>('');
	const org = useCurrentOrganization();

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const handleBlur = async () => {
		await delay(100);

		setSearch('');
	};

	return (
		<nav className={css.navigation}>
			<header className={css.navigation__header}>
				<Input.Field
					small
					autoComplete="off"
					name="keywords"
					value={search}
					placeholder="Search kitchen sink..."
					onChange={handleChange}
					onBlur={handleBlur}>
					<Input.Prefix inline>
						<Icon name="search" />
					</Input.Prefix>
				</Input.Field>
			</header>
			<main className={css.items}>
				<Column spacing={spacing._7}>
					{navigationItems.map((item, i) => (
						<NavigationBranch key={i} item={item} root search={search} />
					))}
				</Column>
			</main>
			<footer className={css.navigation__footer}>
				<Column spacing={styles.spacing._6}>
					<Link href={routes.Organization.Home(org.id)} className={css.link}>
						<Row align="center" columns="auto 1fr">
							<Icon name="nav-home" size={1.5} />
							Back to 360Player
						</Row>
					</Link>
				</Column>
			</footer>
		</nav>
	);
}

export function CurrentView(): JSX.Element {
	const qs = new URLSearchParams(window.location.search);
	const view = qs.get('view') || '';

	let flattenedNavigation: NavigationItem[] = [];

	const flattenChildren = (item: NavigationItem) => {
		if (item?.children) {
			item.children.forEach((i) => flattenChildren(i));
		}

		flattenedNavigation = [...flattenedNavigation, item];
	};

	navigationItems.forEach((i) => flattenChildren(i));

	const content = flattenedNavigation.find((i) => i.path === view)
		?.component || <Markdown raw={README} />;

	useNorrsken({
		watch: [view],
	});

	return (
		<ErrorBoundary
			fallback={
				<div className={css.error}>
					It's not me, it's you. Check the console.
				</div>
			}>
			<div className={css.main}>{content}</div>
		</ErrorBoundary>
	);
}
