import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { getTypeTranslation } from 'pkg/api/models/event';
import { useQueryParams } from 'pkg/hooks/query-state';

import { getItemResources } from 'routes/scheduling/utils';

import MaterialSymbol from 'components/material-symbols';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface ScheduleItemProps {
	scheduleItem: models.scheduleItem.ScheduleItem;
	group?: models.group.Group;
	hasConflicts?: boolean;
}

export default function ScheduleItem({
	scheduleItem,
	group,
	hasConflicts,
}: ScheduleItemProps): JSX.Element {
	const view = useQueryParams()?.view ?? 'group';
	const isResourceView = view === 'resource';

	const resources = getItemResources(scheduleItem);
	const hasResources = scheduleItem.resource || resources?.length > 0;
	const hasLocation = !!scheduleItem.resource?.location;

	return (
		<Fragment>
			{hasConflicts && (
				<span className={css.scheduleConflict}>{t('Conflicts')}</span>
			)}
			<div className={css.scheduleItem} data-schedule-type={scheduleItem.type}>
				<span className={css.scheduleType}>
					{getTypeTranslation(scheduleItem.type)}
				</span>
				<time className={css.scheduleDates}>
					{scheduleItem.startsAt} - {scheduleItem.endsAt}
				</time>
				<span className={css.scheduleTitle}>{scheduleItem.title}</span>
				{isResourceView && group && <span>{group.name}</span>}
				{hasResources && (
					<Column spacing={styles.spacing._2} className={css.scheduleResources}>
						{hasLocation && (
							<Row
								columns="auto 1fr"
								spacing={styles.spacing._1}
								align="center">
								<MaterialSymbol variant="location_on" scale={0.5} />
								<span className={css.scheduleLocation}>
									{scheduleItem.resource.location.title}
								</span>
							</Row>
						)}

						{resources?.map((resource: models.resource.Resource) => (
							<div key={resource.id}>{resource.title}</div>
						))}
					</Column>
				)}
			</div>
		</Fragment>
	);
}
