// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ize0_p4cImgLJUleDMgU {
	padding: var(--spacing-5);
	background-color: var(--palette-white);
	border-bottom: 1px solid var(--palette-gray-300);
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-line-pack: start;
	    align-content: start;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: start;
	place-content: start;
}

@media (max-width: 768px) {

.ize0_p4cImgLJUleDMgU {
		padding-top: 0;
		background-color: transparent;
		-ms-flex-line-pack: end;
		    align-content: end;
		-webkit-box-pack: end;
		    -ms-flex-pack: end;
		        justify-content: end;
		place-content: end;
}
	}

.fr5ti4sIhUDr5u5w1uZE {
	display: grid;
	grid-template-columns: 30px 1fr 20px;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/use-membership-select/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,sCAAsC;CACtC,gDAAgD;CAChD,kBAAkB;CAClB,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAoB;KAApB,oBAAoB;CAApB,uBAAoB;KAApB,oBAAoB;SAApB,sBAAoB;CAApB,oBAAoB;AAOrB;;AALC;;AARD;EASE,cAAc;EACd,6BAA6B;EAC7B,uBAAkB;MAAlB,kBAAkB;EAAlB,qBAAkB;MAAlB,kBAAkB;UAAlB,oBAAkB;EAAlB,kBAAkB;AAEpB;CADC;;AAGD;CACC,aAAa;CACb,oCAAoC;CACpC,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB","sourcesContent":[".groupSelect {\n\tpadding: var(--spacing-5);\n\tbackground-color: var(--palette-white);\n\tborder-bottom: 1px solid var(--palette-gray-300);\n\tposition: relative;\n\tdisplay: flex;\n\tplace-content: start;\n\n\t@media (--small-viewport) {\n\t\tpadding-top: 0;\n\t\tbackground-color: transparent;\n\t\tplace-content: end;\n\t}\n}\n\n.groupSelectItem {\n\tdisplay: grid;\n\tgrid-template-columns: 30px 1fr 20px;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var groupSelect = `ize0_p4cImgLJUleDMgU`;
export var groupSelectItem = `fr5ti4sIhUDr5u5w1uZE`;
export default ___CSS_LOADER_EXPORT___;
