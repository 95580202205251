import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { Account, Group } from 'pkg/api/models/onboarding_info';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import * as publicStyles from 'routes/public/styles/styles.css';

import Column from 'components/layout/column';

export default function ConfirmUserAndGroup(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const account = onboardingState.get<Account>('account');
	const group = onboardingState.get<Group>('group');

	let title = t('Are you {name} a member of {group}?', {
		name: `${account.firstName} ${account.lastName}`,
		group: `${group.organizationName} ${group.groupName}`,
	});

	if (account.role === 'player') {
		title = t('Are you, {name} a player in {group}?', {
			name: `${account.firstName} ${account.lastName}`,
			group: `${group.organizationName} ${group.groupName}`,
		});
	} else if (account.role === 'parent') {
		let groupName = `${group.organizationName} ${group.groupName}`;

		if (group.organizationName === group.groupName) {
			groupName = group.organizationName;
		}

		title = t('Are you, {name} a parent to a player in {group}?', {
			name: `${account.firstName} ${account.lastName}`,
			group: groupName,
		});
	} else if (account.role === 'staff') {
		title = t('Are you, {name} a coach or staff member in {group}?', {
			name: `${account.firstName} ${account.lastName}`,
			group: `${group.organizationName} ${group.groupName}`,
		});
	}

	const handleContinueClick = () => {
		if (account?.ssoToken) {
			goTo('account-details');

			return;
		}

		goTo('create-account');
	};

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title
					title={title}
					testid="onboarding.signup.confirm_user_and_group_title"
				/>

				<Column>
					<Button
						primary
						label={t('Yes, continue!')}
						onClick={handleContinueClick}
						testid="onboarding.signup.continue_button"
					/>
					<Button
						transparent
						label={t('No, go back')}
						onClick={() => goTo('user-information')}
					/>
				</Column>
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton />
			</div>
		</Fragment>
	);
}
