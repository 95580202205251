import { Dispatch } from 'redux';
import { t } from '@transifex/native';

import { Users } from 'pkg/actions/action-types';
import { triggerError } from 'pkg/actions/app';
import * as logService from 'pkg/actions/services/user_log.services';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as selectors from 'pkg/selectors';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';
import { RootState } from 'pkg/reducers';
import { withSdk } from 'pkg/core/with_sdk';
import { flashes } from 'pkg/actions';

import { Event, trackEvent } from 'components/analytics';

interface Entities {
	[key: number]: any;
}

const setUserLogs = (userId: number, logs: unknown) => ({
	type: 'SET_USER_LOGS',
	userId,
	logs,
});

const premendUserLog = (userId: number, logs: unknown) => ({
	type: 'PREPEND_USER_LOG',
	userId,
	logs,
});

/** @deprecated Will be removed when Aggregated User Profile is released  */
export const removeUserLog = (userId: number, logId: number) => {
	return async (dispatch: Dispatch) => {
		const req = await logService.deleteUserLog(userId, logId);

		if (!req.ok) {
			return;
		}

		const res = await req.json();

		dispatch({
			type: Users.USER_LOG_REMOVE_SUCCESS,
			payload: res,
			userId,
			logId,
		});
	};
};

/** @deprecated Will be removed when Aggregated User Profile is released  */
export const fetchLogs =
	(userId: number, groupId: number, fetchNext = true) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		if (!groupId) {
			return;
		}

		const userLogs = getState()?.userLogs.entities as Entities;

		let url = `/users/${userId}/logs?group_id=${groupId}`;
		const currentUserLogs = userLogs?.[userId];

		if (currentUserLogs?.links?.next && fetchNext) {
			url = currentUserLogs.links?.next;
		}

		const response = await sdk.get(url);

		if (!response.ok) {
			return triggerError(response)(dispatch);
		}

		const logs = await response.json();
		dispatch(setUserLogs(userId, logs));
	};

/** @deprecated Will be removed when Aggregated User Profile is released  */
export const postUserLog =
	(userId: number, data: any) =>
	async (dispatch: Dispatch, getState: () => RootState) => {
		const response = await sdk.post('users/:userId/logs', { userId }, data);

		if (!response.ok) {
			return new Promise((res, rej) => rej(response));
		}

		const userLog = await response.json();

		userLog.author = selectors.users.find(getState(), data.authorId);

		dispatch(premendUserLog(userId, userLog));
	};

/** @note New action handling logic below, {@see withSdk} */

interface CreateUserLogPayload {
	userId: number;
	groupId: number;
	content: string;
}

export async function create(
	user: models.user.User,
	group: models.group.Group,
	payload: CreateUserLogPayload
): Promise<models.userLog.UserLog> {
	return withSdk<models.userLog.UserLog, CreateUserLogPayload>(
		'post',
		endpoints.UserLogs.Create(user.id),
		{
			onSuccess() {
				trackEvent(Event.Create, {
					endpoint: endpoints.UserLogs.Create(user.id),
				});

				flashes.show({
					title: t('Created user note'),
					message: t(`Added a user note on {user} in {group}`, {
						user: models.user.fullName(user),
						group: group.name,
					}),
				});
			},
		}
	)(payload);
}

interface UpdateUserLogPayload {
	content: string;
}

export async function update(
	userLog: models.userLog.UserLog,
	payload: UpdateUserLogPayload
): Promise<models.userLog.UserLog> {
	return withSdk<models.userLog.UserLog, UpdateUserLogPayload>(
		'patch',
		endpoints.UserLogs.Update(userLog.userId, userLog.id),
		{
			onSuccess() {
				flashes.show({
					title: t('Updated user note'),
					message: t(`Successfully updated user note.`),
				});
			},
		}
	)(payload);
}

type RemoveUserLogCallback = (userLog: models.userLog.UserLog) => void;

export async function remove(
	userLog: models.userLog.UserLog,
	onRemove: RemoveUserLogCallback
): Promise<void> {
	withSdk('delete', endpoints.UserLogs.Delete(userLog.userId, userLog.id), {
		onSuccess: onRemove,
	})();
}

export async function removeAttachment(
	userLog: models.userLog.UserLog,
	attachmentId: number
): Promise<void> {
	withSdk(
		'delete',
		endpoints.UserLogs.RemoveAttachment(
			userLog.userId,
			userLog.id,
			attachmentId
		)
	)();
}

export async function addAttachments(
	userLog: models.userLog.UserLog,
	attachments: models.attachment.Attachment[]
): Promise<void> {
	await Promise.allSettled(
		attachments.map(async (attachment) => {
			return await sdk.post(
				endpoints.UserLogs.AddAttachment(
					userLog.userId,
					userLog.id,
					attachment.id
				)
			);
		})
	);
}
