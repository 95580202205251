import { JSX } from 'react';
import { t } from '@transifex/native';
import styled from 'styled-components';

import { eventTypeLabels } from 'pkg/models/event';

import * as models from 'pkg/api/models';
import { QueryObject, useQueryState } from 'pkg/hooks/query-state';
import { useCurrentOrganization } from 'pkg/identity';

import Avatar from 'components/avatar';

import * as ActionBar from 'components/layout/ActionBar';
import * as Input from 'components/form/inputs';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';
import { useDialog } from 'design/dialog';

const UserInfo = styled.div`
	display: flex;
	align-items: center;

	${Avatar} {
		margin-right: var(--spacing-3);
	}
`;

interface IFilters {
	[key: string]: any;
	startOfRange?: string;
	endOfRange?: string;
}

export function getFilters(query: QueryObject): IFilters {
	const defaultFilters: IFilters = { eventTypes: [] };
	const filters: IFilters = {};

	Object.entries(query).forEach(([key, value]) => {
		if (value === '') return;

		if (key === 'eventTypes' && typeof value === 'string') {
			filters[key] = value.split(',');
		} else if (key === 'before' || key === 'after') {
			filters[key] = Number(value);
		} else {
			filters[key] = value;
		}
	});

	return { ...defaultFilters, ...filters };
}

interface FiltersProps {
	dateFilter: JSX.Element;
	searchBy?: string;
	handleSearch?: (searchFilter: string) => void;
	user?: models.user.User;
	eventReport?: boolean;
	handleRefreshLok?: () => void;
	validTimeRange?: boolean;
}

export default function Filters({
	dateFilter,
	searchBy,
	handleSearch,
	user,
	eventReport,
	handleRefreshLok,
	validTimeRange,
}: FiltersProps): JSX.Element {
	const qs = useQueryState();
	const eventTypes = qs.getArray('eventTypes', []);

	const org = useCurrentOrganization();

	const confirmDialog = useDialog({
		message: t('Are you sure you want to re-send selected events?'),
		title: t('Resend selected events'),
		confirmLabel: t('Confirm'),
		cancelLabel: t('Cancel'),
		onConfirm: handleRefreshLok,
	});

	const handleEventTypeClick = (eventType: string) => {
		const newEventTypes = eventTypes;

		if (newEventTypes.includes(eventType)) {
			newEventTypes.splice(newEventTypes.indexOf(eventType), 1);
		} else {
			newEventTypes.push(eventType);
		}

		qs.set('eventTypes', newEventTypes);
		qs.commit();
	};

	const eventTypesFilter = (
		<ContextMenu.Menu toggleWith={<Button icon="tune">{t('Filter')}</Button>}>
			<ContextMenu.Label>{t('Filter')}</ContextMenu.Label>

			{eventTypeLabels.map((eventType) => (
				<ContextMenu.Item
					key={eventType}
					closeOnClick={false}
					onClick={() => handleEventTypeClick(eventType)}>
					<Input.Control
						type="checkbox"
						label={models.event.getTypeTranslation(eventType)}
						checked={eventTypes.includes(eventType)}
						name={eventType}
						value={eventType}
					/>
				</ContextMenu.Item>
			))}
		</ContextMenu.Menu>
	);

	return (
		<ActionBar.FilterBar>
			<ActionBar.PrimaryAction>
				{user?.id ? (
					<UserInfo>
						<Avatar user={user} size={35} />
						<span>{models.user.fullName(user)}</span>
					</UserInfo>
				) : (
					<ActionBar.Search
						value={searchBy}
						onChange={handleSearch}
						placeholder={t('Search content')}
					/>
				)}
			</ActionBar.PrimaryAction>
			{eventReport && models.group.isLOKActive(org) && (
				<Button primary onClick={confirmDialog} disabled={!validTimeRange}>
					{t('Resend displayed events to LOK')}
				</Button>
			)}
			{dateFilter}
			{eventTypesFilter}
		</ActionBar.FilterBar>
	);
}
