import { JSX, useRef, useState } from 'react';
import { t } from '@transifex/native';

import { Account } from 'pkg/api/models/account';

import LoginCreatedStep from 'routes/account/settings/child-relations/active-relations/create-child-login-modal/LoginCreatedStep';
import CreateChildLoginStep from 'routes/account/settings/child-relations/active-relations/create-child-login-modal/CreateLoginStep';

import StepModal, { Step } from 'components/step-modal';

interface CreateChildLoginModalProps {
	account: Account;
	onClose: () => void;
}

export default function CreateChildLoginModal({
	account,
	onClose,
}: CreateChildLoginModalProps): JSX.Element {
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const formRef = useRef<HTMLFormElement>();

	const handleFormSubmit = async () => {
		await formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

		return false;
	};

	const stepTitle = t('Create login for your child');

	return (
		<StepModal onClose={onClose}>
			<Step
				title={stepTitle}
				nextLabel={t('Create login')}
				onNext={handleFormSubmit}
				canGoNext={!isLoading}
				spaciousBody>
				<CreateChildLoginStep
					account={account}
					formRef={formRef}
					setEmail={setEmail}
					setIsLoading={setIsLoading}
				/>
			</Step>
			<Step
				title={stepTitle}
				nextLabel={t('Done')}
				hidePrev
				slug="account-created">
				<LoginCreatedStep email={email} />
			</Step>
		</StepModal>
	);
}
