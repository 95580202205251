export function BundlePreview(guid: string): string {
	return `/v1/forms/${guid}/bundles`;
}

export function Create(): string {
	return `/v1/forms`;
}

export function Delete(ID: number): string {
	return `/v1/forms/${ID}`;
}

export function Index(): string {
	return `/v1/forms`;
}

export function Orders(ID: number): string {
	return `/v1/forms/${ID}/orders`;
}

export function PublicShow(guid: string): string {
	return `/v1/forms/public/${guid}`;
}

export function Reactivate(ID: number): string {
	return `/v1/forms/${ID}/reactivate`;
}

export function Show(ID: number): string {
	return `/v1/forms/${ID}`;
}

export function Summary(ID: number): string {
	return `/v1/forms/${ID}/summary`;
}

export function UnauthenticatedPublicShow(guid: string): string {
	return `/v1/forms/unauthed/${guid}`;
}

export function Update(ID: number): string {
	return `/v1/forms/${ID}`;
}

export function UserData(guid: string, ID: number): string {
	return `/v1/forms/${guid}/user-submission-data/${ID}`;
}

export function UserSubmissions(guid: string): string {
	return `/v1/forms/${guid}/user-submissions`;
}
