import { t } from '@transifex/native';
import { JSX, ChangeEvent, Fragment, useState } from 'react';

import { spacing } from 'pkg/config/styles';
import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { findCountryByCode } from 'pkg/countries';
import Link from 'pkg/router/Link';

import EditableRow from 'routes/group/settings/editable-row';

import { useSmallScreen } from 'components/MediaQuery';
import SectionTitle from 'components/SectionTitle';
import CountrySelect from 'components/CountrySelect';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import { SettingRowDivider } from 'components/settings/Settings';
import * as Input from 'components/form/inputs';

import * as Card from 'design/card';

function getTranslatedLabel(
	label: models.groupContactInformation.ContactFieldName
) {
	switch (label) {
		case 'clubLegalName':
			return t('Legal Name');
		case 'addressCountry':
			return t('Country');
		case 'addressRegion':
			return t('Region');
		case 'addressPostalCode':
			return t('Postal Code');
		case 'addressStreetAddress':
			return t('Street address');
		case 'contactEmail':
			return t('Email');
		case 'contactPhone':
			return t('Phone number');
		case 'taxID':
			return t('Tax ID');
		case 'website':
			return t('Website');
	}
}

interface ContactInformationFieldProps {
	groupId: number;
	name: models.groupContactInformation.ContactFieldName;
	type: models.groupContactInformation.ContactFieldType;
	item: models.groupContactInformation.ContactItem;
	items: models.groupContactInformation.ContactItem[];
}

function ContactInformationField({
	groupId,
	name,
	type,
	item,
	items,
}: ContactInformationFieldProps): JSX.Element {
	const [value, setValue] = useState<string>(item?.fieldValue);
	const [isEditing, setEditing] = useState<boolean>(false);
	const [isSaving, setSaving] = useState<boolean>(false);

	const handleEdit = () => setEditing(true);

	const handleSubmit = async () => {
		setSaving(true);
		const payload = [...items].map(
			(n: models.groupContactInformation.ContactItem) => {
				if (n.fieldName === name) {
					n.fieldValue = value;
				}

				return n;
			}
		);

		await actions.groupContactInformation.update(groupId, payload);

		setEditing(false);
		setSaving(false);
	};

	const handleCancel = () => setEditing(false);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
	};

	const handleCountryChange = (event: ChangeEvent<HTMLOptionElement>) => {
		setValue(event.target.value);
	};

	const inputType = type === 'phone' ? 'tel' : type;

	let staticComponent = <span>{value}</span>;

	let editableComponent = (
		<Input.Field
			name={name}
			type={inputType}
			value={value}
			onChange={handleInputChange}
		/>
	);

	if (name === 'addressCountry') {
		staticComponent = (
			<span>{value ? findCountryByCode(value)?.country : null}</span>
		);

		editableComponent = (
			<CountrySelect name={name} value={value} onChange={handleCountryChange} />
		);
	}

	if (name === 'contactPhone' && value?.length > 0) {
		staticComponent = <Link href={`tel:${value}`}>{value}</Link>;
	}

	if (name === 'contactEmail' && value?.length > 0) {
		staticComponent = <Link href={`mailto:${value}`}>{value}</Link>;
	}

	if (name === 'website' && value?.startsWith('http')) {
		staticComponent = <Link href={value}>{value}</Link>;
	}

	return (
		<EditableRow
			label={getTranslatedLabel(name)}
			isEditing={isEditing}
			isSaving={isSaving}
			staticComponent={staticComponent}
			editableComponent={editableComponent}
			onEdit={handleEdit}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
		/>
	);
}

type InfoPair = [
	models.groupContactInformation.ContactFieldName,
	models.groupContactInformation.ContactFieldType,
];

interface ContactSettingsProps {
	groupId: number;
}

export default function ContactSettings({
	groupId,
}: ContactSettingsProps): JSX.Element {
	const { isLoading, record: contactInfoItems } =
		useEndpoint<models.groupContactInformation.GroupContactInformation>(
			endpoints.GroupInfo.ShowByGroupID(groupId),
			{
				defaultResponse: [],
			}
		);

	const fields = Object.entries(
		models.groupContactInformation.GroupContactFields
	);

	const normalizedContactInformation: models.groupContactInformation.GroupContactInformation =
		fields.map(([name, type]: InfoPair) => {
			let fieldValue: string = '';

			const existing = contactInfoItems?.find(
				(item: models.groupContactInformation.ContactItem) =>
					item.fieldName === name
			);

			if (existing) {
				fieldValue = existing.fieldValue;
			}

			return {
				fieldName: name,
				fieldType: type,
				fieldValue,
			} as models.groupContactInformation.ContactItem;
		});

	const findContactItem = (
		name: models.groupContactInformation.ContactFieldName
	): models.groupContactInformation.ContactItem | null => {
		return normalizedContactInformation.find(
			(item: models.groupContactInformation.ContactItem) =>
				item.fieldName === name
		);
	};

	const isSmallScreen = useSmallScreen();

	if (isLoading) return null;

	return (
		<Column>
			<SectionTitle>{t('Club contact Information')}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					<Column
						spacing={isSmallScreen ? styles.spacing._5 : styles.spacing._1}>
						<InfoBox color="blue">
							<div>
								{t(
									'Club information is displayed in the Club Lobby, billing related pages, emails and invoices, as well as during checkout and registration.'
								)}
							</div>
						</InfoBox>
						<Column spacing={spacing._1}>
							{fields.map(([name, type]: InfoPair, index: number) => (
								<Fragment key={name}>
									<ContactInformationField
										groupId={groupId}
										name={name}
										type={type}
										item={findContactItem(name)}
										items={normalizedContactInformation}
									/>
									{index !== fields.length - 1 && <SettingRowDivider />}
								</Fragment>
							))}
						</Column>
					</Column>
				</Card.Body>
			</Card.Base>
		</Column>
	);
}
