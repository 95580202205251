import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as Routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import SectionTitle from 'components/SectionTitle';
import Label from 'components/label';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';
import { useDialog } from 'design/dialog';

interface HeadingProps {
	eventSeries: models.eventSeries.EventSeries;
}

export default function Heading({ eventSeries }: HeadingProps): JSX.Element {
	const group = eventSeries.group;
	const org = useCurrentOrganization();

	const useDeleteDialog = useDialog({
		message: t(
			'This action will remove all upcoming events in this series and detach all past events from the series.'
		),
		onConfirm: () => actions.eventSeries.remove(eventSeries.id, false, org.id),
	});

	const useDeleteAllDialog = useDialog({
		message: t('This action will remove all events connected to this series.'),
		onConfirm: () => actions.eventSeries.remove(eventSeries.id, true, org.id),
	});

	const icons: MaterialSymbolVariant[] = ['sync'];

	if (eventSeries.isPrivate) icons.push('visibility_off');

	return (
		<Column spacing={styles.spacing._4}>
			<Row spacing={styles.spacing._2} autoColumns="max-content">
				<Label eventType={eventSeries.type}>
					{models.eventSeries.getTypeTranslation(eventSeries)}
				</Label>
				{group.name && <Label color="gray">{group.name}</Label>}
			</Row>
			<SectionTitle icon={icons} large>
				{eventSeries.title}
				{(models.canEdit(eventSeries) || models.canDelete(eventSeries)) && (
					<ContextMenu.Menu
						toggleWith={<Button secondary small icon="more_horiz" />}>
						{models.canEdit(eventSeries) && (
							<ContextMenu.LinkItem
								href={Routes.EventSeries.Edit(org.id, eventSeries.id)}
								icon="edit">
								{t('Edit')}
							</ContextMenu.LinkItem>
						)}
						{models.canDelete(eventSeries) && (
							<Fragment>
								<ContextMenu.Item
									onClick={useDeleteDialog}
									caution
									icon="delete">
									{t('Delete series')}
								</ContextMenu.Item>
								<ContextMenu.Item
									onClick={useDeleteAllDialog}
									caution
									icon="delete">
									{t('Delete series and events')}
								</ContextMenu.Item>
							</Fragment>
						)}
					</ContextMenu.Menu>
				)}
			</SectionTitle>
		</Column>
	);
}
