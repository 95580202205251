import { JSX } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Frame = styled.div`
	position: absolute;
	border: 1px solid #509af8;
	pointer-events: none;
	z-index: 1000;
`;

const Corner = styled(motion.div)<{ right?: boolean; bottom?: boolean }>`
	position: absolute;
	${({ bottom }) => (bottom ? 'bottom' : 'top')}: -6px;
	${({ right }) => (right ? 'right' : 'left')}: -6px;
	width: 12px;
	height: 12px;
	background: white;
	border: 1px solid #509af8;
	pointer-events: all;
	cursor: ${({ bottom, right }) =>
		(bottom && !right) || (!bottom && right) ? 'nesw-resize' : 'nwse-resize'};
`;

// todo: set actual types
interface AnnotationResizerProps {
	updateKeyframe: any;
	startDragging: any;
	stopDragging: any;
	dragStart: any;
	dragPosition: any;
	setDragPosition: any;
	style: any;
}

export default function AnnotationResizer({
	updateKeyframe,
	startDragging,
	stopDragging,
	dragStart,
	dragPosition,
	setDragPosition,
	style,
}: AnnotationResizerProps): JSX.Element {
	const onTopLeft = (_e: any, { offset }: any) => {
		if (dragStart) {
			setDragPosition({
				left: dragStart.left + offset.x,
				top: dragStart.top + offset.y,
				width: dragStart.width - offset.x,
				height: dragStart.height - offset.y,
			});
		}
	};

	const onTopRight = (_e: any, { offset }: any) => {
		if (dragStart) {
			setDragPosition({
				left: dragStart.left,
				top: dragStart.top + offset.y,
				width: dragStart.width + offset.x,
				height: dragStart.height - offset.y,
			});
		}
	};

	const onBottomRight = (_e: any, { offset }: any) => {
		if (dragStart) {
			setDragPosition({
				left: dragStart.left,
				top: dragStart.top,
				width: dragStart.width + offset.x,
				height: dragStart.height + offset.y,
			});
		}
	};

	const onBottomLeft = (_e: any, { offset }: any) => {
		if (dragStart) {
			setDragPosition({
				left: dragStart.left + offset.x,
				top: dragStart.top,
				width: dragStart.width - offset.x,
				height: dragStart.height + offset.y,
			});
		}
	};

	const onStop = () => {
		stopDragging();

		updateKeyframe(dragPosition);
	};

	return (
		<Frame style={style}>
			<Corner onPanStart={startDragging} onPan={onTopLeft} onPanEnd={onStop} />

			<Corner
				right
				onPanStart={startDragging}
				onPan={onTopRight}
				onPanEnd={onStop}
			/>

			<Corner
				right
				bottom
				onPanStart={startDragging}
				onPan={onBottomRight}
				onPanEnd={onStop}
			/>

			<Corner
				bottom
				onPanStart={startDragging}
				onPan={onBottomLeft}
				onPanEnd={onStop}
			/>
		</Frame>
	);
}
