import { JSX } from 'react';
import { useT } from '@transifex/react';

import { crash } from 'pkg/errors/errors';
import * as routes from 'pkg/router/routes';

import Icon from 'components/icon';

import Column from 'components/layout/column';

import Button from 'design/button';

import * as css from './styles.css';

/**
 * ViewCrash is used when a view crashes, but the navigation should still be available.
 * This is a sensible fallback for most crashes, but if you can isolate the crashed component
 * even further it's good.
 *
 * Resetting from this crashed state doesn't require reloading the entire app.
 */
export function ViewCrash(): JSX.Element {
	const err = crash();
	const t = useT();
	return (
		<div className={css.wrapper}>
			<Column justify="center">
				<Icon className={css.icon} size={4} actualSize name="error" />
				<h2>{err.title}</h2>
				<p>
					{t(
						'The issue has been registered. If this keeps happening, please contact support.'
					)}
				</p>

				<Button large icon="help" href={routes.Support()}>
					{t('Go to support')}
				</Button>
			</Column>
		</div>
	);
}
