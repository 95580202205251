import { JSX } from 'react';
import styled from 'styled-components';

import * as models from 'pkg/api/models';

import Avatar from 'components/avatar';

const UserWrapper = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: auto 1fr;
	gap: var(--spacing-4);
	align-items: center;

	span {
		color: var(--palette-blue-500);
		font-size: var(--font-size-xl);
		font-weight: var(--font-weight-semibold);
	}

	${Avatar} {
		width: 30px;
		height: 30px;
	}
`;

interface ChildUserProps {
	user: models.user.User;
}

export default function ChildUser({ user }: ChildUserProps): JSX.Element {
	return (
		<UserWrapper>
			<Avatar user={user} />
			<span>{models.user.fullName(user)}</span>
		</UserWrapper>
	);
}
