import { t } from '@transifex/native';
import { JSX, ReactNode, useEffect, useState } from 'react';

import * as models from 'pkg/api/models';
import { FilterModalProps } from 'pkg/filters/types/Modal';
import { useCurrentGroupId } from 'pkg/identity';

import * as StepModal from 'components/step-modal';

import * as ActionBar from 'components/layout/ActionBar';
import SearchInput from 'components/form/SearchInput';
import GroupsTable from 'components/group/table';
import useGroupSelectTable from 'components/group/use-select-table';

// This modal handles the fetching of groups
export function GroupSelectModal({
	handleClose,
	handleNext,

	selected = [],
	handleSelectAll,
}: FilterModalProps): JSX.Element {
	const groupId = useCurrentGroupId();

	const selectTable = useGroupSelectTable({
		groupId,
		defaultSelectedGroupIds: selected,
		onChange: (groups: models.group.Group[]) => {
			if (handleSelectAll) {
				handleSelectAll(groups.map((group: models.group.Group) => group.id));
			}
		},
	});

	const onNext = () => handleNext(selectTable.selectedGroups.map((g) => g.id));

	return (
		<StepModal.Base onClose={handleClose}>
			<StepModal.Step
				title={t('Select groups')}
				nextLabel={t('Done')}
				onNext={onNext}
				skipBody>
				{selectTable.Component}
			</StepModal.Step>
		</StepModal.Base>
	);
}

interface GroupsSelectModalProps {
	// Determines whether the modal should only allow to select one item or not
	singleSelect?: boolean;
	// Populate with group ids you want to be pre selected
	preSelectedGroups?: number[];

	// Groups that will be listed
	groups: models.group.Group[];

	// Function that runs on Step modals onClose
	hideModal: () => void;
}

export interface ReturnProps {
	selectedGroups: number[];

	Modal: ReactNode;
}

const useSelectGroups = ({
	singleSelect,
	groups,
	preSelectedGroups = [],
	hideModal,
}: GroupsSelectModalProps): ReturnProps => {
	const [selected, setSelected] = useState<number[]>(preSelectedGroups);
	const [search, setSearch] = useState('');

	const handleSearch = (value: string) => {
		setSearch(value);
	};

	const select = (groupId: number) => {
		if (singleSelect) {
			setSelected((prev) => {
				if (prev.includes(groupId)) {
					return [];
				} else {
					return [groupId];
				}
			});
		} else {
			setSelected((prev) => {
				return prev.includes(groupId)
					? prev.filter((id) => id !== groupId)
					: [...prev, groupId];
			});
		}
	};

	const handleClose = () => {
		setSearch('');
		hideModal();
	};

	useEffect(() => {
		setSelected(preSelectedGroups);
	}, [preSelectedGroups.length]);

	return {
		selectedGroups: selected,
		Modal: (
			<StepModal.Base onClose={handleClose}>
				<StepModal.Step
					title={t('Select groups')}
					nextLabel={t('Done')}
					skipBody>
					<ActionBar.Bar>
						<ActionBar.PrimaryAction>
							<SearchInput
								small
								placeholder={t('Search group (name)')}
								onChange={handleSearch}
							/>
						</ActionBar.PrimaryAction>
					</ActionBar.Bar>
					<GroupsTable
						groups={groups}
						search={search}
						selected={selected}
						select={select}
					/>
				</StepModal.Step>
			</StepModal.Base>
		),
	};
};

export default useSelectGroups;
