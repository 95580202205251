import {
	JSX,
	Children,
	SetStateAction,
	cloneElement,
	isValidElement,
} from 'react';
import styled from 'styled-components';
import {
	Reorder,
	TargetAndTransition,
	useDragControls,
	VariantLabels,
} from 'framer-motion';

import * as styles from 'pkg/config/styles';

import Icon from 'components/icon';

import Column from 'components/layout/column';

interface ListProps {
	items: unknown[];
	onReorder: SetStateAction<any>;
	children: JSX.Element[];
	spacing?: styles.spacing;
}

interface ItemProps {
	item: any;
	gestureStyles?: {
		drag?: VariantLabels | TargetAndTransition;
		tap?: VariantLabels | TargetAndTransition;
	};
	children: any;
	isLast?: boolean;
}

const DragElement = styled.div`
	@media ${styles.breakpoint.small} {
		pointer-events: none;
	}
`;

export const Item: React.FC<React.PropsWithChildren<ItemProps>> = ({
	item,
	gestureStyles = { drag: {}, tap: {} },
	children,
	isLast,
}) => {
	const controls = useDragControls();

	const childrenWithProps = Children.map(children, (child) => {
		return cloneElement(child, {
			dragElement: (
				<DragElement onPointerDown={(e) => controls.start(e)}>
					<Icon
						size={1.3}
						name="drag-handle"
						fill={styles.palette.gray['500']}
					/>
				</DragElement>
			),
		});
	});

	// we know that we will always only have one child
	const child = cloneElement(childrenWithProps[0], {
		isLast,
	});

	return (
		<Reorder.Item
			value={item}
			whileDrag={gestureStyles.drag}
			whileTap={gestureStyles.tap}
			dragControls={controls}>
			{child}
		</Reorder.Item>
	);
};

export const List: React.FC<React.PropsWithChildren<ListProps>> = ({
	items,
	onReorder,
	children,
	spacing = styles.spacing._4,
}) => {
	const clonedChildren = Children.map(children, (child, index) => {
		if (isValidElement(child)) {
			return cloneElement(child as any, {
				isLast: index === Children.count(children) - 1,
			});
		}

		return child;
	});

	return (
		<Reorder.Group layoutScroll axis="y" values={items} onReorder={onReorder}>
			<Column spacing={spacing}>{clonedChildren}</Column>
		</Reorder.Group>
	);
};
