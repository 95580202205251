import { useT } from '@transifex/react';
import { Fragment } from 'react/jsx-runtime';

import useAdyenTransferInstrument from 'pkg/hooks/adyen/useTransferInstrument';
import * as models from 'pkg/api/models';

import { SettingLabel, SettingRow } from 'components/settings/Settings';

interface AdyenTransferInstrumentProps {
	organization: models.group.Group;
	paymentConfigId: number;
}

export default function AdyenTransferInstrument({
	organization,
	paymentConfigId,
}: AdyenTransferInstrumentProps) {
	const t = useT();

	const { isLoading, ManageTransferInstrument } = useAdyenTransferInstrument({
		organizationId: organization.id,
		countryCode: organization.country?.code,
		paymentConfigId,
	});

	if (isLoading) {
		return null;
	}

	return (
		<Fragment>
			<SettingRow>
				<SettingLabel>{t('Bank accounts')}</SettingLabel>
			</SettingRow>
			{ManageTransferInstrument}
		</Fragment>
	);
}
