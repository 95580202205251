import { useSelector } from 'react-redux';
import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import Link from 'pkg/router/Link';
import { formatTime } from 'pkg/timeline';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import { useCurrentOrganization } from 'pkg/identity';

import ClipDiscussion from 'routes/video/shared/ClipDiscussion';
import Clip from 'routes/video/shared/clips/CurrentClip';

import { useCueState } from 'components/video-analytics/CueState';

export default function CurrentClip(): JSX.Element {
	const { currentCue } = useCueState();
	const org = useCurrentOrganization();

	const clip = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, currentCue?.cueId as number)
	);

	const video = useSelector((state: RootState) =>
		selectors.videos.find(state, currentCue?.videoId)
	);

	const author = useSelector((state: RootState) =>
		selectors.accounts.find(state, clip?.accountId)
	);

	if (!clip?.valid() || !video?.valid()) return null;

	return (
		<Clip
			title={clip.title}
			subTitle={t('{starts_at} – {ends_at} by {author_name}', {
				starts_at: formatTime(clip.startsAt),
				ends_at: formatTime(clip.endsAt),
				author_name: author.fullName,
			})}
			byLine={
				<Fragment>
					{t('Source:')}{' '}
					<Link href={routes.Video.Show(org.id, clip.videoId)}>
						{video.title}
					</Link>
				</Fragment>
			}
			isHighlight={clip.reviewed}
			isPrivate={clip.private}
			description={clip.description}>
			<ClipDiscussion />
		</Clip>
	);
}
