// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.I8e5pF4606oqBcMV7g0w {
	font-size: var(--font-size-sm);
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/billing-contact/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;AAC/B","sourcesContent":[".billingEmail {\n\tfont-size: var(--font-size-sm);\n}\n"],"sourceRoot":""}]);
// Exports
export var billingEmail = `I8e5pF4606oqBcMV7g0w`;
export default ___CSS_LOADER_EXPORT___;
