import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as actions from 'pkg/actions';
import { QueryObject } from 'pkg/hooks/query-state';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';
import { Dates } from 'pkg/hooks/use-date-filter';
import { useCurrentOrganization } from 'pkg/identity';
import { Collection } from 'pkg/api/models/collection';
import DateTime from 'pkg/datetime';

import Filters, { getFilters } from 'routes/group/report/shared/Filters';
import ActivityReportRow from 'routes/group/report/activities/ActivityReportRow';

import AssetImage from 'components/AssetImage';

import * as Table from 'design/table';
import TableInfo from 'design/table/table-title';

import * as css from './styles.css';

interface ActivitiesReportProps {
	groupId: number;
	query: QueryObject;
	dateFilter: JSX.Element;
	dateRange: Dates;
}

export interface ReportRow {
	id: number;
	event: models.event.Event;
	attendanceUnhandled: number;
	attendanceAttended: number;
	attendanceInjured: number;
	attendanceSick: number;
	attendanceOther: number;
	invitePending: number;
	inviteAccepted: number;
	inviteDeclined: number;
	validForLok: boolean;
}

export default function ActivitiesReport({
	groupId,
	query = {},
	dateFilter,
	dateRange,
}: ActivitiesReportProps): JSX.Element {
	const [searchBy, setSearchBy] = useState('');
	const [sortedBy, setSortedBy] = useState('date');
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

	const organization = useCurrentOrganization();

	const filters = getFilters(query);

	const targetGroupId = Number.parseInt(query?.targetGroupId as string, 10);

	const { record: group, isLoading: isLoadingGroup } =
		useEndpoint<models.group.Group>(
			endpoints.Groups.Show(targetGroupId || groupId)
		);

	const report = useEndpoint<Collection<ReportRow>>(
		endpoints.EventReport.EventSummaryReport(),
		{
			postRequest: true,
			body: {
				groupId: targetGroupId || groupId,
				eventTypes: filters.eventTypes,
				startsAt: dateRange.startOfRange,
				endsAt: dateRange.endOfRange,
			},
		}
	);

	const handleSort = (key: string, sortOrder: 'asc' | 'desc') => {
		setSortOrder(sortOrder);
		setSortedBy(key);
	};

	const sortReports = (a: ReportRow, b: ReportRow) => {
		let aValue: string;
		let bValue: string;

		const aEvent = a.event;
		const bEvent = b.event;

		if (sortedBy === 'title') {
			aValue = aEvent?.title || '';
			bValue = bEvent?.title || '';
		}

		if (sortedBy === 'date') {
			aValue = `${aEvent?.startsAt || 0}`;
			bValue = `${bEvent?.startsAt || 0}`;
		}

		if (sortedBy === 'location') {
			aValue = `${aEvent?.location || ''}`;
			bValue = `${bEvent?.location || ''}`;
		}

		if (sortedBy === 'invited') {
			aValue = `${a.inviteAccepted + a.inviteDeclined + a.invitePending}`;
			bValue = `${b.inviteAccepted + b.inviteDeclined + b.invitePending}`;
		}

		if (sortedBy === 'attended') {
			aValue = `${a.attendanceAttended}`;
			bValue = `${b.attendanceAttended}`;
		}

		if (sortedBy === 'absent') {
			aValue = `${a.attendanceInjured + a.attendanceOther + a.attendanceSick}`;
			bValue = `${b.attendanceInjured + b.attendanceOther + b.attendanceSick}`;
		}

		if (sortedBy === 'unhandled') {
			aValue = `${a.attendanceUnhandled}`;
			bValue = `${b.attendanceUnhandled}`;
		}

		if (sortOrder === 'asc') {
			return aValue.localeCompare(bValue, DateTime.getLocale(), {
				numeric: true,
				sensitivity: 'base',
			});
		}

		return bValue.localeCompare(aValue, DateTime.getLocale(), {
			numeric: true,
			sensitivity: 'base',
		});
	};

	const renderedReports = Object.values(report.record?.records || {})
		.filter((report) => {
			if (searchBy === '') {
				return true;
			}

			const event = report.event;

			return event.title.toLowerCase().includes(searchBy.toLowerCase());
		})
		.sort(sortReports)
		.map((report) => {
			const event = report.event;

			if (!event?.id) return;

			return (
				<ActivityReportRow
					key={report.id}
					event={event}
					report={report}
					showLOK={models.group.isLOKActive(organization)}
				/>
			);
		});

	const columns = [
		{
			content: t(`Event title`),
			sortOrder,
			sortedBy: sortedBy === 'title',
			sortKey: 'title',
		},
		{
			content: t(`Date`),
			align: 'right',
			width: 'max-content',
			sortOrder,
			sortedBy: sortedBy === 'date',
			sortKey: 'date',
		},
		{
			content: t(`Time`),
			align: 'right',
			width: 'max-content',
		},
		{
			content: t(`Location`),
			sortOrder,
			sortedBy: sortedBy === 'location',
			sortKey: 'location',
		},
		{
			content: t(`Invited`),
			align: 'right',
			width: 'max-content',
			sortOrder,
			sortedBy: sortedBy === 'invited',
			sortKey: 'invited',
		},
		{
			content: t(`Attended`),
			align: 'right',
			width: 'max-content',
			sortOrder,
			sortedBy: sortedBy === 'attended',
			sortKey: 'attended',
		},
		{
			content: t(`Absent`),
			align: 'right',
			width: 'max-content',
			sortOrder,
			sortedBy: sortedBy === 'absent',
			sortKey: 'absent',
		},
		{
			content: t(`Unhandled`),
			align: 'right',
			width: 'max-content',
			sortOrder,
			sortedBy: sortedBy === 'unhandled',
			sortKey: 'unhandled',
		},
		models.group.isLOKActive(organization)
			? {
					content: 'LOK',
					align: 'center',
					width: 'max-content',
				}
			: null,
		models.group.isLOKActive(organization)
			? {
					content: t('Updated at'),
					align: 'center',
					width: 'max-content',
				}
			: null,
		models.group.isLOKActive(organization)
			? {
					content: t('Sent to LOK'),
					align: 'center',
					width: 'max-content',
				}
			: null,
	].filter((n) => n);

	const emptyState = {
		title: t(`You don't have any available reports`),
		content: t(`You don't have any available reports`),
		image: <AssetImage src="img/missing-entities/post.svg" />,
	};

	const handleRefreshLok = async () => {
		const eventIds: number[] = [];
		Object.values(report.record?.records || {}).map((report) => {
			eventIds.push(report.event.id);
		});

		const request = await sdk.post(
			endpoints.Events.UpdateBatch(targetGroupId || groupId),
			{
				from_date: Number.parseInt(filters.startOfRange),
				to_date: Number.parseInt(filters.endOfRange),
			}
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Failed to update events'),
			});
		}
	};

	const validTimeRange =
		Number.parseInt(filters.endOfRange) -
			Number.parseInt(filters.startOfRange) <
		31536000;

	return (
		<Fragment>
			<Filters
				searchBy={searchBy}
				handleSearch={setSearchBy}
				handleRefreshLok={handleRefreshLok}
				eventReport
				validTimeRange={validTimeRange}
				dateFilter={dateFilter}
			/>
			{!isLoadingGroup && (
				<TableInfo>
					<div className={css.groupName}>
						{t('Event report for: {groupName}', {
							groupName: group.name,
						})}
					</div>
				</TableInfo>
			)}
			<Table.Table
				stickyHeader
				isLoading={report.isLoading}
				emptyState={emptyState}
				sortBy={sortedBy}
				sortOrder={sortOrder}
				columns={columns}
				onSort={handleSort}>
				{renderedReports}
			</Table.Table>
		</Fragment>
	);
}
