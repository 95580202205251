import { Fragment, useMemo, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import {
	overlappingPeriods,
	PublishedPeriodInfo,
	PublishSchedulePayload,
} from 'pkg/api/models/schedule';
import {
	getGmtString,
	getTimeZoneByGmtString,
	getTimeZoneFromSessionStorage,
	getTimeZoneFromString,
	validateTimeZone,
} from 'pkg/timezone';
import useMixedState from 'pkg/hooks/useMixedState';
import DateTime from 'pkg/datetime';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { EventFlags } from 'pkg/api/models/event';

import { TimeZoneText } from 'routes/event/components/Form';
import useManagePublishedPeriods, {
	StatePublishPeriod,
} from 'routes/scheduling/templates/hooks/useManagePublishedPeriods';
import PublishedPeriodCardListItem from 'routes/scheduling/templates/modals/publish/published-period-card-list';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import TimeZoneSearch from 'components/form/TimeZoneSelect';
import InfoBox from 'components/form/info-box';

import * as css from './styles.css';

interface FormState {
	timeZone: string;
	bookings: number;
	showTimeZoneInput: boolean;
	createEvents: boolean;
	inviteUsers: boolean;
	autoInviteNewUsers: boolean;
}

interface PublishTemplateModalProps {
	hideModal: () => void;
	refreshSchedule: () => Promise<void>;

	schedule: models.schedule.Schedule;
	publishedPeriods: models.schedule.PublishedPeriod[];
}

const PublishTemplateModal = ({
	hideModal,
	refreshSchedule,
	schedule,
	publishedPeriods = [],
}: PublishTemplateModalProps) => {
	const tz =
		useMemo(() => getGmtString(getTimeZoneFromSessionStorage()), []) || '';
	const {
		content: dateRangeContent,
		statePublishedPeriods,
		hasValidDates,
		periodsToRemove,
	} = useManagePublishedPeriods({
		groupId: schedule.groupId,
		showPublishedPeriods: true,
		scheduleId: schedule.id,
		publishedPeriods: publishedPeriods.sort((a, b) => a.startsAt - b.startsAt),
	});

	const schedulePublishSettings: models.schedule.ParsedPublishSettings =
		schedule.publishSettings ? JSON.parse(schedule.publishSettings) : '';

	const [formState, setFormState] = useMixedState<FormState>({
		timeZone: schedulePublishSettings.timezone
			? getGmtString(getTimeZoneFromString(schedulePublishSettings.timezone))
			: tz || '',
		showTimeZoneInput: false,
		bookings: null,
		createEvents: schedulePublishSettings.createEvents || false,
		inviteUsers: schedulePublishSettings.inviteUsers || false,
		autoInviteNewUsers: schedulePublishSettings.autoInviteNewUsers || false,
	});

	useComponentDidMount(() => {
		if (!tz && !schedulePublishSettings.timezone) {
			setFormState({
				showTimeZoneInput: true,
			});
		}
	});

	const [publishedData, setPublishedData] = useState<PublishedPeriodInfo[]>([]);

	const compiledPublishedData =
		models.schedule.getCompiledPublishedPeriodsInfo(publishedData);

	const handleShowPreview = async () => {
		const payload: PublishSchedulePayload = {
			timezone: getTimeZoneByGmtString(formState.timeZone).name,
			publishedPeriods: statePublishedPeriods.map((period) => {
				const startsAt = DateTime.fromTimestamp(
					period.startsAt
				).toISODateString();
				const endsAt = DateTime.fromTimestamp(period.endsAt).toISODateString();

				return {
					id: period.id || 0,
					groupId: period.groupId,
					startDate: startsAt,
					endDate: endsAt,
					clientId: period.clientId,
				};
			}),
			dryRun: true,
		};

		if (formState.createEvents) {
			payload.createEvents = true;
		}

		const [request, bookings] = await models.schedule.publish(
			schedule.id,
			payload
		);

		setPublishedData(bookings.records);

		if (request.ok) {
			setFormState({ bookings: bookings.records.length });
			return true;
		} else {
			return false;
		}
	};

	const handlePublish = async () => {
		const confirm = window.confirm(
			t(
				'Are you sure you want to publish {scheduleTitle} between selected periods?',
				{
					scheduleTitle: schedule?.title,
				}
			)
		);
		if (confirm) {
			const timezone = getTimeZoneByGmtString(formState.timeZone).name;
			const publishSettings = JSON.stringify({
				timezone,
				createEvents: formState.createEvents,
				inviteUsers: formState.inviteUsers,
				autoInviteNewUsers: formState.autoInviteNewUsers,
			});

			const payload: PublishSchedulePayload = {
				timezone,
				publishedPeriods: statePublishedPeriods.map((period) => {
					const startsAt = DateTime.fromTimestamp(
						period.startsAt
					).toISODateString();
					const endsAt = DateTime.fromTimestamp(
						period.endsAt
					).toISODateString();

					return {
						id: period.id || 0,
						groupId: period.groupId,
						startDate: startsAt,
						endDate: endsAt,
						clientId: period.clientId,
					};
				}),
				dryRun: false,
			};

			if (formState.createEvents) {
				payload.createEvents = true;
			}

			if (formState.inviteUsers) {
				payload.inviteUsersToEvent = true;
				payload.inviteAdminAndStaffToEvent = true;
			}

			if (formState.autoInviteNewUsers) {
				payload.eventFlags = [
					EventFlags.EventFlagsAutoInviteAdminAndStaff,
					EventFlags.EventFlagsAutoInviteUsers,
				];
			}

			const [request] = await models.schedule.publish(schedule.id, payload);

			if (request.ok) {
				await models.schedule.update(schedule, {
					publishSettings,
				});

				refreshSchedule();
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const handleTimeZoneChange = (tz: string) => {
		setFormState({ timeZone: tz });
	};

	const handleEventChange = () =>
		setFormState({ createEvents: !formState.createEvents });

	const handleToggleTimeZoneSelect = () =>
		setFormState({ showTimeZoneInput: !formState.showTimeZoneInput });

	const handleInviteUsersChange = () =>
		setFormState({ inviteUsers: !formState.inviteUsers });

	const handleAutoInviteUsersChange = () =>
		setFormState({ autoInviteNewUsers: !formState.autoInviteNewUsers });

	const hasPeriodsToRemove = !!periodsToRemove.length;

	let canGoNext = false;

	const hasOverlappingPeriods = overlappingPeriods(
		statePublishedPeriods.filter((period) => !period.editMode)
	);

	if (
		(!!statePublishedPeriods.length &&
			hasValidDates &&
			validateTimeZone(formState.timeZone)) ||
		(!!!statePublishedPeriods.length &&
			hasPeriodsToRemove &&
			validateTimeZone(formState.timeZone))
	) {
		canGoNext = true;
	}

	if (hasOverlappingPeriods) {
		canGoNext = false;
	}

	const isEditing = statePublishedPeriods.some((pp) => pp.editMode);

	if (isEditing) {
		canGoNext = false;
	}

	let unPublishedPeriodsContent = null;

	if (hasPeriodsToRemove) {
		unPublishedPeriodsContent = (
			<Fragment>
				<SectionTitle>{t('Schedule will be unpublished from')}</SectionTitle>
				{periodsToRemove.map((publishedPeriod) => (
					<PublishedPeriodCardListItem
						key={publishedPeriod.id}
						publishedPeriod={
							{
								...publishedPeriod,
								editMode: false,
							} as StatePublishPeriod
						}
						showPublishedPeriods
						willBeRemoved={true}
					/>
				))}
			</Fragment>
		);
	}

	// I spread the state so we can sort the mapped out once but not mutate the state
	const publishStepPeriods = [...statePublishedPeriods];

	let scheduleSuccessTitle = t(
		'The schedule "{scheduleTitle}" is beeing published!',
		{
			scheduleTitle: schedule.title,
		}
	);

	if (formState.createEvents) {
		scheduleSuccessTitle = t(
			'The schedule "{scheduleTitle}" is beeing published and events are beeing created!',
			{
				scheduleTitle: schedule.title,
			}
		);
	}

	return (
		<StepModal.Base onClose={hideModal} wide>
			<StepModal.Step
				title={t('Publish schedule')}
				onNext={handleShowPreview}
				closeOnNext={false}
				canGoNext={canGoNext}
				nextLabel={t('Preview')}>
				<Column>
					<Column spacing={styles.spacing._7}>
						<SectionTitle>{t('Add date ranges')}</SectionTitle>
						<InfoBox
							text={t(
								'Important! Applying this schedule to dates where another schedule is already published will remove the old schedule and replace it with this one. Users in your organization will be notified regarding the change.'
							)}
						/>
						{hasOverlappingPeriods && (
							<InfoBox
								text={t(
									`You can't publish periods that overlap with each other`
								)}
								color="red"
							/>
						)}
						{dateRangeContent}
						{hasPeriodsToRemove && (
							<Column spacing={styles.spacing._3}>
								{unPublishedPeriodsContent}
							</Column>
						)}
						<Input.Group label={t('Time zone')}>
							{!formState.showTimeZoneInput ? (
								<TimeZoneText onClick={handleToggleTimeZoneSelect}>
									{formState.timeZone || t('Select time zone')}
								</TimeZoneText>
							) : (
								<TimeZoneSearch
									timezone={formState.timeZone}
									onSelect={handleTimeZoneChange}
									onBlur={handleToggleTimeZoneSelect}
								/>
							)}
						</Input.Group>
					</Column>
					<Card.Divider />
					<div>
						<Column>
							<SectionTitle>{t('Event creation settings')}</SectionTitle>
							<Input.Control
								type="checkbox"
								label={t('Publish events')}
								description={t(
									'Publish the events to be visible in the groups calendars'
								)}
								checked={formState.createEvents}
								onChange={handleEventChange}
							/>
							<Input.Control
								type="checkbox"
								label={t('Add current group users')}
								description={t(
									'Adds current admin/staff as organizers and players as participants'
								)}
								checked={formState.inviteUsers}
								onChange={handleInviteUsersChange}
							/>
							<Input.Control
								type="checkbox"
								label={t('Add new group users')}
								description={t(
									'Automatically adds admins or staff joining the group as organizers and players as participants'
								)}
								checked={formState.autoInviteNewUsers}
								onChange={handleAutoInviteUsersChange}
							/>
						</Column>
					</div>
				</Column>
			</StepModal.Step>
			<StepModal.Step
				title={t('Publish schedule')}
				nextLabel={t('Publish')}
				onNext={handlePublish}>
				<Column>
					<SectionTitle>{t('Preview schedule')}</SectionTitle>
					<InfoBox color="orange" title={t('Read this before proceeding!')}>
						<p>
							{t(
								'A total of {newItems} new and {changedOrRemovedItems} changed/deleted bookings/events will be automatically created.',
								{
									newItems:
										compiledPublishedData.newBookings +
										compiledPublishedData.newEvents,
									changedOrRemovedItems:
										compiledPublishedData.changedBookings +
										compiledPublishedData.changedEvents +
										compiledPublishedData.deletedEvents +
										compiledPublishedData.deletedBookings,
								}
							)}
						</p>
					</InfoBox>
					<Column spacing={styles.spacing._3}>
						{publishStepPeriods
							.sort((a, b) => a.startsAt - b.startsAt)
							.map((publishedPeriod) => {
								return (
									<PublishedPeriodCardListItem
										key={publishedPeriod.id}
										publishedPeriod={publishedPeriod}
										showPublishedPeriods
										periodData={publishedData.find(
											(pd) => pd.clientId === publishedPeriod.clientId
										)}
									/>
								);
							})}
					</Column>
					{hasPeriodsToRemove && (
						<Column spacing={styles.spacing._3}>
							{unPublishedPeriodsContent}
							<InfoBox color="yellow">
								{periodsToRemove.length > 1
									? t(
											'Warning: Schedule will be removed for these periods and bookings/events removed'
										)
									: t(
											'Warning: Schedule will be removed for this period and bookings and events removed'
										)}
							</InfoBox>
						</Column>
					)}
				</Column>
			</StepModal.Step>
			<StepModal.Step
				title={t('Publish schedule')}
				nextLabel={t('Close')}
				hidePrev={true}>
				<div className={css.successWrapper}>
					<Icon
						name="check-circle"
						fill={styles.palette.green[400]}
						size={4}
						actualSize
					/>
					<SectionTitle>{scheduleSuccessTitle}</SectionTitle>
					<span>
						{t(
							'You will get a notification when this is done, you can close this window.'
						)}
					</span>
				</div>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default PublishTemplateModal;
