import { JSX, Fragment, useContext } from 'react';

import * as models from 'pkg/api/models';
import { useQueryState } from 'pkg/hooks/query-state';

import { findGroupsByFilterForBookings } from 'routes/scheduling/utils';
import GroupRow from 'routes/scheduling/bookings/groups/Row';
import {
	defaultFilters,
	FilterState,
	hasBeenFiltered,
} from 'routes/scheduling/templates/filters';
import { BookingsContext } from 'routes/scheduling/bookings';

interface groupRow {
	group: models.group.Group;
	items: models.booking.Booking[];
}

export interface groupGrid {
	[key: number]: groupRow;
}

const GroupGrid = () => {
	const qs = useQueryState();
	const search = qs.get('search')?.toString().toLowerCase();
	const qsFilters = qs.get('schedulingFilters');
	const filters =
		qsFilters !== 'undefined'
			? (JSON.parse(qsFilters as string) as FilterState)
			: defaultFilters;
	const hasFiltered = hasBeenFiltered(filters);
	const bookingsContext = useContext(BookingsContext);

	const grid = new Map<string, groupRow>();

	bookingsContext.flattenedGroups.forEach((group) => {
		grid.set(group.id.toString(), { group, items: [] });
	});

	bookingsContext.bookings.forEach((item) => {
		if (!grid.has(item.forGroupId?.toString())) {
			if (!item.forGroupId) {
				return;
			}

			grid.set(item.forGroupId.toString(), {
				group: bookingsContext.flattenedGroups.find(
					(g) => g.id === item.forGroupId
				),
				items: [],
			});
		}

		const gridItem = grid.get(item.forGroupId?.toString());
		grid.set(item.forGroupId.toString(), {
			...gridItem,
			items: [...gridItem.items, item],
		});
	});

	if (search) {
		for (const k of grid.keys()) {
			if (!grid.get(k).group.name.toLocaleLowerCase().includes(search)) {
				grid.delete(k);
			}
		}
	}

	const groupsToList = findGroupsByFilterForBookings(
		bookingsContext.flattenedGroups,
		bookingsContext.bookings,
		filters,
		bookingsContext.conflicts
	);

	const groupIds = [...new Set(groupsToList.map((g) => g.id))];

	if (hasFiltered) {
		for (const k of grid.keys()) {
			if (!groupIds.includes(Number.parseInt(k, 10))) {
				grid.delete(k);
			}
		}
	}

	if (grid.size === 0) {
		return null;
	}

	const groupRowItems: JSX.Element[] = [];

	grid.forEach((row) =>
		!row.group
			? null
			: groupRowItems.push(
					<GroupRow key={row.group.id} group={row.group} bookings={row.items} />
				)
	);

	return <Fragment>{groupRowItems}</Fragment>;
};

export default GroupGrid;
