import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as actions from 'pkg/actions';
import * as sdk from 'pkg/core/sdk';

import SingleUserRow from 'routes/group/settings/user_management/SingleUserRow';

import AddToSingleGroupModal from 'containers/payment_platform/contacts/AddToSingleGroupModal';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';
import InlinePlaceholder from 'design/placeholders/inline';
import * as Table from 'design/table';

import * as css from './styles.css';

interface UserListProps {
	organizationId: number;
	groupId: number;
	userId: number;
}

export default function UserGroupList({
	organizationId,
	userId,
	groupId,
}: UserListProps): JSX.Element {
	const {
		records: memberships,
		isLoading,
		selection,
		refresh,
	} = useCollection<models.membership.Membership>(
		endpoints.UserGroups.GroupMemberships(groupId, userId)
	);

	const { record: user, isLoading: userLoading } =
		useEndpoint<models.user.User>(endpoints.Users.Show(userId));

	const [modal, setModal] = useState<{
		show: boolean;
		name: string;
		userId?: number;
	}>({
		show: false,
		name: '',
		userId: 0,
	});

	const handleOpenGroupModal = (userId: number): void =>
		setModal({ show: true, name: 'group', userId });

	const handleCloseModal = (): void =>
		setModal({ show: false, name: '', userId: 0 });

	if (userLoading) {
		return <Spinner />;
	}
	const handleAddToGroup = async (groupId: number, role: number) => {
		const [request] = await models.create(
			endpoints.UserGroups.AddUsersToGroup(groupId),
			modal.userId
				? [{ id: modal.userId, role }]
				: selection.selectedRecords.map((userId) => ({
						id: userId,
						role,
					}))
		);

		if (request.ok) {
			actions.flashes.show({
				title: t('Added user to group'),
			});
		} else {
			actions.flashes.show({
				title: t('Unable to add user to group'),
			});
		}
	};

	const deleteUserFromGroup = async () => {
		const requestUrl = `/groups/${groupId}/users/${userId}`;
		const request = await sdk.destroy(requestUrl);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not delete user'),
			});
		}

		refresh();
	};

	const blockUserFromGroup = async () => {
		const requestUrl = `/groups/${groupId}/users/${userId}/ban`;
		const request = await sdk.patch(requestUrl);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not block user'),
			});
		}

		refresh();
	};

	const handlegroupModal = () => handleOpenGroupModal(user.id);

	return (
		<Fragment>
			<div className={css.top}>
				<div className={css.wrap}>
					<div className={css.user}>
						<Avatar size={50} user={user} />
						<div className={css.info}>
							<div className={css.userName}>{models.user.fullName(user)}</div>
							<div className={css.email}>{user.email}</div>
						</div>
					</div>
					<ContextMenu.Menu
						toggleWith={
							<Fragment>
								<LargeScreen>
									<ContextMenu.TableTrigger className={css.button}>
										<Icon name="context-menu" fill={styles.palette.blue[500]} />
									</ContextMenu.TableTrigger>
								</LargeScreen>
								<SmallScreen>
									<Button
										block
										secondary
										icon="expand_more"
										iconPosition="right">
										{t('Actions')}
									</Button>
								</SmallScreen>
							</Fragment>
						}>
						<ContextMenu.Item onClick={handlegroupModal}>
							<ContextMenu.ItemIcon name="group_add" />
							{t(`Add user to group`)}
						</ContextMenu.Item>
						<ContextMenu.Item caution onClick={deleteUserFromGroup}>
							<ContextMenu.ItemIcon name="delete" />
							{t(`Delete user from all groups`)}
						</ContextMenu.Item>
						<ContextMenu.Item caution onClick={blockUserFromGroup}>
							<ContextMenu.ItemIcon name="block" />
							{t(`Block user from all groups`)}
						</ContextMenu.Item>
						<ContextMenu.ReportItem contentType="User" contentId={userId} />
					</ContextMenu.Menu>
				</div>
			</div>
			{memberships.length > 0 ? (
				<div>
					<Table.Table
						isLoading={isLoading}
						columns={[
							{
								content: t(`Name`),
							},
							{
								content: t(`Role`),
							},
							{
								content: t('Title'),
							},
							{
								content: '',
								width: 'max-content',
							},
						]}>
						{memberships.map((membership) => (
							<SingleUserRow
								organizationId={organizationId}
								membership={membership}
								refresh={refresh}
							/>
						))}
					</Table.Table>
				</div>
			) : (
				<InlinePlaceholder>
					<Icon name="nav-members" />
					{t(`This user has no memberships`)}
				</InlinePlaceholder>
			)}

			{modal.show && modal.name === 'group' && (
				<AddToSingleGroupModal
					groupId={groupId}
					onConfirm={handleAddToGroup}
					onClose={handleCloseModal}
				/>
			)}
		</Fragment>
	);
}
