import { JSX, FC } from 'react';
import { t } from '@transifex/native';

import { AccountFlags, AccountClearFlags } from 'pkg/api/models/account';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { useFetchIdentity } from 'pkg/identity';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import FormOption from 'components/form/Toggle';

interface ThirdPartyServicesProps {
	account?: models.account.Account;
}

const ThirdPartyServices: FC<ThirdPartyServicesProps> = ({
	account,
}: ThirdPartyServicesProps): JSX.Element => {
	const fetchIdentity = useFetchIdentity();

	const hasOptOutFlag = models.account.hasFlag(
		account,
		AccountFlags.OptOutAnalytics
	);

	const updateMixpanelOptOut = async (): Promise<void> => {
		if (hasOptOutFlag) {
			await actions.accounts.patchAccount(account.id, {
				flags: [AccountClearFlags.OptOutAnalytics],
			});

			fetchIdentity();
		} else {
			await actions.accounts.patchAccount(account.id, {
				flags: [AccountFlags.OptOutAnalytics],
			});

			fetchIdentity();
		}
	};

	return (
		<Column>
			<SectionTitle>{t('Third party services')}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					{t(
						"We use various third party services to give you improved support and to better understand how you use 360Player. If you don't want us to track your usage, you can opt out of these services here."
					)}
				</Card.Body>
				<Card.Divider />
				<Card.Body>
					<FormOption
						standAlone
						label="Mixpanel"
						description={t(
							'We use Mixpanel to track anonymized usage patterns.'
						)}
						isActive={!hasOptOutFlag}
						onChange={updateMixpanelOptOut}
					/>
				</Card.Body>
			</Card.Base>
		</Column>
	);
};

export default ThirdPartyServices;
