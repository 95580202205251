import { JSX, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import * as styles from 'pkg/config/styles';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';

import AnnotationsLayout from 'routes/video/layouts/AnnotationsLayout';
import { LayoutContext } from 'routes/video/layouts/Layout';
import Source from 'routes/video/shared/Source';
import Controls from 'routes/video/shared/Controls';
import Timeline from 'routes/video/shared/Timeline';
import ClipList from 'routes/video/shared/ClipList';
import Workspace from 'routes/video/analyze/Workspace';
import Bootstrap from 'routes/video/analyze/Bootstrap';
import ToolbarState from 'routes/video/analyze/ToolbarState';
import KeyBindings from 'routes/video/analyze/KeyBindings';

import { formatRelativeDateTimeString } from 'components/RelativeDateTime';

import CueState from 'components/video-analytics/CueState';
import CueObserver from 'components/video-analytics/CueObserver';
import ClipState from 'components/video-analytics/ClipState';
import PlayerState from 'components/video-analytics/PlayerState';
import PlaybackState from 'components/video-analytics/PlaybackState';

interface AnalyzeProps {
	videoId: number;
}

export default function Analyze({ videoId }: AnalyzeProps): JSX.Element {
	const video = useSelector((state: RootState) =>
		selectors.videos.find(state, videoId)
	);

	const isLargeScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toLarge,
	});

	useComponentDidMount(async () => {
		await actions.videos.fetch(videoId);
	});

	useEffect(() => {
		if (video.groupId) {
			actions.groups.fetchSquad(video.groupId);
		}
	}, [video.groupId]);

	if (!video) return null;

	const createdAt = formatRelativeDateTimeString(video?.createdAt || 0, true);

	const workspaceHeight = isLargeScreen ? 280 : 340;

	return (
		<Fragment>
			<ClipState>
				<PlayerState>
					<PlaybackState>
						<CueState>
							<CueObserver />
							<ToolbarState>
								<KeyBindings />
								<AnnotationsLayout
									context={LayoutContext.Analyze}
									workspaceHeight={workspaceHeight}
									clampWorkspaceHeight
									title={video.title}
									subTitle={createdAt}
									bootstrap={<Bootstrap />}
									videoSource={<Source />}
									currentClip={<Workspace />}
									clipList={<ClipList context="edit-mode" />}
									controls={<Controls context="analyze" />}
									timeline={<Timeline />}
								/>
							</ToolbarState>
						</CueState>
					</PlaybackState>
				</PlayerState>
			</ClipState>
		</Fragment>
	);
}
