import { JSX, ReactNode } from 'react';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import * as actions from 'pkg/actions';

import IdentityProviderObserver from 'routes/group/settings/single-sign-on/identity-provider/observer';

import * as Card from 'components/Card';
import MaterialSymbol from 'components/material-symbols';
import Label from 'components/label';

import Column from 'components/layout/column';
import CardRow from 'components/card/card-row';
import Row from 'components/layout/row';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import { ButtonTrigger } from 'design/context_menu';
import * as Context from 'design/context_menu';
import Button from 'design/button';
import { useDialog } from 'design/dialog';

interface IdentityProviderCardProps {
	organization: models.group.Group;
	provider: models.identityProvider.IdentityProvider;
	replaceRecord: (idp: models.identityProvider.IdentityProvider) => void;
	removeRecord: (id: number) => void;
}

export default function IdentityProviderCard({
	organization,
	provider,
	replaceRecord,
	removeRecord,
}: IdentityProviderCardProps) {
	const t = useT();

	const handleManageConnection = async () => {
		const [ok, resp] = await actions.identityProviders.getPortalUrl(
			organization.id,
			provider.id
		);

		if (ok) {
			window.open(resp.url, '_blank');
		}
	};

	const handleRemove = async () => {
		const ok = await actions.identityProviders.remove(
			organization.id,
			provider.id
		);

		if (ok) {
			removeRecord(provider.id);
		}
	};

	const hasRequiredRoles = provider.requiredForIdentityUserTraits?.length > 0;

	let labelColor = 'orange';
	let labelStatus = t('Not enabled');

	if (provider.ssoEnabled) {
		labelColor = 'green';
		labelStatus = t('Enabled');
	}

	let connectionIconColor = styles.palette.orange[500];
	let connectionType = t('Not yet connected');
	let connectionIconVariant: MaterialSymbolVariant = 'warning';

	if (provider.connectionActive) {
		connectionIconColor = styles.palette.green[400];
		connectionType = provider.connectionType;
		connectionIconVariant = 'check_circle';
	}

	let roles = '';

	if (hasRequiredRoles) {
		roles = provider.requiredForIdentityUserTraits
			.map((r) => models.identityProvider.userTraitToTranslatedString(r))
			.join(', ');
	}

	return (
		<IdentityProviderObserver
			providerId={provider.id}
			replaceRecord={replaceRecord}
			removeRecord={removeRecord}>
			<Column>
				<Card.Base>
					<Card.Header>
						<Card.Heading $large>
							<MaterialSymbol actualSize scale={1.4} variant="passkey" />
							{provider.displayName}
						</Card.Heading>
						<Context.Menu toggleWith={<ButtonTrigger icon="more_horiz" />}>
							<Context.LinkItem
								href={routes.Sso.Edit(organization.id, provider.id)}>
								<Context.ItemIcon name="settings" />
								{t('Edit settings')}
							</Context.LinkItem>
							<Context.Item onClick={handleManageConnection}>
								<Context.ItemIcon name="open_in_new" />
								{t('Manage connection')}
							</Context.Item>
							<Context.Divider />
							<Context.ConfirmItem
								caution
								message={t(
									'Are you sure you want to remove this identity provider?'
								)}
								onConfirm={handleRemove}>
								<Context.ItemIcon name="delete" />
								{t('Delete')}
							</Context.ConfirmItem>
						</Context.Menu>
					</Card.Header>
					<Card.Divider />
					<Card.Body>
						<CardRow label={t('Provider')}>
							<Row
								align="center"
								spacing={styles.spacing._3}
								autoColumns="max-content">
								<MaterialSymbol
									fill={connectionIconColor}
									scale={1.6}
									actualSize
									variant={connectionIconVariant}
								/>
								{connectionType}
							</Row>
						</CardRow>
						<CardRow label={t('Status')}>
							<div>
								<Label color={labelColor}>{labelStatus}</Label>
							</div>
						</CardRow>
						{hasRequiredRoles && <CardRow label={t('Roles')}>{roles}</CardRow>}

						<DirectorySync organization={organization} provider={provider} />
					</Card.Body>
				</Card.Base>
			</Column>
		</IdentityProviderObserver>
	);
}

interface DirectorySyncProps {
	organization: models.group.Group;
	provider: models.identityProvider.IdentityProvider;
}

function DirectorySync({
	organization,
	provider,
}: DirectorySyncProps): JSX.Element {
	const t = useT();

	const hasDirectorySync = models.group.hasFeature(
		organization,
		models.group.Features.DirectorySync
	);

	const isDirectorySyncActive = provider.directoryActive === true;

	let directorySyncIconColor = styles.palette.orange[500];
	let directorySyncIconVariant: MaterialSymbolVariant = 'warning';

	if (provider.directoryActive) {
		directorySyncIconColor = styles.palette.green[400];
		directorySyncIconVariant = 'check_circle';
	}

	const handleManageDirectorySync = async () => {
		const [canOpen, response] =
			await actions.identityProviders.getPortalSyncUrl(
				organization.id,
				provider.id
			);

		if (canOpen) {
			window.open(response.url, '_blank');
		}
	};

	const handleEnableDirectorySync = useDialog({
		wide: true,
		title: t('Enable directory sync', { _context: 'sso' }),
		message: t(
			'Enabling Directory Sync will synchronize your data from your directory and overwrite any information connected in 360Player.'
		),
		confirmLabel: t('Proceed'),
		onConfirm: async () => {
			const [syncRequest] = await actions.identityProviders.sync(
				organization.id,
				provider.id,
				true
			);

			if (syncRequest.ok) {
				actions.flashes.show({
					title: t('Enabled Directory Sync'),
					message: t('Enabled directory sync for {directoryType}', {
						directoryType: provider.directoryType,
					}),
				});
			} else {
				actions.flashes.show(
					{
						title: t('Something went wrong'),
						message: t('Could not enable directory sync for {directoryType}', {
							directoryType: provider.directoryType,
						}),
					},
					syncRequest.status
				);
			}
		},
	});

	const handleDisableDirectorySync = async () => {
		const [syncRequest] = await actions.identityProviders.sync(
			organization.id,
			provider.id,
			false
		);

		if (syncRequest.ok) {
			actions.flashes.show({
				title: t('Disabled Directory Sync'),
				message: t('Disabled directory sync for {directoryType}', {
					directoryType: provider.directoryType,
				}),
			});
		} else {
			actions.flashes.show(
				{
					title: t('Something went wrong'),
					message: t('Could not disable directory sync for {directoryType}', {
						directoryType: provider.directoryType,
					}),
				},
				syncRequest.status
			);
		}
	};

	if (!hasDirectorySync) {
		return null;
	}

	let content: ReactNode;

	if (!isDirectorySyncActive) {
		content = (
			<Button
				block
				secondary
				icon="sync_lock"
				disabled={!provider.connectionActive}
				onClick={handleManageDirectorySync}>
				{t('Configure directory sync')}
			</Button>
		);
	} else {
		content = (
			<Row columns="auto 1fr auto" align="center">
				<MaterialSymbol
					fill={directorySyncIconColor}
					scale={1.6}
					actualSize
					variant={directorySyncIconVariant}
				/>
				<p>{provider.directoryType}</p>
				<Context.Menu
					toggleWith={
						<Button small icon="edit">
							{t('Edit')}
						</Button>
					}>
					{provider.syncEnabled ? (
						<Context.Item onClick={handleDisableDirectorySync}>
							<Context.ItemIcon name="sync_disabled" />
							{t('Disable directory sync', { _context: 'sso' })}
						</Context.Item>
					) : (
						<Context.Item onClick={handleEnableDirectorySync}>
							<Context.ItemIcon name="sync" />
							{t('Enable directory sync', { _context: 'sso' })}
						</Context.Item>
					)}
					<Context.LinkItem
						href={routes.Sso.AttributeMapping(organization.id, provider.id)}>
						<Context.ItemIcon name="rebase_edit" />
						{t('Edit attribute mappings')}
					</Context.LinkItem>
					<Context.Divider />
					<Context.Item onClick={handleManageDirectorySync}>
						<Context.ItemIcon name="open_in_new" />
						{t('Manage directory')}
					</Context.Item>
				</Context.Menu>
			</Row>
		);
	}

	return (
		<CardRow hideDivider label={t('Directory sync', { _context: 'sso' })}>
			{content}
		</CardRow>
	);
}
