import { useState } from 'react';
import { t } from '@transifex/native';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { CreateFormPayload } from 'pkg/api/endpoints/forms';
import { FormProductPrice, Statuses } from 'pkg/api/models/form';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';
import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';

import useFormEngine from 'routes/forms/hooks/useFormEngine';
import SuccessView from 'routes/forms/SuccessView';

import { Spinner } from 'components/loaders/spinner';
import FormComponent, { asString, FormPayload } from 'components/form/Form';

interface DuplicateFormProps {
	groupId: number;
	formId: number;
}

const DuplicateForm: React.FC<React.PropsWithChildren<DuplicateFormProps>> = ({
	groupId,
	formId,
}) => {
	const org = useCurrentOrganization();
	const [createdForm, setCreatedForm] = useState<models.form.Form>(
		{} as models.form.Form
	);
	const providerSettings = usePaymentProviderContext();

	const { record: form, isLoading } = useEndpoint<models.form.Form>(
		endpoints.Forms.Show(formId)
	);

	const { formData, FormContent } = useFormEngine({ prevData: form });

	const handleSave = async (data: FormPayload) => {
		// add all the props from a field and productprice, except the ID
		const payload: CreateFormPayload = {
			groupId,
			title: asString(data.title),
			description: data.description as string,
			status: data.status as Statuses,
			forceAuth: data.forceAuth as boolean,
			fields: formData.fields.map(
				(field: models.form.Field, index: number) => ({
					key: field.key,
					type: field.type,
					label: field.label,
					description: field.description,
					values: field.values,
					sortOrder: index,
					required: field.required,
					deletedAt: field.deletedAt,
					userFieldId: field.userFieldId,
				})
			),
			visibility: data.visibility as string,
			submissionEmailEnabled: formData.submissionEmailEnabled,
			submissionEmailSubject: formData.submissionEmailSubject,
			submissionEmailContent: formData.submissionEmailContent,
			productPrices: [],
		};

		if (data.maxSubmissions) {
			payload.maxSubmissions = Number.parseInt(
				data.maxSubmissions as string,
				10
			);
		}

		if (data.formCategoryId) {
			payload.formCategoryId = Number.parseInt(
				data.formCategoryId as string,
				10
			);
		}

		if (Object.keys(formData.attachment).length) {
			payload.attachmentId = formData.attachment.id;
		}

		formData.priceOptions.forEach((priceOption, index) => {
			const newProductPrices: FormProductPrice[] =
				priceOption.productPrices.map((productPrice) => {
					const productInfo = formData.selectedProductsInfo.find(
						(prodInfo) => prodInfo.product.id === productPrice.product.id
					);

					const formProductPrice: FormProductPrice = {
						productPriceId: productPrice.id,
						minQuantity: productInfo.minQuantity,
						maxQuantity: productInfo.maxQuantity,
						required: productInfo.required,
						validTo: productInfo.validTo,
						bundleNumber: index + 1,
						installmentCount: priceOption.installmentCount,
					};

					if (providerSettings.settings.canHaveDelayedSubscriptionStart) {
						if (priceOption.subscriptionStartAt) {
							formProductPrice.subscriptionStartAt =
								priceOption.subscriptionStartAt;
							formProductPrice.payFirstOrderNow = true;
						}
					}

					if (productInfo.taxRateId) {
						formProductPrice.taxRateId = productInfo.taxRateId;
					}

					return formProductPrice;
				});

			payload.productPrices = [...payload.productPrices, ...newProductPrices];
		});

		const [, form] = await models.create<CreateFormPayload, models.form.Form>(
			endpoints.Forms.Create(),
			payload
		);

		setCreatedForm(form);
	};

	const handlePublish = async () => {
		actions.forms.update(createdForm.id, {
			status: Statuses.Open,
			fields: createdForm.fields,
			title: createdForm.title,
		});

		actions.flashes.show(
			{
				title: t('Published!'),
				message: t('Registration published successfully'),
			},
			200,
			'check-circle'
		);

		replaceState(routes.Registrations.Index(org.id, groupId, 'active'));
	};

	if (createdForm.id) {
		return (
			<SuccessView
				groupId={groupId}
				form={createdForm}
				isEdit
				handlePublish={handlePublish}
			/>
		);
	}

	if (isLoading) {
		return <Spinner />;
	}

	return <FormComponent onSubmit={handleSave}>{FormContent}</FormComponent>;
};

export default DuplicateForm;
