import styled from 'styled-components';
import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import { useCurrentAccount, useFetchIdentity } from 'pkg/identity';
import * as models from 'pkg/api/models';

import {
	Group,
	ItemColumn,
} from 'routes/account/settings/child-relations/styles';
import ConnectionModal from 'routes/account/settings/ConnectionModal';

import * as Card from 'components/Card';
import Heading from 'components/Heading';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import JoinGroupModal from 'components/group/join-modal';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

const GetStarted = styled(Column)`
	margin-bottom: var(--spacing-3);
`;

interface CreateRelationsProps {
	refreshRelations: () => Promise<void>;
}

export default function CreateRelations({
	refreshRelations,
}: CreateRelationsProps): JSX.Element {
	const columnSizes = '1fr 200px';
	const fetchIdentity = useFetchIdentity();

	const externalLink =
		'https://help.360player.com/article/83-getting-started-as-a-parent';

	const account = useCurrentAccount();

	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

	const openCreateModal = () => setCreateModalOpen(true);

	const closeCreateModal = async () => {
		refreshRelations();
		await fetchIdentity();
		setCreateModalOpen(false);
	};

	const [connectionModalOpen, setConnectionModalOpen] =
		useState<boolean>(false);

	const openConnectionModal = () => setConnectionModalOpen(true);
	const closeConnectionModal = () => setConnectionModalOpen(false);

	if (!models.account.isAnAdult(account)) {
		return null;
	}

	return (
		<Fragment>
			<Group>
				<GetStarted>
					<Heading>{t('Add more children or wards')}</Heading>
					<p>
						{t(
							`With 360Player a legal guardian or parent can easily follow their child or ward's development and activities.`
						)}
					</p>
					<Link href={externalLink}>
						{t('Get started as a parent or legal guardian')}{' '}
						<Icon name="new-window" size={1.3} />
					</Link>
				</GetStarted>

				<Card.Base $noBorder>
					<Card.Body $narrowBody>
						<LargeScreen>
							<Row
								columns={columnSizes}
								align="center"
								spacing={styles.spacing._7}>
								<ItemColumn spacing={styles.spacing._2}>
									<strong>{t('Create a new account for your child')}</strong>
									<span>
										{t(
											`If your child doesn't already have an account, you can create one for them here.`
										)}
									</span>
								</ItemColumn>
								<Button
									primary
									block
									icon="person_add"
									onClick={openCreateModal}>
									{t('Create account')}
								</Button>
							</Row>
						</LargeScreen>
						<SmallScreen>
							<Column>
								<ItemColumn spacing={styles.spacing._2}>
									<strong>{t('Create a new account for your child')}</strong>
									<span>
										{t(
											`If your child doesn't already have an account, you can create one for them here.`
										)}
									</span>
								</ItemColumn>
								<Button
									primary
									block
									icon="person_add"
									onClick={openCreateModal}>
									{t('Create account')}
								</Button>
							</Column>
						</SmallScreen>
					</Card.Body>
				</Card.Base>

				<Card.Base $noBorder>
					<Card.Body $narrowBody>
						<LargeScreen>
							<Row
								columns={columnSizes}
								align="center"
								spacing={styles.spacing._7}>
								<ItemColumn spacing={styles.spacing._1}>
									<strong>{t('Connect with an existing account')}</strong>
									<span>
										{t(
											'If your child already has an account you can create a connection by entering their email address.'
										)}
									</span>
								</ItemColumn>
								<Button
									block
									secondary
									icon="supervisor_account"
									onClick={openConnectionModal}>
									{t('Create connection')}
								</Button>
							</Row>
						</LargeScreen>
						<SmallScreen>
							<Column>
								<ItemColumn spacing={styles.spacing._1}>
									<strong>{t('Connect with an existing account')}</strong>
									<span>
										{t(
											'If your child already has an account you can create a connection by entering their email address.'
										)}
									</span>
								</ItemColumn>
								<Button
									block
									secondary
									icon="supervisor_account"
									onClick={openConnectionModal}>
									{t('Create connection')}
								</Button>
							</Column>
						</SmallScreen>
					</Card.Body>
				</Card.Base>
			</Group>
			{createModalOpen && (
				<JoinGroupModal onlyAccountCreate onClose={closeCreateModal} />
			)}
			{connectionModalOpen && (
				<ConnectionModal
					onClose={closeConnectionModal}
					onSave={refreshRelations}
				/>
			)}
		</Fragment>
	);
}
