import {
	JSX,
	Fragment,
	MouseEvent,
	PointerEvent,
	TouchEvent,
	useRef,
	useState,
} from 'react';

import * as styles from 'pkg/config/styles';

import useLongTouchPress from 'pkg/hooks/useLongTouchPress';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';
import { fromSlug, toSlug } from 'pkg/emoji';
import * as numbers from 'pkg/numbers';
import useTooltip from 'pkg/hooks/useTooltip';
import { delay } from 'pkg/timings';
import * as objects from 'pkg/objects';

import { Emoji } from 'components/emoji';
import Icon from 'components/icon';
import {
	LargeScreen,
	useLargeScreen,
	useSmallScreen,
} from 'components/MediaQuery';
import Avatar from 'components/avatar';

import EmojiPicker from 'components/emoji/picker';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface GroupedChatReactions {
	[key: string]: models.chatReaction.ChatReaction[];
}

interface ReactionGroup {
	maxId: number;
	emojiSlug: string;
	count: number;
	userIds: number[];
	users: models.user.User[];
	reactions: models.chatReaction.ChatReaction[];
}

export function groupBySlug(
	reactions: models.chatReaction.ChatReaction[]
): ReactionGroup[] {
	const groups: ReactionGroup[] = [];
	const grouped: GroupedChatReactions = {};

	if (!reactions?.length) return [];

	reactions.forEach((reaction: models.chatReaction.ChatReaction) => {
		if (!grouped.hasOwnProperty(reaction.emojiSlug)) {
			grouped[reaction.emojiSlug] = [];
		}

		grouped[reaction.emojiSlug].push(reaction);
	});

	Object.values(grouped).forEach(
		(reactions: models.chatReaction.ChatReaction[]) => {
			const first: models.chatReaction.ChatReaction = reactions[0];
			const maxId = Math.max(
				...reactions.map((r: models.chatReaction.ChatReaction) => r.id)
			);

			const users = objects.unique<models.user.User>(
				reactions.map(
					(reaction: models.chatReaction.ChatReaction) => reaction.user
				),
				'id'
			);

			const userIds = users.map((user: models.user.User) => user.id);

			groups.push({
				emojiSlug: first.emojiSlug,
				count: users.length,
				users,
				userIds,
				maxId,
				reactions,
			});
		}
	);

	return groups.sort(
		(a: ReactionGroup, b: ReactionGroup) =>
			a.reactions[0].id - b.reactions[0].id
	);
}

export function findBySlugAndUserId(
	reactions: models.chatReaction.ChatReaction[],
	slug: string,
	userId: number
): models.chatReaction.ChatReaction {
	return reactions?.find(
		(reaction: models.chatReaction.ChatReaction) =>
			reaction.emojiSlug === slug && reaction.userId === userId
	);
}

export interface ReactionPayload {
	slug: string;
	data: string;
	reactionId?: number;
}

interface ReactionsProps {
	reactions?: models.chatReaction.ChatReaction[];
	currentUserId?: number;
	canAddReaction?: boolean;
	pickerOnly?: boolean;
	align?: 'left' | 'center' | 'right';

	onReaction: (payload: ReactionPayload) => void | Promise<void>;
}

export default function Reactions({
	reactions = [],
	currentUserId,
	canAddReaction = true,
	pickerOnly,
	align = 'center',

	onReaction,
}: ReactionsProps): JSX.Element {
	const isSmallScreen = useSmallScreen();
	const grouped = groupBySlug(reactions);

	const handleSelect = async (raw: string) => {
		onReaction({
			slug: toSlug(raw),
			data: raw,
		});

		await delay(100);
		hideTooltip();
	};

	const { showTooltip, hideTooltip, tooltip } = useTooltip(
		<div className={css.reactionPicker}>
			<EmojiPicker onSelect={handleSelect} />
		</div>,
		{
			align,
			persist: true,
			skipHorizontalAlign: isSmallScreen,
			offset: {
				y: -70,
			},
		}
	);

	const handleShowPicker = (event: PointerEvent<HTMLDivElement>) => {
		event.stopPropagation();

		showTooltip(event);
	};

	if (pickerOnly) {
		return <EmojiPicker onSelect={handleSelect} />;
	}

	return (
		<div className={css.reactions} data-align={align}>
			{grouped.map((group: ReactionGroup, index: number) => (
				<Reaction
					key={index}
					emoji={fromSlug(group.emojiSlug)}
					count={group.count}
					users={group.users}
					active={group.userIds.includes(currentUserId)}
					onReaction={onReaction}
				/>
			))}
			{canAddReaction && (
				<div
					className={cssClasses(css.reaction, css.addReaction)}
					onClick={handleShowPicker}>
					<span>
						{tooltip}
						<Icon name="add-reaction" size={1.4} />
					</span>
				</div>
			)}
		</div>
	);
}

interface ReactionItemProps {
	emoji: string;
	count: number;
	active?: boolean;
	large?: boolean;

	onClick: () => void | Promise<void>;
	onMouseEnter?: (event: MouseEvent<HTMLDivElement>) => void;
	onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void;
	onTouchStart?: (event: TouchEvent<HTMLDivElement>) => void;
	onTouchEnd?: (event: TouchEvent<HTMLDivElement>) => void;
}

function ReactionItem({
	emoji,
	count,
	active,
	large,
	onClick,
	onMouseEnter,
	onMouseLeave,
	onTouchStart,
	onTouchEnd,
}: ReactionItemProps): JSX.Element {
	const classNames = cssClasses(
		css.reaction,
		large ? css.large : undefined,
		active ? css.active : undefined
	);

	return (
		<div
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onTouchStart={onTouchStart}
			onTouchEnd={onTouchEnd}
			className={classNames}>
			<Emoji raw={emoji} className={css.emoji} />
			<span className={css.count}>{numbers.compact(count)}</span>
		</div>
	);
}

interface ReactionProps {
	emoji: string;
	count: number;
	active?: boolean;
	users: models.user.User[];

	onReaction?: (payload: ReactionPayload) => void | Promise<void>;
}

export function Reaction({
	emoji,
	count,
	active,
	users,

	onReaction,
}: ReactionProps): JSX.Element {
	const isLargeScreen = useLargeScreen();

	const didFocus = useRef<boolean>(false);
	const [userReactions, showUserReactions] = useState<boolean>(false);

	const handleInteract = async () => {
		if (!onReaction) return;

		await onReaction({
			slug: toSlug(emoji),
			data: emoji,
		});

		hideTooltip();
	};

	const longPressEventHandlers = useLongTouchPress({
		onLongPress: (event: TouchEvent<HTMLElement>) => {
			event?.stopPropagation();

			showUserReactions(true);

			return false;
		},
		onClick: handleInteract,
	});

	const onTooltipFocus = () => {
		didFocus.current = true;
	};

	const onTooltipBlur = async () => {
		await delay(100);

		if (!didFocus.current) {
			hideTooltip();
		}
	};

	const { showTooltip, hideTooltip, tooltip } = useTooltip(
		<UserReactions
			emoji={emoji}
			count={count}
			active={active}
			users={users}
			onClick={isLargeScreen ? handleInteract : undefined}
		/>,
		{
			className: css.reactionsTooltip,
			hideOnMouseLeave: false,
			onTooltipFocus,
			onTooltipBlur: () => {
				hideTooltip();
			},
			onShow: () => {
				didFocus.current = false;
			},
		}
	);

	return (
		<Fragment>
			<ReactionItem
				emoji={emoji}
				count={count}
				active={active}
				{...longPressEventHandlers}
				onMouseEnter={showTooltip}
				onMouseLeave={onTooltipBlur}
				onClick={isLargeScreen ? handleInteract : undefined}
			/>
			{userReactions && (
				<UserReactions
					emoji={emoji}
					count={count}
					active={active}
					users={users}
					onClick={handleInteract}
					onClose={() => showUserReactions(false)}
				/>
			)}
			<LargeScreen>{tooltip}</LargeScreen>
		</Fragment>
	);
}

interface UserReactionsProps {
	users: models.user.User[];
	emoji: string;
	count: number;
	active: boolean;

	onClick?: () => void;
	onClose?: () => void;
	onMouseLeave?: () => void;
}

function UserReactions({
	users,
	emoji,
	count,
	active,

	onClick,
	onClose,
	onMouseLeave,
}: UserReactionsProps): JSX.Element {
	const isSmallScreen = useSmallScreen();

	const reaction = (
		<ReactionItem
			large
			emoji={emoji}
			count={count}
			active={active}
			onClick={onClick}
		/>
	);

	const list = (
		<Column>
			{isSmallScreen && reaction}
			{users.map((user: models.user.User) => (
				<Row
					key={user.id}
					align="center"
					columns={isSmallScreen ? '24px 1fr' : '16px 1fr'}
					spacing={isSmallScreen ? styles.spacing._3 : styles.spacing._2}>
					<Avatar user={user} />
					<span>{models.user.fullName(user)}</span>
				</Row>
			))}
		</Column>
	);

	if (isSmallScreen) {
		return (
			<Context.Menu defaultOpen onClose={onClose}>
				<Context.Pane>{list}</Context.Pane>
			</Context.Menu>
		);
	}

	return (
		<div onMouseLeave={onMouseLeave} className={css.userReactions}>
			{list}
		</div>
	);
}
