import styled from 'styled-components';
import { JSX, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import { VideoSequenceRecordingId } from 'pkg/video';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import { formatTime } from 'pkg/timeline';
import { popState } from 'pkg/router/state';

import EditForm from 'routes/video/shared/forms/Edit';
import {
	useClipActions,
	useClipChanges,
} from 'routes/video/shared/hooks/clip-actions';
import Record from 'routes/video/analyze/Record';
import UserTags from 'routes/video/shared/forms/UserTags';

import { SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import Modal, { Step } from 'components/step-modal';

import { useCueState } from 'components/video-analytics/CueState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useClipState } from 'components/video-analytics/ClipState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';

import * as ContextMenu from 'design/context_menu';

const Wrapper = styled.div`
	--header-size: 50px;
	--footer-size: 100px;

	--header-height: calc(var(--sat) + var(--header-size));
	--footer-height: calc(var(--sab) + var(--footer-size));
	--content-height: calc(100% - var(--header-height) - var(--footer-height));

	height: 100%;
	display: grid;
	grid-template-rows:
		var(--header-height)
		var(--content-height)
		var(--footer-height);
	align-items: start;
	grid-area: content;
`;

const Header = styled.div`
	padding-top: var(--sat);
	height: 100%;
	display: grid;
	grid-template-columns: var(--header-size) 1fr var(--header-size);
	place-items: center;
	color: var(--palette-white);
`;

const HeaderAction = styled.div``;

const Footer = styled.div`
	padding-bottom: var(--sab);
	height: 100%;
	display: grid;
	grid-auto-rows: 50px 50px;
`;

const FooterActions = styled.div``;

const Controls = styled.div``;

const Content = styled.div`
	height: 100%;
`;

const Video = styled.div`
	width: 100vw;
	height: calc(100vw * 9 / 16);
`;

const Workspace = styled.div`
	height: calc(100% - calc(100vw * 9 / 16));
	background: var(--palette-gray-800);
`;

interface SmallScreenAnalyzeLayoutProps {
	bootstrap: ReactNode;
	videoSource: ReactNode;
	currentClip: ReactNode;
	controls: ReactNode;
	contextItems?: ReactNode;
}

export default function SmallScreenAnalyzeLayout({
	bootstrap,
	videoSource,
	currentClip,
	controls,
	contextItems,
}: SmallScreenAnalyzeLayoutProps): JSX.Element {
	const {
		isEditing,
		setEditing,
		isRecording,
		setRecording,
		setTrimming,
		duration,
	} = usePlayerState();

	const { currentTime } = usePlaybackState();
	const { controller } = usePlayerState();
	const { currentCue } = useCueState();
	const clipState = useClipState();

	const clipId = isRecording
		? VideoSequenceRecordingId
		: (currentCue?.cueId as number);

	const { cancel, destroy } = useClipActions(clipId);
	const { hasChanges, save } = useClipChanges(clipId);

	const cue = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, clipId)
	);

	const comments = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findAllComments(state, clipId)
	);

	useEffect(() => {
		if (comments) {
			clipState.setTaggedUsers(comments);
		}
	}, [clipId]);

	const onModalOpen = () => {
		controller.pause();

		if (cue) {
			clipState.reset({ ...(cue.toJS() as any), taggedUsers: comments });
		}
	};

	const onModalClose = () => {
		setEditing(false);
		setTrimming(false);
		setRecording(false);

		controller.play();

		cancel();
	};

	const confirmUnsavedChanges = (): boolean => {
		if (hasChanges && !isRecording) {
			return confirm(
				t('Unsaved changes will be lost, do you want to continue?')
			);
		}

		return true;
	};

	const modalTitle = isRecording ? t('Save as…') : t('Edit clip');

	const exitAnalytics = () => {
		popState();
	};

	return (
		<SmallScreen>
			{bootstrap}
			<Wrapper>
				<Header>
					<HeaderAction onClick={exitAnalytics}>
						<Icon name="close" size={1.2} />
					</HeaderAction>
					<span>
						{formatTime(currentTime)} / {formatTime(duration)}
					</span>
					<HeaderAction>
						{contextItems && (
							<ContextMenu.Menu
								toggleWith={<Icon name="nav-settings" size={1.6} />}>
								{contextItems}
							</ContextMenu.Menu>
						)}
					</HeaderAction>
				</Header>
				<Content>
					<Video>{videoSource}</Video>
					<Workspace>{currentClip}</Workspace>
				</Content>
				<Footer>
					{!isEditing && (
						<FooterActions>
							<Record save={save} cancel={cancel} />
						</FooterActions>
					)}
					<Controls>{controls}</Controls>
				</Footer>
			</Wrapper>
			{isEditing && (
				<Modal
					onOpen={onModalOpen}
					onBeforeClose={confirmUnsavedChanges}
					onClose={onModalClose}>
					<Step
						slug="edit"
						title={modalTitle}
						closeOnNext
						nextLabel={t('Save')}
						canGoNext={hasChanges}
						onNext={save}>
						<EditForm
							hasChanges={hasChanges}
							save={save}
							cancel={cancel}
							destroy={destroy}
						/>
					</Step>
					<Step
						slug="tags"
						nextSlug="edit"
						hideNext
						canClose={false}
						title={modalTitle}
						skipBody>
						<UserTags />
					</Step>
				</Modal>
			)}
		</SmallScreen>
	);
}
