import { t } from '@transifex/native';
import { JSX, ChangeEvent, useEffect } from 'react';

import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as api from 'pkg/api';
import { useCurrentAccount, useFetchIdentity } from 'pkg/identity';
import { CreateChildPayload } from 'pkg/api/endpoints/accounts';

import SectionTitle from 'components/SectionTitle';
import SectionDescription from 'components/SectionDescription';
import { useStepModalContext } from 'components/step-modal';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import {
	flashErrorMessage,
	useCreateChildAccount,
	useJoinModalContext,
} from 'components/group/join-modal';

export default function Credentials(): JSX.Element {
	const joinContext = useJoinModalContext();
	const modalContext = useStepModalContext();
	const currentAccount = useCurrentAccount();
	const fetchIdentity = useFetchIdentity();

	const createAccount = useCreateChildAccount(currentAccount);

	modalContext.withNext(async () => {
		const [request, info] = await api.get<models.onboardingInfo.Group>(
			endpoints.Accounts.OnboardingInfo() +
				`?invite_code=${joinContext.groupCode}`
		);

		if (request.ok) {
			joinContext.set('connectionInfo', info);
		} else {
			flashErrorMessage(request.status);
			return Promise.resolve(false);
		}

		const { statusCode, membership, account } = await createAccount(
			joinContext.getCreatePayload()
		);

		if (statusCode >= 400) {
			flashErrorMessage(statusCode);
			return Promise.resolve(false);
		}

		// @NOTE Joining an org does not return a valid membership
		if (!membership?.hasOwnProperty('message')) {
			joinContext.set('membership', membership);
		}

		joinContext.set('targetAccount', account);

		await fetchIdentity();

		return Promise.resolve(true);
	});

	const get = (key: keyof CreateChildPayload): string => {
		let value = joinContext.createPayload[key] || '';

		if (typeof value !== 'string') {
			value = value.toString();
		}

		return value;
	};

	const set = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.currentTarget.name;
		const value: string = event.currentTarget.value;

		joinContext.setCreatePayload({ [name]: value });
	};

	const canContinue =
		get('password') &&
		get('password') === get('passwordConfirmation') &&
		get('email') &&
		get('email') === get('emailConfirmation');

	useEffect(() => {
		modalContext.setCanGoNext(canContinue);
	}, [canContinue]);

	return (
		<Column spacing={styles.spacing._7}>
			<Column spacing={styles.spacing._1}>
				<SectionTitle>
					{t('Provide login credentials for your child')}
				</SectionTitle>
				<SectionDescription>
					{t('Your child will be able to log in with these credentials.')}
				</SectionDescription>
			</Column>

			<Column spacing={styles.spacing._7}>
				<Input.Group label={t(`Child's email`)}>
					<Input.Field
						required
						name="email"
						type="email"
						onChange={set}
						value={get('email')}
					/>
				</Input.Group>
				<Input.Group label={t(`Confirm email`)}>
					<Input.Field
						required
						name="emailConfirmation"
						type="email"
						onChange={set}
						value={get('emailConfirmation')}
					/>
				</Input.Group>
			</Column>

			<Column spacing={styles.spacing._7}>
				<Input.Group label={t(`Pick a password`)}>
					<Input.Field
						required
						name="password"
						type="password"
						onChange={set}
						value={get('password')}
					/>
				</Input.Group>
				<Input.Group label={t(`Confirm password`)}>
					<Input.Field
						required
						name="passwordConfirmation"
						type="password"
						onChange={set}
						value={get('passwordConfirmation')}
					/>
				</Input.Group>
			</Column>
		</Column>
	);
}
