import { JSX, Fragment } from 'react';

import { CheckboxFilterSettings, FilterRow } from 'pkg/filters/use_filters';
import { BaseFilterProps } from 'pkg/filters/types';

import * as Input from 'components/form/inputs';

interface OptionRowProps {
	label: string;
	value: string | number;
	checked: boolean;
	setFilterValue: (val: string | number) => void;
}

function OptionRow({
	label,
	value,
	checked,
	setFilterValue,
}: OptionRowProps): JSX.Element {
	const handleSetFilterValue = () => {
		setFilterValue(value);
	};

	return (
		<FilterRow onClick={handleSetFilterValue}>
			<Input.Control
				type="checkbox"
				label={label}
				value={value}
				defaultChecked={checked}
			/>
		</FilterRow>
	);
}

type CheckboxProps = BaseFilterProps;

export function Checkbox({
	filter,
	setFilter,
	currentFilters,
}: CheckboxProps): JSX.Element {
	const settings = (filter.settings || {}) as CheckboxFilterSettings;

	const setFilterValue = (val: string | number) => {
		if (settings.transformValuesToArray) {
			const arrayVal = val as string;
			const finalArrayVal = arrayVal.split(',');
			setFilter(filter, finalArrayVal, true);
		} else {
			setFilter(filter, [val], true);
		}
	};

	return (
		<Fragment>
			{Object.entries(filter.values).map(([label, filterValue]) => {
				let value: string | number;
				switch (typeof filterValue) {
					case 'boolean':
						value = filterValue ? 1 : 0;
						break;
					default:
						value = filterValue;
						break;
				}

				const vals = currentFilters?.[0]?.values || [];
				let checked = vals.includes(value);

				if (settings.transformValuesToArray) {
					const stringValue = value as string;
					const arrayValue = stringValue.split(',');
					checked = arrayValue.every((value) => vals.includes(value));
				}

				return (
					<OptionRow
						key={`${value}:${checked}`}
						setFilterValue={setFilterValue}
						label={label}
						value={value}
						checked={checked}
					/>
				);
			})}
		</Fragment>
	);
}
