import { JSX, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';

import CollectionModal from 'components/video-library/modals/add-to-collection';
import Group, { GroupWrapperProperties } from 'components/library/Group';
import { GroupItemAction } from 'components/library/GroupItem';
import GroupItemButton from 'components/library/GroupItemButton';
import VideoCollectionGroupItem from 'components/library/group-items/VideoCollection';

export default function Collections({
	title,
	items,
	hasNext,
	fetchNext,
	removeRecord,
	replaceRecord,
}: GroupWrapperProperties<models.videoCollection.VideoCollection>): JSX.Element {
	const [collectionId, setCollectionId] = useState<number>(0);

	const handleEdit = (collection: models.videoCollection.VideoCollection) => {
		setCollectionId(collection.id);
	};

	const handleDidSave = (
		collection: models.videoCollection.VideoCollection
	) => {
		replaceRecord(collection);
	};

	const handleDelete = async (
		collection: models.videoCollection.VideoCollection
	) => {
		const success = await models.destroy(collection);

		if (success) {
			removeRecord(collection.id);
		}
	};

	const closeModal = () => setCollectionId(0);

	return (
		<Group title={title}>
			{items.map((item: models.videoCollection.VideoCollection, n: number) => {
				const actions: GroupItemAction<models.videoCollection.VideoCollection>[] =
					[];

				if (models.canEdit(item)) {
					actions.push({
						icon: 'edit',
						label: t('Edit'),
						onClick: handleEdit,
					});
				}

				if (models.canDelete(item)) {
					actions.push({
						icon: 'delete',
						label: t('Delete'),
						confirm: t(
							'Do you want to delete {name}? This action cannot be undone.',
							{
								name: item.name,
							}
						),
						onClick: handleDelete,
					});
				}

				return (
					<VideoCollectionGroupItem
						key={`${item.id}:${n}`}
						item={item}
						actions={actions}
					/>
				);
			})}
			{hasNext && fetchNext && (
				<GroupItemButton label={t('Load more')} onClick={fetchNext} />
			)}
			{collectionId !== 0 && (
				<CollectionModal
					createOnly
					collectionId={collectionId}
					onClose={closeModal}
					afterSave={handleDidSave}
				/>
			)}
		</Group>
	);
}
