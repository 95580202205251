import { JSX, Fragment, ReactNode, Children, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import Account from 'pkg/models/account';
import User from 'pkg/models/user';

import Link from 'pkg/router/Link';
import useConfirm from 'pkg/hooks/useConfirm';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';

import ReplyForm from 'containers/comment/ReplyForm';
import UserLink from 'containers/user/Link';

import { FormattedContent } from 'components/formatted-content';
import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import Button from 'design/button';
import * as Context from 'design/context_menu';

import * as CommentStyles from 'styles/Comment';

const Wrapper = styled.div``;

const ReplyButtonWrapper = styled.div`
	margin-top: var(--spacing-3);
`;

const ShowRepliesLink = styled(Link)`
	width: auto;
	display: inline-block;
	margin-left: var(--spacing-7);
	text-indent: var(--spacing-3);
	font-size: var(--font-size-sm);
	svg {
		transform: scale(1.35) rotate(180deg);
	}
`;

interface CommentProps {
	commentId?: number;
	commentType?: string;

	userId?: number;
	accountId?: number;
	content: string;
	createdAt?: number;

	footerLabel?: string;
	linkUser?: boolean;
	compact?: boolean;

	children?: ReactNode;

	onDelete?: (commentId: number) => void;
	onEdit?: (commentId: number, content: string) => void;
	onReply?: (commentId: number, content: string) => void;
}

export default function Comment({
	commentId,
	commentType,

	userId,
	accountId,
	content,
	createdAt,

	footerLabel,
	linkUser,
	compact,

	children,

	onDelete,
	onEdit,
	onReply,
}: CommentProps): JSX.Element {
	const replyThreshold: number = 3;
	const numChildren: number = Children.toArray(children).length;
	const collapsedComments = Children.toArray(children).slice(0, replyThreshold);
	const numCollapsedComments =
		numChildren > replyThreshold ? numChildren - replyThreshold : 0;

	const initialShowReplies = numCollapsedComments < 1;

	const [showReplyForm, setShowReplyForm] = useState<boolean>(false);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [showReplies, setShowReplies] = useState<boolean>(initialShowReplies);

	const user = useSelector((state: RootState) => {
		if (userId) {
			return selectors.users.find(state, userId);
		} else if (accountId) {
			return selectors.accounts.find(state, accountId);
		}

		return null;
	});

	const activateReplyMode = () => setShowReplyForm(true);

	const activateEditMode = () => setEditMode(true);
	const deactivateEditMode = () => setEditMode(false);

	const handleDelete = useConfirm({
		message: t('Delete this comment? This action cannot be undone.'),
		onConfirm: () => onDelete(commentId),
	});

	const handleReply = (content: string) => {
		onReply(commentId, content);
		setShowReplyForm(false);
	};

	const handleEdit = (content: string) => {
		onEdit(commentId, content);
		setEditMode(false);
	};

	const handleShowReplies = () => setShowReplies(true);

	const CommentWrapper = compact
		? CommentStyles.CompactWrapper
		: CommentStyles.Wrapper;

	if (!user) return null;

	return (
		<Wrapper>
			<CommentWrapper $isEditing={editMode}>
				<CommentStyles.Avatar>
					{userId ? (
						<Avatar user={user as User} />
					) : (
						<Avatar account={user as Account} />
					)}
				</CommentStyles.Avatar>
				<CommentStyles.Container $isEditing={editMode}>
					<div>
						{!editMode ? (
							<FormattedContent
								raw={content}
								maxLength={280}
								prefixWith={
									<Fragment>
										{linkUser ? (
											<UserLink userId={user.id} />
										) : (
											<CommentStyles.UserName>
												{user.fullName}
											</CommentStyles.UserName>
										)}
									</Fragment>
								}
								wrapWith={<CommentStyles.Body />}
							/>
						) : (
							<CommentStyles.Body data-editing={editMode}>
								<ReplyForm isEditing onSend={handleEdit} content={content} />
							</CommentStyles.Body>
						)}
					</div>
					<CommentStyles.Footer>
						{footerLabel && (
							<Fragment>
								<span>{footerLabel}</span>
								{createdAt && <CommentStyles.FooterActionDivider />}
							</Fragment>
						)}
						{createdAt && <RelativeDateTime dateTime={createdAt} />}
						{!editMode ? (
							<Fragment>
								<Fragment>
									{(onEdit || onDelete || commentType) && (
										<Context.Menu
											toggleWith={
												<CommentStyles.FooterContextTrigger>
													<Icon name="context-menu" size={1.25} />
												</CommentStyles.FooterContextTrigger>
											}>
											{onEdit && (
												<Context.Item icon="edit" onClick={activateEditMode}>
													{t('Edit')}
												</Context.Item>
											)}
											{onDelete && (
												<Context.ConfirmItem
													caution
													closeOnClick
													icon="delete"
													message={t('Do you want to delete this comment?')}
													onConfirm={async () => handleDelete()}>
													{t('Delete')}
												</Context.ConfirmItem>
											)}
											<Context.ReportItem
												contentType={commentType}
												contentId={commentId}
											/>
										</Context.Menu>
									)}
								</Fragment>
								{onReply && (
									<Fragment>
										<CommentStyles.FooterActionDivider />
										<CommentStyles.FooterAction onClick={activateReplyMode}>
											{t(`Reply`)}
										</CommentStyles.FooterAction>
									</Fragment>
								)}
							</Fragment>
						) : (
							<Fragment>
								<CommentStyles.FooterActionDivider />
								<CommentStyles.FooterAction
									caution
									onClick={deactivateEditMode}>
									{t('Cancel')}
								</CommentStyles.FooterAction>
							</Fragment>
						)}
					</CommentStyles.Footer>

					{showReplies ? (
						children
					) : (
						<Fragment>
							{collapsedComments}
							{numChildren > 0 && (
								<span>
									<ShowRepliesLink onClick={handleShowReplies}>
										<Icon name="reply" />{' '}
										{t(`{num} more replies`, {
											num: numCollapsedComments,
										})}
									</ShowRepliesLink>
								</span>
							)}
						</Fragment>
					)}

					{showReplies && onReply && !showReplyForm && numChildren >= 1 && (
						<ReplyButtonWrapper>
							<Button
								small
								transparent
								icon="reply"
								onClick={activateReplyMode}>
								{t(`Leave your reply`)}
							</Button>
						</ReplyButtonWrapper>
					)}
					{showReplyForm && onReply && <ReplyForm onSend={handleReply} />}
				</CommentStyles.Container>
			</CommentWrapper>
		</Wrapper>
	);
}
