import { JSX } from 'react';
type Props = {
	className?: string;
	src: string;
};

const assetUrl = window.TS['assetUrl'];

const AssetImage = ({ className, src }: Props): JSX.Element => {
	let url = src;

	if (url.startsWith('/')) {
		url = url.substr(1, url.length);
	}

	if (!url.startsWith('http')) {
		url = assetUrl.endsWith('/') ? `${assetUrl}${url}` : `${assetUrl}/${url}`;
	}

	return <img src={url} className={className} />;
};

export default AssetImage;
