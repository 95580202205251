import { Record } from 'immutable';
import { schema } from 'normalizr';

export const userGoals = new schema.Entity('userGoal');

interface IUserGoal {
	authorId: number;
	accountId: number;
	category: string;
	completedAt?: number;
	createdAt: number;
	description: string;
	goal: string;
	id: number;
	links?: { [key: string]: string };
	progress: number;
	updatedAt: number;
	userId: number;
}

const userGoalProps: IUserGoal = {
	authorId: 0,
	accountId: 0,
	category: '',
	completedAt: null,
	createdAt: 0,
	description: '',
	goal: '',
	id: 0,
	links: {},
	progress: 0,
	updatedAt: 0,
	userId: 0,
};

class UserGoal
	extends Record(userGoalProps, 'UserGoalsRecord')
	implements IUserGoal
{
	static normalizr(): schema.Entity {
		return userGoals;
	}
}

export default UserGoal;
