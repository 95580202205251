import { JSX } from 'react';
import { t } from '@transifex/native';

import User from 'pkg/models/user';

import { ISO3166Alpha2Codes } from 'pkg/countries';
import { useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { translatedSexString } from 'pkg/api/models/user';

import DataRow from 'containers/user/profile/info-contact/DataRow';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import { DataRowProps } from 'components/user-address/DataList';

interface BaseInformationProps {
	user: User;
}

export default function BaseInformation({
	user,
}: BaseInformationProps): JSX.Element {
	const organization = useCurrentOrganization();

	const labels = {
		name: t(`Name`),
		age: t(`Age`),
		birthDate: t(`Birth date`),
		sex: t(`Gender`),
		height: t(`Height`),
		nationality: t(`Nationality`),
		lokSwedishPersonalId: t(`Personal ID Number`),
	};

	const rows: DataRowProps[] = [];

	// Name
	rows.push({
		label: labels.name,
		value: user.fullName,
	});

	// Age and birth date
	if (user.birthDate) {
		rows.push({
			label: labels.age,
			value: user.age,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.age,
		});
	}

	if (user.birthDate) {
		rows.push({
			label: labels.birthDate,
			value: user.birthDate,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.birthDate,
		});
	}

	// Gender
	rows.push({
		label: labels.sex,
		value: translatedSexString(user.sex),
	});

	// Height
	if (user.height) {
		rows.push({
			label: labels.height,
			value: user.height,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.height,
		});
	}

	// Nationality
	if (user.nationality) {
		rows.push({
			label: labels.nationality,
			value: ISO3166Alpha2Codes().find((c) => c.code === user.nationality)
				.nationality,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.nationality,
		});
	}

	// Personal ID
	if (models.group.isLOKActive(organization)) {
		if (user.lokSwedishPersonalId) {
			rows.push({
				label: labels.lokSwedishPersonalId,
				value: user.lokSwedishPersonalId,
			});
		} else if (!user.isScrubbed()) {
			rows.push({
				label: labels.lokSwedishPersonalId,
			});
		}
	}

	if (rows.length === 0) {
		return null;
	}

	return (
		<Column>
			<SectionTitle icon="person">{t(`Base information`)}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					{rows.map((row, i) => (
						<DataRow key={i} label={row.label} value={row.value} />
					))}
				</Card.Body>
			</Card.Base>
		</Column>
	);
}
