import { t } from '@transifex/native';
import { JSX, useState } from 'react';

import * as styles from 'pkg/config/styles';

import copyString from 'pkg/copyString';

import Icon from 'components/icon';

import * as Inputs from 'components/form/inputs';
import Row from 'components/layout/row';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface InviteLinkGeneratedStepProps {
	inviteKey: string;
	multipleChildren: boolean;
}

export default function InviteLinkGeneratedStep({
	inviteKey,
	multipleChildren,
}: InviteLinkGeneratedStepProps): JSX.Element {
	const [didCopyInviteLink, setDidCopyInviteLink] = useState(false);

	const inviteLink = `https://app.360Player.com/claim/${inviteKey}`;

	const handleCopy = () => {
		copyString(inviteLink);
		setDidCopyInviteLink(true);
	};

	const infoText = multipleChildren
		? t(
				'Anyone with this private link can connect to your children. This link can only be used once.'
			)
		: t(
				'Anyone with this private link can connect to your child. This link can only be used once.'
			);

	return (
		<Column spacing={styles.spacing._7}>
			<InfoBox color="orange">{infoText}</InfoBox>
			<Column>
				<Row align="center" columns="1fr">
					<Inputs.Field value={inviteLink} readOnly>
						<Inputs.Suffix interactable>
							<span onClick={handleCopy}>{t('Copy invite link')}</span>
						</Inputs.Suffix>
					</Inputs.Field>
				</Row>
				{didCopyInviteLink && (
					<div className={css.inviteLinkCopied}>
						<Icon name="check" /> {t('Invite link copied!')}
					</div>
				)}
			</Column>
		</Column>
	);
}
