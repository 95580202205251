import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import { crash } from 'pkg/errors/errors';
import { CreateSubscriptionPayload } from 'pkg/api/endpoints/subscriptions';
import * as sdk from 'pkg/core/sdk';
import * as models from 'pkg/api/models';

import { Event, trackEvent } from 'components/analytics';

export const createBatch = async (
	payload: CreateSubscriptionPayload
): Promise<Response> => {
	const [request, , error] = await models.create<
		CreateSubscriptionPayload,
		models.subscription.Subscription[]
	>(endpoints.Subscriptions.CreateBatch(), payload);

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
			message: error.error,
		});

		return request;
	}

	return request;
};

export interface CancelSubscriptionPayload {
	ids: number[];
	atPeriodEnd?: boolean;
	date?: number;
}

export const cancel = async (
	payload: CancelSubscriptionPayload
): Promise<boolean> => {
	const request = await sdk.post(endpoints.Subscriptions.Cancel(), {}, payload);
	const response = await request.json();

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
			message: response.error,
		});

		return false;
	}

	flashActions.show({
		title: t(`Successfully cancelled subscription(s)`),
	});

	trackEvent(Event.SubscriptionCancel);

	return true;
};

interface SubscriptionRow {
	priceId: number;
	quantity: number;
	taxRateId: number;
	validTo?: number;
}

export interface UpdateSubscriptionPayload {
	userIds?: number[];
	rows?: SubscriptionRow[];
	collectionMethod?: 'automatic' | 'manual';
	daysUntilDue?: number;
	installmentCount?: number;
	discountId?: number;
}

export const update = async (
	id: number,
	payload: UpdateSubscriptionPayload
): Promise<boolean> => {
	const request = await sdk.patch(
		endpoints.Subscriptions.Update(id),
		{},
		payload
	);
	const response = await request.json();

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
			message: response.error,
		});

		return false;
	}

	return true;
};

export const setPaymentMethod = async (
	subscriptionId: number,
	paymentMethodId: number
): Promise<boolean> => {
	const request = await sdk.post(
		endpoints.Subscriptions.SetPaymentMethod(subscriptionId),
		{},
		{ payment_method_id: paymentMethodId }
	);
	const response = await request.json();

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
			message: response.error,
		});

		return false;
	}

	return true;
};
