import { t } from '@transifex/native';
import { JSX, Fragment, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';

import { ItemColumn } from 'routes/account/settings/child-relations/styles';

import Badge from 'components/Badge';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Row from 'components/layout/row';
import { MissingInformation } from 'components/user/missing-information';
import Column from 'components/layout/column';

import Button from 'design/button';

interface GroupItemProps {
	user: models.user.User;
}

export default function GroupItem({ user }: GroupItemProps): JSX.Element {
	const [showMissingInformationModal, setShowMissingInformationModal] =
		useState(false);

	const organization = user.organization;

	const createdAt = new DateTime(new Date(user.createdAt * 1000));

	const isMissingInformation = models.user.isMissingRequiredInformation(user);

	return (
		<Fragment>
			<Column>
				<Row columns="35px 1fr auto auto" align="center">
					<Badge badgeUrl={organization.profileImageUrl} />
					<ItemColumn spacing={styles.spacing._1} isGroup>
						<strong>{organization.name}</strong>
						<span>
							{t('Since {date}', {
								date: createdAt.toLocaleDateString(),
							})}
						</span>
					</ItemColumn>
					<span>
						<LargeScreen>
							{isMissingInformation && (
								<Button
									label={t('Complete profile')}
									onClick={() => setShowMissingInformationModal(true)}
									small
									primary
									icon="edit"
								/>
							)}
						</LargeScreen>
					</span>
					<LargeScreen>
						<Button
							label={t('Edit contact information')}
							href={routes.User.Settings(user.organizationId, user.id)}
							small
							secondary
							icon="edit"
						/>
					</LargeScreen>
					<SmallScreen>
						<Button
							href={routes.User.Settings(user.organizationId, user.id)}
							small
							secondary
							icon="edit"
						/>
					</SmallScreen>
				</Row>
				<SmallScreen>
					{isMissingInformation && (
						<Button
							label={t('Complete profile')}
							onClick={() => setShowMissingInformationModal(true)}
							primary
							icon="edit"
						/>
					)}
				</SmallScreen>
			</Column>

			{showMissingInformationModal && (
				<MissingInformation
					targetUser={user as unknown as models.user.User}
					modal
					onClose={() => setShowMissingInformationModal(false)}
				/>
			)}
		</Fragment>
	);
}
