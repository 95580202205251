import React, {
	Fragment,
	cloneElement,
	useMemo,
	ReactElement,
	ReactNode,
	JSX,
} from 'react';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { ApiError } from 'pkg/errors/errors';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentRoute, usePreviousRoutePath } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';

import UserHeader from 'routes/organization/user-profile/user-header';
import { UserActions } from 'routes/organization/user-profile/user-actions';
import UserDetails from 'routes/organization/user-profile/user-details';
import UserTimestamps from 'routes/organization/user-profile/UserTimestamps';
import BrandedHeaderLayout from 'routes/organization/user-profile/layout/BrandedHeaderLayout';
import CustomCategories from 'routes/organization/user-profile/custom-categories';
import BillingContact from 'routes/organization/user-profile/billing-contact';
import UserProfileOverview from 'routes/organization/user-profile/overview';
import UserProfileCalendar from 'routes/user/profile/calendar';
import OrganizationUserProfileNotes from 'routes/organization/user-profile/notes';
import OrganizationUserProfilePayments from 'routes/organization/user-profile/payments';
import OrganizationUserProfileRegistrations from 'routes/organization/user-profile/registrations';
import OrganizationUserProfileAttendance from 'routes/organization/user-profile/attendance';
import OrganizationUserProfileVideos from 'routes/organization/user-profile/videos';
import OrganizationUserProfileDevelopment from 'routes/organization/user-profile/development';
import OrganizationUserProfileSubscriptions from 'routes/organization/user-profile/payments/subscriptions';
import OrganizationUserProfileInvoices from 'routes/organization/user-profile/payments/invoices';
import OrganizationUserProfileUserProducts from 'routes/organization/user-profile/payments/user-products';

import {
	LargeScreen,
	SmallScreen,
	useLargeScreen,
} from 'components/MediaQuery';
import MaterialSymbol from 'components/material-symbols';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as LargeLayout from 'components/navigation/header/large_screen/Styles';
import * as SideBarLayout from 'components/layout/side-bar-page';
import { LayoutPortal, LayoutPortalLocation } from 'components/layout/portal';
import { PageProps, SubNavItemProps } from 'components/layout/page-templates';
import Column from 'components/layout/column';
import Panel from 'components/sidebar/panel';
import { TemplateContextWrapper } from 'components/layout/page-templates/context';

import Button from 'design/button';

import * as css from './styles.css';

interface UserProfileLayoutCrashProps {
	error: ApiError;
}

export function UserProfileLayoutCrash({
	error,
}: UserProfileLayoutCrashProps): JSX.Element {
	const t = useT();
	const backUrl = usePreviousRoutePath();

	let symbolVariant: MaterialSymbolVariant = 'error';

	let { title, body } = error;

	if (error.code === 404) {
		symbolVariant = 'account_circle_off';
		title = t('User not found');
		body = t('The requested user could not be found.');
	}

	if (error.code === 403) {
		symbolVariant = 'supervised_user_circle_off';
		title = t('Forbidden');
		body = t('You are not authorized to view this user.');
	}

	let backButton: ReactNode = (
		<Button primary href={routes.Home()}>
			{t('Back to start')}
		</Button>
	);

	if (backUrl !== '') {
		backButton = (
			<Button primary href={backUrl}>
				{t('Go back')}
			</Button>
		);
	}

	return (
		<Column justify="center" className={css.wrapper}>
			<MaterialSymbol variant={symbolVariant} actualSize scale={3} />
			<h3 className={css.title}>{title}</h3>
			<p className={css.body}>{body}</p>
			{backButton}
		</Column>
	);
}

export function UserProfileLayout({
	title,
	icon,
	children,
}: PageProps): JSX.Element {
	const t = useT();
	const isLargeScreen = useLargeScreen();
	const { organizationId, userId, currentTab, query } = useCurrentRoute();

	// @note Undefined in this case is on the overview page, this only applies to small screens
	const showSidebar = currentTab === 'overview';

	const {
		record: user,
		hasError,
		isLoading,
		response,
		refresh,
	} = useEndpoint<models.user.User>(endpoints.Users.Show(userId), {
		queryParams: new URLSearchParams({
			include_billing_contact: 'true',
			include_user_products: 'true',
		}),
	});

	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					user,
				})
			),
		[children, user]
	);

	const unfilteredSubNavItems: SubNavItemProps[] = [
		{
			href: routes.Organization.User.Profile.Show(
				organizationId,
				userId,
				'overview'
			),
			title: t('Overview'),
		},
		{
			href: routes.Organization.User.Profile.Show(
				organizationId,
				userId,
				'calendar'
			),
			title: t('Calendar'),
		},
		{
			href: routes.Organization.User.Profile.Show(
				organizationId,
				userId,
				'notes'
			),
			title: t('Notes'),
		},
		{
			href: routes.Organization.User.Profile.Show(
				organizationId,
				userId,
				'payments'
			),
			title: t('Payments'),
			allowedToViewItem: [
				models.Action.UserListProducts,
				models.Action.UserListSubscriptions,
				models.Action.UserListOrders,
			],
		},
		{
			href: routes.Organization.User.Profile.Show(
				organizationId,
				userId,
				'registrations'
			),
			title: t('Registrations'),
			allowedToViewItem: [models.Action.UserListFormSubmissions],
		},
		{
			href: routes.Organization.User.Profile.Show(
				organizationId,
				userId,
				'attendance'
			),
			title: t('Attendance'),
		},
		{
			href: routes.Organization.User.Profile.Show(
				organizationId,
				userId,
				'videos'
			),
			title: t('Video'),
			allowedToViewItem: [models.Action.UserListVideos],
		},
		{
			href: routes.Organization.User.Profile.Show(
				organizationId,
				userId,
				'development'
			),
			title: t('Development'),
		},
	];

	const subNavItems = unfilteredSubNavItems.filter((item) => {
		if (user) {
			if (item?.allowedToViewItem?.length > 0) {
				return item.allowedToViewItem.every((allowedAction) =>
					models.hasAllowedAction(user, allowedAction)
				);
			}
		}

		return true;
	});

	const errorFallback = (
		<UserProfileLayoutCrash error={new ApiError(response?.status)} />
	);

	const renderSubComponent = (currentTab: string) => {
		switch (currentTab) {
			case 'calendar':
				return <UserProfileCalendar userId={user.id} />;
			case 'notes':
				return <OrganizationUserProfileNotes user={user} />;
			case 'payments':
				return (
					<OrganizationUserProfilePayments
						organizationId={organizationId}
						isLoading={isLoading}
						user={user}
					/>
				);
			case 'registrations':
				return <OrganizationUserProfileRegistrations user={user} />;
			case 'attendance':
				return <OrganizationUserProfileAttendance user={user} query={query} />;
			case 'videos':
				return <OrganizationUserProfileVideos user={user} />;
			case 'development':
				return <OrganizationUserProfileDevelopment user={user} />;
			case 'invoices':
				return (
					<OrganizationUserProfileInvoices
						isLoading={isLoading}
						organizationId={organizationId}
						user={user}
					/>
				);
			case 'products':
				return (
					<OrganizationUserProfileUserProducts
						isLoading={isLoading}
						organizationId={organizationId}
						user={user}
					/>
				);
			case 'subscriptions':
				return (
					<OrganizationUserProfileSubscriptions
						organizationId={organizationId}
						user={user}
						isLoading={isLoading}
					/>
				);
			default:
				return (
					<UserProfileOverview organizationId={organizationId} user={user} />
				);
		}
	};

	const getBackgroundColor = (currentTab: string) => {
		switch (currentTab) {
			case 'attendance':
			case 'notes':
			case 'subscriptions':
			case 'invoices':
			case 'products':
			case 'payments':
			case 'development':
				return styles.palette.gray[200];
			default:
				return isLargeScreen ? styles.palette.gray[200] : styles.palette.white;
		}
	};

	return (
		<TemplateContextWrapper title={title} icon={icon} subNavItems={subNavItems}>
			<Fragment>
				<LargeScreen>
					<LargeScreenHeader title={title} icon={icon}>
						{subNavItems.map((item, n) => (
							<LargeLayout.SubNavItem key={n} {...item}>
								{item.title}
							</LargeLayout.SubNavItem>
						))}
					</LargeScreenHeader>
					<LargeScreenContent.Wrapper
						backgroundColor={getBackgroundColor(currentTab)}>
						<SideBarLayout.Layout>
							<SideBarLayout.SideBar data-hide-if-empty>
								<UserHeader user={user} />
								<LayoutPortalLocation portalId="user-profile-sidebar" />
							</SideBarLayout.SideBar>
							<SideBarLayout.Content>
								{hasError ? errorFallback : renderSubComponent(currentTab)}
							</SideBarLayout.Content>
						</SideBarLayout.Layout>
					</LargeScreenContent.Wrapper>
				</LargeScreen>
				<SmallScreen>
					<BrandedHeaderLayout
						user={user}
						pageSubNavItems={subNavItems}
						backgroundColor={getBackgroundColor(currentTab)}>
						<LargeScreenContent.Inner noPadding>
							{showSidebar && (
								<div className={css.smallScreenSideBar}>
									<LayoutPortalLocation portalId="user-profile-sidebar" />
								</div>
							)}
							<div>
								{hasError ? errorFallback : renderSubComponent(currentTab)}
							</div>
						</LargeScreenContent.Inner>
					</BrandedHeaderLayout>
				</SmallScreen>

				<LayoutPortal portalId="user-profile-sidebar">
					<UserActions user={user} />
					<UserDetails user={user} />
					<BillingContact user={user} onUpdate={refresh} />
					<CustomCategories user={user} />

					<LargeScreen>
						<Panel footer>
							<UserTimestamps user={user} />
						</Panel>
					</LargeScreen>
				</LayoutPortal>
			</Fragment>
		</TemplateContextWrapper>
	);
}
