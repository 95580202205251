import React, { Fragment } from 'react';

import { cssClasses } from 'pkg/css/utils';
import useTooltip from 'pkg/hooks/useTooltip';

import {
	MaterialSymbols,
	MaterialSymbolVariant,
} from 'components/material-symbols/symbols';

import * as css from './styles.css';

interface MaterialSymbolProps {
	variant: MaterialSymbolVariant;
	scale?: number;
	className?: string;
	fill?: string;
	rotate?: number;
	style?: React.CSSProperties;
	actualSize?: boolean;
	tooltip?: string;
}

export default function MaterialSymbol({
	variant,
	scale = 1,
	rotate,
	actualSize = false,
	className = '',
	fill,
	style,
	tooltip,
}: MaterialSymbolProps) {
	const symbol = MaterialSymbols[variant];
	const { onMouseEnter, tooltip: tip } = useTooltip(tooltip);

	if (!symbol) {
		return null;
	}

	const combinedStyle = {
		'--scale': scale.toString(),
		color: fill,
		transform: rotate ? `rotate(${rotate}deg)` : undefined,
		...style,
	};

	return (
		<Fragment>
			{tip}
			<span
				onMouseEnter={onMouseEnter}
				role="img"
				className={cssClasses(
					css.materialSymbol,
					actualSize ? css.actualSize : '',
					className
				)}
				style={combinedStyle as React.CSSProperties}>
				{symbol}
			</span>
		</Fragment>
	);
}
