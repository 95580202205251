import React, { useEffect, JSX } from 'react';
import { t } from '@transifex/native';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';

import PendingRequests from 'routes/group/show/pending-requests';
import DisplaySettings from 'routes/group/settings/DisplaySettings';
import InviteCodeSettings from 'routes/group/settings/GroupCodeSettings';
import PendingEmailInvites from 'routes/group/show/pending-email-invites';
import CoreSettings from 'routes/group/settings/core_settings';
import AppearanceSettings from 'routes/group/settings/AppearanceSettings';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import { useTemplateContext } from 'components/layout/page-templates/context';

interface SubgroupProps {
	groupId: number;
}

const SubgroupSettings: React.FC<React.PropsWithChildren<SubgroupProps>> = ({
	groupId,
}): JSX.Element => {
	const { setPageTitle } = useTemplateContext();
	const {
		record: group,
		isLoading,
		refresh,
		replaceRecord,
	} = useEndpoint<models.group.Group>(endpoints.Groups.Show(groupId));

	useEffect(() => {
		if (group?.name) {
			setPageTitle(t('Settings for {groupName}', { groupName: group.name }));
		}
	}, [group?.name]);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Column>
			<InviteCodeSettings group={group} refresh={refresh} />
			<PendingRequests groupId={group.id} refreshTeamList={refresh} />
			<PendingEmailInvites groupId={group.id} />
			<CoreSettings
				group={group}
				replaceRecord={replaceRecord}
				refresh={refresh}
			/>
			<AppearanceSettings group={group} refresh={refresh} />
			<DisplaySettings group={group} />
		</Column>
	);
};

export default SubgroupSettings;
