import { t } from '@transifex/native';

import { Record } from 'pkg/api/models/record';
import * as sdk from 'pkg/core/sdk';
import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';

export interface Attachment extends Record, Linkable, Dateable {
	accountId: number;
	userId: number;
	title: string;
	description: string;
	type: AttachmentType;
	status: AttachmentStatus;
	url: string;
	previewUrl: string;
	size: number;

	// used to track upload state, never stored in the API
	bytesUploaded?: number;

	/**
	 * uploadUrl is where the client should send files when uploading an attachment.
	 */
	uploadUrl?: string;

	// Used to greedily render attachment items before we get response from API.
	tmpId?: string;
}

export enum AttachmentType {
	Image = 'image',
	Video = 'video',
	Audio = 'audio',
	File = 'file',
}

export enum AttachmentVisibility {
	Visible = 'default',
	Hidden = 'hidden',
}

export enum AttachmentStatus {
	Uploading = 'uploading',
	Processing = 'processing',
	Normal = 'normal',
	WillDelete = 'will_delete',
}

export function getExtension(a: Attachment): string {
	const url = a.url;

	const l = document.createElement('a');
	l.href = url;

	const fileName = l.pathname.split('/').pop().split('.');

	if (fileName.length < 2) {
		return '';
	}

	return fileName[1];
}

export function getHumanFileSize(a: Attachment): string {
	const bytes = a.size;
	const y = Math.floor(Math.log(bytes) / Math.log(1000));
	const sizes = ['B', 'KB', 'MB', 'GB'];

	return `${Math.round(bytes / Math.pow(1000, y)) * 1}${sizes[y]}`;
}

export function hasThumbnail(a: Attachment): boolean {
	if (!a) {
		return false;
	}

	return (
		a.previewUrl !== '' || (a.type === 'image' && a.size < 5 * 1024 * 1024)
	);
}

export function destroy(a: Attachment) {
	return sdk.destroy(`/attachments/${a.id}`);
}

export function getTypeTranslation(type: string) {
	switch (type) {
		case AttachmentType.Audio:
			return t('Audio');
		case AttachmentType.File:
			return t('File');
		case AttachmentType.Image:
			return t('Image');
		case AttachmentType.Video:
			return t('Video');
		default:
			return type;
	}
}
