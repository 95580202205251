import { JSX } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

const Wrapper = styled.div`
	font-size: var(--font-size-base);
	font-weight: var(--font-weight-semibold);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-lg);
	}

	${({ color }) =>
		color &&
		css`
			color: ${color};
		`};
`;

interface StatusLabelProps {
	label: string;
	color: string;
}

export default function StatusLabel({
	label,
	color,
}: StatusLabelProps): JSX.Element {
	return <Wrapper color={color}>{label}</Wrapper>;
}
