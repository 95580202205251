import { JSX } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import Row from 'components/layout/row';

const AvatarRow = styled.div`
	--avarar-size: 40px;
	--icon-size: 28px;

	margin: 0 auto;
	position: relative;
	width: calc((var(--avarar-size) * 2) + var(--spacing-6));
`;

const ConnectionIconWrapper = styled.div`
	--size: 28px;

	width: var(--size);
	height: var(--size);
	border-radius: 1000px;
	position: absolute;
	left: 50%;
	top: 50%;
	background: var(--palette-blue-300);
	color: var(--palette-blue-600);
	box-shadow: var(--palette-white) 0 0 0 2px;
	transform: translate(-50%, -50%);
	display: flex;
	place-content: center;
	align-items: center;
`;

interface AccountConnectionProps {
	a: models.account.Account;
	b: models.account.Account;
}

export default function AccountConnection({
	a,
	b,
}: AccountConnectionProps): JSX.Element {
	return (
		<AvatarRow>
			<Row columns="repeat(2, 40px)" spacing={styles.spacing._6}>
				<Avatar account={a} />
				<Avatar account={b} />
			</Row>
			<ConnectionIconWrapper>
				<Icon name="link" size={1.25} />
			</ConnectionIconWrapper>
		</AvatarRow>
	);
}
