import { Fragment, JSX } from 'react';

import * as models from 'pkg/api/models';
import * as objects from 'pkg/objects';

import Avatar from 'components/avatar';

import * as css from './styles.css';

interface UserHeaderProps {
	user: models.user.User;
}

/**
 *	@note Render this even if user is null to prevent content flickering when waiting for useEndpoint.
 */
export default function UserHeader({ user }: UserHeaderProps): JSX.Element {
	const hasUser = objects.empty(user) === false;

	return (
		<div className={css.userHeader}>
			{hasUser && (
				<Fragment>
					<Avatar user={user} className={css.avatar} />
					<span className={css.firstName}>{user?.firstName}</span>
					<strong className={css.lastName}>{user?.lastName}</strong>
				</Fragment>
			)}
		</div>
	);
}
