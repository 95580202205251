import styled from 'styled-components';
import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';

import * as Card from 'components/Card';
import Icon, { IconName } from 'components/icon';

import Column from 'components/layout/column';

import * as css from './styles.css';

const ImageWrapper = styled.div`
	display: flex;
	min-width: auto;
	align-items: center;
	justify-content: center;

	img {
		width: 150px;
	}
`;

const IconWrapper = styled.div`
	display: flex;
	min-width: auto;
	align-items: center;
	justify-content: center;
	color: var(--palette-gray-500);

	& svg {
		font-size: var(--font-size-5xl);
	}
`;

const Wrapper = styled.div`
	padding: var(--spacing-6);
	padding-bottom: var(--spacing-7);
`;

const Title = styled.div`
	font-size: var(--font-size-base);
	line-height: var(--font-line-height-base);
	font-weight: var(--font-weight-semibold);
	text-align: center;
	max-width: 460px;
`;

const Description = styled.div`
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	line-height: var(--font-line-height-sm);
	text-align: center;
	max-width: 380px;
`;

const ActionRow = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-auto-rows: auto;
	justify-content: center;
	align-items: center;
	gap: var(--spacing-3);
	margin-top: var(--spacing-3);
`;

const CloseIcon = styled(Icon)`
	position: absolute;
	top: var(--spacing-5);
	right: var(--spacing-5);
	color: var(--palette-gray-400);

	&:hover {
		color: var(--palette-gray-500);
	}
`;

interface BlockProps {
	onDismiss?: () => void;
	label?: JSX.Element;
	title: string;
	description?: string;
	helpUrl?: string;
	helpUrlText?: string;
	image?: React.ReactNode;
	actions?: React.ReactNode;
	// Set column to display the block as a column, with the image on top
	column?: boolean;
	// Set centerText if you want the text to be aligned center
	centerText?: boolean;
	// Sets the bottom margin of the image wrapper
	imageMargin?: styles.spacing;
	dashboard?: boolean;
	inCard?: boolean;
	icon?: IconName;
	noBorder?: boolean;
}

export default function BlockPlaceholder({
	onDismiss,
	label,
	title,
	description,
	helpUrl,
	helpUrlText,
	actions,
	image,
	column,
	imageMargin = styles.spacing._9,
	inCard = true,
	icon,
	noBorder,
}: BlockProps): JSX.Element {
	let marginBottom = `-${imageMargin}`;

	if (!column) {
		marginBottom = '0';
	}

	const content = (
		<Wrapper>
			<Column spacing={styles.spacing._4} justify="center">
				{onDismiss && <CloseIcon name="close" size={1.5} onClick={onDismiss} />}
				{image && (
					<ImageWrapper
						style={{
							marginBottom,
						}}>
						{image}
					</ImageWrapper>
				)}
				{icon && (
					<IconWrapper
						style={{
							marginBottom,
						}}>
						<Icon name={icon} />
					</IconWrapper>
				)}
				{label && label}
				<Column spacing={styles.spacing._2} justify="center">
					{title && <Title>{title}</Title>}
					{description && <Description>{description}</Description>}
				</Column>
				{actions && <ActionRow>{actions}</ActionRow>}
				{helpUrl && (
					<Link href={helpUrl} target="_blank" className={css.link}>
						{helpUrlText || t('Learn more')}
					</Link>
				)}
			</Column>
		</Wrapper>
	);

	return (
		<Fragment>
			{inCard ? <Card.Base $noBorder={noBorder}>{content}</Card.Base> : content}
		</Fragment>
	);
}
