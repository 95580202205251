import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';

import { palette } from 'pkg/config/styles';

import { SoldProductsItem } from 'pkg/api/models/order_report';

import {
	useLocalizedCurrencyFormatter,
	useOrderReport,
} from 'routes/payments/overview/hooks';
import { Widget } from 'routes/payments/overview/widgets';

import * as DataCard from 'design/data_card';
import * as Chart from 'design/chart';

export default function SoldProductsGraphWidget(): JSX.Element {
	const fmt = useLocalizedCurrencyFormatter();
	const { soldProducts } = useOrderReport();

	const products = Object.values(soldProducts.products);

	const items: Chart.ChartItem[] = products
		.map((item: SoldProductsItem) => ({
			title: item.name,
			value: item.amount,
		}))
		.sort((a: Chart.ChartItem, b: Chart.ChartItem) => b.value - a.value)
		.slice(0, 10);

	const formatLabel = (item: Chart.ChartItem) => {
		return fmt(item.value);
	};

	const CustomTheme: Chart.ChartItemTheme[] = [
		{ fillColor: palette.blue[200], textColor: palette.blue[600] },
	];

	const formatTooltip = (item: Chart.ChartItem, itemTotal: number) => {
		const percent = ((item.value / itemTotal) * 100).toFixed(1);

		return (
			<Fragment>
				<strong>{fmt(item.value)}</strong>
				<span>{t('{percent}% of total volume', { percent })}</span>
			</Fragment>
		);
	};

	if (items.length === 0) {
		return null;
	}

	return (
		<Widget>
			<DataCard.Base titleIcon="sell" title={t('Most sold products')}>
				<Chart.HorizontalBar
					sortKey="value"
					sortOrder="desc"
					items={Chart.applyTheme(items, CustomTheme)}
					theme={CustomTheme}
					formatLabel={formatLabel}
					formatTooltip={formatTooltip}
				/>
			</DataCard.Base>
		</Widget>
	);
}
