import { JSX } from 'react';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import DateTime, { Granularity } from 'pkg/datetime';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';
import { useCurrentAccountUserIds, useCurrentAccountWards } from 'pkg/identity';
import { isSameDay } from 'pkg/date';

import { getCalendarEventColorVars } from 'routes/group/calendar/config';
import { SharedEventProps } from 'routes/group/calendar/components/events/single';
import EventIcons from 'routes/group/calendar/components/events/event-icons';

import Icon from 'components/icon';

import * as eventStyles from 'components/event/calendar-styles/styles.css';
import EventUserRow from 'components/event-user/schedule-event';
import Column from 'components/layout/column';

import * as css from './styles.css';

const ScheduleStyle = ({
	event,
	previewContextMenu,
	handleEventClick,
}: SharedEventProps): JSX.Element => {
	const startsAtDate = new Date(event.startsAt * 1000);
	const endsAtDate = new Date(event.endsAt * 1000);
	const startsAtDateTime = new DateTime(startsAtDate);
	const endsAtDateTime = new DateTime(endsAtDate);
	const isNotSameDay = !isSameDay(startsAtDate, endsAtDateTime);

	const calendarEventStyles = getCalendarEventColorVars(event.type);

	const wardAccountRelations = useCurrentAccountWards();
	const activeUserIds = useCurrentAccountUserIds();
	const wardUserIds = models.accountRelation.findAllUserIds(
		wardAccountRelations.filter((ar) => models.accountRelation.isApproved(ar))
	);

	const eventUsers = models.eventUser.findAllEventUsers(event.users, [
		...activeUserIds,
		...wardUserIds,
	]);

	const meetingTime = startsAtDateTime.prev(
		Granularity.minute,
		event.meetBeforeMinutes
	);

	const unhandled = eventUsers.some((ev) => models.eventUser.isUnhandled(ev));
	const hasEnded = models.event.hasEnded(event);

	const isCanceled = models.event.isCanceled(event);

	return (
		<div
			className={cssClasses(
				css.eventContainer,
				eventStyles['event-color-styles'],
				unhandled && !models.event.hasEnded(event) ? eventStyles.unhandled : '',
				hasEnded ? eventStyles['has-finished'] : ''
			)}
			style={{
				...calendarEventStyles,
			}}>
			<div
				className={css.header}
				style={{
					...calendarEventStyles,
				}}>
				<span className={css.type}>
					{models.event.getTypeTranslation(event.type)}
				</span>
				<span className={css.group}>{event.group?.name}</span>
			</div>
			<div className={css.wrapper} onClick={handleEventClick}>
				<div className={css.scheduleEvent}>
					<div className={css.title}>
						<EventIcons event={event} size={1.1} />

						{isCanceled && (
							<div className={eventStyles.uppercase}>{t('Canceled')}</div>
						)}

						<span className={isCanceled ? css.strikeThrough : ''}>
							{event.title}
						</span>
					</div>

					<div className={css.eventTimeWrapper}>
						<span className={css.smallFont}>
							{startsAtDateTime.toLocaleTimeString({
								hour: '2-digit',
								minute: '2-digit',
							})}{' '}
							–{' '}
							{endsAtDateTime.toLocaleTimeString({
								hour: '2-digit',
								minute: '2-digit',
							})}{' '}
							{isNotSameDay &&
								`(${endsAtDateTime.toLocaleDateString({
									day: 'numeric',
									month: 'short',
								})})`}
						</span>
						{event.meetBeforeMinutes > 0 && (
							<div className={css.meetTime}>
								{t('Meet time {time}', {
									time: meetingTime.toTimeString(),
								})}
							</div>
						)}
					</div>
					{event.location && (
						<div className={css.location}>
							<Icon name="location" />
							<span className={css.smallFont}>{event.location}</span>
						</div>
					)}
				</div>

				{eventUsers.length > 0 && (
					<Column spacing={spacing._2}>
						{eventUsers.map((eu) => (
							<EventUserRow event={event} eventUser={eu} key={eu.id} />
						))}
					</Column>
				)}
			</div>
			{previewContextMenu}
		</div>
	);
};

export default ScheduleStyle;
