import styled from 'styled-components';
import { JSX, Fragment, useEffect, useRef, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import VideoSequence from 'pkg/models/video_sequence';

import { findMaxTimeIntersections } from 'pkg/video';
import * as numbers from 'pkg/numbers';
import rgba from 'pkg/rgba';
import { useCurrentFilteredClips } from 'pkg/hooks/selectors';

import Timeline from 'routes/video/analyze/timeline/Timeline';
import ClipGroupSelector from 'routes/video/analyze/ClipGroupSelector';
import Clip from 'routes/video/analyze/timeline/Clip';
import { useTimelineOffset } from 'routes/video/shared/hooks/timeline';
import Controls from 'routes/video/shared/Controls';
import Annotation from 'routes/video/analyze/timeline/Annotation';
import SelectableTaggedUsersModal from 'routes/video/analyze/SelectableTaggedUsersModal';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { CueType, useCueState } from 'components/video-analytics/CueState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useClipState } from 'components/video-analytics/ClipState';
import { getAnnotationName } from 'components/annotations/utils';

import Button from 'design/button';

const Wrapper = styled.div`
	display: grid;
	grid-template-rows: auto 1fr;
	height: 100%;
	position: relative;

	&::before,
	&::after {
		pointer-events: none;
		background: linear-gradient(
			to right,
			var(--palette-gray-700) 5px,
			${rgba(styles.palette.gray[700], 0)}
		);
		content: '';
		display: block;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 50px;
		left: 0;
		z-index: ${styles.zIndex.videoAnalytics + 50};
	}

	&::after {
		left: auto;
		right: 0;
		transform: rotate(180deg);
	}
`;

const Header = styled.div`
	padding: 0 var(--spacing-5);
	background: var(--palette-gray-800);
	height: 50px;
	display: grid;
	place-items: center;
	grid-template-columns: repeat(3, 1fr);
	color: var(--palette-white);
	border-bottom: 1px solid var(--palette-gray-900);

	> :first-child {
		justify-self: start;
	}

	> :last-child {
		justify-self: end;
	}

	@media ${styles.breakpoint.small} {
		padding: 0 var(--spacing-3);
		grid-template-columns: 1fr auto;
		gap: var(--spacing-3);
	}
`;

export default function Workspace(): JSX.Element {
	const wrapperRef = useRef<HTMLDivElement>();

	const { setCurrentTime } = usePlaybackState();

	const clipState = useClipState();
	const timelineOffset = useTimelineOffset();
	const { currentTime } = usePlaybackState();

	const [isLocked, setLocked] = useState<boolean>(false);

	const {
		controller,
		duration,
		isRecording,
		isPlaying,
		isTrimming,

		showingClipList,
		setShowingClipList,
		timelineZoom,
		timelinePrecision,
	} = usePlayerState();

	const { cuePoints, currentCue } = useCueState();

	const filteredSequences = useCurrentFilteredClips();
	const numFilteredSequences = filteredSequences.size;

	const maxIntersects = findMaxTimeIntersections(
		cuePoints.map((cuePoint: CueType) => [
			cuePoint.startsAtMs || cuePoint.startsAt * 1000,
			cuePoint.endsAtMs || cuePoint.endsAt * 1000,
		])
	);

	const clampedMaxIntersections = numbers.clamp(maxIntersects, 0, 4);

	const isCurrentCue = (clipId: number): boolean =>
		currentCue && currentCue.cueId === clipId;

	const lockTimeline = () => setLocked(true);

	const unlockTimeline = () => setLocked(false);

	const trimStartsAt = (startsAt: number) => {
		controller.seekTo(startsAt, isPlaying);
		setCurrentTime(startsAt);

		timelineOffset.set(startsAt);
	};

	const trimEndsAt = (endsAt: number) => {
		controller.seekTo(endsAt, isPlaying);
		setCurrentTime(endsAt);

		timelineOffset.set(endsAt);
	};

	const openListModal = () => {
		if (controller.isPlaying) {
			controller.pause();
		}

		setShowingClipList(true);
	};

	const activeAnnotation = clipState.getActiveAnnotation();

	let maxIntersections = clampedMaxIntersections;

	if (isTrimming && !activeAnnotation) {
		maxIntersections = 0;
	} else if (isTrimming && activeAnnotation) {
		maxIntersections = 3;
	}

	useEffect(() => {
		if (isRecording && !isTrimming) {
			clipState.setEndsAt(currentTime);
		}
	}, [isRecording, currentTime]);

	return (
		<Fragment>
			<Wrapper ref={wrapperRef}>
				<LargeScreen>
					<Controls context="analyze" />
				</LargeScreen>
				<SmallScreen>
					<Header>
						<ClipGroupSelector disabled={isTrimming && !isRecording} />
						<Button transparent small icon="tune" onClick={openListModal}>
							{t('Clips ({num})', { num: numFilteredSequences })}
						</Button>
					</Header>
				</SmallScreen>
				<Timeline
					disabled={isLocked}
					duration={duration}
					precision={timelinePrecision}
					zoom={timelineZoom}
					timelineOffset={timelineOffset}
					maxIntersections={maxIntersections}>
					{!isTrimming &&
						filteredSequences.map((sequence: VideoSequence) => (
							<Clip
								key={sequence.id}
								clipId={sequence.id}
								title={sequence.title}
								videoDuration={duration}
								startsAt={sequence.getStartsAt()}
								endsAt={sequence.getEndsAt()}
								isHighlight={sequence.reviewed}
								isPrivate={sequence.private}
								isActive={isCurrentCue(sequence.id)}
								isDisabled={isRecording}
							/>
						))}
					{isTrimming && (
						<Fragment>
							{activeAnnotation && (
								<Clip
									key="activeAnnotation"
									isAnnotation
									title={t('Keyframes ({name})', {
										name: getAnnotationName(activeAnnotation.tool),
									})}
									precision={timelinePrecision}
									zoom={timelineZoom}
									videoDuration={duration}
									startsAt={clipState.getStartsAt()}
									endsAt={clipState.getEndsAt()}
									rowEnd="span 2"
								/>
							)}
							<Clip
								key="currentClip:trim"
								isTrimming={isTrimming}
								isRecording={isRecording}
								precision={timelinePrecision}
								zoom={timelineZoom}
								videoDuration={duration}
								startsAt={clipState.getStartsAt()}
								endsAt={clipState.getEndsAt()}
								onTrimStart={lockTimeline}
								onTrimEnd={unlockTimeline}
								onDidTrimStartsAt={trimStartsAt}
								onDidTrimEndsAt={trimEndsAt}
								rowStart={activeAnnotation ? '3' : '1'}
							/>
							{activeAnnotation ? (
								<Annotation annotationId={clipState.activeAnnotationId} />
							) : (
								<Annotation annotationId="playback" />
							)}
						</Fragment>
					)}
					{!isTrimming && isRecording && (
						<Clip
							isRecording={isRecording}
							precision={timelinePrecision}
							zoom={timelineZoom}
							videoDuration={duration}
							startsAt={clipState.getStartsAt()}
							endsAt={clipState.getEndsAt()}
						/>
					)}
				</Timeline>
			</Wrapper>
			{showingClipList && <SelectableTaggedUsersModal />}
		</Fragment>
	);
}
