import Lottie, { AnimationConfigWithData } from 'lottie-web';
import { JSX, useRef } from 'react';
import styled from 'styled-components';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

interface LottieAnimationProps {
	autoplay?: boolean;
	loop?: boolean;
	onComplete?: () => void;
	togglePlayOnClick?: boolean;
	delay?: number;
	className?: string;
	name: string;
}

function LottieAnimation({
	autoplay = false,
	loop = false,
	onComplete,
	togglePlayOnClick = false,
	delay = 0,
	className,
	name,
}: LottieAnimationProps): JSX.Element {
	const lottieDOM = useRef();
	const animation = useRef(null);

	useComponentDidMount(async () => {
		const animationData = await import(`assets/lottie-animations/${name}.json`);

		const lottieConfig: AnimationConfigWithData = {
			container: lottieDOM.current,
			renderer: 'svg',
			autoplay: !delay && autoplay,
			loop: loop,
			animationData: animationData.default,
		};

		animation.current = Lottie.loadAnimation(lottieConfig);

		if (loop) {
			animation.current.addEventListener('loopComplete', () => onComplete?.());
		} else {
			animation.current.addEventListener('complete', () => onComplete?.());
		}

		if (delay > 0 && autoplay) {
			setTimeout(() => {
				animation.current.play();
			}, delay);
		}
	});

	const togglePlay = () => {
		if (animation.current.isPaused) {
			animation.current.play();
		} else {
			animation.current.pause();
		}
	};

	return (
		<div
			ref={lottieDOM}
			className={className}
			onClick={togglePlayOnClick && togglePlay}
		/>
	);
}

export default styled(LottieAnimation)``;
