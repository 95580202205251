import { JSX } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

interface BigTitleStylesProps {
	center?: boolean;
	hasDecsription?: boolean;
}

const BigTitleStyles = styled.div<BigTitleStylesProps>`
	color: var(--palette-white);
	text-align: center;

	${({ hasDecsription }) =>
		hasDecsription &&
		css`
			display: grid;
			grid-template-rows: auto auto;
			gap: var(--spacing-6);
		`}

	h1 {
		font-size: var(--font-size-4xl);

		span {
			display: block;
			color: ${styles.palette.brand};
		}
	}

	@media ${styles.breakpoint.small} {
		h1 {
			font-size: var(--font-size-3xl);
			line-height: var(--font-line-height-2xl);
		}
	}

	p {
		font-size: var(--font-size-lg);
		margin: 0;
		@media ${styles.breakpoint.small} {
			font-size: var(--font-size-base);
		}
	}
`;

interface BigTitleProps extends BigTitleStylesProps {
	firstTitle: string;
	secondTitle: string;
	description?: string;
	testid?: string;
}

export default function BigTitle({
	firstTitle,
	secondTitle,
	description,
	testid = 'onboarding.title',
}: BigTitleProps): JSX.Element {
	const hasDecsription = !!description;

	return (
		<BigTitleStyles hasDecsription={hasDecsription} data-testid={testid}>
			<h1>
				{firstTitle}
				<span>{secondTitle}</span>
			</h1>
			{description && <p>{description}</p>}
		</BigTitleStyles>
	);
}
