// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CDd1PgUxff4Yi43XQ0NS {
	color: var(--palette-gray-500);
}

.Fr8FrrAehN2xWM3C2J7u {
	padding: var(--spacing-4);
	font-size: var(--font-size-base);
}

.fyiLpLRVc8poNZ165yWk {
	margin-top: var(--spacing-5);
	display: grid;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/development/performance-reviews/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;AAC/B;;AAEA;CACC,yBAAyB;CACzB,gCAAgC;AACjC;;AAEA;CACC,4BAA4B;CAC5B,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;AACpB","sourcesContent":[".timestamp {\n\tcolor: var(--palette-gray-500);\n}\n\n.body {\n\tpadding: var(--spacing-4);\n\tfont-size: var(--font-size-base);\n}\n\n.loadMore {\n\tmargin-top: var(--spacing-5);\n\tdisplay: grid;\n\tplace-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var timestamp = `CDd1PgUxff4Yi43XQ0NS`;
export var body = `Fr8FrrAehN2xWM3C2J7u`;
export var loadMore = `fyiLpLRVc8poNZ165yWk`;
export default ___CSS_LOADER_EXPORT___;
