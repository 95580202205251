import { JSX, Fragment, MouseEvent } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import * as Input from 'components/form/inputs';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';

const Item = styled(ContextMenu.Item)`
	display: grid;
	grid-template-columns: 1fr 22px;
	align-items: center;
`;

interface SortOption {
	label: string;
	ascLabel: string;
	descLabel: string;
	queryParam: string;
}

interface ContentFilterProps {
	options: SortOption[];
}

export default function SortOptions({
	options,
}: ContentFilterProps): JSX.Element {
	const qs = useQueryState();

	useComponentDidMount(() => {
		// Emulate default option without updating querystring
		qs.set('sort', 'date_added:asc');
	});

	const updateSortFilter = (event: MouseEvent<HTMLDivElement>) => {
		const { sortParam, sortDirection } = event.currentTarget.dataset;

		qs.set('sort', `${sortParam}:${sortDirection}`);
		qs.commit();
	};

	const toggle = <Button icon="swap_vert">{t('Sort options')}</Button>;

	const items = options.map(
		({ label, ascLabel, descLabel, queryParam }: SortOption) => (
			<Fragment>
				<ContextMenu.Label>{label}</ContextMenu.Label>
				<Item
					key={ascLabel}
					closeOnClick={false}
					data-sort-param={queryParam}
					data-sort-direction="asc"
					onClick={updateSortFilter}>
					<span>{ascLabel}</span>
					<Input.Control
						type="radio"
						standalone
						checked={qs.is('sort', `${queryParam}:asc`)}
					/>
				</Item>
				<Item
					key={descLabel}
					closeOnClick={false}
					data-sort-param={queryParam}
					data-sort-direction="desc"
					onClick={updateSortFilter}>
					<span>{descLabel}</span>
					<Input.Control
						type="radio"
						standalone
						checked={qs.is('sort', `${queryParam}:desc`)}
					/>
				</Item>
			</Fragment>
		)
	);

	return <ContextMenu.Menu toggleWith={toggle}>{items}</ContextMenu.Menu>;
}
