import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { replaceState } from 'pkg/router/state';
import store from 'pkg/store/createStore';
import {
	useCurrentMembership,
	useCurrentUser,
	useCurrentOrganization,
	useFetchIdentity,
} from 'pkg/identity';
import { useOrganizationIdentity } from 'pkg/identity/organization';
import { useCollection } from 'pkg/api/use_collection';
import { Flag, useFlag } from 'pkg/flags';

import CardBase, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import { useSmallScreen } from 'components/MediaQuery';
import Modal from 'components/modal';

import InfoBox from 'components/form/info-box';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import ChildUser from 'components/user/missing-information/ChildUser';
import Column from 'components/layout/column';
import Form, { FormPayload } from 'components/form/Form';
import Row from 'components/layout/row';
import SlimHeader from 'components/navigation/slim-header';
import { Fields } from 'components/user/missing-information/Fields';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

export function checkIfShouldValidatePersonalNumber(
	nationality: string,
	personalNumbervalue: string,
	isAdmin: boolean = false
) {
	let shouldValidatePersonalNumber = false;

	if (nationality === 'se') {
		if (!isAdmin) {
			// Always require personal number for players if nationality is SE
			shouldValidatePersonalNumber = true;
		} else if (isAdmin && personalNumbervalue !== '') {
			// Only require personal number for admins if value not empty
			// This is used when editing contacts
			shouldValidatePersonalNumber = true;
		}
	} else if (nationality !== 'se' && personalNumbervalue !== '') {
		shouldValidatePersonalNumber = true;
	}

	return shouldValidatePersonalNumber;
}

export interface FormState {
	country: string;
	nationality: string;
}

interface RequiredInformationProps {
	modal?: boolean;
	targetUser?: models.user.User;
	onClose?: () => void;
}

export function MissingInformation({
	modal,
	targetUser,
	onClose,
}: RequiredInformationProps) {
	const [isSaving, setIsSaving] = useState(false);
	const { wards } = useOrganizationIdentity();
	const fetchIdentity = useFetchIdentity();
	const user = useCurrentUser();
	const currentMembership = useCurrentMembership();
	const childUser = wards.find((w) => w.id === currentMembership.targetUserId);
	const userFieldCategoriesEnabled = useFlag<boolean>(Flag.UserFieldCategories);

	const organization = useCurrentOrganization();

	const { records: categories, isLoading } =
		useCollection<models.userFieldCategories.UserFieldCategory>(
			endpoints.UserFieldCategories.Index(organization.id)
		);

	let actingUser: models.user.User;
	let isParent: boolean;

	if (targetUser) {
		actingUser = targetUser;
		isParent = true;
	} else if (models.membership.isParent(currentMembership)) {
		actingUser = childUser;
		isParent = true;
	} else {
		actingUser = user;
		isParent = false;
	}

	const missingUserFields =
		models.user.getMissingRequiredUserFields(actingUser);

	const handleSave = async (data: FormPayload) => {
		setIsSaving(true);

		if (data.fields) {
			data.fields = models.user.getFieldValuesAsPayload(data.fields) as any;
		}

		const ok = await actions.users.update(actingUser.id, data)(store.dispatch);
		if (!ok) {
			setIsSaving(false);
			return;
		}

		await fetchIdentity();

		if (modal) {
			onClose();
		} else {
			replaceState(document.location.pathname, {
				information_saved: true,
			});
		}
	};

	const formTitle = isParent
		? t('Fill in the information for {child_name}', {
				child_name: models.user.fullName(
					actingUser as unknown as models.user.User
				),
			})
		: t('Complete your profile');

	const infoText = t(
		'{organization} is requesting the following information. Please fill it out in order to continue using 360Player.',
		{ organization: organization.name }
	);

	const isSmallScreen = useSmallScreen();

	const fields = (
		<Fields user={actingUser} missingUserFields={missingUserFields} />
	);

	if (isLoading) {
		return <Spinner />;
	}

	const renderFieldsByCategory = () => {
		return categories.map((category) => {
			const categoryFields = missingUserFields.filter(
				(field) => field.userFieldCategoryId === category.id
			);

			if (categoryFields.length === 0) return null;

			return (
				<Column key={category.id} spacing={styles.spacing._4}>
					<SectionTitle medium>{category.title}</SectionTitle>
					<Fields user={actingUser} missingUserFields={categoryFields} />
				</Column>
			);
		});
	};

	const renderFieldsWithoutCategory = () => {
		const fields = missingUserFields.filter(
			(field) => field.userFieldCategoryId == null
		);

		if (fields.length === 0) return null;

		return <Fields user={actingUser} missingUserFields={fields} />;
	};

	const renderAllMissingFields = () => {
		return (
			<Column>
				{renderFieldsWithoutCategory()}
				{renderFieldsByCategory()}
			</Column>
		);
	};

	if (modal) {
		return (
			<Modal onClose={onClose}>
				<Form onSubmit={handleSave}>
					<CardBase>
						<Card.Header>
							<Card.Heading>{formTitle}</Card.Heading>
						</Card.Header>
						<Card.Divider />
						<Card.Body $flex>
							<Column spacing={styles.spacing._7}>
								{userFieldCategoriesEnabled ? renderAllMissingFields() : fields}
							</Column>
						</Card.Body>
						<Card.Footer>
							<Button
								primary
								large
								block
								type="submit"
								disabled={isSaving}
								label={t('Save')}
								testid="user.missing_information.submit"
							/>
						</Card.Footer>
					</CardBase>
				</Form>
			</Modal>
		);
	}

	return (
		<Fragment>
			<SlimHeader />
			<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.STANDARD}>
				<Form onSubmit={handleSave}>
					<Column spacing={styles.spacing._7}>
						<Column spacing={styles.spacing._4}>
							<SectionTitle medium>{formTitle}</SectionTitle>
							{isParent && (
								<ChildUser user={actingUser as unknown as models.user.User} />
							)}
							<InfoBox>{infoText}</InfoBox>
						</Column>

						<Column spacing={styles.spacing._7}>
							{userFieldCategoriesEnabled ? renderAllMissingFields() : fields}
						</Column>

						<Row justify="end">
							<Button
								primary
								large
								block={isSmallScreen}
								type="submit"
								disabled={isSaving}
								label={t('Save')}
								testid="user.missing_information.submit"
							/>
						</Row>
					</Column>
				</Form>
			</LargeScreenContent.Inner>
		</Fragment>
	);
}
