import { JSX } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { months } from 'pkg/date';

import * as Inputs from 'components/form/inputs';
import Row from 'components/layout/row';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

interface BirthDateInputProps {
	user: models.user.User;
	required: boolean;
}

export default function BirthDateInput({
	user,
	required,
}: BirthDateInputProps): JSX.Element {
	const dayOptions = [];
	const monthOptions = [];
	const yearOptions = [];

	for (let i = 1; i <= 12; i++) {
		monthOptions.push({
			value: i.toString().padStart(2, '0'),
			label: months()[i - 1][1],
		});
	}

	for (let j = 1; j <= 31; j++) {
		dayOptions.push({
			value: j.toString().padStart(2, '0'),
			label: j.toString(),
		});
	}

	for (let y = currentYear; y > currentYear - 100; y--) {
		yearOptions.push({
			value: y.toString(),
			label: y.toString(),
		});
	}

	const [year, month, day] = (user.birthDate as string)
		.split('-')
		.map((n) => n);

	return (
		<Inputs.Group label={t('Birth date')} required={required}>
			<Row>
				<Inputs.Select name="birthDateDay" defaultValue={day}>
					{dayOptions.map((item, index) => (
						<option key={index} value={item.value}>
							{item.label}
						</option>
					))}
				</Inputs.Select>

				<Inputs.Select name="birthDateMonth" defaultValue={month}>
					{monthOptions.map((item, index) => (
						<option key={index} value={item.value}>
							{item.label}
						</option>
					))}
				</Inputs.Select>

				<Inputs.Select name="birthDateYear" defaultValue={year}>
					{yearOptions.map((item, index) => (
						<option key={index} value={item.value}>
							{item.label}
						</option>
					))}
				</Inputs.Select>
			</Row>
		</Inputs.Group>
	);
}
