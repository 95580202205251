import styled, { css } from 'styled-components';
import { JSX, ReactNode, ReactElement } from 'react';

import * as styles from 'pkg/config/styles';

import {
	FormattedContent,
	FormattedContentMaxLength,
} from 'components/formatted-content';
import Icon from 'components/icon';

interface CurrentClipState {
	isPrivate?: boolean;
	isHighlight?: boolean;
}

const Wrapper = styled.div<CurrentClipState>`
	border-radius: var(--radius-3);
	display: grid;
	grid-auto-flow: row;
	row-gap: var(--spacing-2);
	align-items: center;
	user-select: none;
	color: var(--palette-white);

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-4);
		margin-bottom: var(--spacing-4);
	}
`;

const Header = styled.header`
	font-weight: var(--font-weight-semibold);
	display: grid;
	grid-auto-flow: column;
	column-gap: var(--spacing-2);
	align-items: center;
	justify-content: start;
`;

const Title = styled.span<CurrentClipState>`
	font-size: var(--font-size-lg);
	display: grid;
	grid-auto-flow: column;
	gap: var(--spacing-3);
	align-items: center;

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		display: inline-block;
	}

	${({ isHighlight }) =>
		isHighlight &&
		css`
			color: var(--palette-yellow-500);
		`}

	${({ isPrivate }) =>
		isPrivate &&
		css`
			color: var(--palette-gray-500);
		`}

	${({ isHighlight, isPrivate }) =>
		isHighlight &&
		isPrivate &&
		css`
			color: var(--palette-yellow-800);
		`}
`;

const SubTitle = styled.span`
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	display: inline-block;
`;

const Content = styled.div`
	display: grid;
	grid-auto-flow: row;
	row-gap: var(--spacing-5);

	&:not(:empty) {
		margin-top: var(--spacing-4);
		row-gap: var(--spacing-5);
	}
`;

interface CurrentClipProps extends CurrentClipState {
	title: string;
	subTitle?: string | ReactNode;
	byLine?: string | ReactNode;
	isPrivate?: boolean;
	isHighlight?: boolean;
	description: string;
	children?: ReactNode | ReactNode[] | ReactElement;
}

export default function CurrentClip({
	title,
	subTitle,
	byLine,
	isPrivate,
	isHighlight,
	description,
	children,
}: CurrentClipProps): JSX.Element {
	return (
		<Wrapper isPrivate={isPrivate} isHighlight={isHighlight}>
			<Header>
				<Title isPrivate={isPrivate} isHighlight={isHighlight}>
					{isHighlight && <Icon name="star" />}
					{isPrivate && <Icon name="eye-hidden" />}
					<span>{title}</span>
				</Title>
			</Header>
			{subTitle && <SubTitle>{subTitle}</SubTitle>}
			<Content>
				{!!description.length && (
					<FormattedContent
						raw={description}
						maxLength={FormattedContentMaxLength.Standard}
						allowedFormatters={['line-breaks', 'links']}
					/>
				)}
				{byLine && <SubTitle>{byLine}</SubTitle>}
			</Content>
			<Content>{children}</Content>
		</Wrapper>
	);
}
