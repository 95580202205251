import { JSX } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import { useSmallScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';

import Button from 'design/button';

import { FormActionFooter } from 'styles/Form';

interface Props {
	dataHasBeenChanged: boolean;
	hasRequiredFields: boolean;
	hasValidPriceOptions: boolean;
	isEdit?: boolean;
	groupId?: number;
}

export const FormActionBar = ({
	dataHasBeenChanged,
	hasValidPriceOptions,
	isEdit = false,
	hasRequiredFields,
	groupId,
}: Props): JSX.Element => {
	const org = useCurrentOrganization();
	const context = useFormContext();
	const formIsDirty = context.formState.isDirty || dataHasBeenChanged;

	const isSmallScreen = useSmallScreen();

	return (
		<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
			<Button
				href={routes.Registrations.Index(org.id, groupId, 'active')}
				block={isSmallScreen}
				large={isSmallScreen}>
				{t('Cancel')}
			</Button>
			<Button
				block={isSmallScreen}
				large={isSmallScreen}
				primary
				type="submit"
				testid="form.create.save"
				disabled={
					isEdit
						? !formIsDirty || !hasValidPriceOptions
						: !hasRequiredFields || !hasValidPriceOptions
				}>
				{isEdit ? t('Save') : t('Create')}
			</Button>
		</ActionBar.SaveBar>
	);
};

export const ActionFooter = ({
	dataHasBeenChanged,
	hasValidPriceOptions,
	isEdit = false,
	hasRequiredFields,
	groupId,
}: Props) => {
	const org = useCurrentOrganization();
	const context = useFormContext();
	const formIsDirty = context.formState.isDirty || dataHasBeenChanged;

	return (
		<FormActionFooter>
			<Button href={routes.Registrations.Index(org.id, groupId, 'active')}>
				{t('Cancel')}
			</Button>
			<Button
				primary
				type="submit"
				disabled={
					isEdit
						? !formIsDirty || !hasValidPriceOptions
						: !hasRequiredFields || !hasValidPriceOptions
				}>
				{isEdit ? t('Save') : t('Create')}
			</Button>
		</FormActionFooter>
	);
};
