import { Dispatch } from 'redux';
import { t } from '@transifex/native';

import AccountRelation from 'pkg/models/account_relation';

import { normalizedDispatch } from 'pkg/actions/utils';
import * as actionTypes from 'pkg/actions/action-types';
import * as appActions from 'pkg/actions/app';
import * as flashActions from 'pkg/actions/flashes';
import * as services from 'pkg/actions/services';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import store from 'pkg/store/createStore';

interface AccountRelationsCreatePayload {
	type: string;
	email: string;
}

export const fetchAccountRelations =
	(includeRequests: boolean = false) =>
	async (dispatch: Dispatch): Promise<void> => {
		const [request, response] =
			await services.accountRelations.fetchAccountRelations(includeRequests);

		if (!request.ok) {
			appActions.triggerError(request, response)(dispatch);
			return;
		}

		normalizedDispatch(response.records, [AccountRelation.normalizr()])(
			dispatch
		);
	};

export async function createRequest(
	type: string,
	email: string
): Promise<[Response, any]> {
	const [request, response, error] = await models.create<
		AccountRelationsCreatePayload,
		models.accountRelation.AccountRelation
	>(endpoints.AccountRelations.Create(), { type, email });

	if (!request.ok) {
		flashActions.show(
			{
				title: t('Something went wrong'),
				message: error.error,
			},
			request.status
		);

		return [request, response];
	}

	normalizedDispatch(response, AccountRelation.normalizr())(store.dispatch);

	flashActions.show(
		{
			title: t(`Request sent`),
			message: t(`Request sent to {userEmail}`, {
				userEmail: email,
			}),
		},
		request.status
	);

	return [request, response];
}

export const approveRequest =
	(accountRelationId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const [request, response] =
			await services.accountRelations.approveAccountRelationRequest(
				accountRelationId
			);

		// @NOTE Remove current relation from state, a new one is returned from API
		dispatch({
			type: actionTypes.AccountRelations.DELETE_ITEM,
			accountRelationId,
		});

		if (!request.ok) {
			appActions.triggerError(request)(dispatch);
			return;
		}

		normalizedDispatch(response, AccountRelation.normalizr())(dispatch);
	};

export const remove =
	(accountRelationId: number) =>
	async (dispatch: Dispatch): Promise<void> => {
		const [request] =
			await services.accountRelations.removeAccountRelation(accountRelationId);

		if (!request.ok) {
			appActions.triggerError(request)(dispatch);
			return;
		}

		dispatch({
			type: actionTypes.AccountRelations.DELETE_ITEM,
			accountRelationId,
		});
	};

export const setActiveRequestItem =
	(itemId: number) =>
	(dispatch: Dispatch): void => {
		dispatch({
			type: actionTypes.AccountRelations.SET_ACTIVE_REQUEST_ITEM,
			payload: { itemId },
		});
	};
