import { useState, useEffect } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { setConfirmNavigation } from 'pkg/router/utils';

import RegistrationForm from 'routes/forms/registration/form';
import SelectUser from 'routes/forms/registration/form/select_user';

import { Spinner } from 'components/loaders/spinner';
import { useTemplateContext } from 'components/layout/page-templates/context';

interface Props {
	guid: string;
}

interface ShowFormResponse {
	form: models.form.Form;
	paymentProviderSettings: models.providerSettings.ProviderSettings;
}

const Registration = ({ guid }: Props) => {
	const { setPageTitle } = useTemplateContext();
	const [selectedAccount, setSelectedAccount] =
		useState<models.account.Account>(null);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const { record: response, isLoading } = useEndpoint<ShowFormResponse>(
		endpoints.Forms.PublicShow(guid)
	);

	useEffect(() => {
		if (response?.form?.title) {
			setPageTitle(response.form.title);
		}
	}, [isLoading]);

	const handleSelectAccount = (account: models.account.Account) => {
		setSelectedAccount(account);
	};

	useComponentDidMount(
		() => {
			setConfirmNavigation(
				t('Are you sure you want to leave without completing the form?', {
					_context: 'registrations',
				})
			);
		},
		() => {
			setConfirmNavigation(null);
		}
	);

	useEffect(() => {
		if (isSubmitting) {
			setConfirmNavigation(null);
		}
	}, [isSubmitting]);

	if (isLoading) {
		return <Spinner />;
	}

	return selectedAccount ? (
		<RegistrationForm
			form={response.form}
			submittedForAccount={selectedAccount}
			paymentProviderSettings={response.paymentProviderSettings}
			setIsSubmitting={setIsSubmitting}
			isSubmitting={isSubmitting}
		/>
	) : (
		<SelectUser form={response.form} onSelectAccount={handleSelectAccount} />
	);
};

export default Registration;
