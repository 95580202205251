import { Fragment, useState } from 'react';
import DOM from 'react-dom';
import styled, {
	keyframes,
	css,
	ThemeProvider,
	useTheme,
} from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import useKeyBindings from 'pkg/hooks/useKeyBindings';
import * as models from 'pkg/api/models';

import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import Backdrop from 'components/modals/BackdropGallery';
import FileInfo from 'components/attachment/gallery/FileInfo';
import GalleryItem from 'components/attachment/gallery/GalleryItem';

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: scale(0.9, 0.9);
	}

	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
`;

const fadeOutAnimation = keyframes`
	100% {
		opacity: 0;
		transform: scale(0.9, 0.9);
	}

	0% {
		opacity: 1;
		transform: scale(1, 1);
	}
`;

const slideUpAnimation = keyframes`
	0% {
		transform: translateY(100%);
	}

	100% {
		transform: translateY(5px);
		transform: translateY(calc(env(safe-area-inset-top) + 5px));
	}
`;

const slideDownAnimation = keyframes`
	0% {
		transform: translateY(5px);
		transform: translateY(calc(env(safe-area-inset-top) + 5px));
		opacity: 1;
	}

	100% {
		transform: translateY(100vh);
		opacity: 0.8;
		box-shadow: none;
	}
`;

const ModalBase = styled.div<{ index: number }>`
	display: flex;
	flex-direction: column;
	flex-grow: 0;
	flex-shrink: 0;

	overflow: auto;
	overflow-x: hidden;
	position: relative;
	margin: 0;
	animation-duration: 400ms;
	animation-timing-function: ease-out;
	animation-iteration-count: 1;

	background: var(--palette-gray-800);

	@media all and (min-width: ${styles.breakpoint.fromSmall}px) {
		${(props) =>
			props.theme.animationDirection === 'in'
				? css`
						animation-name: ${fadeInAnimation};
					`
				: css`
						opacity: 0;
						animation-name: ${fadeOutAnimation};
						animation-duration: 250ms;
					`};
	}

	transition: max-width 200ms;
	max-width: 100%;
	width: 100%;
	height: 100%;
	max-height: 100%;

	z-index: ${({ index }) => index + 1};

	@media ${styles.breakpoint.small} {
		height: calc(100% - 5px);
		height: calc(100% - env(safe-area-inset-top) - 5px);

		transform: translateY(5px);
		transform: translateY(calc(env(safe-area-inset-top) + 5px));

		${(props) =>
			props.theme.animationDirection === 'in'
				? css`
						animation-name: ${slideUpAnimation};
					`
				: css`
						transform: translateY(100vh);
						animation-name: ${slideDownAnimation};
						animation-duration: 200ms;
					`};

		animation-duration: 250ms;
	}
`;

const CloseButton = styled.span`
	text-decoration: none;
	line-height: 1;
	font-size: var(--font-size-2xl);
	font-weight: var(--font-weight-bold);
	padding: var(--spacing-4);
	display: flex;
	justify-content: center;
	cursor: pointer;
	color: var(--palette-gray-500);
	width: 60px;

	@media (hover: hover) {
		&:hover {
			color: var(--palette-blue-500);
		}
	}

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-2xl);
	}
`;

const TopHolder = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 60px;
	border-bottom: 1px solid var(--palette-gray-600);
	padding: var(--spacing-2) 0 var(--spacing-2) var(--spacing-5);
	color: var(--palette-white);
	z-index: 2;
	background: var(--palette-gray-800);

	&::before {
		content: '';
		position: absolute;
		right: 60px;
		top: 0;
		height: 100%;
		min-height: 60px;
		width: 1px;
		background-color: var(--palette-gray-600);

		@media ${styles.breakpoint.small} {
			display: none;
		}
	}

	@media ${styles.breakpoint.small} {
		background: var(--palette-gray-800);
		position: fixed;
		padding: var(--spacing-2) var(--spacing-3) var(--spacing-2) var(--spacing-5);
	}
`;

const Arrow = styled.span<{ right?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 87px;
	height: 87px;
	background-color: ${rgba(styles.palette.white, 0.07)};
	border-radius: 100%;
	overflow: hidden;
	position: absolute;
	left: ${({ right }) => (right ? 'auto' : '25px')};
	right: ${({ right }) => (right ? '25px' : 'auto')};
	top: calc(50% + 30px);
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 2;

	@media ${styles.breakpoint.small} {
		width: 64px;
		height: 64px;
		background-color: ${rgba(styles.palette.black, 0.45)};
		left: ${({ right }) => (right ? 'auto' : '0')};
		right: ${({ right }) => (right ? '0' : 'auto')};
		transform: ${({ right }) =>
			right ? 'translate(50%, -50%)' : 'translate(-50%, -50%)'};
	}

	.${iconStyles.icon} {
		font-size: 45px;
		width: auto;
		transform: ${({ right }) => !right && 'rotate(180deg)'};

		@media ${styles.breakpoint.small} {
			font-size: 38px;
			transform: ${({ right }) =>
				!right ? 'translateX(30%) rotate(180deg)' : 'translateX(-30%)'};
		}
	}
`;

interface ModalProps {
	attachments?: models.attachment.Attachment[];
	activeId?: number;
	onClose?: (pr: Promise<boolean>) => void;
}

export const GalleryModal = ({
	attachments = [],
	activeId = 0,
	onClose,
}: ModalProps) => {
	const currentIndex = useNewTopIndex();
	const theme = useTheme();

	const [animationDirection, setAnimationDirection] = useState('in');
	const [activeSlide, setActiveSlide] = useState(() => {
		let idx = 0;
		if (attachments.length > 0) {
			idx = attachments.findIndex((a) => a.id === activeId);
		}

		if (idx === -1) {
			idx = 0;
		}

		return idx;
	});
	const numSlides = attachments.length;

	const close = () => {
		document.body.classList.remove('modal-open');
		setAnimationDirection('out');

		// @todo onAnimationEnd never fires, invoke onClose event here @hejrobin
		onClose(Promise.resolve(true));
	};

	const handleAnimationEnd = () => {
		if (animationDirection === 'out') {
			onClose(Promise.resolve(true));
		}
	};

	const handleNext = () => {
		if (activeSlide !== numSlides - 1) {
			setActiveSlide(activeSlide + 1);
		} else {
			setActiveSlide(0);
		}
	};

	const handlePrev = () => {
		if (activeSlide > 0) {
			setActiveSlide(activeSlide - 1);
		} else {
			setActiveSlide(numSlides - 1);
		}
	};

	useKeyBindings(
		{
			onArrowRight: () => handleNext,
			onArrowLeft: () => handlePrev,
			onEscape: () => close,
		},
		window,
		[activeSlide]
	);

	return DOM.createPortal(
		<ThemeProvider
			theme={{
				...theme,
				animationDirection,
			}}>
			<Backdrop index={currentIndex} key={animationDirection}>
				<ModalBase onAnimationEnd={handleAnimationEnd} index={currentIndex}>
					<TopHolder>
						{attachments.length > 0 && (
							<FileInfo attachment={attachments[activeSlide]} />
						)}

						<CloseButton onClick={close}>
							<Icon name="close" />
						</CloseButton>
					</TopHolder>

					{attachments.length > 1 && (
						<Fragment>
							<Arrow onClick={handlePrev}>
								<Icon name="chevron" fill={styles.palette.white} />
							</Arrow>
							<Arrow onClick={handleNext} right>
								<Icon name="chevron" fill={styles.palette.white} />
							</Arrow>
						</Fragment>
					)}

					{attachments.length > 0 && (
						<GalleryItem attachment={attachments[activeSlide]} />
					)}
				</ModalBase>
			</Backdrop>
		</ThemeProvider>,
		document.getElementById('modal-portal-container')
	);
};
