export function CancelDelete(accountID: number): string {
	return `/v1/accounts/${accountID}/cancel-delete`;
}

export function Create(): string {
	return `/v1/accounts`;
}

export function CreateChildAccountForAccount(accountID: number): string {
	return `/v1/accounts/${accountID}/create-child-account`;
}

export function CreateRelation(accountID: number): string {
	return `/v1/accounts/${accountID}/relations`;
}

export function Delete(accountID: number): string {
	return `/v1/accounts/${accountID}`;
}

export function GrantParentalConsent(accountID: number): string {
	return `/v1/accounts/${accountID}/parental-consent`;
}

export function GrantParentalConsentWithToken(): string {
	return `/v1/parental-consent`;
}

export function OnboardingInfo(): string {
	return `/v1/onboarding-info`;
}

export function RemoveParentalConsent(accountID: number): string {
	return `/v1/accounts/${accountID}/parental-consent`;
}

export function RemoveParentalConsentWithToken(): string {
	return `/v1/parental-consent`;
}

export function ResetPassword(): string {
	return `/v1/reset-password`;
}

export function ShowParentalConsent(): string {
	return `/v1/parental-consent`;
}

export function Update(accountID: number): string {
	return `/v1/accounts/${accountID}`;
}
