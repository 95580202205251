import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { UpdateEventSeriesUserPayload } from 'pkg/actions/event_series_users';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { EventSeriesUserResponseStatus } from 'pkg/api/models/event_series_user';

import { useEventSeriesContext } from 'routes/event/series/single';

import { IconName } from 'components/icon';

import StatusPickerTrigger from 'components/event/status-picker';

import * as Context from 'design/context_menu';

interface StatusPickerProps {
	eventSeries: models.eventSeries.EventSeries;
	eventSeriesUser: models.eventSeriesUser.EventSeriesUser;

	isOrganizer?: boolean;
}

export default function StatusPicker({
	eventSeries,
	eventSeriesUser,
}: StatusPickerProps): JSX.Element {
	const eventSeriesCtx = useEventSeriesContext();

	let icon: IconName = 'check-filled';
	let color = styles.palette.green[500];

	switch (eventSeriesUser.status) {
		case EventSeriesUserResponseStatus.Accepted:
			icon = 'check-filled';
			color = styles.palette.green[500];
			break;
		case EventSeriesUserResponseStatus.Declined:
			icon = 'close-circle-filled';
			color = styles.palette.red[500];
			break;
		case EventSeriesUserResponseStatus.Pending:
			icon = 'help-circle-filled';
			color = styles.palette.orange[500];
			break;
	}

	const handleDecline = async () => {
		const [req, eventSeriesUsers] = await actions.eventSeriesUsers.update(
			eventSeries.id,
			{
				userIDs: [eventSeriesUser.userId],
				status: EventSeriesUserResponseStatus.Declined,
			}
		);

		if (req) {
			eventSeriesUsers.forEach((user) =>
				eventSeriesCtx.eventSeriesEndpoint.replaceInRecord(
					eventSeries,
					'seriesUsers',
					user
				)
			);
		}
	};

	const handleAccept = async () => {
		const payload: UpdateEventSeriesUserPayload = {
			userIDs: [eventSeriesUser.userId],
			status: EventSeriesUserResponseStatus.Accepted,
		};

		const [req, eventSeriesUsers] = await actions.eventSeriesUsers.update(
			eventSeries.id,
			payload
		);

		if (req) {
			eventSeriesUsers.forEach((user) =>
				eventSeriesCtx.eventSeriesEndpoint.replaceInRecord(
					eventSeries,
					'seriesUsers',
					user
				)
			);
		}
	};

	const handleRemove = async () => {
		const userConsent = window.confirm(
			t('Are you sure you want to remove {user}?', {
				user: models.user.fullName(eventSeriesUser.user),
			})
		);

		if (!userConsent) {
			return;
		}

		const ok = await actions.eventSeriesUsers.remove(eventSeries.id, [
			eventSeriesUser.user.id,
		]);

		if (ok) {
			eventSeriesCtx.eventSeriesEndpoint.removeInRecord(
				eventSeries,
				'seriesUsers',
				eventSeriesUser
			);
		}
	};

	const handleRemoveAsOrganizer = async () => {
		const payload: UpdateEventSeriesUserPayload = {
			userIDs: [eventSeriesUser.userId],
			status: eventSeriesUser.status,
			role: models.eventSeriesUser.EventSeriesUserRole.Participant,
		};

		const [req, eventSeriesUsers] = await actions.eventSeriesUsers.update(
			eventSeries.id,
			payload
		);

		if (req) {
			eventSeriesUsers.forEach((user) =>
				eventSeriesCtx.eventSeriesEndpoint.replaceInRecord(
					eventSeries,
					'seriesUsers',
					user
				)
			);
		}
	};

	const handleAddAsOrganizer = async () => {
		const payload: UpdateEventSeriesUserPayload = {
			userIDs: [eventSeriesUser.userId],
			status: eventSeriesUser.status,
			role: models.eventSeriesUser.EventSeriesUserRole.Organizer,
		};

		const [req, eventSeriesUsers] = await actions.eventSeriesUsers.update(
			eventSeries.id,
			payload
		);

		if (req) {
			eventSeriesUsers.forEach((user) =>
				eventSeriesCtx.eventSeriesEndpoint.replaceInRecord(
					eventSeries,
					'seriesUsers',
					user
				)
			);
		}
	};

	return (
		<Fragment>
			<Context.Menu
				toggleWith={<StatusPickerTrigger color={color} icon={icon} />}>
				<Context.Item onClick={handleAccept}>
					<Context.ItemIcon name="published_with_changes" />
					{t('Accept event series')}
				</Context.Item>
				<Context.Item onClick={handleDecline}>
					<Context.ItemIcon name="sync_disabled" />
					{t('Decline event series')}
				</Context.Item>
				{models.canEdit(eventSeries) && (
					<Fragment>
						{eventSeriesUser.role === 'organizer' ? (
							<Context.Item onClick={handleRemoveAsOrganizer}>
								<Context.ItemIcon name="person_remove" />
								<span>{t('Remove as organizer')}</span>
							</Context.Item>
						) : (
							<Context.Item onClick={handleAddAsOrganizer}>
								<Context.ItemIcon name="person_edit" />
								<span>{t('Make organizer')}</span>
							</Context.Item>
						)}
						<Context.Divider />
						<Context.Item onClick={handleRemove} caution>
							<Context.ItemIcon name="person_off" />
							{t('Uninvite')}
						</Context.Item>
					</Fragment>
				)}
			</Context.Menu>
		</Fragment>
	);
}
