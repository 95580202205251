import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import { Header } from 'routes/profile/Header';
import CustomUserFields from 'routes/profile/CustomUserFields';
import BillingAddressInformation from 'routes/profile/BillingAddressInformation';
import BaseInformation from 'routes/profile/BaseInformation';
import ContactInformation from 'routes/profile/ContactInformation';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { Spinner } from 'components/loaders/spinner';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import Column from 'components/layout/column';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import Button from 'design/button';

interface ProfileProps {
	userId: number;
	icon: MaterialSymbolVariant;
}

export function Profile({ userId, icon }: ProfileProps): JSX.Element {
	const [pageTitle, setPageTitle] = useState('');
	const org = useCurrentOrganization();

	const { record: user, isLoading: isLoadingUser } =
		useEndpoint<models.user.User>(endpoints.Users.Show(userId), {}, (u) => {
			setPageTitle(models.user.fullName(u));
		});

	if (isLoadingUser) {
		return <Spinner />;
	}

	const content = (
		<Column spacing={styles.spacing._5}>
			<Button
				href={routes.User.Settings(org.id, user.id)}
				label={t('Edit profile')}
				testid="user_profile.settings_link"
				icon="edit"
			/>

			<BaseInformation user={user} />

			<ContactInformation user={user} />

			{user.fields?.length > 0 && <CustomUserFields user={user} />}

			<BillingAddressInformation user={user} />
		</Column>
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={icon} />
				<LargeScreenContent.Wrapper>
					<Header user={user} />
					<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
						{content}
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenHeader />
				<SmallScreenContent>
					<SmallLayout.Top>
						<Header user={user} />
					</SmallLayout.Top>
					<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
}
