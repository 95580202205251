import { JSX, ReactNode, Fragment, useState, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import useResizeObserver from 'use-resize-observer';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import uuid from 'pkg/uuid';
import { useEventListener } from 'pkg/hooks/events';
import rgba from 'pkg/rgba';

import { useToolbar } from 'routes/video/analyze/ToolbarState';

import Spine from 'components/annotations/tools/Spine';
import { toolConfig } from 'components/annotations/tools/ToolConfig';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useClipState } from 'components/video-analytics/ClipState';
import SingleAnnotation from 'components/annotations/SingleAnnotation';
import {
	useTimeline,
	generatePath,
} from 'components/annotations/AnnotationHooks';
import {
	IS_PLAYING,
	IS_PAUSED,
	IS_DISPLAYING,
	IS_EDITING,
	IS_TRIGGERED,
	IS_OUTSIDE_CLIP,
} from 'components/annotations/constants';

const Cover = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	opacity: 1;
	transition: opacity 0.2s;

	&.${IS_DISPLAYING} {
		pointer-events: none;
	}

	&.${IS_OUTSIDE_CLIP} {
		opacity: 0;
	}

	&.${IS_TRIGGERED} {
		cursor: crosshair;

		div,
		div svg {
			pointer-events: none;
		}
	}
`;

const Tooltip = styled(motion.div)`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background: ${rgba(styles.palette.gray[900], 0.8)};
	border: 1px solid var(--palette-gray-400);
	border-radius: var(--radius-5);
	padding: 5px 15px;
	font-size: 13px;
	font-weight: 600;
	color: white;
	z-index: 0;
`;

interface AnnotationsProps {
	children?: ReactNode | ReactNode[];
}

export default function Annotations({
	children,
}: AnnotationsProps): JSX.Element {
	const { isEditing, isPlaying, controller } = usePlayerState();
	const clipState = useClipState();
	const { currentTime } = usePlaybackState();
	const toolbar = useToolbar();

	const ref = useRef<HTMLDivElement>(null);
	const { width, height } = useResizeObserver<HTMLDivElement>({ ref });
	const coverSize = { width, height };
	const coverPosition = ref.current?.getBoundingClientRect();

	const {
		setAnnotations,
		setActiveAnnotation,
		setActiveKeyframe,
		activeAnnotationId,
	} = clipState;
	const annotations = clipState.getParsedAnnotations();

	const config = toolConfig[toolbar.activeTool];

	const { isWithinClip, tooltipText } = useTimeline(coverSize);

	const onCoverClick = (e: React.MouseEvent<HTMLElement>) => {
		// close open annotation?
		if (e.target === e.currentTarget && !toolbar.activeTool) {
			setActiveAnnotation('');
			setActiveKeyframe(null);
		}

		// create annotation?
		if (toolbar.activeTool) {
			controller.pause();

			if (config.multipoint) {
				// create temporary spine before adding annotation
				addMultiPoint(e, toolbar.activeTool);
			} else {
				// add annotation instantly
				addAnnotation(e, toolbar.activeTool);
			}
		}
	};

	const addAnnotation = (
		e: React.MouseEvent<HTMLElement>,
		tool: string,
		points?: JSONObject
	) => {
		const id = uuid();

		setActiveAnnotation(id.toString());

		// first keyframe, get default values from config
		const { left, top, width, height, move, resize, multipoint } = config;

		const firstKeyframe: JSONObject = {
			time: currentTime * 1000,
		};

		if (move) {
			// position from mouse cursor or default config position
			firstKeyframe.left = e
				? (e.clientX - coverPosition.x) / coverSize.width - (width || 0) / 2
				: left;
			firstKeyframe.top = e
				? (e.clientY - coverPosition.y) / coverSize.height - (height || 0) / 2
				: top;
		}

		// add size if available in config
		if (width && resize) {
			firstKeyframe.width = width;
			firstKeyframe.height = height;
		}

		// add points if multipoint
		if (multipoint && points) {
			firstKeyframe.points = points;
		}

		const keyframes = [firstKeyframe];
		const properties: any = { tool, keyframes };

		// preselected colors?
		const color = window.localStorage.getItem(`annotation_${tool}_color`);
		if (color) properties.color = color;
		const rotatingColor = window.localStorage.getItem(
			`annotation_${tool}_rotatingColor`
		);
		if (rotatingColor) properties.rotatingColor = rotatingColor;

		setAnnotations({ ...annotations, [id]: properties });

		setSpine(null);
		toolbar.setActiveTool('');

		// todo: figure out some better callback after element has been added and synced to timeline…
		setTimeout(() => {
			setActiveAnnotation(id + '');
		}, 100);
	};

	const [spine, setSpine] = useState<JSONObject | null>();

	useEventListener('annotation-deselect', () => {
		setSpine(null);
		setActiveAnnotation('');
		toolbar.setActiveTool('');
	});

	const addMultiPoint = (e: React.MouseEvent<HTMLElement>, tool: string) => {
		const { multipoint } = toolConfig[tool];

		const point: any = {
			left: (e.clientX - coverPosition.x) / coverSize.width,
			top: (e.clientY - coverPosition.y) / coverSize.height,
		};

		if (!spine) {
			// start spine and add first point
			setSpine({ 0: point });
		} else {
			const nofPoints = Object.values(spine).length;

			// look for existing point in same location. double click or click on existing point will finish spine
			const closeThreshold = 0.02; // within 2 percent?
			const existingPoint = Object.values(spine).find(
				(p: any) =>
					Math.abs(p.left - point.left) < closeThreshold &&
					Math.abs(p.top - point.top) < closeThreshold
			);

			let newSpine = { ...spine };
			if (!existingPoint) {
				// add spine point
				newSpine = { ...spine, [nofPoints]: point };
				setSpine(newSpine);
			}

			if (
				existingPoint ||
				(multipoint !== true && multipoint === nofPoints + 1)
			) {
				// add annotation with points and first keyframe, cancel spine
				addAnnotation(e, toolbar.activeTool, newSpine);
			}
		}
	};

	return (
		<Fragment>
			{children}

			{annotations && (
				<Cover
					ref={ref}
					onClick={onCoverClick}
					className={`
					${isEditing ? IS_EDITING : IS_DISPLAYING}
					${isPlaying ? IS_PLAYING : IS_PAUSED}
					${!isEditing && !isWithinClip ? IS_OUTSIDE_CLIP : ''}
					${toolbar.activeTool ? IS_TRIGGERED : ''}`}
					data-testid="annotations.cover">
					{Object.entries(annotations)
						.filter(([, annotation]) => toolConfig[annotation.tool]) // filter out deprecated tools
						.map(([id, annotation]) => (
							<SingleAnnotation
								key={id}
								annotationId={id}
								annotation={annotation}
								annotations={annotations}
								isCurrent={id === activeAnnotationId && isEditing && !isPlaying}
								setActiveAnnotation={setActiveAnnotation}
								coverSize={coverSize}
							/>
						))}
					<AnimatePresence>
						{spine && (
							<Fragment>
								<Spine
									drawing
									path={generatePath(spine)}
									closed={config.closedPath && Object.values(spine).length > 1}
									filled={config.closedPath}
								/>
							</Fragment>
						)}
					</AnimatePresence>
				</Cover>
			)}

			{tooltipText && (
				<Tooltip
					initial={{ scale: 0.8, top: '-5%', x: '-50%' }}
					exit={{ scale: 0.8, top: '-5%', x: '-50%' }}
					animate={{ scale: 1, top: '5%', x: '-50%' }}
					transition={{ ease: 'easeOut', duration: 0.3 }}>
					{tooltipText}
				</Tooltip>
			)}

			{spine && config.multipoint === true && (
				<Tooltip
					initial={{ scale: 0.8, bottom: '-5%', x: '-50%' }}
					exit={{ scale: 0.8, bottom: '-5%', x: '-50%' }}
					animate={{ scale: 1, bottom: '5%', x: '-50%' }}
					transition={{ ease: 'easeOut', duration: 0.3 }}>
					{t('Double click to finish shape', { _context: 'video/annotations' })}
				</Tooltip>
			)}
		</Fragment>
	);
}
