import { JSX, ChangeEvent, useContext } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { EventFormContext } from 'routes/event/create';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import { RangeInput } from 'components/user/activity/Form';
import Row from 'components/layout/row';

const PhysicalStrainSlider = styled(RangeInput)`
	margin-bottom: auto;
	margin-top: auto;
`;

const StrainText = styled.div`
	color: var(--palette-gray-600);
`;

const PhysicalStrain = (): JSX.Element => {
	const EventContext = useContext(EventFormContext);

	const handlePhysicalStrainChange = (e: ChangeEvent<HTMLInputElement>) =>
		EventContext.setFormState({
			physicalStrainDefault: parseInt(e.target.value, 10),
		});

	return (
		<Input.Group label={t('Physical strain')}>
			<Column>
				<StrainText>
					{t(
						`Enter the expected physical strain to track load levels of attendees.`
					)}
				</StrainText>
				<Row autoColumns="auto 1fr auto" align="center">
					<span>{t('Low')}</span>
					<PhysicalStrainSlider
						type="range"
						min="0"
						max="100"
						step="1"
						data-min-label={t('Low')}
						onChange={handlePhysicalStrainChange}
						data-max-label={t('High')}
						value={EventContext.formState.physicalStrainDefault}
					/>
					<span>{t('High')}</span>
				</Row>
			</Column>
		</Input.Group>
	);
};

export default PhysicalStrain;
