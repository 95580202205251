import {
	JSX,
	CSSProperties,
	ElementType,
	Fragment,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	DndContext,
	MouseSensor,
	TouchSensor,
	UniqueIdentifier,
	DraggableSyntheticListeners,
	useSensor,
	useSensors,
	closestCorners,
	DragOverlay,
	DragStartEvent,
	DragOverEvent,
} from '@dnd-kit/core';
import {
	useSortable,
	SortableContext,
	arrayMove,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
	restrictToVerticalAxis,
	restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

import { cssClasses } from 'pkg/css/utils';

interface Item {
	id: UniqueIdentifier;
}

interface SortableState {
	isDragging: boolean;
	theme: Theme;
}

export interface Theme {
	container?: string;
	activeContainer?: string;

	item?: string;
	activeItem?: string;
	siblingItem?: string;
	overlayItem?: string;
}

const ThemeContext = createContext<SortableState>({
	isDragging: false,
	theme: {},
});

const OverlayContext = createContext<boolean>(false);

interface SortableProps<T> {
	items: T[];
	idProp?: keyof T;
	theme?: Theme;
	as?: ElementType;
	onChange?: (items: T[]) => void;
	onBeforeChange?: (item: T) => void;
	renderWith: (item: T, isDragging?: boolean) => ReactNode;
}

export function Container<T>({
	items: initialItems,
	idProp = 'id' as keyof T,
	theme,
	as: DragTarget = 'div',
	onChange,
	onBeforeChange,
	renderWith,
}: SortableProps<T>): JSX.Element {
	const mouseSensor = useSensor(MouseSensor, {
		activationConstraint: {
			distance: 10,
		},
	});

	const touchSensor = useSensor(TouchSensor, {
		activationConstraint: {
			delay: 200,
			tolerance: 5,
		},
	});

	const sensors = useSensors(mouseSensor, touchSensor);

	const [activeId, setActiveId] = useState<string>(null);
	const [sortOrder, setSortOrder] = useState<number[]>([]);

	useEffect(() => {
		setSortOrder(initialItems.map((item) => item[idProp] as number));
	}, [initialItems.length]);

	const find = (itemId: string): T =>
		initialItems.find((item) => item[idProp].toString() === itemId);

	const activeItem = useMemo(() => {
		return find(activeId);
	}, [activeId]);

	const className = cssClasses(
		theme?.container,
		!!activeId ? theme?.activeContainer : undefined
	);

	const providedState: SortableState = {
		isDragging: activeId !== null,
		theme,
	};

	const dragStart = (event: DragStartEvent) => {
		// Force identifier to be a string, UniqueIdentifier allows for string | number.
		const activeId = event.active.id.toString();

		setActiveId(activeId);

		const item = find(activeId);

		if (item && onBeforeChange) {
			onBeforeChange(item);
		}
	};

	const dragEnd = () => {
		setActiveId(null);

		if (onChange) {
			const items = sortOrder.map((id) => {
				return initialItems.find((item) => item[idProp] === id);
			});
			onChange(items);
		}
	};

	const drag = (event: DragOverEvent) => {
		if (event.over && event.active.id !== event.over?.id) {
			//from
			const activeIndex = sortOrder.indexOf(event.over.id as number);
			//to
			const overIndex = sortOrder.indexOf(event.active.id as number);

			setSortOrder(arrayMove(sortOrder, activeIndex, overIndex));
		}
	};

	return (
		<ThemeContext.Provider value={providedState}>
			<DndContext
				sensors={sensors}
				modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
				collisionDetection={closestCorners}
				onDragStart={dragStart}
				onDragCancel={dragEnd}
				onDragEnd={dragEnd}
				onDragOver={drag}>
				<SortableContext
					items={sortOrder as any}
					strategy={verticalListSortingStrategy}>
					<DragTarget className={className}>
						{sortOrder.map((id) => {
							const item = initialItems.find((item) => item[idProp] === id);

							if (!item) {
								return null;
							}

							return <Fragment key={id}>{renderWith(item)}</Fragment>;
						})}
					</DragTarget>
				</SortableContext>
				<DragOverlay>
					<OverlayContext.Provider value={true}>
						{activeItem ? renderWith(activeItem, true) : null}
					</OverlayContext.Provider>
				</DragOverlay>
			</DndContext>
		</ThemeContext.Provider>
	);
}

interface ItemContext {
	attributes: Record<string, any>;
	listeners: DraggableSyntheticListeners;
	ref(node: Nullable<HTMLElement>): void;
}

const SortableItemContext = createContext<ItemContext>({
	attributes: {},
	listeners: undefined,
	ref: () => null,
});

interface ItemProps {
	id: UniqueIdentifier;
	as?: ElementType;
	wrapWith?: ElementType;
	children?: ReactNode | ReactNode[];
}

export function Item({
	id,
	as: DragTarget = 'div',
	wrapWith: Wrapper = 'div',
	children,
}: ItemProps): JSX.Element {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
	} = useSortable({ id });

	const { isDragging: insideActiveGroup, theme } = useContext(ThemeContext);
	const isDragOverlay = useContext(OverlayContext);

	const draggingWithinGroup =
		insideActiveGroup && !isDragging && !isDragOverlay;

	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef,
		}),
		[attributes, listeners, setActivatorNodeRef]
	);

	const style: CSSProperties = {
		touchAction: 'manipulation',
		cursor: isDragging ? 'grabbing' : 'grab',
		transform: CSS.Translate.toString(transform),
		transition,
	};

	const className = cssClasses(
		theme?.item,
		isDragging ? theme?.activeItem : undefined,
		draggingWithinGroup ? theme?.siblingItem : undefined,
		isDragOverlay ? theme?.overlayItem : undefined
	);

	return (
		<SortableItemContext.Provider value={context}>
			<DragTarget ref={setNodeRef} {...attributes} {...listeners} style={style}>
				<Wrapper className={className}>{children}</Wrapper>
			</DragTarget>
		</SortableItemContext.Provider>
	);
}
