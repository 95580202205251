import { JSX } from 'react';

import OEmbed from 'routes/video/shared/OEmbed';

import { ContainerProps } from 'components/video-analytics/Container';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { VideoUrl } from 'components/video-analytics/source/SourceUrl';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useCueState } from 'components/video-analytics/CueState';

interface VideoContainerProps extends ContainerProps {
	source: VideoUrl;
}

export default function VideoContainer({
	source,
	startsAt,

	onReady,
	onBuffering,
	onError,

	onHasDuration,
	onPlay,
	onPause,
}: VideoContainerProps): JSX.Element {
	const cueState = useCueState();
	const playerState = usePlayerState();
	const playbackState = usePlaybackState();

	const handleControllerChange = (controller: any) => {
		if (controller) {
			playerState.setController(controller);
		}

		playerState.setHasError(false);
	};

	const handleCanPlay = () => {
		playerState.setBuffering(false);
		playerState.setReady(true);

		if (onReady) {
			onReady();
		}
	};

	const handleHasDuration = (duration: number) => {
		playerState.setDuration(duration);

		if (onHasDuration) {
			onHasDuration(duration);
		}
	};

	const handlePlay = () => {
		playerState.setBuffering(false);
		playerState.setPlaying(true);

		if (onPlay) {
			onPlay();
		}
	};

	const handlePause = () => {
		playerState.setPlaying(false);

		if (onPause) {
			onPause();
		}
	};

	const handleWaiting = () => {
		playerState.setBuffering(true);

		if (onBuffering) {
			onBuffering();
		}
	};

	const handleResume = () => {
		playerState.setBuffering(false);
	};

	const handleError = () => {
		playerState.setHasError(true);

		if (onError) {
			onError();
		}
	};

	const handleTimeUpdate = (currentTime: number) => {
		playbackState.setCurrentTime(currentTime);

		if (cueState.currentCue && !playerState.gaplessPlayback) {
			const { endsAt } = cueState.currentCue;

			if (endsAt && currentTime >= endsAt) {
				cueState.setCueActive(false);
			}
		}
	};

	return (
		<OEmbed
			autoPlay
			startsAt={startsAt}
			url={source.getSourceUrl()}
			canvasRoundness={0}
			canvasBackdrop="transparent"
			onTimeUpdate={handleTimeUpdate}
			onControllerChange={handleControllerChange}
			onHasDuration={handleHasDuration}
			onCanPlay={handleCanPlay}
			onPlay={handlePlay}
			onPause={handlePause}
			onWaiting={handleWaiting}
			onResume={handleResume}
			onError={handleError}
		/>
	);
}
