import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import { crash } from 'pkg/errors/errors';

export interface CreateIndentityProviderPayload {
	displayName: string;
	requiredForIdentityUserTraits: models.identityProvider.IdentityUserTrait[];
}

export async function create(
	groupId: number,
	payload: CreateIndentityProviderPayload
): Promise<[boolean, models.identityProvider.IdentityProvider]> {
	const [request, response, error] = await models.create<
		CreateIndentityProviderPayload,
		models.identityProvider.IdentityProvider
	>(endpoints.IdentityProviders.Create(groupId), payload);

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
			message: error.error,
		});

		return [false, null];
	}

	return [true, response];
}

export interface UpdateIdentityProviderPayload
	extends CreateIndentityProviderPayload {
	ssoEnabled: boolean;
}

export async function update(
	groupId: number,
	identityProviderId: number,
	payload: UpdateIdentityProviderPayload
) {
	const resp = await sdk.put(
		endpoints.IdentityProviders.UpdateSSO(groupId, identityProviderId),
		{},
		payload
	);
	const json = await resp.json();

	if (!resp.ok) {
		flashActions.show({
			title: crash().title,
			message: json.error,
		});

		return [false, null];
	}

	return [true, json];
}

interface UrlResponse {
	url: string;
}

export async function getPortalUrl(
	groupId: number,
	providerId: number
): Promise<[boolean, UrlResponse]> {
	return fetchPortalUrl('sso', groupId, providerId);
}

export async function getPortalSyncUrl(
	groupId: number,
	providerId: number
): Promise<[boolean, UrlResponse]> {
	return fetchPortalUrl('sync', groupId, providerId);
}

async function fetchPortalUrl(
	type: 'sso' | 'sync',
	groupId: number,
	providerId: number
): Promise<[boolean, UrlResponse]> {
	const endpoint =
		type === 'sso'
			? endpoints.IdentityProviders.PortalSSO(groupId, providerId)
			: endpoints.IdentityProviders.PortalSync(groupId, providerId);

	const request = await sdk.get(endpoint);

	let response: any, message: string;
	let hasError: boolean = false;

	try {
		response = await request.json();

		if (response.error) {
			message = response.error;
		}
	} catch {
		if (!message) {
			message = t('Could not fetch Protal URL', { _context: 'sso' });
		}

		hasError = true;
	} finally {
		if (hasError) {
			flashActions.show({
				title: crash().title,
				message,
			});

			return [false, null];
		}

		return [true, response];
	}
}

export async function remove(
	groupId: number,
	identityProviderId: number
): Promise<boolean> {
	const resp = await sdk.destroy(
		endpoints.IdentityProviders.Delete(groupId, identityProviderId)
	);
	const json = await resp.json();

	if (!resp.ok) {
		flashActions.show({
			title: crash().title,
			message: json.error,
		});

		return false;
	}

	return true;
}

interface AttributeMappingsPayload {
	mappings: {
		userFieldId: number;
		key: string;
	}[];
}

export async function mappings(
	organizationId: number,
	identityProviderId: number,
	payload: AttributeMappingsPayload
): Promise<[Response, models.identityProvider.AttributeMapping[]]> {
	const request = await sdk.put(
		endpoints.IdentityProviders.UpdateMapping(
			organizationId,
			identityProviderId
		),
		{},
		payload
	);

	let response: any, message: string;
	let hasError: boolean = false;

	try {
		response = await request.json();

		if (response.error) {
			message = response.error;
		}
	} catch {
		if (!message) {
			message = t('Could not update attribute mappings', { _context: 'sso' });
		}

		hasError = true;
	} finally {
		if (hasError) {
			flashActions.show({
				title: crash().title,
				message,
			});

			return [request, null];
		}

		return [request, response];
	}
}

interface SyncResponse {
	syncEnabled: boolean;
}

export async function sync(
	groupId: number,
	identityProviderId: number,
	syncEnabled: boolean
): Promise<[Response, SyncResponse]> {
	const request = await sdk.put(
		endpoints.IdentityProviders.UpdateSync(groupId, identityProviderId),
		{},
		{
			syncEnabled,
		}
	);

	if (!request.ok) {
		return [request, null];
	}

	const response = await request.json();

	return [request, response];
}
