import { t } from '@transifex/native';
import styled from 'styled-components';
import {
	JSX,
	Fragment,
	MouseEvent,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import { List } from 'immutable';

import * as styles from 'pkg/config/styles';

import User from 'pkg/models/user';
import Tag from 'pkg/models/tag';
import Account from 'pkg/models/account';

import * as actions from 'pkg/actions';
import * as strings from 'pkg/strings';
import * as selectors from 'pkg/selectors';
import { useCurrentRoute } from 'pkg/router/hooks';
import { RootState } from 'pkg/reducers';
import {
	useGroupedClipAuthorAccounts,
	useGroupedTaggedUsers,
} from 'pkg/hooks/selectors';

import SelectableList from 'routes/video/shared/SelectableList';

import Icon from 'components/icon';
import Modal, { Step, useStepModalContext } from 'components/step-modal';
import Avatar from 'components/avatar';

import {
	FilterHighlights,
	FilterPrivate,
	useFilterState,
	useNumAppliedFilters,
} from 'components/video-analytics/FilterState';
import * as Input from 'components/form/inputs';
import AutoComplete from 'components/form/inputs/autocomplete';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './style.css';

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-3);
	position: relative;

	> :not(:first-child) {
		justify-content: start;
	}

	&[data-has-filters='true']::after {
		content: '';
		display: block;
		border-radius: var(--radius-5);
		background: var(--palette-blue-500);
		position: absolute;
		width: 10px;
		height: 10px;
		top: -5px;
		right: -5px;
	}
`;

interface FilterHeadingProps {
	onClear?: () => void;
}

function FilterHeading({ onClear }: FilterHeadingProps): JSX.Element {
	const contextMenu = useContext(ContextMenu.MenuContext);

	const clear = () => {
		contextMenu.close();

		if (onClear) {
			onClear();
		}
	};

	const done = () => {
		contextMenu.close();
	};

	return (
		<ContextMenu.Heading>
			<span>{t('Clip filters')}</span>
			<Row spacing={styles.spacing._3}>
				<Button small transparent onClick={clear}>
					{t('Clear')}
				</Button>
				<Button small primary onClick={done}>
					{t('Done')}
				</Button>
			</Row>
		</ContextMenu.Heading>
	);
}

const LabeledContextMenuItem = styled(ContextMenu.Item)`
	grid-template-columns: 22px 1fr auto;
	place-items: center;

	span:first-of-type {
		justify-self: start;
	}

	span:last-of-type {
		color: var(--palette-gray-400);
		font-size: var(--font-size-xs);
		text-align: right;
	}
`;

function TaggedUsersFilter(): JSX.Element {
	const { videoId } = useCurrentRoute();
	const filterState = useFilterState();
	const stepModalContext = useStepModalContext();

	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const groupedTaggedUsers = useGroupedTaggedUsers(videoId);

	const numTaggedUsers = List<User>(
		filterState.taggedUserIds.map((userId: number) =>
			groupedTaggedUsers.users.find((user: User) => user.id === userId)
		)
	).size;

	useEffect(() => {
		if (modalOpen) {
			actions.videos.fetch(videoId);
		}
	}, [modalOpen]);

	const open = () => {
		if (stepModalContext) {
			stepModalContext.goTo('tags');
		} else {
			setModalOpen(true);
		}
	};

	const close = () => setModalOpen(false);

	const trigger = (
		<LabeledContextMenuItem closeOnClick={false} onClick={open}>
			<Icon name="user" size={1.4} />
			<span>{t('Tagged users')}</span>
			<span>{t('{num} selected', { num: numTaggedUsers })}</span>
		</LabeledContextMenuItem>
	);

	const handleTaggedUsersChange = (userIds: number[]) =>
		filterState.setTaggedUsers(userIds);

	return (
		<Fragment>
			{trigger}
			{modalOpen && (
				<Modal onClose={close}>
					<Step title={t('Tagged users')} nextLabel={t('Done')} skipBody>
						<SelectableList
							selectable
							multipleChoice
							showControls
							showUngrouped={false}
							items={groupedTaggedUsers.users}
							groupBy={groupedTaggedUsers.groupBy}
							selectedIds={filterState.taggedUserIds}
							onChange={handleTaggedUsersChange}
						/>
					</Step>
				</Modal>
			)}
		</Fragment>
	);
}

function ClipAuthorsFilter(): JSX.Element {
	const { videoId } = useCurrentRoute();
	const filterState = useFilterState();
	const stepModalContext = useStepModalContext();

	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const groupedAccounts = useGroupedClipAuthorAccounts(videoId);

	const numSelectedAccounts = List<Account>(
		filterState.authorAccountIds.map((accountId: number) =>
			groupedAccounts.accounts.find(
				(account: Account) => account.id === accountId
			)
		)
	).size;

	const open = () => {
		if (stepModalContext) {
			stepModalContext.goTo('tags');
		} else {
			setModalOpen(true);
		}
	};

	const close = () => setModalOpen(false);

	const trigger = (
		<LabeledContextMenuItem closeOnClick={false} onClick={open}>
			<Icon name="edit" size={1.4} />
			<span>{t('Clip authors')}</span>
			<span>{t('{num} selected', { num: numSelectedAccounts })}</span>
		</LabeledContextMenuItem>
	);

	const handleSelectedAccountChange = (accountIds: number[]) =>
		filterState.setAuthors(accountIds);

	return (
		<Fragment>
			{trigger}
			{modalOpen && (
				<Modal onClose={close}>
					<Step title={t('Clip authors')} nextLabel={t('Done')} skipBody>
						<SelectableList
							selectable
							multipleChoice
							showControls
							showUngrouped
							ungroupedLabel={t('Other')}
							items={groupedAccounts.accounts}
							groupBy={groupedAccounts.groupBy}
							selectedIds={filterState.authorAccountIds}
							onChange={handleSelectedAccountChange}
						/>
					</Step>
				</Modal>
			)}
		</Fragment>
	);
}

function VisibilityFilterOptions(): JSX.Element {
	const filterState = useFilterState();

	const highlightOptions = [
		{
			label: t('Show highlights'),
			isActive: filterState.showHighlights,
			onClick: () => filterState.setHighlights(FilterHighlights.Show),
		},
		{
			label: t('Hide highlights'),
			isActive: filterState.hideHighlights,
			onClick: () => filterState.setHighlights(FilterHighlights.Hide),
		},
		{
			label: t('Only highlights'),
			isActive: filterState.onlyHighlights,
			onClick: () => filterState.setHighlights(FilterHighlights.Only),
		},
	];

	const privateOptions = [
		{
			label: t('Show private'),
			isActive: filterState.showPrivate,
			onClick: () => filterState.setPrivate(FilterPrivate.Show),
		},
		{
			label: t('Hide private'),
			isActive: filterState.hidePrivate,
			onClick: () => filterState.setPrivate(FilterPrivate.Hide),
		},
		{
			label: t('Only private'),
			isActive: filterState.onlyPrivate,
			onClick: () => filterState.setPrivate(FilterPrivate.Only),
		},
	];

	return (
		<Fragment>
			<ContextMenu.Label>{t('Filter highlight clips')}</ContextMenu.Label>
			{highlightOptions.map(({ label, isActive, onClick }, index) => (
				<ContextMenu.Item key={index} onClick={onClick} closeOnClick={false}>
					<Input.Control standalone type="radio" checked={isActive} /> {label}
				</ContextMenu.Item>
			))}
			<ContextMenu.Divider />
			<ContextMenu.Label>{t('Filter private clips')}</ContextMenu.Label>
			{privateOptions.map(({ label, isActive, onClick }, index) => (
				<ContextMenu.Item key={index} onClick={onClick} closeOnClick={false}>
					<Input.Control standalone type="radio" checked={isActive} /> {label}
				</ContextMenu.Item>
			))}
		</Fragment>
	);
}

const TagItem = styled.span`
	background: var(--palette-blue-500);
	display: inline-block;
	margin: 0 var(--spacing-2) var(--spacing-2) 0;
	padding: var(--spacing-3);
	font-size: var(--font-size-xs);
	color: var(--palette-white);
	border-radius: var(--radius-2);
	cursor: pointer;

	svg {
		margin-left: var(--spacing-2);
		pointer-events: none;
	}

	&[data-type='text'] {
		background: var(--palette-gray-600);
	}
`;

export default function InlineFilter(): JSX.Element {
	const filterState = useFilterState();
	const numFilters = useNumAppliedFilters();
	const route = useCurrentRoute();

	const isAnalyzeMode = route.name === 'video.analyze';

	const tags = useSelector((state: RootState) =>
		selectors.tags.findAllByIds(state, filterState.tagIds)
	);

	const allTags = useSelector((state: RootState) =>
		selectors.videoSequences.findAllAvailableTags(state, route.videoId)
	);

	const users = useSelector((state: RootState) =>
		selectors.videoSequences.findAllTaggedUsers(state, route.videoId)
	).toArray();

	const suggestions = {
		[t('Tagged users')]: {
			items: users,
			onSelect: (user: User) => {
				filterState.refresh();
				filterState.setTaggedUsers([...filterState.taggedUserIds, user.id]);
			},
			getValue: (user: User) => user.fullName,
			renderWith: (user: User) => (
				<Row columns="auto 1fr" align="center">
					<Avatar user={user} size={16} />
					<span>{user.fullName}</span>
				</Row>
			),
			filterWith: (keyword: string, user: User) =>
				strings.compare(user.fullName, keyword) >= 0.15,
		},
		[t('Tags')]: {
			items: allTags,
			onSelect: (tag: Tag) => {
				filterState.refresh();
				filterState.setTags([...filterState.tagIds, tag.id]);
			},
			getValue: (item: Tag) => item.name,
			renderWith: (item: Tag) => (
				<Row columns="auto 1fr" align="center">
					<Icon name="tag" size={1.3} />
					<span>{item.name.replace(/\_/g, ' ')}</span>
				</Row>
			),
		},
	};

	const removeSearch = () => {
		filterState.setSearch('');
	};

	const removeTag = (event: MouseEvent<HTMLSpanElement>) => {
		const tagId = Number.parseInt(event.currentTarget.dataset.tagId, 10);

		filterState.setTags(
			filterState.tagIds.filter((id: number) => id !== tagId)
		);
	};

	const handleSelectFallback = (keyword: string) => {
		filterState.refresh();
		filterState.setSearch(keyword);
	};

	return (
		<Wrapper data-has-filters={numFilters > 0}>
			<AutoComplete
				small
				placeholder={t('Filter clips...')}
				testid="video.filter.search"
				suggestions={suggestions}
				onSelectFallback={handleSelectFallback}>
				<Input.Prefix inline>
					<Icon name="search" size={1.2} />
				</Input.Prefix>
				<Input.Suffix transparent>
					<ContextMenu.Menu
						toggleWith={
							<Row
								spacing={styles.spacing._2}
								columns="auto 1fr"
								align="center"
								justify="center"
								className={css.filterTrigger}>
								<Icon name="filter" size={1.2} />
							</Row>
						}>
						<FilterHeading onClear={filterState.reset} />
						<TaggedUsersFilter />
						{!isAnalyzeMode && <ClipAuthorsFilter />}
						<ContextMenu.Divider />
						<VisibilityFilterOptions />
					</ContextMenu.Menu>
				</Input.Suffix>
			</AutoComplete>

			{(filterState.search || filterState.tagIds) && (
				<div>
					{filterState.search && (
						<TagItem data-type="text" onClick={removeSearch}>
							{filterState.search}
							<Icon name="close" />
						</TagItem>
					)}

					{tags.map((tag: Tag) => (
						<TagItem key={tag.id} data-tag-id={tag.id} onClick={removeTag}>
							{tag.name.replace(/\_/g, ' ')}
							<Icon name="close" />
						</TagItem>
					))}
				</div>
			)}
		</Wrapper>
	);
}
