import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import { isSameDay } from 'pkg/date';
import Link from 'pkg/router/Link';
import DateTime, { Granularity } from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import { getCalendarEventColorVars } from 'routes/group/calendar/config';

import Icon from 'components/icon';

import * as css from './styles.css';

interface EventProps {
	isPending?: boolean;
	event: models.event.Event;
}

export default function SingleEvent({
	isPending = false,
	event,
}: EventProps): JSX.Element {
	const org = useCurrentOrganization();
	const startsAtDateTime = DateTime.fromTimestamp(event.startsAt);
	const endsAtDateTime = DateTime.fromTimestamp(event.endsAt);

	const startsAtDate = new Date(event.startsAt * 1000);

	const isNotSameDay = !isSameDay(startsAtDate, endsAtDateTime);
	const calendarEventStyles = getCalendarEventColorVars(event.type);

	const startDate = DateTime.fromTimestamp(event?.startsAt);

	const formattedDate = startDate.toLocaleDateString({ day: 'numeric' });
	const formattedMonth = startDate.toLocaleDateString({ month: 'short' });
	const formattedDay = startDate.toLocaleDateString({ weekday: 'short' });

	const meetingTime = startsAtDateTime.prev(
		Granularity.minute,
		event.meetBeforeMinutes
	);

	const { id, title, location } = event;

	return (
		<div
			className={cssClasses(css.eventWrapper, isPending ? css.isPending : '')}
			style={{
				...calendarEventStyles,
			}}>
			<Link
				className={cssClasses(css.eventLink, isPending ? css.isPending : '')}
				style={{
					...calendarEventStyles,
				}}
				href={routes.Event.View(org.id, id, 'overview')}>
				<div className={css.eventHeader}>
					<div
						className={css.type}
						style={{
							...calendarEventStyles,
						}}>
						{eventTypeToTranslatedString(event.type)}
					</div>
					<div className={css.groupName}>{event.group?.name}</div>
				</div>
				<div className={css.eventContent}>
					<div className={css.eventDate}>
						<div className={css.dateNumber}>{formattedDate}</div>
						<div className={css.month}>{formattedMonth}</div>
					</div>
					<div className={css.eventInfo}>
						<div className={css.eventTitle}>
							{event.eventSeriesId && <Icon size={0.9} name="sync" />}

							{models.event.isCanceled(event) ? (
								<Fragment>
									<span className={css.allCaps}>{t('Canceled')} </span>
									<span className={css.strikeThrough}>{title}</span>
								</Fragment>
							) : (
								title
							)}
						</div>
						<div className={css.eventTimeWrapper}>
							<div className={css.eventTime}>
								<span>
									{formattedDay}{' '}
									{startsAtDateTime.toLocaleTimeString({
										hour: '2-digit',
										minute: '2-digit',
									})}
									–
									{endsAtDateTime.toLocaleTimeString({
										hour: '2-digit',
										minute: '2-digit',
									})}
									{isNotSameDay && (
										<Fragment>
											{' '}
											(
											{endsAtDateTime.toLocaleDateString({
												day: 'numeric',
												month: 'short',
											})}
											)
										</Fragment>
									)}
								</span>
							</div>{' '}
							{event.meetBeforeMinutes > 0 && (
								<div className={css.meetTime}>
									{t('Meet time {time}', {
										time: meetingTime.toTimeString(),
									})}
								</div>
							)}{' '}
						</div>
						<div className={css.eventLocation}>
							<Icon name="location" />
							{location}
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
}
