import { JSX } from 'react';
import { useT } from '@transifex/react';

import * as routes from 'pkg/router/routes';

import * as Drawer from 'design/navigation/drawer';

export default function NoOrganizationsNavigation(): JSX.Element {
	const t = useT();

	return (
		<Drawer.ItemGroup>
			<Drawer.Item href={routes.Home()} name={t('Home')} icon="home" />
		</Drawer.ItemGroup>
	);
}
