import { JSX } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { cssClasses } from 'pkg/css/utils';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import DateTime from 'pkg/datetime';

import { FormattedContent } from 'components/formatted-content';
import Icon, { IconName } from 'components/icon';
import Badge from 'components/Badge';

import * as Printable from 'components/layout/printable';
import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface PrintProps {
	sessionId: string;
}

export default function Print({ sessionId }: PrintProps): JSX.Element {
	const id = Number.parseInt(sessionId, 10);

	const org = useCurrentOrganization();
	const group = useCurrentGroup();

	const metaData = (
		icon: IconName,
		min: number,
		max: number,
		delimiter: string = '-'
	): JSX.Element => {
		let value = [min, max].join(delimiter);

		if (!min && !max) {
			return null;
		} else if (!min && max) {
			value = max.toString();
		} else if (min && !max) {
			value = min.toString();
		}

		return (
			<span className={css.metaDataItem}>
				<Icon name={icon} size={1.3} />
				{value}
			</span>
		);
	};

	const { record: session, isLoading: isLoadingSession } =
		useEndpoint<models.session.Session>(
			endpoints.TrainingSessions.Show(org.id, id)
		);

	const { record: event, isLoading: isLoadingEvent } =
		useEndpoint<models.event.Event>(
			session.eventId ? endpoints.Events.Show(session.eventId) : null
		);

	const isLoading = isLoadingSession || isLoadingEvent;

	if (isLoading) {
		return (
			<Printable.Sheet>
				<Spinner />
			</Printable.Sheet>
		);
	}

	const sessionMetaData: Printable.DataTableMap = {
		[`${t('Total time')}`]: t('{duration} min', {
			duration: models.session.duration(session),
		}),
	};

	if (event?.location) {
		sessionMetaData[t('Location')] = event.location;
	}

	if (event?.users?.length > 0) {
		const participants = event.users
			.filter(
				(eventUser: models.eventUser.EventUser) =>
					eventUser.status === 'accepted'
			)
			.map((eventUser: models.eventUser.EventUser) =>
				models.user.fullName(eventUser.user)
			)
			.join(', ');

		sessionMetaData[t('Participants')] = participants;
	}

	if (session.tags?.length > 0) {
		sessionMetaData[t('Session Tags')] = (
			<div className={css.tags}>
				{session.tags.map((tag: models.tag.Tag) => (
					<Printable.Tag key={tag.id}>{tag.name}</Printable.Tag>
				))}
			</div>
		);
	}

	const eventStartsAt = event?.startsAt
		? DateTime.fromTimestamp(event.startsAt)
		: null;

	return (
		<Printable.Sheet
			backUrl={routes.TrainingSession.Show(org.id, id)}
			backLabel={t('Back to session')}>
			<Printable.Block>
				<Row
					columns="30px 1fr auto"
					align="center"
					justify="start"
					justifyContent="start"
					spacing={styles.spacing._2}
					className={css.header}>
					<Badge badgeUrl={group?.profileImageUrl} />
					<Row
						align="center"
						className={css.headerText}
						spacing={styles.spacing._2}>
						<Printable.Heading size="medium">{org.name}</Printable.Heading>
						{group?.name && (
							<Printable.Heading size="medium">
								<span>{group.name}</span>
							</Printable.Heading>
						)}
					</Row>
					{event?.startsAt ? (
						<Column justify="end" spacing={styles.spacing._1}>
							<strong>{eventStartsAt.toLongDateString()}</strong>
							<time>{eventStartsAt.toTimeString()}</time>
						</Column>
					) : (
						<span />
					)}
				</Row>

				<Printable.Divider />

				<Printable.Heading>{session.title}</Printable.Heading>

				<Printable.DataTable data={sessionMetaData} />

				{session.description && (
					<Printable.Text>
						<FormattedContent
							raw={session.description}
							allowedFormatters={['line-breaks']}
						/>
					</Printable.Text>
				)}
			</Printable.Block>

			{session.blocks
				?.sort(
					(
						a: models.sessionBlock.SessionBlock,
						b: models.sessionBlock.SessionBlock
					) => a.order - b.order
				)
				.map((block: models.sessionBlock.SessionBlock) => (
					<Column key={block.id}>
						<Printable.Block>
							<Printable.Heading size="medium" withDivider>
								{block.title}
								<span>{t('{duration} min', { duration: block.duration })}</span>
							</Printable.Heading>
							{block.description && (
								<Printable.Text>
									<FormattedContent
										raw={block.description}
										allowedFormatters={['line-breaks']}
									/>
								</Printable.Text>
							)}
						</Printable.Block>

						{block.items
							?.sort(
								(
									a: models.sessionBlockItem.SessionBlockItem,
									b: models.sessionBlockItem.SessionBlockItem
								) => a.order - b.order
							)
							.filter(
								(item: models.sessionBlockItem.SessionBlockItem) =>
									!!item.exercise
							)
							.map((item: models.sessionBlockItem.SessionBlockItem) => (
								<Printable.Block key={item.id}>
									<Printable.SectionHeading>
										<Row
											columns="1fr auto"
											align="center"
											justify="start"
											justifyContent="start">
											<Printable.Heading size="small" withCounter>
												{item.exercise.title}
											</Printable.Heading>
											<div>
												{metaData(
													'area',
													item.exercise.areaWidth,
													item.exercise.areaLength,
													'×'
												)}
												{metaData(
													'age',
													item.exercise.minAge,
													item.exercise.maxAge
												)}
												{metaData(
													'nav-members',
													item.exercise.minParticipants,
													item.exercise.maxParticipants
												)}
											</div>
										</Row>
									</Printable.SectionHeading>

									<Row align="stretch" columns="repeat(2, 1fr)">
										<div className={css.exerciseContentWrapper}>
											<Column spacing={styles.spacing._3}>
												{item.tags?.length > 0 && (
													<div className={css.tags}>
														{item.tags.map((tag: models.tag.Tag) => (
															<Printable.Tag key={tag.id}>
																{tag.name}
															</Printable.Tag>
														))}
													</div>
												)}
												{item.exercise.introduction && (
													<Printable.Text size="small">
														<strong>
															<FormattedContent
																raw={item.exercise.introduction}
																allowedFormatters={['line-breaks']}
															/>
														</strong>
													</Printable.Text>
												)}
												{item.exercise.materialRequirements && (
													<Printable.Text size="small">
														{item.exercise.materialRequirements}
													</Printable.Text>
												)}
												{item.exercise.description && (
													<Printable.Text size="small">
														<FormattedContent
															raw={item.exercise.description}
															allowedFormatters={['line-breaks']}
														/>
													</Printable.Text>
												)}
												{item.exercise.keyPoints && (
													<Printable.Text size="small" italic>
														<FormattedContent
															raw={item.exercise.keyPoints}
															allowedFormatters={['line-breaks']}
														/>
													</Printable.Text>
												)}
											</Column>

											<div className={css.notes}>
												<span>{t('Notes')}</span>
											</div>
										</div>

										<div
											className={cssClasses(
												css.exerciseImageWrapper,
												!models.exercise.coverImage(item.exercise)?.url &&
													css.noThumbnail
											)}>
											<figure className={css.figure}>
												{models.exercise.coverImage(item.exercise)?.url ? (
													<img
														className={css.exerciseImage}
														src={models.exercise.coverImage(item.exercise)?.url}
													/>
												) : null}
											</figure>
										</div>
									</Row>
								</Printable.Block>
							))}
					</Column>
				))}
		</Printable.Sheet>
	);
}
