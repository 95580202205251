import { JSX, Fragment, ReactNode, RefObject, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as actions from 'pkg/actions';
import { popState } from 'pkg/router/state';
import { useVideoAspectRatio } from 'pkg/hooks/dimensions';

import SmallScreenAnalyzeLayout from 'routes/video/layouts/SmallScreenAnalyzeLayout';
import { LayoutContext } from 'routes/video/layouts/Layout';
import { Tool, useToolbar } from 'routes/video/analyze/ToolbarState';
import ToolbarItem from 'routes/video/analyze/ToolbarItem';
import { ToolSymbol } from 'routes/video/analyze/tool-symbols';
import ErrorOverlay from 'routes/video/shared/ErrorOverlay';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as LargeScreenHeader from 'components/navigation/header/large_screen/Styles';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { useCueState } from 'components/video-analytics/CueState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import InfoBox from 'components/form/info-box';
import Annotations from 'components/annotations/Annotations';
import { getAnnotationName } from 'components/annotations/utils';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

enum LayoutSizes {
	Controls = 50,
	Toolbar = 60,
	WideSidebar = 320,
	NarrowSidebar = 260,
}

const Wrapper = styled.div`
	padding: var(--spacing-2);
	padding-top: 0;
	height: 100%;
	display: grid;
	grid-template-columns: ${LayoutSizes.Toolbar}px 1fr ${LayoutSizes.NarrowSidebar}px;

	@media ${styles.breakpoint.nolimit} {
		grid-template-columns: ${LayoutSizes.Toolbar}px 1fr ${LayoutSizes.WideSidebar}px;
	}

	@media ${styles.breakpoint.small} {
		padding: 0;
		display: block;
	}
`;

const Container = styled.div<{
	clampWorkspaceHeight?: boolean;
}>`
	padding: var(--spacing-2);
	padding-top: 0;
	padding-bottom: 0;

	${({ clampWorkspaceHeight }) =>
		clampWorkspaceHeight &&
		css`
			display: flex;
			flex-flow: column;
		`}

	@media ${styles.breakpoint.small} {
		padding: 0;
	}

	@media not ${styles.breakpoint.small} {
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	}
`;

const CurrentClip = styled.div<{
	workspaceHeight: number;
	clampWorkspaceHeight: boolean;
}>`
	background: var(--palette-gray-800);
	border-radius: var(--radius-3);

	${({ workspaceHeight }) =>
		workspaceHeight > 0 &&
		css`
			min-height: ${workspaceHeight}px;
		`}

	${({ clampWorkspaceHeight }) =>
		clampWorkspaceHeight &&
		css`
			overflow: auto;
			flex: 1;
		`}
`;

const Controls = styled.div`
	padding-bottom: var(--sat);
	background-color: var(--palette-gray-800);
	color: var(--palette-gray-100);
	border-radius: 0 0 5px 5px;
	flex-shrink: 0;
	display: grid;
	grid-template-rows: auto ${LayoutSizes.Controls}px;
	z-index: ${styles.zIndex.videoAnalytics};
	font-size: var(--font-size-sm);

	&[data-standalone='true'] {
		padding-top: var(--spacing-2);
		background-color: transparent;
		display: block;
	}

	@media ${styles.breakpoint.small} {
		background-color: var(--palette-gray-900);
		width: 100%;
		border-radius: 0;
		position: sticky;
		bottom: 0;
		border-top: solid 1px var(--palette-gray-700);
	}
`;

const Sidebar = styled(Container)`
	@media not ${styles.breakpoint.small} {
		padding: 0;
	}

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-5);

		&[data-is-trimming='true'] {
			padding: 0;
			height: calc((100vh * 9 / 16) - 27px);
		}
	}
`;

const Source = styled.div<{ workspaceHeight: number }>`
	background-color: #000;
	border-radius: 5px 5px 0 0;
	overflow: hidden;
	position: relative;
	display: grid;
	place-items: center;
	z-index: ${styles.zIndex.videoAnalytics};
	flex-grow: 1;

	@media ${styles.breakpoint.small} {
		border-radius: 0;
		width: 100%;
		height: calc(100vw * 9 / 16);
		position: fixed;
		top: calc(60px + var(--sat));
		z-index: ${styles.zIndex.videoAnalytics + 10};
		border-bottom: solid 1px var(--palette-gray-700);
		box-shadow: var(--palette-black) 0 0 10px;
	}

	@media not ${styles.breakpoint.small} {
		&[data-standalone-controls] {
			border-radius: var(--radius-3);
		}

		${({ workspaceHeight }) => css`
			height: calc(
				100vh - (${workspaceHeight + 50}px + env(safe-area-inset-bottom))
			);
		`}
	}
`;

const Video = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const SmallScreenContainer = styled.div`
	position: relative;
	display: block;
	min-height: calc(100% - (var(--sat) + 85px));

	&::before {
		content: '';
		padding-top: 56.25%;
		display: block;
	}
`;

const InvalidSourceWrapper = styled.div`
	position: absolute;
	width: 100%;
	z-index: ${styles.zIndex.videoAnalytics + 10};
	top: 0;
	left: 0;
`;

const Header = styled(LargeScreenHeader.Header)`
	padding: 0 var(--spacing-3) 0 var(--spacing-4);
	display: grid;
	grid-template-columns: 1fr auto;
	gap: var(--spacing-8);

	h1 {
		display: block;
	}
`;

const Toolbar = styled.div`
	background: var(--palette-gray-800);
	border-radius: var(--radius-3);
`;

const AspectWrapper = styled.div`
	position: relative;
`;

interface AspectRatioWrapperProps {
	children: ReactNode | ReactNode[];
	forwardedRef: RefObject<HTMLElement>;
}

function AspectRatioWrapper({
	children,
	forwardedRef,
}: AspectRatioWrapperProps): JSX.Element {
	const [width, height] = useVideoAspectRatio(forwardedRef);

	return (
		<AspectWrapper
			style={{
				width,
				height,
			}}>
			{children}
		</AspectWrapper>
	);
}

interface LayoutProps {
	context?: LayoutContext;

	title?: string;
	subTitle?: string;
	pageAction?: ReactNode;
	contextItems?: ReactNode;

	bootstrap?: ReactNode;
	videoSource: ReactNode;
	currentClip: ReactNode;
	clipList: ReactNode;
	controls: ReactNode;
	timeline: ReactNode;

	clampWorkspaceHeight?: boolean;
	workspaceHeight?: number;
}

export default function Layout({
	title,
	pageAction,
	contextItems,

	bootstrap,
	videoSource,
	currentClip,
	clipList,
	controls,
	timeline,

	clampWorkspaceHeight = false,
	workspaceHeight = 140,
}: LayoutProps): JSX.Element {
	const observeableRef = useRef<HTMLDivElement>();

	const cueState = useCueState();
	const playerState = usePlayerState();
	const toolbar = useToolbar();

	const isSmallScreen = useMediaQuery({ maxWidth: styles.breakpoint.toMedium });
	const useStandalone = !isSmallScreen;

	useComponentDidMount(
		() => {
			toolbar.addTool({
				guid: 'label',
				label: getAnnotationName('label'),
				symbol: ToolSymbol.Text,
			});

			toolbar.addTool({
				guid: 'circle',
				label: getAnnotationName('circle'),
				symbol: ToolSymbol.Circle,
			});

			toolbar.addTool({
				guid: 'spotlight',
				label: getAnnotationName('spotlight'),
				symbol: ToolSymbol.Spotlight,
			});

			toolbar.addTool({
				guid: 'line',
				label: getAnnotationName('line'),
				symbol: ToolSymbol.Line,
			});

			toolbar.addTool({
				guid: 'connection',
				label: getAnnotationName('connection'),
				symbol: ToolSymbol.Connection,
			});

			toolbar.addTool({
				guid: 'arrow',
				label: getAnnotationName('arrow'),
				symbol: ToolSymbol.Arrow,
			});

			toolbar.addTool({
				guid: 'polygon',
				label: getAnnotationName('polygon'),
				symbol: ToolSymbol.Polygon,
			});
		},
		() => {
			cueState.reset();
			playerState.reset();
			actions.app.setDrawerVisibility(true);
		}
	);

	const exitAnalyzeMode = () => {
		popState();
	};

	return (
		<Fragment>
			{playerState.hasError && <ErrorOverlay />}
			{bootstrap}
			<LargeScreen>
				<LargeScreenHeader.Wrap>
					<Header>
						<LargeScreenHeader.PageTitle data-testid="video.page_title">
							{title}
						</LargeScreenHeader.PageTitle>
						<LargeScreenHeader.Actions>
							{contextItems && (
								<ContextMenu.Menu
									toggleWith={<Button small transparent icon="edit" />}>
									{contextItems}
								</ContextMenu.Menu>
							)}
							{pageAction}
							<Button
								small
								transparent
								iconPosition="right"
								icon="cancel"
								onClick={exitAnalyzeMode}>
								{t('Exit analytics')}
							</Button>
						</LargeScreenHeader.Actions>
					</Header>
				</LargeScreenHeader.Wrap>
				<LargeScreenContent.Wrapper disableScroll>
					<LargeScreenContent.Inner noPadding maxWidth={PageWidths.ULTRA_WIDE}>
						<Wrapper>
							<Toolbar>
								{toolbar.tools.map((tool: Tool) => (
									<ToolbarItem key={tool.guid} {...tool} />
								))}
							</Toolbar>
							<Container clampWorkspaceHeight={clampWorkspaceHeight}>
								<Source
									ref={observeableRef}
									workspaceHeight={workspaceHeight}
									data-standalone-controls={useStandalone}>
									{playerState.isReady && !playerState.hasValidSource && (
										<InvalidSourceWrapper>
											<InfoBox
												color="red"
												text={t('Invalid video source URL')}
											/>
										</InvalidSourceWrapper>
									)}
									<AspectRatioWrapper forwardedRef={observeableRef}>
										<Annotations>
											<Video>{videoSource}</Video>
										</Annotations>
									</AspectRatioWrapper>
								</Source>
								<LargeScreen>
									<CurrentClip
										clampWorkspaceHeight={clampWorkspaceHeight}
										workspaceHeight={
											clampWorkspaceHeight ? workspaceHeight : 0
										}>
										{currentClip}
									</CurrentClip>
								</LargeScreen>
							</Container>
							<LargeScreen>
								<Sidebar>{clipList}</Sidebar>
							</LargeScreen>
							<SmallScreen>
								<SmallScreenContainer>
									<Sidebar data-is-trimming={playerState.isTrimming}>
										{playerState.isTrimming ? currentClip : clipList}
									</Sidebar>
								</SmallScreenContainer>
								{!playerState.isEditing && (
									<Controls>
										{timeline}
										{controls}
									</Controls>
								)}
							</SmallScreen>
						</Wrapper>
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenAnalyzeLayout
					bootstrap={bootstrap}
					videoSource={videoSource}
					currentClip={currentClip}
					controls={controls}
					contextItems={contextItems}
				/>
			</SmallScreen>
		</Fragment>
	);
}
