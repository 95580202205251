import { JSX, useEffect, useState, memo } from 'react';

import { tlog } from 'pkg/tlog';

import { addFormatter } from 'components/formatted-content/FormattedContent';
import Embed from 'components/formatted-content/Embed';

interface VimeoOEmbedProps {
	url: string;
}

const VimeoOEmbed = memo(({ url }: VimeoOEmbedProps): JSX.Element => {
	const [resource, setResource] = useState<JSONObject>();

	useEffect(() => {
		const embed = async () => {
			try {
				const request = await fetch(
					`https://vimeo.com/api/oembed.json?url=${encodeURIComponent(url)}`
				);

				if (request.ok) {
					const response = await request.json();

					if (response.html) {
						setResource(response);
					}
				}
			} catch (e: any) {
				tlog.error(e);
			}
		};

		embed();
	}, []);

	if (resource) {
		return <Embed html={decodeURIComponent(resource.html as string)} />;
	}

	return (
		<a href={url} target="_blank" rel="noreferrer noopener">
			{url}
		</a>
	);
});

addFormatter('vimeo', {
	isEmbed: true,
	selector: /(^https?:\/\/?(?:www.)?(?:vimeo.com)(?:\S+)?)$/gi,
	decorator: (guid: string, match: string): JSX.Element => (
		<VimeoOEmbed key={guid} url={match} />
	),
});
