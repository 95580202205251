import { JSX, Fragment } from 'react';
import styled from 'styled-components';

import { ToolProps } from 'components/annotations/tools/ToolProps';
import { IS_EDITING } from 'components/annotations/constants';
import { getPreferenceValue } from 'components/annotations/tools/ToolConfig';

const SVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.${IS_EDITING} & {
		pointer-events: visible;
	}
`;

export default function Circle({
	annotationId,
	annotation,
	config,
	coverSize,
}: ToolProps): JSX.Element {
	const rotating = getPreferenceValue(annotation, 'rotating');
	const strokeWidth =
		getPreferenceValue(annotation, 'strokeWidth') * (coverSize.width / 1000);

	return (
		<SVG
			viewBox="0 0 100 100"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/SVG">
			{rotating ? (
				<RotatingCircle
					annotationId={annotationId}
					annotation={annotation}
					config={config}
					coverSize={coverSize}
				/>
			) : (
				<g>
					<circle
						cx="50"
						cy="50"
						r="49"
						fill={annotation.color || config.preferences.color.defaultValue}
						mask={'url(#mask-' + annotationId + ')'}
					/>
					<mask id={'mask-' + annotationId}>
						<rect x="0" y="0" width="100%" height="100%" fill="white" />
						<circle
							key={'force_remount' + Date.now()} // embarassing, but it works... forces the mask shape to update when changing shape
							cx="50"
							cy="50"
							r="49"
							strokeWidth={strokeWidth}
							stroke="white"
							fill="black"
							vectorEffect="non-scaling-stroke"
						/>
					</mask>
				</g>
			)}
		</SVG>
	);
}

const RotateGroup = styled.g`
	transform-origin: center;
	animation: spin 1.6s linear infinite;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}

	.back-circle {
		opacity: 0.5;
	}
`;

export const RotatingCircle = ({ annotation, config }: ToolProps) => {
	const strokeWidth = 9.61931;

	const color = annotation.color || config.preferences.color.defaultValue;
	const rotatingColor =
		annotation.rotatingColor || config.preferences.rotatingColor.defaultValue;

	return (
		<Fragment>
			<defs>
				<radialGradient
					id="mask-gradient"
					cx="50%"
					cy="50%"
					r="50%"
					fx="50%"
					fy="50%">
					<stop offset="20%" stopColor="#000000" stopOpacity="1" />
					<stop offset="100%" stopColor="#000000" stopOpacity="0" />
				</radialGradient>
			</defs>
			<mask id="backside">
				<rect x="0" y="0" width="100" height="100" fill="white" />
				<circle cx="50" cy="5" r="90" fill="url(#mask-gradient)" />
			</mask>
			<g mask="url(#backside)">
				<RotateGroup>
					<path
						d="M97.5952 50.0002C97.5952 76.2862 76.2861 97.5952 50.0001 97.5952C23.7141 97.5952 2.40513 76.2862 2.40513 50.0002C2.40513 23.7142 23.7141 2.40513 50.0001 2.40513C76.2861 2.40513 97.5952 23.7142 97.5952 50.0002Z"
						stroke={rotatingColor}
						className="back-circle"
						strokeWidth={strokeWidth / 2}
						fill="none"
					/>
					<path
						d="M91.9445 50C91.9445 73.1653 73.1653 91.9445 50 91.9445C26.8346 91.9445 8.05542 73.1653 8.05542 50C8.05542 26.8346 26.8346 8.05542 50 8.05542C73.1653 8.05542 91.9445 26.8346 91.9445 50Z"
						stroke={color}
						strokeWidth={strokeWidth / 2}
						fill="none"
					/>
					<mask
						id="mask-1"
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="100"
						height="100">
						<path
							d="M97.5952 50.0002C97.5952 76.2862 76.2861 97.5952 50.0001 97.5952C23.7141 97.5952 2.40513 76.2862 2.40513 50.0002C2.40513 23.7142 23.7141 2.40513 50.0001 2.40513C76.2861 2.40513 97.5952 23.7142 97.5952 50.0002Z"
							stroke="white"
							strokeWidth={strokeWidth / 2}
						/>
					</mask>
					<g mask="url(#mask-1)">
						<mask id="mask-4" fill="white">
							<path d="M100 50.0002C100 56.5662 98.7068 63.068 96.194 69.1343C93.6813 75.2006 89.9983 80.7125 85.3554 85.3554C80.7125 89.9983 75.2006 93.6813 69.1343 96.194C63.0681 98.7068 56.5663 100 50.0002 100L50.0002 50.0002H100Z" />
						</mask>
						<path
							d="M100 50.0002C100 56.5662 98.7068 63.068 96.194 69.1343C93.6813 75.2006 89.9983 80.7125 85.3554 85.3554C80.7125 89.9983 75.2006 93.6813 69.1343 96.194C63.0681 98.7068 56.5663 100 50.0002 100L50.0002 50.0002H100Z"
							stroke={rotatingColor}
							strokeWidth={strokeWidth}
							mask="url(#mask-4)"
						/>
					</g>
					<mask
						id="mask-2"
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="100"
						height="100">
						<path
							d="M2.4049 49.9999C2.40491 23.7139 23.7139 2.40489 49.9999 2.40489C76.2859 2.40489 97.5949 23.7139 97.5949 49.9999C97.5949 76.286 76.2859 97.595 49.9999 97.595C23.7139 97.595 2.4049 76.2859 2.4049 49.9999Z"
							stroke="white"
							strokeWidth={strokeWidth / 2}
							fill="none"
						/>
					</mask>
					<g mask="url(#mask-2)">
						<mask id="mask-3">
							<path
								fill="white"
								d="M1.20005e-05 49.9999C1.25745e-05 43.4338 1.2933 36.9321 3.80603 30.8658C6.31875 24.7995 10.0017 19.2876 14.6446 14.6447C19.2875 10.0018 24.7995 6.3188 30.8658 3.80607C36.932 1.29334 43.4338 5.5803e-05 49.9999 5.6664e-05L49.9999 49.9999L1.20005e-05 49.9999Z"
							/>
						</mask>
						<path
							d="M1.20005e-05 49.9999C1.25745e-05 43.4338 1.2933 36.9321 3.80603 30.8658C6.31875 24.7995 10.0017 19.2876 14.6446 14.6447C19.2875 10.0018 24.7995 6.3188 30.8658 3.80607C36.932 1.29334 43.4338 5.5803e-05 49.9999 5.6664e-05L49.9999 49.9999L1.20005e-05 49.9999Z"
							stroke={rotatingColor}
							strokeWidth={strokeWidth}
							fill="none"
							mask="url(#mask-3)"
						/>
					</g>
				</RotateGroup>
			</g>
		</Fragment>
	);
};
