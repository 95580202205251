import styled from 'styled-components';
import { JSX, CSSProperties, Fragment, MouseEvent, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useQueryState } from 'pkg/hooks/query-state';
import rgba from 'pkg/rgba';
import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import RelativeDateTime from 'components/RelativeDateTime';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import * as iconStyles from 'components/icon/styles.css';

import * as ContextMenu from 'design/context_menu';

const Cover = styled(Link)`
	--bg: var(--palette-gray-500);
	--fg: var(--palette-white);
	--aw: 16;
	--ah: 9;
	max-width: 100%;
	min-width: 0;
	aspect-ratio: var(--aw) / var(--ah);
	border-radius: var(--radius-5);
	background-color: var(--bg);
	background-position: 50% 50%;
	background-size: cover;
	position: relative;
	overflow: hidden;
	color: var(--fg) !important;

	&[data-is-embedded='true'] {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	img,
	video {
		object-fit: contain;
		width: 100%;
		height: 100%;
		pointer-events: none;
		user-select: none;
	}

	&[data-is-branded='true'] {
		img {
			width: 120px;
			height: 120px;
			opacity: 0.2;
			filter: grayscale(1);
			transform: translate(-50%, -50%);
			position: absolute;
			left: 50%;
			top: 50%;
			z-index: 10;
			pointer-events: none;
		}

		@media ${styles.breakpoint.small} {
			img {
				width: 90px;
				height: 90px;
			}
		}
	}

	/* @TODO Remove when we can drop iOS 14 */
	@supports not (aspect-ratio: 16 / 9) {
		&::before {
			float: left;
			padding-top: calc(var(--ah) / var(--aw) * 100%);
			content: '';
		}

		&::after {
			display: block;
			content: '';
			clear: both;
		}

		& > img,
		& > video {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: var(--radius-5);
		}
	}
`;

export const CoverTextContainer = styled.div`
	width: 100%;
	position: absolute;
	left: var(--spacing-4);
	top: var(--spacing-5);
	z-index: 30;
	color: currentColor;

	@media ${styles.breakpoint.small} {
		left: var(--spacing-3);
		top: var(--spacing-3);
	}
`;

export const CoverText = styled.span`
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	word-break: break-word;
	-webkit-box-orient: vertical;
	max-width: 60%;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-lg);
	color: currentColor;

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-sm);
		line-height: var(--font-line-height-sm);
		left: var(--spacing-3);
	}
`;

export const CoverType = styled.span`
	font-size: var(--font-size-xs);
	color: currentColor;
	display: block;
	margin-bottom: var(--spacing-3);

	svg {
		margin-left: var(--spacing-1);
		margin-right: var(--spacing-3);
	}

	@media ${styles.breakpoint.small} {
		margin-bottom: var(--spacing-2);
		font-size: var(--font-size-2xs);
	}
`;

const Container = styled.div`
	display: grid;
	grid-template-columns: 24px 1fr 20px;
	grid-template-areas: 'avatar title context-menu' 'avatar byline context-menu';
	grid-template-rows: repeat(2, auto);
	column-gap: var(--spacing-3);
	row-gap: var(--spacing-2);
	padding-top: var(--spacing-1);
	margin-bottom: var(--spacing-4);

	&[data-account-byline='false'] {
		@media ${styles.breakpoint.small} {
			grid-template-columns: 24px 1fr 20px;
			row-gap: var(--spacing-3);
			grid-template-areas: 'title title context-menu' 'avatar byline byline';
		}
	}

	&[data-is-embedded='false'] {
		height: 30px;
	}

	&[data-is-embedded='true'] {
		margin-bottom: var(--spacing-3);
		padding: var(--spacing-4);
		background: var(--palette-gray-200);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1);

		a {
			color: var(--palette-black);

			@media (hover: hover) {
				&:hover {
					color: var(--palette-gray-700);
				}
			}
		}
	}
`;

const Author = styled.div`
	grid-area: avatar;
	align-self: start;

	@media ${styles.breakpoint.small} {
		align-self: center;
	}
`;

const Title = styled(Link)`
	grid-area: title;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-word;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-sm);
	color: var(--palette-gray-900);

	@media ${styles.breakpoint.small} {
		-webkit-line-clamp: 3;
		line-clamp: 3;
	}

	mark {
		background-color: var(--palette-green-300);
	}

	@media (hover: hover) {
		&:hover {
			color: var(--palette-gray-700);
		}
	}
`;

const Byline = styled.div`
	grid-area: byline;
	font-size: var(--font-size-xs);
	color: var(--palette-gray-500);
	align-self: center;

	@media ${styles.breakpoint.small} {
		span,
		time {
			display: block;
		}
	}
`;

const Wrapper = styled.div`
	--content-row: max-content;

	display: grid;
	grid-template-rows: var(--content-row) auto;
	gap: var(--spacing-3);
	align-items: stretch;
	align-content: start;
	cursor: pointer;
	color: var(--palette-black);
	transition: transform 135ms ease-out;

	@media (hover: hover) {
		&:hover {
			transform: scale(1.022);
		}
	}

	&[data-is-embedded='true'] {
		row-gap: 0;
	}

	${Title} {
		transition: color 120ms ease-out;
	}

	${Cover} {
		transition: box-shadow 120ms ease-out;
	}

	@media (hover: hover) {
		&:hover {
			${Title} {
				color: var(--palette-blue-500);
			}

			${Cover} {
				box-shadow:
					${styles.boxShadow.cardHover},
					inset 0 0 0 1px hsla(0, 0%, 0%, 0.1);
			}
		}
	}

	&:not([data-item-type*='training']):not([data-is-embedded='true']) {
		@media ${styles.breakpoint.small} {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-template-rows: auto;
			gap: var(--spacing-4);
		}
	}
`;

const ContextMenuWrapper = styled.div`
	position: relative;
`;

const InteractableIcon = styled(Icon)`
	width: 24px;
	height: 24px;
	padding: var(--spacing-2);
	transition: color 120ms ease-out;
	position: absolute;

	@media (hover: hover) {
		&:hover {
			color: var(--palette-blue-500);
		}
	}

	@media ${styles.breakpoint.small} {
		grid-template-columns: 1fr;
		column-gap: var(--spacing-5);
	}
`;

export const VisibilityLabel = styled.span`
	padding: var(--spacing-2) var(--spacing-3);
	font-size: var(--font-size-2xs);
	font-weight: var(--font-weight-semibold);
	background: ${rgba(styles.palette.black, 0.85)};
	color: var(--palette-white);
	text-transform: uppercase;
	position: absolute;
	display: block;
	border-radius: var(--radius-2);
	bottom: var(--spacing-4);
	left: var(--spacing-4);
	z-index: 50;

	.${iconStyles.icon} {
		margin-right: var(--spacing-2);
	}

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-1) var(--spacing-2);
		bottom: var(--spacing-3);
		left: var(--spacing-3);
		font-size: var(--font-size-3xs);
	}

	@supports (backdrop-filter: blur(15px)) {
		background: ${rgba(styles.palette.black, 0.6)};
		backdrop-filter: blur(15px);
	}
`;

const IsNewLabel = styled(VisibilityLabel)`
	background: ${rgba(styles.palette.white, 0.95)};
	color: var(--palette-gray-800);
	left: auto;
	right: var(--spacing-4);
	top: var(--spacing-4);
	bottom: auto;

	@media ${styles.breakpoint.small} {
		right: var(--spacing-3);
		top: var(--spacing-3);
	}
`;

export interface GroupItemAction<T> {
	icon?: MaterialSymbolVariant;
	label: string;
	confirm?: string;
	onClick: (item: T) => void;
}

export enum GroupItemType {
	Video = 'video',
	VideoPlaylist = 'video-playlist',
	VideoCollection = 'video-collection',
	Exercise = 'training-exercise',
	TrainingSession = 'training-session',
	TrainingCollection = 'training-collection',
}

interface GroupItemProps<T> {
	href?: string;
	title: string;
	createdAt?: number;
	embedded?: boolean;
	aspectRatio?: string;
	backdropColor?: string;
	visibilityLabel?: string;
	isNew?: boolean;
	isBranded?: boolean;
	accountAsByline?: boolean;

	item?: T;
	itemType?: GroupItemType;
	actions?: GroupItemAction<T>[];
	account?: models.account.Account;
	user?: models.user.User;
	author?: models.author.Author;
	children?: ReactNode;
	className?: string;
	style?: CSSProperties;

	onClick?: (event?: MouseEvent) => void;
	onMouseEnter?: (event?: MouseEvent) => void;
	onMouseLeave?: (event?: MouseEvent) => void;
}

export default function GroupItem<T>({
	href,
	title,
	createdAt,
	embedded,
	aspectRatio = '16:9',
	backdropColor = styles.palette.gray[500],
	visibilityLabel,
	isNew,
	isBranded,
	accountAsByline,

	item,
	itemType,
	actions,
	account,
	user,
	author,
	children,
	className,
	style,

	onClick,
	onMouseEnter,
	onMouseLeave,
}: GroupItemProps<T>): JSX.Element {
	const qs = useQueryState();
	const keyword = (qs.get('search', '') as string)
		.toString()
		.toLocaleLowerCase();

	const [aw, ah] = aspectRatio
		.split(':')
		.map((value: string) => Number.parseInt(value ?? '1', 10));

	const coverStyles: { [key: string]: string | number } = {
		'--bg': backdropColor,
		'--aw': aw,
		'--ah': ah,
	};

	const generateClickHandler =
		(onClick?: (item: T) => void) =>
		(event?: MouseEvent): void => {
			event?.preventDefault();
			event?.stopPropagation();

			if (onClick) {
				onClick(item);
			}
		};

	const generateAsyncClickHandler =
		(onClick?: (item: T) => void) =>
		async (event?: MouseEvent): Promise<void> => {
			event?.preventDefault();
			event?.stopPropagation();

			if (onClick) {
				onClick(item);
			}
		};

	let itemTitle: ReactNode = title;

	if (keyword.length > 0) {
		const from = title.toLocaleLowerCase().indexOf(keyword);
		const to = from + keyword.length;
		const segment = title.substring(from, to);

		itemTitle = (
			<span
				dangerouslySetInnerHTML={{
					__html: title.replace(
						new RegExp(keyword, 'i'),
						`<mark>${segment}</mark>`
					),
				}}
			/>
		);
	}

	const isSmallScreen: boolean = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	const contextMenu = (
		<ContextMenuWrapper>
			{actions?.length > 0 && (
				<ContextMenu.Menu
					toggleWith={
						<InteractableIcon
							name="context-menu"
							rotate={isSmallScreen && '90deg'}
							size={isSmallScreen ? 1.25 : 0.8}
						/>
					}>
					{actions.map(({ icon, label, confirm, onClick }) => {
						if (confirm) {
							return (
								<Fragment key={label}>
									{actions.length > 1 && <ContextMenu.Divider />}
									<ContextMenu.ConfirmItem
										caution
										closeOnClick
										confirmLabel={t('Yes, delete')}
										onConfirm={generateAsyncClickHandler(onClick)}
										message={confirm}>
										{icon && <ContextMenu.ItemIcon name={icon} />}
										{label}
									</ContextMenu.ConfirmItem>
								</Fragment>
							);
						} else {
							return (
								<ContextMenu.Item
									key={label}
									onClick={generateClickHandler(onClick)}>
									{icon && <ContextMenu.ItemIcon name={icon} />}
									{label}
								</ContextMenu.Item>
							);
						}
					})}
				</ContextMenu.Menu>
			)}
		</ContextMenuWrapper>
	);

	let fullName = null;
	let byLinePrefix = null;

	if (author) {
		fullName = author.name;
		byLinePrefix = author.name;
	} else if (user) {
		const name = models.user.fullName(user);
		fullName = name;
		byLinePrefix = name;
	} else if (account) {
		fullName = models.account.fullName(account);
		byLinePrefix = `${models.account.fullName(account)} •`;
	}

	if (accountAsByline) {
		byLinePrefix = null;
	}

	let avatar = null;

	if (author) {
		avatar = <Avatar size={25} author={author} />;
	} else if (user) {
		avatar = <Avatar size={25} user={user} />;
	} else if (account) {
		avatar = <Avatar size={25} account={account} />;
	}

	return (
		<Wrapper
			data-is-embedded={embedded}
			data-item-type={itemType}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={style}>
			<Cover
				href={href}
				data-is-branded={isBranded}
				data-is-embedded={!!embedded}
				data-account-byline={!!accountAsByline}
				className={className}
				style={coverStyles as CSSProperties}>
				{children}
				{visibilityLabel && (
					<VisibilityLabel>
						<Icon size={1.5} name="eye-visible" />
						{visibilityLabel}
					</VisibilityLabel>
				)}
				{isNew && <IsNewLabel>{t('New')}</IsNewLabel>}
			</Cover>
			{(account || user || author) && createdAt && (
				<Container
					data-account-byline={!!accountAsByline}
					data-is-embedded={embedded}>
					<Author>{avatar}</Author>
					<Title href={href} title={title}>
						{accountAsByline ? fullName : itemTitle}
					</Title>
					{contextMenu}
					<Byline data-account-as-byline={accountAsByline}>
						<SmallScreen>
							{!accountAsByline && <span>{fullName}</span>}
							<RelativeDateTime dateTime={createdAt} hideTime />
						</SmallScreen>
						<LargeScreen>
							<RelativeDateTime
								prefix={byLinePrefix}
								dateTime={createdAt}
								hideTime
							/>
						</LargeScreen>
					</Byline>
				</Container>
			)}
		</Wrapper>
	);
}
