// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JjUQaGAoXPMzwdPaHAVo {
	width: var(--page-width-wide);
	margin: 0 auto;
	padding: var(--spacing-6) 0;
}
`, "",{"version":3,"sources":["webpack://./routes/payments/reports/payouts/adyen_payouts/styles.css"],"names":[],"mappings":"AAAA;CACC,6BAA6B;CAC7B,cAAc;CACd,2BAA2B;AAC5B","sourcesContent":[".wrapper {\n\twidth: var(--page-width-wide);\n\tmargin: 0 auto;\n\tpadding: var(--spacing-6) 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `JjUQaGAoXPMzwdPaHAVo`;
export default ___CSS_LOADER_EXPORT___;
