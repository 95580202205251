import { JSX, ReactNode, Fragment, useState, useCallback } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import {
	SettingRow,
	SettingLabel,
	SettingDescription,
	SettingControl,
} from 'components/settings/Settings';
import FormHint from 'components/form/Hint';
import FormLabel from 'components/form/Label';
import DelayedTextInput from 'components/form/DelayedTextInput';

import Button from 'design/button';

export const Label = styled(FormLabel)`
	margin-bottom: 1rem;
	display: grid;
	grid-auto-flow: row;
	gap: 3px;
	font-size: var(--font-size-sm);

	& > span {
		font-weight: var(--font-weight-normal);
	}

	&:last-child {
		margin-bottom: 0;
	}
`;

interface SettingItemProps {
	label: string;
	editLabel?: string;
	isEditing: boolean;
	staticComponent: ReactNode;
	editableComponent?: ReactNode;
	requirePassword?: boolean;
	slug?: string;
	onEdit: () => void;
	onSubmit: (obj: any) => void;
	onCancel: () => void;
}

export default function SettingItem({
	label,
	editLabel,
	isEditing,
	staticComponent,
	editableComponent,
	onEdit,
	onSubmit,
	onCancel,
	requirePassword = false,
	slug = '',
}: SettingItemProps): JSX.Element {
	const [password, setPassword] = useState('');

	let canSave = requirePassword && password.length > 0;

	if (!requirePassword) {
		canSave = true;
	}

	const detectSaveable = useCallback(
		(value: string) => setPassword(value),
		[setPassword]
	);

	const cancel = () => {
		onCancel();
		setPassword('');
	};

	const save = useCallback(() => {
		onSubmit(password);
		setPassword('');
	}, [password, onSubmit]);

	return (
		<SettingRow isEditing={isEditing}>
			<SettingLabel>{label}</SettingLabel>
			<SettingDescription>
				{!isEditing ? (
					staticComponent
				) : (
					<Fragment>
						{editableComponent}
						{requirePassword && (
							<Label>
								<span>{t(`Current password`)}</span>
								<DelayedTextInput type="password" onChange={detectSaveable} />
								<FormHint>
									{t(`Enter your current password to update account settings.`)}
								</FormHint>
							</Label>
						)}
					</Fragment>
				)}
			</SettingDescription>
			<SettingControl>
				{!isEditing ? (
					<Button
						small
						icon="edit"
						onClick={onEdit}
						testid={`profile_settings.change_${slug}_button`}>
						{editLabel ? editLabel : t('Change')}
					</Button>
				) : (
					<Fragment>
						{onCancel && (
							<Button small transparent onClick={cancel}>
								{t('Cancel')}
							</Button>
						)}
						<Button
							primary
							small
							disabled={!canSave}
							onClick={save}
							testid="profile_settings.save_button">
							{t('Save')}
						</Button>
					</Fragment>
				)}
			</SettingControl>
		</SettingRow>
	);
}
