import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';
import styled from 'styled-components';

import { Account } from 'pkg/api/models/account';
import DateTime from 'pkg/datetime';
import { useCurrentAccount } from 'pkg/identity';

import AccountCard from 'routes/account/settings/privacy/AccountCard';

import InfoBox from 'components/form/info-box';

const RemovalLabel = styled.div`
	font-size: var(--font-size-base);
	color: var(--palette-red-500);
	font-weight: var(--font-weight-semibold);
`;

interface AccountDeleteConfirmProps {
	account: Account;
}

export default function AccountDeleteConfirm({
	account,
}: AccountDeleteConfirmProps): JSX.Element {
	const currentAccount = useCurrentAccount();

	const willBeDeletedAt = new DateTime(
		new Date(account.willBeDeletedAt * 1000)
	).toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	const isParent = currentAccount.id !== account.id;

	return (
		<Fragment>
			<InfoBox color="orange">
				{isParent
					? t(
							`Your child's account has been scheduled for deletion, and will be deleted on {date}, you can cancel the deletion at any time before that date.`,
							{
								date: willBeDeletedAt,
							}
						)
					: t(
							`Your account has been scheduled for deletion, and will be deleted on {date}, you can cancel the deletion at any time before that date.`,
							{
								date: willBeDeletedAt,
							}
						)}
			</InfoBox>
			<RemovalLabel>{t(`Account removal started for:`)}</RemovalLabel>
			<AccountCard account={account} />
		</Fragment>
	);
}
