import { JSX, createContext, useContext } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { EndpointResponse, useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentAccountUserIds, useCurrentAccountWards } from 'pkg/identity';

import Heading from 'routes/event/series/single/heading';
import Description from 'routes/event/series/single/description';
import EventSeriesAttendance from 'routes/event/series/single/attendance';
import EventSeriesUsers from 'routes/event/series/event_series_users';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { ColumnDivider } from 'components/form/Divider';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import InfoBox from 'components/form/info-box';

const Divider = styled.div`
	border-bottom: 1px solid var(--palette-gray-300);
`;

interface EventSeriesContextProps {
	eventSeriesEndpoint: EndpointResponse<models.eventSeries.EventSeries>;
}

const EventSeriesContext = createContext<EventSeriesContextProps>({
	eventSeriesEndpoint: null,
});

export function useEventSeriesContext() {
	return useContext(EventSeriesContext);
}

interface SeriesProps {
	eventSeriesId: number;
	query?: { [key: string]: any };
}

export default function Series({
	eventSeriesId,
	query,
}: SeriesProps): JSX.Element {
	const isFirstLoad = query['firstLoad'] === 'true';
	const eventSeriesEndpoint = useEndpoint<models.eventSeries.EventSeries>(
		endpoints.EventSeries.Show(eventSeriesId)
	);
	const activeUserIds = useCurrentAccountUserIds();

	const wardAccountRelations = useCurrentAccountWards();
	const wardUserIds =
		models.accountRelation.findAllUserIds(wardAccountRelations);
	const allEventUsers = models.eventSeriesUser.findAllEventSeriesUsers(
		eventSeriesEndpoint.record.seriesUsers,
		[...activeUserIds, ...wardUserIds]
	);
	const eventSeriesUsers = models.eventSeriesUser.sortEventSeriesUsers(
		allEventUsers,
		[],
		eventSeriesEndpoint.record
	);

	const eventSeriesAttendance = eventSeriesUsers.length > 0 && (
		<EventSeriesAttendance
			eventSeries={eventSeriesEndpoint.record}
			eventSeriesUsers={eventSeriesUsers}
		/>
	);

	const creatingEvents = isFirstLoad ? (
		<InfoBox
			color="blue"
			text={t(
				'The events in your event series are currently being created. You will receive a notification once they are done.'
			)}
		/>
	) : null;

	if (eventSeriesEndpoint.isLoading) {
		return <Spinner />;
	}

	return (
		<EventSeriesContext.Provider value={{ eventSeriesEndpoint }}>
			<LargeScreen>
				<LargeScreenContent.Inner
					maxWidth={PageWidths.WIDE}
					columns={3}
					columnSpacing="5%"
					columnSizes="1fr 1px 0.6fr">
					<Column spacing={styles.spacing._7}>
						<Heading eventSeries={eventSeriesEndpoint.record} />
						{creatingEvents}

						{eventSeriesAttendance}

						<Description eventSeries={eventSeriesEndpoint.record} />
					</Column>
					<ColumnDivider />
					<Column>
						<EventSeriesUsers />
					</Column>
				</LargeScreenContent.Inner>
			</LargeScreen>

			<SmallScreen>
				<LargeScreenContent.Inner>
					<Column spacing={styles.spacing._6}>
						<Heading eventSeries={eventSeriesEndpoint.record} />
						{creatingEvents}

						{eventSeriesAttendance}

						<Description eventSeries={eventSeriesEndpoint.record} />
						<Divider />
						<Column>
							<EventSeriesUsers />
						</Column>
					</Column>
				</LargeScreenContent.Inner>
			</SmallScreen>
		</EventSeriesContext.Provider>
	);
}
