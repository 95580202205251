import { JSX, ChangeEvent, ReactNode } from 'react';

import * as styles from 'pkg/config/styles';

import { cssVarList } from 'pkg/css/utils';
import {
	PhysicalStrainThreshold,
	physicalStrainLabel,
} from 'pkg/api/models/user_activity';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './style.css';

function physicalStrainLevel(value: number): string {
	if (value < 0 || value > 100) {
		return 'invalid';
	}

	if (value === PhysicalStrainThreshold.Max) {
		return 'max';
	} else if (value >= PhysicalStrainThreshold.VeryHigh) {
		return 'veryHigh';
	} else if (value >= PhysicalStrainThreshold.High) {
		return 'high';
	} else if (value >= PhysicalStrainThreshold.Moderate) {
		return 'moderate';
	} else if (value >= PhysicalStrainThreshold.Low) {
		return 'low';
	} else {
		return 'veryLow';
	}
}

interface PhysicalStrainProps {
	id: string;
	label: ReactNode;
	value: number;
	thin?: boolean;
	editable?: boolean;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function PhysicalStrain({
	id,
	label,
	value,
	thin,
	editable,
	onChange,
}: PhysicalStrainProps): JSX.Element {
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			onChange(event);
		}
	};

	return (
		<Column className={css.wrapper} spacing={styles.spacing._2}>
			<Row columns="1fr auto" align="center">
				{thin ? (
					<span className={css.barLabel}>{label}</span>
				) : (
					<strong className={css.barLabel}>{label}</strong>
				)}
				<span
					data-strain-level={physicalStrainLevel(value)}
					className={css.strainLabel}>
					{physicalStrainLabel(value)} ({Math.floor(value / 10)})
				</span>
			</Row>
			<div className={css.container}>
				<div data-thin={thin} className={css.bar}>
					<div
						className={css.progress}
						style={cssVarList({ offset: `${100 - value}%` })}
					/>
				</div>
				{editable && (
					<input
						id={id}
						type="range"
						min={0}
						max={100}
						step={1}
						value={value}
						data-thin={thin}
						onChange={handleChange}
						className={css.slider}
					/>
				)}
			</div>
		</Column>
	);
}
