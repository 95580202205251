// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agKFloeSVH0Fj7mSQLJA {
	margin-left: auto;
	margin-right: auto;
	padding: var(--spacing-7) var(--spacing-5);
	max-width: var(--page-width-standard);
	width: 100%;
}

.O_952JZutBEColjlOnlB {
	padding: var(--spacing-5);
	background-color: var(--palette-white);
	border-bottom: 1px solid var(--palette-gray-300);
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-line-pack: start;
	    align-content: start;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: start;
	place-content: start;
}

@media (max-width: 768px) {

.O_952JZutBEColjlOnlB {
		padding-top: 0;
		background-color: transparent;
		-ms-flex-line-pack: end;
		    align-content: end;
		-webkit-box-pack: end;
		    -ms-flex-pack: end;
		        justify-content: end;
		place-content: end;
}
	}

.UJrwPNAVebVjg4DK1FTG {
	display: grid;
	grid-template-columns: 30px 1fr 20px;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/development/styles.css"],"names":[],"mappings":"AAAA;CACC,iBAAmB;CAAnB,kBAAmB;CACnB,0CAA0C;CAC1C,qCAAqC;CACrC,WAAW;AACZ;;AAEA;CACC,yBAAyB;CACzB,sCAAsC;CACtC,gDAAgD;CAChD,kBAAkB;CAClB,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAoB;KAApB,oBAAoB;CAApB,uBAAoB;KAApB,oBAAoB;SAApB,sBAAoB;CAApB,oBAAoB;AAOrB;;AALC;;AARD;EASE,cAAc;EACd,6BAA6B;EAC7B,uBAAkB;MAAlB,kBAAkB;EAAlB,qBAAkB;MAAlB,kBAAkB;UAAlB,oBAAkB;EAAlB,kBAAkB;AAEpB;CADC;;AAGD;CACC,aAAa;CACb,oCAAoC;CACpC,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;AACpB","sourcesContent":[".wrapper {\n\tmargin-inline: auto;\n\tpadding: var(--spacing-7) var(--spacing-5);\n\tmax-width: var(--page-width-standard);\n\twidth: 100%;\n}\n\n.groupSelect {\n\tpadding: var(--spacing-5);\n\tbackground-color: var(--palette-white);\n\tborder-bottom: 1px solid var(--palette-gray-300);\n\tposition: relative;\n\tdisplay: flex;\n\tplace-content: start;\n\n\t@media (--small-viewport) {\n\t\tpadding-top: 0;\n\t\tbackground-color: transparent;\n\t\tplace-content: end;\n\t}\n}\n\n.groupSelectItem {\n\tdisplay: grid;\n\tgrid-template-columns: 30px 1fr 20px;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `agKFloeSVH0Fj7mSQLJA`;
export var groupSelect = `O_952JZutBEColjlOnlB`;
export var groupSelectItem = `UJrwPNAVebVjg4DK1FTG`;
export default ___CSS_LOADER_EXPORT___;
