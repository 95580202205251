import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';

import PositionMatch from 'containers/user/profile/development/position-match/Single';

const NoOp = () => {};

interface AssessmentProps {
	user: models.user.User;
	groupId: number;
}

export default function Assessment({
	user,
	groupId,
}: AssessmentProps): JSX.Element {
	const { record: userRating, isLoading } =
		useEndpoint<models.userRating.UserRating>(
			endpoints.UserRatings.ShowPrimaryRating(user.id),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);

	if (isLoading || !userRating?.id) {
		return null;
	}

	return (
		<PositionMatch
			userId={user.id}
			ratingId={userRating.id}
			compareId={0}
			setDefinedPageActions={NoOp}
			showBreakdown={false}
		/>
	);
}
