import VideoSequence, {
	VideoSequencePayload,
	VideoSequenceResponse,
} from 'pkg/models/video_sequence';

import * as services from 'pkg/actions/services';
import { normalizedDispatch } from 'pkg/actions/utils';
import * as actionTypes from 'pkg/actions/action-types';

import store from 'pkg/store/createStore';
import { VideoSequenceRecordingId } from 'pkg/video';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';

export const create = async (
	payload: VideoSequencePayload
): Promise<VideoSequenceResponse> => {
	const [request, response] = await models.create<
		VideoSequencePayload,
		VideoSequenceResponse
	>(endpoints.Video.CreateSequence(payload.videoId), payload);

	if (request.ok) {
		normalizedDispatch(response, VideoSequence.normalizr())(store.dispatch);
	}

	return response;
};

export const update = async (
	sequenceId: number,
	payload: VideoSequencePayload
): Promise<VideoSequenceResponse> => {
	const [request, response] = await services.video.updateSequence(
		sequenceId,
		payload
	);

	if (request.ok) {
		normalizedDispatch(response, VideoSequence.normalizr())(store.dispatch);
	}

	return response;
};

export const destroy = async (sequenceId: number): Promise<void> => {
	const request = await services.video.destroySequence(sequenceId);

	if (request.ok) {
		store.dispatch({
			type: actionTypes.VideoSequences.DELETE_ITEM,
			sequenceId,
		});
	}
};

export const deleteTag = async (
	sequenceId: number,
	tagId: number
): Promise<void> => {
	const request = await services.video.deleteSequenceTag(sequenceId, tagId);

	if (request.ok) {
		store.dispatch({
			type: actionTypes.Tags.DELETE_ITEM,
			tagId,
		});
	}
};

export const cancelRecord = (): void => {
	store.dispatch({
		type: actionTypes.VideoSequences.DELETE_ITEM,
		sequenceId: VideoSequenceRecordingId,
	});
};
