import styled from 'styled-components';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import * as selectors from 'pkg/selectors';
import { RootState } from 'pkg/reducers';
import ColorConverter from 'pkg/colorconverter';
import { translatedSexString } from 'pkg/api/models/user';
import { useToggleBoolean } from 'pkg/hooks/useToggleBoolean';

import Actions from 'routes/organization/single/Actions';

import Badge from 'components/Badge';

import Column from 'components/layout/column';
import * as SideBarLayout from 'components/layout/side-bar-page';
import * as SideBarList from 'components/sidebar/List';
import { AddUserModal } from 'components/group/add-users-modal';

import { Divider } from 'design/context_menu';

const TeamBadge = styled(Badge)`
	justify-self: center;
`;

const Texts = styled.div`
	text-align: center;
	margin-bottom: var(--spacing-3);

	div {
		font-weight: var(--font-weight-semibold);
		margin-bottom: var(--spacing-1);
	}

	span {
		font-size: var(--font-size-sm);
	}
`;

const GroupCode = styled.span`
	font-feature-settings: 'ss02' 1;
`;

const ColorWrapper = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
`;

const TeamColor = styled.div<{ color: string }>`
	height: 13px;
	width: 13px;
	border-radius: 100%;
	background-color: ${({ color }) => color};
	margin-right: var(--spacing-2);
`;

interface SideBarProps {
	group: models.group.Group;
	groupId: number;
	refreshUsers: () => void;
}

const SideBar: React.FC<React.PropsWithChildren<SideBarProps>> = ({
	group,
	groupId,
	refreshUsers,
}) => {
	const [addUserModal, toggleAddUserModal] = useToggleBoolean();
	const createdAt = new DateTime(new Date(group.createdAt * 1000));

	const currentSeason = useSelector((state: RootState) =>
		selectors.groups.getCurrentSeason(state, { groupId })
	);

	const seasonStartDateTime = new DateTime(
		new Date(currentSeason.start * 1000)
	);
	const seasonEndDateTime = new DateTime(new Date(currentSeason.end * 1000));

	const monthNames = [...Array(12).keys()].map((_, n) => {
		n = n + 1;

		const y = new Date().getFullYear();
		const d = new DateTime(new Date(y, n - 1, 15, 13, 37, 0));

		return d.toLocaleDateString({
			month: 'long',
		});
	});

	const conv = ColorConverter.from(models.group.getPrimaryColor(group));

	return (
		<Fragment>
			<SideBarLayout.SideBar>
				<SideBarLayout.SideBarBody>
					<Column>
						<TeamBadge size="120px" badgeUrl={group.profileImageUrl} />
						<Texts>
							<div>{group.name}</div>
							<span>
								{t(`Created: {date}`, {
									date: createdAt.toLocaleDateString(),
								})}
							</span>
						</Texts>
						<Actions
							group={group}
							activeGroupId={groupId}
							showAddUserModal={toggleAddUserModal}
						/>
					</Column>
				</SideBarLayout.SideBarBody>
				<Divider />
				<SideBarLayout.SideBarBody>
					<Column>
						<SideBarList.Header>
							<SideBarList.Title>{t(`Overview`)}</SideBarList.Title>
						</SideBarList.Header>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Team code`)}</SideBarList.Name>
							<GroupCode>{group.inviteCode}</GroupCode>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Invites`)}</SideBarList.Name>
							<span>{group.allowInvites ? t(`Enabled`) : t(`Disabled`)}</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Season start`)}</SideBarList.Name>
							<span>{monthNames[group.seasonStartMonth - 1]}</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Current season`)}</SideBarList.Name>
							<span>
								{seasonStartDateTime.toLocaleDateString({})} -{' '}
								{seasonEndDateTime.toLocaleDateString({})}
							</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Branding colors`)}</SideBarList.Name>
							<ColorWrapper>
								<TeamColor color={conv.toHEX()} />
								{conv.toHEX()}
							</ColorWrapper>
						</SideBarList.Row>
						{group.sex !== 0 && (
							<SideBarList.Row>
								<SideBarList.Name>{t('Gender')}</SideBarList.Name>
								<span>{translatedSexString(group.sex)}</span>
							</SideBarList.Row>
						)}
						{group.birthYearFrom && group.birthYearTo && (
							<SideBarList.Row>
								<SideBarList.Name>{t('Birth year')}</SideBarList.Name>
								<span>
									{group.birthYearFrom !== group.birthYearTo
										? group.birthYearFrom + '-' + group.birthYearTo
										: group.birthYearFrom}
								</span>
							</SideBarList.Row>
						)}
						<SideBarList.Row>
							<SideBarList.Name>{t(`Sport`)}</SideBarList.Name>
							<ColorWrapper>{group.sport?.name}</ColorWrapper>
						</SideBarList.Row>
					</Column>
				</SideBarLayout.SideBarBody>
			</SideBarLayout.SideBar>
			{addUserModal && (
				<AddUserModal
					onClose={toggleAddUserModal}
					groupId={group.id}
					onFinish={refreshUsers}
				/>
			)}
		</Fragment>
	);
};

export default SideBar;
