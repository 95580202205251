import React, { useEffect, JSX } from 'react';

import { useGroup, useUser } from 'pkg/hooks/selectors';
import { useCurrentMembership } from 'pkg/identity';

import Icon from 'components/icon';

import Actions from 'components/user/profile/overview/Actions';
import Header from 'components/user/profile/overview/Header';

type OverviewHeaderProps = {
	groupId: number;
	userId: number;
	setPageActions: (trigger: JSX.Element) => void;
};

const OverviewHeader: React.FC<
	React.PropsWithChildren<OverviewHeaderProps>
> = ({ groupId, userId, setPageActions }) => {
	const activeMembership = useCurrentMembership();
	const group = useGroup(groupId);
	const user = useUser(userId);

	useEffect(() => {
		setPageActions(
			<Actions
				group={group}
				user={user}
				toggle={<Icon name="context-menu" />}
			/>
		);

		return (): void => setPageActions(null);
	}, [groupId, userId, activeMembership.id]);

	return <Header group={group} user={user} />;
};

export default OverviewHeader;
