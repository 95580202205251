import { JSX } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import useConfirm from 'pkg/hooks/useConfirm';

import {
	Group,
	ItemColumn,
} from 'routes/account/settings/child-relations/styles';

import * as Card from 'components/Card';
import Heading from 'components/Heading';
import RelativeDateTime from 'components/RelativeDateTime';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

interface PendingRelationRowProps {
	relation: models.accountRelation.AccountRelation;
	refreshRelations?: () => Promise<void>;
}

function PendingRelationRow({
	relation,
	refreshRelations,
}: PendingRelationRowProps): JSX.Element {
	const dispatch = useDispatch();

	const targetAccountname = models.account.fullName(relation.targetAccount);

	const remove = useConfirm({
		message: t(
			'You are about to remove {name} as a connection, this cannot be undone.',
			{ name: targetAccountname }
		),
		onConfirm: async () => {
			await dispatch(actions.accountRelations.remove(relation.id));

			actions.flashes.show({
				title: t('Successfully saved!'),
				message: t('You are no longer connected to {name}', {
					name: targetAccountname,
				}),
			});

			refreshRelations();
		},
	});

	return (
		<Card.Base $noBorder>
			<Card.Body $narrowBody>
				<LargeScreen>
					<Row columns="35px 1fr auto" align="center">
						<Avatar account={relation.targetAccount} />
						<ItemColumn spacing={styles.spacing._1}>
							<strong>{models.account.fullName(relation.targetAccount)}</strong>
							<span>
								<RelativeDateTime dateTime={relation.createdAt} />
							</span>
						</ItemColumn>
						<Button caution onClick={remove}>
							{t('Remove request')}
						</Button>
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Column>
						<Row columns="35px 1fr">
							<Avatar account={relation.targetAccount} />
							<ItemColumn spacing={styles.spacing._1}>
								<strong>
									{models.account.fullName(relation.targetAccount)}
								</strong>
								<span>
									<RelativeDateTime dateTime={relation.createdAt} />
								</span>
							</ItemColumn>
						</Row>
						<Button caution onClick={remove}>
							{t('Remove request')}
						</Button>
					</Column>
				</SmallScreen>
			</Card.Body>
		</Card.Base>
	);
}

interface PendingRelationsProps {
	relations: models.accountRelation.AccountRelation[];
	refreshRelations: () => Promise<void>;
}

export default function PendingRelations({
	relations,
	refreshRelations,
}: PendingRelationsProps): JSX.Element {
	if (relations.length === 0) {
		return null;
	}

	return (
		<Group>
			<Heading>{t('Pending connection requests')}</Heading>
			{relations.map((relation: models.accountRelation.AccountRelation) => (
				<PendingRelationRow
					key={relation.id}
					relation={relation}
					refreshRelations={refreshRelations}
				/>
			))}
		</Group>
	);
}
