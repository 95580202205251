import { createContext, PropsWithChildren, ReactNode, useContext } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import useMixedState from 'pkg/hooks/useMixedState';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { SubNavItemProps } from 'components/layout/page-templates';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

export function useTemplateContext() {
	return useContext(TemplateContext);
}

interface TemplateContextProps {
	pageTitle: string;
	pageIcon: MaterialSymbolVariant;
	pageActions: ReactNode[] | ReactNode;
	pageSubNavItems: SubNavItemProps[];
	pageBackgroundColor: string;
	pageSubTitle: string;
	noScroll: boolean;
	currentTab: string;
	maxWidth: PageWidths;

	setNoScroll: (scroll: boolean) => void;
	setPageTitle: (title: string) => void;
	setPageBackgroundColor: (backgroundColor: string) => void;
	setPageSubTitle: (title: string) => void;
	setPageIcon: (icon: MaterialSymbolVariant) => void;
	setPageActions: (actions: ReactNode | ReactNode[]) => void;
	setPageSubNavItems: (navItems: SubNavItemProps[]) => void;
}

const TemplateContext = createContext<TemplateContextProps>({
	pageTitle: '',
	pageIcon: '' as MaterialSymbolVariant,
	pageActions: null,
	pageSubNavItems: [],
	pageBackgroundColor: '',
	pageSubTitle: '',
	noScroll: false,
	currentTab: '',
	maxWidth: PageWidths.WIDE,

	setNoScroll: null,
	setPageTitle: null,
	setPageBackgroundColor: null,
	setPageSubTitle: null,
	setPageIcon: null,
	setPageActions: null,
	setPageSubNavItems: null,
});

interface TemplateContextWrapperProps extends PropsWithChildren {
	title: string;
	icon: MaterialSymbolVariant;
	subNavItems?: SubNavItemProps[];
	backgroundColor?: string;
	subTitle?: string;
	currentTab?: string;
	maxWidth?: PageWidths;
}

export function TemplateContextWrapper({
	title,
	icon,
	children,
	backgroundColor,
	subNavItems,
	subTitle,
	currentTab,
	maxWidth,
}: TemplateContextWrapperProps) {
	const [state, setState] = useMixedState({
		pageTitle: title,
		pageIcon: icon,
		pageActions: null,
		pageSubNavItems: subNavItems,
		pageBackgroundColor: backgroundColor,
		pageSubTitle: subTitle,
		noScroll: false,
	});

	const setNoScroll = (noScroll: boolean) => setState({ noScroll });

	const setPageTitle = (pageTitle: string) => setState({ pageTitle });

	const setPageBackgroundColor = (pageBackgroundColor: string) =>
		setState({ pageBackgroundColor });

	const setPageSubTitle = (pageSubTitle: string) => setState({ pageSubTitle });

	const setPageIcon = (pageIcon: MaterialSymbolVariant) =>
		setState({ pageIcon });

	const setPageActions = (pageActions: ReactNode | ReactNode[]) =>
		setState({ pageActions });

	const setPageSubNavItems = (pageSubNavItems: SubNavItemProps[]) =>
		setState({ pageSubNavItems });

	return (
		<TemplateContext.Provider
			value={{
				pageTitle: state.pageTitle,
				pageIcon: state.pageIcon,
				pageActions: state.pageActions,
				pageSubNavItems: state.pageSubNavItems,
				pageBackgroundColor: state.pageBackgroundColor,
				pageSubTitle: state.pageSubTitle,
				noScroll: state.noScroll,
				currentTab,
				maxWidth,
				setNoScroll,
				setPageTitle,
				setPageBackgroundColor,
				setPageSubTitle,
				setPageIcon,
				setPageActions,
				setPageSubNavItems,
			}}>
			{children}
		</TemplateContext.Provider>
	);
}

export function useBackgroundColor(current: string, initial: string) {
	const { setPageBackgroundColor } = useTemplateContext();

	useComponentDidMount(
		() => {
			setPageBackgroundColor(current);
		},
		() => {
			setPageBackgroundColor(initial);
		}
	);
}
