import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as actions from 'pkg/actions';
import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { MembershipRole, MembershipStatus } from 'pkg/api/models/membership';

import Pagination from 'components/pagination';
import Icon from 'components/icon';
import AssetImage from 'components/AssetImage';
import MembershipBulkActions from 'components/membership-bulk-actions';
import Avatar from 'components/avatar';

import * as Input from 'components/form/inputs';
import { Spinner } from 'components/loaders/spinner';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface UserListProps {
	groupId: number;
}

export default function BannedUsersList({
	groupId,
}: UserListProps): JSX.Element {
	const {
		records: allBannedMemberships,
		isLoading,
		refresh,
		pagination,
		selection,
		selectedRecords,
	} = useCollection<models.membership.Membership>(
		endpoints.Groups.ShowUsers(groupId),
		{
			queryParams: new URLSearchParams({ status: 'banned' }),
		}
	);

	const handleDeactivate = async (membership: models.membership.Membership) => {
		const request = await sdk.patch(
			endpoints.Groups.UpdateUser(membership.groupId, membership.userId),
			{},
			{ status: MembershipStatus.Inactive }
		);

		if (!request.ok) {
			actions.flashes.show({
				title: t('Could not deactivate user'),
			});
		}

		refresh();
	};
	if (isLoading) {
		return <Spinner />;
	}

	const emptyState = {
		title: t('No users found'),
		image: <AssetImage src="img/missing-entities/contacts.svg" />,
	};

	return (
		<Fragment>
			<MembershipBulkActions
				groupId={groupId}
				selection={selection}
				selectedRecords={selectedRecords}
				onRemove={refresh}
			/>
			<Table.Table
				emptyState={emptyState}
				stickyFooter
				columns={[
					{
						content: (
							<Input.Control
								testid="check-all"
								type="checkbox"
								standalone
								checked={selection.isAllSelected}
								disabled={allBannedMemberships.length === 0}
								onChange={selection.selectAll}
							/>
						),
						width: 'min-content',
					},
					{
						content: t('Name'),
					},
					{ content: '', width: 'min-content' },
					{
						content: t('Email'),
					},
					{
						content: t('Role'),
					},
					{
						content: t('Date of birth'),
					},
					{
						content: '',
						width: 'max-content',
					},
				]}
				isLoading={isLoading}>
				{allBannedMemberships.map((bannedMembership) => {
					const handleDeactivateUser = () => {
						handleDeactivate(bannedMembership);
					};

					return (
						<Table.Row key={bannedMembership.user.id}>
							<Table.Cell>
								<Input.Control
									onChange={() => selection.selectSingle(bannedMembership.id)}
									checked={selection.isSelected(bannedMembership.id)}
									type="checkbox"
									standalone
								/>
							</Table.Cell>
							<Table.Cell>
								<div className={css.name}>
									<Avatar user={bannedMembership.user} size={25} />
									<div className={css.nameInner}>
										<span>{models.user.fullName(bannedMembership.user)}</span>
									</div>
								</div>
							</Table.Cell>
							<Table.LinkCell>
								{bannedMembership.user.deletedAt ||
								bannedMembership.user.accountId ? (
									bannedMembership.user.deletedAt ? (
										<Icon name="archive" />
									) : (
										<Icon
											name="key"
											size={1.5}
											tooltip={t('This user has a 360Player account')}
										/>
									)
								) : null}
							</Table.LinkCell>

							<Table.Cell>{bannedMembership.user.email}</Table.Cell>
							<Table.Cell>
								{models.membership.roleToTranslatedString(
									bannedMembership.role as MembershipRole
								)}
							</Table.Cell>
							<Table.Cell>{bannedMembership.user.birthDate}</Table.Cell>
							<Table.ActionCell>
								<ContextMenu.Menu
									toggleWith={
										<ContextMenu.TableTrigger>
											<Icon name="context-menu" />
										</ContextMenu.TableTrigger>
									}>
									<ContextMenu.Item onClick={handleDeactivateUser}>
										<ContextMenu.ItemIcon name="delete" />
										{t('Remove')}
									</ContextMenu.Item>
								</ContextMenu.Menu>
							</Table.ActionCell>
						</Table.Row>
					);
				})}
			</Table.Table>
			<Pagination {...pagination} />
		</Fragment>
	);
}
