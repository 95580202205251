import {
	JSX,
	ChangeEvent,
	FocusEvent,
	InputHTMLAttributes,
	MutableRefObject,
	ReactNode,
	useRef,
	useState,
} from 'react';

import useUuid from 'pkg/hooks/useUuid';
import { cssClasses } from 'pkg/css/utils';

import Icon from 'components/icon';

import * as css from './styles.css';

interface FieldProps extends InputHTMLAttributes<HTMLInputElement> {
	testid?: string;
	forwardedRef?: MutableRefObject<HTMLInputElement>;

	// Used as a field hint
	message?: string;

	// Custom error message, sets setCustomValidity
	// If persistentErrorMessage is set to true, this will *always* show as error
	errorMessage?: string;
	persistentErrorMessage?: boolean;
	validateOnChange?: boolean;

	variant?: 'default' | 'group-code';

	// Name and value is required
	name: string;
	value: string;
	label?: string;

	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void;

	// onChange is required
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;

	// Custom event for validation
	onValidate?: (isValid: boolean, target: HTMLInputElement) => void;
}

export default function Field({
	testid,
	forwardedRef,

	message,
	errorMessage,
	persistentErrorMessage,
	validateOnChange,

	type = 'input',
	variant = 'default',
	name,
	value = '',
	label,

	onBlur,
	onFocus,
	onChange,
	onValidate,

	// Common props instead of using props.thing.
	pattern,
	required,
	placeholder,
	inputMode = 'text',

	...props
}: FieldProps): JSX.Element {
	const inputIdentifier = useUuid();

	const internalRef = useRef<HTMLInputElement>();
	const inputRef = forwardedRef || internalRef;

	const [hasInteracted, setHasInteracted] = useState<boolean>(false);
	const [inputValidity, setInputValidity] = useState<boolean>(true);
	const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

	let inputToggle: ReactNode;
	let inputMessage: ReactNode;
	let variantType: string = type;
	let showSuccess: boolean = true;

	if (type === 'password' || type === 'number') {
		showSuccess = false;
	}

	if (variant === 'group-code') {
		variantType = variant;
	}

	if (!testid) {
		testid = `input${name ? '-' + name : ''}`;
	}

	// Infer default inputs as required and at least one character
	if (type === 'input' && !pattern && !required) {
		required = true;
		pattern = '[\\s\\S]{1,}';
	}

	const hasError =
		hasInteracted && (!inputValidity || persistentErrorMessage || false);

	const validate = (target: HTMLInputElement) => {
		if (target) {
			let valid = target.checkValidity();

			if (target.dataset?.mustMatch) {
				valid = target.value === target.dataset.mustMatch;
			}

			setInputValidity(valid);

			if (onValidate) {
				onValidate(valid, target);
			}
		}
	};

	const handleInvalid = (event: any) => {
		event.preventDefault();
	};

	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		if (!hasInteracted) {
			setHasInteracted(true);
		}

		validate(event.target);

		if (onBlur) {
			onBlur(event);
		}
	};

	const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
		if (onFocus) {
			onFocus(event);
		}
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (validateOnChange) {
			validate(event.target);
		}

		onChange(event);
	};

	const togglePasswordVisibility = () => {
		if (passwordVisible) {
			inputRef.current.type = 'password';
			setPasswordVisible(false);
		} else {
			inputRef.current.type = 'text';
			setPasswordVisible(true);
		}

		inputRef.current.focus();
	};

	if (type === 'password') {
		inputToggle = (
			<div className={css.toggle} style={{ transform: 'translateY(1px)' }}>
				<Icon
					name={passwordVisible ? 'eye-visible' : 'eye-hidden'}
					onClick={togglePasswordVisibility}
				/>
			</div>
		);
	}

	if (type !== 'number') {
		if (hasError) {
			inputMessage = (
				<span>
					{errorMessage ? errorMessage : inputRef.current.validationMessage}
				</span>
			);
		} else if (message) {
			inputMessage = <span title={message}>{message}</span>;
		} else {
			inputMessage = null;
		}
	}

	// Any field that's not a number, group code or a password will get a checkbox when done typing
	// Group code is verified on submit, adding a checkmark *before* that would make zero sense
	if (
		showSuccess &&
		hasInteracted &&
		inputValidity &&
		value.length > 0 &&
		variant !== 'group-code'
	) {
		inputToggle = (
			<div className={cssClasses(css.toggle, css.success)}>
				<Icon name="check" />
			</div>
		);

		inputMessage = null;
	}

	return (
		<fieldset
			data-input-type={variantType}
			data-input-validity={inputValidity.toString()}
			data-input-toggle={!!inputToggle}
			className={cssClasses(
				css.wrapper,
				type === 'number' ? css.centered : ''
			)}>
			<input
				{...props}
				ref={inputRef}
				id={inputIdentifier}
				type={type}
				autoComplete="off"
				spellCheck={false}
				required={required}
				pattern={pattern}
				placeholder={placeholder}
				data-testid={testid}
				name={name}
				value={value}
				onBlur={handleBlur}
				onFocus={handleFocus}
				onChange={handleChange}
				onInvalid={handleInvalid}
				inputMode={inputMode}
			/>
			{(label || placeholder) && (
				<label htmlFor={inputIdentifier}>{label || placeholder}</label>
			)}
			{inputToggle}
			<div
				className={cssClasses(
					css.message,
					inputMessage ? css.visible : '',
					hasError ? css.error : ''
				)}>
				{inputMessage}
			</div>
		</fieldset>
	);
}
