import { JSX } from 'react';
import { Capacitor } from '@capacitor/core';
import {
	PushNotifications,
	PushNotificationSchema,
} from '@capacitor/push-notifications';

import {
	navigatePushNotification,
	parseNotificatioAdditionalData,
} from 'pkg/actions/notifications';

import * as sdk from 'pkg/core/sdk';
import asyncLocalStorage from 'pkg/asyncLocalStorage';
import { isAndroid, isIOS } from 'pkg/platform';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { flashes } from 'pkg/actions';

const hasPushNotifications = Capacitor.isPluginAvailable('PushNotifications');

let queuedPushNotificationActions: CallableFunction[] = [];

function callQueuedPushNotificationsActions() {
	if (queuedPushNotificationActions.length > 0) {
		queuedPushNotificationActions.forEach((action: CallableFunction) =>
			action()
		);

		queuedPushNotificationActions = [];
	}
}

type PushNotificationCallback = (pns: PushNotificationSchema) => void;

let __handlePushNotification: PushNotificationCallback = () => null;

async function pushNotifications(): Promise<void> {
	if (!hasPushNotifications) {
		return;
	}

	let status = await PushNotifications.checkPermissions();
	if (status.receive === 'prompt') {
		status = await PushNotifications.requestPermissions();
	}

	// User has denied notification, no further setup is needed
	if (status.receive !== 'granted') {
		return;
	}

	await PushNotifications.register();

	if (isAndroid()) {
		PushNotifications.createChannel({
			id: 'com.360player.app.default_notifications',
			name: 'General 360Player notifications',
			importance: 3,
			vibration: true,
			visibility: 1,
			sound: 'default',
		});
	}

	if (hasPushNotifications) {
		PushNotifications.addListener('registration', (token) => {
			asyncLocalStorage.setItem('pnsID', token);
			let registrationType = 'fcm';
			let tokenValue = token.value;

			if (isIOS()) {
				registrationType = 'apns';
				tokenValue = tokenValue.toLowerCase();
			}

			sdk.post(
				'/me/pns',
				{},
				{
					id: tokenValue,
					type: registrationType,
				}
			);
		});

		// Only triggers when the app is open
		PushNotifications.addListener(
			'pushNotificationReceived',
			(notification) => {
				__handlePushNotification(notification);
			}
		);

		PushNotifications.addListener(
			'pushNotificationActionPerformed',
			(action) => {
				switch (action.actionId) {
					case 'tap':
						__handlePushNotification(action.notification);
						navigatePushNotification(action.notification.data);
						break;
				}
			}
		);
	}

	if (status.receive === 'granted') {
		callQueuedPushNotificationsActions();
	}
}

export function usePushNotificationSender(): PushNotificationCallback {
	return (pns: PushNotificationSchema) => __handlePushNotification(pns);
}

export function PushNotificationsObserver(): JSX.Element {
	const capturePushNotification = (notification: PushNotificationSchema) => {
		const currentUrl = document.location.pathname;

		const { targetUrl } = parseNotificatioAdditionalData(notification.data);

		if (!notification.title && !notification.body) {
			return;
		}

		if (currentUrl !== targetUrl) {
			flashes.show({
				title: notification.title,
				message: notification.body,
				additionalData: notification.data,
			});
		}

		return;
	};

	useComponentDidMount(async () => {
		__handlePushNotification = capturePushNotification;

		await pushNotifications();
	});

	return null;
}
