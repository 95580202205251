import { t } from '@transifex/native';
import {
	BlockBlobClient,
	newPipeline,
	AnonymousCredential,
	StoragePipelineOptions,
	BlockBlobParallelUploadOptions,
} from '@azure/storage-blob';

import { MAX_FILE_SIZE } from 'pkg/config/files';

import * as sdk from 'pkg/core/sdk';
import {
	AttachmentType,
	AttachmentVisibility,
	AttachmentStatus,
} from 'pkg/api/models/attachment';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { delay } from 'pkg/timings';

type AttachmentCompleteCallback = (
	attachment: models.attachment.Attachment
) => void;

type AttachmentProgressCallback = (
	bytesLoaded: number,
	bytesTotal: number,
	attachment: models.attachment.Attachment
) => void;

interface AttachmentUploadOptions {
	waitForCompleteEvent?: boolean;
	onComplete?: AttachmentCompleteCallback;
	onProgress?: AttachmentProgressCallback;
}

export interface AttachmentCreatePayload {
	tmpId?: string;
	file: File;
	title?: string;
	description?: string;
	type?: AttachmentType;
	visibility: AttachmentVisibility;
	url?: string;
	size?: number;
	originalFilename?: string;
}

const AttachmentBytesBlockSize = 4 * 1024 * 1024;

export async function upload(
	payload: AttachmentCreatePayload,
	{ onProgress }: AttachmentUploadOptions = {}
): Promise<models.attachment.Attachment> {
	if (payload.file.type.includes('image')) {
		payload.type = AttachmentType.Image;
	} else if (payload.file.type.includes('video')) {
		payload.type = AttachmentType.Video;
	} else if (payload.file.type.includes('audio')) {
		payload.type = AttachmentType.Audio;
	} else {
		payload.type = AttachmentType.File;
	}

	if (!payload.originalFilename) {
		payload.originalFilename = payload.file.name;
	}

	payload.size = payload.file.size;
	if (!payload.title) {
		payload.title = payload.originalFilename;
	}

	if (payload.file.size > MAX_FILE_SIZE) {
		alert(t(`The max file size is 25MB.`));
		return null;
	}

	const [request, attachment] = await models.create<
		AttachmentCreatePayload,
		models.attachment.Attachment
	>(endpoints.Attachments.Create(), payload);

	if (!request.ok) {
		throw new Error('failed to create attachment');
	}

	const handleComplete = async (): Promise<models.attachment.Attachment> => {
		const request = await sdk.post(
			endpoints.Attachments.CompleteUpload(attachment.id)
		);

		if (request.ok) {
			const response = await request.json();
			return response;
		}
	};

	const handleProgress = (progress: number) => {
		if (onProgress) {
			onProgress(progress, attachment.size, attachment);
		}
	};

	if (attachment.status === AttachmentStatus.Uploading) {
		const blob = new BlockBlobClient(
			attachment.uploadUrl,
			newPipeline(new AnonymousCredential(), {
				retryOptions: 4,
			} as StoragePipelineOptions)
		);

		const uploadOptions = {
			blockSize: AttachmentBytesBlockSize,
			parallelism: 20,
			blobHTTPHeaders: {
				blobContentType: payload.file.type,
				blobContentDisposition: 'inline',
			},
		} as BlockBlobParallelUploadOptions;

		return new Promise((resolve) => {
			uploadOptions.onProgress = async (progress: { loadedBytes: number }) => {
				handleProgress(progress.loadedBytes);
				if (progress.loadedBytes >= attachment.size) {
					await delay(1000);
					const att = await handleComplete();
					resolve(att);
				}
			};

			blob.uploadData(payload.file, uploadOptions);
		});
	}

	return attachment;
}
