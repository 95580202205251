// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qab5H2ncnNLQ3kivRGQR {
	margin-bottom: var(--spacing-4);
}
`, "",{"version":3,"sources":["webpack://./routes/group/report/user/styles.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;AAChC","sourcesContent":[".summaryWrapper {\n\tmargin-bottom: var(--spacing-4);\n}\n"],"sourceRoot":""}]);
// Exports
export var summaryWrapper = `Qab5H2ncnNLQ3kivRGQR`;
export default ___CSS_LOADER_EXPORT___;
