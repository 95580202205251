import { JSX } from 'react';
import { t } from '@transifex/native';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import rgba from 'pkg/rgba';

import { useOnboardingContext } from 'routes/public/onboarding';

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LogoutButtonStyles = styled.button`
	color: var(--palette-white);
	background: ${rgba(styles.palette.white, 0.15)};
	padding: 0 var(--spacing-7);
	font-family: var(--font-family-default);
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
	border: none;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	appearance: none;
	user-select: none;
	height: 30px;
	border-radius: 15px;
	transition: box-shadow 120ms ease-in-out;

	@media (hover: hover) {
		&:hover:not([disabled]) {
			box-shadow: ${rgba(styles.palette.white, 0.5)} 0 0 0 4px;
		}
	}
`;

interface LogoutButtonProps {
	disabled?: boolean;
}

export default function LogoutButton({
	disabled,
}: LogoutButtonProps): JSX.Element {
	const dispatch = useDispatch();
	const { resetStore } = useOnboardingContext();

	const handleLogout = async () => {
		resetStore();

		dispatch(actions.auth.logout());

		window.history.pushState({}, '', '/');
		window.location.reload();
	};

	return (
		<ButtonWrapper>
			<LogoutButtonStyles
				onClick={handleLogout}
				disabled={disabled}
				data-testid="onboarding.logout_button">
				{t('Log out')}
			</LogoutButtonStyles>
		</ButtonWrapper>
	);
}
