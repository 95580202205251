import { JSX, ReactNode, createContext, useContext } from 'react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import useMixedState from 'pkg/hooks/useMixedState';
import { useCollection } from 'pkg/api/use_collection';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentMemberships, useCurrentOrganization } from 'pkg/identity';

const NoOp = (): null => null;

interface GroupSwitcherState {
	isLoading: boolean;
	isTraversing: boolean;

	nodeTree: number[];
	currentNodeId: number;
	descend: (groupId: number) => void;
	ascend: () => void;

	parentGroup?: models.group.Group;
	groups: models.group.Group[];
	memberships: models.membership.Membership[];

	search: string;
	setSearch: (search: string) => void;

	reset: () => void;
}

const DefaultGroupSwitcherState: GroupSwitcherState = {
	isLoading: true,
	isTraversing: false,

	nodeTree: [],
	currentNodeId: 0,
	descend: NoOp,
	ascend: NoOp,

	parentGroup: null,
	groups: [],
	memberships: [],

	search: '',
	setSearch: NoOp,

	reset: NoOp,
};

export const GroupSwitcherContext = createContext<GroupSwitcherState>(
	DefaultGroupSwitcherState
);

export function useGroupSwitcher(): GroupSwitcherState {
	return useContext(GroupSwitcherContext);
}

interface GroupSwitcherProviderProps {
	children: ReactNode | ReactNode[];
}

export default function GroupSwitcherProvider({
	children,
}: GroupSwitcherProviderProps): JSX.Element {
	const memberships = useCurrentMemberships();
	const org = useCurrentOrganization();

	const [state, setState] = useMixedState<GroupSwitcherState>(
		DefaultGroupSwitcherState
	);

	const search = state.search ?? '';
	const isSearching = search.length > 0;
	const currentNodeId = state.nodeTree[state.nodeTree.length - 1] ?? 0;

	const shouldFetchParentGroup = !isSearching && currentNodeId !== 0;
	const shouldFetchChildGroups = !isSearching && currentNodeId > 0;

	const { record: parentGroup, isLoading: isLoadingParentGroup } =
		useEndpoint<models.group.Group>(
			shouldFetchParentGroup ? endpoints.Groups.Show(currentNodeId) : null
		);

	const { records, isLoading: isLoadingChildGroups } =
		useCollection<models.group.Group>(
			shouldFetchChildGroups
				? endpoints.Groups.ShowChildren(currentNodeId)
				: null
		);

	const { records: searchedGroups, isLoading: isLoadingSearch } =
		useCollection<models.group.Group>(
			isSearching ? endpoints.Groups.SearchViewable(org.id) : null,
			{
				queryParams: new URLSearchParams({
					name: state.search,
				}),
			}
		);

	const isLoading =
		isLoadingParentGroup || isLoadingChildGroups || isLoadingSearch;

	const membershipGroups = memberships.map(
		(membership: models.membership.Membership) => membership.group
	);

	const resolveGroups = (): models.group.Group[] => {
		const topLevelGroupIndex = membershipGroups.findIndex(
			(group: models.group.Group) => group.organizationId !== 0
		);

		if (topLevelGroupIndex !== -1 && !currentNodeId && !isSearching) {
			const sorted = models.group
				.sortByName(membershipGroups)
				.sort(function (a, b) {
					if (!a.organizationId && b.organizationId) {
						return -1;
					} else if (b.organizationId && !a.organizationId) {
						return 1;
					}

					return 0;
				});

			return sorted;
		} else if (isSearching) {
			return models.group.sortByName(searchedGroups);
		}

		return models.group.sortByOrder(records) ?? [];
	};

	const groups: models.group.Group[] = resolveGroups();

	const setSearch = (search: string) => {
		setState({ search });
	};

	const descend = (groupId: number) => {
		const nodeTree = state.nodeTree.concat([groupId]);
		const isTraversing = nodeTree?.length > 0;

		setState({
			nodeTree,
			isTraversing,
		});
	};

	const ascend = () => {
		const nodeTree = state.nodeTree.slice(0, -1);
		const isTraversing = nodeTree?.length > 0;

		setState({
			nodeTree,
			isTraversing,
		});
	};

	const reset = () => {
		setState(DefaultGroupSwitcherState);
	};

	const providedState: GroupSwitcherState = {
		...state,
		isLoading,

		currentNodeId,
		descend,
		ascend,

		parentGroup,
		groups,
		memberships,

		search,
		setSearch,

		reset,
	};

	return (
		<GroupSwitcherContext.Provider value={providedState}>
			{children}
		</GroupSwitcherContext.Provider>
	);
}
