import { useT } from '@transifex/react';
import { JSX, Fragment, useState } from 'react';

import * as styles from 'pkg/config/styles';

import { useCurrentAccount, useFetchIdentity } from 'pkg/identity';
import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import useConfirm from 'pkg/hooks/useConfirm';
import Link from 'pkg/router/Link';

import Children from 'routes/dashboard/no-membership/children';

import JoinGroupModal from 'components/group/join-modal';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

export default function NoMembershipWidgets(): JSX.Element {
	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const t = useT();

	const currentAccount = useCurrentAccount();
	const fetchIdentity = useFetchIdentity();

	const openJoinGroup = () => setModalOpen(true);
	const closeModal = () => setModalOpen(false);

	const accountUnderDeletion = currentAccount.willBeDeletedAt;

	const willBeDeletedAt = DateTime.fromTimestamp(
		currentAccount.willBeDeletedAt
	).toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	const handleCancelDeletion = useConfirm({
		message: t('Do you want to cancel your account deletion?'),
		onConfirm: async () => {
			await models.account.cancelDelete(
				currentAccount as unknown as models.account.Account
			);
			await fetchIdentity();
		},
	});

	return (
		<Fragment>
			<Column spacing={styles.spacing._8}>
				{accountUnderDeletion && (
					<InfoBox color="orange">
						<p>
							{t('Your account will be deleted at {date}.', {
								date: willBeDeletedAt,
							})}
						</p>

						<p>
							<Link onClick={handleCancelDeletion}>{t('Cancel deletion')}</Link>
						</p>
					</InfoBox>
				)}
				<h1>
					{t('Welcome back, {name}!', {
						name: models.account.fullName(currentAccount),
					})}
				</h1>

				<MissingEntities
					noBorder
					title={t('You are not a part of any organization')}
					description={t('Join a group with a group code to continue.')}
					icon="user-add"
					actions={
						<Button
							secondary
							icon="add"
							label={t('Join a group')}
							onClick={openJoinGroup}
						/>
					}
				/>

				<Children />
			</Column>

			{modalOpen && <JoinGroupModal onClose={closeModal} />}
		</Fragment>
	);
}
