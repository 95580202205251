import { JSX } from 'react';
import { t } from '@transifex/native';
import styled from 'styled-components';

import { UpdateGroupMemberPayload } from 'pkg/actions/groups';

import * as actions from 'pkg/actions';
import * as routes from 'pkg/router/routes';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

import { useSingleContactCtx } from 'routes/organization/contacts/single';

import Toggle from 'components/Toggle';
import Icon from 'components/icon';

import { useRemoveUserDialog } from 'components/user/remove';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const ToggleItem = styled(ContextMenu.Item)`
	grid-template-columns: auto 1fr auto;
`;

type UserActionsProps = {
	membership: models.membership.Membership;
	isMembership: boolean;
	group: models.group.Group;
	activeView: string;
	onEditTitle: (membership: any) => void;
	listTrigger?: boolean;
};

const UserActions = ({
	membership,
	isMembership = false,
	group,
	activeView,
	onEditTitle,
	listTrigger,
}: UserActionsProps): JSX.Element => {
	const { membershipCollection } = useSingleContactCtx();
	const memberships = membershipCollection.records;
	const removeRecord = membershipCollection.removeRecord;
	const refreshMemberships = membershipCollection.refresh;

	const activeMembership = useCurrentMembership();
	const org = useCurrentOrganization();

	const contextActions = [];
	const userId = isMembership ? membership.userId : membership.id;
	const isSelf = userId === activeMembership.userId;

	const updateMembership = async (payload: UpdateGroupMemberPayload) => {
		const [ok] = await actions.groups.updateGroupMember({
			groupId: membership.groupId,
			userId: userId,
			payload,
		});

		if (ok) {
			refreshMemberships();
		}
	};

	const handleSetAsAdmin = () => {
		if (models.membership.isAdmin(membership) && !isSelf) {
			handleSetAsUser();
			return;
		}

		if (!confirm(t(`Convert this user into an administrator?`))) {
			return;
		}

		updateMembership({ role: 2 });
	};

	const handleSetAsStaff = () => {
		if (models.membership.isStaff(membership) && !isSelf) {
			handleSetAsUser();
			return;
		}

		if (!confirm(t(`Convert this user into staff?`))) {
			return;
		}

		updateMembership({ role: 5 });
	};

	const handleSetAsUser = () => {
		updateMembership({ role: 1 });
	};

	const handleTitle = () => onEditTitle(membership);

	const handleRemoveUser = async (
		removeUserRecursively: boolean,
		removeFromFutureEvents: boolean
	) => {
		const req = await actions.memberships.removeUserFromGroup(
			membership.groupId,
			membership.userId,
			{
				removeUserRecursively,
				removeFromFutureEvents,
			}
		);

		let membershipIdsToRemove: number[] = [membership.id];

		if (removeUserRecursively) {
			membershipIdsToRemove = [
				...membershipIdsToRemove,
				...models.membership
					.findAllChildMemberships(memberships, membership)
					.map((m) => m.id),
			];
		}

		if (req) {
			membershipIdsToRemove.forEach((id) => removeRecord(id));
		}
	};

	const removeUserDialog = useRemoveUserDialog({
		handleRemoveUsers: handleRemoveUser,
	});

	const handleBan = () => {
		if (
			!confirm(
				t(
					`Do you want to block this user? The user won't be able to rejoin your group until the block is lifted.`
				)
			)
		) {
			return;
		}

		updateMembership({ status: 2 });
	};

	const handleLeave = () => {
		if (
			!confirm(
				t(
					`Are you sure you want to leave the group? You will be able to re-join using the group code or an email invite.`
				)
			)
		) {
			return;
		}

		updateMembership({ status: 3 });
	};

	if (activeView !== 'single' && (!isMembership || membership.status !== 2)) {
		contextActions.push(
			<ContextMenu.LinkItem
				key="view-user"
				icon="person"
				href={routes.Settings.User.Show(org.id, group.id, userId)}>
				{isMembership ? t(`View user`) : t(`Show all memberships`)}
			</ContextMenu.LinkItem>
		);
	}

	if (membership.status === 1 && isMembership) {
		contextActions.push(<ContextMenu.Divider key="divider-1" />);

		contextActions.push(
			<ContextMenu.Label key="role">{t(`Role`)}</ContextMenu.Label>
		);

		if (!isSelf) {
			contextActions.push(
				<ToggleItem key="set-as-user" onClick={handleSetAsUser}>
					<ContextMenu.ItemIcon name="person_add" />
					{t(`User`)}
					<Toggle active={models.membership.isPlayer(membership)} />
				</ToggleItem>
			);
		}

		contextActions.push(
			<ToggleItem key="set-as-staff" onClick={handleSetAsStaff}>
				<ContextMenu.ItemIcon name="person_add" />
				{t(`Staff`)}
				<Toggle active={models.membership.isStaff(membership)} />
			</ToggleItem>
		);

		contextActions.push(
			<ToggleItem key="set-as-admin" onClick={handleSetAsAdmin}>
				<ContextMenu.ItemIcon name="person_add" />
				{t(`Admin`)}
				<Toggle active={models.membership.isAdmin(membership)} />
			</ToggleItem>
		);

		if (models.membership.isAdminOrStaff(membership)) {
			contextActions.push(
				<ContextMenu.Item key="toggle-title-modal" onClick={handleTitle}>
					<ContextMenu.ItemIcon name="arrow_forward" />
					{t(`Set role`)}
				</ContextMenu.Item>
			);
		}

		contextActions.push(<ContextMenu.Divider key="divider-2" />);
	}

	if (activeView === 'single') {
		contextActions.push(
			<ContextMenu.LinkItem
				icon="person"
				href={routes.User.Profile.Show(org.id, group.id, userId, 'overview')}
				key="show-profile">
				{t(`View profile`)}
			</ContextMenu.LinkItem>
		);
	}

	if (!isSelf && isMembership) {
		contextActions.push(
			<ContextMenu.LinkItem
				icon="arrow_forward"
				key="go-to-group"
				href={routes.Group.Show(group.organizationId, group.id)}>
				{t(`Go to group`)}
			</ContextMenu.LinkItem>,
			<ContextMenu.Item
				caution
				icon="delete"
				key="deactivate"
				onClick={removeUserDialog}>
				{t(`Remove`)}
			</ContextMenu.Item>
		);

		if (membership.status !== 2) {
			contextActions.push(
				<ContextMenu.Item caution icon="lock" key="ban" onClick={handleBan}>
					{t(`Block`)}
				</ContextMenu.Item>
			);
		}
	}

	if (isSelf) {
		contextActions.push(
			<ContextMenu.LinkItem
				key="leave-group"
				icon="logout"
				caution
				onClick={handleLeave}>
				{t(`Leave group`)}
			</ContextMenu.LinkItem>
		);
	}

	let toggle = (
		<ContextMenu.TableTrigger>
			<Icon name="context-menu" />
		</ContextMenu.TableTrigger>
	);

	if (listTrigger) {
		toggle = <Button secondary small icon="more_horiz" />;
	}

	return (
		<ContextMenu.Menu toggleWith={toggle}>{contextActions}</ContextMenu.Menu>
	);
};

export default UserActions;
