import { useT } from '@transifex/react';
import { motion } from 'framer-motion';
import { Fragment } from 'react/jsx-runtime';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import useUserLogEditModal from 'routes/organization/user-profile/notes/form/edit';

import * as Card from 'components/Card';
import { FormattedContent } from 'components/formatted-content';
import MaterialSymbol from 'components/material-symbols';
import Avatar from 'components/avatar';
import RelativeDateTime from 'components/RelativeDateTime';

import GalleryPreview from 'components/attachment/gallery/preview';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface UserLogProps {
	user: models.user.User;
	userLog: models.userLog.UserLog;

	onUpdate: (userLog: models.userLog.UserLog) => void;
	onRemove: (userLog: models.userLog.UserLog) => void;
}

export default function UserLog({
	user,
	userLog,

	onUpdate,
	onRemove,
}: UserLogProps): JSX.Element {
	const t = useT();

	const canUpdate = models.hasAllowedAction(
		userLog,
		models.Action.UserLogUpdate
	);

	const canDelete = models.hasAllowedAction(
		userLog,
		models.Action.UserLogRemove
	);

	const handleRemoveAttachment = async (attachmentId: number) => {
		await actions.userLogs.removeAttachment(userLog, attachmentId);

		userLog.attachments = userLog.attachments.filter(
			(a) => a.id !== attachmentId
		);

		return true;
	};

	const { UserLogEditModal, openUserLogEditModal } = useUserLogEditModal(
		user,
		onUpdate,
		handleRemoveAttachment
	);

	const handleRemove = async () =>
		actions.userLogs.remove(userLog, () => onRemove(userLog));

	const handleEdit = () => {
		openUserLogEditModal(userLog);
	};

	return (
		<Fragment>
			<motion.div
				layout
				transition={{
					default: { ease: 'linear' },
					layout: { duration: 0.3 },
				}}>
				<Row columns="24px 1fr" spacing={spacing._3}>
					<div className={css.timeline}>
						<span className={css.indicator}>
							<MaterialSymbol variant="edit_note" scale={1.2} />
						</span>
					</div>
					<div className={css.cardWrapper}>
						<Card.Base>
							<Card.Body $noSpacing>
								<Column className={css.logEntry} spacing={spacing._2}>
									<Row
										columns="20px 1fr 20px"
										align="center"
										spacing={spacing._3}
										className={css.title}>
										<Avatar user={userLog.author} />
										<span>
											<strong>{models.user.fullName(userLog.author)}</strong>{' '}
											{t('in group {group}', { group: userLog.group.name })}
										</span>
										<span className={css.trigger}>
											{(canUpdate || canDelete) && (
												<Context.Menu
													toggleWith={
														<MaterialSymbol variant="more_horiz" scale={1.7} />
													}>
													{canUpdate && (
														<Context.Item onClick={handleEdit}>
															<Context.ItemIcon name="contract_edit" />
															<span>{t('Edit note')}</span>
														</Context.Item>
													)}
													{canUpdate && canDelete && <Context.Divider />}
													{canDelete && (
														<Context.ConfirmItem
															caution
															closeOnClick
															onConfirm={handleRemove}
															message={t(
																'Are you sure you want to delete this note? This action cannot be undone.'
															)}
															confirmLabel={t('Delete note')}>
															<Context.ItemIcon name="contract_delete" />
															<span>{t('Delete note')}</span>
														</Context.ConfirmItem>
													)}
												</Context.Menu>
											)}
										</span>
									</Row>
									<RelativeDateTime
										dateTime={userLog.updatedAt}
										className={css.timestamp}
									/>
									<FormattedContent raw={userLog.content} />
									{userLog.attachments && (
										<GalleryPreview attachments={userLog.attachments} />
									)}
								</Column>
							</Card.Body>
						</Card.Base>
					</div>
				</Row>
			</motion.div>
			{UserLogEditModal}
		</Fragment>
	);
}
