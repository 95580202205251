import styled from 'styled-components';
import { JSX, ChangeEvent, ReactNode, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { formatTime } from 'pkg/timeline';

import * as Input from 'components/form/inputs';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useCueState } from 'components/video-analytics/CueState';
import { useClipState } from 'components/video-analytics/ClipState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import Column from 'components/layout/column';

const Toggle = styled.div`
	padding: var(--spacing-3);
	background: var(--palette-gray-700);
	display: grid;
	grid-template-columns: 1fr 40px;
	justify-content: start;
	align-items: center;
	border-radius: var(--radius-3);
	cursor: pointer;
	transition: box-shadow 150ms ease-in-out;

	@media (hover: hover) {
		&:hover {
			box-shadow:
				0 0 0 1px var(--palette-gray-800),
				0 0 0 4px var(--palette-gray-600);
		}
	}
`;

const TimestampWrapper = styled.div`
	padding: var(--spacing-3);
	font-size: var(--font-size-sm);
	background: var(--palette-gray-700);
	border-radius: var(--radius-3);
`;

const ActionGroup = styled.div`
	display: grid;
	grid-template-rows: auto min-content;
	gap: var(--spacing-2);
	min-height: 0;
`;

function Timestamps(): JSX.Element {
	const clipState = useClipState();

	return (
		<TimestampWrapper>
			{t(
				'Clip is {duration} seconds long, starts at {startsAt} and ends at {endsAt}.',
				{
					duration: clipState.getDuration(),
					startsAt: formatTime(clipState.getStartsAt()),
					endsAt: formatTime(clipState.getEndsAt()),
				}
			)}
		</TimestampWrapper>
	);
}

interface FormProps {
	clipId: number;
	actions?: ReactNode;
	children?: ReactNode | ReactNode[];
}

export default function Form({
	clipId,
	actions,
	children,
}: FormProps): JSX.Element {
	const { isTrimming, isRecording, controller } = usePlayerState();
	const clipState = useClipState();
	const { setCueId } = useCueState();
	const { currentTime } = usePlaybackState();

	const isSmallScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	useComponentDidMount(() => {
		if (!isRecording) {
			controller.pause();
		}

		setCueId(clipId);
	});

	const setTitle = (event: ChangeEvent<HTMLInputElement>) =>
		clipState.setTitle(event.target.value);

	const setDescription = (event: ChangeEvent<HTMLInputElement>) =>
		clipState.setDescription(event.target.value);

	const setReviewed = () => clipState.setIsHighlight(!clipState.reviewed);

	const setPrivate = () => clipState.setIsPrivate(!clipState.private);

	useEffect(() => {
		if (!isSmallScreen && isRecording && !isTrimming) {
			clipState.setEndsAt(currentTime);
		}
	}, [currentTime]);

	return (
		<Column spacing={styles.spacing._6}>
			<Input.Group label={t('Clip name')}>
				<Input.Field
					name="name"
					value={clipState.title}
					onChange={setTitle}
					placeholder={t('Clip name')}
				/>
			</Input.Group>

			<Input.Group label={t('Description')}>
				<Input.Area
					minRows={2}
					maxRows={5}
					name="description"
					value={clipState.description}
					onChange={setDescription}
					placeholder={t('Description')}
				/>
			</Input.Group>

			<Timestamps />

			<Column spacing={styles.spacing._3}>
				<Toggle onClick={setReviewed}>
					<span>{t('Highlight')}</span>
					<Input.Control
						standalone
						type="toggle"
						checked={clipState.reviewed}
					/>
				</Toggle>

				<Toggle onClick={setPrivate}>
					<span>{t('Private')}</span>
					<Input.Control standalone type="toggle" checked={clipState.private} />
				</Toggle>
			</Column>

			{actions && <ActionGroup>{actions}</ActionGroup>}

			{children}
		</Column>
	);
}
