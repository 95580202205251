import { t } from '@transifex/native';
import { JSX, Fragment, MouseEvent, ReactNode, useState } from 'react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import { CollectionResponse, useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import {
	useCurrentGroup,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';

import {
	AggregatedCollectionResponse,
	useUserCollections,
	useUserSessions,
	useAggregatedCollections,
	useAggregatedSessions,
	useClubCollections,
	useClubSessions,
	useCuratedCollections,
	useCuratedSessions,
	useGroupCollections,
	useGroupSessions,
} from 'routes/training/library/hooks';
import CurrentFilter from 'routes/training/library/CurrentFilter';

import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import SessionGroupItem from 'components/library/group-items/TrainingSession';
import TrainingCollectionGroupItem from 'components/library/group-items/TrainingCollection';
import Group from 'components/library/Group';
import * as ActionBar from 'components/layout/ActionBar';
import Row from 'components/layout/row';
import EmptyState from 'components/library/TrainingEmptyState';
import GroupItemButton from 'components/library/GroupItemButton';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface SessionGroupProps {
	onSelect: (exerciseId: number) => void;
}

interface SessionProps extends SessionGroupProps {
	onClose: () => void;
}

enum ContentType {
	Aggregated = 'all',
	Group = 'team',
	Club = 'club',
	Curated = 'curated',
	Account = 'my',
}

function contentTypeTitle(type: ContentType): string {
	switch (type) {
		case ContentType.Aggregated:
			return t('Browse');
		case ContentType.Group:
			return t('Team');
		case ContentType.Club:
			return t('Club');
		case ContentType.Curated:
			return t('Curated content');
		case ContentType.Account:
			return t('My');
	}
}

interface SessionAndCollectionItemsProps extends SessionGroupProps {
	sessions?:
		| CollectionResponse<models.session.Session>
		| AggregatedCollectionResponse<models.session.Session>;
	collections?:
		| CollectionResponse<models.trainingCollection.TrainingCollection>
		| AggregatedCollectionResponse<models.trainingCollection.TrainingCollection>;
}

function SessionAndCollectionItems({
	sessions,
	collections,

	onSelect,
}: SessionAndCollectionItemsProps): JSX.Element {
	const filteredCollections = collections.records?.filter(
		(collection: models.trainingCollection.TrainingCollection) =>
			collection.sessionCount > 0
	);

	const isLoading = sessions?.isLoading || collections?.isLoading;
	const hasExercies = sessions?.records.length > 0;
	const hasCollections = filteredCollections?.length > 0;
	const hasRecords = !isLoading && (hasExercies || hasCollections);

	const [collectionId, setCollectionId] = useState<number>(0);

	if (isLoading) {
		return <Spinner />;
	}

	if (!hasRecords) {
		return <EmptyState />;
	}

	const handleSessionClick = (item: models.session.Session) => {
		onSelect(item.id);
	};

	const handleCollectionClick = (
		item: models.trainingCollection.TrainingCollection
	) => {
		setCollectionId(item.id);
	};

	const closeCollection = () => {
		setCollectionId(0);
	};

	if (collectionId !== 0) {
		return (
			<Column spacing={spacing._8}>
				<CurrentFilter />
				<div>
					<Button
						icon="arrow_back"
						label={t('Back')}
						onClick={closeCollection}
					/>
				</div>
				<CollectionSessionItems
					onSelect={onSelect}
					collectionId={collectionId}
				/>
			</Column>
		);
	}

	return (
		<Column spacing={spacing._8}>
			<CurrentFilter />
			{hasExercies && (
				<Group title={t('Sessions')} context="training-library">
					{sessions.records.map((item: models.session.Session, n: number) => (
						<SessionGroupItem
							item={item}
							key={`${item.id}:${n}`}
							onClick={handleSessionClick}
						/>
					))}
					{sessions.pagination.hasNext && sessions.pagination.fetchNext && (
						<GroupItemButton
							label={t('Load more')}
							onClick={sessions.pagination.fetchNext}
						/>
					)}
				</Group>
			)}
			{hasCollections && (
				<Group title={t('Collections')} context="training-library">
					{collections.records.map(
						(item: models.trainingCollection.TrainingCollection, n: number) => (
							<TrainingCollectionGroupItem
								item={item}
								key={`${item.id}:${n}`}
								onClick={handleCollectionClick}
							/>
						)
					)}
					{collections.pagination.hasNext &&
						collections.pagination.fetchNext && (
							<GroupItemButton
								label={t('Load more')}
								onClick={collections.pagination.fetchNext}
							/>
						)}
				</Group>
			)}
		</Column>
	);
}

interface CollectionSessionItemsProps extends SessionGroupProps {
	collectionId: number;
}

function CollectionSessionItems({
	collectionId,
	onSelect,
}: CollectionSessionItemsProps): JSX.Element {
	const org = useCurrentOrganization();
	const sessions =
		useCollection<models.trainingCollection.TrainingCollectionSession>(
			endpoints.TrainingCollections.ShowSessions(org.id, collectionId),
			{ showAllResults: true }
		);

	const records: models.session.Session[] = sessions.records.map(
		(item: models.trainingCollection.TrainingCollectionSession) => item.session
	);

	const handleSessionClick = (item: models.session.Session) => {
		onSelect(item.id);
	};

	return (
		<Group title={t('Sessions')} context="training-library">
			{records.map((item: models.session.Session, n: number) => (
				<SessionGroupItem
					item={item}
					key={`${item.id}:${n}`}
					onClick={handleSessionClick}
				/>
			))}
		</Group>
	);
}

function AggregatedSessions({ onSelect }: SessionGroupProps): JSX.Element {
	const sessions = useAggregatedSessions();
	const collections = useAggregatedCollections();

	return (
		<SessionAndCollectionItems
			sessions={sessions}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

function GroupSessions({ onSelect }: SessionGroupProps): JSX.Element {
	const group = useCurrentGroup();

	const sessions = useGroupSessions(group.id);
	const collections = useGroupCollections(group.id);

	return (
		<SessionAndCollectionItems
			sessions={sessions}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

function ClubSessions({ onSelect }: SessionGroupProps): JSX.Element {
	const group = useCurrentGroup();
	const sessions = useClubSessions(group.id);
	const collections = useClubCollections(group.id);

	return (
		<SessionAndCollectionItems
			sessions={sessions}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

function CuratedSessions({ onSelect }: SessionGroupProps): JSX.Element {
	const sessions = useCuratedSessions();
	const collections = useCuratedCollections();

	return (
		<SessionAndCollectionItems
			sessions={sessions}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

function AccountSessions({ onSelect }: SessionGroupProps): JSX.Element {
	const org = useCurrentOrganization();
	const user = useCurrentUser();
	const sessions = useUserSessions(org.id, user.id);
	const collections = useUserCollections();

	return (
		<SessionAndCollectionItems
			sessions={sessions}
			collections={collections}
			onSelect={onSelect}
		/>
	);
}

export default function Session({
	onSelect,
	onClose,
}: SessionProps): JSX.Element {
	const [contentType, setContentType] = useState<ContentType>(
		ContentType.Aggregated
	);

	const handleContentChange = (event: MouseEvent<HTMLDivElement>) => {
		setContentType(
			(event.currentTarget as HTMLDivElement).dataset.contentType as ContentType
		);
	};

	const trigger: ReactNode = (
		<div className={css.trigger}>
			<Row align="center" justify="center" spacing={spacing._3}>
				<span>{contentTypeTitle(contentType)}</span>
				<Icon name="select-trigger" size={1.5} />
			</Row>
		</div>
	);

	const contentTypes: ReactNode[] = Object.values(ContentType).map(
		(type: string) => {
			const isActive = (type as ContentType) === contentType;

			return (
				<ContextMenu.Item
					key={type}
					columns={isActive ? '1fr auto' : 'auto'}
					data-content-type={type}
					onClick={handleContentChange}>
					<span>{contentTypeTitle(type as ContentType)}</span>
					{isActive && <ContextMenu.ItemIcon name="check" />}
				</ContextMenu.Item>
			);
		}
	);

	let library: ReactNode = null;

	switch (contentType) {
		case ContentType.Aggregated:
			library = <AggregatedSessions onSelect={onSelect} />;
			break;
		case ContentType.Group:
			library = <GroupSessions onSelect={onSelect} />;
			break;
		case ContentType.Club:
			library = <ClubSessions onSelect={onSelect} />;
			break;
		case ContentType.Curated:
			library = <CuratedSessions onSelect={onSelect} />;
			break;
		case ContentType.Account:
			library = <AccountSessions onSelect={onSelect} />;
			break;
		default:
			library = null;
	}

	return (
		<StepModal.Base wide onClose={onClose}>
			<StepModal.StepWithoutBody hideNext title={t(`Add session`)}>
				<ActionBar.FilterBar>
					<ActionBar.PrimaryAction>
						<ActionBar.Search placeholder={t('Search')} queryName="title">
							<Input.Suffix interactable>
								<ContextMenu.Menu toggleWith={trigger}>
									<Fragment>{contentTypes}</Fragment>
								</ContextMenu.Menu>
							</Input.Suffix>
						</ActionBar.Search>
					</ActionBar.PrimaryAction>
				</ActionBar.FilterBar>
				<StepModal.Body>{library}</StepModal.Body>
			</StepModal.StepWithoutBody>
		</StepModal.Base>
	);
}
