import {
	JSX,
	ReactNode,
	PointerEvent,
	MouseEvent,
	useRef,
	useState,
	Fragment,
} from 'react';
import { createPortal } from 'react-dom';

import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { cssClasses } from 'pkg/css/utils';

import * as css from './styles.css';

interface Coords {
	x: number;
	y: number;
}

function getSafePosition(coords: Coords, rect: DOMRect): Coords {
	const w = window.innerWidth;
	const rw = rect.width;

	let x = coords.x;
	const y = coords.y;

	// Overflow on right side of the screen
	if (rw / 2 + x > w) {
		x = w - (rw + x - w);
	}

	return { x, y };
}

interface TooltipProps {
	persist?: boolean;
	component: string | ReactNode;
	className?: string;

	position: {
		x: number;
		y: number;
	};

	align: 'left' | 'center' | 'right';
	skipHorizontalAlign?: boolean;
	skipVerticalAlign?: boolean;

	onMouseEnter?: (event: MouseEvent<HTMLElement>) => void;
	onMouseLeave?: (event: MouseEvent<HTMLElement>) => void;
	onOutsideInteract?: (event: PointerEvent<HTMLElement>) => void;
}

export default function Tooltip({
	persist = false,
	component,
	className,
	position,

	align,
	skipHorizontalAlign,
	skipVerticalAlign,

	onMouseEnter,
	onMouseLeave,
	onOutsideInteract,
}: TooltipProps): JSX.Element {
	const zIndex = useNewTopIndex();
	const tooltipRef = useRef<HTMLSpanElement>();
	const [coords, setCoords] = useState<Coords>({ x: -1000, y: -1000 });

	const targetNode = document.getElementById('tooltip-portal-container');

	if (typeof component === 'string') {
		component = <span className={css.textTooltip}>{component}</span>;
	}

	const styles = {
		zIndex: zIndex + 1,
		'--x': skipHorizontalAlign ? '0px' : `${coords.x}px`,
		'--y': skipVerticalAlign ? '0px' : `${coords.y}px`,
	};

	const handleOutsideInteract = (event: PointerEvent<HTMLDivElement>) => {
		event.stopPropagation();

		if (onOutsideInteract) {
			onOutsideInteract(event);
		}
	};

	useComponentDidMount(() => {
		setCoords(
			getSafePosition(position, tooltipRef.current.getBoundingClientRect())
		);
	});

	return createPortal(
		<Fragment>
			{persist && (
				<div
					className={css.backdrop}
					onClick={handleOutsideInteract}
					style={{ zIndex }}
				/>
			)}
			<span
				ref={tooltipRef}
				data-align={align}
				className={cssClasses(css.tooltip, className)}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				style={styles}>
				{component}
			</span>
		</Fragment>,
		targetNode
	);
}
