import { JSX } from 'react';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

const InfoBoxView = (): JSX.Element => {
	return (
		<Column>
			<InfoBox
				text="Upload a video to YouTube, or find a public video that you
    want to analyze and paste the link here.  Uploaded videos have
    to be either Public or Unlisted to work."
			/>
			<InfoBox
				text="Upload a video to YouTube, or find a public video that you
    want to analyze and paste the link here.  Uploaded videos have
    to be either Public or Unlisted to work."
				color="green"
			/>
			<InfoBox
				text="Upload a video to YouTube, or find a public video that you
    want to analyze and paste the link here.  Uploaded videos have
    to be either Public or Unlisted to work."
				color="orange"
			/>
			<InfoBox
				text="Upload a video to YouTube, or find a public video that you
    want to analyze and paste the link here.  Uploaded videos have
    to be either Public or Unlisted to work."
				color="red"
			/>
			<InfoBox
				text="Upload a video to YouTube, or find a public video that you
    want to analyze and paste the link here.  Uploaded videos have
    to be either Public or Unlisted to work."
				color="yellow"
			/>
		</Column>
	);
};

export default InfoBoxView;
