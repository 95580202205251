import { t } from '@transifex/native';
import {
	JSX,
	ChangeEvent,
	Fragment,
	PointerEvent,
	useEffect,
	useRef,
	useState,
} from 'react';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as arrays from 'pkg/arrays';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import {
	useCurrentGroup,
	useCurrentGroupId,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import { useEndpoint } from 'pkg/api/use_endpoint';
import useMixedState from 'pkg/hooks/useMixedState';
import { toFilterQuery } from 'pkg/filters/use_filters';
import {
	FilterOperator,
	createQueryFilter,
	QueryFilterObject,
} from 'pkg/filters';
import { useEventDispatcher } from 'pkg/hooks/events';
import * as actions from 'pkg/actions';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';

import { TabBar, Tab, TabView } from 'components/tab-bar';
import Icon from 'components/icon';
import RelativeDateTime from 'components/RelativeDateTime';
import Label from 'components/label';
import Pagination from 'components/pagination';
import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import InfoBox from 'components/form/info-box';

import * as Context from 'design/context_menu';
import * as Table from 'design/table';
import Button from 'design/button';

import * as css from './style.css';

interface HeaderProps {
	onSearch: (keyword: string) => void;
}

function Header({ onSearch }: HeaderProps): JSX.Element {
	const ctx = StepModal.useStepModalContext();

	const searchRef = useRef<HTMLInputElement>();
	const [search, setSearch] = useState<string>('');

	const goToCreate = () => ctx.goTo('create');

	const clearSearch = () => {
		setSearch('');
		searchRef.current.focus();
	};

	const handleTyping = (event: ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		onSearch(event.target.value);
	};

	return (
		<Row columns="1fr auto" align="center" className={css.header}>
			<Input.Field
				small
				ref={searchRef}
				type="search"
				placeholder={t('Search collections')}
				onTyping={handleTyping}
				onChange={handleChange}
				changeDelay={350}
				value={search}>
				<Input.Prefix inline>
					<Icon name="search" />
				</Input.Prefix>
				{search.length > 0 && (
					<Input.Suffix inline onClick={clearSearch}>
						<Icon name="close" />
					</Input.Suffix>
				)}
			</Input.Field>
			<Button icon="add" secondary onClick={goToCreate}>
				{t('New')}
			</Button>
		</Row>
	);
}

interface ListCollectionsProps {
	videoId: number;
	groupId?: number;
	showSharedWith?: boolean;
	showInherited?: boolean;
	selectedIds?: number[];
	search?: string;

	onSelect: (collectionIds: number[]) => void;
	onDeselect: (collectionIds: number[]) => void;
}

function ListCollections({
	videoId,
	groupId,
	showSharedWith = false,
	showInherited = false,
	selectedIds = [],
	search,

	onSelect,
	onDeselect,
}: ListCollectionsProps): JSX.Element {
	const count: number = 20;
	const filters: QueryFilterObject = {};
	const queryParams: URLSearchParams = new URLSearchParams({});

	if (search) {
		filters.search = createQueryFilter({
			operator: FilterOperator.Contains,
			values: [search],
		});

		queryParams.set('filters', toFilterQuery(filters));
	}

	if (groupId) {
		queryParams.set('group_id', groupId.toString());
	}

	if (showInherited === true) {
		queryParams.set('inherited', '1');
	}

	const {
		records: collections,
		isLoading,
		pagination,
		selection,
	} = useCollection<models.videoCollection.VideoCollection>(
		endpoints.VideoCollections.Index(),
		{
			showAllResults: false,
			queryParams: queryParams,
			count,
		}
	);

	const inCollection = (
		collection: models.videoCollection.VideoCollection,
		videoId: number
	): boolean => {
		if (collection?.videoCount === 0) {
			return false;
		}

		return (
			collection.videos?.findIndex(
				(item: models.videoCollection.VideoCollectionItem) =>
					item.videoId === videoId
			) !== -1
		);
	};

	useEffect(() => {
		if (selectedIds.length > 0) {
			selection.selectMultiple(selectedIds);
		}
	}, [selectedIds]);

	const currentCollectionIds: number[] = collections.map(
		(collection: models.videoCollection.VideoCollection) => collection.id
	);

	const handleSelect = (event: PointerEvent<HTMLTableRowElement>) => {
		const collectionId = Number.parseInt(event.currentTarget.id, 10);

		if (selection.isSelected(collectionId)) {
			onDeselect([collectionId]);
		} else {
			onSelect([collectionId]);
		}

		selection.selectSingle(collectionId);
	};

	const toggleSelectAll = () => {
		if (selection.isAllSelected) {
			onDeselect(currentCollectionIds);
			selection.deselectAll();
		} else {
			onSelect(currentCollectionIds);
			selection.selectAll();
		}
	};

	const columns: Table.HeaderColumn[] = [
		{
			content: (
				<Input.Control
					standalone
					type="checkbox"
					checked={selection.isAllSelected}
					onChange={toggleSelectAll}
				/>
			),
			width: 'max-content',
		},
		{
			content: t('Collection Name'),
			width: '1fr',
		},
		{
			content: t('Videos'),
			align: 'center',
			width: 'max-content',
		},
	];

	return (
		<Fragment>
			<Table.Table columns={columns} isLoading={isLoading}>
				{collections.map(
					(collection: models.videoCollection.VideoCollection) => (
						<Table.Row
							key={collection.id}
							id={collection.id.toString()}
							onClick={inCollection(collection, videoId) ? null : handleSelect}>
							<Table.Cell>
								{inCollection(collection, videoId) ? (
									<Input.Control standalone type="checkbox" checked disabled />
								) : (
									<Input.Control
										standalone
										type="checkbox"
										value={collection.id}
										checked={selection.isSelected(collection.id)}
									/>
								)}
							</Table.Cell>
							<Table.Cell>
								<Row columns="1fr auto" className={css.row} align="center">
									<Column spacing={styles.spacing._1}>
										<strong>{collection.name}</strong>
										<RelativeDateTime dateTime={collection.createdAt} />
									</Column>
									<div>
										{showSharedWith && (
											<Fragment>
												{collection.groupId && !collection.isClubCollection && (
													<Label color="blue">{t('Shared with team')}</Label>
												)}
												{collection.groupId && collection.isClubCollection && (
													<Label color="orange">{t('Shared with club')}</Label>
												)}
											</Fragment>
										)}
									</div>
								</Row>
							</Table.Cell>
							<Table.Cell align="center">{collection.videoCount}</Table.Cell>
						</Table.Row>
					)
				)}
			</Table.Table>
			<Pagination {...pagination} />
		</Fragment>
	);
}

interface CollectionPayload {
	name: string;
	sharedWith: models.SharedWith;
}

interface CollectionFormProps {
	collectionId?: number;
	afterSave?: (collection: models.videoCollection.VideoCollection) => void;
}

function CollectionForm({
	collectionId,
	afterSave,
}: CollectionFormProps): JSX.Element {
	const group = useCurrentGroup();
	const membership = useCurrentMembership();
	const isAdminOrStaff = models.membership.isAdminOrStaff(membership);
	const { withNext, setCanGoNext } = StepModal.useStepModalContext();

	const [data, setData] = useMixedState<CollectionPayload>({
		name: '',
		sharedWith: models.SharedWith.Account,
	});

	const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setData({ name: event.target.value });
	};

	const shareWithMe = () => setData({ sharedWith: models.SharedWith.Account });

	const shareWithTeam = () => setData({ sharedWith: models.SharedWith.Group });

	const shareWithClub = () => setData({ sharedWith: models.SharedWith.Club });

	const { record: collection, isLoading } =
		useEndpoint<models.videoCollection.VideoCollection>(
			!!collectionId ? endpoints.VideoCollections.Show(collectionId) : null
		);

	useEffect(() => {
		if (collection?.id) {
			setData({ name: collection.name });

			if (collection.groupId && !collection.isClubCollection) {
				setData({ sharedWith: models.SharedWith.Group });
			}

			if (collection.groupId && collection.isClubCollection) {
				setData({ sharedWith: models.SharedWith.Club });
			}
		}
	}, [collection]);

	useEffect(() => {
		setCanGoNext(data.name.length > 0);
	}, [data.name]);

	withNext(async () => {
		if (collectionId) {
			const payload: models.videoCollection.VideoCollectionUpdatePayload = {
				name: data.name,
			};

			if (collection.groupId !== 0) {
				if (data.sharedWith === models.SharedWith.Group) {
					payload.inherit = false;
				} else if (data.sharedWith === models.SharedWith.Club) {
					payload.inherit = true;
				}
			}

			const [req, updatedCollection] = await models.videoCollection.update(
				collection,
				payload
			);

			if (afterSave && req.ok) {
				afterSave(updatedCollection);
			}
		} else {
			const payload: models.videoCollection.VideoCollectionCreatePayload = {
				name: data.name,
			};

			if (data.sharedWith === models.SharedWith.Group) {
				payload.groupId = group.id;
				payload.inherit = false;
			} else if (data.sharedWith === models.SharedWith.Club) {
				payload.groupId = group.id;
				payload.inherit = true;
			}

			const [req, newCollection] = await models.videoCollection.create(payload);

			if (afterSave && req.ok) {
				afterSave(newCollection);
			}
		}

		return true;
	});

	let shareLabel = (
		<Row columns="repeat(2, auto)" align="center" justify="center">
			<Icon name="user" />
			<span>{t('Only you')}</span>
		</Row>
	);

	if (data.sharedWith === 'team') {
		shareLabel = (
			<Row columns="repeat(2, auto)" align="center" justify="center">
				<Icon name="nav-members" />
				<span>{t('Shared with team')}</span>
			</Row>
		);
	} else if (data.sharedWith === 'club') {
		shareLabel = (
			<Row columns="repeat(2, auto)" align="center" justify="center">
				<Icon name="nav-badge" />
				<span>{t('Shared with club')}</span>
			</Row>
		);
	}

	const isDisabled = collectionId && !collection?.groupId;

	const triggerButton = (
		<Button
			small
			primary
			icon="expand_more"
			iconPosition="right"
			disabled={isDisabled}>
			{shareLabel}
		</Button>
	);

	if (!isLoading && collectionId && !collection?.id) {
		return (
			<Column justify="center">
				<Icon name="error" size={2} />
				<strong>{t('Could not load collection')}</strong>
			</Column>
		);
	}

	return (
		<Column>
			<Input.Field
				placeholder={t('Collection name')}
				value={data.name}
				onChange={handleNameChange}>
				<Input.Prefix inline>
					<Icon name="add-folder" size={1.2} />
				</Input.Prefix>
			</Input.Field>

			{isAdminOrStaff ? (
				<Fragment>
					<InfoBox>
						<Column>
							{isDisabled ? (
								triggerButton
							) : (
								<Context.Menu toggleWith={triggerButton}>
									{!collectionId && (
										<Context.Item onClick={shareWithMe}>
											<Context.ItemIcon name="person" />
											{t('Only you')}
										</Context.Item>
									)}

									<Context.Item onClick={shareWithTeam}>
										<Context.ItemIcon name="group" />
										{t('Shared with team')}
									</Context.Item>

									<Context.Item onClick={shareWithClub}>
										<Context.ItemIcon name="groups" />
										{t('Shared with club')}
									</Context.Item>
								</Context.Menu>
							)}

							<span>
								{data.sharedWith === 'club' && (
									<T
										_str="Visible to everyone in {team_name} and all child groups."
										team_name={<strong>{group.name}</strong>}
									/>
								)}
								{data.sharedWith === 'only_you' && t('Visible only to you.')}
								{data.sharedWith === 'team' && (
									<T
										_str="Visible to everyone in {team_name}."
										team_name={<strong>{group.name}</strong>}
									/>
								)}
							</span>
						</Column>
					</InfoBox>
				</Fragment>
			) : (
				<InfoBox>{t('This collection will only be visible to you')}</InfoBox>
			)}
		</Column>
	);
}

interface CollectionProps {
	videoId?: number;
	collectionId?: number;
	createOnly?: boolean;

	onClose?: () => void;
	afterSave?: (collection: models.videoCollection.VideoCollection) => void;
}

export default function Collection({
	videoId,
	collectionId,
	createOnly = false,

	onClose,
	afterSave,
}: CollectionProps): JSX.Element {
	const groupId = useCurrentGroupId();
	const org = useCurrentOrganization();

	const [search, setSearch] = useState<string>();

	const dispatchVideoCollectionSaved = useEventDispatcher(
		'video-collection-saved'
	);

	const handleSearch = (keyword: string) => setSearch(keyword);

	let initialIndex: number = createOnly ? 1 : 0;
	let title = t('Create new video collection');

	// Enforce collection form if edit
	if (collectionId) {
		initialIndex = 1;
		title = t('Edit collection');
	}

	const [selectedIds, setSelectedIds] = useState<number[]>([]);

	const tabs: Tab[] = [
		{ id: 'my', label: t('My') },
		{ id: 'team', label: t('Team') },
		{ id: 'club', label: t('Club') },
	];

	const handleCollectionSelect = (collectionIds: number[]) => {
		const nextSelectedIds = arrays.unique([...selectedIds, ...collectionIds]);

		setSelectedIds(nextSelectedIds);
	};

	const handleCollectionDeselect = (collectionIds: number[]) => {
		const nextSelectedIds = selectedIds.filter(
			(id: number) => !collectionIds.includes(id)
		);

		setSelectedIds(nextSelectedIds);
	};

	const handleCollectionCreate = (
		collection: models.videoCollection.VideoCollection
	) => {
		if (createOnly) {
			pushState(
				routes.VideoLibrary.Collection.Show(org.id, groupId, collection.id)
			);
		} else {
			handleCollectionSelect([collection.id]);
		}

		if (afterSave) {
			afterSave(collection);
		}
	};

	const handleAddToCollection = async () => {
		if (videoId) {
			await Promise.all([
				selectedIds.map((collectionId: number) =>
					models.videoCollection.addVideo(collectionId, videoId)
				),
			]);

			const message =
				selectedIds.length > 1
					? t('The video was added to {num} collections.', {
							num: selectedIds.length,
						})
					: t('Video added to collection');

			actions.flashes.show({
				title: t('Video added!'),
				message,
			});

			dispatchVideoCollectionSaved();

			return true;
		}

		return false;
	};

	return (
		<StepModal.Base initialIndex={initialIndex} onClose={onClose}>
			<StepModal.Step
				slug="list"
				title={t('Add to collection')}
				skipBody
				closeOnNext
				nextLabel={t('Finish')}
				canGoNext={selectedIds.length > 0}
				onNext={handleAddToCollection}>
				<Column justify="start" className={css.wrapper}>
					<Header onSearch={handleSearch} />
					<TabBar tabs={tabs} className={css.navigation}>
						<TabView tabId="my" className={css.view}>
							<ListCollections
								videoId={videoId}
								showSharedWith
								selectedIds={selectedIds}
								onSelect={handleCollectionSelect}
								onDeselect={handleCollectionDeselect}
								search={search}
							/>
						</TabView>
						<TabView tabId="team" className={css.view}>
							<ListCollections
								videoId={videoId}
								groupId={groupId}
								selectedIds={selectedIds}
								onSelect={handleCollectionSelect}
								onDeselect={handleCollectionDeselect}
								search={search}
							/>
						</TabView>
						<TabView tabId="club" className={css.view}>
							<ListCollections
								videoId={videoId}
								groupId={groupId}
								showInherited
								selectedIds={selectedIds}
								onSelect={handleCollectionSelect}
								onDeselect={handleCollectionDeselect}
								search={search}
							/>
						</TabView>
					</TabBar>
				</Column>
			</StepModal.Step>
			<StepModal.Step
				slug="create"
				title={title}
				hidePrev={createOnly}
				nextSlug="list"
				prevLabel={t('Cancel')}
				nextLabel={collectionId ? t('Save') : t('Create')}
				closeOnNext={createOnly}
				closeOnPrev={!!collectionId}>
				<CollectionForm
					collectionId={collectionId}
					afterSave={handleCollectionCreate}
				/>
			</StepModal.Step>
		</StepModal.Base>
	);
}
