import { JSX, ChangeEvent, Fragment, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Options, RRule, rrulestr } from 'rrule';
import { t } from '@transifex/native';

import { toMedium } from 'pkg/config/breakpoints';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { days, months } from 'pkg/date';

import {
	dayRules,
	RecurringByWeekDayNthPositions,
} from 'routes/event/rRuleConfig';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Select from 'components/form/Select';
import Row from 'components/layout/row';

interface CustomRecurringOptionsProps {
	rruleString: string;
	onDay: boolean;
	setOnDay: (onDay: boolean) => void;
	setRuleString: (rRuleString: string) => void;
}

const CustomRecurringOptions = ({
	rruleString,
	onDay,
	setOnDay,
	setRuleString,
}: CustomRecurringOptionsProps): JSX.Element => {
	const [intervalHasError, setIntervalHasError] = useState(false);
	const rule = rrulestr(rruleString);

	const newRule: Partial<Options> = {
		...rule.origOptions,
	};

	useComponentDidMount(() => {
		if (
			!!rule.options.bynweekday &&
			(rule.options.freq === RRule.MONTHLY ||
				rule.options.freq === RRule.YEARLY)
		) {
			setOnDay(!onDay);
		}
	});

	const onFreqChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const freq = Number.parseInt(e.target.value, 10);

		newRule.freq = freq;

		if (freq === RRule.WEEKLY) {
			newRule.byweekday = [0];
		} else if (freq === RRule.MONTHLY && onDay) {
			newRule.bymonthday = rule.options.bymonthday?.[0] || 1;
		}

		setRuleString(new RRule(newRule).toString());
	};

	const onByMonthDayChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = Number.parseInt(e.target.value, 10);

		if (value > 31) return;

		newRule.bymonthday = value;
		setRuleString(new RRule(newRule).toString());
	};

	const onNumberInputChange = (
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const interval = Number.parseInt(e.target.value, 10);
		if (Number.isNaN(interval)) {
			setIntervalHasError(true);
			return;
		}

		setIntervalHasError(false);
		newRule.interval = interval;
		setRuleString(new RRule(newRule).toString());
	};

	const onRadioChange = () => {
		setOnDay(!onDay);

		if (onDay) {
			newRule.byweekday = dayRules[
				rule.options.bynweekday?.[0][0] || RRule.MO.weekday
			].nth(
				rule.options.bynweekday?.[0][1] || RecurringByWeekDayNthPositions.First
			);
		} else {
			newRule.bymonthday = rule.options.bymonthday?.[0] || 1;
		}

		setRuleString(new RRule(newRule).toString());
	};

	const onSelectDays = (weekDay: number) => {
		if (
			rule.options.byweekday.length === 1 &&
			rule.options.byweekday.includes(weekDay)
		) {
			return;
		}

		newRule.byweekday = rule.options.byweekday.includes(weekDay)
			? rule.options.byweekday.filter((item) => item !== weekDay)
			: [...rule.options.byweekday, weekDay];

		setRuleString(new RRule(newRule).toString());
	};

	const onSingleSelectWeekDay = (e: ChangeEvent<HTMLSelectElement>) => {
		newRule.byweekday = dayRules[Number.parseInt(e.target.value, 10)].nth(
			rule.options.bynweekday?.[0][1] || RecurringByWeekDayNthPositions.First
		);
		setRuleString(new RRule(newRule).toString());
	};

	const onNthChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const byweekday = rule.options.bynweekday?.[0][0] || RRule.MO.weekday;

		newRule.byweekday = dayRules[byweekday].nth(
			Number.parseInt(e.target.value, 10)
		);

		setRuleString(new RRule(newRule).toString());
	};

	const changeByMonth = (e: ChangeEvent<HTMLSelectElement>) => {
		newRule.bymonth = Number.parseInt(e.target.value, 10);

		setRuleString(new RRule(newRule).toString());
	};

	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });

	const onDayInputs = (
		<Fragment>
			<Input.Control
				type="radio"
				label={t(`On day`)}
				checked={onDay}
				onChange={onRadioChange}
			/>
			{rule.options.freq === RRule.YEARLY && (
				<Select
					value={rule.options.bymonth?.[0] || 1}
					onChange={changeByMonth}
					disabled={!onDay}>
					{months().map((m) => {
						return (
							<option value={m[0]} key={m[1]}>
								{m[1]}
							</option>
						);
					})}
				</Select>
			)}
			<Input.Field
				type="number"
				name="bymonthday"
				defaultValue={rule.options.bymonthday?.[0] || 1}
				onChange={onByMonthDayChange}
				min="1"
				max="31"
				disabled={!onDay}
				changeDelay={100}
			/>
		</Fragment>
	);

	const nthOccurrenceInputs = (
		<Fragment>
			<Input.Control
				type="radio"
				label={t(`On the`)}
				checked={!onDay}
				onChange={onRadioChange}
				testid="events.form.recurring.on_the"
			/>
			<Input.Select
				value={
					rule.options?.bynweekday?.[0][1] ||
					RecurringByWeekDayNthPositions.First
				}
				name="bynweekdayposition"
				onChange={onNthChange}
				disabled={onDay}
				testid="events.form.nth.select">
				<option value={RecurringByWeekDayNthPositions.First}>
					{t(`First`)}
				</option>
				<option value={RecurringByWeekDayNthPositions.Second}>
					{t(`Second`)}
				</option>
				<option value={RecurringByWeekDayNthPositions.Third}>
					{t(`Third`)}
				</option>
				<option value={RecurringByWeekDayNthPositions.Fourth}>
					{t(`Fourth`)}
				</option>
				<option value={RecurringByWeekDayNthPositions.Last}>{t(`Last`)}</option>
			</Input.Select>
			<Input.Select
				name="byweekday"
				value={rule.options.bynweekday?.[0][0] || RRule.MO.weekday}
				onChange={onSingleSelectWeekDay}
				disabled={onDay}
				testid="events.form.recurring.byweekday">
				{days().map((d) => (
					<option value={d[0]} key={d[0]}>
						{d[1]}
					</option>
				))}
			</Input.Select>
			{rule.options.freq === RRule.YEARLY && (
				<Input.Select
					value={rule.options.bymonth?.[0] || 1}
					onChange={changeByMonth}
					disabled={onDay}
					testid="events.form.recurring.bymonth">
					{months().map((m) => {
						return (
							<option value={m[0]} key={m[1]}>
								{m[1]}
							</option>
						);
					})}
				</Input.Select>
			)}
		</Fragment>
	);

	return (
		<Column>
			<Input.Group label={t(`Repeat every`)}>
				<Row autoColumns={isSmallScreen ? '1fr auto' : '84px max-content'}>
					<Input.Group hasError={intervalHasError}>
						<Input.Field
							name="interval"
							type="number"
							min={1}
							defaultValue={rule.options.interval || 1}
							onChange={onNumberInputChange}
							changeDelay={100}
						/>
					</Input.Group>
					<Input.Select
						name="freq"
						value={rule.options.freq}
						onChange={onFreqChange}
						testid="recurring.options.frequency">
						<option value={RRule.DAILY}>{t('Day')}</option>
						<option value={RRule.WEEKLY}>{t('Week')}</option>
						<option value={RRule.MONTHLY}>{t('Monthly')}</option>
						<option value={RRule.YEARLY}>{t('Year')}</option>
					</Input.Select>
				</Row>
			</Input.Group>
			{rule.options.freq === RRule.WEEKLY && (
				<Input.Group label={t(`On`)}>
					{days().map((d) => {
						return (
							<Input.Control
								type="checkbox"
								label={d[1]}
								key={d[0]}
								value={d[0]}
								onChange={onSelectDays}
								checked={rule.options.byweekday.includes(d[0])}
							/>
						);
					})}
				</Input.Group>
			)}
			{(rule.options.freq === RRule.MONTHLY ||
				rule.options.freq === RRule.YEARLY) && (
				<Fragment>
					<LargeScreen>
						<Row
							align="center"
							autoColumns={
								rule.options.freq === RRule.MONTHLY
									? 'max-content 84px'
									: 'max-content max-content 84px'
							}>
							{onDayInputs}
						</Row>
					</LargeScreen>
					<SmallScreen>
						<Column>{onDayInputs}</Column>
					</SmallScreen>
					<LargeScreen>
						<Row autoColumns="max-content" align="center">
							{nthOccurrenceInputs}
						</Row>
					</LargeScreen>
					<SmallScreen>
						<Column>{nthOccurrenceInputs}</Column>
					</SmallScreen>
				</Fragment>
			)}
		</Column>
	);
};

export default CustomRecurringOptions;
