import { JSX, useState } from 'react';

import Slider from 'components/form/Slider';

const FormSlider = (): JSX.Element => {
	const [value, setValue] = useState<number>(0);

	const handleChange = ({ target }: { target: HTMLInputElement }) =>
		setValue(Number.parseInt(target.value));

	return (
		<Slider
			type="range"
			value={value}
			min={0}
			max={100}
			step={0.01}
			onChange={handleChange}
		/>
	);
};

export default FormSlider;
