import spacing from 'pkg/config/spacing';
import { palette } from 'pkg/config/styles';

import '@adyen/adyen-platform-experience-web/adyen-platform-experience-web.css';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useAdyenReportingComponents } from 'pkg/hooks/adyen/useReportingComponents';

import { useReportsNavigation } from 'routes/payments/reports';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import { useTemplateContext } from 'components/layout/page-templates/context';

import * as css from './styles.css';

interface AdyenPayoutsProps {
	organizationId: number;
}

export default function AdyenPayouts({ organizationId }: AdyenPayoutsProps) {
	const { setPageBackgroundColor } = useTemplateContext();

	useReportsNavigation({ organizationId });

	const {
		isLoading,
		PayoutsComponent,
		ReportsComponent,
		TransactionComponent,
	} = useAdyenReportingComponents({
		organizationId,
	});

	useComponentDidMount(() => {
		setPageBackgroundColor(palette.white);
	});

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<div className={css.wrapper}>
			<Column spacing={spacing._5}>
				{PayoutsComponent}
				{ReportsComponent}
				{TransactionComponent}
			</Column>
		</div>
	);
}
