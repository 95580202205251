import {
	ContextAttributes,
	PrefabProvider,
	usePrefab,
} from '@prefab-cloud/prefab-cloud-react';
import { Fragment, ReactNode } from 'react';

import { tlog } from 'pkg/tlog';
import {
	useCurrentAccount,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import { isAndroid, isIOS, isWeb } from 'pkg/platform';
import * as models from 'pkg/api/models';
import { isDev } from 'pkg/flags';

import { Spinner } from 'components/loaders/spinner';

function FlagProviderChecker({
	children,
}: {
	children: ReactNode;
}): JSX.Element {
	const prefab = usePrefab();

	if (prefab.loading) {
		return <Spinner center />;
	}

	return <Fragment>{children}</Fragment>;
}

export function FlagProvider({
	children,
}: {
	context?: ContextAttributes;
	children: ReactNode;
}): JSX.Element {
	const acc = useCurrentAccount();
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();

	const context: ContextAttributes = {
		user: {
			key: acc.trackingHash,
		},
		env: {
			web: isWeb(),
			ios: isIOS(),
			android: isAndroid(),
		},
	};

	if (isDev()) {
		context.user.email = acc.email;
	}

	if (org) {
		context.organization = {
			key: org.id,
			name: org.name,
		};
	}
	if (membership) {
		context.membership = {
			group_id: membership.groupId,
			role: models.membership.roleToString(membership.role),
			isParent: models.membership.isParent(membership),
		};
	}

	return (
		<PrefabProvider
			apiKey={window.TS.prefabKey}
			contextAttributes={context}
			pollInterval={60000}
			onError={(error) => tlog.error(error.message, { err: error })}>
			<FlagProviderChecker>{children}</FlagProviderChecker>
		</PrefabProvider>
	);
}
