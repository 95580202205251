import { JSX, InputHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

import Icon from 'components/icon';

import FieldStyles from './FieldStyles';

const ErrorIcon = styled(Icon)`
	color: var(--palette-red-400);
	position: absolute;
	top: 50%;
	right: 12px;
	transform: translateY(-50%);
	height: 20px;
	width: 20px;
	z-index: -1;
`;

const Wrapper = styled.div`
	width: 100%;
	position: relative;
	display: flex;
`;

interface TextFieldProps {
	hasError?: boolean;
	small?: boolean;
}

const TextField = styled.input<TextFieldProps>`
	${FieldStyles};
	min-width: 0;
`;

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
	name?: string;
	className?: string;
	hasError?: boolean;
	small?: boolean;
}

const TextInput = forwardRef<JSX.Element, TextInputProps>(
	({ name, className, hasError, small, ...rest }: TextInputProps, ref: any) => {
		return (
			<Wrapper className={className}>
				<TextField
					name={name}
					hasError={hasError}
					small={small}
					ref={ref}
					{...rest}
				/>
				<ErrorIcon name="error" />
			</Wrapper>
		);
	}
);

// @deprecated
export default styled(TextInput)``;
