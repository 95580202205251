import { JSX } from 'react';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import DateTime from 'pkg/datetime';
import { useCurrentGroup } from 'pkg/identity';
import useDateFilter, {
	DateQueryFilters,
	Dates,
} from 'pkg/hooks/use-date-filter';
import { QueryObject, useQueryState } from 'pkg/hooks/query-state';
import { eventTypeLabels } from 'pkg/api/models/event';
import { useFilters } from 'pkg/filters/use_filters';
import { createFilterGroups, FilterOperator } from 'pkg/filters';

import { getFilters } from 'routes/group/report/shared/Filters';
import UserReportRow from 'routes/group/report/user/UserReportRow';
import Summary from 'routes/group/report/user/Summary';

import AssetImage from 'components/AssetImage';

import * as ActionBar from 'components/layout/ActionBar';
import { Table as TableContentWrapper } from 'components/layout/side-bar-page';
import { GroupSelectModal } from 'components/group/select_modal';
import { FullScreenTableContextProvider } from 'components/layout/page-templates';

import * as Table from 'design/table';

import * as css from './styles.css';

interface OrganizationUserProfileAttendanceProps {
	user: models.user.User;
	query: QueryObject;
}

export default function OrganizationUserProfileAttendance({
	user,
	query,
}: OrganizationUserProfileAttendanceProps): JSX.Element {
	const t = useT();
	const qs = useQueryState();
	const currentGroup = useCurrentGroup();
	const currentSeason = models.group.getCurrentSeason(currentGroup);
	const filters = getFilters(query);

	const now = new DateTime(new Date());

	const dateRange: Dates = {
		startOfRange: currentSeason.start,
		endOfRange: now.getUnixTimestamp(),
	};

	if (query?.startOfRange) {
		dateRange.startOfRange = Number.parseInt(query.startOfRange as string, 10);
	}

	if (query?.endOfRange) {
		dateRange.endOfRange = Number.parseInt(query.endOfRange as string, 10);
	}

	const setUrl = (params: DateQueryFilters) => {
		qs.setAll({
			dateLabel: params.dateLabel,
			endOfRange: params.endOfRange,
			startOfRange: params.startOfRange,
		});
		qs.commit();
	};

	const { DateFilter } = useDateFilter({
		filters: {
			startOfRange: dateRange.startOfRange,
			endOfRange: dateRange.endOfRange,
			dateLabel: query?.dateLabel as string,
		},
		setUrl,
		customDatePresets: [
			{
				default: !query?.dateLabel ? true : false,
				name: t('Season'),
				startOfRangeDate: currentSeason.start,
			},
		],
	});

	const eventTypeFilterValues: { [key: string]: string } = {};

	eventTypeLabels.forEach((label) => {
		eventTypeFilterValues[models.event.getTypeTranslation(label)] = label;
	});

	const filterGroups = createFilterGroups({
		[t('Groups')]: {
			filters: {
				['Groups']: {
					type: 'modal',
					property: 'groupIds',
					operator: FilterOperator.Includes,
					settings: {
						modal: <GroupSelectModal />,
						buttonLabel: t('Select groups'),
						hideButton: false,
					},
				},
			},
		},
		[t('Event types')]: {
			filters: {
				['Event types']: {
					type: 'checkbox',
					property: 'eventTypes',
					operator: FilterOperator.Includes,
					values: eventTypeFilterValues,
				},
			},
		},
	});

	const { Component, currentFilters } = useFilters({
		groups: filterGroups,
	});

	const groupIds =
		currentFilters?.find((f) => f.property === 'groupIds')?.values || [];

	const eventTypes =
		currentFilters?.find((f) => f.property === 'eventTypes')?.values || [];

	const { records, isLoading } =
		useCollection<models.eventReport.UserDetailReport>(
			endpoints.EventReport.UserDetailReport1(user.id),
			{
				queryParams: new URLSearchParams({
					startsAt: dateRange.startOfRange.toString(),
					endsAt: dateRange.endOfRange.toString(),
					groupIds: groupIds.toString(),
					eventTypes: eventTypes.toString(),
				}),
			}
		);

	const search = (query?.search as string) || '';

	const searchedRecords = records.filter((r) =>
		r.eventTitle.toLocaleLowerCase().includes(search.toLocaleLowerCase())
	);

	const columns = [
		{
			content: t('Event title'),
		},
		{
			content: t('Event Type'),
			width: 'max-content',
		},
		{
			content: t('Date'),
			align: 'right',
			width: 'max-content',
		},
		{
			content: t('Time'),
			align: 'right',
			width: 'max-content',
		},
		{
			content: t('Status'),
			width: 'max-content',
		},
		{
			content: t('Comment'),
		},
	];

	const emptyState = {
		title: t('No reports found'),
		image: <AssetImage src="img/missing-entities/post.svg" />,
	};

	return (
		<FullScreenTableContextProvider>
			<TableContentWrapper className={css.tableContentWrapper}>
				<ActionBar.FilterBar transparent>
					<ActionBar.PrimaryAction>
						<ActionBar.Search
							value={search}
							placeholder={t('Search content')}
						/>
					</ActionBar.PrimaryAction>
					{DateFilter}
					{Component}
				</ActionBar.FilterBar>
				{!isLoading && (
					<div className={css.summaryWrapper}>
						<Summary reports={records} filters={filters} />
					</div>
				)}
				<Table.Table
					columns={columns}
					emptyState={emptyState}
					isLoading={isLoading}>
					{searchedRecords.map((r) => (
						<UserReportRow key={r.id} report={r} />
					))}
				</Table.Table>
			</TableContentWrapper>
		</FullScreenTableContextProvider>
	);
}
