import { JSX, ReactNode, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';

interface CellProps {
	align?: 'left' | 'center' | 'right';
	verticalAlign?: 'start' | 'center' | 'end';
	multiline?: boolean;
	span?: number;
	/** Value to use when summarizing all cells */
	value?: number;
	/** Slug is used when auto summarizing cells in table footer */
	slug?: string;
	className?: string;
	children?: ReactNode;
	emphasized?: boolean;
	onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
	border?: boolean;
}

const Wrapper = styled.td<CellProps>`
	text-align: left;
	justify-content: flex-start;
	position: relative;
	align-content: ${({ verticalAlign }) => verticalAlign || 'center'};
	align-items: ${({ verticalAlign }) => verticalAlign || 'center'};
	overflow: hidden;
	text-overflow: ellipsis;

	${({ multiline }) =>
		!multiline &&
		css`
			white-space: nowrap;
		`}

	${({ align }) =>
		align &&
		align === 'right' &&
		css`
			text-align: ${align};
			justify-content: flex-end;
		`};

	${({ align }) =>
		align &&
		align === 'center' &&
		css`
			text-align: ${align};
			justify-content: center;
		`};

	${({ onClick }) =>
		onClick &&
		css`
			&:hover {
				cursor: pointer;
			}
		`};

	${({ emphasized }) =>
		emphasized &&
		css`
			font-weight: var(--font-weight-semibold);
		`}

	${({ border }) =>
		border &&
		css`
			border-right: 1px solid var(--palette-gray-300);

			&:last-of-type {
				border-right: none;
			}
		`}
`;

const CellContent = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

function TD({
	align,
	verticalAlign,
	value,
	slug,
	span,
	className,
	multiline,
	children,
	onClick,
	emphasized,
	border,
}: CellProps): JSX.Element {
	return (
		<Wrapper
			align={align}
			verticalAlign={verticalAlign}
			className={className}
			onClick={onClick}
			multiline={multiline}
			colSpan={span}
			data-cell-slug={slug}
			data-cell-value={value}
			emphasized={emphasized}
			border={border}>
			<CellContent>{children}</CellContent>
		</Wrapper>
	);
}

export default styled(TD)<CellProps>``;
