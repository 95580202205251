import { Fragment, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import { Filters } from 'pkg/filters/use_filters';
import { FilterOperator } from 'pkg/filters';
import * as routes from 'pkg/router/routes';
import { crash } from 'pkg/errors/errors';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import useMixedState from 'pkg/hooks/useMixedState';
import DateTime, { Granularity } from 'pkg/datetime';
import { TransactionExportTypes } from 'pkg/api/models/order';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import { Flag, useFlag } from 'pkg/flags';

import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import * as ActionBar from 'components/layout/ActionBar';
import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface OrdersActionBarProps {
	filters: Filters;
	selectedItems: number[];
	showSelected: boolean;
	setShowSelected: Dispatch<SetStateAction<boolean>>;
	disableNewInvoice?: boolean;
	hideExport?: boolean;
	hideNewInvoice?: boolean;
	filterParams: URLSearchParams;

	onUpdateRecords: (orders: models.order.Order[]) => void;
	refresh: () => void;

	orders: models.order.Order[];
	selectedRecords: models.order.Order[];

	search?: string;
	formId?: number;
}

export default function OrdersActionBar({
	orders,
	selectedRecords,
	filters,
	selectedItems,
	showSelected,
	setShowSelected,
	disableNewInvoice,
	formId,
	hideExport,
	hideNewInvoice,
	filterParams,
	onUpdateRecords,
	refresh,
}: OrdersActionBarProps) {
	const org = useCurrentOrganization();
	const providerSettings = usePaymentProviderContext();
	const orderAllowedActionsEnabled = useFlag<boolean>(Flag.OrderAllowedActions);
	const orderActions = orders
		.filter((o) => selectedItems.includes(o.id))
		.map((order) => {
			if (orderAllowedActionsEnabled) {
				return order.allowedActions;
			} else {
				return models.order.getActions(order, providerSettings.settings);
			}
		});

	const canUseAction = (action: string) =>
		orderActions.some((orderAction) => orderAction.includes(action));

	const canUseAllowedAction = (action: models.Action) =>
		orderActions.some((oa) => oa.includes(action));

	const nowDate = new Date();
	const nowDateTime = new DateTime(nowDate);
	const fromStartDate = Math.ceil(
		nowDateTime.prev(Granularity.month, 1).startOfDay / 1000
	);

	const [transactionExportSettings, setTransactionExportSettings] =
		useMixedState<{
			from: number;
			to: number;
			exportType: TransactionExportTypes;
		}>({
			from: fromStartDate,
			to: nowDateTime.getUnixTimestamp(),
			exportType: TransactionExportTypes.PaidBased,
		});

	const handleTransactionTypeChange = (
		event: ChangeEvent<HTMLSelectElement>
	) => {
		setTransactionExportSettings({
			exportType: event.target.value as TransactionExportTypes,
		});
	};

	const handleFromDateChange = (date: Date) => {
		const from = new DateTime(date).getUnixTimestamp();

		if (from > transactionExportSettings.to) {
			setTransactionExportSettings({
				from: transactionExportSettings.to,
				to: from,
			});
		} else {
			setTransactionExportSettings({
				from,
			});
		}
	};

	const handleToDateChange = (date: Date) => {
		const to = new DateTime(date).getUnixTimestamp();

		if (to < transactionExportSettings.from) {
			setTransactionExportSettings({
				from: to,
				to: transactionExportSettings.from,
			});
		} else {
			setTransactionExportSettings({
				to,
			});
		}
	};

	let canVoid = canUseAction('void');
	let canRemind = canUseAction('reminder');
	let canSendReceipt = canUseAction('receipt');
	let canDelete = canUseAction('delete');

	if (orderAllowedActionsEnabled) {
		canVoid = canUseAllowedAction(models.Action.OrderMarkAsVoid);
		canRemind = canUseAllowedAction(models.Action.OrderSendReminder);
		canSendReceipt = canUseAllowedAction(models.Action.OrderSendReceipt);
		canDelete = canUseAllowedAction(models.Action.OrderRemove);
	}

	const handleVoid = async () => {
		const resp = await actions.order.markAs(
			endpoints.Orders.MarkVoid(),
			selectedItems,
			t('Invoice successfully voided.')
		);

		if (resp) {
			selectedRecords.forEach((item) => {
				item.status = models.order.Statuses.Void;
			});

			onUpdateRecords(selectedRecords);
		}
	};

	const handleDelete = async () => {
		const resp = await actions.order.remove(selectedItems);

		if (resp) {
			refresh();
		}
	};

	const handleSendReceipt = () => actions.order.sendReceipt(selectedItems);

	const handleSendReminder = () => actions.order.sendReminder(selectedItems);

	const exportFields = models.order.getExportFields();
	const group = useCurrentGroup();

	const defaultExportState = {
		modal: '',
		selectedFields: exportFields.map((f) => f.key),
	};

	const [exportState, setExportState] = useMixedState(defaultExportState);

	const handleChangeSelectedExportFields = (field: string) => {
		if (exportState.selectedFields.includes(field)) {
			setExportState({
				selectedFields: exportState.selectedFields.filter((f) => f !== field),
			});
		} else {
			setExportState({
				selectedFields: [...exportState.selectedFields, field],
			});
		}
	};

	const handleToggleAllExportFields = () => {
		setExportState({
			selectedFields:
				exportState.selectedFields.length === exportFields.length
					? []
					: exportFields.map((f) => f.key),
		});
	};

	const openExportModal = () => {
		setExportState({ modal: 'export' });
	};

	const openTransactionExportModal = () =>
		setExportState({ modal: 'transactionExport' });

	const hideExportModal = () => {
		setExportState(defaultExportState);
	};

	const createGrossExport = async () => {
		const payload: { [key: string]: boolean } = {};
		exportState.selectedFields.forEach((f) => (payload[f] = true));

		const filters = new URLSearchParams(filterParams.toString());

		if (formId) {
			filters.set('form_id', formId.toString());
		}

		const [ok, csvData] = await models.order.exportOrders(
			Object.fromEntries(filters.entries()),
			payload
		);

		handleResult(ok, csvData);
	};

	const createTransactionExport = async () => {
		const filters = new URLSearchParams(filterParams.toString());

		if (formId) {
			filters.set('form_id', formId.toString());
		}

		const filtersObject = Object.fromEntries(filters.entries());

		const [ok, csvData] = await models.order.exportTransactionOrders(
			filtersObject,
			{
				from: Math.ceil(
					DateTime.fromTimestamp(transactionExportSettings.from).startOfDay /
						1000
				),
				to: Math.floor(
					DateTime.fromTimestamp(transactionExportSettings.to).endOfDay / 1000
				),
				exportType: Number.parseInt(transactionExportSettings.exportType, 10),
			}
		);

		handleResult(ok, csvData);
	};

	const createItemizedExport = async () => {
		const filters = new URLSearchParams(filterParams.toString());

		if (formId) {
			filters.set('form_id', formId.toString());
		}

		const [ok, csvData] = await models.order.exportItemizedOrders(
			Object.fromEntries(filters.entries())
		);

		handleResult(ok, csvData);
	};

	const handleResult = (ok: boolean, csvData: string) => {
		if (ok) {
			const fileName = `${group.name}-invoices.csv`;

			const url = URL.createObjectURL(new Blob([csvData]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				pageActionIcon="more_horiz"
				filters={filters}
				searchFilter={{
					property: 'search',
					operator: FilterOperator.Contains,
					type: 'text',
				}}
				actions={[
					!hideExport && {
						icon: 'download',
						label: t('Export'),
						contextMenuItems: [
							<ContextMenu.Item key="gross" onClick={openExportModal}>
								<ContextMenu.ItemIcon name="download" />
								{t('Gross invoice export')}
							</ContextMenu.Item>,
							<ContextMenu.Item key="itemized" onClick={createItemizedExport}>
								<ContextMenu.ItemIcon name="download" />
								{t('Itemized invoice export')}
							</ContextMenu.Item>,
							<ContextMenu.Item
								key="transaction"
								onClick={openTransactionExportModal}>
								<ContextMenu.ItemIcon name="download" />
								{t('Transaction export')}
							</ContextMenu.Item>,
						],
					},
					!hideNewInvoice &&
						!disableNewInvoice && {
							type: 'primary',
							label: t('New order'),
							icon: 'add',
							href: routes.Invoice.New(org.id),
						},
				]}
			/>
			<ActionBar.BulkActions
				numSelected={selectedItems.length}
				showSelected={showSelected}
				setShowSelected={setShowSelected}
				actions={[
					<ContextMenu.Item
						onClick={handleSendReminder}
						disabled={!canRemind}
						tooltip={
							!canRemind && t(`Can't send reminder to voided or paid invoices.`)
						}>
						<ContextMenu.ItemIcon name="draft" />
						{t('Send reminder')}
					</ContextMenu.Item>,
					<ContextMenu.Item
						onClick={handleSendReceipt}
						disabled={!canSendReceipt}
						tooltip={
							!canSendReceipt && t('Can only send receipts to paid invoices.')
						}>
						<ContextMenu.ItemIcon name="draft" />
						{t('Send receipt')}
					</ContextMenu.Item>,
					<ContextMenu.ConfirmItem
						disabled={!canVoid}
						caution
						tooltip={!canVoid && t(`Can't void paid invoices.`)}
						message={t('Are you sure you want to void these invoices?')}
						confirmLabel={t('Void')}
						onConfirm={handleVoid}>
						<ContextMenu.ItemIcon name="block" />
						{t('Void')}
					</ContextMenu.ConfirmItem>,
					<ContextMenu.ConfirmItem
						disabled={!canDelete}
						caution
						tooltip={
							!canDelete &&
							t('Some of the selected invoices cannot be deleted.')
						}
						message={t('Are you sure you want to void these invoices?')}
						onConfirm={handleDelete}>
						<ContextMenu.ItemIcon name="block" />
						{t('Delete')}
					</ContextMenu.ConfirmItem>,
				]}
			/>

			{exportState.modal === 'transactionExport' && (
				<StepModal.Base onClose={hideExportModal}>
					<StepModal.Step
						title={t('Transaction export')}
						nextLabel={t('Export')}
						onNext={createTransactionExport}>
						<Column>
							<Row columns="1fr auto 1fr" align="end">
								<Input.Group label={t('From')}>
									<div>
										<Input.DateTimePicker
											date={new Date(transactionExportSettings.from * 1000)}
											hideTimeInput
											onDateChange={handleFromDateChange}
										/>
									</div>
								</Input.Group>
								<Icon
									name="arrow-right"
									size={1.6}
									actualSize
									className={css.arrowIcon}
								/>
								<Input.Group label={t('To')}>
									<div>
										<Input.DateTimePicker
											date={new Date(transactionExportSettings.to * 1000)}
											hideTimeInput
											onDateChange={handleToDateChange}
										/>
									</div>
								</Input.Group>
							</Row>

							<Input.Group label={t('Select export type')}>
								<Input.Select
									onChange={handleTransactionTypeChange}
									value={transactionExportSettings.exportType}>
									<option value={TransactionExportTypes.PaidBased}>
										{t('Transactions')}
									</option>
									<option value={TransactionExportTypes.SentBased}>
										{t('Sent invoices')}
									</option>
								</Input.Select>
							</Input.Group>
							<InfoBox
								text={
									transactionExportSettings.exportType ===
									TransactionExportTypes.PaidBased
										? t(
												'All invoices with a payment date in the selected date span will be exported.'
											)
										: t(
												'All invoices that have been sent in the selected date span will be exported.'
											)
								}
							/>
						</Column>
					</StepModal.Step>
				</StepModal.Base>
			)}

			{exportState.modal === 'export' && (
				<StepModal.Base onClose={hideExportModal}>
					<StepModal.Step
						title={t('Select fields')}
						onNext={createGrossExport}
						skipBody>
						<Table.Table
							columns={[
								{
									content: (
										<Row autoColumns="max-content" align="center">
											<Input.Control
												type="checkbox"
												standalone
												checked={
													exportState.selectedFields.length ===
													exportFields.length
												}
												onChange={handleToggleAllExportFields}
											/>
											{t('Select all')}
										</Row>
									),
								},
							]}>
							{exportFields.map((f, i) => (
								<Table.Row
									key={i}
									onClick={() => handleChangeSelectedExportFields(f.key)}>
									<Table.Cell>
										<Row autoColumns="max-content" align="center">
											<Input.Control
												type="checkbox"
												standalone
												checked={exportState.selectedFields.includes(f.key)}
											/>
											<Fragment>{f.label}</Fragment>
										</Row>
									</Table.Cell>
								</Table.Row>
							))}
						</Table.Table>
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);
}
