import { useEffect, useRef } from 'react';
import '@adyen/kyc-components/styles.css';
import AdyenKyc from '@adyen/kyc-components';
import { CountryCode } from '@adyen/kyc-components/dist/types/core/models/country-code';

import * as actions from 'pkg/actions';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import { InitTransferInstrumentResponse } from 'pkg/api/payment_providers/adyen';
import { getTransferInstrumentLocale } from 'pkg/adyen/utils';

interface Props {
	organizationId: number;
	countryCode: string;
	paymentConfigId: number;
}

export default function useAdyenTransferInstrument({
	organizationId,
	countryCode,
	paymentConfigId,
}: Props) {
	const manageTransferInstrumentRef = useRef(null);

	const { record, isLoading } = useEndpoint<InitTransferInstrumentResponse>(
		endpoints.Adyen.InitiateManageTransferInstruments(
			organizationId,
			paymentConfigId
		),
		{
			postRequest: true,
		}
	);

	const getSdkToken = async () => {
		const [ok, resp] = await actions.adyen.initTransferInstrument(
			organizationId,
			paymentConfigId
		);

		if (ok) {
			return { token: resp.sessionToken };
		}
	};

	let adyenKycHandler = null;

	if (!isLoading) {
		adyenKycHandler = new AdyenKyc({
			locale: getTransferInstrumentLocale(),
			country: countryCode as CountryCode,
			environment: 'https://test.adyen.com',
			sdkToken: record.sessionToken,
			getSdkToken,
		});
	}

	useEffect(() => {
		if (manageTransferInstrumentRef.current !== null && !isLoading) {
			adyenKycHandler
				.create('manageTransferInstrumentComponent', {
					legalEntityId: record.legalEntityId,
					onAdd: (legalEntityId: string) => {
						console.log(legalEntityId);
						actions.adyen.getOnboardingLink(organizationId, paymentConfigId);
					},
					onEdit: (transferInstrumentId: string, legalEntityId: string) => {
						console.log(transferInstrumentId, legalEntityId);
						actions.adyen.getOnboardingLink(organizationId, paymentConfigId);
					},
				})
				.mount(manageTransferInstrumentRef.current); // Mount to the container you created
		}
	}, [manageTransferInstrumentRef.current, isLoading]);

	return {
		isLoading,
		ManageTransferInstrument: <div ref={manageTransferInstrumentRef}></div>,
	};
}
