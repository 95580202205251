import { JSX, ReactNode } from 'react';

import * as styles from './forms/styles.css';

interface LabelProps {
	children: ReactNode | ReactNode;
}

export default function Label({ children }: LabelProps): JSX.Element {
	return <label className={styles.label}>{children}</label>;
}
