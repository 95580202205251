import { JSX, memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { getLastMatches } from 'pkg/selectors/matches.selector';

import { fetchMatches } from 'pkg/actions/matches_deprecated';

import * as routes from 'pkg/router/routes';
import Link from 'pkg/router/Link';
import DateTime from 'pkg/datetime';
import rgba from 'pkg/rgba';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

import CardWrapper from 'routes/dashboard/components/Card';

import Result from 'containers/match/Result';

import AssetImage from 'components/AssetImage';
import { ScrollableWrapper } from 'components/scrollable-wrapper';
import SectionTitle from 'components/SectionTitle';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import Column from 'components/layout/column';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

const MatchWrapper = styled(CardWrapper)`
	scroll-snap-align: center;
	margin-right: var(--spacing-4);
	flex: 0 0 90%;
	width: 90%;
	min-width: 0;

	@media ${styles.breakpoint.large}, ${styles.breakpoint.nolimit} {
		flex: 0 0 320px;
		width: 320px;
	}

	&:only-child {
		flex: 0 0 100%;
		width: 100%;
	}

	&:last-child {
		margin-right: 0;
	}
`;

const MatchResultHeader = styled.div`
	text-align: center;
	color: var(--palette-gray-500);
	padding: 0.8rem 1rem;
	border-bottom: 1px solid ${rgba(styles.palette.card.innerDividerColor)};
	font-size: var(--font-size-sm);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: var(--font-weight-normal);
`;

interface LastGamesResultsProps {
	groupId: number;
}
const LastGamesResults = memo(
	({ groupId }: LastGamesResultsProps): JSX.Element => {
		const org = useCurrentOrganization();
		const dispatch = useDispatch();

		const activeMembership = useCurrentMembership();
		const matches = useSelector((state) =>
			getLastMatches(state, {
				groupId,
				numberOfMatches: 5,
			})
		);

		const [fetchingMatches, setFetchingMatches] = useState(true);

		useEffect(() => {
			(async () => {
				await dispatch(
					fetchMatches(
						{
							group_id: groupId,
						},
						'',
						false
					)
				);

				setFetchingMatches(false);
			})();
		}, [dispatch, groupId]);

		const renderMatches = () => {
			if (fetchingMatches) {
				return (
					<ScrollableWrapper chevrons>
						<Skeleton
							placeholderType={PlaceholderType.DashboardLastGames}
							numItems={3}
						/>
					</ScrollableWrapper>
				);
			}

			if (matches.size === 0) {
				if (models.membership.isAdminOrStaff(activeMembership)) {
					const image = (
						<AssetImage src="img/missing-entities/match-statistics.svg" />
					);

					return (
						<MissingEntities
							noBorder
							dashboard
							title={t(`See your match statistics`)}
							description={t(
								`Track your recent matches and stats at a glance! Add a match and statistics to get started.`
							)}
							image={image}
							helpUrl="https://help.360player.com/category/10-statistics"
						/>
					);
				}

				const image = (
					<AssetImage src="img/missing-entities/match-statistics.svg" />
				);

				return (
					<MissingEntities
						noBorder
						dashboard
						title={t(`No matches added!`)}
						description={t(`See all of your recent matches at a glance.`)}
						image={image}
					/>
				);
			}

			return (
				<ScrollableWrapper chevrons>
					{matches.toList().map((match: any) => {
						const matchDate = new DateTime(
							new Date(match.startsAt * 1000)
						).toLocaleDateString({
							weekday: 'short',
							month: 'short',
							day: 'numeric',
						});

						const canEdit =
							match.canEdit() &&
							(match.goalCount === null || match.opponentGoalCount === null);

						let url = routes.Match.View(
							org.id,
							match.eventId,
							match.id,
							'summary'
						);

						if (canEdit) {
							url = routes.Match.Edit(
								org.id,
								match.eventId,
								match.id,
								'result'
							);
						}

						return (
							<MatchWrapper noBorder key={match.id} as={Link} href={url}>
								<MatchResultHeader>
									{matchDate} - {match.location}
								</MatchResultHeader>

								<Result matchId={match.id} noLink slim />
							</MatchWrapper>
						);
					})}
				</ScrollableWrapper>
			);
		};

		return (
			<Column>
				<SectionTitle icon="emoji_events">
					{t('Last games')}

					{matches.size > 0 && (
						<Button
							inline
							iconPosition="right"
							icon="chevron_right"
							noPadding
							href={routes.Statistics.Match(org.id, groupId)}
							label={t('View all')}
						/>
					)}
				</SectionTitle>

				{renderMatches()}
			</Column>
		);
	}
);

export default LastGamesResults;
