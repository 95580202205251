import styled, { css, DefaultTheme } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import * as iconStyles from 'components/icon/styles.css';

export interface PrefixProps {
	transparent?: boolean;
	inline?: boolean;
	interactable?: boolean;
	theme?: DefaultTheme;
	align?: 'start' | 'center' | 'end';
	flow?: 'column' | 'row';
	gap?: string;
	noSpacing?: boolean;
}

export const Prefix = styled.div<PrefixProps>`
	display: grid;
	grid-auto-flow: ${({ flow }) => flow ?? 'column'};
	align-items: ${({ align }) => align ?? 'center'};
	gap: ${({ gap }) => gap ?? styles.spacing._3};
	justify-content: center;
	cursor: default;
	color: var(--palette-gray-600);
	order: 0;
	min-width: auto;

	select {
		border: none !important;
		box-shadow: none !important;
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			background: var(--palette-gray-700);
		`}

	${({ transparent }) =>
		transparent &&
		css`
			background: none;
		`}

	${({ inline }) =>
		inline &&
		css`
			background: none;
			border: none !important;
		`}

	${({ interactable }) =>
		interactable &&
		css`
			cursor: pointer;

			@media (hover: hover) {
				&:hover {
					color: var(--palette-blue-500);
				}
			}

			&,
			&[data-active='true'] {
				color: var(--palette-gray-500);
			}
		`}

	${({ interactable, theme }) =>
		interactable &&
		theme.darkMode &&
		css`
			@media (hover: hover) {
				&:hover {
					background: var(--palette-gray-700);
					color: var(--palette-white);
				}
			}

			&,
			&[data-active='true'] {
				background: var(--palette-gray-800);
				color: var(--palette-white);
			}
		`}

	${({ noSpacing }) =>
		noSpacing &&
		css`
			padding: 0 !important;
		`}
`;

export const Suffix = styled(Prefix)`
	order: 2;

	${({ inline }) =>
		inline &&
		css`
			~ input {
				padding-right: 0px !important;
				padding-left: var(--spacing-4) !important;
			}
		`}
`;

export const ColorPickerWrapper = styled.label`
	padding: 6px var(--spacing-4);
	background-color: currentColor;
	font-size: var(--font-size-xs);
	text-transform: uppercase;
	border-radius: var(--radius-2);
	position: relative;
	cursor: pointer;
	box-shadow:
		inset 0 0 0 1px black,
		inset 0 0 0 2px hsla(0, 0%, 100%, 0.5);

	svg {
		margin-left: 5px;
		display: inline-block;
	}

	input {
		opacity: 0;
		height: 0;
		width: 0;
		position: absolute;
	}
`;

export interface WrapperProps {
	rounded?: boolean;
	small?: boolean;
	filled?: boolean;
	large?: boolean;
	groupCode?: boolean;
	transparent?: boolean;
	theme?: DefaultTheme;
	active?: boolean;
	hidden?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
	position: relative;
	display: flex;
	border-radius: var(--radius-3);
	appearance: none;
	padding: 0;
	height: 40px;
	width: 100%;
	font-size: var(--font-size-base);
	background-color: var(--palette-white);
	border: 1px solid var(--palette-gray-400);
	transition:
		box-shadow 150ms ease-in-out,
		border-color 150ms ease-in-out;

	--ring-offset-width: 2px;
	--ring-offset-color: var(--palette-white);
	--ring-color: var(--palette-blue-500);
	--ring-offset-shadow: 0 0 0 var(--ring-offset-width) var(--ring-offset-color);
	--ring-shadow: 0 0 0 calc(2px + var(--ring-offset-width)) var(--ring-color);

	input,
	textarea {
		font-weight: var(--font-weight-normal);
		font-size: var(--font-size-base);
		padding: 0 var(--spacing-4);
		transition: border-color 150ms ease-in-out;
	}

	> ${Prefix} {
		padding: 0 0 0 var(--spacing-3);
		margin-right: -4px;
		.${iconStyles.icon} {
			margin-top: -1px;
			font-size: var(--font-size-2xl);
		}
	}

	> ${Suffix} {
		padding: 0 var(--spacing-4);
		margin-right: 0;
		.${iconStyles.icon} {
			margin-right: -4px;
			margin-top: -1px;
			font-size: var(--font-size-2xl);
		}
	}

	input {
		height: 38px;
		line-height: 38px;
	}

	${({ hidden }) =>
		hidden &&
		css`
			display: none;
		`}

	${({ groupCode }) =>
		groupCode &&
		css`
			height: 50px;

			input {
				text-align: center;
				height: 50px;
				line-height: 50px;
				font-weight: var(--font-weight-semibold);
				font-size: var(--font-size-xl);
			}
		`}

	input,
	textarea {
		order: 1;
	}

	&[data-disabled],
	input[disabled],
	textarea[disabled] {
		cursor: not-allowed;
	}

	@media (hover: hover) {
		&:hover {
			border-color: var(--palette-gray-500);
			box-shadow: var(--palette-gray-300) 0 0 0 4px;
		}
	}

	> ${Prefix} {
		justify-self: start;
		border-radius: 5px 0 0 5px;
	}

	> ${Suffix} {
		justify-self: end;
		border-radius: 0 5px 5px 0;
		border-right: none;
		white-space: nowrap;
	}

	&:focus-within {
		box-shadow: var(--ring-offset-shadow), var(--ring-shadow);
		border: 1px solid var(--palette-gray-300);

		> ${Prefix}, > ${Suffix} {
			border-color: var(--palette-blue-500);
		}
	}

	&:active {
		border-color: var(--palette-blue-500);
		box-shadow: none;

		> ${Prefix}, > ${Suffix} {
			border-color: var(--palette-blue-500);
		}
	}

	&:invalid {
		border-color: var(--palette-red-500);
		box-shadow: var(--palette-red-300) 0 0 0 4px;

		> ${Prefix}, > ${Suffix} {
			border-color: var(--palette-red-500);
		}
	}

	${({ active }) =>
		active &&
		css`
			border-color: var(--palette-blue-500);

			> ${Prefix}, > ${Suffix} {
				border-color: var(--palette-blue-500);
			}
		`}

	${({ rounded }) =>
		rounded &&
		css`
			border-radius: 1000px;

			${Prefix} {
				border-radius: 1000px 0 0 1000px;
			}

			${Suffix} {
				border-radius: 0 1000px 1000px 0;
			}
		`}

	${({ small }) =>
		small &&
		css`
			height: 35px;

			input,
			textarea {
				font-size: var(--font-size-sm);
				padding: 0 var(--spacing-4);
			}

			> ${Prefix} {
				padding: var(--spacing-3);
				margin-right: -12px;
				.${iconStyles.icon} {
					margin-top: -1px;
					font-size: var(--font-size-xl);
				}
			}

			> ${Suffix} {
				padding: 0 var(--spacing-4);
				font-size: var(--font-size-sm);
				margin-right: 0;
				.${iconStyles.icon} {
					margin-right: -4px;
					margin-top: -1px;
					font-size: var(--font-size-xl);
				}
			}

			input {
				height: 33px;
				line-height: 33px;
			}
		`}

		${({ filled }) =>
		filled &&
		css`
			background-color: var(--palette-gray-250);
			border-color: var(--palette-gray-250);
			color: var(--palette-gray-900);

			input,
			textarea {
				color: var(--palette-gray-900);

				&::placeholder {
					color: var(--palette-gray-600);
				}
			}

			> ${Prefix}, > ${Suffix} {
				border-color: var(--palette-gray-600);
			}

			@media (hover: hover) {
				&:hover {
					border-color: var(--palette-gray-400);
					box-shadow: var(--palette-gray-300) 0 0 0 4px;
				}
			}

			&:focus-within {
				box-shadow: var(--ring-offset-shadow), var(--ring-shadow);
				border: 1px solid var(--palette-gray-300);
				background-color: var(--palette-white);

				> ${Prefix}, > ${Suffix} {
					border-color: var(--palette-blue-400);
				}
			}

			&:active {
				border-color: var(--palette-blue-500);

				> ${Prefix}, > ${Suffix} {
					border-color: var(--palette-blue-500);
				}
			}

			&:invalid {
				border-color: var(--palette-red-500) 0 0 0 1px;
				box-shadow: var(--palette-red-800) 0 0 0 4px;

				> ${Prefix}, > ${Suffix} {
					border-color: var(--palette-red-500);
				}
			}
		`}

	${({ theme }) =>
		theme.darkMode &&
		css`
			background-color: var(--palette-gray-900);
			border-color: var(--palette-gray-600);
			box-shadow: ${rgba(styles.palette.black, 0.35)} 0 1px 3px;
			color: var(--palette-white);

			input,
			textarea {
				color: var(--palette-white);
			}

			> ${Prefix}, > ${Suffix} {
				border-color: var(--palette-gray-600);
			}

			@media (hover: hover) {
				&:hover {
					border-color: var(--palette-gray-500);
					box-shadow: var(--palette-gray-600) 0 0 0 4px;
				}
			}

			&:focus-within {
				border-color: var(--palette-blue-400);
				box-shadow: var(--palette-blue-700) 0 0 0 4px;

				> ${Prefix}, > ${Suffix} {
					border-color: var(--palette-blue-400);
				}
			}

			&:active {
				border-color: var(--palette-blue-500);

				> ${Prefix}, > ${Suffix} {
					border-color: var(--palette-blue-500);
				}
			}

			&:invalid {
				border-color: var(--palette-red-500) 0 0 0 1px;
				box-shadow: var(--palette-red-800) 0 0 0 4px;

				> ${Prefix}, > ${Suffix} {
					border-color: var(--palette-red-500);
				}
			}
		`}

		${({ transparent }) =>
		transparent &&
		css`
			background: none;

			${Prefix} {
				background: none;
			}

			${Suffix} {
				background: none;
			}
		`}



	&[data-textarea] {
		height: auto;

		input,
		textarea,
		> ${Prefix}, > ${Suffix} {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
`;

const FieldStyles = css`
	background: transparent;
	appearance: none;
	resize: none;
	border: none;
	line-height: 1;
	text-indent: none;
	width: 100%;

	&::-webkit-search-cancel-button,
	&::-webkit-search-decoration {
		appearance: none;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		border: none;
	}

	&[type='time']:invalid {
		color: ${rgba(styles.palette.gray[500], 0.3)};
	}

	&::placeholder {
		color: var(--palette-gray-500);
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			&::placeholder {
				color: var(--palette-gray-500);
			}
		`}
`;

export const Field = styled.input`
	${FieldStyles};

	[list]&::-webkit-calendar-picker-indicator {
		opacity: 0;
	}
`;

export const Area = styled.textarea`
	${FieldStyles};
	line-height: 20px;
`;

export const Group = styled.fieldset<{
	disabled?: boolean;
	readOnly?: boolean;
	hasError?: boolean;
	theme?: DefaultTheme;
	justify?: string;
}>`
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-3);
	border: none;
	padding: 0;
	margin: 0;
	justify-content: ${({ justify }) => justify || 'stretch'};

	${({ hasError }) =>
		hasError &&
		css`
			${Wrapper} {
				box-shadow:
					var(--palette-red-500) 0 0 0 1px,
					var(--palette-red-300) 0 0 0 4px;

				> ${Prefix}, > ${Suffix} {
					border-color: var(--palette-red-500);
				}
			}
		`}

	${({ disabled, readOnly }) =>
		(disabled || readOnly) &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
			pointer-events: none;
		`}

	> label {
		font-weight: var(--font-weight-semibold);
	}
`;

export type ControlColor =
	| 'gray'
	| 'red'
	| 'orange'
	| 'yellow'
	| 'green'
	| 'blue';

export const Control = styled.div<{
	checked?: boolean;
	rounded?: boolean;
	large?: boolean;
	theme?: DefaultTheme;
	color?: ControlColor;
}>`
	width: 18px;
	height: 18px;
	border-radius: var(--radius-3);
	position: relative;
	background-color: var(--palette-white);
	box-shadow: var(--palette-gray-400) 0 0 0 1px;
	transition:
		box-shadow 150ms ease-in-out,
		background-color 150ms ease-in-out;

	${({ theme }) =>
		theme.darkMode &&
		css`
			background-color: var(--palette-gray-800);
			box-shadow: var(--palette-gray-600) 0 0 0 1px;
		`}

	${({ rounded }) =>
		rounded &&
		css`
			border-radius: 1000px;
		`}

	${({ checked, color = 'blue' }) =>
		checked &&
		css`
			color: var(--palette-white);
			background: ${styles.palette[color][500]};
			box-shadow: ${styles.palette[color][500]} 0 0 0 1px;
		`}

	svg {
		color: currentColor;
		width: 14px;
		height: 14px;
		position: absolute;
		left: 2px;
		top: 2px;
	}

	${({ large }) =>
		large &&
		css`
			width: 24px;
			height: 24px;

			svg {
				left: 5px;
				top: 5px;
			}
		`}
`;

export const ControlGroup = styled.div<{
	position?: string;
	color?: ControlColor;
	standalone?: boolean;
	disabled?: boolean;
}>`
	padding: var(--spacing-1);
	display: grid;
	grid-template-columns: auto 1fr;
	gap: var(--spacing-4);
	align-items: start;
	cursor: pointer;
	border-radius: var(--radius-3);
	color: transparent;
	transition:
		background 150ms ease-in-out,
		box-shadow 150ms ease-in-out;

	> div {
		display: grid;
		grid-auto-flow: row;
	}

	@media (hover: hover) {
		&:hover {
			background: var(--palette-blue-100);
			box-shadow: var(--palette-blue-100) 0 0 0 4px;

			${Control}[data-active="false"] {
				box-shadow: var(--palette-gray-500) 0 0 0 1px;
			}
		}
	}

	&:active {
		box-shadow: var(--palette-blue-100) 0 0 0 2px;

		${Control} {
			&[data-checked='true'] {
				color: ${({ color }) => styles.palette[color || 'blue'][300]};
			}

			&[data-checked='false'] {
				color: transparent;
			}
		}
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			@media (hover: hover) {
				&:hover {
					background: var(--palette-gray-700);
					box-shadow: var(--palette-gray-700) 0 0 0 4px;
				}
			}

			&:active {
				box-shadow: var(--palette-gray-700) 0 0 0 2px;
			}
		`}

	${({ position }) =>
		position === 'after' &&
		css`
			grid-template-columns: 1fr auto;
		`}

	${({ standalone }) =>
		standalone &&
		css`
			display: inline-block;
			width: 22px;

			&,
			&:hover,
			&:active {
				background: none;
				box-shadow: none;
			}
		`}

	${({ disabled }) =>
		disabled &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
			pointer-events: none;
		`}

	input {
		display: none;
	}

	input:checked ~ ${Control} {
		color: var(--palette-white);
		background: var(--palette-blue-500);
		box-shadow: var(--palette-blue-500) 0 0 0 1px;
	}
`;

export const Label = styled.label`
	font-size: var(--font-size-base);
	line-height: var(--font-line-height-base);
	color: var(--palette-black);
	word-break: break-word;

	span {
		font-size: var(--font-size-sm);
		font-weight: var(--font-weight-normal);
		color: var(--palette-gray-500);
		margin-left: var(--spacing-3);
		display: inline-block;
	}

	${({ theme }) =>
		theme.darkMode &&
		css`
			color: var(--palette-white);
		`}
`;

export const Description = styled.span`
	font-size: var(--font-size-sm);
	line-height: var(--font-line-height-sm);
	color: var(--palette-gray-500);
`;

export const Hint = styled(Description)``;

export const Error = styled(Description)`
	margin-top: var(--spacing-2);
	color: var(--palette-red-400);
`;

export const DateInputsWrapper = styled.div<{ singleInput: boolean }>`
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: ${({ singleInput }) =>
		singleInput ? '1fr' : '1fr auto'};
	gap: var(--spacing-3);
	min-width: 0;

	@media ${styles.breakpoint.small} {
		grid-template-columns: 1fr max-content;
	}
`;

export const SearchContainer = styled.div`
	position: relative;
`;

export const ResultItem = styled.div`
	padding: var(--spacing-3) var(--spacing-5);
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			background-color: ${rgba(styles.palette.blue[400], 0.05)};
		}
	}
`;

export const SelectInputWrapper = styled.div`
	position: relative;
`;

export const SelectIconWrapper = styled.div`
	position: absolute;
	right: 7px;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	pointer-events: none;

	.${iconStyles.icon} {
		color: var(--palette-gray-500);
		font-size: 20px;

		&:first-child {
			transform: translateY(25%) rotate(-90deg);
		}

		&:last-child {
			transform: translateY(-25%) rotate(90deg);
		}
	}
`;

interface SelectInputProps {
	small?: boolean;
}

export const SelectInput = styled.select<SelectInputProps>`
	${FieldStyles};
	padding: 0 36px 0 var(--spacing-4);
	cursor: pointer;
	height: 38px;
	line-height: 38px;

	${({ theme }) =>
		theme.darkMode &&
		css`
			color: var(--palette-white);
		`}

	${({ small }) =>
		small &&
		css`
			/* parent-div  has 33px as height */
			font-size: var(--font-size-sm);
			height: 33px;
			line-height: 33px;
		`}
`;
