import { JSX } from 'react';
import { t } from '@transifex/native';
import styled, { css, DefaultTheme, keyframes } from 'styled-components';

import Icon from 'components/icon';

import Button from 'design/button';

const fadeInAnimation = keyframes`
	0% {
		opacity: 0;
		transform: scale(.95);
	}

	100% {
		opacity: 1;
	}
`;

const Wrapper = styled.div<{ fadeIn?: boolean; theme?: DefaultTheme }>`
	display: grid;
	align-items: center;
	justify-items: center;
	align-content: center;
	justify-content: center;
	grid-auto-flow: row;
	min-height: 30vh;

	gap: var(--spacing-8);
	color: var(--palette-gray-500);

	${({ theme }) =>
		theme.darkMode &&
		css`
			color: var(--palette-white);
		`}

	${({ fadeIn }) =>
		fadeIn &&
		css`
			width: 100%;
			// Can have 100vh because there's no UI on empty states in video analytics
			height: 100vh;
			opacity: 0;
			animation: ${fadeInAnimation} 250ms ease-in-out 1s;
			animation-fill-mode: forwards;
		`}
`;

const Title = styled.span`
	font-size: var(--font-size-lg);
`;

const Help = styled.div`
	padding: var(--spacing-4);
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-4);
	max-width: 300px;
	background: var(--palette-gray-800);
	border-radius: var(--radius-3);

	p {
		font-size: var(--font-size-sm);
	}
`;

const HelpTitle = styled.span`
	font-size: var(--font-size-base);
`;

interface EmptyStateProps {
	location?: 'playlist' | 'library';
	context?: 'group' | 'account';
	hideAction?: boolean;
	onAction?: () => void;
}

export default function EmptyState({
	location = 'playlist',
	hideAction,
	onAction,
}: EmptyStateProps): JSX.Element {
	const isPlaylist = location === 'playlist';

	const title = t(`Playlist is empty`);

	const help = isPlaylist && (
		<Help>
			<HelpTitle>{t(`How do I add clips?`)}</HelpTitle>
			<p>
				{t(
					`Choose a video from your Video Library, click on the three dots on a clip and click "Add to playlist".`
				)}
			</p>
		</Help>
	);

	const action = t(`Go to Video Library`);

	return (
		<Wrapper fadeIn={isPlaylist}>
			<Icon name="video-placeholder" size={3} />
			<Title>{title}</Title>
			{help}
			{!hideAction && <Button onClick={onAction}>{action}</Button>}
		</Wrapper>
	);
}
