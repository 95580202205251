import { JSX, useEffect, useState } from 'react';
import { List } from 'immutable';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Membership, { roleToString } from 'pkg/models/membership';
import User, { GroupsProps } from 'pkg/models/user';

import { UserPayload } from 'pkg/actions/services/users';

import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';
import store from 'pkg/store/createStore';
import * as actions from 'pkg/actions';
import useMixedState from 'pkg/hooks/useMixedState';
import { Record } from 'pkg/api/models/record';
import { UserFieldType } from 'pkg/api/models/user_fields';
import { Field } from 'pkg/api/models/user';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import UserGroupsSection from 'routes/payments/hooks/contact_form_sections/UserGroups';
import DetailsSection from 'routes/payments/hooks/contact_form_sections/DetailsSection';
import BillingUserSection from 'routes/payments/hooks/contact_form_sections/BillingUserSection';
import BillingAddressSection from 'routes/payments/hooks/contact_form_sections/BillingAddressSection';

import SelectContact from 'containers/payment_platform/SelectContact';
import AddToGroupsModal from 'containers/user/AddToGroupsModal';

import * as iconStyles from 'components/icon/styles.css';
import CreateModal from 'components/payment_platform/contacts/CreateModal';
import { FormPayload } from 'components/form/Form';

export const Selected = styled.div`
	p {
		font-weight: var(--font-weight-semibold);
	}

	div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: var(--spacing-4);
		margin: var(--spacing-2) 0;
		background-color: var(--palette-blue-100);
		color: var(--palette-blue-500);
		border-radius: var(--radius-3);
	}

	.${iconStyles.icon} {
		font-size: var(--font-size-xl);
	}
`;

export interface FormDataProps extends UserPayload {
	birthYear?: string;
	birthMonth?: string;
	birthDay?: string;
	profileImageUrl?: string;
}

// { key: string; value?: string; attachmentId?: string }[] is for attachments
type DetailsDataType =
	| string
	| number
	| { key: string; value?: string; attachmentId?: number }[];

export interface AlteredProps {
	add: GroupsProps[];
	delete: GroupsProps[];
	role: GroupsProps[];
}

interface ReturnProps {
	contactData: FormStateData;
	billingData: FormPayload | User;
	billingContactData: FormStateData;
	alteredGroups: AlteredProps;
	isEdited: boolean;
	DetailsSection: JSX.Element;
	UserGroupsSection: JSX.Element;
	BillingUserSection: JSX.Element;
	BillingAddressSection: JSX.Element;
	Modals: JSX.Element;
	modalIsOpen: boolean;
	hasFetchedData: boolean;
	showBillingAddressFields: boolean;
	user: User;
}

export interface FormStateData {
	groups?: GroupsProps[];
	profileImage?: string;
	profileImageUrl?: string;
	organizationId?: number;
	billingUserId?: number;
}

export enum FormFields {
	firstName = 'firstName',
	lastName = 'lastName',
	email = 'email',
	phoneNumber = 'phoneNumber',
	companyName = 'companyName',
	taxIdType = 'taxIdType',
	taxIdValue = 'taxIdValue',
	birthYear = 'birthYear',
	birthMonth = 'birthMonth',
	birthDay = 'birthDay',
	sex = 'sex',
	address = 'address',
	region = 'region',
	city = 'city',
	postalCode = 'postalCode',
	country = 'country',
	nationality = 'nationality',
	lokSwedishPersonalId = 'lokSwedishPersonalId',
	description = 'description',
}

const generateFormData = (
	userData: User,
	memberships: List<Membership> = List()
): FormStateData => {
	const userGroups: GroupsProps[] =
		(memberships
			.map(({ group, role }: Membership) => ({
				id: group.id,
				role: roleToString(role),
			}))
			.toJS() as GroupsProps[]) || ([] as GroupsProps[]);

	const data: FormStateData = {
		organizationId: userData.organizationId || 0,
		billingUserId: userData.billingUserId || null,
		profileImageUrl: userData.profileImageUrl || '',
		groups: userGroups || [],
	};

	return data;
};

export function checkSubstringInArray(substring: string, array: string[]) {
	for (let i = 0; i < array.length; i++) {
		if (array[i].includes(substring)) {
			return true;
		}
	}
	return false;
}

export const getAlteredValues = (
	oldData: User | models.user.User,
	newData: FormPayload
) => {
	const mappedFormData = newData as typeof FormFields;
	// convert to js to let typescript know it's of the same data
	const oldDataAsJs: any =
		oldData instanceof User
			? (oldData.toJS() as unknown as {
					[key: string]: DetailsDataType;
				})
			: oldData;

	const ignoreValues = [
		'groups',
		'birthYear',
		'birthMonth',
		'birthDay',
		'meta',
		'sex',
	];

	const values: { [key: string]: any } = { fields: [] };

	if (
		mappedFormData.birthYear &&
		mappedFormData.birthMonth &&
		mappedFormData.birthDay
	) {
		values['birthDate'] = getBirthDate(mappedFormData);
	}

	Object.entries(mappedFormData).forEach(([key, value]) => {
		if (key === FormFields.sex && oldDataAsJs[key].toString() !== value) {
			values[key] = Number.parseInt(value, 10);
		} else if (!ignoreValues.includes(key) && oldDataAsJs[key] !== value) {
			values[key] = value;
		}
	});

	const newDataFieldKeys = newData.fields ? Object.keys(newData.fields) : [];

	if (oldDataAsJs && oldDataAsJs.fields) {
		oldDataAsJs.fields
			.filter((f: Field) => f.type === UserFieldType.Attachment)
			.forEach((f: Field) => {
				const exist = checkSubstringInArray(f.key, newDataFieldKeys);

				if (!exist) {
					values.fields[`attachment:${f.key}`] = null;
				}
			});
	}

	return values;
};

const getAlteredGroups = (
	data: FormStateData,
	memberships: List<Membership>
) => {
	const currentMemberships: GroupsProps[] = memberships
		.toJS()
		.map(({ groupId, role }: Membership) => ({
			id: groupId,
			role,
		}));

	const addGroups = data.groups.filter((group: GroupsProps) => {
		const matchingGroup = currentMemberships.find(
			(membership: GroupsProps) => membership.id === group.id
		);

		return !matchingGroup;
	});

	const deleteGroups = currentMemberships.filter((membership: GroupsProps) => {
		const matchingGroup = data.groups.find(
			(group: GroupsProps) => group.id === membership.id
		);

		return !matchingGroup;
	});

	const updateRoles = data.groups.filter((group: GroupsProps) => {
		const matchingGroup = currentMemberships.find(
			(membership: GroupsProps) => membership.id === group.id
		);

		if (!matchingGroup) {
			return false;
		}

		return group.role !== roleToString(matchingGroup.role);
	});

	return {
		add: addGroups,
		delete: deleteGroups,
		role: updateRoles,
	};
};

export const getBirthDate = ({
	birthYear,
	birthMonth,
	birthDay,
}: typeof FormFields): string => {
	const padZero = (value: string): string => {
		return value.padStart(2, '0');
	};

	if (birthYear && birthMonth && birthDay) {
		return `${birthYear}-${padZero(birthMonth)}-${padZero(birthDay)}`;
	}

	return '';
};

interface HookProps {
	groupId: number;
	userId?: number;
	reset?: boolean;
	emailRequired?: boolean;
	preSelectedGroups?: number[];
	userAddressRecords?: Record[];
	fetchMemberships?: boolean;
}

function checkIfGroupsIsEdited(
	currentGroups: GroupsProps[],
	newGroups: GroupsProps[]
): boolean {
	if (currentGroups.length !== newGroups.length) {
		return true;
	}

	return (
		currentGroups.filter((item: GroupsProps) => {
			const group: GroupsProps = newGroups.find(
				(group: GroupsProps) => group.id === item.id
			);

			if (!group) {
				return true;
			}

			return item.role !== group.role;
		}).length > 0
	);
}

const initialNewUserData = generateFormData(new User({}));

const useContactForm = ({
	groupId,
	userId,
	reset,
	emailRequired,
	preSelectedGroups = [],
	userAddressRecords = [],
	fetchMemberships = true,
}: HookProps): ReturnProps => {
	const org = useCurrentOrganization();
	const isEditMode: boolean = !!userId;
	const [contactData, setContactData] =
		useMixedState<FormStateData>(initialNewUserData);
	const [isEdited, setIsEdited] = useState<boolean>(false);
	const [billingData, setBillingData] = useState<FormPayload | User>(
		new User({})
	);
	const [billingContactData, setBillingContactData] =
		useMixedState<FormStateData>(initialNewUserData);
	const [activeModal, setActiveModal] = useState<string>('');
	const [membershipIds, setMembershipIds] = useState<number[]>([]);
	const [hasFetchedData, setHasFetchedData] = useState(false);
	const [showBillingAddressFields, setShowBillingAddressFields] =
		useState(false);

	const user = useSelector((state: RootState) =>
		selectors.users.find(state, userId)
	);
	const memberships: List<Membership> = useSelector((state: RootState) =>
		selectors.memberships.findAllByIds(state, { membershipIds })
	);

	const checkIfEdited = (newData: FormStateData): boolean => {
		const currentStoredData = generateFormData(user, memberships);

		return (
			Object.entries(currentStoredData).filter(
				([key, value]: [key: keyof FormStateData, value: any]) => {
					if (key === 'groups') {
						return checkIfGroupsIsEdited(value, newData[key]);
					}

					return value !== newData[key];
				}
			).length > 0
		);
	};

	useEffect(() => {
		const isAnythingAltered = checkIfEdited(contactData);

		if (isEditMode && isEdited !== isAnythingAltered) {
			setIsEdited(isAnythingAltered);
		}
	}, [contactData, memberships]);

	useEffect(() => {
		if (fetchMemberships) {
			const getMemberships = async () => {
				const ids: number[] = await actions.users.getUserMemberships(
					groupId,
					userId
				)(store.dispatch);

				setMembershipIds(ids);
			};

			if (userId) {
				getMemberships();
			}
		}
	}, [groupId, userId, user, fetchMemberships]);

	useEffect(() => {
		const fetchData = async () => {
			await actions.users.fetchUser(userId)(store.dispatch);
			setHasFetchedData(true);
		};

		if (userId) {
			fetchData();
		}
	}, [userId]);

	useEffect(() => {
		setContactData(generateFormData(user, memberships));
	}, [user, membershipIds]);

	useEffect(() => {
		if (reset === true) {
			setContactData(generateFormData(new User({})));
			setBillingContactData(generateFormData(new User({})));
			setBillingData(new User({}));
		}
	}, [reset]);

	useEffect(() => {
		if (userAddressRecords.length > 0) {
			setShowBillingAddressFields(true);
		}
	}, [userAddressRecords.length]);

	const currentSelectedGroups = contactData.groups;

	const handleAvatarChange = async (value: string) => {
		const updatedState = { ...contactData, ['profileImage']: value };

		setContactData(updatedState);
	};

	const handleShowBillingForm = () => {
		setActiveModal('billingNew');
	};

	const handleSaveBillingForm = (
		data: FormPayload,
		contactData: FormStateData
	) => {
		setBillingData(data);
		setBillingContactData(contactData);
	};

	const handleRemoveGroup = (groupId: number) => {
		const updatedState = { ...contactData };

		updatedState['groups'] = contactData['groups'].filter(
			(group: GroupsProps) => group.id !== groupId
		);
		setContactData(updatedState);

		if (userId) {
			setIsEdited(checkIfEdited(updatedState));
		}
	};

	const handleRemoveBilling = () => {
		setContactData({ ...contactData, billingUserId: null });
		setBillingData(null);
		setBillingContactData(initialNewUserData);
	};

	const handleSelectGroups = (groups: GroupsProps[]) => {
		const updatedState = { ...contactData };

		// patchUser takes role as a int and create user takes role as a string
		updatedState['groups'] = [
			...currentSelectedGroups,
			...groups.map(({ id, role }: GroupsProps) => ({
				id,
				role: isEditMode ? role : roleToString(role),
			})),
		];

		setContactData(updatedState);
	};

	const handleSelectBillingUser = (userId: number) => {
		const prevState = { ...contactData };

		prevState['billingUserId'] = userId;
		setContactData(prevState);
	};

	const handleCloseModal = () => setActiveModal('');
	const handleToggleBillingAddressFields = () =>
		setShowBillingAddressFields(!showBillingAddressFields);

	return {
		user,
		contactData,
		billingData,
		billingContactData,
		alteredGroups: getAlteredGroups(contactData, memberships),
		hasFetchedData: hasFetchedData,
		isEdited,
		showBillingAddressFields,
		DetailsSection: (
			<DetailsSection
				organizationId={org.id}
				data={contactData}
				user={user}
				isEdit={isEditMode}
				handleAvatarChange={handleAvatarChange}
				emailRequired={!billingData?.firstName && emailRequired}
			/>
		),
		UserGroupsSection: (
			<UserGroupsSection
				contactData={contactData}
				onRemoveGroup={handleRemoveGroup}
				onOpenModal={setActiveModal}
			/>
		),
		BillingUserSection: (
			<BillingUserSection
				contactData={contactData}
				billingData={billingData}
				onRemoveBilling={handleRemoveBilling}
				onOpenModal={setActiveModal}
			/>
		),
		BillingAddressSection: (
			<BillingAddressSection
				userAddressRecords={userAddressRecords}
				showBillingAddressFields={showBillingAddressFields}
				handleToggleBillingAddressFields={handleToggleBillingAddressFields}
			/>
		),
		Modals: (
			<Modals
				groupId={groupId}
				activeModal={activeModal}
				onGroupsConfirm={handleSelectGroups}
				onBillingUserConfirm={handleSelectBillingUser}
				onOpenBillingForm={handleShowBillingForm}
				onSaveBillingForm={handleSaveBillingForm}
				onClose={handleCloseModal}
				preSelectedGroups={preSelectedGroups}
			/>
		),
		modalIsOpen: activeModal !== '',
	};
};

interface ModalsProps {
	groupId: number;
	activeModal: string;
	onGroupsConfirm: (groups: GroupsProps[]) => void;
	onBillingUserConfirm: (userId: number) => void;
	onSaveBillingForm: (data: FormPayload, contactData: FormStateData) => void;
	onOpenBillingForm: () => void;
	onClose: () => void;
	preSelectedGroups: number[];
}

const Modals: React.FC<React.PropsWithChildren<ModalsProps>> = ({
	groupId,
	activeModal,
	onGroupsConfirm,
	onBillingUserConfirm,
	onSaveBillingForm,
	onOpenBillingForm,
	onClose,
	preSelectedGroups,
}) => {
	switch (activeModal) {
		case 'billingSelect':
			return (
				<SelectContact
					groupId={groupId}
					canCreateNew
					onConfirm={onBillingUserConfirm}
					onCreateNew={onOpenBillingForm}
					onClose={onClose}
				/>
			);
		case 'billingNew':
			return (
				<CreateModal
					groupId={groupId}
					onConfirm={onSaveBillingForm}
					onClose={onClose}
				/>
			);
		case 'group':
			return (
				<AddToGroupsModal
					groupId={groupId}
					preSelectedGroups={preSelectedGroups}
					onConfirm={onGroupsConfirm}
					onClose={onClose}
				/>
			);
		default:
			return null;
	}
};

export default useContactForm;
