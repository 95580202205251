import { JSX } from 'react';
import { t } from '@transifex/native';

import { SoldProductsItem } from 'pkg/api/models/order_report';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import {
	useLocalizedCurrencyFormatter,
	useOrderReport,
} from 'routes/payments/overview/hooks';
import { Widget } from 'routes/payments/overview/widgets';

import * as Table from 'design/table';
import * as DataCard from 'design/data_card';
import useTableSet from 'design/table/use_table_set';

import * as css from './styles.css';

export default function SoldProductsTableWidget(): JSX.Element {
	const org = useCurrentOrganization();
	const fmt = useLocalizedCurrencyFormatter();
	const { soldProducts } = useOrderReport();

	const products = Object.values(soldProducts.products);

	const items = useTableSet<SoldProductsItem>(products, {
		wrapperClassName: css.table,
		defaultSortKey: 'amount',
		defaultSortOrder: 'desc',
		itemsPerPage: 10,
		columns: [
			{
				content: t('Product'),
				sortKey: 'name',
				columnKey: 'name',
				renderItemWith: (item: SoldProductsItem) => (
					<Table.LinkCell
						linkStyle
						href={routes.Product.Show(org.id, item.id, 'overview')}>
						{item.name}
					</Table.LinkCell>
				),
			},
			{
				content: t('Quantity'),
				sortKey: 'quantity',
				columnKey: 'quantity',
				width: 'max-content',
			},
			{
				content: t('Amount'),
				sortKey: 'amount',
				columnKey: 'amount',
				width: 'max-content',
				renderItemWith: (item: SoldProductsItem) => (
					<Table.Cell>{fmt(item.amount)}</Table.Cell>
				),
			},
		],
	});

	if (products.length === 0) {
		return null;
	}

	return (
		<Widget>
			<DataCard.Base
				wrapContent={false}
				titleIcon="sell"
				title={t('Product sales overview')}>
				{items.Table}
				{items.Pagination}
			</DataCard.Base>
		</Widget>
	);
}
