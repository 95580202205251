import { JSX } from 'react';

import { useViewportFetchLimit } from 'pkg/hooks/library';
import { useCurrentGroup } from 'pkg/identity';

import { useGroupLibrary } from 'routes/training/library/hooks';
import Library from 'routes/training/library/Library';

export default function Account(): JSX.Element {
	const group = useCurrentGroup();

	const fetchLimit = useViewportFetchLimit();
	const library = useGroupLibrary(group.id, fetchLimit);

	return <Library library={library} />;
}
