import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useGroup } from 'pkg/hooks/selectors';
import { useCollection } from 'pkg/api/use_collection';
import { crash } from 'pkg/errors/errors';
import { fromEntries } from 'pkg/utils';
import { FilterOperator } from 'pkg/filters';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';
import * as actions from 'pkg/actions';
import { Flag, useFlag } from 'pkg/flags';

import SubscriptionRow from 'routes/payments/subscriptions/list/Row';
import useSubscriptionFilters from 'routes/payments/subscriptions/filters';

import AssetImage from 'components/AssetImage';
import { useExport } from 'components/export';
import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';
import * as Input from 'components/form/inputs';

import * as Context from 'design/context_menu';
import Button from 'design/button';
import * as Table from 'design/table';
import { useDialog } from 'design/dialog';

enum SubscriptionFields {
	CustomerBillingEmail = 'customer_billing_email',
	Status = 'status',
	Plan = 'plan',
	CreatedAt = 'created_at',
	CurrentPeriodEnd = 'current_period_end',
	StartAt = 'start_at',
	CustomerName = 'customer_name',
	Type = 'type',
	AssignedUsers = 'assigned_users',
	CollectionMethod = 'collection_method',
	Products = 'products',
	PaidInstallments = 'paid_installments',
}

function getTranslatedField(field: SubscriptionFields): string {
	let string = '';

	switch (field) {
		case SubscriptionFields.CustomerBillingEmail:
			string = t('Billing email');
			break;
		case SubscriptionFields.Status:
			string = t('Status');
			break;
		case SubscriptionFields.Plan:
			string = t('Plan');
			break;
		case SubscriptionFields.CreatedAt:
			string = t('Created');
			break;
		case SubscriptionFields.CurrentPeriodEnd:
			string = t('Current period end');
			break;
		case SubscriptionFields.StartAt:
			string = t('Start at');
			break;
		case SubscriptionFields.CustomerName:
			string = t('Recipient name');
			break;
		case SubscriptionFields.Type:
			string = t('Type');
			break;
		case SubscriptionFields.AssignedUsers:
			string = t('Assigned user(s)');
			break;
		case SubscriptionFields.CollectionMethod:
			string = t('Collection method');
			break;
		case SubscriptionFields.Products:
			string = t('Product');
			break;
		case SubscriptionFields.PaidInstallments:
			string = t('Paid installments');
			break;
	}

	return string;
}

interface SubscriptionIndexProps {
	organizationId: number;
	userId?: number;
	productId?: number;
	formId?: number;
	disableNewSubscription?: boolean;
}

export default function SubscriptionIndex({
	organizationId,
	userId,
	productId,
	formId,
	disableNewSubscription,
}: SubscriptionIndexProps) {
	const group = useGroup(organizationId);
	const [showSelected, setShowSelected] = useState(false);
	const subscriptionAllowedActionsEnabled = useFlag<boolean>(
		Flag.SubscriptionAllowedActions
	);

	const filters = useSubscriptionFilters();

	if (userId) {
		filters.queryParam.set('associated_user_id', userId.toString());
	}

	if (productId) {
		filters.queryParam.set('product_id', productId.toString());
	}

	if (formId) {
		filters.queryParam.set('form_id', formId.toString());
	}

	filters.queryParam.set('group_id', organizationId.toString());

	const {
		records: subscriptions,
		pagination,
		isLoading,
		sort,
		selection,
		replaceMultipleRecords,
		selectedRecords,
	} = useCollection<models.subscription.Subscription>(
		endpoints.Subscriptions.Index(),
		{
			queryParams: filters.queryParam,
			useLocationPagination: true,
			defaultSortBy: 'created_at',
			showOnlySelected: showSelected,
		}
	);

	const getColumn = (field: SubscriptionFields): { content: string } => {
		const translatedField = getTranslatedField(field);
		return {
			content: translatedField,
		};
	};

	const getSortColumn = (field: SubscriptionFields): Table.HeaderColumn => {
		const translatedField = getTranslatedField(field);

		const columnInfo: Table.HeaderColumn = {
			content: translatedField,
			sortKey: field,
		};

		return columnInfo;
	};

	const exportFields = models.subscription.getExportFields();

	const onCreateExport = async (fields: string[]) => {
		const payload: { [key: string]: boolean } = {};

		fields.forEach((f) => (payload[f] = true));

		const [ok, csvData] = await models.subscription.exportSubscriptions(
			filters.queryParam
				? (fromEntries(filters.queryParam) as any) //todo(jbfm): this can be updated when useCollection isn't forced to use URLSearcHParams
				: {
						group_id: organizationId,
					},
			payload
		);

		if (ok) {
			const fileName = `${group.name}-subscriptions.csv`;

			const url = URL.createObjectURL(new Blob([csvData]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	const handleBulkCancel = async () => {
		const ok = await actions.subscriptions.cancel({
			ids: selection.selectedRecords as number[],
		});

		if (ok) {
			const updatedSubscriptions = selectedRecords.map((r) => {
				return {
					...r,
					status: models.subscription.SubscriptionStatus.Canceled,
				};
			});

			replaceMultipleRecords(updatedSubscriptions);
		}
	};

	const exportAction = useExport(exportFields, onCreateExport);

	const emptyState = {
		title: t('Could not find any subscriptions'),
		content: t('Could not find any subscriptions'),
		image: <AssetImage src="img/missing-entities/Subscription.svg" />,
		button: (
			<Button secondary href={routes.Invoice.New(organizationId)} icon="add">
				{t('New order')}
			</Button>
		),
	};

	const cancelDialog = useDialog({
		onConfirm: handleBulkCancel,
		message: t(
			'Are you sure you want to cancel the selected subscriptions immediately? This action cannot be undone.'
		),
		destructive: true,
	});

	let canCancel = true;

	if (subscriptionAllowedActionsEnabled) {
		canCancel = subscriptions
			.filter((s) => selection.selectedRecords.includes(s.id))
			.map((s) => s.allowedActions)
			.some((s) => s.includes(models.Action.SubscriptionCancel));
	}

	return (
		<Fragment>
			<ActionBar.IntegratedFilterBar
				filters={filters}
				searchFilter={{
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'search',
				}}
				actions={[
					{
						type: 'base',
						icon: 'download',
						onClick: exportAction.open,
						label: t('Export'),
					},
					!disableNewSubscription && {
						type: 'primary',
						icon: 'add',
						label: t('New order'),
						href: link(routes.Invoice.New(organizationId), {
							userIds: [userId],
						}),
					},
				]}
			/>

			<ActionBar.BulkActions
				numSelected={selection.selectedRecords.length}
				showSelected={showSelected}
				setShowSelected={setShowSelected}
				actions={[
					<Context.Item
						disabled={!canCancel}
						icon="delete"
						caution
						onClick={cancelDialog}>
						{t('Cancel selected')}
					</Context.Item>,
				]}
			/>
			<Table.Table
				emptyState={emptyState}
				stickyHeader
				isLoading={isLoading}
				sortBy={sort.column}
				sortOrder={sort.order}
				onSort={sort.setSort}
				columns={[
					{
						content: (
							<Input.Control
								type="checkbox"
								onChange={selection.selectAll}
								checked={selection.isAllSelected}
								standalone
							/>
						),
						width: 'max-content',
					},
					getColumn(SubscriptionFields.CustomerName),
					getSortColumn(SubscriptionFields.CustomerBillingEmail),
					getColumn(SubscriptionFields.AssignedUsers),
					getColumn(SubscriptionFields.Products),
					getColumn(SubscriptionFields.Type),
					getColumn(SubscriptionFields.PaidInstallments),
					getColumn(SubscriptionFields.Status),
					getColumn(SubscriptionFields.Plan),
					getSortColumn(SubscriptionFields.CollectionMethod),
					getSortColumn(SubscriptionFields.StartAt),
					getSortColumn(SubscriptionFields.CurrentPeriodEnd),
					getSortColumn(SubscriptionFields.CreatedAt),
					{
						content: '',
						width: 'max-content',
					},
				]}>
				{subscriptions.map((item) => {
					return (
						<SubscriptionRow
							key={item.id}
							subscription={item}
							selection={selection}
						/>
					);
				})}
			</Table.Table>

			<Pagination {...pagination} />

			{exportAction.modal}
		</Fragment>
	);
}
