import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { Dates } from 'pkg/hooks/use-date-filter';
import { FilterGroups, useFilters } from 'pkg/filters/use_filters';
import { FilterOperator, createFilterGroups } from 'pkg/filters';

import GroupReportTable from 'routes/group/report/group/table';

import Pagination from 'components/pagination';

import * as ActionBar from 'components/layout/ActionBar';
import { GroupSelectModal } from 'components/group/select_modal';

interface GroupReportProps {
	groupId: number;
	dateFilter: JSX.Element;
	dateRange: Dates;
}

export default function GroupReport({
	groupId,
	dateFilter,
	dateRange,
}: GroupReportProps) {
	const filterGroups: FilterGroups = createFilterGroups({
		[t('Name')]: {
			hidden: true,
			filters: {
				Name: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'name',
				},
			},
		},
		[t('Groups')]: {
			filters: {
				['Groups']: {
					type: 'modal',
					property: 'group_ids',
					operator: FilterOperator.Includes,
					settings: {
						modal: <GroupSelectModal />,
						buttonLabel: t('Select groups'),
						hideButton: false,
					},
				},
			},
		},
		[t('Groups with events')]: {
			filters: {
				['Events']: {
					type: 'checkbox',
					property: 'has_events',
					operator: FilterOperator.Equals,
					values: {
						[t('Group has events')]: 'true',
					},
				},
			},
		},
	});

	const filters = useFilters({
		groups: filterGroups,
	});

	const groupFilters = filters.currentFilters.find(
		(f) => f.property === 'group_ids'
	);

	if (groupFilters) {
		filters.queryParam.set('group_ids', groupFilters.values.toString());
	}

	filters.queryParam.set('from', dateRange.startOfRange.toString());
	filters.queryParam.set('to', dateRange.endOfRange.toString());

	const {
		records: groupReports,
		isLoading: isLoadingGroupReports,
		pagination,
		sort,
	} = useCollection<models.eventReport.GroupReport>(
		endpoints.EventReport.GroupReport(groupId),
		{
			queryParams: filters.queryParam,
			defaultSortBy: 'group_name',
			defaultSortByOrder: 'asc',
			useLocationPagination: true,
		}
	);

	return (
		<Fragment>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search
						filter={filterGroups[t('Name')].filters.Name}
						filterSetter={filters.setFilter}
						placeholder={t('Search content')}
					/>
				</ActionBar.PrimaryAction>
				{dateFilter}
				{filters.Component}
			</ActionBar.FilterBar>
			<GroupReportTable
				groupReports={groupReports}
				isLoading={isLoadingGroupReports}
				sort={sort}
			/>
			<Pagination {...pagination} />
		</Fragment>
	);
}
