import { JSX, MouseEvent, ReactNode, useRef, useState } from 'react';

import { useDebounce } from 'pkg/timings';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import { localeIncludes } from 'pkg/strings';

import * as styles from 'components/form/inputs/autocomplete/styles';
import * as Input from 'components/form/inputs';

export interface SimpleAutoCompleteSuggestion {
	items?: string[];
	label?: string;

	onSelect: (item: string) => void;
	renderWith: (item: string) => ReactNode;
}

interface AutoCompleteProps {
	children?: ReactNode | ReactNode[];
	autoFocus?: boolean;

	testid?: string;
	inline?: boolean;
	rounded?: boolean;
	large?: boolean;
	small?: boolean;
	transparent?: boolean;
	placeholder?: string;
	defaultValue?: string;
	name?: string;
	validate?: (value: string) => void;

	renderInitialWith?: (inputValue: string) => ReactNode;
	suggestions: SimpleAutoCompleteSuggestion;

	onFocus?: () => void;
	onBlur?: () => void;
	onTyping?: (value: string) => void;
	onDidType?: (value: string) => void;
	onSelectFallback?: (value: string) => void;
}

function filterSuggestions(items: string[], keyword: string) {
	if (keyword === '') {
		return items;
	}

	return items.filter((i) => localeIncludes(i, keyword));
}

// Dumber version of the AutoComplete input, this has no keybindings and doesn't take multiple AutoCompleteSuggestionGroups
// the filter function is also a set function instead of dynamic
export default function SimpleAutoComplete({
	children,
	autoFocus,

	testid,
	inline,
	rounded,
	large,
	small,
	transparent,
	placeholder,
	defaultValue = '',
	validate,
	name,

	suggestions,

	onFocus,
	onBlur,
}: AutoCompleteProps): JSX.Element {
	const inputRef = useRef<HTMLInputElement>();

	const zIndex = useNewTopIndex();

	const [showSuggestions, setShowSuggestions] = useState(false);
	const [search, setSearch] = useState('');

	const selectItem = (item: string) => {
		setSearch('');

		suggestions.onSelect(item);
		inputRef.current.value = item as string;

		handleBlur();
	};

	const handleChange = async () => {
		setSearch(inputRef.current?.value);
		suggestions.onSelect(inputRef.current?.value);
	};

	const handleFocus = () => {
		setShowSuggestions(true);

		if (onFocus) {
			onFocus();
		}
	};

	// Debounce needed to allow click on items
	const handleBlur = useDebounce(() => {
		setSearch('');
		setShowSuggestions(false);

		if (onBlur) {
			onBlur();
		}
	}, 100);

	const handleMouseDown = (event: MouseEvent<HTMLLIElement>) => {
		selectItem(event.currentTarget.dataset.value as string);
	};

	const items = filterSuggestions(suggestions.items, search);
	const shouldShowSuggestions = items.length > 0 && showSuggestions;

	return (
		<styles.AutocompleteWrapper>
			<Input.Field
				name={name}
				ref={inputRef}
				validate={validate}
				data-testid={testid}
				inline={inline}
				rounded={rounded}
				large={large}
				small={small}
				autoFocus={autoFocus}
				transparent={transparent}
				placeholder={placeholder}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				defaultValue={defaultValue}
				autoComplete="off">
				{children}
			</Input.Field>
			{shouldShowSuggestions && (
				<styles.SuggestionsWrapper style={{ zIndex }}>
					<styles.Suggestions data-inline={inline}>
						<styles.SuggestionGroup>
							{suggestions?.label && (
								<styles.SuggestionGroupLabel>
									{suggestions.label}
								</styles.SuggestionGroupLabel>
							)}
							<ul>
								{items.map((i) => {
									return (
										<styles.Suggestion
											key={i}
											// Need onMouseDown here because we need to run the func
											// straight away or ells the currentTarget changes
											// and nothing gets selected
											onMouseDown={handleMouseDown}
											data-value={i}>
											{suggestions.renderWith(i)}
										</styles.Suggestion>
									);
								})}
							</ul>
						</styles.SuggestionGroup>
					</styles.Suggestions>
				</styles.SuggestionsWrapper>
			)}
		</styles.AutocompleteWrapper>
	);
}
