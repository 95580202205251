import { JSX, Fragment, ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import { AttendanceStatuses, EventUserStatus } from 'pkg/api/models/event_user';

import { useCalendarContext } from 'routes/group/calendar';
import { useEventContext } from 'routes/event/single';

import StepModal, { Step } from 'components/step-modal';

import * as Input from 'components/form/inputs';
import FormRow from 'components/form/Row';

const Statuses = styled.div`
	display: grid;
	grid-auto-flow: row;
	gap: ${styles.spacing._3};
`;

const declineReasons: AttendanceStatuses[] = [
	AttendanceStatuses.Injured,
	AttendanceStatuses.Sick,
	AttendanceStatuses.Other,
];

function getReasonString(reason: AttendanceStatuses) {
	switch (reason) {
		case 'injured':
			return t('Injured');
		case 'other':
			return t('Other');
		case 'sick': {
			return t('Illness');
		}
	}
}

interface AttendanceModalProps {
	event: models.event.Event;
	eventUser: models.eventUser.EventUser;

	refresh: () => Promise<void>;
	closeModal: () => void;
}

export default function AttedanceModal({
	event,
	refresh,
	eventUser,
	closeModal,
}: AttendanceModalProps): JSX.Element {
	const calendarCtx = useCalendarContext();
	const eventCtx = useEventContext();

	const [attendanceStatus, setAttendanceStatus] = useState<AttendanceStatuses>(
		eventUser.attendanceStatus
	);
	const [comment, setComment] = useState(eventUser.comment);
	const hasEnded = models.event.hasEnded(event);

	const handleUpdateUserStatus = async () => {
		if (hasEnded) {
			const req = await actions.eventUsers.patchEventUsers(event.id, {
				users: [
					{
						userId: eventUser.userId,
						comment,
						attendanceStatus,
					},
				],
			});

			if (req) {
				refresh();

				if (calendarCtx.eventsCollection) {
					calendarCtx.eventsCollection.refresh();
				}

				if (eventCtx) {
					eventCtx.eventEndpointResponse.refresh();
				}
			}
		} else {
			const req = await actions.eventUsers.patchEventUsers(event.id, {
				users: [
					{
						userId: eventUser.userId,
						comment,
						attendanceStatus,
						status: EventUserStatus.Declined,
					},
				],
			});

			if (req) {
				refresh();

				if (calendarCtx.eventsCollection) {
					calendarCtx.eventsCollection.refresh();
				}

				if (eventCtx) {
					eventCtx.eventEndpointResponse.refresh();
				}
			}
		}
	};

	const handleAttendanceStatusChange = (
		value: models.eventUser.AttendanceStatuses
	) => {
		setAttendanceStatus(value);
	};

	const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
		setComment(event.target.value);
	};

	const renderedAttendanceStatuses = declineReasons.map(
		(status: AttendanceStatuses) => (
			<Input.Control
				type="radio"
				key={status}
				name="attendanceStatus[]"
				value={status}
				label={getReasonString(status)}
				onChange={handleAttendanceStatusChange}
				checked={attendanceStatus === status}
			/>
		)
	);

	let stepModalTitle = t('Decline invitation');
	let nextLabel = t('Send response');

	if (eventUser.status === models.eventUser.EventUserStatus.Accepted) {
		if (eventUser.comment) {
			stepModalTitle = t('Edit comment');
			nextLabel = t('Save comment');
		} else {
			stepModalTitle = t('Add comment');
			nextLabel = t('Add comment');
		}
	}

	const canGoNext = declineReasons.includes(attendanceStatus);

	return (
		<StepModal thin onClose={closeModal}>
			<Step
				title={stepModalTitle}
				prevLabel={t('Cancel')}
				nextLabel={nextLabel}
				onNext={handleUpdateUserStatus}
				canGoNext={canGoNext}>
				<Fragment>
					<FormRow>
						<Input.Group label={t('Reason')}>
							<Statuses>{renderedAttendanceStatuses}</Statuses>
						</Input.Group>
					</FormRow>
					<FormRow>
						<Input.Field
							defaultValue={comment}
							onChange={handleCommentChange}
							placeholder={t('Absence comment (optional)')}
						/>
					</FormRow>
				</Fragment>
			</Step>
		</StepModal>
	);
}
