import { usePrefab } from '@prefab-cloud/prefab-cloud-react';

/* Static required flags, do not edit these */
export const isDev = (): boolean => window.TS.dev;
export const isBeta = (): boolean => window.TS.beta || isDev();
export const isDevServer = (): boolean => window.TS.devServer;
export const isCypress = (): boolean => !!window.Cypress;

export enum Flag {
	RevenueTable = 'payments.revenue_table',
	SubscriptionAllowedActions = 'payments.subscription_allowed_actions',
	OrderAllowedActions = 'payments.order_allowed_actions',
	PaymentMethods = 'payments.payment_methods',
	UserFieldCategories = 'management.user_field_categories',
	NewUserProfile = 'management.new_user_profile',
	Intercom = 'support.intercom',
	Multisport = 'multisport',
}

/**
 * Override the value of a flag for the current session, useful for testing a feature
 * locally without going into Prefab and changing the flag value for everyone.
 * @param flag A flag from Prefab.
 * @param value The override value.
 */
export function overrideFlag(flag: string, value: boolean) {
	sessionStorage.setItem(`flagoverride:${flag}`, value.toString());
}

export function getOverride(flag: string): boolean | undefined {
	const val = sessionStorage.getItem(`flagoverride:${flag}`);
	if (val !== null) {
		return val === 'true';
	}

	return undefined;
}

/**
 * Reads a flag from Prefab, or the local override if it exists.
 * @param flag A flag from Prefab.
 * @returns The value of the flag
 */
export function useFlag<T>(flag: Flag): T {
	const prefab = usePrefab();
	let val: T;
	val = getOverride(flag) as T;
	if (val === undefined) {
		val = prefab.get(flag) as T;
	}

	return val;
}
