// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._63MUeWP7U3vUfBK_jCjg {
	margin-bottom: 0 !important;
}

.lnPN1USw7ntjFY80oAfT {
	margin-bottom: var(--spacing-5);
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
}

.I3_1Ms00f4Xz9xMVtkYS {
	border: 1px solid var(--palette-gray-300);
	border-radius: var(--radius-3);
}

.i7qAFC5V72IQ1CAACFPg,
.Gc2dnWWiVuoRFh9rrwSw {
	padding: var(--spacing-5);
}

.i7qAFC5V72IQ1CAACFPg {
	text-align: right;
}

.Gc2dnWWiVuoRFh9rrwSw {
	border-right: 1px solid var(--palette-gray-300);
}

.wxpmB3zWFxyG_OF1fbWl {
	margin-bottom: var(--spacing-3);
}

.cByd88nTuMRZBNX6wEI2 {
	--size: 25px;

	background-color: hsl(var(--hsl));
	width: var(--size);
	height: var(--size);
	border-radius: var(--size);
	-webkit-box-shadow:
		0 2px 12px hsla(0, 0%, 0%, 0.12),
		inset 0 0 0 1px hsla(0, 0%, 0%, 0.17);
	        box-shadow:
		0 2px 12px hsla(0, 0%, 0%, 0.12),
		inset 0 0 0 1px hsla(0, 0%, 0%, 0.17);
}
`, "",{"version":3,"sources":["webpack://./containers/user/rating/styles.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;AAC5B;;AAEA;CACC,+BAA+B;CAC/B,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,yCAAyC;CACzC,8BAA8B;AAC/B;;AAEA;;CAEC,yBAAyB;AAC1B;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,+CAA+C;AAChD;;AAEA;CACC,+BAA+B;AAChC;;AAEA;CACC,YAAY;;CAEZ,iCAAiC;CACjC,kBAAkB;CAClB,mBAAmB;CACnB,0BAA0B;CAC1B;;uCAEuC;SAFvC;;uCAEuC;AACxC","sourcesContent":[".heading {\n\tmargin-bottom: 0 !important;\n}\n\n.subHeading {\n\tmargin-bottom: var(--spacing-5);\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-500);\n}\n\n.positionLegend {\n\tborder: 1px solid var(--palette-gray-300);\n\tborder-radius: var(--radius-3);\n}\n\n.bestPosition,\n.chosenPosition {\n\tpadding: var(--spacing-5);\n}\n\n.bestPosition {\n\ttext-align: right;\n}\n\n.chosenPosition {\n\tborder-right: 1px solid var(--palette-gray-300);\n}\n\n.positionLegendKey {\n\tmargin-bottom: var(--spacing-3);\n}\n\n.trend {\n\t--size: 25px;\n\n\tbackground-color: hsl(var(--hsl));\n\twidth: var(--size);\n\theight: var(--size);\n\tborder-radius: var(--size);\n\tbox-shadow:\n\t\t0 2px 12px hsla(0deg 0% 0% / 12%),\n\t\tinset 0 0 0 1px hsla(0deg 0% 0% / 17%);\n}\n"],"sourceRoot":""}]);
// Exports
export var heading = `_63MUeWP7U3vUfBK_jCjg`;
export var subHeading = `lnPN1USw7ntjFY80oAfT`;
export var positionLegend = `I3_1Ms00f4Xz9xMVtkYS`;
export var bestPosition = `i7qAFC5V72IQ1CAACFPg`;
export var chosenPosition = `Gc2dnWWiVuoRFh9rrwSw`;
export var positionLegendKey = `wxpmB3zWFxyG_OF1fbWl`;
export var trend = `cByd88nTuMRZBNX6wEI2`;
export default ___CSS_LOADER_EXPORT___;
