import { JSX, Fragment, ReactNode } from 'react';

import * as css from './styles.css';

export interface DataListItem {
	label: string;
	value: string | ReactNode;
}

interface DataListProps {
	items: DataListItem[];
}

export default function DataList({ items }: DataListProps): JSX.Element {
	return (
		<dl className={css.wrapper}>
			{items.map(({ label, value }, n) => (
				<Fragment key={n}>
					<dt className={css.label}>{label}</dt>
					<dd className={css.value}>{value}</dd>
				</Fragment>
			))}
		</dl>
	);
}
