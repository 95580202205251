import { JSX, ReactNode, ElementType, Children, ReactElement } from 'react';

import Link from 'pkg/router/Link';
import { cssClasses } from 'pkg/css/utils';

import MaterialSymbol from 'components/material-symbols';
import Icon from 'components/icon';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import { useAppState } from 'components/application/state';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface ItemGroupProps {
	title?: string;
	children: ReactNode;
	show?: boolean;
}

interface ItemGroupCollapsibleProps {
	title?: string;
	children: ReactNode;
	slug?: string;
	show?: boolean;
	expand?: string;
	icon: MaterialSymbolVariant;
	testid?: string;
	onClick?: (slug: string) => void;
}

export function ItemGroup({
	children,
	title,
	show = true,
}: ItemGroupProps): JSX.Element {
	if (!show) {
		return null;
	}

	return (
		<div className={css.itemGroupWrapper}>
			{title && <h3 className={css.itemGroupTitle}>{title}</h3>}
			<div className={css.itemGroupChildrenWrapper}>{children}</div>
		</div>
	);
}

export function ItemGroupCollapsible({
	children,
	title,
	show = true,
	expand = '',
	slug,
	icon,
	onClick,
	testid,
}: ItemGroupCollapsibleProps): JSX.Element {
	const appState = useAppState();
	const collapsedDrawer = appState.collapsedDrawer;

	if (!show) {
		return null;
	}

	// ItemGroup should have the active state and children should be hidden when this is true
	const itemGroupActive = slug === expand;

	const handleClick = () => {
		if (onClick) {
			onClick(itemGroupActive ? '' : slug);
		}
	};

	const content = (
		<div className={css.collapsibleItemGroupWrapper} data-testid={testid}>
			{title && (
				<span
					className={cssClasses(
						css.collapsibleItemGroupTitle,
						itemGroupActive ? css.activeItemGroup : ''
					)}
					onClick={handleClick}>
					<MaterialSymbol variant={icon} className={css.itemIcon} />
					<span className={css.collapsedTitle}>{title}</span>
					<Icon
						className={css.chevron}
						name="chevron"
						rotate={slug === expand ? '90deg' : '0deg'}
						size={1.25}
					/>
				</span>
			)}
			<div
				className={cssClasses(
					css.itemGroupChildren,
					!itemGroupActive ? css.hide : ''
				)}>
				{children}
			</div>
		</div>
	);

	if (collapsedDrawer) {
		// Check if the prop show exist or if it's set to true
		const filteredChildren = Children.toArray(children).filter(
			(c: ReactElement) => c.props.show === undefined || c.props.show
		);

		return (
			<Context.Menu toggleWith={content}>
				{filteredChildren.map((child: ReactElement, index) => {
					return (
						<Context.LinkItem key={index} href={child.props?.href}>
							{child.props?.name}
						</Context.LinkItem>
					);
				})}
			</Context.Menu>
		);
	}

	return content;
}

interface MenuItemProps {
	as?: ElementType;
	name?: string;
	title?: string;
	icon?: MaterialSymbolVariant;
	avatar?: ReactNode;
	to?: string;
	params?: {
		[key: string]: any;
	};
	href?: string;
	replace?: string;
	onClick?: () => void;
	currentUrl?: string;
	children?: ReactNode;
	rotateIcon?: string;
	outlinedIcon?: boolean;
	testid?: string;
	show?: boolean;
	small?: boolean;
	myPages?: boolean;
}

export const Item = ({
	as,
	name,
	title,
	icon,
	avatar,
	to,
	params,
	href,
	replace,
	onClick,
	children,
	small,
	myPages,
	testid = 'navigation.item',
	show = true,
}: MenuItemProps): JSX.Element => {
	if (!show) {
		return null;
	}
	const classNames = cssClasses(
		icon || avatar ? css.collapsibleItemGroupTitle : css.itemStyles,
		small ? css.smallItemStyles : null
	);

	const myPageClasses = cssClasses(
		icon || avatar ? css.myPages : css.itemStyles,
		small ? css.smallItemStyles : null
	);

	const labelClassNames = cssClasses(
		icon || avatar ? css.itemName : css.subItemName,
		small ? css.smallItemStyles : null
	);

	const iconClasses = cssClasses(
		icon || avatar ? css.collapsibleItemGroupTitle : css.itemStyles
	);

	let item = (
		<Link
			onClick={onClick}
			as={as}
			to={to}
			params={params}
			href={href}
			replace={replace}
			title={title || name}
			data-testid={testid}
			className={myPages ? myPageClasses : classNames}>
			{avatar && <div className={css.avatarWrapper}>{avatar}</div>}{' '}
			{icon && <MaterialSymbol variant={icon} className={css.itemIcon} />}
			<span className={labelClassNames}>{name}</span>
		</Link>
	);

	if (window.location.pathname === href) {
		item = (
			<div
				data-current-page={true}
				data-testid={testid}
				className={myPages ? myPageClasses : iconClasses}>
				{avatar && <div className={css.avatarWrapper}>{avatar}</div>}{' '}
				{icon && <MaterialSymbol variant={icon} className={css.itemIcon} />}
				<span className={labelClassNames}>{name}</span>
			</div>
		);
	}

	if (!href && onClick) {
		item = (
			<div
				onClick={onClick}
				data-testid={testid}
				className={myPages ? myPageClasses : iconClasses}>
				{avatar && <div className={css.avatarWrapper}>{avatar}</div>}{' '}
				{icon && <MaterialSymbol variant={icon} className={css.itemIcon} />}
				<span className={labelClassNames}>{name}</span>
			</div>
		);
	}

	return (
		<div className={css.itemWrapper}>
			{item}
			{children}
		</div>
	);
};

export const Items = ({ children }: { children: React.ReactNode }) => (
	<div className={cssClasses(css.items, css.orgSplitEnabled)}>{children}</div>
);

export const Menu = ({ children }: { children: JSX.Element }) => {
	return (
		<nav className={cssClasses(css.menu, css.orgSplitEnabled)}>{children}</nav>
	);
};
