import { JSX } from 'react';
import { t } from '@transifex/native';

import SectionTitle from 'components/SectionTitle';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as DataCard from 'design/data_card';

interface ActivitySummaryProps {
	totalEvents: number;
	totalAttendance: number;
	individual: number;
}

export default function ActivitySummary({
	totalEvents,
	totalAttendance,
	individual,
}: ActivitySummaryProps): JSX.Element {
	const formatParticipationAverage = (): string => {
		const attendance = totalAttendance || 0;
		const events = totalEvents || 0;
		let average = 0;

		if (attendance && events) {
			average = Math.floor(attendance / events);
		}

		return `${attendance} / ${average}`;
	};

	return (
		<Column>
			<SectionTitle>{t('Activity summary')}</SectionTitle>
			<LargeScreen>
				<Row align="stretch">
					<DataCard.WithLargeIcon
						title={t('Total events')}
						heading={(totalEvents || 0).toString()}
						icon="training"
					/>
					<DataCard.WithLargeIcon
						title={t('Total Participation / Average')}
						heading={formatParticipationAverage()}
						icon="nav-members"
					/>
					<DataCard.WithLargeIcon
						title={t('Individually logged activities')}
						heading={(individual || 0).toString()}
						icon="nav-profile"
					/>
				</Row>
			</LargeScreen>
			<SmallScreen>
				<Row>
					<DataCard.WithLargeIcon
						title={t('Total events')}
						heading={(totalEvents || 0).toString()}
						icon="training"
					/>
				</Row>
				<Row>
					<DataCard.WithLargeIcon
						title={t('Total Participation / Average')}
						heading={formatParticipationAverage()}
						icon="nav-members"
					/>
				</Row>
				<Row>
					<DataCard.WithLargeIcon
						title={t('Individually logged activities')}
						heading={(individual || 0).toString()}
						icon="nav-profile"
					/>
				</Row>
			</SmallScreen>
		</Column>
	);
}
