import { JSX } from 'react';
export default function Polygon(): JSX.Element {
	return (
		<svg
			viewBox="0 0 25 25"
			width="25"
			height="25"
			preserveAspectRatio="xMidYMid meet"
			fill="none">
			<path
				d="M5.86093 18.7339L10.5149 9.99201L19.183 5.5539V15.3234L5.86093 18.7339Z"
				stroke="currentColor"
				strokeWidth="1.3"
			/>
		</svg>
	);
}
