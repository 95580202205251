import { JSX } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { AttendanceStatuses } from 'pkg/api/models/event_user';
import { useCurrentAccountUserIds, useCurrentAccountWards } from 'pkg/identity';

import StatusLabel from 'routes/event/single/event-users/StatusLabel';
import UserRow from 'routes/event/single/event-users/user-row';

import SectionTitle from 'components/SectionTitle';
import CardAnatomy, * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import Icon from 'components/icon';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

interface AttendedListProps {
	event: models.event.Event;
	eventUsers: models.eventUser.EventUser[];

	variant: 'organizers' | 'participants';

	toggleInviteModal: () => void;
}

const MissingEntitiesWrapper = styled.div`
	margin-top: var(--spacing-4);
`;

export default function AttendedList({
	event,
	eventUsers,

	variant,

	toggleInviteModal,
}: AttendedListProps): JSX.Element {
	const activeUserIds = useCurrentAccountUserIds();
	const wardRelations = useCurrentAccountWards();
	const wardUserIds = models.accountRelation.findAllUserIds(
		wardRelations.filter((ar) => models.accountRelation.isApproved(ar))
	);

	const myEventUsers = models.eventUser.findAllEventUsers(event.users, [
		...activeUserIds,
		...wardUserIds,
	]);

	const sortedEventUsers = models.eventUser.sortEventUsers(
		eventUsers,
		myEventUsers,
		event
	);

	const usersAttended = sortedEventUsers
		.filter(
			(eventUser) => eventUser.attendanceStatus === AttendanceStatuses.Attended
		)
		.map((eventUser, i: number) => (
			<UserRow key={i} event={event} eventUser={eventUser} />
		));

	const usersDidNotAttend = sortedEventUsers
		.filter(
			(eventUser) =>
				eventUser.attendanceStatus !== AttendanceStatuses.Attended &&
				eventUser.attendanceStatus !== AttendanceStatuses.Unset
		)
		.map((eventUser, i: number) => (
			<UserRow key={i} event={event} eventUser={eventUser} />
		));

	const usersUnhandled = sortedEventUsers
		.filter(
			(eventUser) => eventUser.attendanceStatus === AttendanceStatuses.Unset
		)
		.map((eventUser, i: number) => (
			<UserRow key={i} event={event} eventUser={eventUser} />
		));

	if (eventUsers.length === 0 && !models.canEdit(event)) {
		return (
			<MissingEntitiesWrapper>
				<CardAnatomy>
					<Card.Body>
						<MissingEntities centered>
							<Icon name="library" />
							<p>{t('There are no participants for this event')}</p>
						</MissingEntities>
					</Card.Body>
				</CardAnatomy>
			</MissingEntitiesWrapper>
		);
	}

	const usersCount = [...usersAttended, ...usersDidNotAttend, ...usersUnhandled]
		.length;

	const renderedUsersUnhandled = usersUnhandled.length > 0 && (
		<Column spacing={styles.spacing._5}>
			<StatusLabel
				label={
					models.canEdit(event)
						? t('Requires action ({number})', {
								number: usersUnhandled.length,
							})
						: t('Unknown ({number})', {
								number: usersUnhandled.length,
							})
				}
				color={styles.palette.orange[400]}
			/>
			{usersUnhandled}
		</Column>
	);

	const sectionTitle =
		variant === 'organizers'
			? t('Organizers ({number})', {
					number: usersCount,
				})
			: t('Participants ({number})', {
					number: usersCount,
				});

	return (
		<Column spacing={styles.spacing._6}>
			<Column spacing={styles.spacing._2}>
				<Row columns="1fr auto" align="center" justifyContent="center">
					<SectionTitle>{sectionTitle}</SectionTitle>
					{models.canEdit(event) && (
						<Button
							inline
							icon="add"
							disabled={models.event.isCanceled(event)}
							label={t('Add')}
							onClick={toggleInviteModal}
						/>
					)}
				</Row>
			</Column>

			{models.canEdit(event) && renderedUsersUnhandled}

			{usersAttended.length > 0 && (
				<Column spacing={styles.spacing._5}>
					<StatusLabel
						color={styles.palette.green[400]}
						label={t('Attended ({number})', {
							number: usersAttended.length,
						})}
					/>
					{usersAttended}
				</Column>
			)}

			{usersDidNotAttend.length > 0 && (
				<Column spacing={styles.spacing._5}>
					<StatusLabel
						color={styles.palette.red[400]}
						label={t(`Didn't attend ({number})`, {
							number: usersDidNotAttend.length,
						})}
					/>
					{usersDidNotAttend}
				</Column>
			)}

			{!models.canEdit(event) && renderedUsersUnhandled}
		</Column>
	);
}
