import { JSX, Fragment, useEffect, useState } from 'react';
import { t } from '@transifex/native';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import {
	useCurrentAccountUserIds,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useCurrentRoute } from 'pkg/router/hooks';
import uuid from 'pkg/uuid';
import * as json from 'pkg/json';

import { LargeLayout, SmallLayoutInChat } from 'routes/chat/view';
import Initiate from 'routes/chat/view/Initiate';
import ChatList from 'routes/chat/view/ChatList';
import CurrentChat from 'routes/chat/view/CurrentChat';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import InlinePlaceholder from 'design/placeholders/inline';

const newChatId = uuid();

interface QueryUser {
	id: number;
	firstName: string;
	lastName: string;
}

interface NewChatProps {
	organizationId: number;
}

export default function NewChat({ organizationId }: NewChatProps): JSX.Element {
	const route = useCurrentRoute();
	const currentUser = useCurrentUser();
	const organization = useCurrentOrganization();
	const accountUserIds = useCurrentAccountUserIds();

	const queryUserData: QueryUser[] = json.parse(route.query?.userData ?? '');

	const queryUserIds = json
		.parse<string[]>(route.query?.userIds ?? '[]')
		.map((n: string) => Number.parseInt(n, 10))
		.filter((n: number) => n !== currentUser.id);

	const targetUserIds: number[] = queryUserIds ?? [];
	const isGroupChat = targetUserIds.length > 1;

	const [newChat, setNewChat] = useState<models.chat.Chat>({
		id: null,
		users: [],
		isArchived: false,
	} as models.chat.Chat);

	const chats = useCollection<models.chat.Chat>(
		endpoints.Chat.List1(organizationId)
	);

	useEffect(() => {
		if (route.query?.userIds === '' || route.query === '') {
			replaceState(routes.Chat.Index(organization.id));
			return;
		}

		if (targetUserIds.length === 1 && !chats.isLoading) {
			const chat = models.chat.findByMembers(
				chats.records,
				currentUser.id,
				targetUserIds[0]
			);

			const chatUser = models.chat.findMyChatUser(chat, accountUserIds);

			if (chat !== null) {
				if (chatUser.hasArchived) {
					models.chatUser.update(chat, chatUser, { hasArchived: false });
				}

				replaceState(routes.Chat.Show(organization.id, chat.id));

				return;
			}
		}

		const chatUsers = [];

		chatUsers.push({
			chatId: newChatId,
			isAdmin: true,
			hasMuted: false,
			lastReadMessageId: null,
			notifications: true,
			userId: currentUser.id,
			user: currentUser,
		});

		targetUserIds.map((userId: number) => {
			const user = queryUserData.find((d) => d.id === userId);

			chatUsers.push({
				chatId: newChatId,
				isAdmin: false,
				hasMuted: false,
				hasArchived: false,
				lastReadMessageId: null,
				notifications: true,
				userId,
				user,
				createdAt: Date.now(),
			});
		});

		const chat: models.chat.Chat = {
			id: newChatId,
			isNew: true,
			type: isGroupChat ? 'group' : 'personal',
			isArchived: false,
			rootGroupId: organizationId,
			users: chatUsers as models.chatUser.ChatUser[],
		};

		if (route.query.title) {
			chat.title = route.query.title;
		}

		if (newChat.id !== chat.id) {
			setNewChat(chat);
		}
	}, [chats.isLoading]);

	const chatList = [...chats.records, newChat];

	let targetChat = null;

	if (newChat.id) {
		targetChat = chatList.find((c) => c.id === newChatId);
	}

	return (
		<Fragment>
			<LargeScreen>
				<LargeLayout>
					<Initiate />
					<ChatList
						replaceState
						currentChatId={targetChat?.id}
						chatRecords={chatList}
						loading={chats.isLoading}
					/>
					{targetChat?.id ? (
						<CurrentChat autoFocus targetChat={targetChat} />
					) : (
						<InlinePlaceholder>
							<Icon name="add-conversation" />
							{t('Select a conversation!')}
						</InlinePlaceholder>
					)}
				</LargeLayout>
			</LargeScreen>
			<SmallScreen>
				{targetChat?.id && (
					<SmallLayoutInChat>
						<CurrentChat autoFocus targetChat={targetChat} />
					</SmallLayoutInChat>
				)}
			</SmallScreen>
		</Fragment>
	);
}
