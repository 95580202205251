import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import { useViewportFetchLimit } from 'pkg/hooks/library';
import * as strings from 'pkg/strings';
import * as models from 'pkg/api/models';

import {
	useAggregatedExercises,
	useAggregatedSessions,
	useAggregatedCollections,
	useTrainingLibraryFilters,
} from 'routes/training/library/hooks';
import Sessions from 'routes/training/library/group/Sessions';
import Exercises from 'routes/training/library/group/Exercises';
import Collections from 'routes/training/library/group/Collections';
import CurrentFilter from 'routes/training/library/CurrentFilter';

import { useContentTypeFilter } from 'components/library/hooks';
import EmptyState from 'components/library/TrainingEmptyState';
import { Spinner } from 'components/loaders/spinner';
import EmptySearch from 'components/library/EmptySearch';

export default function Browse(): JSX.Element {
	const contentType = useContentTypeFilter();
	const fetchLimit = useViewportFetchLimit();
	const filters = useTrainingLibraryFilters();

	const aggregatedSessions = useAggregatedSessions(fetchLimit);
	const aggregatedExercises = useAggregatedExercises(fetchLimit);
	const aggreagatedCollections = useAggregatedCollections();

	const isLoading =
		aggregatedSessions.isLoading &&
		aggregatedExercises.isLoading &&
		aggreagatedCollections.isLoading;

	const sessions = aggregatedSessions.records;
	const exercises = aggregatedExercises.records;

	let collections = aggreagatedCollections.records;

	// Hide collections if there isn't a title search
	if (filters.isFiltered && !filters.hasTitleFilter) {
		collections = [];
	}

	// Filter out collections here as they are not searched for in the backend
	if (filters.hasTitleFilter && collections.length > 0) {
		collections = collections.filter(
			(collection: models.trainingCollection.TrainingCollection) =>
				strings.localeIncludes(collection.name, filters.title)
		);
	}

	if (isLoading) {
		return <Spinner />;
	}

	const hasContent =
		!isLoading &&
		(sessions.length > 0 || exercises.length > 0 || collections.length > 0);

	let emptyState = null;

	if (!hasContent) {
		emptyState = <EmptyState />;

		if (filters.isFiltered) {
			emptyState = (
				<EmptySearch
					icon="search"
					heading={t(
						`Sorry, we couldn't find anything matching current filters…`
					)}
				/>
			);
		}
	}

	return (
		<Fragment>
			<CurrentFilter />
			{emptyState}

			{exercises.length > 0 && contentType.exercises && (
				<Exercises
					title={t('Exercises')}
					items={exercises}
					isLoading={aggregatedExercises.isLoading}
					hasNext={aggregatedExercises.pagination.hasNext}
					fetchNext={aggregatedExercises.pagination.fetchNext}
					replaceRecord={aggregatedExercises.replaceRecord}
					removeRecord={aggregatedExercises.removeRecord}
				/>
			)}

			{sessions.length > 0 && contentType.sessions && (
				<Sessions
					title={t('Sessions')}
					items={sessions}
					isLoading={aggregatedSessions.isLoading}
					hasNext={aggregatedSessions.pagination.hasNext}
					fetchNext={aggregatedSessions.pagination.fetchNext}
					replaceRecord={aggregatedSessions.replaceRecord}
					removeRecord={aggregatedSessions.removeRecord}
				/>
			)}

			{collections.length > 0 && contentType.collections && (
				<Collections
					title={t('Collections')}
					items={collections}
					isLoading={aggreagatedCollections.isLoading}
					hasNext={aggreagatedCollections.pagination.hasNext}
					fetchNext={aggreagatedCollections.pagination.fetchNext}
					replaceRecord={aggreagatedCollections.replaceRecord}
					removeRecord={aggreagatedCollections.removeRecord}
				/>
			)}
		</Fragment>
	);
}
