import { JSX, ReactNode, createContext, useContext, useState } from 'react';
import { useT } from '@transifex/react';
import { AppLauncher } from '@capacitor/app-launcher';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { resume } from 'pkg/events';
import { isApp, isIOS } from 'pkg/platform';

import InfoScreen from 'components/InfoScreen';

import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

export interface ApiConfig {
	socketUrl: string;
	adsEnabled: boolean;
	formsServiceUrl: string;
	maintenanceMode: boolean;
	updateRequired: boolean;
}

interface ApiConfigContextProps extends ApiConfig {
	refreshConfig: () => void;
}

const apiConfig: ApiConfig = {
	socketUrl: '',
	adsEnabled: true,
	formsServiceUrl: '',
	maintenanceMode: false,
	updateRequired: false,
};

const ApiConfigContext = createContext<ApiConfigContextProps>({
	...apiConfig,
	refreshConfig: () => null,
});

export function useApiConfig(): ApiConfigContextProps {
	const ctx = useContext(ApiConfigContext);
	return ctx;
}

interface ApiConfigProps {
	children: ReactNode;
}

export function ApiConfig({ children }: ApiConfigProps): JSX.Element {
	const t = useT();
	const [canOpenUrl, setCanOpenUrl] = useState(false);
	const url = isIOS()
		? 'https://apps.apple.com/se/app/360player/id1440982399'
		: 'market://details?id=com.threesixtyplayer.app';

	useComponentDidMount(async () => {
		if (isApp()) {
			const { value } = await AppLauncher.canOpenUrl({
				url,
			});

			setCanOpenUrl(value);
		}
	});

	const handleUpdate = async () =>
		await AppLauncher.openUrl({
			url,
		});

	const { record, isLoading, refresh, hasError } = useEndpoint<ApiConfig>(
		endpoints.Config.Index(),
		{},
		(cfg) => {
			window.TS.config = cfg;
		}
	);

	useComponentDidMount(
		() => {
			resume.on(refresh);
		},
		() => {
			resume.off(refresh);
		}
	);

	if (isLoading) {
		return <Spinner center />;
	}

	if (record.updateRequired) {
		return (
			<InfoScreen
				icon="sync"
				heading={t(`Update required`)}
				message={t(`You must update your app to continue using 360Player.`)}>
				{canOpenUrl && (
					<Button label={t('Download update')} onClick={handleUpdate} />
				)}
			</InfoScreen>
		);
	}

	if (hasError) {
		return (
			<InfoScreen
				icon="error"
				heading={t(`Something went wrong`)}
				message={t(`The appplication failed to load, please try again later.`)}
			/>
		);
	}

	return (
		<ApiConfigContext.Provider value={{ ...record, refreshConfig: refresh }}>
			{children}
		</ApiConfigContext.Provider>
	);
}
