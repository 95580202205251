import { JSX, ReactNode, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
interface ActionCellProps {
	className?: string;
	align?: 'left' | 'center' | 'right';
	verticalAlign?: 'start' | 'center' | 'end';
	children: ReactNode;
	onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
}

const Wrapper = styled.td<ActionCellProps>`
	justify-content: center;
	position: sticky;
	right: 0;
	gap: var(--spacing-4);
	align-content: ${({ verticalAlign }) => verticalAlign || 'center'};
	align-items: ${({ verticalAlign }) => verticalAlign || 'center'};

	${({ align }) =>
		align &&
		align === 'right' &&
		css`
			text-align: ${align};
			justify-content: flex-end;
		`};

	${({ align }) =>
		align &&
		align === 'center' &&
		css`
			text-align: ${align};
			justify-content: center;
		`};

	${({ onClick }) =>
		onClick &&
		css`
			&:hover {
				cursor: pointer;
			}
		`};
`;

function TD({
	className,
	align,
	verticalAlign,
	children,
	onClick,
}: ActionCellProps): JSX.Element {
	return (
		<Wrapper
			className={className}
			onClick={onClick}
			align={align}
			verticalAlign={verticalAlign}>
			{children}
		</Wrapper>
	);
}

export default styled(TD)<ActionCellProps>``;
