import { JSX, Fragment, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import PerformanceReview from 'pkg/models/performance_review';

import * as actions from 'pkg/actions';
import useConfirm from 'pkg/hooks/useConfirm';

import { FormattedContent } from 'components/formatted-content';
import CardBase, * as Card from 'components/Card';
import RelativeTime from 'components/RelativeDateTime';

import EditModal from 'components/user/profile/performance-review/EditModal';

import * as ContextMenu from 'design/context_menu';

const ReviewUpdated = styled.h4`
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-normal);
	color: var(--palette-gray-500);
`;

const TimeWrap = styled.div`
	display: flex;
	align-items: center;
	position: relative;

	${ReviewUpdated} {
		padding-right: var(--spacing-7);
	}

	/* This is used to position the DefaultToggle for the ContextMenu. */
	> div {
		right: -15px;
		top: 50%;
		transform: translateY(-50%);
	}
`;

type ReviewProps = {
	review: PerformanceReview;
	userId: number;
	groupId: number;
	forAccount?: number;
};

const Single = ({
	review,
	groupId,
	userId,
	forAccount,
}: ReviewProps): JSX.Element => {
	const dispatch = useDispatch();

	const [showModal, setShowModal] = useState(false);

	const toggleModal = (): void => {
		if (showModal) {
			setShowModal(false);
		} else {
			setShowModal(true);
		}
	};

	const deleteReview = useConfirm({
		message: t(`Are you sure you want to delete this review?`),
		onConfirm: async () => {
			dispatch(
				actions.performanceReview.deletePerformanceReview(review.id, forAccount)
			);
		},
	});

	const handleSave = async (text: string): Promise<void> => {
		await dispatch(
			actions.performanceReview.updatePerformanceReview(
				{
					id: review.id,
					performanceReview: text,
					groupId: groupId,
					userId: userId,
				},
				forAccount
			)
		);

		setShowModal(false);
	};

	const hideModal = (): void => {
		setShowModal(false);
	};

	return (
		<Fragment>
			<CardBase $noBorder>
				<Card.Header>
					<Card.Heading>
						<RelativeTime dateTime={review.createdAt} />
					</Card.Heading>
					<TimeWrap>
						<ReviewUpdated>
							{t(`Last updated`)} <RelativeTime dateTime={review?.updatedAt} />
						</ReviewUpdated>
					</TimeWrap>
					<ContextMenu.Menu
						toggleWith={<ContextMenu.ButtonTrigger icon="more_horiz" />}>
						<ContextMenu.Item onClick={toggleModal} icon="edit">
							{t('Edit')}
						</ContextMenu.Item>
						{review?.links.delete && (
							<ContextMenu.Item caution onClick={deleteReview} icon="delete">
								{t('Delete')}
							</ContextMenu.Item>
						)}
					</ContextMenu.Menu>
				</Card.Header>

				<Card.Divider />

				<Card.Body>
					<FormattedContent raw={review.performanceReview} />
				</Card.Body>
			</CardBase>
			{showModal && (
				<EditModal review={review} onClose={hideModal} onSave={handleSave} />
			)}
		</Fragment>
	);
};

export default Single;
