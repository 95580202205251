import styled, { css } from 'styled-components';

export interface FootCellProps {
	align?: 'start' | 'center' | 'end' | string;
	justify?: 'normal' | 'start' | 'center' | 'end' | string;
}

export default styled.td<FootCellProps>`
	display: flex;
	font-size: var(--font-size-sm);
	flex: 1;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	justify-content: flex-start;
	align-items: center;
	padding: var(--spacing-3) var(--spacing-4);
	font-weight: var(--font-weight-semibold);

	${({ align }) =>
		align &&
		css`
			align-items: ${align};
		`};

	${({ justify }) =>
		justify &&
		css`
			justify-content: ${justify};
		`};
`;
