import { JSX, Fragment } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useEventListener } from 'pkg/hooks/events';
import { useNumLibraryColumns, useViewportFetchLimit } from 'pkg/hooks/library';

import {
	useAggregatedCollections,
	useAggregatedPlaylists,
	useAggregatedVideos,
	useCurrentUserTaggedVideos,
} from 'routes/video/library/hooks';
import Videos from 'routes/video/library/group/Videos';
import Playlists from 'routes/video/library/group/Playlists';
import Collections from 'routes/video/library/group/Collections';

import { useContentTypeFilter } from 'components/library/hooks';
import EmptyState from 'components/library/VideoEmptyState';
import { Spinner } from 'components/loaders/spinner';

export default function Browse(): JSX.Element {
	const contentType = useContentTypeFilter();
	const fetchLimit = useViewportFetchLimit();
	const numColumns = useNumLibraryColumns();

	const isSmallScreen: boolean = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	const aggregatedVideos = useAggregatedVideos(fetchLimit);
	const aggregatedPlaylists = useAggregatedPlaylists(fetchLimit);
	const aggregatedCollections = useAggregatedCollections();

	useEventListener('video-collection-saved', () => {
		if (aggregatedCollections.refresh) {
			aggregatedCollections.refresh();
		}
	});

	// Only fetch one row
	const userTaggedVideos = useCurrentUserTaggedVideos(
		isSmallScreen ? 3 : numColumns - 1
	);

	const isLoading =
		aggregatedVideos.isLoading &&
		aggregatedPlaylists.isLoading &&
		aggregatedCollections.isLoading &&
		userTaggedVideos.isLoading;

	const videos = aggregatedVideos.records;
	const tagged = userTaggedVideos.records;
	const playlists = aggregatedPlaylists.records;
	const collections = aggregatedCollections.records;

	if (isLoading) {
		return <Spinner />;
	}

	const hasContent =
		!isLoading &&
		videos.length + tagged.length + playlists.length + collections.length > 0;

	return (
		<Fragment>
			{!hasContent && <EmptyState />}

			{tagged.length > 0 && contentType.videos && (
				<Videos
					title={t(`Videos you're tagged in`)}
					items={tagged}
					hasNext={userTaggedVideos.pagination.hasNext}
					fetchNext={userTaggedVideos.pagination.fetchNext}
				/>
			)}

			{videos.length > 0 && contentType.videos && (
				<Videos
					title={t(`Your team's library`)}
					items={videos}
					hasNext={aggregatedVideos.pagination.hasNext}
					fetchNext={aggregatedVideos.pagination.fetchNext}
					removeRecord={aggregatedVideos.removeRecord}
					replaceRecord={aggregatedVideos.replaceRecord}
				/>
			)}

			{playlists.length > 0 && contentType.playlists && (
				<Playlists
					title={t('Playlists')}
					items={playlists}
					hasNext={aggregatedPlaylists.pagination.hasNext}
					fetchNext={aggregatedPlaylists.pagination.fetchNext}
					removeRecord={aggregatedPlaylists.removeRecord}
					replaceRecord={aggregatedPlaylists.replaceRecord}
				/>
			)}

			{collections.length > 0 && contentType.collections && (
				<Collections
					title={t('Collections')}
					items={collections}
					hasNext={aggregatedCollections.pagination.hasNext}
					fetchNext={aggregatedCollections.pagination.fetchNext}
					removeRecord={aggregatedCollections.removeRecord}
					replaceRecord={aggregatedCollections.replaceRecord}
				/>
			)}
		</Fragment>
	);
}
