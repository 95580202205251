import { all_locales } from '@adyen/adyen-platform-experience-web';

const locales = Object.keys(all_locales);

function findLang(langArr: string[]) {
	const lang = navigator.language;

	return langArr.find((f) => f === lang || f.substring(0, 2) === lang || '');
}

export function getAdyenPlatformExperienceLocale() {
	return findLang(locales);
}

const transferInstrumentLanguages = [
	'bg-BG',
	'hr-HR',
	'cs-CZ',
	'da-DK',
	'nl-NL',
	'en-US',
	'et-EE',
	'fi-FI',
	'fr-FR',
	'de-DE',
	'el-GR',
	'hu-HU',
	'it-IT',
	'lv-LV',
	'lt-LT',
	'no-NO',
	'pl-PL',
	'pt-PT',
	'ro-RO',
	'sk-SK',
	'sl-SI',
	'es-ES',
	'sv-SE',
];

export function getTransferInstrumentLocale() {
	return findLang(transferInstrumentLanguages);
}
