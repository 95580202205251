import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';
import { setFieldAsRequired } from 'pkg/actions/user_fields';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import { cssClasses } from 'pkg/css/utils';
import * as models from 'pkg/api/models';
import { UserFieldType } from 'pkg/api/models/user_fields';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Label from 'components/label';
import Icon from 'components/icon';
import MaterialSymbol from 'components/material-symbols';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

const Field = ({
	item,
	custom,
	isReordering,
	dragElement,
	onReplace,
	onRefresh,
	onRemove,
	onEdit,
	isLast,
}: {
	item: models.userFields.UserField;
	isEditingSort?: boolean;
	custom?: boolean;
	isReordering?: boolean;
	dragElement?: any;
	onReplace: (record: models.userFields.UserField) => void;
	onEdit?: (record: models.userFields.UserField) => void;
	onRefresh?: () => void;
	onRemove?: (recordId: number) => void;
	isLast?: boolean;
}) => {
	const handleEdit = () => onEdit(item);

	const handleSetAsRequired = async () => {
		const field = await setFieldAsRequired(item);

		if (!item.id) {
			onRefresh();
		} else {
			onReplace(field);
			onRefresh();
		}
	};

	const handleSetAsNotRequired = async () => {
		const [ok, res] = await models.update<
			{ required: boolean },
			models.userFields.UserField
		>(item, {
			required: false,
		});

		if (ok) {
			onReplace(res);
			onRefresh();
		}
	};

	const handleDelete = async () => {
		const req = await sdk.destroy(endpoints.UserField.Delete(item.id), {}, {});

		if (req.ok) {
			onRemove(item.id);
			onRefresh();
		}
		if (!req.ok) {
			flashActions.show({ title: t('Something went wrong') }, req.status);
		}
	};

	return (
		<div
			className={cssClasses(
				css.wrapper,
				isReordering ? css.reordering : '',
				isLast ? css.isLast : ''
			)}>
			<div>
				<div
					className={cssClasses(
						css['left_col'],
						isReordering ? css['can_reorder'] : ''
					)}>
					{dragElement}
					<div className={css['icon_wrapper']}>
						<MaterialSymbol
							variant={
								fieldIcons[
									item.type === models.userFields.UserFieldType.Reserved
										? item.key
										: item.type
								]
							}
							scale={1.5}
							fill={styles.palette.gray[900]}
						/>
					</div>

					<div>
						<div>
							<div className={css.heading}>
								{item.label}
								{item.type !== UserFieldType.Reserved && (
									<span className={css.type}>
										({models.userFields.getTypeTranslation(item.type)})
									</span>
								)}
							</div>
							{item.description && (
								<div className={css.description}>{item.description}</div>
							)}
						</div>
						<SmallScreen>
							<div className={css.meta}>
								{item.visibility?.length > 0 && (
									<div className={cssClasses(css['visible'])}>
										<Icon size={1.2} name="eye-visible" />{' '}
										<span>{t('Visible')}</span>
									</div>
								)}
								{item.required && (
									<Label small color="gray">
										{t('Required')}
									</Label>
								)}
							</div>
						</SmallScreen>
					</div>
				</div>
			</div>
			<div className={css['right_col']}>
				<LargeScreen>
					{item.visibility?.length > 0 && (
						<div className={cssClasses(css['visible'])}>
							<Icon size={1.5} name="eye-visible" /> <span>{t('Visible')}</span>
						</div>
					)}
					{item.required && <Label color="gray">{t('Required')}</Label>}
				</LargeScreen>
				<ContextMenu.Menu
					toggleWith={<ContextMenu.ButtonTrigger icon="more_horiz" small />}>
					{custom && (
						<Fragment>
							<ContextMenu.Item onClick={handleEdit}>
								<ContextMenu.ItemIcon name="edit" />
								{t('Edit')}
							</ContextMenu.Item>
							<ContextMenu.Divider />
						</Fragment>
					)}
					<ContextMenu.Label>{t('Required')}</ContextMenu.Label>

					<ContextMenu.ControlItem
						onClick={handleSetAsRequired}
						type="radio"
						checked={item.required}>
						{t('Yes')}
					</ContextMenu.ControlItem>

					<ContextMenu.ControlItem
						onClick={handleSetAsNotRequired}
						type="radio"
						checked={!item.required}>
						{t('No')}
					</ContextMenu.ControlItem>

					{custom && (
						<Fragment>
							<ContextMenu.Divider />
							<ContextMenu.ConfirmItem
								caution
								message={t('Are you sure you want to delete this user field?')}
								onConfirm={() => handleDelete()}>
								<ContextMenu.ItemIcon name="delete" />
								{t('Delete')}
							</ContextMenu.ConfirmItem>
						</Fragment>
					)}
				</ContextMenu.Menu>
			</div>
		</div>
	);
};

const customFieldIcons: { [key: string]: MaterialSymbolVariant } = {
	[models.userFields.UserFieldType.ShortText]: 'text_fields',
	[models.userFields.UserFieldType.LongText]: 'text_fields',
	[models.userFields.UserFieldType.Number]: '123',
	[models.userFields.UserFieldType.Email]: 'alternate_email',
	[models.userFields.UserFieldType.Phone]: 'call',
	[models.userFields.UserFieldType.Choice]: 'radio_button_checked',
	[models.userFields.UserFieldType.MultiChoice]: 'check_box',
	[models.userFields.UserFieldType.Date]: 'event',
	[models.userFields.UserFieldType.Attachment]: 'attachment',
	[models.userFields.UserFieldType.Reserved]: 'lock',
};

const reservedFieldIcons: { [key: string]: MaterialSymbolVariant } = {
	[`user.${models.userFields.ReservedUserField.FirstName}`]: 'text_fields',
	[`user.${models.userFields.ReservedUserField.LastName}`]: 'text_fields',
	[`user.${models.userFields.ReservedUserField.BirthDate}`]: 'event',
	[`user.${models.userFields.ReservedUserField.Email}`]: 'alternate_email',
	[`user.${models.userFields.ReservedUserField.Sex}`]: 'radio_button_checked',
	[`user.${models.userFields.ReservedUserField.PhoneNumber}`]: 'call',
	[`user.${models.userFields.ReservedUserField.City}`]: 'location_city',
	[`user.${models.userFields.ReservedUserField.Country}`]: 'language',
	[`user.${models.userFields.ReservedUserField.PostalCode}`]: '123',
	[`user.${models.userFields.ReservedUserField.CompanyName}`]: 'text_fields',
	[`user.${models.userFields.ReservedUserField.Address}`]: 'location_on',
	[`user.${models.userFields.ReservedUserField.Region}`]: 'location_on',
	[`user.${models.userFields.ReservedUserField.Nationality}`]: 'language',
	[`user.${models.userFields.ReservedUserField.LokSwedishPersonalId}`]: '123',
};

export const fieldIcons: { [key: string]: MaterialSymbolVariant } = {
	...reservedFieldIcons,
	...customFieldIcons,
};

export default Field;
