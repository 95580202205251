import { JSX } from 'react';
import styled, { css, keyframes } from 'styled-components';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';

import Icon, { IconName } from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';

const PulseAnimation = keyframes`
	from {
		box-shadow: ${rgba(styles.palette.red[500], 0.6)} 0 0 0 0;
	}

	to {
		box-shadow: ${rgba(styles.palette.red[500], 0)} 0 0 0 10px;
	}
`;

interface WrapperProps {
	smallIcon: boolean;
	mediumIcon: boolean;
	largeIcon: boolean;
	isActive: boolean;
	isDisabled: boolean;
	recordButton: boolean;
}

const Wrapper = styled.div<WrapperProps>`
	padding: 0 var(--spacing-2);
	height: 50px;
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
	gap: var(--spacing-2);
	transition: all 150ms ease;
	color: ${rgba(styles.palette.white, 0.7)};
	cursor: pointer;

	@media (hover: hover) {
		&:hover {
			color: ${rgba(styles.palette.white)};
		}
	}

	&:active {
		transform: scale(0.9);
	}

	.${iconStyles.icon} {
		width: 32px;
		height: 32px;
	}

	${({ smallIcon }) =>
		smallIcon &&
		css`
			.${iconStyles.icon} {
				width: 18px;
				height: 18px;
			}
		`}

	${({ mediumIcon }) =>
		mediumIcon &&
		css`
			.${iconStyles.icon} {
				width: 24px;
				height: 24px;
			}
		`}

	${({ largeIcon }) =>
		largeIcon &&
		css`
			.${iconStyles.icon} {
				width: 42px;
				height: 42px;
			}
		`}


	${({ isDisabled }) =>
		isDisabled &&
		css`
			opacity: 0.35;
		`}

	${({ recordButton }) =>
		recordButton &&
		css`
			margin-right: var(--spacing-3);
			width: 30px;
			height: 30px;
			border-radius: 100%;
			background: var(--palette-red-500);
		`}

	${({ recordButton, isActive }) =>
		recordButton &&
		isActive &&
		css`
			animation: 1s ease ${PulseAnimation} infinite;
		`}
`;

interface ControlButtonProps {
	id?: string;
	icon?: IconName;
	label?: string;
	onClick?: () => void;
	smallIcon?: boolean;
	mediumIcon?: boolean;
	largeIcon?: boolean;
	isActive?: boolean;
	isDisabled?: boolean;
	recordButton?: boolean;
	testid?: string;
}

export default function ControlButton({
	id,
	icon,
	label,
	onClick,
	smallIcon,
	mediumIcon,
	largeIcon,
	isActive,
	isDisabled,
	recordButton,
	testid = 'video.controls.button',
}: ControlButtonProps): JSX.Element {
	return (
		<Wrapper
			id={id}
			onClick={onClick}
			isActive={!!isActive}
			isDisabled={!!isDisabled}
			smallIcon={!!smallIcon}
			mediumIcon={!!mediumIcon}
			largeIcon={!!largeIcon}
			recordButton={!!recordButton}
			data-testid={testid}>
			{icon && <Icon name={icon} />}
			{label}
		</Wrapper>
	);
}
