import { JSX } from 'react';
import { t } from '@transifex/native';

import FullPageMessage from 'components/layout/full-page-message';

interface EmailInviteSentStepProps {
	email: string;
}

export default function EmailInviteSentStep({
	email,
}: EmailInviteSentStepProps): JSX.Element {
	return (
		<FullPageMessage
			title={t('Connection request sent!')}
			message={t(
				'An email with instructions on how to connect has been sent to {email}.',
				{
					email,
				}
			)}
		/>
	);
}
