import { useSelector } from 'react-redux';
import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import { replaceState } from 'pkg/router/state';
import * as share from 'pkg/share';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import CurrentClip from 'routes/video/playlist/CurrentClip';
import ClipList from 'routes/video/playlist/ClipList';
import Controls from 'routes/video/shared/Controls';
import Timeline from 'routes/video/shared/Timeline';
import Source from 'routes/video/shared/Source';
import Layout, { LayoutContext } from 'routes/video/layouts/Layout';
import { TimelineContext } from 'routes/video/play/Timeline';
import Bootstrap from 'routes/video/playlist/Bootstrap';

import PlaylistModal from 'containers/video-analytics/modals/Playlist';

import { formatRelativeDateTimeString } from 'components/RelativeDateTime';
import { SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import EmptyState from 'components/video-analytics/playlist/EmptyState';
import CueState from 'components/video-analytics/CueState';
import ClipState from 'components/video-analytics/ClipState';
import CueObserver from 'components/video-analytics/CueObserver';
import { getCurrentFilterState } from 'components/video-analytics/FilterState';
import PlayerState from 'components/video-analytics/PlayerState';
import PlaybackState from 'components/video-analytics/PlaybackState';

import * as ContextMenu from 'design/context_menu';

interface PlaylistProps {
	playlistId: number;
}

export default function Playlist({ playlistId }: PlaylistProps): JSX.Element {
	const filters = getCurrentFilterState();
	const org = useCurrentOrganization();

	const [isEditing, setEditing] = useState<boolean>(false);

	useComponentDidMount(async () => {
		await actions.videoPlaylists.fetch(playlistId);
	});

	const openEditModal = () => setEditing(true);
	const closeEditModal = () => setEditing(false);

	const playlist = useSelector((state: RootState) =>
		selectors.videoPlaylists.find(state, playlistId)
	);

	const groupId = useCurrentGroup().id;

	if (!playlist.valid()) return null;

	const createdAt = formatRelativeDateTimeString(playlist.createdAt, true);

	const canEdit = playlist.hasIn(['links', 'edit']);

	if (playlist.sequences.length === 0) {
		const goToVideoLibrary = () =>
			replaceState(routes.VideoLibrary.Index(org.id, groupId));

		return <EmptyState location="playlist" onAction={goToVideoLibrary} />;
	}

	return (
		<Fragment>
			<ClipState>
				<PlayerState>
					<PlaybackState>
						<CueState>
							<CueObserver />
							<Layout
								context={LayoutContext.Playlist}
								title={playlist.title}
								subTitle={createdAt}
								bootstrap={<Bootstrap />}
								videoSource={<Source />}
								currentClip={<CurrentClip />}
								clipList={
									<ClipList playlistId={playlistId} filters={filters} />
								}
								controls={<Controls context="cue" />}
								timeline={
									<Timeline cueOnly context={TimelineContext.Playlist} />
								}
								contextItems={
									canEdit && (
										<Fragment>
											<ContextMenu.Item onClick={openEditModal}>
												<Icon name="edit" />
												{t('Edit')}
											</ContextMenu.Item>
											<SmallScreen>
												{share.hasShareApi() && (
													<ContextMenu.Item onClick={share.currentUrl}>
														<Icon name="share" />
														{t('Share')}
													</ContextMenu.Item>
												)}
											</SmallScreen>
										</Fragment>
									)
								}
							/>
						</CueState>
					</PlaybackState>
				</PlayerState>
			</ClipState>
			{isEditing && (
				<PlaylistModal playlistId={playlistId} onClose={closeEditModal} />
			)}
		</Fragment>
	);
}
