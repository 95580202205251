import React, { Fragment, ReactNode, JSX } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useQueryState } from 'pkg/hooks/query-state';
import { useCurrentRoute } from 'pkg/router/hooks';

import ClipGroupSelector from 'routes/video/analyze/ClipGroupSelector';
import InlineFilter from 'routes/video/shared/InlineFilter';
import LargeScreenAnnotationsForm from 'routes/video/analyze/LargeScreenAnnotationsForm';
import useClipRecording from 'routes/video/shared/hooks/use-clip-recording';
import RecordButton from 'routes/video/shared/RecordButton';

import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import { useNumAppliedFilters } from 'components/video-analytics/FilterState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import Column from 'components/layout/column';

import Button from 'design/button';

const Wrapper = styled.div`
	padding: var(--spacing-4);
	padding-top: 0;

	&[data-is-editing='true'] {
		padding: 0;
	}

	@media ${styles.breakpoint.small} {
		padding: 0;
	}
`;

const Layout = styled.div`
	display: grid;
	grid-auto-flow: row;
	row-gap: var(--spacing-4);
	color: var(--palette-white);
`;

const Container = styled.div`
	display: grid;
	grid-auto-flow: row;
	row-gap: var(--spacing-2);
`;

const EmptyContainer = styled.div`
	padding: var(--spacing-9) 0 var(--spacing-5) 0;
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-5);
	place-items: center;
	color: var(--palette-white);
	height: 100%;
`;

const FilterContainer = styled.div`
	padding: var(--spacing-3) 0 var(--spacing-2);
`;

const CallToAction = styled.div`
	padding-top: var(--spacing-6);
`;

const ActionsWrapper = styled.div`
	margin-bottom: var(--spacing-2);
`;

interface ListProps {
	numItems?: number;
	actions?: ReactNode | ReactNode[];
	children?: ReactNode | ReactNode[];
}

export default function List({
	numItems,
	actions,
	children,
}: ListProps): JSX.Element {
	const route = useCurrentRoute();
	const recording = useClipRecording();

	const queryState = useQueryState();
	const numFilters = useNumAppliedFilters();
	const { isEditing, isRecording } = usePlayerState();

	const isAnalyzeMode = route.name === 'video.analyze';
	const showGroupSelector = isAnalyzeMode && !isEditing;

	const handleResetFilters = () => queryState.flush();

	let items = (
		<Fragment>
			{showGroupSelector && <ClipGroupSelector />}
			<Container>{children}</Container>
		</Fragment>
	);

	if (actions) {
		items = (
			<Column>
				<ActionsWrapper>{actions}</ActionsWrapper>
				{items}
			</Column>
		);
	}

	let layout = <Layout>{!isEditing && items}</Layout>;

	if (isEditing) {
		layout = (
			<Layout>
				<LargeScreen>
					<LargeScreenAnnotationsForm />
				</LargeScreen>
				{showGroupSelector && <ClipGroupSelector />}
				{!isEditing && <Container>{children}</Container>}
			</Layout>
		);
	}

	if (!isRecording) {
		if (numFilters > 0 && numItems === 0) {
			layout = (
				<EmptyContainer>
					<Icon name="error" size={2} />
					{t('Applied filters returned zero clips')}

					<CallToAction>
						<Button transparent onClick={handleResetFilters}>
							{t('Clear all filters')}
						</Button>
					</CallToAction>
				</EmptyContainer>
			);
		} else if (numItems === 0) {
			layout = (
				<EmptyContainer>
					<Icon name="video-placeholder" size={2.5} />
					{t('No clips created yet')}

					{isAnalyzeMode && (
						<CallToAction>
							<RecordButton caution onClick={recording.start}>
								{t('Start recording')}
							</RecordButton>
						</CallToAction>
					)}
				</EmptyContainer>
			);
		}
	}

	return (
		<Wrapper data-is-editing={isEditing}>
			{!isEditing && (
				<FilterContainer>
					<InlineFilter />
				</FilterContainer>
			)}
			{layout}
		</Wrapper>
	);
}
