import { JSX } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import * as styles from 'pkg/config/styles';

import { useTimelineOffset } from 'routes/video/shared/hooks/timeline';

import Icon from 'components/icon';

import {
	useClipState,
	useKeyframes,
} from 'components/video-analytics/ClipState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';

const Wrapper = styled(motion.div)`
	background-color: var(--palette-gray-900);
	padding: var(--spacing-4);
	position: absolute;
	left: 50%;
	bottom: var(--spacing-5);
	transform: translateX(-50%);
	z-index: ${styles.zIndex.videoAnalytics + 15};
	display: grid;
	grid-auto-flow: column;
	gap: var(--spacing-5);
	place-items: center;
	box-shadow: inset 0 0 0 1px var(--palette-gray-600);
	border-radius: var(--radius-3);
`;

const Control = styled(Icon)`
	cursor: pointer;
	color: var(--palette-blue-400);

	@media (hover: hover) {
		&:hover {
			color: var(--palette-blue-100);
		}
	}
`;

export default function KeyframeControls(): JSX.Element {
	const timelineOffset = useTimelineOffset();
	const { controller } = usePlayerState();
	const playbackState = usePlaybackState();

	const { getActiveAnnotation } = useClipState();
	const activeAnnotation = getActiveAnnotation();
	const keyframes = useKeyframes();

	const seekTo = (ms: number) => {
		const time = ms / 1000;

		playbackState.setCurrentTime(time);
		controller.seekTo(time);
		timelineOffset.set(time);
	};

	const first = () => {
		if (keyframes.first) {
			seekTo(keyframes.first.time);
			keyframes.goFirst();
		}
	};

	const last = () => {
		if (keyframes.last) {
			seekTo(keyframes.last.time);
			keyframes.goLast();
		}
	};

	const prev = () => {
		if (keyframes.prev) {
			seekTo(keyframes.prev.time);
			keyframes.goPrev();
		}
	};

	const next = () => {
		if (keyframes.next) {
			seekTo(keyframes.next.time);
			keyframes.goNext();
		}
	};

	const enterAnimation = {
		opacity: 1,
		x: '-50%',
		y: '0%',
	};

	const leaveAnimation = {
		opacity: 0,
		x: '-50%',
		y: '100%',
	};

	return (
		<AnimatePresence>
			{activeAnnotation && (
				<Wrapper
					key="keyframeControls"
					initial={leaveAnimation}
					animate={enterAnimation}
					exit={leaveAnimation}>
					<Control name="av-jump-prev" size={1.6} onClick={first} />
					<Control name="av-step-prev" size={1.6} onClick={prev} />
					<Control name="av-step-next" size={1.6} onClick={next} />
					<Control name="av-jump-next" size={1.6} onClick={last} />
				</Wrapper>
			)}
		</AnimatePresence>
	);
}
