import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';

import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import { FilterOperator, createQueryFilter } from 'pkg/filters';
import { useCurrentOrganization } from 'pkg/identity';

import { DateSpanFooter, Widget } from 'routes/payments/overview/widgets';
import {
	useLocalizedCurrencyFormatter,
	useOrderReport,
} from 'routes/payments/overview/hooks';

import * as DataCard from 'design/data_card';
import * as Chart from 'design/chart';

export default function TotalNetWidget(): JSX.Element {
	const org = useCurrentOrganization();
	const { totalNetGraph, startOfRange, endOfRange } = useOrderReport();
	const fmt = useLocalizedCurrencyFormatter();

	const { paid, paidOrders, refunded, refundedOrders, totalSum } =
		totalNetGraph.totalNet;
	const { datePeriods, showAs } = totalNetGraph.graphData;

	const statusBreakdown: DataCard.BreakdownItem[] = [
		{
			title: t('Paid'),
			color: 'green',
			variant: 'label',
			value: fmt(paid),
			diff: paidOrders.toString(),
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Payments.Orders(org.id), {
						paid_at: createQueryFilter({
							property: 'paid_at',
							type: 'date',
							operator: FilterOperator.Between,
							values: [startOfRange, endOfRange],
						}),
					})
				),
		},
		{
			title: t('Refunds'),
			color: 'orange',
			variant: 'label',
			value: fmt(refunded, false),
			diff: refundedOrders.toString(),
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Refunds.Index(org.id), {
						created_at: createQueryFilter({
							property: 'created_at',
							type: 'date',
							operator: FilterOperator.Between,
							values: [startOfRange, endOfRange],
						}),
					})
				),
		},
	];

	const chartData: Chart.ChartItem[] = Object.entries(datePeriods).map(
		([date, item]) => ({
			title: date,
			value: item.currentPeriod,
		})
	);

	const tooltipFormatter = (item: Chart.ChartItem) => {
		const dateTime = new DateTime(new Date(item.title));
		const formattedDate =
			showAs === 'months'
				? dateTime.toLocaleDateString({ year: 'numeric', month: 'short' })
				: dateTime.toDateString();

		return (
			<Fragment>
				<strong>{fmt(item.value)}</strong>
				<span>{formattedDate}</span>
			</Fragment>
		);
	};

	return (
		<Widget>
			<DataCard.Base
				titleIcon="receipt_long"
				title={t('Total net (excl. fees)')}
				heading={fmt(totalSum)}
				footer={<DateSpanFooter granularity={showAs} />}>
				<DataCard.Breakdown items={statusBreakdown} />
				<Chart.Linear
					items={chartData}
					formatTooltip={tooltipFormatter}
					emptyStateLabel={t('No data for selected period')}
				/>
			</DataCard.Base>
		</Widget>
	);
}
