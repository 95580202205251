import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as Routes from 'pkg/router/routes';
import { formatPriceIntervalToText } from 'pkg/utils';
import * as actions from 'pkg/actions';
import { formatToCurrency } from 'pkg/i18n/formatters';
import * as models from 'pkg/api/models';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';
import * as CardList from 'components/card-list';

import StatusLabel from 'components/payment_platform/status/label';

import * as ContextMenu from 'design/context_menu';

interface PricingListItemProps {
	price: models.productPrice.ProductPrice;
	productId: number;
	refreshProduct: () => void;
}

const PricingListItem = ({
	price,
	productId,
	refreshProduct,
}: PricingListItemProps): JSX.Element => {
	const org = useCurrentOrganization();
	const handleArchive = async () => {
		const ok = await actions.prices.update(price.id, { archived: true });

		if (ok) {
			actions.flashes.show({
				title: t('Successfully archived price.'),
			});
			refreshProduct();
		}
	};

	const handleRestore = async () => {
		const ok = await actions.prices.update(price.id, { archived: false });

		if (ok) {
			actions.flashes.show({
				title: t('Successfully restored price.'),
			});
			refreshProduct();
		}
	};

	const group = useCurrentGroup();
	const interval = formatPriceIntervalToText(
		price.recurringInterval,
		price.recurringIntervalCount
	);
	const cost = formatToCurrency(price.cost / 100, group.currency);

	return (
		<CardList.Base noBorder>
			<CardList.IconWrapper>
				<Icon name="tag" />
			</CardList.IconWrapper>
			<CardList.Title>
				<div>
					{cost}/{interval}
				</div>
				<CardList.SubTitle>{price.title}</CardList.SubTitle>
			</CardList.Title>
			{price.archivedAt > 0 && (
				<CardList.Col>
					<StatusLabel
						status={{ name: 'archived', archivedAt: price.archivedAt }}
					/>
				</CardList.Col>
			)}
			<CardList.Actions>
				<ContextMenu.Menu
					toggleWith={
						<ContextMenu.ButtonTrigger>
							<Icon name="context-menu" />
						</ContextMenu.ButtonTrigger>
					}>
					{!price.archivedAt ? (
						<Fragment>
							<ContextMenu.LinkItem
								href={Routes.Product.Edit(org.id, productId)}>
								<Icon name="edit" />
								{t('Edit')}
							</ContextMenu.LinkItem>
							<ContextMenu.ConfirmItem
								caution
								message={t('Are you sure you want to archive this price?')}
								onConfirm={handleArchive}>
								<Icon name="archive" />
								{t('Archive')}
							</ContextMenu.ConfirmItem>
						</Fragment>
					) : (
						<ContextMenu.ConfirmItem
							message={t('Are you sure you want to restore this price?')}
							confirmLabel={t('Restore')}
							onConfirm={handleRestore}>
							<Icon name="add" />
							{t('Restore price')}
						</ContextMenu.ConfirmItem>
					)}
				</ContextMenu.Menu>
			</CardList.Actions>
		</CardList.Base>
	);
};

export default PricingListItem;
