import { JSX, Fragment, useMemo } from 'react';
import { t } from '@transifex/native';

import { eventTypeLabels } from 'pkg/models/event';

import DateTime from 'pkg/datetime';
import { isToday } from 'pkg/date';
import {
	useCurrentAccount,
	useCurrentGroup,
	useCurrentMembership,
	useCurrentUser,
} from 'pkg/identity';
import * as models from 'pkg/api/models';
import { useWardsInCurrentGroup } from 'pkg/hooks/useWardsInCurrentGroup';
import useMixedState from 'pkg/hooks/useMixedState';

import DisplayedDatesControls from 'routes/group/calendar/components/DisplayedDatesControls';
import CalendarFilters from 'routes/group/calendar/filters';
import { ViewOptions, useCalendarContext } from 'routes/group/calendar';
import SubscriptionDialog from 'routes/group/calendar/components/CalendarSubscriptionDialog';
import CalendarExport from 'routes/group/calendar/components/export';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';
import EventTypeContextMenuItem from 'components/event/TypeContextMenuItem';
import ScheduleDateSelect from 'components/calendar/schedule-date-select';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface CalendarActionBarProps {
	currentTab: ViewOptions;
	getStartAndEndDates: { from: number; to?: number };
	timestamp: number;
	sessionChangeDate: (timestamp: number) => void;
}

const CalendarActionBar = ({
	currentTab,
	getStartAndEndDates,
	timestamp,
	sessionChangeDate,
}: CalendarActionBarProps): JSX.Element => {
	const [subscriptionDialogProps, setSubscriptionDialogProps] = useMixedState({
		show: false,
		userIds: [],
		groupIds: [],
	});

	const calendarCtx = useCalendarContext();
	const scrollItemRef = calendarCtx.scrollItemRef;

	const selectedDateIsToday = isToday(
		new DateTime(calendarCtx.calendarState.scheduleDate)
	);

	const activeMembership = useCurrentMembership();
	const group = useCurrentGroup();
	const account = useCurrentAccount();
	const wards = useWardsInCurrentGroup();
	const currentUser = useCurrentUser();

	const setGroupModal = () =>
		setSubscriptionDialogProps({
			show: true,
			groupIds: [group.id],
		});

	const setMyModal = () =>
		setSubscriptionDialogProps({
			show: true,
			userIds: [currentUser.id],
		});

	const setChildModal = (userId: number) =>
		setSubscriptionDialogProps({
			show: true,
			userIds: [userId],
		});

	const closeModal = () =>
		setSubscriptionDialogProps({
			show: false,
			userIds: [],
			groupIds: [],
		});

	const todayDate = useMemo(() => {
		return new DateTime(new Date());
	}, []);

	const todayVisible = useMemo(() => {
		const dateObj = todayDate;
		switch (currentTab) {
			case 'year':
			case 'month':
			case 'week':
				return dateObj.isBetween(
					getStartAndEndDates.from * 1000,
					getStartAndEndDates.to * 1000
				);
			case 'schedule':
				return true;
			case 'day':
				return isToday(new DateTime(new Date(timestamp * 1000)));
		}
	}, [
		timestamp,
		currentTab,
		getStartAndEndDates.from,
		getStartAndEndDates.to,
		todayDate,
	]);

	const toggleToday = () => {
		if (currentTab === ViewOptions.Schedule) {
			scrollItemRef.current.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		} else {
			sessionChangeDate(todayDate.startOfDay / 1000);
		}
	};

	return (
		<Fragment>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					{currentTab !== ViewOptions.Schedule ? (
						<DisplayedDatesControls
							getStartAndEndDates={getStartAndEndDates}
							timestamp={timestamp}
							currentTab={currentTab}
							sessionChangeDate={sessionChangeDate}
							todayVisible={todayVisible}
							toggleToday={toggleToday}
						/>
					) : (
						<ScheduleDateSelect />
					)}
				</ActionBar.PrimaryAction>
				<Fragment>
					{(!todayVisible ||
						(currentTab === ViewOptions.Schedule &&
							calendarCtx.calendarState.showToday &&
							selectedDateIsToday)) && (
						<SmallScreen>
							<Button
								className={css.todayButton}
								label={t('Today')}
								onClick={toggleToday}
								inline
								noPadding
							/>
						</SmallScreen>
					)}
					<LargeScreen>
						<CalendarExport />
					</LargeScreen>
					<CalendarFilters
						setGroupModal={setGroupModal}
						setMyModal={setMyModal}
						setChildModal={setChildModal}
					/>
					<LargeScreen>
						<ContextMenu.Menu
							toggleWith={
								<Button
									icon="ios_share"
									iconPosition="left"
									label={t('Share')}
									testid="calendar.actionbar.share"
								/>
							}>
							<ContextMenu.Item
								onClick={setGroupModal}
								testid="events.subscribe.group_calendar">
								{group.name}
							</ContextMenu.Item>
							<ContextMenu.Item onClick={setMyModal}>
								{models.account.fullName(account)}
							</ContextMenu.Item>
							{wards.map((w) => (
								<ContextMenu.Item
									key={w.id}
									onClick={() => setChildModal(w.id)}>
									{models.user.fullName(w)}
								</ContextMenu.Item>
							))}
						</ContextMenu.Menu>
						{models.membership.isAdminOrStaff(activeMembership) && (
							<ContextMenu.Menu
								toggleWith={
									<Button
										icon="add"
										iconPosition="left"
										primary
										label={t('Create')}
										testid="calendar.create_new_button"
									/>
								}>
								{eventTypeLabels.map((eventType) => (
									<EventTypeContextMenuItem
										eventType={eventType}
										key={eventType}
										startsAt={
											currentTab !== ViewOptions.Schedule &&
											(timestamp + 32400).toString()
										}
										endsAt={
											currentTab !== ViewOptions.Schedule &&
											(timestamp + 36000).toString()
										}
									/>
								))}
							</ContextMenu.Menu>
						)}
					</LargeScreen>
				</Fragment>
			</ActionBar.FilterBar>
			{subscriptionDialogProps.show && (
				<SubscriptionDialog
					userIds={subscriptionDialogProps.userIds}
					groupIds={subscriptionDialogProps.groupIds}
					onClose={closeModal}
				/>
			)}
		</Fragment>
	);
};

export default CalendarActionBar;
