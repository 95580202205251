import { JSX, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ToolProps } from 'components/annotations/tools/ToolProps';
import { generatePath } from 'components/annotations/AnnotationHooks';
import { MULTIPOINT } from 'components/annotations/constants';

const squigglify = (
	followPath: any,
	squigglePath: any,
	squiggleStep: any,
	squiggleAmplitude: any
) => {
	const pathLen = followPath.getTotalLength();

	// Adjust step so that there are a whole number of steps along the path
	const numSteps = Math.round(pathLen / squiggleStep);

	let pos = followPath.getPointAtLength(0);
	let newPath = 'M' + [pos.x, pos.y].join(',');
	let side = -1;
	for (let i = 1; i <= numSteps; i++) {
		const last = pos;
		pos = followPath.getPointAtLength((i * pathLen) / numSteps);

		// Find a point halfway between last and pos. Then find the point that is
		// perpendicular to that line segment, and is squiggleAmplitude away from
		// it on the side of the line designated by 'side' (-1 or +1).
		// This point will be the control point of the quadratic curve forming the
		// squiggle step.

		// The vector from the last point to this one
		const vector = { x: pos.x - last.x, y: pos.y - last.y };
		// The length of this vector
		const vectorLen = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
		// The point halfwasy between last point and tis one
		const half = { x: last.x + vector.x / 2, y: last.y + vector.y / 2 };
		// The vector that is perpendicular to 'vector'
		const perpVector = {
			x: -((squiggleAmplitude * vector.y) / vectorLen),
			y: (squiggleAmplitude * vector.x) / vectorLen,
		};
		// No calculate the control point position
		const controlPoint = {
			x: half.x + perpVector.x * side,
			y: half.y + perpVector.y * side,
		};
		newPath += 'Q' + [controlPoint.x, controlPoint.y, pos.x, pos.y].join(',');
		// Switch the side (for next step)
		side = -side;
	}
	squigglePath.setAttribute('d', newPath);
};

const SVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export default function Squiggly({
	// annotationId,
	annotation,
	config,
	multipointRef,
}: ToolProps): JSX.Element {
	const points = annotation.keyframes[0].points;
	const d = generatePath(points);
	const squiggle = useRef();

	const strokeWidth =
		annotation.strokeWidth || config.preferences.strokeWidth.defaultValue;

	// get path, update squiggle
	const currentPath = multipointRef.current?.getAttribute('d');
	useEffect(() => {
		squigglify(multipointRef.current, squiggle.current, 5, 2);
	}, [currentPath]);

	return (
		<SVG
			viewBox="0 0 100 100"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/SVG">
			<path
				ref={multipointRef}
				d={d}
				stroke="none"
				fill="none"
				className={MULTIPOINT}
			/>
			<path
				ref={squiggle}
				d="M0,0"
				stroke={annotation.color || config.preferences.color.defaultValue}
				strokeWidth={strokeWidth}
				strokeLinecap="round"
				strokeLinejoin="round"
				vectorEffect="non-scaling-stroke"
				fill="none"
			/>
		</SVG>
	);
}
