import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';

import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { LineItem, PaymentPeriodInfo } from 'pkg/api/models/payment_preview';
import { RecurringInterval } from 'pkg/api/models/provider_settings';

import { getIntervalText } from 'routes/forms/registration/form/products/summary';

import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface BillingSummaryProps {
	currency: string;
	serviceFee: number;

	recurringDiscountedPayment?: PaymentPeriodInfo;
	recurringPayment?: PaymentPeriodInfo;
	monthlyPayment?: number;
	recurringInterval?: RecurringInterval;
	recurringIntervalCount?: number;
	installmentCount?: number;
	discount?: models.discount.Discount;
	previewData: models.paymentPreview.PaymentPreview;
}

const getServiceFeeAndTaxText = (
	lineItems: LineItem[],
	groupHasServiceFee: boolean
) => {
	const hasTaxRate = !!lineItems?.find((item) => item.taxRate);

	if (hasTaxRate && !groupHasServiceFee) {
		return t('incl. tax');
	}

	if (!hasTaxRate && groupHasServiceFee) {
		return t('incl. service fee');
	}

	if (hasTaxRate && groupHasServiceFee) {
		return t('incl. tax & service fee');
	}

	return '';
};

export default function BillingSummary({
	currency,
	serviceFee,
	discount,
	previewData,
	recurringIntervalCount,
}: BillingSummaryProps): JSX.Element {
	const intervalMapping: { [key in RecurringInterval]: string } = {
		[RecurringInterval.Day]: t('Daily payment: '),
		[RecurringInterval.Week]: t('Weekly payment: '),
		[RecurringInterval.Month]: t('Monthly payment: '),
		[RecurringInterval.Year]: t('Yearly payment: '),
	};

	const isSubscription =
		previewData?.installmentCount != 0 && !previewData?.totalInstallmentAmount;

	const discountedRecurringSummary = (recurringInterval: RecurringInterval) => (
		<Row align="stretch">
			<div>
				<div className={css.dueToday}>{intervalMapping[recurringInterval]}</div>
				<div className={css.infoGrey}>
					{t('For {installmentCount} payments', {
						installmentCount:
							previewData?.recurringDiscountedPayment.recurringCount,
					})}
				</div>
			</div>
			<div>
				<div className={css.summaryAmountContainer}>
					<div className={css.currentPrice}>
						{formatToCurrency(
							previewData?.recurringDiscountedPayment?.salePrice / 100,
							currency
						)}
					</div>
					<div className={css.originalPrice}>
						{previewData?.recurringDiscountedPayment?.originalSalePrice !==
							null &&
							formatToCurrency(
								previewData?.recurringDiscountedPayment?.originalSalePrice /
									100,
								currency
							)}
					</div>
				</div>
				<div className={css.taxInfo}>
					{getServiceFeeAndTaxText(
						previewData.recurringDiscountedPayment.lineItems,
						!!serviceFee
					)}
				</div>
			</div>
		</Row>
	);

	const recurringSummary = (recurringInterval: RecurringInterval) => (
		<Fragment>
			<Row align="stretch">
				<div>
					<div className={css.dueToday}>
						{t('{interval} payment:', {
							interval: getIntervalText(
								recurringInterval,
								recurringIntervalCount
							),
						})}
					</div>
					{!isSubscription && (
						<div className={css.infoGrey}>
							{t('For {installmentCount} payments', {
								installmentCount: previewData.recurringPayment.recurringCount,
							})}
						</div>
					)}
				</div>
				<div>
					<div className={css.summaryAmountContainer}>
						<div className={css.currentPrice}>
							{formatToCurrency(
								previewData.recurringPayment?.salePrice / 100,
								currency
							)}
						</div>
						{discount && (
							<div className={css.originalPrice}>
								{previewData.recurringPayment?.originalSalePrice !== null &&
									formatToCurrency(
										previewData.recurringPayment?.originalSalePrice / 100,
										currency
									)}
							</div>
						)}
					</div>
					<div className={css.taxInfo}>
						{getServiceFeeAndTaxText(
							previewData.recurringPayment.lineItems,
							!!serviceFee
						)}
					</div>
				</div>
			</Row>
			{!isSubscription && (
				<Fragment>
					<hr className={css.divider} />
					<Row align="stretch">
						<div>
							<div className={css.dueToday}>{t('Plan total: ')}</div>
							<div className={css.infoGrey}>
								{t('For {installmentCount} payments', {
									installmentCount: previewData?.installmentCount,
								})}
							</div>
						</div>
						<div>
							<div className={css.summaryAmount}>
								{formatToCurrency(
									previewData?.totalInstallmentAmount / 100,
									currency
								)}
							</div>
							<div className={css.taxInfo}>
								{getServiceFeeAndTaxText(
									[
										...(previewData?.initialPayment.lineItems || []),
										...(previewData.recurringPayment.lineItems || []),
									],
									!!serviceFee
								)}
							</div>
						</div>
					</Row>
				</Fragment>
			)}
		</Fragment>
	);

	return (
		<Column>
			<Row align="stretch">
				<div className={css.dueToday}>{t('Due today:')}</div>
				<div>
					<div className={css.summaryAmountContainer}>
						<div className={css.currentPrice}>
							{formatToCurrency(
								previewData?.initialPayment?.salePrice / 100,
								currency
							)}
						</div>
						{discount && (
							<div className={css.originalPrice}>
								{previewData?.initialPayment?.originalSalePrice !== null &&
									formatToCurrency(
										previewData?.initialPayment?.originalSalePrice / 100,
										currency
									)}
							</div>
						)}
					</div>
					<div className={css.taxInfo}>
						{getServiceFeeAndTaxText(
							previewData?.initialPayment.lineItems,
							!!serviceFee
						)}
					</div>
				</div>
			</Row>
			{previewData?.recurringDiscountedPayment?.originalSalePrice &&
				discountedRecurringSummary(previewData?.recurringInterval)}

			{previewData?.recurringInterval &&
				recurringSummary(previewData?.recurringInterval)}
		</Column>
	);
}
