import Intercom, { onHide, onShow } from '@intercom/messenger-js-sdk';

import { Flag, useFlag } from 'pkg/flags';
import {
	useCurrentAccount,
	useCurrentAccountWards,
	useCurrentMembership,
} from 'pkg/identity';
import * as models from 'pkg/api/models';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { isWeb } from 'pkg/platform';

import { useSmallScreen } from 'components/MediaQuery';

export let isIntercomInitialized = false;

function StartIntercom(): JSX.Element {
	const account = useCurrentAccount();
	const accountWards = useCurrentAccountWards();
	const membership = useCurrentMembership();
	const intercomEnabled = useFlag<boolean>(Flag.Intercom);
	const isSmallScreen = useSmallScreen();

	useComponentDidMount(() => {
		if (intercomEnabled) {
			Intercom({
				app_id: 'tgg7zrd1', // todo: don't hard code
				user_id: account.trackingHash,
				email: account.email,
				name: models.account.fullName(account),
				Role: models.membership.roleString(membership),
				Capabilities: (membership.capabilities || []).join(', '),
				IsParent: accountWards.length > 0,
				hide_default_launcher: true,
			});
			if (isWeb() && isSmallScreen) {
				//overwrite the height value (0 !important) in "intercom-mobile-messenger-active"-class
				onShow(() => {
					document.body.style.cssText += 'height: 100% !important';
				});

				onHide(() => {
					document.body.style.height = '';
				});
			}

			isIntercomInitialized = true;
		}
	});

	return null;
}

export default StartIntercom;
