// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ezmrj9Bng_I8jPeoKrve {
	padding: var(--spacing-8);
	background-color: var(--palette-gray-100);
	border-radius: var(--radius-5);
}

.RKVvJO2n_moMre7c8ups {
	justify-self: end;
	cursor: pointer;
}

.dOqSpgz7xPeZidmchZwG {
	color: var(--palette-gray-600);
	font-size: var(--font-size-sm);
}

.ArjdcgmCin2ZVQ98EzjZ {
	margin-bottom: var(--spacing-3);
}
`, "",{"version":3,"sources":["webpack://./components/group/join-modal/create-ward/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,yCAAyC;CACzC,8BAA8B;AAC/B;;AAEA;CACC,iBAAiB;CACjB,eAAe;AAChB;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,+BAA+B;AAChC","sourcesContent":[".body {\n\tpadding: var(--spacing-8);\n\tbackground-color: var(--palette-gray-100);\n\tborder-radius: var(--radius-5);\n}\n\n.close {\n\tjustify-self: end;\n\tcursor: pointer;\n}\n\n.terms {\n\tcolor: var(--palette-gray-600);\n\tfont-size: var(--font-size-sm);\n}\n\n.collectEmail {\n\tmargin-bottom: var(--spacing-3);\n}\n"],"sourceRoot":""}]);
// Exports
export var body = `Ezmrj9Bng_I8jPeoKrve`;
export var close = `RKVvJO2n_moMre7c8ups`;
export var terms = `dOqSpgz7xPeZidmchZwG`;
export var collectEmail = `ArjdcgmCin2ZVQ98EzjZ`;
export default ___CSS_LOADER_EXPORT___;
