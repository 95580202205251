import { JSX } from 'react';

import * as styles from 'pkg/config/styles';

import EventReport from 'pkg/models/event_report';
import User from 'pkg/models/user';
import { eventTypeLabels } from 'pkg/models/event';

import { pushState } from 'pkg/router/state';
import { QueryObject } from 'pkg/hooks/query-state';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';
import Label from 'components/label';

import * as Table from 'design/table';

interface AttendanceReportRow {
	eventReport: EventReport;
	eventTypes: string[];
	groupId: number;
	query: QueryObject;
	user: User;
	showLOK: boolean;
}

export default function AttendanceReportRow({
	eventReport,
	eventTypes,
	groupId,
	query,
	user,
	showLOK,
}: AttendanceReportRow): JSX.Element {
	const renderedEventTypes = eventTypeLabels
		.filter((eventType: string) => eventTypes.includes(eventType))
		.map((eventType: string) => (
			<Table.Cell
				align="right"
				key={eventType}
				value={eventReport.eventType[eventType]}
				slug={eventType}>
				{eventReport.eventType[eventType]}
			</Table.Cell>
		));

	const numEvents: number =
		eventReport.total + eventReport.absent + eventReport.attendance.unset;

	const attendedPercentage = Math.round((eventReport.total / numEvents) * 100);
	const absentPercentage = Math.round((eventReport.absent / numEvents) * 100);
	const unhandledPercentage = Math.round(
		(eventReport.attendance.unset / numEvents) * 100
	);

	const org = useCurrentOrganization();

	const handleRowClick = () => {
		pushState(
			routes.Statistics.AttendanceReport.User(
				org.id,
				groupId,
				eventReport.userId
			),
			query
		);
	};

	return (
		<Table.Row onClick={handleRowClick}>
			<Table.Cell>{user.fullName}</Table.Cell>
			<Table.Cell align="right" value={numEvents} slug="numEvents">
				<Label color="gray">{numEvents}</Label>
			</Table.Cell>
			{renderedEventTypes}
			<Table.Cell align="right" value={eventReport.total} slug="attended">
				<Label color="green">
					{eventReport.total} ({attendedPercentage}%)
				</Label>
			</Table.Cell>
			<Table.Cell
				align="right"
				value={eventReport.attendance.injured}
				slug="injured">
				{eventReport.attendance.injured}
			</Table.Cell>
			<Table.Cell
				align="right"
				value={eventReport.attendance.sick}
				slug="illness">
				{eventReport.attendance.sick}
			</Table.Cell>
			<Table.Cell
				align="right"
				value={eventReport.attendance.other}
				slug="other">
				{eventReport.attendance.other}
			</Table.Cell>
			<Table.Cell align="right" value={eventReport.absent} slug="absent">
				<Label color="red">
					{eventReport.absent} ({absentPercentage}%)
				</Label>
			</Table.Cell>
			<Table.Cell
				align="right"
				value={eventReport.attendance.unset}
				slug="unhandled">
				<Label color="orange">
					{eventReport.attendance.unset} ({unhandledPercentage}%)
				</Label>
			</Table.Cell>
			{showLOK && (
				<Table.Cell align="center">
					<Icon
						size={1.8}
						fill={
							eventReport.isValidForLok
								? styles.palette.green[500]
								: styles.palette.red[500]
						}
						name={eventReport.isValidForLok ? 'check-circle' : 'close-circle'}
					/>
				</Table.Cell>
			)}
		</Table.Row>
	);
}
