import {
	JSX,
	createContext,
	Fragment,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import { toMedium } from 'pkg/config/breakpoints';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import DateTime from 'pkg/datetime';
import { getFirstDayOfWeek, getLastDayOfWeek, getWeekDates } from 'pkg/date';
import { useQueryState } from 'pkg/hooks/query-state';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCollection } from 'pkg/api/use_collection';
import { cssVarList } from 'pkg/css/utils';
import { useCurrentGroup } from 'pkg/identity';
import * as json from 'pkg/json';

import GroupGrid from 'routes/scheduling/bookings/groups/Grid';
import ReplaceTemplateModal from 'routes/scheduling/bookings/modals/ReplaceTemplate';
import CreateBookingModal from 'routes/scheduling/bookings/modals/CreateBooking';
import BookingsActionBar from 'routes/scheduling/bookings/ActionBar';
import PublishedPeriods from 'routes/scheduling/bookings/published_periods';
import ResourceGrid from 'routes/scheduling/bookings/resources/Grid';
import {
	defaultFilters,
	FilterState,
} from 'routes/scheduling/templates/filters';
import { ConflictingItems, resourceConflictMap } from 'routes/scheduling/utils';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import WeekDay from 'components/scheduling/WeekDay';
import * as SchedulingLayout from 'components/scheduling/PageLayout';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import { Spinner } from 'components/loaders/spinner';
import { TemplateWeekWrapper } from 'components/scheduling/TemplateWeekWrapper';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import SelectView, { SchedulingViews } from 'components/scheduling/SelectView';
import * as headerRowCss from 'components/scheduling/grid/header-row/styles.css';

interface IBookingsContext {
	groupId: number;

	weekDates: Date[];
	filters: FilterState;
	conflicts: ConflictingItems;
	search?: string;
	isLoading: boolean;
	isGroupView: boolean;

	view: SchedulingViews;
	calendarView?: boolean;
	isSmallScreen: boolean;
	timestampDate: Date;
	startDate: DateTime;
	endDate: DateTime;

	groups: models.group.Group[];
	flattenedGroups: models.group.Group[];
	resources?: models.resource.Resource[];
	bookings: models.booking.Booking[];
	schedules: models.schedule.Schedule[];
	publishedPeriods: models.schedule.PublishedPeriod[];

	refreshBookings?: () => Promise<void>;
	refreshPublishedPeriods: () => Promise<void>;
}

export const DefaultBookingsContext: IBookingsContext = {
	refreshBookings: null,
	isSmallScreen: false,
	isGroupView: true,
	groups: [],
	flattenedGroups: [],
	resources: [],
	bookings: [],
	calendarView: false,
	weekDates: [],
	groupId: null,
	view: SchedulingViews.Group,
	filters: defaultFilters,
	conflicts: {},
	schedules: [],
	isLoading: false,
	timestampDate: new Date(),
	startDate: DateTime.now(),
	endDate: DateTime.now(),
	publishedPeriods: [],
	refreshPublishedPeriods: null,
};

export const BookingsContext = createContext<IBookingsContext>(
	DefaultBookingsContext
);

export function useBookingsContext(): IBookingsContext {
	return useContext(BookingsContext);
}

interface BookingsProps {
	groupId: number;
}

const Bookings = ({ groupId }: BookingsProps) => {
	const [modal, setModal] = useState('');

	const handleShowBookingModal = () => setModal('booking');
	const hideModal = () => setModal('');

	return (
		<BookingsProvider groupId={groupId}>
			<LargeScreen>
				<LargeScreenHeader title={t('Bookings')} icon="schedule" />
				<LargeScreenContent.Wrapper disableScroll>
					<SchedulingLayout.ContentWrapper>
						<BookingsCalendar handleShowBookingModal={handleShowBookingModal} />
					</SchedulingLayout.ContentWrapper>
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenHeader title={t('Bookings')} />
				<SmallScreenContent noScroll>
					<BookingsCalendar handleShowBookingModal={handleShowBookingModal} />
				</SmallScreenContent>
			</SmallScreen>
			{modal === 'replace' && (
				<ReplaceTemplateModal hideModal={hideModal} groupId={groupId} />
			)}
			{modal === 'booking' && (
				<CreateBookingModal groupId={groupId} hideModal={hideModal} />
			)}
		</BookingsProvider>
	);
};

interface BookingsCalendarProps {
	handleShowBookingModal: () => void;
}

function BookingsCalendar({
	handleShowBookingModal,
}: BookingsCalendarProps): JSX.Element {
	const { weekDates, groups, bookings, resources, timestampDate, isGroupView } =
		useBookingsContext();

	return (
		<Fragment>
			<BookingsActionBar
				timestampDate={timestampDate}
				groups={groups}
				bookings={bookings}
				resources={resources}
				handleShowBookingModal={handleShowBookingModal}
			/>
			<SchedulingLayout.Container>
				<SchedulingLayout.Wrapper>
					<TemplateWeekWrapper>
						<PublishedPeriods />
						<div
							className={headerRowCss.wrapper}
							style={cssVarList({
								cols: 7,
							})}>
							<LargeScreen>
								<div className={headerRowCss.day}>
									<SelectView />
								</div>
							</LargeScreen>
							{weekDates.map((wd, i) => (
								<WeekDay key={i} day={wd} />
							))}
						</div>
					</TemplateWeekWrapper>
					{isGroupView ? <GroupGrid /> : <ResourceGrid />}
				</SchedulingLayout.Wrapper>
			</SchedulingLayout.Container>
		</Fragment>
	);
}

export default Bookings;

interface BookingsProviderProps {
	groupId: number;
	children?: ReactNode | ReactNode[];
}

export function BookingsProvider({
	groupId,
	children,
}: BookingsProviderProps): JSX.Element {
	const qs = useQueryState();
	const group = useCurrentGroup();

	let timestamp: number;

	const tsFromQuery: number = Number.parseInt(
		qs.get<string>('timestamp', '0'),
		10
	);

	const tsFromStorage: number = Number.parseInt(
		sessionStorage.getItem('calendarTimestamp') ?? '0',
		10
	);

	if (tsFromQuery > 0) {
		timestamp = tsFromQuery;
	} else if (tsFromStorage > 0) {
		timestamp = tsFromStorage;
	} else {
		timestamp = Math.round(new DateTime(new Date()).startOfDay / 1000);
	}

	const timestampDate = new Date(timestamp * 1000);
	const weekDates = useMemo(() => getWeekDates(timestamp * 1000), [timestamp]);
	const startDate = new DateTime(getFirstDayOfWeek(timestampDate));
	const endDate = new DateTime(getLastDayOfWeek(timestampDate));

	const startOfWeek = new DateTime(weekDates[0]);
	const endOfWeek = new DateTime(weekDates[6]);
	const startOfWeekTimestamp = Math.round(startOfWeek.startOfDay / 1000);
	const endOfWeekTimestamp = Math.floor(endOfWeek.endOfDay / 1000);

	const view: SchedulingViews =
		qs.get<SchedulingViews>('view') ||
		(sessionStorage.getItem('schedulingView') as SchedulingViews);

	const qsFilters = qs.get('schedulingFilters', '').toString();
	const search = qs.get('search', '').toString().toLowerCase();
	const filters = json.parse<FilterState>(qsFilters) || defaultFilters;

	const isGroupView = view === SchedulingViews.Group;
	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });

	useComponentDidMount(() => {
		qs.set('timestamp', timestamp);

		if (view) {
			qs.set('view', view);
		}

		qs.commit();
	});

	useEffect(() => {
		sessionStorage.setItem('calendarTimestamp', timestamp.toString());
	}, [timestamp]);

	const bookingsCollection = useCollection<models.booking.Booking>(
		endpoints.Booking.Index(),
		{
			queryParams: new URLSearchParams({
				by_group_id: groupId.toString(),
				from: Math.round(startDate.startOfDay / 1000).toString(),
				to: Math.floor(endDate.endOfDay / 1000).toString(),
			}),
		}
	);

	const { records: resources, isLoading: resourcesLoading } =
		useCollection<models.resource.Resource>(endpoints.Resource.Index(), {
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
			}),
		});

	const { records: resourceCategories, isLoading: categoriesLoading } =
		useCollection<models.resourceCategory.ResourceCategory>(
			endpoints.ResourceCategory.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
				}),
			}
		);

	const { records: schedules, isLoading: schedulesLoading } =
		useCollection<models.schedule.Schedule>(endpoints.Schedule.Index(), {
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
			}),
		});

	const { records: publishedPeriods, refresh: refreshPublishedPeriods } =
		useCollection<models.schedule.PublishedPeriod>(
			endpoints.Schedule.PublishedPeriods(),
			{
				queryParams: new URLSearchParams({
					group_id: groupId.toString(),
					from: startOfWeekTimestamp.toString(),
					to: endOfWeekTimestamp.toString(),
				}),
			}
		);

	const sortedResources = models.resource.buildResourceTree(
		resources,
		resourceCategories,
		true
	);

	const conflictMap = useMemo(() => {
		return resourceConflictMap(sortedResources);
	}, [sortedResources.length]);

	const conflicts = models.booking.getConflictingItems(
		bookingsCollection.records,
		conflictMap
	);

	const groupCollection = useCollection<models.group.Group>(
		endpoints.Groups.ShowChildren(groupId),
		{
			queryParams: new URLSearchParams({
				recursive: 'true',
			}),
		}
	);

	// The flattned groups is used in the grid to display groups
	const flattenedGroups = models.group.buildGroupTree(
		group,
		groupCollection.records,
		true
	) as models.group.Group[];

	// We need non flattened groups for the select groups filter
	const groups = models.group.buildGroupTree(group, groupCollection.records);

	const isLoading =
		bookingsCollection.isLoading ||
		categoriesLoading ||
		resourcesLoading ||
		schedulesLoading;

	return (
		<BookingsContext.Provider
			value={{
				isLoading,
				isSmallScreen,
				refreshBookings: bookingsCollection.refresh,
				flattenedGroups,
				groups,
				resources: sortedResources,
				bookings: bookingsCollection.records,
				schedules,
				weekDates,
				groupId,
				view,
				filters,
				conflicts,
				search,
				timestampDate,
				startDate,
				endDate,
				isGroupView,
				publishedPeriods,
				refreshPublishedPeriods,
			}}>
			{isLoading ? <Spinner /> : children}
		</BookingsContext.Provider>
	);
}
