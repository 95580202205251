import { JSX, MouseEvent, ReactNode } from 'react';

import { cssClasses } from 'pkg/css/utils';

import * as Input from 'components/form/inputs';

import * as css from './styles.css';

interface FeatureSelectProps {
	children: ReactNode | ReactNode[];
	onClick?: (event: MouseEvent<HTMLDivElement>) => void;
	active?: boolean;
	noSpacing?: boolean;
}

export default function FeatureSelect({
	children,
	onClick,
	active,
	noSpacing,
	...rest
}: FeatureSelectProps): JSX.Element {
	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		if (onClick) {
			onClick(event);
		}
	};

	return (
		<div
			{...rest}
			onClick={handleClick}
			className={cssClasses(css.wrapper, active ? css.active : null)}>
			<div className={css.toggle}>
				<Input.Control
					type="checkbox"
					standalone
					large
					rounded
					value={active ? 1 : 0}
					checked={active}
				/>
			</div>
			{noSpacing === true ? (
				children
			) : (
				<div className={css.container}>{children}</div>
			)}
		</div>
	);
}
