import { JSX, ReactNode } from 'react';

import { cssClasses } from 'pkg/css/utils';

import Icon, { IconName } from 'components/icon';

import { Spinner } from 'components/loaders/spinner';

import * as css from './styles.css';

interface ConnectionProps {
	leftComponent: ReactNode;
	rightComponent: ReactNode;
	iconName?: IconName;
	pending?: boolean;
	className?: string;
}

export default function Connection({
	leftComponent = null,
	rightComponent = null,
	iconName,
	pending,
	className,
}: ConnectionProps): JSX.Element {
	return (
		<div className={cssClasses(css.wrapper, className)}>
			<div className={css.item}>{leftComponent}</div>
			<div className={css.iconWrapper}>
				{!pending ? (
					<Icon name={iconName || 'link'} className={css.icon} />
				) : (
					<Spinner size="30px" />
				)}
			</div>
			<div className={css.item}>{rightComponent}</div>
		</div>
	);
}
