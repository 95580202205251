import { Fragment, useState } from 'react';

import * as models from 'pkg/api/models';
import { cssClasses, cssVarList } from 'pkg/css/utils';

import Icon from 'components/icon';

import * as css from './styles.css';

interface FormsAttachmentsProps {
	onDelete: () => void;

	attachments?: models.attachment.Attachment[];
}

interface ItemProps {
	onDelete: () => void;

	attachment: models.attachment.Attachment;
}

function Image({ src }: { src: string }) {
	const [isLoaded, setIsLoaded] = useState(false);
	const handleDidLoad = () => setIsLoaded(true);

	return (
		<img
			className={cssClasses(css.imageSource, isLoaded ? css.loaded : '')}
			src={src}
			onLoad={handleDidLoad}
			loading="lazy"
		/>
	);
}

function Item({ attachment, onDelete }: ItemProps) {
	const extension = models.attachment.getExtension(attachment);
	const fileSize = models.attachment.getHumanFileSize(attachment);

	let icon = null;
	let shouldHaveIcon = true;

	if (attachment.previewUrl !== '') {
		shouldHaveIcon = false;
	} else if (attachment.type === models.attachment.AttachmentType.Image) {
		shouldHaveIcon = false;
	}

	if (shouldHaveIcon) {
		switch (attachment.type) {
			case models.attachment.AttachmentType.Image:
				icon = <Icon name="file-image" />;
			default:
				icon = <Icon name="file-generic" />;
		}
	}

	const fileInfo = (
		<div className={css.fileInfo}>
			<div className={css.fileIcon}>{icon}</div>
			<figcaption>
				<div className={css.fileName}>{attachment.title}</div>
				<div className={css.fileMeta}>
					{extension} - {fileSize}
				</div>
			</figcaption>
		</div>
	);

	let inner = null;

	if (attachment.status === models.attachment.AttachmentStatus.Uploading) {
		const height = ((attachment.bytesUploaded || 0) / attachment.size) * 100;

		inner = (
			<div className={css.inner}>
				<div
					className={css.uploading}
					style={cssVarList({
						height: `${height}px`,
					})}
				/>
			</div>
		);
	} else {
		switch (attachment.type) {
			case models.attachment.AttachmentType.Image:
				inner = (
					<div className={css.inner}>
						<Image src={attachment.previewUrl || attachment.url} />
						{fileInfo}
					</div>
				);
				break;
			default:
				inner = <div className={css.inner}>{fileInfo}</div>;
		}
	}

	return (
		<figure className={css.item}>
			{inner}
			<div className={css.remove} onClick={onDelete}>
				<Icon className={css.icon} name="delete" />
			</div>
		</figure>
	);
}

export default function FormsAttachments({
	attachments = [],
	onDelete,
}: FormsAttachmentsProps) {
	return (
		<Fragment>
			{attachments.map((attachment) => (
				<Item attachment={attachment} onDelete={onDelete} />
			))}
		</Fragment>
	);
}
