const icon = {
	name: 'drawing-triangle-dashed',
	objects: [
		{
			type: 'polygon',
			attributes: {
				points: '12,4.57 4.8,19.07 19.2,19.07',
				stroke: 'currentColor',
				fill: 'none',
				strokeWidth: 1.3,
				'stroke-dasharray': '2.55 2.44',
			},
		},
	],
};

export default icon;
