import styled, { css } from 'styled-components';
import { JSX, Fragment, MouseEvent, useState, useRef } from 'react';
import { motion, PanInfo } from 'framer-motion';
import { useSelector } from 'react-redux';

import * as styles from 'pkg/config/styles';
import { ColorSwatch } from 'pkg/config/palette';

import {
	clipOffset,
	clipWidth,
	timelineOffsetToTime,
	timelineWidth,
} from 'pkg/video';
import { formatTime } from 'pkg/timeline';
import { RootState } from 'pkg/reducers';
import * as selectors from 'pkg/selectors';

import * as config from 'routes/video/analyze/timeline/config';
import { useClipActions } from 'routes/video/shared/hooks/clip-actions';

import Icon from 'components/icon';

import { useClipState } from 'components/video-analytics/ClipState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useCueState } from 'components/video-analytics/CueState';

interface ClipAttrsProps {
	clipId?: number;

	videoDuration: number;
	startsAt: number;
	endsAt: number;
	precision?: config.Precision;
	zoom?: number;

	rowStart?: string;
	rowEnd?: string;

	onTrimStart?: () => void;
	onTrimEnd?: () => void;

	onDidTrimStartsAt?: (startsAt: number) => void;
	onDidTrimEndsAt?: (endsAt: number) => void;
}

const ClipAttrs = ({
	videoDuration,
	startsAt,
	endsAt,
	rowStart,
	rowEnd,
}: ClipAttrsProps) => {
	const style: any = {
		width: `${clipWidth(videoDuration, startsAt, endsAt) || 0}%`,
		left: `${clipOffset(0, videoDuration, startsAt)}%`,
	};

	if (rowStart) {
		style.gridRowStart = rowStart;
	}

	if (rowEnd) {
		style.gridRowEnd = rowEnd;
	}

	return { style };
};

const Wrapper = styled.div.attrs<ClipAttrsProps>(ClipAttrs)<ClipAttrsProps>`
	padding: var(--spacing-2) 0;
	position: relative;
	cursor: pointer;

	&[data-disabled='true'] {
		pointer-events: none;
		opacity: 0.2;
	}
`;

const TrimHandleRight = styled(motion.div)`
	width: 25px;
	height: calc(100% - 4px);
	position: absolute;
	right: -23px;
	top: 2px;
	cursor: ew-resize;

	&::before {
		position: absolute;
		display: block;
		content: '';
		left: calc(50% - 1px);
		top: 15%;
		background: var(--palette-yellow-700);
		width: 2px;
		height: 70%;
		border-radius: var(--radius-3);
		transition: 150ms background ease-in-out;
	}
`;

const TrimHandleLeft = styled(TrimHandleRight)`
	right: auto;
	left: -23px;
`;

interface IndicatorProps {
	title?: string;

	isAnnotation?: boolean;
	isTrimming?: boolean;
	isRecording?: boolean;
	isActive?: boolean;
	isHighlight?: boolean;
	isPrivate?: boolean;
	isDisabled?: boolean;

	onClick?: () => void;
}

const BaseClip = styled.div`
	transition: 150ms box-shadow ease-in-out;
	border-radius: var(--radius-2);
	height: 100%;
	overflow: hidden;

	span {
		padding: var(--spacing-2) var(--spacing-3);
		font-size: var(--font-size-xs);
		transform: translateY(1px);
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
	}

	@media (hover: hover) {
		&:hover {
			box-shadow:
				0 0 0 2px var(--palette-white),
				inset 0 0 0 1px var(--palette-gray-800);

			${TrimHandleLeft}, ${TrimHandleRight} {
				&::before {
					background: var(--palette-white);
				}
			}
		}
	}
`;

const generateClipStyles = (colorSwatch: ColorSwatch) => css`
	background: linear-gradient(
		to bottom,
		${colorSwatch[500]},
		${colorSwatch[600]}
	);
	box-shadow:
		0 0 0 2px var(--palette-gray-800),
		inset 0 0 0 1px ${colorSwatch[400]};
	color: ${colorSwatch[900]};
	text-shadow: 0 1px 1px ${colorSwatch[500]};
	font-weight: var(--font-weight-semibold);

	&[data-active='true'] {
		background: linear-gradient(
			to bottom,
			${colorSwatch[400]},
			${colorSwatch[500]}
		);
		box-shadow:
			0 0 0 2px var(--palette-white),
			inset 0 0 0 1px var(--palette-gray-800);
		text-shadow: 0 1px 1px ${colorSwatch[300]};

		@media (hover: hover) {
			&:hover {
				background: linear-gradient(
					to bottom,
					${colorSwatch[300]},
					${colorSwatch[400]}
				);
			}
		}
	}
`;

const DefaultClip = styled(BaseClip)`
	${generateClipStyles(styles.palette.green)};
`;

const HighlightClip = styled(BaseClip)`
	${generateClipStyles(styles.palette.yellow)};
`;

const PrivateHighlightClip = styled(BaseClip)`
	${generateClipStyles(styles.palette.orange)};
`;

const PrivateClip = styled(BaseClip)`
	${generateClipStyles(styles.palette.blue)};
`;

const RecordingClip = styled(BaseClip)`
	background: linear-gradient(
		to bottom,
		var(--palette-red-400),
		var(--palette-red-500)
	);
	color: var(--palette-red-900);
	box-shadow:
		0 0 0 2px var(--palette-white),
		inset 0 0 0 1px var(--palette-gray-800);
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
`;

const AnnotationsClip = styled(BaseClip)`
	background: var(--palette-gray-700);
	cursor: grab;

	@media (hover: hover) {
		&:hover {
			box-shadow: none;
		}
	}
`;

const TrimmingClip = styled(BaseClip)`
	background: var(--palette-yellow-900);
	color: var(--palette-white);
	box-shadow: 0 0 0 2px var(--palette-yellow-600);
`;

const ClipContainer = styled.div`
	display: block;
	width: 100%;
	height: 100%;
	position: relative;
`;

const ClipStatus = styled.div`
	position: absolute;
	left: var(--spacing-3);
	bottom: var(--spacing-2);

	svg {
		margin-right: var(--spacing-2);
		fill: currentColor;
	}
`;

interface Coordinates {
	x: number;
	y: number;
}

export default function Clip({
	clipId,
	title,

	videoDuration,
	startsAt,
	endsAt,
	precision,
	zoom,

	isAnnotation,
	isActive,
	isRecording,
	isHighlight,
	isPrivate,
	isTrimming,
	isDisabled,

	onClick,
	onTrimStart,
	onTrimEnd,
	onDidTrimStartsAt,
	onDidTrimEndsAt,

	rowStart,
	rowEnd,
}: ClipAttrsProps & IndicatorProps): JSX.Element {
	let ClipComponent = DefaultClip;

	if (isHighlight && !isPrivate) {
		ClipComponent = HighlightClip;
	} else if (isPrivate && isHighlight) {
		ClipComponent = PrivateHighlightClip;
	} else if (isPrivate && !isHighlight) {
		ClipComponent = PrivateClip;
	} else if (isTrimming) {
		ClipComponent = TrimmingClip;
	} else if (isRecording) {
		ClipComponent = RecordingClip;
	} else if (isAnnotation) {
		ClipComponent = AnnotationsClip;
	}

	const clipRef = useRef<HTMLDivElement>();

	const { currentCue } = useCueState();
	const clipState = useClipState();
	const clipActions = useClipActions(clipId || 0);
	const { currentTime } = usePlaybackState();

	const clip = useSelector((state: RootState) =>
		selectors.videoSequences.find(state, clipId)
	);

	const taggedUserIds = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findAllUserIds(state, clipId)
	);

	const numTaggedUsers = Object.keys(taggedUserIds).length;

	const canEdit = clip?.hasIn(['links', 'edit']);

	const [initialClipWidth, setClipWidth] = useState<number>(0);
	const [initialClipOffset, setClipOffset] = useState<number>(0);

	const [coords, setCoords] = useState<Coordinates>({ x: 0, y: 0 });

	const containerWidth = timelineWidth(
		config.getSegmentWidth(zoom),
		videoDuration,
		config.multiplier[precision]
	);

	const handleMouseDown = (event: MouseEvent) => {
		setCoords({
			x: event.pageX,
			y: event.pageY,
		});
	};

	const handleClick = (event: MouseEvent) => {
		const delta: Coordinates = {
			x: event.pageX - coords.x,
			y: event.pageY - coords.y,
		};

		const radius: number = 10;
		const bounds: number = Math.sqrt(delta.x * delta.x + delta.y * delta.y);

		if (bounds < radius) {
			if (!isRecording && !isTrimming) {
				if (currentCue.cueId === clipId && canEdit) {
					clipActions.edit(currentTime);
				} else {
					clipActions.activate();
				}
			}

			if (onClick) {
				onClick();
			}
		}
	};

	const handlePanStart = (event: any) => {
		event.stopPropagation();

		if (clipRef?.current) {
			setClipWidth(clipRef.current.offsetWidth);
			setClipOffset(clipRef.current.offsetLeft);
		}
	};

	const handlePanLeft = (event: any, info: PanInfo) => {
		event.stopPropagation();

		if (onTrimStart) {
			onTrimStart();
		}

		if (clipRef?.current) {
			const offset = Math.round(initialClipOffset + info.offset.x);
			const width = Math.round(initialClipWidth - info.offset.x);

			if (offset >= 0 && offset < offset + width) {
				const relativeOffset = (offset / containerWidth) * 100;
				const relativeWidth = (width / containerWidth) * 100;

				clipState.setStartsAt(
					timelineOffsetToTime(relativeOffset, 0, videoDuration)
				);

				clipState.setEndsAt(
					timelineOffsetToTime(relativeOffset + relativeWidth, 0, videoDuration)
				);

				clipRef.current.style.left = relativeOffset + '%';
				clipRef.current.style.width = relativeWidth + '%';
			}
		}
	};

	const handlePanLeftEnd = () => {
		if (onTrimEnd) {
			onTrimEnd();
		}

		if (onDidTrimStartsAt) {
			onDidTrimStartsAt(clipState.getStartsAt());
		}
	};

	const handlePanRight = (event: any, info: PanInfo) => {
		event.stopPropagation();

		if (onTrimStart) {
			onTrimStart();
		}

		if (clipRef?.current) {
			const width = Math.round(initialClipWidth + info.offset.x);

			const relativeOffset = (initialClipOffset / containerWidth) * 100;
			const relativeWidth = (width / containerWidth) * 100;

			clipState.setEndsAt(
				timelineOffsetToTime(relativeOffset + relativeWidth, 0, videoDuration)
			);

			clipRef.current.style.width = relativeWidth + '%';
		}
	};

	const handlePanRightEnd = () => {
		if (onTrimEnd) {
			onTrimEnd();
		}

		if (onDidTrimEndsAt) {
			onDidTrimEndsAt(clipState.getEndsAt());
		}
	};

	const hasAnnotations = clip?.annotations && clip?.annotations !== '{}';

	return (
		<Wrapper
			ref={clipRef}
			videoDuration={videoDuration}
			startsAt={startsAt}
			endsAt={endsAt}
			data-disabled={isDisabled}
			rowStart={rowStart}
			rowEnd={rowEnd}>
			<ClipComponent
				key={`clipComponent:${clipId}`}
				data-active={isActive}
				onMouseDown={handleMouseDown}
				onClick={handleClick}>
				{isTrimming || isRecording ? (
					<Fragment>
						<span>
							{formatTime(clipState.getStartsAt())} &mdash;{' '}
							{formatTime(
								isRecording && !isTrimming ? currentTime : clipState.getEndsAt()
							)}
						</span>

						{isTrimming && (
							<Fragment>
								<TrimHandleLeft
									onPanStart={handlePanStart}
									onPan={handlePanLeft}
									onPanEnd={handlePanLeftEnd}
								/>
								<TrimHandleRight
									onPanStart={handlePanStart}
									onPan={handlePanRight}
									onPanEnd={handlePanRightEnd}
								/>
							</Fragment>
						)}
					</Fragment>
				) : (
					<ClipContainer>
						{title && <span>{title}</span>}
						<ClipStatus>
							{numTaggedUsers > 0 && <Icon name="user" size={1.2} />}
							{hasAnnotations && <Icon name="clip-annotations" size={1.4} />}
						</ClipStatus>
					</ClipContainer>
				)}
			</ClipComponent>
		</Wrapper>
	);
}
