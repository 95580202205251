import { t } from '@transifex/native';
import { normalize, schema } from 'normalizr';

import {
	ACTIVITY_POST_SUCCESS,
	ACTIVITY_REMOVE_SUCCESS,
	ACTIVITY_UPDATE_SUCCESS,
} from 'pkg/actions/action-types';
import { setActivities } from 'pkg/actions/activity_list';
import { show } from 'pkg/actions/flashes';
import * as activityService from 'pkg/actions/services/activities.services';
import { forAccountHeaders } from 'pkg/actions/utils';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

const activitySchema = new schema.Entity('activities', {});

export const newActivity = (id, data) => {
	return async (dispatch) => {
		const [request, response] = models.create(
			endpoints.UserActivities.Create(id),
			data
		);

		if (!request.ok) {
			return;
		}

		const payload = normalize(response, activitySchema);

		dispatch({
			type: ACTIVITY_POST_SUCCESS,
			payload,
		});

		show(
			{
				title: t(`Activity saved`),
				message: t(`Training activity saved successfully, well done!`),
			},
			200
		);
	};
};

export const updateActivity = (id, data) => {
	return async (dispatch) => {
		const req = await activityService.updateActivity(id, data);

		if (!req.ok) {
			return;
		}

		const res = await req.json();

		dispatch({
			type: ACTIVITY_UPDATE_SUCCESS,
			payload: res,
		});
	};
};

export const setActivity = (activity) => {
	return {
		type: 'SET_ACTIVITY',
		activity,
	};
};

export const setActivitiesEntities = (collection) => ({
	type: 'SET_ACTIVITIES_ENTITIES',
	collection,
});

export const setActivitiesComments = (collection) => ({
	type: 'SET_ACTIVITIES_COMMENTS',
	collection,
});

export const setActivitiesLikes = (collection) => ({
	type: 'SET_ACTIVITIES_LIKES',
	collection,
});

export const removeActivity = (id) => {
	return async (dispatch) => {
		const req = await activityService.removeActivity(id);

		if (!req.ok) {
			return;
		}

		dispatch({
			type: ACTIVITY_REMOVE_SUCCESS,
			payload: id,
		});
	};
};

export const addLikeActivity = (id) => {
	return {
		type: 'ADD_LIKE_ACTIVITY',
		id,
	};
};

const removeActivityLike = (id) => {
	return {
		type: 'REMOVE_LIKE_ACTIVITY',
		id,
	};
};

export const toggleLikeActvity = (activityId) => async (dispatch, getState) => {
	const state = getState();
	const { likedByCurrentUser } = state.activities.entities[activityId];

	const method = likedByCurrentUser ? 'delete' : 'post';

	if (!likedByCurrentUser) {
		dispatch(addLikeActivity(activityId));
	}

	const response = await sdk[method]('/user-activities/:id/likes', {
		id: activityId,
	});

	if (method === 'post') {
		if (!response.ok) {
			dispatch(removeActivityLike(activityId));
		}
	} else if (method === 'delete') {
		dispatch(removeActivityLike(activityId));
	}
};

export const fetchUserActivities =
	(userId, withStats = false, addActivities = false, forAccount = null) =>
	async (dispatch) => {
		const headers = forAccountHeaders(forAccount);
		const params = { userId };

		if (withStats) {
			params['include-stats'] = 1;
		}

		const response = await sdk.get(
			`/users/:userId/activities`,
			params,
			{},
			headers
		);

		if (!response.ok) {
			throw response;
		}

		const collection = await response.json();

		await dispatch(setActivitiesEntities(collection));
		await dispatch(setActivitiesComments(collection));
		await dispatch(setActivitiesLikes(collection));

		if (addActivities) {
			dispatch(setActivities(collection));
		}
	};

export const fetchGroupActivities =
	(groupId, withStats = false, addActivities = false) =>
	async (dispatch) => {
		const params = {};

		if (withStats) {
			params['include-stats'] = 1;
		}

		const response = await sdk.get(`/groups/${groupId}/activities`, params);

		if (!response.ok) {
			throw response;
		}

		const collection = await response.json();

		dispatch(setActivitiesEntities(collection));
		dispatch(setActivitiesComments(collection));
		dispatch(setActivitiesLikes(collection));

		if (addActivities) {
			dispatch(setActivities(collection));
		}
	};

export const fetchActivitiesPage =
	(pageLink, forAccount = null) =>
	async (dispatch) => {
		const headers = forAccountHeaders(forAccount);

		const response = await sdk.get(pageLink, {}, {}, headers);

		if (!response.ok) {
			throw response;
		}

		const collection = await response.json();

		dispatch(setActivitiesEntities(collection));
		dispatch(setActivitiesComments(collection));
		dispatch(setActivitiesLikes(collection));

		dispatch(setActivities(collection));
	};
