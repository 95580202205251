import { JSX, useMemo } from 'react';

import * as styles from 'pkg/config/styles';

import DateTime, {
	Calendar,
	CalendarDayType,
	CalendarMonthType,
} from 'pkg/datetime';
import { cssClasses, cssVarList } from 'pkg/css/utils';
import * as models from 'pkg/api/models';
import { isSameDay } from 'pkg/date';
import rgba from 'pkg/rgba';

import { ViewProps, useCalendarContext } from 'routes/group/calendar';

import Row from 'components/layout/row';

import * as css from './styles.css';

interface DaysProps {
	events: models.event.Event[];
	day: CalendarDayType;
}

const Days = ({ day, events }: DaysProps): JSX.Element => {
	return (
		<div className={css.day}>
			<div
				className={cssClasses(
					css.number,
					day.isToday && day.isCurrentMonth ? css.isCurrentMonth : '',
					day.isCurrentMonth ? css.isCurrentMonth : ''
				)}>
				{day.day}
			</div>
			{events.length > 0 && (
				<Row spacing={styles.spacing._1}>
					{events.slice(0, 3).map((e) => (
						<div
							key={e.id}
							className={css.dot}
							style={cssVarList({
								color: rgba(styles.palette.events[e.type].foregroundColor),
							})}
						/>
					))}
				</Row>
			)}
		</div>
	);
};

interface MonthProps {
	month: CalendarMonthType;
	onClick: (monthNumber: number, month: string) => void;
	weekDays: string[];
}

const Month = ({ month, weekDays, onClick }: MonthProps): JSX.Element => {
	const calendarCtx = useCalendarContext();
	const currentMonth = useMemo(() => {
		for (let i = 0; i < month.length; i++) {
			if (month[i].isCurrentMonth === true) {
				return month[i].timestamp;
			}
		}
	}, [month]);

	const displayMonthView = () => {
		onClick(currentMonth, 'month');
	};

	return (
		<div className={css.monthWrapper} onClick={displayMonthView}>
			<span className={css.label}>
				{new DateTime(new Date(currentMonth)).toLocaleDateString({
					month: 'long',
				})}
			</span>
			<div className={css.monthGrid}>
				{weekDays.map((day, index) => (
					<span className={css.weekLabel} key={index}>
						{day}
					</span>
				))}
				{month.map((day, index) => {
					const timestampDay = new DateTime(new Date(day.timestamp));
					const events = calendarCtx.eventsCollection.records.filter((e) =>
						isSameDay(new Date(e.startsAt * 1000), timestampDay)
					);
					return <Days key={index} day={day} events={events} />;
				})}
			</div>
		</div>
	);
};

export default function YearView({ from, changeView }: ViewProps) {
	const currentCalendar = useMemo(() => {
		const c = [];
		const year = new Date(from * 1000).getFullYear();
		for (let i = 0; i < 12; i++) {
			c.push(Calendar.generate(year, i + 1));
		}
		return c;
	}, [from]);

	const weekDays = useMemo(() => {
		const d: string[] = [];
		currentCalendar[0].forEach((day, index) => {
			if (index < 7) {
				d.push(
					new DateTime(new Date(day.timestamp)).toLocaleDateString({
						weekday: 'narrow',
					})
				);
			}
		});
		return d;
	}, [currentCalendar]);

	return (
		<div className={css.contentWrapper}>
			<div className={css.yearGrid}>
				{currentCalendar.map((month, index) => (
					<Month
						key={index}
						month={month}
						weekDays={weekDays}
						onClick={changeView}
					/>
				))}
			</div>
		</div>
	);
}
