import { JSX, FC } from 'react';
import { t } from '@transifex/native';

import { AccountHourFormat } from 'pkg/models/account';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import DateTime from 'pkg/datetime';
import { useCurrentAccount, useFetchIdentity } from 'pkg/identity';
import { useEndpoint } from 'pkg/api/use_endpoint';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import RadioButton from 'components/form/RadioButton';
import {
	SettingRow,
	SettingLabel,
	SettingDescription,
	SettingControl,
	SettingRowDivider,
} from 'components/settings/Settings';
import Column from 'components/layout/column';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const flashFail = () =>
	actions.flashes.show(
		{
			title: t(`Could not save!`),
		},
		400
	);

interface Language {
	code: string;
	localLabel: string;
}

interface LangaugeProps {
	account?: models.account.Account;
	languages: models.language.Language[];
	onSave: (event: any) => void;
}

const Language: FC<LangaugeProps> = ({
	account,
	languages,
	onSave,
}: LangaugeProps): JSX.Element => {
	return (
		<SettingRow>
			<SettingLabel>{t(`Language`)}</SettingLabel>
			<SettingDescription>
				{
					languages.find((lang) => lang.code === account.languageCode)
						?.localLabel
				}
			</SettingDescription>
			<SettingControl>
				<ContextMenu.Menu
					toggleWith={
						<Button
							small
							icon="language"
							testid="account_settings.change_language_button">
							{t('Change')}
						</Button>
					}>
					{languages.map((lang: Language) => (
						<ContextMenu.Item
							key={`lang-${lang.code}`}
							data-language={lang.code}
							onClick={onSave}>
							<RadioButton
								checked={lang.code === account.languageCode ? true : false}
								label={lang.localLabel}
								readOnly
							/>
						</ContextMenu.Item>
					))}
				</ContextMenu.Menu>
			</SettingControl>
		</SettingRow>
	);
};

const HourFormat: FC = (): JSX.Element => {
	const account = useCurrentAccount();
	const fetchIdentity = useFetchIdentity();

	const set12h = async () => {
		await actions.accounts.patchAccount(account.id, {
			hourFormat: '12h',
		});

		DateTime.setHourFormat('12h');

		fetchIdentity();
	};

	const set24h = async () => {
		await actions.accounts.patchAccount(account.id, {
			hourFormat: '24h',
		});

		DateTime.setHourFormat('24h');

		fetchIdentity();
	};

	return (
		<SettingRow>
			<SettingLabel>{t(`Hour format`)}</SettingLabel>
			<SettingDescription>
				{account.hourFormat === AccountHourFormat['12h']
					? t('12 hour clock')
					: t('24 hour clock')}
			</SettingDescription>
			<SettingControl>
				<ContextMenu.Menu
					toggleWith={
						<Button small icon="schedule">
							{t('Change')}
						</Button>
					}>
					<ContextMenu.Item onClick={set12h}>
						<RadioButton
							checked={account.hourFormat === AccountHourFormat['12h']}
							label={t('12 hour clock')}
						/>
					</ContextMenu.Item>
					<ContextMenu.Item onClick={set24h}>
						<RadioButton
							checked={account.hourFormat === AccountHourFormat['24h']}
							label={t('24 hour clock')}
						/>
					</ContextMenu.Item>
				</ContextMenu.Menu>
			</SettingControl>
		</SettingRow>
	);
};

interface Payload {
	[key: string]: string | number;
}

interface DisplaySettingsProps {
	account: models.account.Account;
}
export default function DisplaySettings({
	account,
}: DisplaySettingsProps): JSX.Element {
	const fetchIdentity = useFetchIdentity();
	const { record: languages, isLoading: isLoadingLanguages } =
		useEndpoint<models.language.Language[]>('/languages');

	const save = async (payload: Payload): Promise<boolean> => {
		const patchedAccount = await actions.accounts.patchAccount(
			account.id,
			payload
		);

		await fetchIdentity();
		return Promise.resolve(!!patchedAccount);
	};

	const saveLanguage = async (event: MouseEvent) => {
		const { language } = (event.currentTarget as HTMLDivElement).dataset;
		const payload = { languageCode: language };
		const success = await save(payload);

		if (success) {
			window.location.reload();
		} else {
			flashFail();
		}
	};

	return (
		<Column>
			<SectionTitle>{t(`Display settings`)}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					{!isLoadingLanguages && (
						<Language
							account={account}
							languages={languages}
							onSave={saveLanguage}
						/>
					)}
					<SettingRowDivider />
					<HourFormat />
				</Card.Body>
			</Card.Base>
		</Column>
	);
}
