import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import * as models from 'pkg/api/models';
import { useCurrentMembership } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

import VideoListItem from 'routes/event/single/tabs/videos/list-item';

import SectionTitle from 'components/SectionTitle';
import AssetImage from 'components/AssetImage';
import { LargeScreen } from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import VideoModal from 'components/video-library/modals/Video';
import Column from 'components/layout/column';
import {
	PageAction,
	usePageActions,
} from 'components/navigation/header/small_screen/page_actions/Context';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

interface VideosProps {
	match: models.match.Match;
}

export default function Videos({ match }: VideosProps) {
	const matchVideosCollection = useCollection<models.matchVideo.MatchVideo>(
		endpoints.Match.ShowVideos(match.id)
	);

	const [showAddForm, setShowAddForm] = useState(false);

	const showAddModal = () => setShowAddForm(true);
	const hideAddModal = () => setShowAddForm(false);

	const matchVideos = matchVideosCollection.records.sort(
		(a, b) => b.video.createdAt - a.video.createdAt
	);
	const activeMembership = useCurrentMembership();

	const onUpdateVideo = (matchVideo: models.matchVideo.MatchVideo) => {
		matchVideosCollection.replaceRecord(matchVideo);
	};

	const onAddVideo = (video: models.video.Video) => {
		matchVideosCollection.addRecord({
			matchId: match.id,
			videoId: video.id,
			video,
		} as models.matchVideo.MatchVideo);
	};

	const onRemoveVideo = (matchVideoId: number) => {
		matchVideosCollection.removeRecord(matchVideoId);
	};

	let pageActions: PageAction[] = [];

	if (
		matchVideos.length > 0 &&
		models.membership.isAdminOrStaff(activeMembership)
	) {
		pageActions = [{ label: 'Add', onClick: showAddModal }];
	}

	usePageActions(pageActions);

	let content: JSX.Element | JSX.Element[] = (
		<MissingEntities
			helpUrl="https://help.360player.com/article/18-how-to-add-a-video"
			title={t('Add a video')}
			description={t('Connect videos to this match')}
			actions={
				models.canEdit(match) && (
					<Button
						secondary
						icon="add"
						label={t('Add video!')}
						onClick={showAddModal}
						testid="match.add_video_button"
					/>
				)
			}
			image={<AssetImage src="img/missing-entities/video-analytics.svg" />}
		/>
	);

	if (matchVideos.length > 0) {
		content = matchVideos.map((v) => (
			<VideoListItem
				key={v.id}
				matchVideo={v}
				canEdit={models.canEdit(match)}
				onUpdate={onUpdateVideo}
				onRemove={onRemoveVideo}
			/>
		));
	}

	return (
		<LargeScreenContent.Inner maxWidth={PageWidths.WIDE}>
			<Fragment>
				<Column>
					<SectionTitle>
						{t('Videos')}
						{matchVideos.length > 0 &&
							models.membership.isAdminOrStaff(activeMembership) && (
								<LargeScreen>
									<Button
										icon="add"
										label={t('Add video')}
										onClick={showAddModal}
									/>
								</LargeScreen>
							)}
					</SectionTitle>
					{content}
				</Column>
				{showAddForm && (
					<VideoModal
						matchId={match.id}
						// match={match}
						onClose={hideAddModal}
						afterSave={onAddVideo}
					/>
				)}
			</Fragment>
		</LargeScreenContent.Inner>
	);
}
