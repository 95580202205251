import { t } from '@transifex/native';
import { Dispatch } from 'redux';

import Account from 'pkg/models/account';

import { normalizedDispatch } from 'pkg/actions/utils';
import * as flashActions from 'pkg/actions/flashes';
import * as services from 'pkg/actions/services';

import { crash } from 'pkg/errors/errors';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';

export interface CreateAccountResponse {
	authToken: string;
	orgToken: string;
	account: models.account.Account;
}

export async function patchAccount(
	accountId: number,
	payload: services.account.AccountPayload,
	token?: string
): Promise<Account> {
	const headers: { [key: string]: string } = {};

	if (token) {
		headers['Authorization'] = `Bearer ${token}`;
	}

	const [request, response] = await services.account.patch(
		accountId,
		payload,
		headers
	);

	if (!request.ok) {
		flashActions.show({ title: t(`Could not save!`) }, request.status);

		return null;
	}

	return response;
}

export const checkParentalConsent = async (): Promise<boolean> => {
	const [, account] = await services.account.getSelf();
	return !account.needsParentalConsent;
};

export const setParentalConsent = () => async (): Promise<any> => {
	const [, account] = await services.account.getSelf();

	if (account.needsParentalConsent) {
		return false;
	}
};

export const requestParentalConsent = async (
	email: string
): Promise<Response> => {
	if (email.indexOf('@') === -1) {
		return;
	}

	const [request] = await services.account.requestParentalConsent(email);

	return request;
};

export const grantAccountParentalConsent =
	(accountId: number) => async (): Promise<boolean> => {
		const [request] = await services.account.grantParentalConsent(accountId);

		if (!request.ok) return false;

		flashActions.show(
			{
				title: t(`Granted access`),
				message: t(`You granted your child access to 360Player.`),
			},
			request.status
		);

		return true;
	};

export const revokeAccountParentalConsent =
	(accountId: number) => async (): Promise<boolean> => {
		const [request] = await services.account.revokeParentalConsent(accountId);

		if (!request.ok) return false;

		flashActions.show(
			{
				title: t(`Removed access`),
				message: t(`Consent removed, your child can no longer use 360Player.`),
			},
			request.status
		);

		return true;
	};

export const connectApple =
	(accountId: number, authToken: string) =>
	async (dispatch: Dispatch): Promise<Account> => {
		const [request, response] = await services.account.connectApple(
			accountId,
			authToken
		);

		if (!request.ok) {
			flashActions.show({ title: t(`Could not save!`) }, request.status);

			return null;
		}

		normalizedDispatch(response, Account.normalizr())(dispatch);

		return response;
	};

export const disconnectApple =
	(accountId: number) =>
	async (dispatch: Dispatch): Promise<Account> => {
		const [request, response] =
			await services.account.disconnectApple(accountId);

		if (!request.ok) {
			flashActions.show({ title: t(`Could not save!`) }, request.status);

			return null;
		}

		normalizedDispatch(response, Account.normalizr())(dispatch);

		return response;
	};

export interface CreateChildAccountPayload {
	firstName: string;
	lastName: string;
	birthDate: string; // required in YYYY-MM-DD format
	countryId: number;
	languageCode?: string;

	email?: string;
	password?: string;
}

export async function createChildAccount(
	accountId: number,
	payload: CreateChildAccountPayload
): Promise<[boolean, models.account.Account]> {
	const [request, response] = await models.create<
		CreateChildAccountPayload,
		models.account.Account
	>(endpoints.Accounts.CreateChildAccountForAccount(accountId), payload);

	if (!request.ok) {
		const c = crash();

		actions.flashes.show({
			title: c.title,
			message: c.description,
		});

		return [false, null];
	}

	return [true, response];
}
