import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { FieldTypes } from 'pkg/api/models/form';
import { stringToSex } from 'pkg/api/models/user';

import CountrySelect, { CountrySelectProps } from 'components/CountrySelect';

import { FormDataValue } from 'components/form/Form';
import * as Inputs from 'components/form/inputs';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface Props {
	field: models.form.Field;
	userDataValue?: any;
}

const getChoiceInput = (
	field: models.form.Field,
	defaultValues: FormDataValue[]
) => {
	const type =
		field.type === models.form.FieldTypes.Choice ? 'radio' : 'checkbox';

	const havePreCheckedValues = defaultValues.length > 0;

	return field.values.map(({ label, value }) => {
		const checkedProp: { [key: string]: string | boolean } = {};

		// if defaultValues exist the user should not be able to change the selection
		if (havePreCheckedValues) {
			checkedProp['checked'] = defaultValues.includes(label);
		}

		const isRequired =
			(field.required && defaultValues.length < 1) ||
			(field.required && !value);

		return (
			<Inputs.Control
				key={value}
				name={`field_${field.id}`}
				required={isRequired}
				type={type}
				value={value}
				label={label}
				noRequiredText={true}
				testid={label}
				{...checkedProp}
			/>
		);
	});
};

const getGenderInput = (
	field: models.form.Field,
	defaultValue: FormDataValue
) => {
	return field.values.map(({ value }) => {
		const checkedProp: { [key: string]: string | boolean } = {};

		if (defaultValue) {
			const sexEnum = stringToSex(defaultValue as string);
			checkedProp['checked'] = Number.parseInt(value, 10) === sexEnum;
		}

		return (
			<Inputs.Control
				key={value}
				name={`field_${field.id}`}
				required={field.required}
				noRequiredText={true}
				type="radio"
				value={value}
				label={models.user.translatedSexString(Number.parseInt(value, 10))}
				{...checkedProp}
			/>
		);
	});
};

const getFieldInput = (
	field: models.form.Field,
	defaultValue: FormDataValue
) => {
	const inputDefaultProps: { [key: string]: FormDataValue } = {
		name: `field_${field.id}`,
		type: field.type,
		required: field.required,
		placeholder: placeHolders[field.type] || '',
	};

	if (defaultValue) {
		inputDefaultProps['value'] = defaultValue;
	}

	if (field.type === models.form.FieldTypes.Phone) {
		inputDefaultProps['pattern'] = '([-0-9+ ]+)';
	}

	if (field.type === models.form.FieldTypes.Email) {
		inputDefaultProps['pattern'] = '[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+';
	}
	if (field.type === models.form.FieldTypes.Country) {
		const props: CountrySelectProps = {
			name: field.key,
			required: field.required,
		};

		if (defaultValue) {
			// both these are needed to display the existing country in the select
			props.suggestedCountry = defaultValue as string;
			props.value = defaultValue as string;
		}

		return <CountrySelect {...props} />;
	}

	if (field.type === models.form.FieldTypes.LongText) {
		return <Inputs.Area {...inputDefaultProps} minRows="2" />;
	}

	return <Inputs.Field {...inputDefaultProps} />;
};

const renderInput = (field: models.form.Field, userDataValue: any) => {
	const value = userDataValue || '';

	if (field.key === 'user_sex') {
		return getGenderInput(field, value);
	}
	switch (field.type) {
		case models.form.FieldTypes.Choice:
		case models.form.FieldTypes.MultiChoice:
			return getChoiceInput(field, value);
		default:
			return getFieldInput(field, value);
	}
};

const placeHolders: { [key: string]: string } = {
	[models.form.FieldTypes.Email]: 'example@email.com',
	[models.form.FieldTypes.Phone]: '+4612345678',
};

const FormInputField = ({ field, userDataValue }: Props) => {
	const formContext = useFormContext();
	const isEditable = userDataValue === null;

	const multiSelectError = t('You must select at least one value');
	const singleSelectError = t('You must select a value');
	const defaultError = t('Please enter a value');

	const getErrorMessage = (field: models.form.Field): string => {
		if (formContext.formState?.errors[`field_${field.id}`]) {
			if (field.type === FieldTypes.MultiChoice) {
				return multiSelectError;
			} else if (field.type === FieldTypes.Choice) {
				return singleSelectError;
			} else {
				return defaultError;
			}
		}
		return null;
	};

	const errorMessage = getErrorMessage(field);

	if (field.type === models.form.FieldTypes.Section) {
		return (
			<Column spacing={styles.spacing._1}>
				{field.sortOrder !== 0 && <hr className={css.sectionDivider} />}
				<div>
					<div className={css.sectionTitle}>{field.label}</div>
					<span className={css.sectionDescription}>{field.description}</span>
				</div>
			</Column>
		);
	}

	return (
		<Inputs.Group
			key={field.key}
			label={field.label}
			required={field.required}
			errorMessage={errorMessage}
			description={field.description}
			readOnly={!isEditable && userDataValue?.length > 0}
			testid={field.label}>
			{renderInput(field, userDataValue)}
		</Inputs.Group>
	);
};

export default FormInputField;
