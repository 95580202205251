import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as sdk from 'pkg/core/sdk';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { flashes } from 'pkg/actions';

export async function setFieldAsRequired(
	userField: models.userFields.UserField
): Promise<models.userFields.UserField> {
	let req, res;
	if (!userField.id) {
		// only for reserved fields that does not have a user field connected to it
		[req, res] = await models.create<
			models.userFields.UserFieldPayload,
			models.userFields.UserField
		>(endpoints.UserField.Index(), {
			key: userField.key,
			type: userField.type,
			label: userField.label,
			groupId: userField.groupId,
			required: true,
		});
	} else {
		[req, res] = await models.update<unknown, models.userFields.UserField>(
			userField,
			{
				required: true,
			}
		);
	}

	if (!req.ok) {
		flashes.show(
			{
				title: t('Something went wrong'),
				message: t('Please try again later'),
			},
			req.status
		);
		return userField;
	}

	return res;
}

export async function updateSortOrder(sortOrders: {
	[key: number]: number;
}): Promise<[boolean, models.userFields.UserField]> {
	const request = await sdk.patch(
		endpoints.UserField.UpdateSortOrder(),
		{},
		sortOrders
	);

	const response = await request.json();

	if (!request.ok) {
		flashActions.show({ title: t('Something went wrong') }, response.error);
		return [false, null];
	}

	return [true, response];
}
export async function createUserField(
	requestPayload: Partial<models.userFields.UserField>
): Promise<[boolean, models.userFields.UserField]> {
	const [request, response] = await models.create<
		Partial<models.userFields.UserField>,
		models.userFields.UserField
	>(endpoints.UserField.Create(), requestPayload);

	if (!request.ok) {
		flashes.show(
			{
				title: t('Failed to save custom field'),
				message: t('Could not save custom field'),
			},
			400
		);
	}

	return [true, response];
}
export async function updateUserField(
	requestPayload: Partial<models.userFields.UserField>,
	fieldInEditId: number
): Promise<[boolean, models.userFields.UserField]> {
	const request = await sdk.patch(
		endpoints.UserField.Update(fieldInEditId),
		{},
		requestPayload
	);

	const response = await request.json();

	if (!request.ok) {
		flashActions.show({ title: t('Something went wrong') }, response.error);
		return [false, null];
	}

	return [true, response];
}
