export function AddAttachment(
	userID: number,
	userLogID: number,
	attachmentID: number
): string {
	return `/v1/users/${userID}/logs/${userLogID}/attachments/${attachmentID}`;
}

export function Create(userID: number): string {
	return `/v1/users/${userID}/logs`;
}

export function Delete(userID: number, userLogID: number): string {
	return `/v1/users/${userID}/logs/${userLogID}`;
}

export function Index(userID: number): string {
	return `/v1/users/${userID}/logs`;
}

export function RemoveAttachment(
	userID: number,
	userLogID: number,
	attachmentID: number
): string {
	return `/v1/users/${userID}/logs/${userLogID}/attachments/${attachmentID}`;
}

export function Update(userID: number, userLogID: number): string {
	return `/v1/users/${userID}/logs/${userLogID}`;
}
