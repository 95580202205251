import { filterEmojis } from 'pkg/emoji';

/**
 *	Returns a cleaned string without markup and special chars and emojis
 *
 *	@param dirty Dirty string
 *	@param allowDiacritics If set to false, diacritics will be stripped
 */
export function clean(dirty: string, allowDiacritics: boolean = true): string {
	// Remove emojis
	let clean = filterEmojis(dirty);

	// Remove markup
	clean = clean.replace(/(<([^>]+)>)/gi, '');

	// Remove special chars
	clean = clean.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');

	if (!allowDiacritics) {
		clean = clean.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	// Remove exessive whitespace
	clean = clean.replace(/\s+/g, ' ');

	return clean;
}

export function localeIncludes(a: string, b: string): boolean {
	if (a.length && b.length) {
		return a.toLocaleLowerCase().includes(b.toLocaleLowerCase());
	}

	return false;
}

export const validateSwedishPersonalId = (personalId: string): boolean => {
	// Must match XXXXXXXX-XXXX
	const regex = /^(\d{8}-\d{4})$/;
	if (!regex.test(personalId)) {
		return false;
	}

	return true;
};

type Bigram = string[];

/**
 *  Returns a Bigram from string.
 *
 *  @param source string
 *
 *  @returns Bigram
 */
function toBigram(source: string): Bigram {
	const bigrams: Bigram = [];

	for (let n = 0, l = source.length - 1; n < l; n++) {
		bigrams.push(source.substring(n, n + 2));
	}

	return bigrams;
}

/**
 *  Returns the intersections of two bigrams.
 *
 *  @param source Bigram
 *  @param target Bigram
 *
 *  @returns Bigram
 */
function bigramIntersects(source: Bigram, target: Bigram): Bigram {
	return source.filter((bigram: string) => target.includes(bigram));
}

/**
 * Calculates Sørensen–Dice coefficient between two strings.
 * TL;DR: Dice Coefficient = bigram overlap * 2 / (bigrams in a + bigrams in b)
 *
 * @link https://en.wikipedia.org/wiki/S%C3%B8rensen%E2%80%93Dice_coefficient
 *
 * @param source string
 * @param target string
 *
 * @returns number
 */
export function compare(source: string, target: string): number {
	// Trim away exessive whitespace
	source = source.toLocaleLowerCase().replace(/ +/g, ' ');
	target = target.toLocaleLowerCase().replace(/ +/g, ' ');

	// Strings are equal, skip skip bigram dissect
	if (source === target) return 1;

	// Skip if any of input for empty or one character strings
	if (source.length <= 1 || target.length <= 1) return 0;

	const sourceBigrams = toBigram(source.trim());
	const targetBigrams = toBigram(target.trim());

	const numBigrams = sourceBigrams.length + targetBigrams.length;
	const numIntersects = bigramIntersects(sourceBigrams, targetBigrams).length;

	return (2.0 * numIntersects) / numBigrams;
}

/**
 *	@interface StringMatchRating
 *
 *	@property target string
 *	@property rating string
 */
export interface StringMatchRating {
	target: string;
	rating: number;
}

/**
 *	@interface StringMatch
 *
 *	@property ratings StringMatchRating[]
 *	@property closestMatch StringMatchRating
 *	@property closestMatchIndex number
 */
export interface StringMatch {
	ratings: StringMatchRating[];
	closestMatch: StringMatchRating;
}

/**
 *	Returns a StringMatch result based on closest string intersection, uses dice coefficient string comparison.
 *
 *	@see compare
 *
 *	@param source string
 *	@param targets string[]
 *	@param threshold number
 *
 *	@returns StringMatch
 */
export function findClosestMatch(
	source: string,
	targets: string[],
	threshold = 0
): StringMatch {
	let ratings: StringMatchRating[] = [];

	for (let n = 0, l = targets.length; n < l; n++) {
		const target = targets[n];
		const rating = compare(source, target);

		if (rating >= threshold) {
			ratings.push({ target, rating });
		}
	}

	ratings = ratings.sort(
		(a: StringMatchRating, b: StringMatchRating) => b.rating - a.rating
	);

	const closestMatch = ratings[0];

	return {
		ratings,
		closestMatch,
	};
}

export const emailRegexPattern: RegExp =
	/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const phoneRegexPattern: RegExp = /^(\+\d{1,19}|\d{1,20})$/;
