import { JSX, Fragment } from 'react';

import Link from 'pkg/router/Link';

import { ApiConfig } from 'components/application/apiconfig';
import { addFormatter } from 'components/formatted-content/FormattedContent';
import * as Internal from 'components/formatted-content/embeds/internal';
import JoinEmbed from 'components/formatted-content/embeds/join';
import FormEmbed from 'components/formatted-content/embeds/form';
import FormCategoryEmbed, {
	FormCategoryExternalEmbed,
} from 'components/formatted-content/embeds/form-category';

addFormatter('internal-embed-join', {
	isEmbed: true,
	defaultIgnored: false,
	selector: /((?:https?:\/{2})?.*\/join\/[a-z0-9]{6})/gi,
	decorator: (guid: string, match: string): JSX.Element => {
		const [, groupCode] = /join\/([a-z0-9]{6})?/gi.exec(match);

		return <JoinEmbed groupCode={groupCode} />;
	},
});

addFormatter('internal-embeds', {
	isEmbed: true,
	defaultIgnored: false,
	ignoreMatchSplit: true,
	selector:
		/(https?:\/{2}((app|dev|staging)\.360player\.com|localhost\:\d+)\/(?:organization\/\d+\/)?(video-playlists|videos|video-library\/collections|events)\/\d+(?:\/analyze|\/overview)?(?:\?.*)?)/i,
	decorator: (guid: string, match: string, meta): JSX.Element => {
		// Prevent double render due to nested capture group issue
		if (match !== meta.subject) {
			return null;
		}

		const result = match.match(
			/(?<domain>(?:https?:\/{2})((app|dev|staging)\.360player\.com|localhost\:\d+))\/(?:organization\/\d+\/)?(?<contentType>(?:video-playlists|videos|video-library\/collections|events))\/(?<contentId>\d+(?:\/analyze|\/overview)?(?:\?.*)?)/i
		);

		const { contentType, contentId } = result?.groups || {
			contentId: '0',
			contentType: '',
		};

		const id = Number.parseInt(contentId, 10);
		const query = new URLSearchParams(match.split('?').pop());
		const queryString = `?${query.toString()}`;

		let component = <span>{match}</span>;

		switch (contentType) {
			case 'videos':
				component = (
					<Internal.VideoEmbed contentId={id} queryString={queryString} />
				);
				break;
			case 'video-playlists':
				component = (
					<Internal.PlaylistEmbed contentId={id} queryString={queryString} />
				);
				break;
			case 'collections':
				component = (
					<Internal.CollectionEmbed contentId={id} queryString={queryString} />
				);
				break;
			case 'events':
				component = (
					<Internal.EventEmbed contentId={id} queryString={queryString} />
				);
				break;
		}

		return <Fragment>{component}</Fragment>;
	},
});

addFormatter('form-embeds', {
	isEmbed: true,
	defaultIgnored: false,
	ignoreMatchSplit: true,
	selector: (apiConfig: ApiConfig) => {
		const remoteUrl = apiConfig.formsServiceUrl.replace('//', '\\/\\/');
		const remotePattern = `${remoteUrl}\\/{1,}(?<slug>[\\w\\-0-9]{1,50})\\/form\\/(?<remoteGuid>[\\w-]+)`;
		const localPattern = `https?://${window.location.host}\\/registration/(?<orgSlug>[\\w\\-0-9]{1,50})/(?<localGuid>[\\w-]+)`;

		// Uses \\ to escape for single \
		const pattern = `(?<before>.*?)(${remotePattern}|${localPattern})`;

		return new RegExp(pattern, 'gi');
	},
	decorator: (guid: string, match: string, meta): JSX.Element => {
		let component = (
			<Link key={guid} href={match} target="_blank">
				{match}
			</Link>
		);

		if (meta?.matches?.groups) {
			const formGuid =
				meta.matches.groups?.remoteGuid ||
				meta.matches.groups?.localGuid ||
				null;

			if (formGuid) {
				component = (
					<Fragment>
						{meta.matches.groups?.before && <p>{meta.matches.groups.before}</p>}
						<FormEmbed guid={formGuid} fallback={component} />
					</Fragment>
				);
			}
		}

		return <Fragment>{component}</Fragment>;
	},
});

addFormatter('form-category-embeds', {
	isEmbed: true,
	defaultIgnored: false,
	ignoreMatchSplit: true,
	selector: (apiConfig: ApiConfig) => {
		const remoteUrl = apiConfig.formsServiceUrl.replace('//', '\\/\\/');
		const remotePattern = `${remoteUrl}\\/{1,}(?<slug>[\\w\\-0-9]{1,50})\\/form-categories\\/(?<publicId>[\\w-]+)\\/forms`;
		const localPattern = `https?://${window.location.host}/organization/(?<organizationId>\\d+)/registrations/category/(?<categoryId>\\d+)`;

		// Uses \\ to escape for single \
		const pattern = `(?<before>.*?)(${remotePattern}|${localPattern})`;

		return new RegExp(pattern, 'gi');
	},
	decorator: (guid: string, match: string, meta): JSX.Element => {
		if (meta?.matches?.groups) {
			const publicId = meta.matches.groups?.publicId || '';

			if (publicId !== '') {
				return <FormCategoryExternalEmbed publicId={publicId} />;
			} else {
				const categoryId = meta.matches.groups?.categoryId || '0';
				const cId = Number.parseInt(categoryId, 10);

				return <FormCategoryEmbed categoryId={cId} />;
			}
		}
	},
});
