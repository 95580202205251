import styled from 'styled-components';
import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import rgba from 'pkg/rgba';
import {
	useCurrentAccount,
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import Badge from 'components/Badge';
import Avatar from 'components/avatar';

import ContextSwitcher from 'components/navigation/menus/context_switch';

const HeaderWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1;
	height: 50%;

	background: ${({ theme }) =>
		theme.colorProfileGradient
			? `linear-gradient(155deg, ${theme.colorProfileGradient})`
			: rgba(styles.palette.navBar.backgroundColor)};

	color: ${({ theme }) => theme.accentColor || styles.palette.white};
`;

const Header = styled.div`
	min-height: 70px;
	width: 100%;
	position: relative;
	padding-top: var(--sat);

	& > div {
		min-height: 70px;
	}
`;

const UserWrapper = styled.div`
	position: absolute;
	top: calc(var(--sat) + 90px);
	width: 100%;
	padding: var(--spacing-3) var(--spacing-5);
	line-height: 1;
	display: grid;
	grid-gap: 0.5rem 1rem;
	grid-template-areas:
		'message avatar'
		'name avatar';

	@media ${styles.breakpoint.small} {
		grid-gap: var(--spacing-2) 1rem;
	}
`;

const WelcomeMessage = styled.h3`
	font-size: var(--font-size-base);
	font-weight: 400;
	opacity: 0.6;
	grid-area: message;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const UserName = styled.div`
	grid-area: name;
	font-size: var(--font-size-3xl);
	font-weight: var(--font-weight-bold);
	color: ${({ theme }) => theme.accentColor || styles.palette.white};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	a {
		color: ${({ theme }) => theme.accentColor || styles.palette.white};
	}
`;

const UserAvatarWrapper = styled.div`
	grid-area: avatar;
	justify-self: end;
`;

const UserAvatar = styled(Avatar)`
	width: 58px;
	height: 58px;
	border: 2px solid ${({ theme }) => theme.accentColor || styles.palette.white};
`;

const Branding = styled(Badge)`
	position: absolute;
	width: 300px;
	right: -50px;

	-webkit-mask-image: -webkit-gradient(
		linear,
		left top,
		right bottom,
		from(rgba(0, 0, 0, 0.1)),
		to(rgba(0, 0, 0, 0))
	);
`;

const SmallScreenHeader = (): JSX.Element => {
	const user = useCurrentUser();
	const account = useCurrentAccount();
	const membership = useCurrentMembership();
	const organization = useCurrentOrganization();

	if (!account.id && !user.id) {
		return null;
	}

	const firstName = account.firstName || user.firstName;

	return (
		<HeaderWrapper>
			<Branding badgeUrl={organization.profileImageUrl} />

			<Header>
				<ContextSwitcher />
			</Header>

			<UserWrapper>
				<WelcomeMessage>{t('Welcome back')}</WelcomeMessage>

				<UserName data-testid="user.profile.show">
					{membership.isOrganizationMembership ? (
						<Fragment>{firstName}</Fragment>
					) : (
						<Link
							href={routes.User.Profile.Show(
								organization.id,
								membership.groupId,
								user.id,
								'overview'
							)}>
							{firstName}
						</Link>
					)}
				</UserName>

				{membership.isOrganizationMembership ? (
					<UserAvatarWrapper>
						<UserAvatar user={user} />
					</UserAvatarWrapper>
				) : (
					<UserAvatarWrapper>
						<Link
							href={routes.User.Profile.Show(
								organization.id,
								membership.groupId,
								user.id,
								'overview'
							)}>
							<UserAvatar account={account} />
						</Link>
					</UserAvatarWrapper>
				)}
			</UserWrapper>
		</HeaderWrapper>
	);
};

export default SmallScreenHeader;
