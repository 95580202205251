import { ChangeEvent, Fragment, ReactNode, useState } from 'react';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';

import { useUserGoalCategories } from 'routes/organization/user-profile/development/user-goals';

import * as StepModal from 'components/step-modal';

import Form from 'components/form/Form';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

type UserGoalModalSaveHandler = (
	userGoal: models.userGoal.UserGoal
) => Promise<void>;

interface UserGoalModalHook {
	Component: ReactNode;

	open: (
		userGoal?: models.userGoal.UserGoal,
		onSave?: UserGoalModalSaveHandler
	) => void;
	close: () => void;
}

export function useUserGoalModal(
	onSave: UserGoalModalSaveHandler
): UserGoalModalHook {
	const t = useT();

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [goal, setGoal] = useState<models.userGoal.UserGoal>(null);

	const [state, setState, forceState] = useMixedState<models.userGoal.UserGoal>(
		{} as models.userGoal.UserGoal
	);

	const userGoalCategories = useUserGoalCategories();

	const handleFieldChange = (
		event: ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>
	) => {
		const fieldName = event.currentTarget.name;
		const fieldValue = event.currentTarget.value;

		if (fieldValue !== undefined) {
			setState({ [fieldName]: fieldValue });
		}
	};

	const open = (userGoal?: models.userGoal.UserGoal) => {
		setGoal(userGoal ?? null);
		setState({ ...userGoal });

		setIsOpen(true);
	};

	const close = () => {
		setGoal(null);
		setIsOpen(false);
		forceState({} as models.userGoal.UserGoal);
	};

	const handleModalNext = async () => {
		if (!state.category) {
			state.category = models.userGoal.UserGoalCategories.General;
		}

		await onSave(state);

		return true;
	};

	const hasGoal = goal !== null;
	const canGoNext = state.goal?.length > 0;

	const title = hasGoal ? t('Update goal') : t('Create new goal');
	const label = hasGoal ? t('Update goal') : t('Create goal');

	const Component = (
		<Fragment>
			{isOpen && (
				<StepModal.Base key="user-goal-modal" onClose={close}>
					<StepModal.Step
						key={goal ? 'update-user-goal' : 'create-user-goal'}
						title={title}
						nextLabel={label}
						canGoNext={canGoNext}
						onNext={handleModalNext}>
						<Form>
							<Column>
								<Input.Group label={t(`Goal title`)}>
									<Input.Field
										placeholder={t(`Eg. get better at heading`)}
										onChange={handleFieldChange}
										name="goal"
										value={state.goal}
										required
									/>
								</Input.Group>

								<Input.Group label={t(`Description`)}>
									<Input.Area
										name="description"
										minRows={2}
										placeholder={t(
											`Notes on how will you achieve this goal...`
										)}
										onChange={handleFieldChange}
										value={state.description}
									/>
								</Input.Group>

								<Input.Group label={t(`Type of goal`)}>
									<Input.Select
										name="category"
										onChange={handleFieldChange}
										value={state.category}>
										{userGoalCategories.entries.map(([value, label]) => (
											<option key={value} value={value}>
												{label}
											</option>
										))}
									</Input.Select>
								</Input.Group>
							</Column>
						</Form>
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);

	return {
		Component,

		open,
		close,
	};
}
