import { JSX, ChangeEvent, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { eventTypeLabels, eventTypeToTranslatedString } from 'pkg/models/event';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentGroup } from 'pkg/identity';

import { getWeekDaysOrder } from 'routes/scheduling/utils';
import { ScheduleItemFormState } from 'routes/scheduling/templates/modals/CreateScheduleItem';
import MultiDaySelect from 'routes/scheduling/templates/schedule_items/multi_day_select';

import Icon from 'components/icon';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import { SelectEventType } from 'components/event/SelectType';
import ResourceCardItem from 'components/scheduling/resources';
import InfoBox from 'components/form/info-box';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface FormProps {
	isEditForm?: boolean;
	groups: models.group.Group[];
	selectedGroup: number[];
	selectedResource: number[];
	primaryResource: number;

	selectResource: (id: number) => void;
	selectPrimaryResource: (resource: models.resource.Resource) => void;
	showGroupSelectModal: () => void;
	showSelectResourceModal: () => void;

	formState: ScheduleItemFormState;
	setFormState: (payload: Partial<ScheduleItemFormState>) => void;

	scheduleItem?: Partial<models.scheduleItem.ScheduleItem>;
	schedule: models.schedule.Schedule;
}

const ScheduleItemForm = ({
	groups,
	selectedGroup,
	selectedResource,
	showGroupSelectModal,
	showSelectResourceModal,
	scheduleItem,
	schedule,
	formState,
	setFormState,
	isEditForm,
	selectResource,
	selectPrimaryResource,
	primaryResource,
}: FormProps): JSX.Element => {
	const formContext = useFormContext();
	const startsAt = formContext.watch('startsAt');
	const eventType = formContext.watch('eventType');
	const currentGroup = useCurrentGroup();

	const { record: group } = useEndpoint<models.group.Group>(
		endpoints.Groups.Show(selectedGroup[0])
	);
	const { records: resources } = useCollection<models.resource.Resource>(
		endpoints.Resource.Index(),
		{
			queryParams: new URLSearchParams({
				group_id: currentGroup.id.toString(),
			}),
		}
	);

	const parentGroup = groups.find((g) => g.id === group.parentGroupId);

	const scheduleDays = getWeekDaysOrder(
		schedule.startingDay,
		schedule.days,
		true
	);

	const validateEndTime = (value: string) => {
		const starts = Number.parseInt(startsAt.replace(':', ''), 10);
		const ends = Number.parseInt(value.replace(':', ''), 10);

		if (starts > ends) {
			return false;
		}

		return true;
	};

	const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFormState({
			[e.target.name]: e.target.value,
		});
	};

	const handleSelectDay = (index: number) => {
		const arr = [...formState.days];
		if (isEditForm) {
			setFormState({ days: [index] });
		} else if (formState.days.includes(index)) {
			setFormState({
				days: arr.filter((i) => i !== index),
			});
		} else {
			setFormState({ days: [...arr, index] });
		}
	};

	const setPrimary = (resource: models.resource.Resource) =>
		selectPrimaryResource(resource);

	const handleRemoveResource = (id: number) => {
		selectResource(id);
	};

	const hasResourceOrGroup =
		!!selectedResource.length || !!selectedGroup.length;

	return (
		<Fragment>
			<Column spacing={styles.spacing._7}>
				<Input.Group label={t('Time')}>
					<div className={css['time-row']}>
						<Row align="center" autoColumns="max-content">
							<Input.Field
								name="startsAt"
								type="time"
								value={formState.startsAt}
								required
								onChange={handleTimeChange}
								small
							/>
							<Icon name="arrow-right" size={1.6} />
							<Input.Field
								name="endsAt"
								type="time"
								value={formState.endsAt}
								required
								validate={validateEndTime}
								onChange={handleTimeChange}
								small
							/>
						</Row>
						<MultiDaySelect
							selectedDays={formState.days}
							scheduleDays={scheduleDays}
							handleSelectDay={handleSelectDay}
						/>
					</div>
				</Input.Group>

				<Input.Group label={t('Group')}>
					{group.id && selectedGroup.length ? (
						<div className={css['group-name']} onClick={showGroupSelectModal}>
							{!!parentGroup
								? `${parentGroup?.name} - ${group.name}`
								: group.name}
							<Icon name="edit" />
						</div>
					) : (
						<Button
							icon="groups"
							secondary
							large
							label={t('Select group')}
							onClick={showGroupSelectModal}
						/>
					)}
				</Input.Group>
				<Input.Group label={t('Resources')}>
					{selectedResource.length > 0 && (
						<Column spacing={styles.spacing._3}>
							{resources &&
								selectedResource.map((id) => (
									<ResourceCardItem
										key={id}
										resource={resources.find((r) => r.id === id)}
										isPrimary={primaryResource === id}
										actions={
											<Context.Menu
												toggleWith={<Button icon="more_horiz" secondary />}>
												<Context.Item
													onClick={() =>
														setPrimary(resources.find((r) => r.id === id))
													}>
													<Context.ItemIcon name="home_pin" />
													{t('Set as main resource')}
												</Context.Item>
												<Context.Item onClick={() => handleRemoveResource(id)}>
													<Context.ItemIcon name="cancel" />
													{t('Remove')}
												</Context.Item>
											</Context.Menu>
										}
									/>
								))}
						</Column>
					)}

					<Button
						icon="location_on"
						secondary
						large
						label={t('Select resources')}
						onClick={showSelectResourceModal}
					/>
				</Input.Group>

				{!hasResourceOrGroup && (
					<InfoBox
						color="red"
						text={t(
							'You need to select a group or resource to create a booking.'
						)}
					/>
				)}

				<Input.Group label={t('Event type')}>
					<SelectEventType
						name="eventType"
						defaultValue={eventType || scheduleItem?.type}
						eventType={eventType || scheduleItem?.type}>
						{eventTypeLabels.map((eventType) => (
							<option key={eventType} value={eventType}>
								{eventTypeToTranslatedString(eventType)}
							</option>
						))}
					</SelectEventType>
				</Input.Group>

				<Input.Group label={t('Booking title')} required>
					<Input.Field
						name="title"
						defaultValue={scheduleItem?.title}
						required
					/>
				</Input.Group>

				<Input.Group label={t('Description')}>
					<Input.Area
						name="description"
						defaultValue={scheduleItem?.description}
						minrows={4}
					/>
				</Input.Group>
			</Column>
		</Fragment>
	);
};

export default ScheduleItemForm;
