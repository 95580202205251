import { JSX } from 'react';
import { t } from '@transifex/native';

import { ISO3166Alpha2Codes } from 'pkg/countries';
import * as models from 'pkg/api/models';

import DataRow from 'containers/user/profile/info-contact/DataRow';
import LoginMethods from 'containers/user/LoginMethods';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import { DataRowProps } from 'components/user-address/DataList';

interface ContactInformationProps {
	user: models.user.User;
}

export default function ContactInformation({
	user,
}: ContactInformationProps): JSX.Element {
	const labels = {
		email: t(`Email`),
		phoneNumber: t(`Cellphone number`),
		address: t(`Street address`),
		postalCode: t(`ZIP code`),
		city: t(`City`),
		region: t(`State or region`),
		country: t(`Country`),
	};

	const rows: DataRowProps[] = [];

	// Email
	rows.push({
		label: labels.email,
		value: user.email ? (
			<a href={`mailto:${user.email}`}>{user.email}</a>
		) : null,
	});

	rows.push({
		label: t('Login methods'),
		value: <LoginMethods userId={user.id} accountId={user?.accountId} />,
	});

	// Phone number
	rows.push({
		label: labels.phoneNumber,
		value: user.phoneNumber ? (
			<a href={`tel:${user.phoneNumber}`}>{user.phoneNumber}</a>
		) : null,
	});

	// Street address
	rows.push({
		label: labels.address,
		value: user.address,
	});

	// Postal code
	rows.push({
		label: labels.postalCode,
		value: user.postalCode,
	});

	// City
	rows.push({
		label: labels.city,
		value: user.city,
	});

	// State/region
	rows.push({
		label: labels.region,
		value: user.region,
	});

	// Country
	rows.push({
		label: labels.country,
		value: user.country
			? ISO3166Alpha2Codes().find((c) => c.code === user.country.toLowerCase())
					?.country
			: null,
	});

	if (rows.length === 0) {
		return null;
	}

	return (
		<Column>
			<SectionTitle icon="mail">{t(`Contact information`)}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					{rows.map((row, i) => (
						<DataRow key={i} label={row.label} value={row.value} />
					))}
				</Card.Body>
			</Card.Base>
		</Column>
	);
}
