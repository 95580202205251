import { t } from '@transifex/native';
import { Dispatch } from 'redux';

import UserProduct from 'pkg/models/user_product';

import * as actionTypes from 'pkg/actions/action-types';
import * as services from 'pkg/actions/services/user_products';
import { normalizedDispatch } from 'pkg/actions/utils';
import * as flashActions from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import store from 'pkg/store/createStore';
import { crash } from 'pkg/errors/errors';

const removeItem = (userProductId: number) => ({
	type: actionTypes.UserProducts.DELETE_ITEM,
	payload: { userProductId },
});

export const createRelation =
	(payload: services.RelationProps) =>
	async (dispatch: Dispatch): Promise<void> => {
		const [request, response] = await models.create<
			services.RelationProps,
			UserProduct
		>(endpoints.UserProduct.Create(), payload);

		if (!request.ok) {
			flashActions.show({
				title: crash().title,
			});

			return;
		}

		flashActions.show({
			title: t(`Successfully assigned product`),
		});

		normalizedDispatch(response, [UserProduct.normalizr()])(dispatch);
	};

export const removeRelation = async (id: number): Promise<void> => {
	const request = await services.removeRelation(id);

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
		});

		return;
	}

	flashActions.show({
		title: t(`Successfully removed assigned product.`),
	});

	store.dispatch(removeItem(id));
};

export const updateRelation = async (
	id: number,
	validUntil: number
): Promise<void> => {
	const request = await services.updateRelation(id, validUntil);

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
		});

		return;
	}

	flashActions.show({
		title: t(`Successfully updated assigned product`),
	});

	const response = await request.json();

	normalizedDispatch(response, UserProduct.normalizr())(store.dispatch);
};
