import { JSX, ChangeEvent, Fragment, ReactNode } from 'react';
import { t } from '@transifex/native';

import { ISO3166Alpha2Codes } from 'pkg/countries';

import * as Input from 'components/form/inputs';

export interface CountrySelectProps {
	name: string;

	placeholder?: string;
	suggestedPlaceholder?: string;
	role?: 'country' | 'nationality';

	value?: string;
	defaultValue?: string;
	suggestedCountry?: string;

	disabled?: boolean;
	required?: boolean;
	testid?: string;
	className?: string;

	countryValue?: boolean;

	onChange?: (event: ChangeEvent<HTMLOptionElement>) => void;
}

export default function CountrySelect({
	name,
	placeholder,
	suggestedPlaceholder,
	role = 'country',
	value,
	defaultValue,
	suggestedCountry,

	disabled,
	required,
	testid,
	className,

	onChange,
}: CountrySelectProps): JSX.Element {
	const isNationality = role === 'nationality';
	const countryCodes = ISO3166Alpha2Codes().sort((a, b) =>
		isNationality
			? a.nationality.localeCompare(b.nationality)
			: a.country.localeCompare(b.country)
	);

	const showSuggestedCountry =
		suggestedCountry && countryCodes.find((c) => c.code === suggestedCountry);

	if (!placeholder) {
		placeholder = t('Select country');

		if (isNationality) {
			placeholder = t('Select nationality');
		}
	}

	if (!suggestedPlaceholder) {
		suggestedPlaceholder = t('Suggested country');

		if (isNationality) {
			suggestedPlaceholder = t('Suggested nationality');
		}
	}

	let suggestedOption: ReactNode = null;

	let countryOptions: ReactNode = (
		<Fragment>
			{countryCodes.map((country) => (
				<option key={country.code} value={country.code}>
					{isNationality ? country.nationality : country.country}
				</option>
			))}
		</Fragment>
	);

	if (showSuggestedCountry?.code) {
		suggestedOption = (
			<optgroup label={suggestedPlaceholder}>
				<option value={showSuggestedCountry.code}>
					{isNationality
						? showSuggestedCountry.nationality
						: showSuggestedCountry.country}
				</option>
			</optgroup>
		);

		countryOptions = <optgroup label={placeholder}>{countryOptions}</optgroup>;
	}

	return (
		<Input.Select
			name={name}
			value={value}
			defaultValue={defaultValue}
			onChange={onChange}
			className={className}
			disabled={disabled}
			required={required}
			testid={testid}>
			<option hidden={!!showSuggestedCountry?.code} value="">
				{placeholder}
			</option>
			{suggestedOption}
			{countryOptions}
		</Input.Select>
	);
}
