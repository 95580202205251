import { JSX, useEffect, useState } from 'react';

import { PricePayload } from 'pkg/actions/services/products';

import { decimalToCentsByCurrency } from 'pkg/utils';
import useMixedState from 'pkg/hooks/useMixedState';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentGroup } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';

import {
	AddProductContext,
	emptyPriceOption,
	ProductMixedState,
} from 'routes/payments/products/create';
import AddForm from 'routes/payments/products/form';
import { validatePriceOptions } from 'routes/payments/products/form/price_option';

import { Spinner } from 'components/loaders/spinner';

interface ReturnProps {
	canSaveCreateProductForm: boolean;

	NewProductForm: JSX.Element;
	onCreateNewProduct: () => Promise<models.product.Product>;
}

const useNewProduct = (groupId: number): ReturnProps => {
	const { record: providerSettings, isLoading: isLoadingProviderSettings } =
		useEndpoint<models.providerSettings.ProviderSettings>(
			endpoints.Groups.PaymentProviderSettings(groupId)
		);

	const { record: metadataKeys, isLoading: loadingProductMetadata } =
		useEndpoint<string[]>(endpoints.ProductMetadata.Index(), {
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
			}),
		});

	const [formState, setFormState] = useMixedState<ProductMixedState>({
		category: '',
		productTitle: '',
		description: '',
		isDefault: false,
		metadata: [],
		priceOptions: [],
	});
	const [saving, setSaving] = useState(false);

	const group = useCurrentGroup();

	const { records: taxRates, isLoading: isLoadingTaxRates } =
		useCollection<models.taxRate.TaxRate>(endpoints.TaxRates.Index(groupId), {
			queryParams: new URLSearchParams({
				active: 'true',
			}),
		});

	const {
		records: productCategoriesRecords,
		isLoading: isLoadingProductCategories,
		refresh: refreshProductCategories,
	} = useCollection<models.productCategory.ProductCategory>(
		endpoints.ProductCategory.List(),
		{
			queryParams: new URLSearchParams({
				group_id: groupId.toString(),
			}),
		}
	);

	useEffect(() => {
		if (!!group.currency && providerSettings?.availableRecurringOptions) {
			setFormState({
				...formState,
				priceOptions: [emptyPriceOption(group.currency, providerSettings)],
			});
		}
	}, [group.currency, providerSettings?.availableRecurringOptions]);

	const handlePriceSave = async (productId: number) => {
		const item = formState.priceOptions[0];
		const payload: PricePayload = {
			productId,
			cost: decimalToCentsByCurrency(item.cost, item.currency),
			currency: item.currency.toLowerCase(),
			recurring: item.recurring,
			taxRateId: Number.parseInt(item.taxRateId, 10),
			title: item.priceTitle,
			description: '',
			recurringInterval: item.recurringInterval,
			recurringIntervalCount: item.recurringIntervalCount,
		};

		if (item.description.length > 0) {
			payload.description = item.description;
		}

		const [req, resp] = await actions.prices.create(
			payload,
			item.recurringOption,
			providerSettings
		);

		if (req.ok) {
			return resp;
		}
	};

	const handleCreateProduct = async () => {
		setSaving(true);

		const productPayload: models.product.ProductCreatePayload = {
			name: formState.productTitle,
			groupId,
			isDefault: false,
		};

		if (!!formState.category) {
			productPayload.productCategoryId = Number.parseInt(
				formState.category,
				10
			);
		}

		if (formState.description.length > 0) {
			productPayload.description = formState.description;
		}

		if (formState.metadata.length > 0) {
			productPayload.metadata = formState.metadata.map((m) => {
				return {
					key: m.key,
					value: m.value,
				};
			});
		}

		const [req, resp] = await models.create<
			models.product.ProductCreatePayload,
			models.product.Product
		>(endpoints.Products.Index(), productPayload);

		if (req.ok) {
			const prices = await handlePriceSave(resp.id);
			return { ...resp, prices: [prices] } as models.product.Product;
		}

		setSaving(false);
		return resp;
	};

	const validProductInfo = !!formState.productTitle;

	const canSave =
		validatePriceOptions(formState.priceOptions).length ===
			formState.priceOptions.length &&
		validProductInfo &&
		!saving;

	return {
		canSaveCreateProductForm: canSave,
		onCreateNewProduct: handleCreateProduct,
		NewProductForm:
			isLoadingProviderSettings ||
			loadingProductMetadata ||
			isLoadingTaxRates ||
			isLoadingProductCategories ? (
				<Spinner />
			) : (
				<AddProductContext.Provider
					value={{
						formState,
						setFormState,
						taxRates,
						providerSettings,
						metadataKeys,
					}}>
					<AddForm
						productCategories={productCategoriesRecords}
						refreshCategories={refreshProductCategories}
						singlePriceOption
					/>
				</AddProductContext.Provider>
			),
	};
};

export default useNewProduct;
