import { JSX } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import { useEventContext } from 'routes/event/single';
import EventUserActions from 'routes/event/user-actions';

import { IconName } from 'components/icon';

import StatusPickerTrigger from 'components/event/status-picker';

interface EventUserStatusProps {
	eventUser: models.eventUser.EventUser;
}

export default function EventUserStatus({
	eventUser,
}: EventUserStatusProps): JSX.Element {
	const eventCtx = useEventContext();
	const event = eventCtx.eventEndpointResponse.record;

	let icon: IconName = 'check-filled';
	let color = styles.palette.green[500];

	if (models.event.hasEnded(event)) {
		switch (eventUser.attendanceStatus) {
			case models.eventUser.AttendanceStatuses.Attended:
				icon = 'check-filled';
				color = styles.palette.green[500];
				break;
			case models.eventUser.AttendanceStatuses.Unset:
				icon = 'help-circle-filled';
				color = styles.palette.orange[500];
				break;
			default:
				icon = 'close-circle-filled';
				color = styles.palette.red[500];
		}
	} else {
		switch (eventUser.status) {
			case models.eventUser.EventUserStatus.Accepted:
				icon = 'check-filled';
				color = styles.palette.green[500];
				break;
			case models.eventUser.EventUserStatus.Declined:
				icon = 'close-circle-filled';
				color = styles.palette.red[500];
				break;
			case models.eventUser.EventUserStatus.Pending:
				icon = 'help-circle-filled';
				color = styles.palette.orange[500];
				break;
		}
	}

	if (models.event.isCanceled(event)) return null;

	return (
		<EventUserActions
			eventUser={eventUser}
			event={event}
			refresh={eventCtx.eventEndpointResponse.refresh}
			toggleWith={<StatusPickerTrigger color={color} icon={icon} />}
		/>
	);
}
