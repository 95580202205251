import { JSX, Fragment, useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';

import * as styles from 'pkg/config/styles';

import Membership from 'pkg/models/membership';
import { Features } from 'pkg/models/group';

import * as actions from 'pkg/actions';
import { useCheckRelationToUser, useGroup, useUser } from 'pkg/hooks/selectors';
import { useCurrentSport } from 'pkg/identity';
import * as models from 'pkg/api/models';

import LatestReview from 'containers/user/profile/development/performance-review/Preview';
import PositionMatch from 'containers/user/profile/development/position-match/Preview';
import Goals from 'containers/user/profile/development/goals/Preview';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';

type AppProps = {
	groupId: number;
	userId: number;
	membership: Membership;
};

const Development = memo(
	({ groupId, userId, membership }: AppProps): JSX.Element => {
		const [loading, setLoading] = useState(true);
		const dispatch = useDispatch();

		const group = useGroup(groupId);
		const user = useUser(userId);

		const groupSport = useCurrentSport();

		const { isParentToUser } = useCheckRelationToUser(userId);

		useEffect(() => {
			const fetchData = async (): Promise<void> => {
				const forAccount = isParentToUser ? user.accountId : null;

				await Promise.all([
					dispatch(
						actions.performanceReview.fetchPerformanceReviews(
							userId,
							groupId,
							false,
							forAccount
						)
					),
					dispatch(actions.userGoals.fetchUserGoals(userId, 1, forAccount)),
				]);

				setLoading(false);
			};

			fetchData();
		}, [dispatch, groupId, userId]);

		return !loading ? (
			<Column spacing={styles.spacing._8}>
				{group.hasFeature(Features.IndividualDevelopment) && (
					<Fragment>
						<Goals groupId={groupId} userId={userId} />
						<LatestReview groupId={groupId} userId={userId} />
					</Fragment>
				)}
				{membership.isPlayer() &&
					models.sport.hasRatingsEnabled(groupSport) &&
					group.hasFeature(Features.Assessments) && (
						<Fragment>
							<PositionMatch groupId={groupId} userId={userId} />
						</Fragment>
					)}
			</Column>
		) : (
			<Spinner />
		);
	}
);

export default Development;
