import { t } from '@transifex/native';
import { JSX, Fragment, useState } from 'react';
import { List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import UserGoal from 'pkg/models/user_goal';

import * as models from 'pkg/api/models';
import { RootState } from 'pkg/reducers';
import * as actions from 'pkg/actions';
import * as selectors from 'pkg/selectors';
import { useCheckRelationToUser, useUser } from 'pkg/hooks/selectors';
import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import GoalList from 'containers/user/profile/development/goals/List';

import AssetImage from 'components/AssetImage';
import SectionTitle from 'components/SectionTitle';

import { UserGoalForm } from 'components/user/profile/development/goals/Form';
import Column from 'components/layout/column';
import ButtonWrapper from 'components/user/profile/development/ButtonWrapper';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

type Props = {
	groupId: number;
	userId: number;
};

const Goals = ({ groupId, userId }: Props): JSX.Element => {
	const org = useCurrentOrganization();
	const [showGoalForm, setShowGoalForm] = useState(false);

	const dispatch = useDispatch();

	const user = useUser(userId);

	const goals: List<UserGoal> = useSelector((state: RootState) =>
		selectors.userGoals.findAllUserActiveGoals(state, userId)
	);
	const completedGoals: List<UserGoal> = useSelector((state: RootState) =>
		selectors.userGoals.findAllUserCompletedGoals(state, userId)
	);

	const { isParentToUser } = useCheckRelationToUser(userId);
	const forAccount = isParentToUser ? user.accountId : null;

	const saveGoal = async (goal: UserGoal): Promise<void> => {
		await dispatch(
			actions.userGoals.createGoal(
				userId,
				goal as unknown as models.userGoal.UserGoal,
				forAccount
			)
		);
		setShowGoalForm(false);
	};

	const showGoal = (): void => setShowGoalForm(true);

	const closeGoal = (): void => setShowGoalForm(false);

	return (
		<Column>
			<SectionTitle>
				{t(`Active goals`)}

				{goals.size > 0 && (
					<Button
						icon="add"
						inline
						noPadding
						label={t(`Add goal`, {
							_context: 'user profile development',
						})}
						onClick={showGoal}
					/>
				)}
			</SectionTitle>
			{goals.size > 0 ? (
				<GoalList goals={goals} />
			) : (
				<MissingEntities
					noBorder
					title={t(`Set up goals to track individual progress!`)}
					helpUrl="https://help.360player.com/article/89-how-to-create-development-goals"
					description={t(
						`With individual goals you can track progress in an easy and structured way.`
					)}
					actions={
						<Fragment>
							<Button
								secondary
								icon="add"
								label={t(`Create new goal`)}
								onClick={showGoal}
							/>
							{completedGoals.size > 0 && (
								<Button
									label={t(`Show history`)}
									href={routes.User.Profile.Goals(org.id, groupId, userId)}
								/>
							)}
						</Fragment>
					}
					image={
						<AssetImage
							className="temporary"
							src="img/missing-entities/goals.svg"
						/>
					}
				/>
			)}
			{goals.size > 0 && (
				<ButtonWrapper>
					<Button
						block
						href={routes.User.Profile.Goals(org.id, groupId, userId)}
						label={t(`View all`)}
					/>
				</ButtonWrapper>
			)}
			{showGoalForm && <UserGoalForm close={closeGoal} saveGoal={saveGoal} />}
		</Column>
	);
};

export default Goals;
