import { JSX, useState, ChangeEvent } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import StepModal, { Step } from 'components/step-modal';
import * as Card from 'components/Card';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import FormComponent from 'components/form/Form';

interface CategoryModalProps {
	handleCategoryModalClose: () => void;
	handleCreateNewCategory: (payload: { title: string }) => void;
	isEditingCategory: boolean;
	handleDeleteCategory?: () => Promise<boolean>;
	categoryInEdit?: { title: string };
}

export default function CategoryModal({
	handleCategoryModalClose,
	handleCreateNewCategory,
	isEditingCategory,
	handleDeleteCategory,
	categoryInEdit,
}: CategoryModalProps): JSX.Element {
	const [categoryTitle, setCategoryTitle] = useState<string>(
		categoryInEdit?.title || ''
	);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setCategoryTitle(event.target.value);
	};

	const handleSave = async () => {
		handleCreateNewCategory({ title: categoryTitle });
	};

	return (
		<FormComponent onSubmit={handleSave}>
			<StepModal onClose={handleCategoryModalClose}>
				<Step
					title={isEditingCategory ? t('Edit Category') : t('New Category')}
					skipBody
					nextLabel={t('Save')}
					closeOnNext={true}
					onNext={handleSave}
					showDelete={isEditingCategory}
					onDelete={handleDeleteCategory}>
					<Card.Body>
						<Column spacing={styles.spacing._5}>
							<Input.Group label={t('Category Title')}>
								<Input.Field
									name="category_title"
									placeholder={t('Type category name')}
									value={categoryTitle}
									onChange={handleInputChange}
								/>
							</Input.Group>
						</Column>
					</Card.Body>
				</Step>
			</StepModal>
		</FormComponent>
	);
}
