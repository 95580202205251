import { JSX, Fragment, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';

import { useTrainingLibraryFilters } from 'routes/training/library/hooks';

import Icon from 'components/icon';

import AutoComplete, {
	AutoCompleteSuggestions,
} from 'components/form/inputs/autocomplete';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

export default function TagSearchInput(): JSX.Element {
	const [prefix, setPrefix] = useState<string>();
	const filters = useTrainingLibraryFilters();

	const { records: tags } = useCollection<models.tag.Tag>(
		prefix ? endpoints.Tag.Index() : null,
		{
			queryParams: new URLSearchParams({ prefix }),
		}
	);

	const suggestions: AutoCompleteSuggestions = {
		[t('Tags')]: {
			items: tags,
			onSelect: (item: models.tag.Tag) => {
				filters.addTag(item.name);
			},
			renderWith: (item: models.tag.Tag) => (
				<Row columns="auto 1fr" align="center">
					<Icon name="tag" size={1.3} />
					<span>{item.name}</span>
				</Row>
			),
		},
	};

	const handleDidType = (value: string): void => {
		setPrefix(value);
	};

	const handleSelectFallback = (title: string) => {
		filters.setTitle(title);
	};

	const isLargeScreen = useMediaQuery({
		minWidth: styles.breakpoint.fromMedium,
	});

	return (
		<Fragment>
			<AutoComplete
				small={isLargeScreen}
				placeholder={t('Search for sessions, exercises and collections...')}
				testid="tag-search-input"
				suggestions={suggestions}
				onDidType={handleDidType}
				onSelectFallback={handleSelectFallback}
				renderInitialWith={(inputValue: string) => (
					<T _str="Search for {keyword}" keyword={<mark>{inputValue}</mark>} />
				)}>
				<Input.Prefix inline>
					<Icon name="search" size={1.35} />
				</Input.Prefix>
			</AutoComplete>
		</Fragment>
	);
}
