import { JSX, useEffect } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { useCheckRelationToUser, useUser } from 'pkg/hooks/selectors';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

import BaseInformation from 'containers/user/profile/info-contact/BaseInformation';
import BillingAddressInformation from 'containers/user/profile/info-contact/BillingAddressInformation';
import ContactInformation from 'containers/user/profile/info-contact/ContactInformation';
import OptionalInformation from 'containers/user/profile/info-contact/OptionalInformation';
import RelativesContactInformation from 'containers/user/profile/info-contact/RelativesContactInformation';
import CustomUserFields from 'containers/user/profile/info-contact/CustomUserFields';

import Icon from 'components/icon';

import Column from 'components/layout/column';
import RelativeView from 'components/user/profile/overview/RelatedUsers';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

interface InfoContactProps {
	userId: number;
	setPageActions?: (trigger: JSX.Element) => void;
}

export default function InfoContact({
	userId,
	setPageActions,
}: InfoContactProps): JSX.Element {
	const user = useUser(userId);
	const org = useCurrentOrganization();
	const activeMembership = useCurrentMembership();

	const { isSelfOrParentToUser } = useCheckRelationToUser(userId);

	const isAdminOrStaff = models.membership.isAdminOrStaff(activeMembership);
	const isAdmin = models.membership.isAdmin(activeMembership);

	useEffect(() => {
		if (setPageActions && isSelfOrParentToUser) {
			setPageActions(
				<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
					<ContextMenu.LinkItem
						href={routes.User.Settings(org.id, userId)}
						testid="user_profile.settings_link">
						<ContextMenu.ItemIcon name="edit" />
						{t(`Edit profile`)}
					</ContextMenu.LinkItem>
				</ContextMenu.Menu>
			);

			return (): void => setPageActions(null);
		}
	}, [userId, isSelfOrParentToUser]);

	if (!user.id) {
		return;
	}

	let displayRelatives = false;

	if (isAdminOrStaff) {
		displayRelatives = true;
	} else if (isSelfOrParentToUser) {
		displayRelatives = true;
	}

	if (!user.accountId) {
		displayRelatives = false;
	}

	if (activeMembership.isOrganizationMembership) {
		displayRelatives = false;
	}

	return (
		<Column spacing={styles.spacing._5}>
			{isSelfOrParentToUser && (
				<Button
					href={routes.User.Settings(org.id, user.id)}
					testid="user_profile.settings_link"
					icon="edit">
					{t(`Edit profile`)}
				</Button>
			)}

			{displayRelatives && <RelativeView userId={user.id} isUserProfile />}

			<BaseInformation user={user} />

			<ContactInformation user={user} />

			{user.fields?.length > 0 && (isAdminOrStaff || isSelfOrParentToUser) && (
				<CustomUserFields user={user} />
			)}

			<BillingAddressInformation user={user} />

			<RelativesContactInformation user={user} />

			<OptionalInformation user={user} />

			{isAdmin && (
				<Link href={routes.Contact.Fields(org.id, user.id)}>
					<Button block>{t('View all')}</Button>
				</Link>
			)}
		</Column>
	);
}
