import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { CollectionResponse } from 'pkg/api/use_collection';
import * as actions from 'pkg/actions';
import useTooltip from 'pkg/hooks/useTooltip';
import { useCurrentOrganization } from 'pkg/identity';
import { newChatParams } from 'pkg/chat';
import { link } from 'pkg/router/utils';

import OrderActions from 'routes/payments/orders/context_menu_actions';
import RefundModal from 'routes/payments/orders/single/refund_modal';

import Icon from 'components/icon';

import StatusLabel from 'components/payment_platform/status/label';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';

interface ItemProps {
	order: models.order.Order;
	currency: string;
	// The current user we're viewing, to determine paid by/paid on behalf of logic
	userId: number;

	orderCollection: CollectionResponse<models.order.Order>;
}

interface LinkedUserContextProps {
	user: models.user.User;
}

export function LinkedUserContext({
	user,
}: LinkedUserContextProps): JSX.Element {
	const org = useCurrentOrganization();

	return (
		<ContextMenu.Menu
			toggleWith={
				<ContextMenu.ButtonTrigger>
					<Icon name="context-menu" />
				</ContextMenu.ButtonTrigger>
			}>
			<ContextMenu.LinkItem
				href={routes.Management.Contact.Show(org.id, user.id, 'overview')}>
				<ContextMenu.ItemIcon name="arrow_forward" />
				{t('View')}
			</ContextMenu.LinkItem>
			{user.accountId && (
				<ContextMenu.LinkItem
					href={link(routes.Chat.New(org.id), newChatParams([user]))}>
					<ContextMenu.ItemIcon name="add_comment" />
					{t('Chat')}
				</ContextMenu.LinkItem>
			)}

			<ContextMenu.LinkItem href={`mailto:${user.email}`}>
				<ContextMenu.ItemIcon name="mail" />
				{t('Send email')}
			</ContextMenu.LinkItem>
		</ContextMenu.Menu>
	);
}

function InvoiceItem({ order, currency, orderCollection }: ItemProps) {
	const org = useCurrentOrganization();

	const [refundModal, setRefundModal] = useState(false);

	const dueDate = new DateTime(new Date(order.dueDate * 1000));

	const handleRefund = async () => {
		if (order.flags.includes('auto_refundable')) {
			setRefundModal(true);
		} else {
			const resp = await actions.order.refund(order.id);

			if (resp) {
				orderCollection.replaceRecord({
					...order,
					status: models.order.Statuses.Refunded,
				});
			}
		}
	};

	const users: {
		[key: number]: models.user.User;
	} = {};
	order.userProducts?.forEach((userProduct) => {
		users[userProduct.user.id] = userProduct.user;
	});

	const assignedContacts = [
		...new Map(
			order.userProducts
				?.map((userProduct) => userProduct.user)
				?.map((user) => [user['id'], user])
		).values(),
	];

	const assignedContact =
		assignedContacts.length > 0 ? assignedContacts[0] : null;

	const { onMouseEnter, tooltip } = useTooltip(
		assignedContacts.map((contact) => models.user.fullName(contact)).join(', ')
	);

	let assignedContactsContent = null;

	if (assignedContacts.length === 0) {
		assignedContactsContent = <span>-</span>;
	} else if (assignedContacts.length === 1) {
		assignedContactsContent = (
			<span>{models.user.fullName(assignedContact)}</span>
		);
	} else {
		assignedContactsContent = (
			<Fragment>
				{tooltip}
				<span onMouseEnter={onMouseEnter}>
					{t('{amount} contacts', {
						amount: assignedContacts.length,
					})}
				</span>
			</Fragment>
		);
	}

	const hideModal = () => setRefundModal(false);

	const orderShowUrl = routes.Order.Show(org.id, order.id);

	const invoiceContext = (
		<ContextMenu.Menu
			toggleWith={
				<ContextMenu.TableTrigger>
					<Icon name="context-menu" />
				</ContextMenu.TableTrigger>
			}>
			<ContextMenu.LinkItem href={orderShowUrl}>
				<ContextMenu.ItemIcon name="arrow_forward" />
				<span>{t('View details')}</span>
			</ContextMenu.LinkItem>
			{order.customerUser.accountId && (
				<ContextMenu.LinkItem
					href={link(
						routes.Chat.New(org.id),
						newChatParams([order.customerUser])
					)}>
					<ContextMenu.ItemIcon name="add_comment" />
					{t('Chat')}
				</ContextMenu.LinkItem>
			)}

			<ContextMenu.LinkItem href={`mailto:${order.customerUser.email}`}>
				<ContextMenu.ItemIcon name="mail" />
				{t('Send email')}
			</ContextMenu.LinkItem>
			<OrderActions
				order={order}
				showRefundModal={handleRefund}
				replaceRecord={orderCollection.replaceRecord}
				removeRecord={orderCollection.removeRecord}
			/>
		</ContextMenu.Menu>
	);

	return (
		<Fragment>
			<Table.Row>
				<Table.LinkCell href={orderShowUrl}>
					{order.invoiceNumber || '-'}
				</Table.LinkCell>
				<Table.LinkCell href={orderShowUrl}>
					{formatToCurrency(order.amountDue / 100, currency)}
				</Table.LinkCell>
				<Table.LinkCell href={orderShowUrl}>
					<StatusLabel status={models.order.getStatus(order)} />
				</Table.LinkCell>
				<Table.LinkCell
					href={routes.Management.Contact.Show(
						org.id,
						order.customerUser.id,
						'overview'
					)}>
					{models.user.fullName(order.customerUser)}
				</Table.LinkCell>
				<Table.LinkCell href={orderShowUrl}>
					{assignedContactsContent}
				</Table.LinkCell>

				<Table.LinkCell href={orderShowUrl}>
					{!!order.dueDate && `${dueDate.toLocaleDateString()}`}
				</Table.LinkCell>
				<Table.ActionCell>{invoiceContext}</Table.ActionCell>
			</Table.Row>

			{refundModal && (
				<RefundModal order={order} hideModal={hideModal} currency={currency} />
			)}
		</Fragment>
	);
}

export default InvoiceItem;
