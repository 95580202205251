import { JSX, ChangeEvent } from 'react';
import { t } from '@transifex/native';

import UserGoal from 'pkg/models/user_goal';

import useMixedState from 'pkg/hooks/useMixedState';

import CardBase, * as Card from 'components/Card';
import Modal from 'components/modal';

import Form from 'components/form/Form';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

import Button from 'design/button';

interface UserGoalFormProps {
	activeGoal?: UserGoal;
	close: () => void;
	saveGoal: (goal: UserGoal) => void;
}

export function UserGoalForm({
	activeGoal,
	close,
	saveGoal,
}: UserGoalFormProps): JSX.Element {
	const categoryDropdown = [
		{
			value: 'general',
			label: t(`General`),
		},
		{
			value: 'tactical',
			label: t(`Tactical`),
		},
		{
			value: 'technical',
			label: t(`Technical`),
		},
		{
			value: 'physical',
			label: t(`Physical`),
		},
		{
			value: 'mental',
			label: t(`Mental`),
		},
		{
			value: 'social',
			label: t(`Social`),
		},
	].map((option) => {
		const { value, label } = option;
		return [value, label];
	});

	const [state, setState] = useMixedState({
		id: activeGoal?.id,
		goal: activeGoal?.goal,
		progress: activeGoal?.progress,
		category: activeGoal?.category || 'general',
		description: activeGoal?.description,
	});

	const cardHeading = () => {
		if (!activeGoal) {
			return t(`Add goal`, {
				_context: 'user profile development',
			});
		}
		return t(`Edit goal`, {
			_context: 'user profile development',
		});
	};

	const handleSave = () => {
		saveGoal(new UserGoal(state));
	};

	const handleFieldChange = (
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
	) => {
		const fieldName = e.currentTarget.name;
		const fieldValue = e.currentTarget.value;
		if (fieldValue !== undefined) {
			setState({
				[fieldName]: fieldValue,
			});
		}
	};

	return (
		<Modal key="modal" onClose={close}>
			<CardBase className="card">
				<Card.Header>
					<Card.Heading>{cardHeading()}</Card.Heading>
				</Card.Header>

				<Card.Divider />

				<Card.Body $flex>
					<Form>
						<Column>
							<Input.Group label={t(`Goal title`)}>
								<Input.Field
									placeholder={t(`Eg. get better at heading`)}
									onChange={handleFieldChange}
									name="goal"
									value={state.goal}
									required
								/>
							</Input.Group>

							<Input.Group label={t(`Category`)}>
								<Input.Select
									name="category"
									onChange={handleFieldChange}
									value={state.category}>
									{categoryDropdown.map((category) => (
										<option key={category[0]} value={category[0]}>
											{category[1]}
										</option>
									))}
								</Input.Select>
							</Input.Group>

							<Input.Group label={t(`Description`)}>
								<Input.Area
									name="description"
									onChange={handleFieldChange}
									value={state.description}
								/>
							</Input.Group>
						</Column>
					</Form>
				</Card.Body>

				<Card.Footer>
					<Button
						primary
						large
						icon="add"
						onClick={handleSave}
						testid="step_modal.next">
						{activeGoal?.id ? t('Update') : t('Add')}
					</Button>
				</Card.Footer>
			</CardBase>
		</Modal>
	);
}
