import { motion, AnimatePresence, Variants } from 'framer-motion';
import {
	JSX,
	PointerEvent,
	ReactNode,
	Children,
	createContext,
	useContext,
	useState,
	Fragment,
} from 'react';
import { createPortal } from 'react-dom';
import { t } from '@transifex/native';

import { cssClasses } from 'pkg/css/utils';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useHapticFeedback } from 'pkg/haptics';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';

import Icon, { IconName } from 'components/icon';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as css from './styles.css';

interface ActionSheetState {
	isVisible: boolean;

	show: () => Promise<void>;
	hide: () => Promise<void>;
}

const defaultActionSheetState: ActionSheetState = {
	isVisible: true,

	show: () => Promise.resolve(),
	hide: () => Promise.resolve(),
};

const ActionSheetStateContext = createContext<ActionSheetState>(
	defaultActionSheetState
);

export function useActionSheet(): ActionSheetState {
	return useContext(ActionSheetStateContext);
}

type ActionSheetActionCallback = () => void | Promise<void>;

export interface ActionSheetAction {
	label: string;
	icon: IconName;
	iconScale?: number;
	cautious?: boolean;

	onInteract: ActionSheetActionCallback;
}

function ActionSheetActionItem({
	label,
	icon,
	iconScale = 1,
	cautious,

	onInteract,
}: ActionSheetAction): JSX.Element {
	const actionSheet = useActionSheet();

	const handleInteract = async () => {
		await onInteract();

		actionSheet.hide();
	};

	return (
		<Row
			columns="auto 1fr"
			align="center"
			justify="start"
			justifyContent="start"
			className={cssClasses(css.action, cautious ? css.cautious : undefined)}
			onClick={handleInteract}>
			<Icon name={icon} size={iconScale} />
			<span>{label}</span>
		</Row>
	);
}

interface ActionSheetGroupProps {
	children: ReactNode | ReactNode[];
}

function ActionSheetGroup({ children }: ActionSheetGroupProps): JSX.Element {
	const variants: Variants = {
		visible: {
			opacity: 1,
			scale: 1,
		},
		hidden: {
			opacity: 0,
			scale: 0.95,
		},
	};

	return <motion.div variants={variants}>{children}</motion.div>;
}

interface ActionSheetProviderProps {
	children?: ReactNode | ReactNode[];
}

export function ActionSheetProvider({
	children,
}: ActionSheetProviderProps): JSX.Element {
	const [isVisible, setVisible] = useState<boolean>(true);

	const providedActionsheetState: ActionSheetState = {
		isVisible,

		show: async () => {
			setVisible(true);
		},

		hide: async () => {
			setVisible(false);
		},
	};

	return (
		<ActionSheetStateContext.Provider value={providedActionsheetState}>
			{children}
		</ActionSheetStateContext.Provider>
	);
}

type PresenceCallback = () => void | Promise<void>;

interface ActionSheetProps {
	children?: ReactNode | ReactNode[];
	actions: ActionSheetAction[];

	onShow?: PresenceCallback;
	onHide: PresenceCallback;
}

export default function ActionSheet({
	children,
	actions,

	onShow,
	onHide,
}: ActionSheetProps): JSX.Element {
	const zIndex = useNewTopIndex();
	const actionSheet = useActionSheet();
	const emitHapticFeedback = useHapticFeedback();

	const portalNode = document.getElementById('actionsheet-portal-container');

	const actionSheetAnimation: Variants = {
		visible: {
			y: '0',
			transition: {
				duration: 0.1,
			},
		},
		hidden: {
			y: '100%',
			transition: {
				duration: 0.1,
			},
		},
	};

	const actionSheetGroupAnimation: Variants = {
		visible: {
			transition: {
				duration: 0.1,
				delayChildren: 0.1,
				staggerChildren: 0.03,
				staggerDirection: -1,
			},
		},
		hidden: {
			transition: {
				staggerChildren: 0.1,
			},
		},
	};

	const handleAnimationComplete = async (variant: string) => {
		if (variant === 'visible' && onShow) {
			await onShow();
		} else if (variant === 'hidden' && onHide) {
			await onHide();
		}
	};

	const handleInteraction = (event: PointerEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};

	const handleCloseActionSheet = (event: PointerEvent<HTMLDivElement>) => {
		event.stopPropagation();
		actionSheet.hide();
	};

	const actionSheetItems = [
		...Children.toArray(children),
		<Fragment key="actionSheetActions">
			{actions.map((action: ActionSheetAction, index: number) => (
				<ActionSheetActionItem key={`actionSheetAction:${index}`} {...action} />
			))}
		</Fragment>,
		<Row
			key="actionSheetCancel"
			align="center"
			justify="center"
			justifyContent="center"
			className={css.action}
			onClick={handleCloseActionSheet}>
			{t('Cancel')}
		</Row>,
	];

	// Resets action sheet render state
	useComponentDidMount(() => {
		actionSheet.show();
		emitHapticFeedback();
	});

	return createPortal(
		<AnimatePresence>
			{actionSheet.isVisible && (
				<motion.div
					key="actionSheetBackdrop"
					className={css.backdrop}
					initial={{ opacity: 0 }}
					animate={{
						opacity: 1,
						transition: { ease: 'linear', duration: 0.1 },
					}}
					exit={{ opacity: 0 }}
					style={{ zIndex }}>
					<div
						key="actionSheetWrapper"
						className={css.wrapper}
						onClick={handleCloseActionSheet}>
						<motion.div
							key="actionSheet"
							className={css.sheet}
							onClick={handleInteraction}
							onAnimationComplete={handleAnimationComplete}
							variants={actionSheetAnimation}
							initial="hidden"
							animate="visible"
							exit="hidden">
							<motion.div variants={actionSheetGroupAnimation}>
								<Column>
									{actionSheetItems.map((child: ReactNode, index: number) => (
										<ActionSheetGroup key={`actionSheetGroup:${index}`}>
											{child}
										</ActionSheetGroup>
									))}
								</Column>
							</motion.div>
						</motion.div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>,
		portalNode
	);
}
