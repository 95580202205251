import { JSX } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

import * as css from './styles.css';

interface EmptySessionProps {
	eventId: number;
}

export default function EmptySession({
	eventId,
}: EmptySessionProps): JSX.Element {
	const org = useCurrentOrganization();

	return (
		<Card.Base>
			<Card.Body $narrowBody>
				<LargeScreen>
					<Row columns="40px 1fr auto" align="center">
						<div className={css.largeIcon}>
							<Icon
								name="library"
								size={1.1}
								fill={styles.palette.orange[500]}
								className={css.icon}
							/>
						</div>
						<Column>
							<Row className={css.sessionTitle}>
								{t('No session added yet')}
							</Row>
						</Column>
						<Button
							secondary
							icon="add"
							href={routes.Event.View(org.id, eventId, 'sessions')}>
							{t('Add session')}
						</Button>
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Row columns="40px 1fr" align="center">
						<div className={css.largeIcon}>
							<Icon
								name="library"
								size={1.1}
								fill={styles.palette.orange[500]}
								className={css.icon}
							/>
						</div>
						<Column>
							<Row className={css.sessionTitle}>
								{t('No session added yet')}
							</Row>
						</Column>
					</Row>
					<Row className={css.smallScreenEmptyState}>
						<Button
							secondary
							icon="add"
							href={routes.Event.View(org.id, eventId, 'sessions')}>
							{t('Add session')}
						</Button>
					</Row>
				</SmallScreen>
			</Card.Body>
		</Card.Base>
	);
}
