import { useT } from '@transifex/react';
import { JSX, Fragment, ReactNode } from 'react';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';

import { formatRelativeDateTimeString } from 'components/RelativeDateTime';

interface UserTimestampsProps {
	user: models.user.User;
}

export default function UserTimestamps({
	user,
}: UserTimestampsProps): JSX.Element {
	const t = useT();

	const createdAt = DateTime.fromTimestamp(user.createdAt).toISODateString();

	let updatedAtComponent: ReactNode = null;

	if (user?.updatedAt) {
		const updatedAt = formatRelativeDateTimeString(user.updatedAt);

		updatedAtComponent = <p>{t('Last update: {updatedAt}', { updatedAt })}</p>;
	}

	return (
		<Fragment>
			{updatedAtComponent}
			<p>{t('Created at {createdAt}', { createdAt })}</p>
		</Fragment>
	);
}
