import { JSX } from 'react';
import { t } from '@transifex/native';

import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import { useCurrentRoute } from 'pkg/router/hooks';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { OnboardingInfoResponse } from 'pkg/api/endpoints/onboarding_info';

import { useOnboardingContext } from 'routes/public/onboarding';
import { useOnboardingState } from 'routes/public/hooks';

import { Spinner } from 'components/loaders/spinner';

export default function AutoJoin(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const { groupCode } = useCurrentRoute();

	useComponentDidMount(async () => {
		if (!groupCode) {
			goTo('start');
			return;
		}

		const [request, response] = await api.get<OnboardingInfoResponse>(
			endpoints.Accounts.OnboardingInfo() + `?invite_code=${groupCode}`
		);

		if (!request.ok) {
			actions.flashes.show(
				{
					title: t('Something went wrong'),
					message: t('This group code is not in use.'),
				},
				request.status
			);

			onboardingState.set({ group: null });

			goTo('start');
		} else {
			const urlParams = new URLSearchParams(document.location.search);
			const returnParam = urlParams.get('return');
			const formGUID = urlParams.get('guid');

			const returnToForms = returnParam === 'forms';

			onboardingState.set({
				groupCode,
				group: request.ok ? response : null,
				identityProviders: response.identityProviders,
				returnToForms,
				formGUID,
			});

			goTo('join-group-confirmation');
		}
	});

	return <Spinner />;
}
