import { JSX, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import { forcedNavigation } from 'pkg/actions/app';

import { useRouterState } from 'pkg/router/hooks';
import rgba from 'pkg/rgba';
import Link from 'pkg/router/Link';
import { RootState } from 'pkg/reducers';

import Icon from 'components/icon';

import * as LargeScreenHeader from 'components/navigation/header/large_screen/Styles';

const BackLink = styled(Link)<{ largeLayout?: boolean; disabled?: boolean }>`
	cursor: ${({ largeLayout, disabled }) =>
		largeLayout && disabled ? 'auto' : 'pointer'};
	z-index: ${styles.zIndex.startIndex};
`;

const BackIcon = styled(Icon)<{ largeLayout?: boolean; disabled?: boolean }>`
	color: ${({ largeLayout, theme, disabled }) => css`
		${largeLayout
			? disabled
				? rgba(styles.palette.black, 0.2)
				: styles.palette.black
			: theme.accentColor || styles.palette.gray[400]}
	`};
	transform: rotateZ(180deg);
	height: ${({ largeLayout }) => (largeLayout ? '40px' : '2.3rem')};
	width: ${({ largeLayout }) => (largeLayout ? '40px' : '100%')};
	margin-right: ${({ largeLayout }) => (largeLayout ? styles.spacing._3 : 0)};
	display: block;

	${({ theme, disabled }) =>
		theme.darkMode &&
		css`
			color: ${rgba(styles.palette.white, disabled ? 0.2 : 1)};
		`}
`;

interface BackProps {
	link?: any;
	largeLayout?: any;
	goBack?: any;
	disabled?: boolean;
}

const Back = ({
	link,
	largeLayout,
	goBack,
	disabled,
}: BackProps): JSX.Element => {
	if (!!link && link.length > 0) {
		if (largeLayout) {
			return <LargeScreenHeader.Back name="chevron" onClick={goBack} />;
		}

		return (
			<BackIcon name="chevron" onClick={goBack} data-testid="back_nav_button" />
		);
	}

	return (
		<BackLink back={!disabled} data-testid="back_nav_button">
			{largeLayout && (
				<LargeScreenHeader.Back name="chevron" disabled={disabled} />
			)}
			{!largeLayout && <BackIcon name="chevron" />}
		</BackLink>
	);
};

interface BackContainerProps {
	largeLayout?: boolean;
}

const BackContainer = ({ largeLayout }: BackContainerProps): JSX.Element => {
	const { store } = useRouterState();
	const dispatch = useDispatch();

	const backLink = useSelector(
		(state: RootState) => state.app.forceNavBarBackLink
	);

	const goBack = useCallback(() => {
		dispatch(forcedNavigation(backLink));
	}, [backLink, dispatch]);

	if (store.size > 1) {
		return <Back largeLayout={largeLayout} />;
	}

	if (backLink) {
		return <Back link={backLink} goBack={goBack} largeLayout={largeLayout} />;
	}

	if (largeLayout) return <Back largeLayout={largeLayout} disabled />;

	return null;
};

export default BackContainer;
