import { JSX } from 'react';
import { t } from '@transifex/native';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';

import StepModal, { Step } from 'components/step-modal';
import Avatar from 'components/avatar';

import Button from 'design/button';

const Body = styled.div`
	padding: var(--spacing-8);
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-7);
	align-items: center;
	align-content: center;
	justify-items: center;
	justify-content: center;
	height: 100%;

	@media not ${styles.breakpoint.small} {
		min-height: 50vh;
	}

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-7);
	}
`;

const Pane = styled.div<{ horizontal?: boolean }>`
	margin: 0 auto;
	display: grid;
	grid-auto-flow: row;
	align-items: center;
	justify-content: center;
	gap: var(--spacing-5);

	${(props) =>
		props.horizontal &&
		css`
			grid-auto-flow: column;
		`}

	h2,
	h3,
	& > p {
		text-align: center;
	}

	@media ${styles.breakpoint.small} {
		max-width: none;
	}

	${Avatar} {
		margin: 0 auto;
	}
`;

export default function ParentalRequestModal(): JSX.Element {
	const dispatch = useDispatch();

	const activeRequest = useSelector(
		selectors.accountRelations.findActiveRequest
	);

	const close = () =>
		dispatch(actions.accountRelations.setActiveRequestItem(0));

	const accept = async () => {
		await dispatch(actions.accountRelations.approveRequest(activeRequest.id));

		close();
	};

	const decline = async () => {
		await dispatch(actions.accountRelations.remove(activeRequest.id));

		close();
	};

	if (!activeRequest.id || activeRequest.approvedAt !== null) return null;

	return (
		<StepModal thin onClose={close} hideCloseButton>
			<Step hideNext prevLabel={t('Dismiss')} title={t(`Connection request`)}>
				<Body>
					<Pane>
						<Avatar size={90} account={activeRequest.account} />
						<h3>
							{t(`{userName} wants to connect with you!`, {
								userName: activeRequest.account.fullName,
							})}
						</h3>
						<p>
							{t(
								`Only accept this request if {userName} is your parent or legal guardian.`,
								{
									userName: activeRequest.account.fullName,
								}
							)}
						</p>
					</Pane>
					<Pane horizontal>
						<Button large onClick={decline}>
							{t('Decline')}
						</Button>

						<Button large onClick={accept}>
							{t('Accept')}
						</Button>
					</Pane>
				</Body>
			</Step>
		</StepModal>
	);
}
