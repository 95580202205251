import { JSX, ChangeEvent, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as sdk from 'pkg/core/sdk';
import { isDev } from 'pkg/flags';
import { useCurrentGroup } from 'pkg/identity';
import * as models from 'pkg/api/models';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';
import Modal from 'components/modal';

import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import * as Table from 'design/table';
import Button from 'design/button';

interface debugEndpoint {
	endpoint: string;
	options: string[];
}

interface debugGroup {
	[key: string]: debugEndpoint;
}

const debugActions: { [key: string]: debugGroup } = {
	Users: {
		Create: {
			endpoint: '/users/create',
			options: ['organizationId', 'accountId'],
		},
		'Create with group membership': {
			endpoint: '/users/create-with-group-membership',
			options: ['groupId', 'userGroupRole', 'accountId'],
		},
		'Create child with billing parent': {
			endpoint: '/users/create-child-with-billing-parent',
			options: ['organizationId'],
		},
		'Create from LOK test persons': {
			endpoint: '/users/create-from-lok',
			options: ['organizationId'],
		},
	},
	Accounts: {
		Create: {
			endpoint: '/accounts/create',
			options: ['langCode', 'countryCode'],
		},
		'Create with pending child': {
			endpoint: '/accounts/create-with-pending-child',
			options: ['langCode', 'countryCode'],
		},
		'Create unboarded noname': {
			endpoint: '/users/create-unboarded-noname',
			options: ['langCode', 'countryCode'],
		},
		'Create with pending staff membership': {
			endpoint: '/users/create-with-pending-staff-membership',
			options: ['groupId', 'langCode', 'countryCode'],
		},
		'Create with pending staff invite': {
			endpoint: '/users/create-with-pending-staff-invite',
			options: ['groupId', 'langCode', 'countryCode'],
		},
		'Create with parent relation and user membership': {
			endpoint: `/accounts/create-with-parent-relation-and-user-membership`,
			options: ['groupId', 'langCode', 'countryCode'],
		},
	},
	Videos: {
		Create: {
			endpoint: '/videos/create',
			options: [
				'organizationId',
				'groupId',
				'numSequences',
				'numSequenceUsers',
			],
		},
		'Create with youtube uri': {
			endpoint: '/videos/create-with-youtube-uri',
			options: [
				'organizationId',
				'groupId',
				'numSequences',
				'numSequenceUsers',
			],
		},
		'Create playlist': {
			endpoint: '/videos/create-playlist',
			options: [
				'organizationId',
				'groupId',
				'numVideos',
				'numSequences',
				'numSequenceUsers',
			],
		},
		'Create collection': {
			endpoint: '/videos/create-collection',
			options: ['groupId', 'numVideos', 'numSequences', 'numSequenceUsers'],
		},
		'Create group collection': {
			endpoint: '/videos/create-group-collection',
			options: ['groupId', 'numVideos', 'numSequences', 'numSequenceUsers'],
		},
	},
	Products: {
		Create: {
			endpoint: '/products/create',
			options: ['groupId'],
		},
		'Create Default': {
			endpoint: '/products/create-default',
			options: ['groupId'],
		},
	},
	Orders: {
		Create: {
			endpoint: '/orders/create',
			options: ['groupId'],
		},
		'Create with multiple user products': {
			endpoint: '/orders/create-with-multiple-user-products',
			options: ['groupId'],
		},
	},
	Subscriptions: {
		Create: {
			endpoint: '/subscriptions/create',
			options: ['groupId'],
		},
		'Create installment': {
			endpoint: '/subscriptions/create-installment',
			options: ['groupId'],
		},
		'Create installment with first order': {
			endpoint: '/subscriptions/create-first-installment',
			options: ['groupId'],
		},
	},
	Matches: {
		Create: {
			endpoint: '/matches/create',
			options: ['groupId', 'timestamp'],
		},
		'Create finished': {
			endpoint: '/matches/create-finished',
			options: ['groupId'],
		},
	},
	Forms: {
		Create: {
			endpoint: '/forms/create',
			options: ['groupId', 'title'],
		},
		'Create with related product': {
			endpoint: '/forms/create-with-product',
			options: ['groupId', 'productPriceId', 'title'],
		},
		'Create with installments': {
			endpoint: '/forms/create-with-installments',
			options: ['groupId', 'title'],
		},
		'Create form submission': {
			endpoint: '/forms/create-form-submission',
			options: ['formId', 'userId'],
		},
		'Create form submission as draft': {
			endpoint: '/forms/create-form-submission-as-draft',
			options: ['formId', 'userId'],
		},
		'Create legacy form': {
			endpoint: '/forms/create-legacy-form',
			options: ['groupId'],
		},
		'Create legacy form with multiple products': {
			endpoint: '/forms/create-legacy-form-multiple-products',
			options: ['groupId'],
		},
		'Create simple form with all field types': {
			endpoint: '/forms/create-with-all-field-types',
			options: ['groupId', 'title'],
		},
		'Create form with optional product': {
			endpoint: '/forms/create-with-optional-product',
			options: ['groupId', 'productPriceId', 'title'],
		},
		'Create form with required and  optional product': {
			endpoint: '/forms/create-with-required-and-optional-product',
			options: ['groupId', 'productPriceId', 'optionalProductPriceId', 'title'],
		},
	},
	Resources: {
		Create: {
			endpoint: '/resources/create',
			options: ['groupId', 'title'],
		},
		'Create with location and category': {
			endpoint: '/resources/create-with-location-and-category',
			options: ['groupId', 'title'],
		},
		'Create resource location': {
			endpoint: '/resource-locations/create',
			options: ['groupId', 'title'],
		},
		'Create resource category': {
			endpoint: '/resource-categories/create',
			options: ['groupId', 'title'],
		},
	},
	Schedules: {
		Create: {
			endpoint: '/schedules/create',
			options: ['groupId', 'title'],
		},
		'Create from groups with some resources': {
			endpoint: 'schedules/create-from-groups-with-resources',
			options: ['groupId', 'title'],
		},
		'Create from resources with some groups': {
			endpoint: 'schedules/create-from-resources-with-groups',
			options: ['groupId', 'title'],
		},
	},
	Posts: {
		'Create multi group': {
			endpoint: '/group-posts/create-multi-group',
			options: ['groupId'],
		},
	},
	Payments: {
		'Create Purspot payout report job (only works in dev, for local data use below endpoint)':
			{
				endpoint: '/payments/payout-report-job',
				options: [],
			},
		'Create payout report with payout rows and order connection': {
			endpoint: '/payments/payout-report',
			options: ['organizationId', 'rowsPerReport'],
		},
		'Generate orders from Purspot dummy report data': {
			endpoint: '/payments/generate-orders-from-purspot-dummy-report-data',
			options: [],
		},
		'Create a payout report from existing orders in the organization ': {
			endpoint: '/payments/payout-report-from-orders',
			options: ['organizationId'],
		},
		'Create a discount': {
			endpoint: '/payments/create-discount',
			options: ['organizationId', 'discountCode'],
		},
	},
	PaymentMethods: {
		'Create Payment Method for a user': {
			endpoint: '/payment-methods/create',
			options: ['organizationId', 'userId', 'expiryYear', 'brand'],
		},
	},
};

interface DebugModalProps {
	onClose: () => void;
}

interface DebugEndpointProps {
	action: debugEndpoint;
	title: string;
	debugSection: string;
	options: string[];
}

function DebugEndpoint({
	action,
	title,
	debugSection,
	options,
}: DebugEndpointProps): JSX.Element {
	const currentGroup = useCurrentGroup();

	const [params, setParams] = useState<{ [key: string]: string | number }>({
		groupId: currentGroup.id,
		organizationId: models.group.isOrganization(currentGroup)
			? currentGroup.id
			: currentGroup.organizationId,
	});
	const [running, setRunning] = useState(false);
	const [qty, setQty] = useState(1);

	const run = async () => {
		setRunning(true);
		const responses: string[] = [];
		for (let i = 0; i < qty; i++) {
			const req = await sdk.get(`/v1/debug/${action.endpoint}`, params);

			if (!req.ok) {
				alert('failed');
				setRunning(false);
				return;
			}

			const resp = await req.json();
			responses.push(resp);
		}

		console.table(responses);
		actions.flashes.show(
			{
				title: `Created ${responses.length} resources`,
				message: 'Check the developer console if you need the results.',
			},
			200
		);
		setRunning(false);
		setQty(1);
	};

	const setParam = (e: ChangeEvent<HTMLInputElement>) => {
		params[e.target.name] = e.target.value;
		setParams(params);
	};

	const changeQuantity = (e: ChangeEvent<HTMLInputElement>) => {
		setQty(Number.parseInt(e.target.value, 10));
	};

	// "Create child with billing parent" returns "debug_action.create_child_with_billing_parent"
	const actionTestId = `debug_action.${debugSection}.${title
		.split(' ')
		.join('_')
		.toLocaleLowerCase()}`;

	console.log(actionTestId);

	return (
		<Table.Row>
			<Table.Cell>{title}</Table.Cell>
			{options.map((v: string) => (
				<Table.Cell key={v}>
					{action.options
						.map((o: string) => {
							if (o === v) {
								let defaultValue: string | number = '';
								switch (o) {
									case 'groupId':
										defaultValue = currentGroup.id;
										break;
									case 'organizationId':
										defaultValue = models.group.isOrganization(currentGroup)
											? currentGroup.id
											: currentGroup.organizationId;
										break;
								}

								return (
									<Input.Field
										key={o}
										small
										name={o}
										onChange={setParam}
										defaultValue={defaultValue}
									/>
								);
							}
						})
						.filter((n) => n)}
				</Table.Cell>
			))}
			<Table.Cell>
				<Input.Field
					small
					value={qty}
					onChange={changeQuantity}
					type="number"
					min="1"
				/>
			</Table.Cell>
			<Table.Cell align="center">
				{running ? (
					<Spinner size="40px" />
				) : (
					<Button
						icon="play_arrow"
						tooltip="Run action"
						testid={actionTestId}
						onClick={run}
					/>
				)}
			</Table.Cell>
		</Table.Row>
	);
}

interface DebugGroupProps {
	group: debugGroup;
	title: string;
}

function DebugGroup({ group, title }: DebugGroupProps): JSX.Element {
	const [collapsed, setCollapsed] = useState(true);
	const distinctOptions = new Set<string>();
	Object.values(group).forEach((v: debugEndpoint) => {
		v.options.forEach((o: string) => {
			distinctOptions.add(o);
		});
	});

	const columns = [...distinctOptions].map(
		(col: string): Table.HeaderColumn => ({
			content: col,
			width: '150px',
		})
	);

	columns.unshift({ content: 'Action' });
	columns.push({ content: 'Qty', width: '75px' });
	columns.push({ content: '', width: 'max-content' });

	const debugSection = title.split(' ').join('_').toLowerCase();

	return (
		<Column>
			<div
				style={{
					padding: styles.spacing._4,
					paddingBottom: 0,
					cursor: 'pointer',
				}}
				onClick={() => setCollapsed(!collapsed)}>
				<SectionTitle>
					{title}{' '}
					<Icon
						name="chevron-down"
						size={2}
						rotate={collapsed ? '-90deg' : '0deg'}
					/>
				</SectionTitle>
			</div>
			<Card.Divider />
			{!collapsed && (
				<Table.Table columns={columns}>
					{Object.keys(group).map((key: string, index: number) => (
						<DebugEndpoint
							key={index}
							action={group[key]}
							title={key}
							debugSection={debugSection}
							options={[...distinctOptions]}
						/>
					))}
				</Table.Table>
			)}
		</Column>
	);
}

export function DebugModal({ onClose }: DebugModalProps): JSX.Element {
	if (!isDev()) {
		return null;
	}

	return (
		<Modal wide onClose={onClose}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>Debug</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body $noSpacing>
					{Object.keys(debugActions)
						.sort((a, b) => (a > b ? 1 : -1))
						.map((key: string) => (
							<DebugGroup key={key} title={key} group={debugActions[key]} />
						))}
				</Card.Body>
			</Card.Base>
		</Modal>
	);
}
