import { useT } from '@transifex/react';
import { JSX, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as arrays from 'pkg/arrays';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentAccount, useCurrentGroupId } from 'pkg/identity';
import supportLinks from 'pkg/support/links';

import { usePerformanceReviewModal } from 'routes/organization/user-profile/development/performance-reviews/modal';

import Section from 'components/section';
import { FormattedContent } from 'components/formatted-content';
import { formatRelativeDateTimeString } from 'components/RelativeDateTime';
import AssetImage from 'components/AssetImage';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import MissingEntities from 'design/placeholders/block';
import * as Card from 'design/card';
import Button from 'design/button';
import * as Context from 'design/context_menu';
import { useDialog } from 'design/dialog';

import * as css from './styles.css';

interface PerformanceReviewsProps {
	user: models.user.User;
	groupId: number;
}

export default function PerformanceReviews({
	user,
	groupId,
}: PerformanceReviewsProps): JSX.Element {
	const t = useT();

	const dispatch = useDispatch();

	const [showingAll, setShowingAll] = useState<boolean>(false);

	const canCreate = models.hasAllowedAction(
		user,
		models.Action.UserCreateUserGroupPerformanceReview
	);

	const {
		records: performanceReviews,
		removeRecord,
		isLoading,
		refresh,
		pagination,
	} = useCollection<models.performanceReview.PerformanceReview>(
		endpoints.PerformanceReview.Index(),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				user_id: user.id.toString(),
				group_id: groupId.toString(),
			}),
		}
	);

	const handleSavePerformanceReview = async (
		performanceReview: models.performanceReview.PerformanceReview
	) => {
		await dispatch(
			actions.performanceReview.savePerformanceReview({
				...performanceReview,
				userId: user.id,
				groupId,
			})
		);

		await refresh();

		performanceReviewModal.close();
	};

	const performanceReviewModal = usePerformanceReviewModal(
		user,
		handleSavePerformanceReview
	);

	const handleCreatePerformanceReview = () => {
		performanceReviewModal.open(null);
	};

	const handleDeletePerformanceReview = async (
		performanceReview: models.performanceReview.PerformanceReview
	) => {
		await dispatch(
			actions.performanceReview.deletePerformanceReview(performanceReview.id)
		);

		removeRecord(performanceReview.id);
	};

	if (isLoading) {
		return null;
	}

	const [latestReviews, trailingReviews] =
		arrays.splitAt<models.performanceReview.PerformanceReview>(
			performanceReviews,
			1
		);

	if (!isLoading && performanceReviews.length === 0) {
		return (
			<Fragment>
				<MissingEntities
					noBorder
					helpUrl={supportLinks.performanceReviews}
					title={t('Performance reviews will help your players get better!')}
					description={t(
						'Periodic reviews of performance will help your players stay focused and understand their own development.'
					)}
					actions={
						canCreate && (
							<Button
								icon="add"
								secondary
								onClick={handleCreatePerformanceReview}
								label={t('Create new performance review', {
									_context: 'user profile development',
								})}
							/>
						)
					}
					image={<AssetImage src="img/missing-entities/goals.svg" />}
				/>
				{performanceReviewModal.Component}
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Section
				title={t('Latest review')}
				action={
					canCreate && (
						<Button inline icon="add" onClick={handleCreatePerformanceReview}>
							{t('New review')}
						</Button>
					)
				}>
				<Column>
					{latestReviews.map(
						(pr: models.performanceReview.PerformanceReview) => (
							<PerformanceReview
								key={pr.id}
								user={user}
								performanceReview={pr}
								onSave={refresh}
								onDelete={handleDeletePerformanceReview}
							/>
						)
					)}
					{showingAll &&
						trailingReviews.map(
							(pr: models.performanceReview.PerformanceReview) => (
								<PerformanceReview
									key={pr.id}
									user={user}
									performanceReview={pr}
									onSave={refresh}
									onDelete={handleDeletePerformanceReview}
								/>
							)
						)}
				</Column>
				{!showingAll && trailingReviews?.length > 0 && (
					<div>
						<Button inline onClick={() => setShowingAll(true)}>
							{t('Show all reviews')}
						</Button>
					</div>
				)}
				{showingAll && pagination.hasNext && (
					<div className={css.loadMore}>
						<Button primary onClick={pagination.fetchNext}>
							{t('Fetch more reviews')}
						</Button>
					</div>
				)}
			</Section>
			{performanceReviewModal.Component}
		</Fragment>
	);
}

interface PerformanceReviewProps {
	user: models.user.User;
	performanceReview: models.performanceReview.PerformanceReview;

	onSave: () => Promise<void>;
	onDelete: (
		performanceReview: models.performanceReview.PerformanceReview
	) => Promise<void>;
}

function PerformanceReview({
	user,
	performanceReview,

	onSave,
	onDelete,
}: PerformanceReviewProps): JSX.Element {
	const t = useT();
	const dispatch = useDispatch();
	const account = useCurrentAccount();
	const groupId = useCurrentGroupId();

	const canUpdate = models.hasAllowedAction(
		performanceReview,
		models.Action.UserGroupPerformanceReviewUpdate
	);

	const canDelete = models.hasAllowedAction(
		performanceReview,
		models.Action.UserGroupPerformanceReviewRemove
	);

	const canShowActions = canUpdate || canDelete;

	const handleDelete = useDialog({
		destructive: true,
		wide: true,
		symbol: 'delete',
		title: t('Delete this review?'),
		message: t(
			'You are about to delete this performance review, this action cannot be undone.'
		),
		confirmLabel: t('Delete review'),
		onConfirm: () => {
			onDelete(performanceReview);
		},
	});

	const handleSavePerformanceReview = async (
		performanceReview: models.performanceReview.PerformanceReview
	) => {
		const isForAccount =
			performanceReview.userId !== user.id && user.accountId !== account?.id;

		const forAccountId = isForAccount ? user.accountId : null;

		if (performanceReview?.id) {
			await dispatch(
				actions.performanceReview.updatePerformanceReview(
					performanceReview,
					forAccountId
				)
			);
		} else {
			await dispatch(
				actions.performanceReview.savePerformanceReview(
					{
						...performanceReview,
						userId: user.id,
						groupId,
					},
					forAccountId
				)
			);
		}

		onSave();

		performanceReviewModal.close();
	};

	const performanceReviewModal = usePerformanceReviewModal(
		user,
		handleSavePerformanceReview
	);

	const handleEditPerformanceReview = () => {
		performanceReviewModal.open(performanceReview);
	};

	return (
		<Fragment>
			<Card.Base $noBorder>
				<Card.Body>
					<Column>
						<Row columns="1fr auto" align="center">
							<strong>
								{t('Performance review with {user}', {
									user: models.user.fullName(user),
								})}
							</strong>
							{canShowActions && (
								<Context.Menu
									toggleWith={<Button secondary small icon="more_horiz" />}>
									{canUpdate && (
										<Context.Item
											icon="edit"
											onClick={handleEditPerformanceReview}>
											{t('Edit review')}
										</Context.Item>
									)}
									{canUpdate && canDelete && <Context.Divider />}
									{canDelete && (
										<Context.Item caution icon="delete" onClick={handleDelete}>
											{t('Delete review')}
										</Context.Item>
									)}
								</Context.Menu>
							)}
						</Row>
						<Row columns="1fr auto" align="center">
							<div />
							<span className={css.timestamp}>
								{t('Last updated {date}', {
									date: formatRelativeDateTimeString(
										performanceReview.updatedAt
									),
								})}
							</span>
						</Row>
						<FormattedContent
							raw={performanceReview.performanceReview}
							wrapperClassName={css.body}
						/>
					</Column>
				</Card.Body>
			</Card.Base>
			{performanceReviewModal.Component}
		</Fragment>
	);
}
