import { useSelector } from 'react-redux';
import { JSX, useEffect } from 'react';

import VideoSequence from 'pkg/models/video_sequence';

import { RootState } from 'pkg/reducers';
import { useCurrentRoute } from 'pkg/router/hooks';
import * as selectors from 'pkg/selectors';
import { cuePointsFromSequences } from 'pkg/video';
import { useQueryState } from 'pkg/hooks/query-state';
import * as json from 'pkg/json';
import { useCurrentAccount } from 'pkg/identity';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { CueGroupType, useCueState } from 'components/video-analytics/CueState';
import { getCurrentFilterState } from 'components/video-analytics/FilterState';
import { useClipState } from 'components/video-analytics/ClipState';

export default function Bootstrap(): JSX.Element {
	const queryState = useQueryState();
	const filters = getCurrentFilterState();
	const { videoId } = useCurrentRoute();
	const clipState = useClipState();
	const { setCuePoints, setCueContextId, setCueActive } = useCueState();
	const { source, setSource, setDuration, setGaplessPlayback } =
		usePlayerState();

	const accountId = useCurrentAccount().id;

	const video = useSelector((state: RootState) =>
		selectors.videos.find(state, videoId)
	);

	const filteredSequences = useSelector((state: RootState) =>
		selectors.videoSequences.findAllFilteredSequences(state, videoId, filters)
	).filter((sequence: VideoSequence) => sequence.accountId === accountId);

	const numFilteredSequences = filteredSequences.size;

	useEffect(() => {
		if (!source && video.uri) {
			setSource(video.uri, {
				onHasDuration: (duration: number) => {
					setDuration(duration);
				},
			});

			setGaplessPlayback(false);
			setDuration(video.duration / 1000);
		}
	}, [video]);

	useEffect(() => {
		if (filteredSequences.size > 0) {
			let newCueId: number = -1;

			queryState.refresh();

			if (queryState.has('new')) {
				newCueId = queryState.get('new') as number;

				queryState.remove('new');
				queryState.commit();
			}

			setCueContextId(accountId);
			setCueActive(false);

			const cuePoints = cuePointsFromSequences(filteredSequences);

			if (cuePoints.length > 0) {
				const cue = cuePoints[0];

				if (cue.annotations && cue.annotations !== '{}') {
					const annotations = json.parse(cue.annotations);

					if (annotations) {
						clipState.setAnnotations(annotations);
					}
				}
			}

			setCuePoints(cuePoints, CueGroupType.Account, newCueId);
		}
	}, [numFilteredSequences]);

	return null;
}
