import { JSX } from 'react';
import styled, { css } from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useAccountIdentity } from 'pkg/identity/account';

interface BadgeProps {
	inDrawer?: boolean;
	inTabBar?: boolean;
}

const Badge = styled.div<BadgeProps>`
	color: var(--palette-white);
	background: var(--palette-red-500);
	border-radius: 100px;
	font-size: 0.4rem;
	font-weight: var(--font-weight-semibold);
	padding: 2px;
	min-width: 0.8rem;
	text-align: center;

	@media ${breakpoints.extraLarge} {
		font-size: 0.6rem;
		line-height: 1;
		padding: 3px;
		min-width: 1rem;
	}

	${({ inDrawer }) =>
		inDrawer &&
		css`
			position: absolute;
			top: 3px;
			left: 5px;
			z-index: 1;

			@media ${breakpoints.extraLarge} {
				top: 4px;
				left: 6px;
			}

			color: var(--palette-white);
			background: var(--palette-red-500);
		`}

	${({ inTabBar }) =>
		inTabBar &&
		css`
			position: absolute;
			top: -3px;
			right: -6px;
			z-index: 1;
			background: var(--palette-red-500);
			min-width: 0.7rem;
			color: var(--palette-white);
			box-shadow: var(--palette-white) 0 0 0 2px;
		`}
`;

interface UnreadChatMessagedBadgeProps {
	inDrawer?: boolean;
	inTabBar?: boolean;
}

const UnreadChatMessagesBadge = ({
	inDrawer,
	inTabBar,
}: UnreadChatMessagedBadgeProps): JSX.Element => {
	const { organization, organizations } = useAccountIdentity();
	const unread = organizations.find(
		(o) => o.id === organization.id
	)?.unreadChatCount;

	useEndpoint<{ unreadCount: number }>(endpoints.Chat.UnreadCount(), {});

	let unreadChatsCount = '';

	if (!unread) {
		return null;
	}

	if (unread > 99) {
		unreadChatsCount = '99+';
	} else {
		unreadChatsCount = unread.toString();
	}

	return (
		<Badge inDrawer={inDrawer} inTabBar={inTabBar}>
			<span>{unreadChatsCount}</span>
		</Badge>
	);
};

export default UnreadChatMessagesBadge;
