import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';

import FormInputField from 'routes/forms/registration/form/FormInputField';

import * as CardList from 'components/card-list';
import Avatar from 'components/avatar';

import Section from 'components/form/Section';
import Column from 'components/layout/column';

interface Props {
	account: models.account.Account;
	fields: models.form.Field[];

	userData?: models.formSubmission.SubmissionUserData;
}

const FormFields = ({ fields, account, userData }: Props) => {
	const t = useT();

	return (
		<Section title={t('Registration for')}>
			<Column spacing={spacing._7}>
				<Column>
					<CardList.Base>
						<CardList.Image>
							<Avatar account={account} />
						</CardList.Image>
						<CardList.Title>{models.account.fullName(account)}</CardList.Title>
					</CardList.Base>
				</Column>
				<Column spacing={spacing._6}>
					{fields
						.sort((a, b) => a.sortOrder - b.sortOrder)
						.map((field) => {
							const userDataValue = userData?.userData?.[field.key] || null;

							return (
								<FormInputField
									key={field.key}
									field={field}
									userDataValue={userDataValue}
								/>
							);
						})}
				</Column>
			</Column>
		</Section>
	);
};

export default FormFields;
