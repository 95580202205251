// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ro7MdXCn6PJoZMngDZRz {
	margin-bottom: var(--spacing-4);
}

@media (min-width: 769px) {

.tXe4ovXnJ81OeVc2AuQQ {
		padding: 0 var(--spacing-4) var(--spacing-4);
}
	}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/attendance/styles.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;AAChC;;AAGC;;AADD;EAEE,4CAA4C;AAE9C;CADC","sourcesContent":[".summaryWrapper {\n\tmargin-bottom: var(--spacing-4);\n}\n\n.tableContentWrapper {\n\t@media (--from-small-viewport) {\n\t\tpadding: 0 var(--spacing-4) var(--spacing-4);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var summaryWrapper = `Ro7MdXCn6PJoZMngDZRz`;
export var tableContentWrapper = `tXe4ovXnJ81OeVc2AuQQ`;
export default ___CSS_LOADER_EXPORT___;
