import { JSX, ChangeEvent, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import useMixedState from 'pkg/hooks/useMixedState';
import * as routes from 'pkg/router/routes';
import { Group } from 'pkg/api/models/onboarding_info';

import {
	fetchCurrentAccount,
	useOnboardingContext,
} from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import ThreeSixtyLogo from 'routes/public/styles/logo';
import Title from 'routes/public/styles/Title';
import Form from 'routes/public/styles/forms';
import Field from 'routes/public/styles/inputs/field';
import ForgotPasswordLink from 'routes/public/styles/ForgotPasswordLink';
import { useOnboardingState } from 'routes/public/hooks';

import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

interface LoginPayload {
	email: string;
	password: string;
}

export default function LoginEmail(): JSX.Element {
	const onboardingState = useOnboardingState();
	const { resetStore } = useOnboardingContext();

	const [isLoading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<{ title: string; message: string }>({
		title: '',
		message: '',
	});

	const [payload, setPayload] = useMixedState<LoginPayload>({
		email: '',
		password: '',
	});

	const returnToForms = onboardingState.get<string>('returnToForms');

	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		setPayload({ [event.target.name]: event.target.value });

	const authenticateAccount = async () => {
		setLoading(true);

		if (payload.password.length === 0) {
			setLoading(false);
			return;
		}

		const success = await actions.auth.passwordAuth(
			payload.email,
			payload.password,
			null,
			true
		);

		if (success) {
			const hasReturnToForms = returnToForms;

			resetStore();

			if (hasReturnToForms) {
				const [, account] = await fetchCurrentAccount();
				const group = onboardingState.get<Group>('group');

				if (account) {
					window.history.pushState(
						{},
						'',
						routes.Registration.Form(
							group.organizationSlug,
							onboardingState.get<string>('formGUID')
						)
					);
					window.location.reload();
				}
			} else {
				window.history.pushState({}, '', '/');
				window.location.reload();
			}
		} else {
			setError({
				title: t('Could not log in'),
				message: t('Invalid email and/or password combination.'),
			});
		}

		setLoading(false);
	};

	return (
		<Column spacing={styles.spacing._8}>
			<ThreeSixtyLogo />

			<Column spacing={styles.spacing._8}>
				<Title title={t('Log in with email')} center />

				<Form onSubmit={authenticateAccount}>
					<Column spacing={styles.spacing._7}>
						<AnimatePresence>
							{error.title && (
								<InfoBox color="red">
									<strong>{error.title}</strong>
									<span>{error.message}</span>
								</InfoBox>
							)}
						</AnimatePresence>
						<Column>
							<Field
								required
								type="email"
								name="email"
								placeholder={t('Your email')}
								inputMode="email"
								value={payload.email}
								onChange={handleChange}
								tabIndex={1}
							/>
							<Field
								required
								type="password"
								name="password"
								placeholder={t('Your password')}
								value={payload.password}
								onChange={handleChange}
								tabIndex={2}
							/>
						</Column>

						<Button
							primary
							type="submit"
							label={t('Sign in')}
							busy={isLoading}
						/>
					</Column>
				</Form>

				<ForgotPasswordLink />
			</Column>
		</Column>
	);
}
