import { JSX, Fragment, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { VideoSequenceRecordingId } from 'pkg/video';
import { formatTime } from 'pkg/timeline';
import { useCurrentVideo } from 'pkg/hooks/selectors';

import Form from 'routes/video/shared/clips/Form';
import FormAction from 'routes/video/shared/clips/FormAction';
import UserTagsForm from 'routes/video/shared/forms/UserTags';
import useClipRecording from 'routes/video/shared/hooks/use-clip-recording';
import RecordButton from 'routes/video/shared/RecordButton';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Modal, { Step, useStepModalContext } from 'components/step-modal';

import { useCueState } from 'components/video-analytics/CueState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useClipState } from 'components/video-analytics/ClipState';

import Button from 'design/button';

const Actions = styled.div`
	margin-top: var(--spacing-7);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: var(--spacing-3);

	&[data-is-recording='true'] {
		grid-template-columns: 0.35fr 0.65fr;
	}
`;

interface EditProps {
	hasChanges: boolean;
	save: () => Promise<void>;
	cancel: () => Promise<void>;
	destroy: () => Promise<void>;
}

export default function Edit({
	hasChanges,
	save,
	cancel,
	destroy,
}: EditProps): JSX.Element {
	const stepModalContext = useStepModalContext();
	const { currentCue } = useCueState();
	const clipState = useClipState();

	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const { isRecording, justRecorded, setTrimming, setEditing, controller } =
		usePlayerState();

	const clipId = isRecording
		? VideoSequenceRecordingId
		: (currentCue?.cueId as number);

	const video = useCurrentVideo();
	const recording = useClipRecording();

	const persisted = clipId !== VideoSequenceRecordingId;

	const showUserTags = () => {
		if (stepModalContext) {
			stepModalContext.goTo('tags');
		} else {
			setModalOpen(true);
		}
	};

	const closeModal = () => setModalOpen(false);

	const activateTrim = () => {
		controller.seekTo(clipState.getEndsAt(), false);

		setTrimming(true);
		setEditing(false);
	};

	const confirmDestroy = () => {
		if (confirm(t('Are you sure? This action cannot be undone.'))) {
			destroy();
		}
	};

	const modalTitle = isRecording ? t('Save clip as...') : t('Edit clip');

	return (
		<Fragment>
			{modalOpen && (
				<Modal onClose={closeModal}>
					<Step title={modalTitle} nextLabel={t('Done')} skipBody>
						<UserTagsForm />
					</Step>
				</Modal>
			)}
			<Form
				clipId={clipId}
				actions={
					<Fragment>
						{video.groupId && (
							<FormAction
								icon="user-add"
								label={t('Tag users')}
								description={t('Tagged users will be able to see this clip')}
								count={Object.keys(clipState.taggedUsers).length}
								onClick={showUserTags}
							/>
						)}
						<SmallScreen>
							<FormAction
								icon="scissors"
								label={t('Trim clip length')}
								description={`${formatTime(
									clipState.getStartsAt()
								)} - ${formatTime(clipState.getEndsAt())}`}
								onClick={activateTrim}
							/>
						</SmallScreen>
					</Fragment>
				}>
				{persisted && (
					<Button block caution onClick={confirmDestroy}>
						{t('Delete')}
					</Button>
				)}
				<LargeScreen>
					<Actions data-is-recording={isRecording}>
						<Button transparent onClick={cancel}>
							{t('Cancel')}
						</Button>
						{!justRecorded && !persisted ? (
							<RecordButton onClick={recording.stop}>
								{t('Stop recording')}
							</RecordButton>
						) : (
							<Button primary disabled={!hasChanges} onClick={save}>
								{t('Save')}
							</Button>
						)}
					</Actions>
				</LargeScreen>
			</Form>
		</Fragment>
	);
}
