import { JSX } from 'react';

import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import CardWrapper from 'components/Card';

import MatchResult from 'components/match/result';

interface MatchStatisticsProps {
	match: models.match.Match;
}

export default function MatchStatistics({
	match,
}: MatchStatisticsProps): JSX.Element {
	const hasStats =
		match?.goalCount !== null && match?.opponentGoalCount !== null;
	const org = useCurrentOrganization();

	if (!match) {
		return null;
	}

	return (
		<CardWrapper
			as={hasStats && Link}
			href={routes.Match.View(org.id, match.eventId, match.id, 'summary')}>
			<MatchResult match={match} />
		</CardWrapper>
	);
}
