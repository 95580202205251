import { t } from '@transifex/native';
import { JSX, useState, Fragment } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import * as exerciseService from 'pkg/actions/services/exercises';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { Exercise } from 'pkg/api/models/exercise';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { setConfirmNavigation } from 'pkg/router/utils';
import * as actions from 'pkg/actions';
import { useCurrentUser } from 'pkg/identity';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import ExerciseFormFields from 'components/training/exercise/ExerciseFormFields';
import { normalizeExerciseData } from 'components/training/exercise/utils';
import Form, { FormPayload } from 'components/form/Form';
import * as ActionBar from 'components/layout/ActionBar';
import { DRAWING_DATA_VERSION } from 'components/drawing/config';

import Button from 'design/button';

interface CreateExerciseProps {
	isInSession?: boolean;
	onCancel?: () => void;
	onSave?: (id: number) => void;
	toggleCollectionModal?: (id?: number) => void;
	collectionModalExerciseId?: number;
}

const CreateExercise = ({
	onCancel,
	onSave,
	toggleCollectionModal,
	collectionModalExerciseId,
}: CreateExerciseProps): JSX.Element => {
	const [isSaving, setIsSaving] = useState(false);
	const [tags, setTags] = useState(null);
	const [attachments, setAttachments] = useState([]);
	const [drawings, setDrawings] = useState({});
	const [thumbnail, setThumbnail] = useState(null);
	const userId = useCurrentUser().id;

	useComponentDidMount(
		() => {
			setConfirmNavigation(
				t('Are you sure you want to leave without saving the exercise?', {
					_context: 'training_library',
				})
			);
		},
		() => {
			setConfirmNavigation(null);
		}
	);

	const handleSave = async (data: FormPayload) => {
		// normalize form data
		const payload = normalizeExerciseData({ data, tags, thumbnail });

		// start saving
		setIsSaving(true);

		const [request, response] = await models.create<unknown, Exercise>(
			endpoints.Exercises.Create(),
			{
				...payload,
				userId,
			}
		);

		if (!response?.id) {
			actions.flashes.show(
				{
					title: t('Could not save exercise'),
				},
				request.status
			);
			return;
		}

		// save drawings
		for await (const [, drawing] of Object.entries(drawings)) {
			await models.create(endpoints.Exercises.CreateDrawing(), {
				exerciseId: response.id,
				version: DRAWING_DATA_VERSION,
				data: JSON.stringify(drawing),
			});
		}

		// link new attachments
		for await (const attachment of attachments) {
			await exerciseService.createExerciseAttachment(
				response.id,
				attachment.id
			);
		}

		setConfirmNavigation(null);
		if (onSave) onSave(response.id);
	};

	const closeCollectionModal = () => {
		// close modal and navigate to library
		toggleCollectionModal();
		setIsSaving(false);
	};

	const cancel = () => {
		setConfirmNavigation(null);
		onCancel();
	};

	const ActionBarActions = () => (
		<Fragment>
			<LargeScreen>
				<Button onClick={cancel}>{t('Cancel')}</Button>
				<Button primary type="submit" disabled={isSaving}>
					{t('Save')}
				</Button>
			</LargeScreen>
			<SmallScreen>
				<Button block large onClick={cancel}>
					{t('Cancel')}
				</Button>
				<Button primary block large type="submit" disabled={isSaving}>
					{t('Save')}
				</Button>
			</SmallScreen>
		</Fragment>
	);

	return (
		<Form onSubmit={handleSave}>
			<ActionBar.SaveBar maxWidth={PageWidths.WIDE}>
				<ActionBarActions />
			</ActionBar.SaveBar>
			<ExerciseFormFields
				toggleCollectionModal={closeCollectionModal}
				collectionModalExerciseId={collectionModalExerciseId}
				ActionBarActions={ActionBarActions}
				onTagUpdate={setTags}
				newAttachments={attachments}
				setNewAttachments={setAttachments}
				drawings={drawings}
				setDrawings={setDrawings}
				newThumbnail={thumbnail}
				setThumbnail={setThumbnail}
			/>
		</Form>
	);
};

export default CreateExercise;
