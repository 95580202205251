import * as endpoints from 'pkg/api/endpoints/auto';
import { InitTransferInstrumentResponse } from 'pkg/api/payment_providers/adyen';
import * as sdk from 'pkg/core/sdk';

interface GetOnboardingLinkResponse {
	redirectUrl: string;
}

export async function getOnboardingLink(
	groupId: number,
	paymentConfigId: number
): Promise<[boolean, GetOnboardingLinkResponse]> {
	const req = await sdk.get(
		endpoints.Adyen.GetOnboardingLink(groupId, paymentConfigId),
		{ return_url: window.location.href }
	);

	if (!req.ok) {
		return [false, null];
	}

	const s: GetOnboardingLinkResponse = await req.json();

	window.location.assign(s.redirectUrl);

	return [true, s];
}

export async function initTransferInstrument(
	groupId: number,
	paymentConfigId: number
): Promise<[boolean, InitTransferInstrumentResponse]> {
	const req = await sdk.post(
		endpoints.Adyen.InitiateManageTransferInstruments(groupId, paymentConfigId)
	);

	if (!req.ok) {
		return [false, null];
	}

	const resp = await req.json();

	return [true, resp];
}

export async function initReportingSession(
	groupId: number,
	paymentConfigId: number
): Promise<[boolean, InitTransferInstrumentResponse]> {
	const req = await sdk.post(
		endpoints.Adyen.InitiateReporting(groupId, paymentConfigId)
	);

	if (!req.ok) {
		return [false, null];
	}

	const resp = await req.json();

	return [true, resp];
}
