import { ChangeEvent, Fragment, ReactNode, useState } from 'react';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import { filterDuplicatesByKey } from 'pkg/arrays';

import * as StepModal from 'components/step-modal';

import useAttachments from 'components/attachment/hooks/useAttachments';

import Editor from 'design/editor';

import * as css from './styles.css';

interface UserLogEditModalHook {
	UserLogEditModal: ReactNode;

	openUserLogEditModal: (userLog: models.userLog.UserLog) => void;
}

export default function useUserLogEditModal(
	user: models.user.User,

	onUpdate: (userLog: models.userLog.UserLog) => void,
	onRemoveAttachment: (attachmentId: number) => Promise<boolean>
): UserLogEditModalHook {
	const t = useT();

	const [content, setContent] = useState<string>();
	const [isOpen, setOpen] = useState<boolean>(false);
	const [userLog, setUserLog] = useState<models.userLog.UserLog>();

	const openUserLogEditModal = (userLog: models.userLog.UserLog) => {
		setUserLog(userLog);
		setContent(userLog.content);
		setOpen(true);

		if (userLog?.attachments?.length > 0) {
			setExistingAttachments(filterDuplicatesByKey(userLog.attachments, 'id'));
		}
	};

	const handleClose = () => {
		setUserLog(null);
		setContent(null);
		setOpen(null);
	};

	const handleContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setContent(event.target.value);
	};

	const {
		FileUploader,
		AttachmentItems,
		allAttachmentsUploaded,
		setExistingAttachments,
	} = useAttachments({
		buttonIcon: 'attachment',
		onRemoveAttachment,
		onNewAttachment: async (attachment: models.attachment.Attachment) => {
			await sdk.post(
				endpoints.UserLogs.AddAttachment(user.id, userLog.id, attachment.id)
			);
		},
	});

	const handleUpdate = async () => {
		const updated = await actions.userLogs.update(userLog, { content });

		onUpdate(updated);

		setContent(null);
	};

	const hasContent: boolean = content?.length > 0;
	const canSave: boolean = hasContent && allAttachmentsUploaded;

	const UserLogEditModal = (
		<Fragment>
			{isOpen && (
				<StepModal.Base onClose={handleClose}>
					<StepModal.Step
						skipBody
						title={t('Edit user note')}
						prevLabel={t('Cancel')}
						nextLabel={t('Update')}
						canGoNext={canSave}
						onNext={handleUpdate}>
						<div className={css.editorWrapper}>
							<Editor
								spacious
								value={content}
								onChange={handleContentChange}
								placeholder={t('Edit user note…')}
								minHeight={100}
								maxHeight={160}
								innerContent={AttachmentItems}>
								{FileUploader}
							</Editor>

							{userLog.attachments?.length > 0 && (
								<p className={css.attachmentWarning}>
									{t(
										'Removing attachments will delete them immediately, you cannot undo a delete attachment action.'
									)}
								</p>
							)}
						</div>
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);

	return {
		UserLogEditModal,
		openUserLogEditModal,
	};
}
