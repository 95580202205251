import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { CreateAccountResponse } from 'pkg/actions/accounts';

import { Account, Group } from 'pkg/api/models/onboarding_info';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import Link from 'pkg/router/Link';
import * as actions from 'pkg/actions';
import * as language from 'pkg/i18n/language';
import { MembershipRole } from 'pkg/api/models/membership';
import useMixedState from 'pkg/hooks/useMixedState';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRoute } from 'pkg/router/hooks';

import * as publicStyles from 'routes/public/styles/styles.css';
import { useOnboardingContext } from 'routes/public/onboarding';
import {
	useOnboardingAccountPayload,
	OnboardingAccountPayload,
	useOnboardingState,
} from 'routes/public/hooks';
import Button from 'routes/public/styles/Button';
import FooterNote from 'routes/public/styles/FooterNote';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import AccountDetailsForm, {
	AccountDetailsPayload,
} from 'routes/public/steps/shared/AccountDetailsForm';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

export default function AccountDetails(): JSX.Element {
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const route = useCurrentRoute();

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);

	const [error, setError] = useState<{ title: string; message: string }>({
		title: '',
		message: '',
	});

	const accountFromState = onboardingState.get<Account>('account');
	const groupFromState = onboardingState.get<Group>('group');

	const [payload, setPayload] = useMixedState<AccountDetailsPayload>({
		...accountFromState,
	});

	const getPayload = useOnboardingAccountPayload();

	const canContinue =
		payload.email &&
		payload.email === payload.emailConfirmation &&
		payload.password &&
		payload.password === payload.passwordConfirmation;

	const appleToken = route.query?.code || '';

	useComponentDidMount(
		() => {
			if (appleToken || accountFromState?.ssoToken) {
				handleSave();
			}
		},
		() => {
			// @NOTE Remove code on unmount
			window.history.replaceState({}, '/account-details');
		}
	);

	const handleSave = async () => {
		setIsSaving(true);

		const account: Account = {
			...onboardingState.get('account'),
			email: payload.email,
			password: payload.password,
		};

		await onboardingState.setAccount(account);

		const accountRole = onboardingState.get<Account>('account')?.role;
		const parentalConsentEmail = onboardingState.get<string>(
			'parentalConsentEmail'
		);

		let canContinue: boolean;

		try {
			const [request, response] = await api
				.acceptLanguage<
					CreateAccountResponse,
					OnboardingAccountPayload
				>(language.get())
				.post(endpoints.Accounts.Create(), {
					...getPayload(),
					email: payload.email,
					password: payload.password,
				});

			if (!request.ok) {
				setIsSaving(false);

				throw new Error(`${request.status}`);
			}

			await actions.auth.updateAuthToken(
				{
					accountToken: response.authToken,
					orgToken: response.orgToken,
				},
				groupFromState.organizationId
			);

			if (parentalConsentEmail) {
				const req =
					await actions.accounts.requestParentalConsent(parentalConsentEmail);

				if (!req) {
					canContinue = false;

					throw new Error('500');
				}
			}

			if (
				accountRole !== 'parent' &&
				accountRole !== undefined &&
				!parentalConsentEmail
			) {
				const groupCode = onboardingState.get<string>('groupCode');

				const role =
					accountRole === 'staff' ? MembershipRole.Staff : MembershipRole.User;

				const [joinRequest] = await api.post(
					endpoints.Groups.JoinFromInviteCode(),
					{
						code: groupCode,
						role,
					}
				);

				if (!joinRequest.ok) {
					setIsSaving(false);

					throw new Error(`${joinRequest.status}`);
				}
			}

			canContinue = true;
		} catch (error: unknown) {
			canContinue = false;

			if (appleToken || accountFromState?.ssoToken) {
				setHasError(true);
			}

			const status = Number.parseInt((error as Error).message, 10);

			let title = t('Something went wrong');
			let message = t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			);

			switch (status) {
				case 400:
				case 404:
					title = t('Could not create account');
					message = t(
						'You can try again, if the problem still persists please contact our customer support'
					);
					break;
				case 409:
					title = t('Email already in use');
					message = t('This email is already registered in 360Player');
					break;
			}

			actions.flashes.show({
				title,
				message,
			});

			setError({
				title,
				message,
			});

			setPayload({
				email: '',
				emailConfirmation: '',
			});

			return;
		} finally {
			if (!canContinue) return;

			if (parentalConsentEmail) {
				goTo('gdpr-request-pending');
				return;
			}

			if (accountFromState.role === 'parent') {
				goTo('create-ward-account');
				return;
			}

			if (groupFromState.isOrganization) {
				goTo('organization-finished');
				return;
			}

			if (accountFromState.role === 'player') {
				goTo('player-finished');
				return;
			}

			if (accountFromState.role === 'staff') {
				goTo('staff-finished');
				return;
			}
		}

		setIsSaving(false);
	};

	if (hasError) {
		return (
			<Column spacing={styles.spacing._8}>
				<Title
					title={
						accountFromState?.ssoToken
							? t('Could not connect using single sign-on')
							: t('Could not connect using Apple')
					}
					description={t(
						'You can try to connect again, if the problem still persists please contact our customer support.'
					)}
					thinDescription
				/>

				<Button label={t('Try again')} onClick={() => goTo('create-account')} />
			</Column>
		);
	}

	if (appleToken || accountFromState?.ssoToken) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title title={t('Please provide your account details')} />

				<AccountDetailsForm
					handleSave={handleSave}
					payload={payload}
					setPayload={setPayload}
					error={error}
					canContinue={canContinue}
					isSaving={isSaving}
				/>

				<FooterNote>
					<T
						_str="By signing up you agree to our {terms_and_conditions_link}"
						terms_and_conditions_link={
							<Link href="https://www.360player.com/terms-of-service">
								{t('terms and conditions')}
							</Link>
						}
					/>
				</FooterNote>
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton disabled={isSaving} />
			</div>
		</Fragment>
	);
}
