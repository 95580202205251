import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';

import InvitedList from 'routes/event/series/event_series_users/invited-list';
import * as css from 'routes/event/single/styles.css';
import { useEventSeriesContext } from 'routes/event/series/single';

import { useUserSelectModal } from 'components/user-select-modal';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import { DialogOptions, useDialog } from 'design/dialog';

export default function EventSeriesUsers(): JSX.Element {
	const eventSeriesCtx = useEventSeriesContext();

	const eventSeries = eventSeriesCtx.eventSeriesEndpoint.record;
	const eventSeriesUsers = eventSeries.seriesUsers || [];

	const defaultSendInvites: boolean =
		eventSeries.type === models.eventSeries.EventSeriesType.Practice
			? false
			: true;

	const [sendInvites, setSendInvites] = useState<boolean>(defaultSendInvites);

	const [selectedOrganizers, setSelectedOrganizers] = useState<
		models.user.User[]
	>([]);

	const [selectedParticipants, setSelectedParticipants] = useState<
		models.user.User[]
	>([]);

	const handleSetInvites = () => {
		setSendInvites(!sendInvites);
	};

	const organizers = eventSeriesUsers.filter(
		(eventUser) => eventUser.role === 'organizer'
	);

	const participants = eventSeriesUsers.filter(
		(eventUser) => eventUser.role === 'participant'
	);

	const invitedUserIds = eventSeriesUsers.map((eventUser) => eventUser.userId);

	const saveParticipants = async (users: models.user.User[]) => {
		if (users.length > 0) {
			await actions.eventSeriesUsers.add(eventSeries.id, {
				userIDs: users.map((user) => user.id),
				sendInvites,
				organizer: {},
			});

			await eventSeriesCtx.eventSeriesEndpoint.refresh();
		}

		setSelectedParticipants([]);
	};

	const saveOrganizers = async (users: models.user.User[]) => {
		if (users.length > 0) {
			await actions.eventSeriesUsers.add(eventSeries.id, {
				userIDs: users.map((user) => user.id),
				sendInvites,
				organizer: users.reduce(
					(agg, u) => ({
						...agg,
						[u.id]: true,
					}),
					{}
				),
			});

			await eventSeriesCtx.eventSeriesEndpoint.refresh();
		}

		setSelectedOrganizers([]);
	};

	const sendInvitesOptions: Partial<DialogOptions> = {
		wide: true,
		symbol: 'calendar_add_on',
		title: t('Send invites?'),
		confirmLabel: t('Finish'),
		onCancel: () => {
			setSendInvites(defaultSendInvites);
			setSelectedOrganizers([]);
			setSelectedParticipants([]);
		},
		message: (
			<Column>
				<span>
					{t(
						'If you send invites, organizers and participants will be notified to take action. Otherwise invited users will automatically be added as "Going".'
					)}
				</span>
				<label className={css.sendInvites} onClick={handleSetInvites}>
					<Row columns="auto 1fr" align="center" spacing={styles.spacing._3}>
						<Input.Control standalone type="checkbox" checked={sendInvites} />
						<strong>{t('Yes, send invites')}</strong>
					</Row>
				</label>
			</Column>
		),
	};

	const confirmSendInvitesToOrganizers = useDialog(
		{
			...sendInvitesOptions,
			onConfirm: async () => saveOrganizers(selectedOrganizers),
		} as DialogOptions,
		[sendInvites, selectedOrganizers]
	);

	const confirmSendInvitesToParticipants = useDialog(
		{
			...sendInvitesOptions,
			onConfirm: async () => saveParticipants(selectedParticipants),
		} as DialogOptions,
		[sendInvites, selectedParticipants]
	);

	const organizersSelect = useUserSelectModal({
		title: t('Add organizers'),
		saveLabel: t('Add organizers'),
		ignoredUserIds: invitedUserIds,
		defaultActiveTabId: 'admin',
		onSave: (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedOrganizers(users);
				confirmSendInvitesToOrganizers();
			}
		},
		onCancel: () => {
			setSelectedOrganizers([]);
		},
	});

	const participantsSelect = useUserSelectModal({
		title: t('Add participants'),
		saveLabel: t('Add participants'),
		ignoredUserIds: invitedUserIds,
		defaultActiveTabId: 'user',
		onSave: (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedParticipants(users);
				confirmSendInvitesToParticipants();
			}
		},
	});

	return (
		<Fragment>
			<Column spacing={styles.spacing._8}>
				<InvitedList
					variant="organizers"
					eventSeries={eventSeries}
					eventSeriesUsers={organizers}
					toggleInviteModal={organizersSelect.open}
				/>
				<InvitedList
					variant="participants"
					eventSeries={eventSeries}
					eventSeriesUsers={participants}
					toggleInviteModal={participantsSelect.open}
				/>
			</Column>
			{organizersSelect.Component}
			{participantsSelect.Component}
		</Fragment>
	);
}
