import { JSX, ChangeEvent, useState } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { range } from 'pkg/utils';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import Column from 'components/layout/column';
import {
	SettingControl,
	SettingDescription,
	SettingLabel,
	SettingRow,
} from 'components/settings/Settings';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

interface ConfirmBirthProps {
	handleConfirmBirthYear: () => void;
}
export function ConfirmBirthButton({
	handleConfirmBirthYear,
}: ConfirmBirthProps): JSX.Element {
	const contextMenu = ContextMenu.useContextMenu();
	const handleConfirm = async () => {
		handleConfirmBirthYear();
		contextMenu.close();
	};
	return <Button secondary label={t('Confirm')} onClick={handleConfirm} />;
}
interface BirthYearSettingProps {
	group: models.group.Group;
	refresh?: () => void;
}
export default function BirthYearSetting({
	group,
	refresh,
}: BirthYearSettingProps): JSX.Element {
	const [birthFrom, setBirthFrom] = useState(group.birthYearFrom);
	const [birthTo, setBirthTo] = useState(group.birthYearTo);

	const currentDate = new Date();
	const years = () =>
		range(currentDate.getFullYear() - 100, currentDate.getFullYear()).map(
			(n) => [n]
		);

	const restrictedYears = () =>
		range(
			birthFrom !== 0 ? birthFrom : currentDate.getFullYear() - 100,
			currentDate.getFullYear()
		).map((n) => [n]);

	const handleBirthFrom = (event: ChangeEvent<HTMLOptionElement>) => {
		setBirthFrom(Number.parseInt(event.target.value));
		if (Number.parseInt(event.target.value) > birthTo) {
			setBirthTo(Number.parseInt(event.target.value));
		}
	};
	const handleBirthTo = (event: ChangeEvent<HTMLOptionElement>) => {
		setBirthTo(Number.parseInt(event.target.value));
	};

	const handleConfirmBirthYear = async () => {
		const [request] = await models.update(group, {
			birthYearFrom: birthFrom,
			birthYearTo: birthTo,
		});
		if (request.ok) {
			refresh();
		}
	};

	return (
		<SettingRow>
			<SettingLabel>{t('Birth year')}</SettingLabel>
			<SettingDescription>
				{group.birthYearFrom && group.birthYearTo
					? group.birthYearFrom + ' - ' + group.birthYearTo
					: t('Select birth year')}
			</SettingDescription>
			<SettingControl>
				<div>
					<ContextMenu.Menu
						toggleWith={
							<Button small icon="edit">
								{t('Change')}
							</Button>
						}>
						<ContextMenu.Heading>
							<div>{t('Set birth year range')}</div>
							{group.birthYearFrom && group.birthYearTo
								? group.birthYearFrom + ' - ' + group.birthYearTo
								: ''}
						</ContextMenu.Heading>
						<ContextMenu.Pane>
							<Column>
								<Row>
									<Input.Group label={t('From')} required>
										<Input.Select value={birthFrom} onChange={handleBirthFrom}>
											<option>{t('Select')}</option>
											{years().map((year) => (
												<option key={year.toString()} value={year}>
													{year}
												</option>
											))}
										</Input.Select>
									</Input.Group>
									<Input.Group label={t('To')} required>
										<Input.Select value={birthTo} onChange={handleBirthTo}>
											<option>{t('Select')}</option>
											{restrictedYears().map((year) => (
												<option key={year.toString()} value={year}>
													{year}
												</option>
											))}
										</Input.Select>
									</Input.Group>
								</Row>
								<ConfirmBirthButton
									handleConfirmBirthYear={handleConfirmBirthYear}
								/>
							</Column>
						</ContextMenu.Pane>
					</ContextMenu.Menu>
				</div>
			</SettingControl>
		</SettingRow>
	);
}
