import { JSX, ChangeEvent, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import Link from 'pkg/router/Link';

import Button from 'routes/public/styles/Button';
import Title from 'routes/public/styles/Title';
import LogoutButton from 'routes/public/styles/LogoutButton';
import Field from 'routes/public/styles/inputs/field';
import Form from 'routes/public/styles/forms';
import { useOnboardingContext } from 'routes/public/onboarding';
import * as publicStyles from 'routes/public/styles/styles.css';

import Icon from 'components/icon';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

interface ErrorMessage {
	message: string;
	link?: string;
	linkText?: string;
}

export default function WardConnect(): JSX.Element {
	const { resetStore } = useOnboardingContext();

	const [isSaving, setIsSaving] = useState(false);
	const [wardEmail, setWardEmail] = useState<string>();

	const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
		setWardEmail(event.target.value);

	const handleErrors = (requestStatus: number | null): ErrorMessage => {
		switch (requestStatus) {
			case 400:
				return {
					message: t(`You can't connect to yourself`),
				};
			case 404:
				return {
					message: t(
						'The account could not be found, please double-check the information and try again.'
					),
				};
			case 403:
				return {
					message: t(
						'The account you want to connect to is already a parent, connect to your child instead to continue.'
					),
					link: 'https://help.360player.com/article/208-connect-to-a-child-with-an-existing-parent',
					linkText: t('Read the guide on connecting to your child'),
				};
			default:
				return {
					message: t(
						'There was an error processing your request. Please try again.'
					),
				};
		}
	};

	const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);

	const handleSave = async () => {
		setIsSaving(true);
		setErrorMessage(null);

		const [request] = await actions.accountRelations.createRequest(
			'legalGuardian',
			wardEmail
		);

		if (!request.ok) {
			const errorMessageObj = handleErrors(request.status);
			setErrorMessage(errorMessageObj);
			setIsSaving(false);
		} else {
			resetStore();
			window.history.pushState({}, '', '/');
			window.location.reload();
		}
	};

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title
					title={t('Send a connection request to your child')}
					description={t(
						`Enter the email of your child's account to send a connection request. They will need to approve you in their 360Player app for you to continue.`
					)}
					thinDescription
				/>

				<Form onSubmit={handleSave}>
					<Column>
						<Field
							name="email"
							placeholder={t(`Your child's email address`)}
							value={wardEmail}
							onChange={handleEmailChange}
						/>

						<Button
							primary
							busy={isSaving}
							label={t('Send connection request')}
							type="submit"
						/>
					</Column>
				</Form>
				{errorMessage && (
					<InfoBox color="orange">
						{errorMessage.message}
						{errorMessage.link && errorMessage.linkText && (
							<Link href={errorMessage.link}>
								{errorMessage.linkText} <Icon name="new-window" size={1.3} />
							</Link>
						)}
					</InfoBox>
				)}
			</Column>

			<div className={publicStyles.footer}>
				<LogoutButton />
			</div>
		</Fragment>
	);
}
