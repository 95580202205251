import { t } from '@transifex/native';

import Order from 'pkg/models/order';

import * as flashActions from 'pkg/actions/flashes';
import { normalizedDispatch } from 'pkg/actions/utils';
import * as orderServices from 'pkg/actions/services/order';

import * as models from 'pkg/api/models';
import store from 'pkg/store/createStore';
import { crash } from 'pkg/errors/errors';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import { formatToCurrency } from 'pkg/i18n/formatters';

import { Event, trackEvent } from 'components/analytics';

export const createBatch = async (
	payload: orderServices.OrderPayload
): Promise<Response> => {
	const [request, response, error] = await models.create<
		orderServices.OrderPayload,
		models.order.Order[]
	>(endpoints.Orders.CreateBatch(), payload);

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
			message: error.error,
		});

		return request;
	}

	normalizedDispatch(response, [Order.normalizr()])(store.dispatch);

	return request;
};

export async function refund(id: number) {
	const request = await sdk.post(endpoints.Orders.Refund(id));
	const response = await request.json();

	if (request.ok) {
		flashActions.show({
			title: t('Refund successfully issued'),
		});

		return true;
	}

	flashActions.show({
		title: crash().title,
		message: response.error,
	});

	return false;
}

export async function sendReceipt(orderIds: number[]): Promise<boolean> {
	const request = await sdk.post(
		endpoints.Orders.SendReceipt(),
		{},
		{ ids: orderIds }
	);

	if (request.ok) {
		const multiple = orderIds.length > 1;
		flashActions.show({
			title: multiple
				? t('Receipts successfully sent!')
				: t('Receipt successfully sent!'),
		});

		return true;
	}

	flashActions.show({
		title: t('Something went wrong!'),
		message: t(
			`If you think this error isn't supposed to happen, please contact 360Player support.`
		),
	});

	return false;
}

export async function sendReminder(orderIds: number[]): Promise<boolean> {
	const request = await sdk.post(
		endpoints.Orders.SendReminder(),
		{},
		{ ids: orderIds }
	);

	if (request.ok) {
		const multiple = orderIds.length > 1;
		flashActions.show({
			title: multiple
				? t('Reminders successfully sent!')
				: t('Reminder successfully sent!'),
		});

		return true;
	}

	flashActions.show({
		title: t('Something went wrong!'),
		message: t(
			`If you think this error isn't supposed to happen, please contact 360Player support.`
		),
	});

	return false;
}

export async function markAs(
	endpoint: string,
	ids: number[],
	successText: string
) {
	const request = await sdk.post(endpoint, {}, { ids });
	const response = await request.json();

	if (request.ok) {
		flashActions.show({
			title: successText,
		});

		let type = '';

		switch (endpoint) {
			case endpoints.Orders.MarkPaid():
				type = 'paid';
				break;
			case endpoints.Orders.MarkUncollectible():
				type = 'uncollectible';
				break;
			case endpoints.Orders.MarkVoid():
				type = 'void';
				break;
		}

		trackEvent(Event.OrderMarkAs, { ['marked_as']: type });

		return true;
	}

	flashActions.show({
		title: crash().title,
		message: response.error,
	});

	return false;
}

export async function remove(ids: number[]) {
	const request = await sdk.destroy(endpoints.Orders.Index(), {}, { ids });
	const response = await request.json();

	if (request.ok) {
		flashActions.show({
			title: t('Invoice successfully deleted'),
		});

		return true;
	}

	flashActions.show({
		title: crash().title,
		message: response.error,
	});

	return false;
}

export interface RefundPayload {
	amount?: number;
}

export async function createRefund(
	order: models.order.Order,
	payload: RefundPayload = {}
) {
	const [request] = await models.create<
		RefundPayload,
		models.orderRefund.OrderRefund
	>(endpoints.Orders.Refund(order.id), payload);

	if (request.ok) {
		let amount = order.amountDue - order.amountRefunded;

		if (payload.amount) {
			amount = payload.amount;
		}

		flashActions.show({
			title: t('Successfully refunded {amount}', {
				amount: formatToCurrency(amount / 100, order.currency),
			}),
		});

		return true;
	} else {
		flashActions.show({
			title: t('Something went wrong.'),
		});
	}

	return false;
}
