import { JSX, cloneElement, Fragment, SyntheticEvent, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';

import CreateResourceModal from 'routes/scheduling/resources/modals/Create';
import EditCategory from 'routes/scheduling/categories/modals/Edit';
import EditResourceModal from 'routes/scheduling/resources/modals/Edit';

import Icon from 'components/icon';
import Label from 'components/label';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import { SelectableOptions } from 'components/resources/select_modal';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

enum Modals {
	CreateResource = 'createResource',
	EditResource = 'editResource',
	EditCategory = 'editCategory',
	HideModal = '',
}

interface CategoryContextMenuProps {
	category: models.resourceCategory.ResourceCategory;
	resources: models.resource.Resource[];

	refresh: () => Promise<void>;
	refreshCategories: () => Promise<void>;
}

const CategoryContextMenu = ({
	category,
	resources,
	refresh,
	refreshCategories,
}: CategoryContextMenuProps) => {
	const [modal, setModal] = useState<Modals>(Modals.HideModal);

	const handleConfirm = async () => {
		const ok = await models.resourceCategory.remove(category);

		if (ok) {
			flashActions.show({
				title: t(`Successfully removed category`),
			});
			refreshCategories();
		}
	};

	const handleNewResource = () => setModal(Modals.CreateResource);
	const handleEditCategory = () => setModal(Modals.EditCategory);
	const hideModal = () => setModal(Modals.HideModal);

	return (
		<Fragment>
			<Table.ActionCell>
				<ContextMenu.Menu
					toggleWith={
						<ContextMenu.TableTrigger>
							<Icon name="context-menu" />
						</ContextMenu.TableTrigger>
					}>
					<ContextMenu.Item onClick={handleNewResource}>
						<ContextMenu.ItemIcon name="add" />
						{t('New resource')}
					</ContextMenu.Item>
					<ContextMenu.Item onClick={handleEditCategory}>
						<ContextMenu.ItemIcon name="edit" />
						{t(`Edit category`)}
					</ContextMenu.Item>
					<ContextMenu.ConfirmItem
						message={t(`Are you sure you want to remove this category?`)}
						caution
						onConfirm={handleConfirm}>
						<ContextMenu.ItemIcon name="delete" />
						{t(`Remove category`)}
					</ContextMenu.ConfirmItem>
				</ContextMenu.Menu>
			</Table.ActionCell>
			{modal === Modals.CreateResource && (
				<CreateResourceModal
					groupId={category.groupId}
					categoryId={category.id}
					refresh={refresh}
					hideModal={hideModal}
					refreshCategories={refreshCategories}
					resources={resources}
				/>
			)}
			{modal === Modals.EditCategory && (
				<EditCategory
					category={category}
					onClose={hideModal}
					refresh={refreshCategories}
				/>
			)}
		</Fragment>
	);
};

interface ContextMenuCellProps {
	resource: models.resource.Resource;
	resources: models.resource.Resource[];

	refresh: () => Promise<void>;
	refreshCategories: () => Promise<void>;
}

const ContextMenuCell = ({
	resource,
	resources,
	refresh,
	refreshCategories,
}: ContextMenuCellProps) => {
	const [modal, setModal] = useState<Modals>(Modals.HideModal);

	const showCreateModal = () => setModal(Modals.CreateResource);
	const showEditModal = () => setModal(Modals.EditResource);
	const hideModal = () => setModal(Modals.HideModal);

	const handleDelete = async () => {
		const ok = await models.resource.remove(resource);

		if (ok) {
			refresh();
		}
	};

	return (
		<Fragment>
			<Table.Cell>
				<ContextMenu.Menu
					toggleWith={
						<ContextMenu.TableTrigger>
							<Icon name="context-menu" />
						</ContextMenu.TableTrigger>
					}>
					<ContextMenu.LinkItem onClick={showCreateModal}>
						<ContextMenu.ItemIcon name="add" />
						{t(`Create sub resource`)}
					</ContextMenu.LinkItem>
					<ContextMenu.LinkItem onClick={showEditModal}>
						<ContextMenu.ItemIcon name="edit" />
						{t('Edit')}
					</ContextMenu.LinkItem>
					<ContextMenu.Divider />
					<ContextMenu.ConfirmItem
						caution
						onConfirm={handleDelete}
						message={t(`Are you sure you want to delete this resource?`)}>
						<ContextMenu.ItemIcon name="delete" />
						{t('Delete')}
					</ContextMenu.ConfirmItem>
				</ContextMenu.Menu>
			</Table.Cell>
			{modal === Modals.EditResource && (
				<EditResourceModal
					resource={resource}
					resources={resources}
					refreshResources={refresh}
					refreshCategories={refreshCategories}
					hideModal={hideModal}
				/>
			)}
			{modal === Modals.CreateResource && (
				<CreateResourceModal
					groupId={resource.groupId}
					hideModal={hideModal}
					resourceId={resource.id}
					refresh={refresh}
					categoryId={resource.resourceCategoryId}
					locationId={resource.resourceLocationId}
					refreshCategories={refreshCategories}
					resources={resources}
				/>
			)}
		</Fragment>
	);
};

interface SearchRowProps {
	selectable: SelectableOptions;
	selected: number[];

	select: (resourceId: number) => void;
	refresh: () => Promise<void>;
	refreshCategories: () => Promise<void>;

	resources: models.resource.Resource[];
	resource: models.resource.Resource;

	extraColumns: Table.ExtraColumn[];
}

const SearchRow = ({
	resource,
	refresh,
	refreshCategories,
	selectable,
	selected,
	resources,
	select,
	extraColumns,
}: SearchRowProps) => {
	const parentResource = resources.find(
		(r) => r.id === resource.parentResourceId
	);

	const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();

		select(resource.id);
	};

	const columnComponents = (
		<Fragment>
			{extraColumns.map((c, i) => (
				<Table.Cell key={i} onClick={selectable && handleClick}>
					{cloneElement(c.component, {
						resource,
					})}
				</Table.Cell>
			))}
		</Fragment>
	);

	return (
		<Table.Row>
			{selectable ? (
				<Table.Cell onClick={handleClick}>
					<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
						<div>
							<Input.Control
								type={
									selectable === SelectableOptions.single ? 'radio' : 'checkbox'
								}
								standalone
								checked={selected.includes(resource.id)}
							/>
						</div>
						<span>{resource.title}</span>
					</Row>
				</Table.Cell>
			) : (
				<Table.Cell>{resource.title}</Table.Cell>
			)}
			<Table.Cell onClick={selectable && handleClick}>
				{resource.location && resource.location.title}
			</Table.Cell>
			<Table.Cell onClick={selectable && handleClick}>
				{parentResource ? parentResource.title : '-'}
			</Table.Cell>
			{columnComponents}
			<ContextMenuCell
				resources={resources}
				resource={resource}
				refresh={refresh}
				refreshCategories={refreshCategories}
			/>
		</Table.Row>
	);
};

interface ResourceRow {
	level?: number;
	selectable: SelectableOptions;
	selected: number[];

	refresh: () => Promise<void>;
	refreshCategories: () => Promise<void>;
	select: (resourceId: number) => void;

	resource: models.resource.Resource;
	resources: models.resource.Resource[];

	extraColumns: Table.ExtraColumn[];
}

const ResourceRow = ({
	resource,
	level = 0,
	resources,
	refresh,
	refreshCategories,
	selectable,
	select,
	selected,
	extraColumns,
}: ResourceRow) => {
	const [isExpanded, setIsExpanded] = useState(true);

	const handleTraverse = () => {
		setIsExpanded(!isExpanded);
	};

	const parentResource = resources.find(
		(r) => r.id === resource.parentResourceId
	);
	const childResources = resources.filter(
		(r) => r.parentResourceId === resource.id
	);

	const handleClick = (e: SyntheticEvent<HTMLDivElement>) => {
		e.stopPropagation();

		select(resource.id);
	};

	let columnComponents = null;

	if (extraColumns.length > 0) {
		columnComponents = (
			<Fragment>
				{extraColumns.map((c, i) => (
					<Table.Cell
						key={i}
						onClick={
							c.onClick ? () => c.onClick(resource) : selectable && handleClick
						}>
						{cloneElement(c.component, {
							resource,
						})}
					</Table.Cell>
				))}
			</Fragment>
		);
	}

	return (
		<Fragment>
			<Table.ExpandableRow
				onClick={handleTraverse}
				isExpanded={isExpanded}
				canExpand={childResources.length > 0}
				level={level}>
				{selectable ? (
					<Table.Cell onClick={handleClick}>
						<Row spacing={styles.spacing._2} autoColumns="auto" align="center">
							<div>
								<Input.Control
									type={
										selectable === SelectableOptions.single
											? 'radio'
											: 'checkbox'
									}
									standalone
									checked={selected.includes(resource.id)}
								/>
							</div>
							<span>{resource.title}</span>
						</Row>
					</Table.Cell>
				) : (
					<Table.Cell>{resource.title}</Table.Cell>
				)}
				<Table.Cell onClick={selectable && handleClick}>
					{resource.location && resource.location.title}
				</Table.Cell>
				<Table.Cell onClick={selectable && handleClick}>
					{parentResource ? parentResource.title : '-'}
				</Table.Cell>
				{columnComponents}
				<ContextMenuCell
					resources={resources}
					refresh={refresh}
					resource={resource}
					refreshCategories={refreshCategories}
				/>
			</Table.ExpandableRow>
			{isExpanded &&
				resource.children?.length > 0 &&
				resource.children.map((r) => (
					<ResourceRow
						key={r.id}
						resource={r}
						level={level + 1}
						resources={resources}
						refresh={refresh}
						selectable={selectable}
						select={select}
						selected={selected}
						extraColumns={extraColumns}
						refreshCategories={refreshCategories}
					/>
				))}
		</Fragment>
	);
};

interface CategoryRowProps {
	children: JSX.Element;

	refresh: () => Promise<void>;
	refreshCategories: () => Promise<void>;

	category: models.resourceCategory.ResourceCategory;
	resources: models.resource.Resource[];
	allResources: models.resource.Resource[];

	extraColumns: Table.ExtraColumn[];
}

const CategoryRow = ({
	category,
	resources,
	allResources,
	refresh,
	refreshCategories,
	extraColumns,
	children,
}: CategoryRowProps) => {
	const [isExpanded, setIsExpanded] = useState(true);

	const resourcesInCategory: models.resource.Resource[] = [];

	const findChildren = (resourceId: number) => {
		allResources
			.filter((r) => r.parentResourceId === resourceId)
			.forEach((r) => {
				resourcesInCategory.push(r);
				findChildren(r.id);
			});
	};

	resources.forEach((r) => {
		if (r.category?.id === category.id && !!r.category?.id) {
			resourcesInCategory.push(r);
			findChildren(r.id);
		} else if (!r.category?.id && !r.parentResourceId) {
			resourcesInCategory.push(r);
			findChildren(r.id);
		}
	});

	const handleTraverse = () => {
		setIsExpanded(!isExpanded);
	};

	const extraColumCells = [];

	for (let i = 0; i < extraColumns.length; i++) {
		extraColumCells.push(<Table.Cell></Table.Cell>);
	}

	return (
		<Fragment>
			<Table.ExpandableRow
				onClick={handleTraverse}
				isExpanded={isExpanded}
				canExpand={resources.length > 0}
				level={0}>
				<Table.Cell>
					<Row spacing={styles.spacing._3} autoColumns="auto" align="center">
						{category.icon && (
							<Icon name={category.icon} size={1.6} actualSize />
						)}
						{category.title}
						<Label color="gray">{resourcesInCategory.length}</Label>
					</Row>
				</Table.Cell>
				<Table.Cell></Table.Cell>
				<Table.Cell></Table.Cell>
				{extraColumCells.map((c, i) => (
					<Fragment key={i}>{c}</Fragment>
				))}
				<CategoryContextMenu
					resources={allResources}
					category={category}
					refresh={refresh}
					refreshCategories={refreshCategories}
				/>
			</Table.ExpandableRow>
			{isExpanded && children}
		</Fragment>
	);
};

interface ResourcesTableProps {
	// Search query
	search?: string;
	// Ids of selected resources
	selected?: number[];
	// Check if table should include a option to select
	selectable?: SelectableOptions;
	// Loading data
	isLoading: boolean;

	// Func that returns resource id on selected item
	select?: (resourceId: number) => void;
	// Function to refresh the fetched data
	refresh?: () => Promise<void>;

	// Resources to list
	resources: models.resource.Resource[];
	// Categories to list
	categories: models.resourceCategory.ResourceCategory[];

	// Refresh function for categories
	refreshCategories: () => Promise<void>;
	// Empty state open modal
	handleOpenModal?: () => void;

	extraColumns?: Table.ExtraColumn[];
}

const ResourcesTable = ({
	resources,
	categories,
	refresh,
	selectable,
	select,
	selected,
	refreshCategories,
	handleOpenModal = null,
	search = '',
	isLoading = true,
	extraColumns = [],
}: ResourcesTableProps) => {
	const resourceTree = models.resource.buildResourceTree(resources, categories);

	const additionalColumns: Table.HeaderColumn[] = extraColumns.map(
		(c, index) => ({
			key: index,
			content: c.content,
			width: c.width,
			onClick: c.onClick,
			hide: c.hide,
			align: c.align,
		})
	);

	const columns: Table.HeaderColumn[] = [
		{
			content: t(`Name`),
		},
		{
			content: t(`Location`),
		},
		{
			content: t(`Parent`),
			hide: !!!search,
		},
		...additionalColumns,
		{
			content: '',
			width: 'max-content',
		},
	];

	if (!!!search) {
		columns.push({
			content: '',
			width: 'max-content',
		});
	}

	const listedCategories = categories;

	if (
		resourceTree.some((r) => !r.resourceCategoryId) &&
		!listedCategories.map((c) => c.id).includes(0)
	) {
		listedCategories.push({
			groupId: 0,
			title: t('No category'),
			id: 0,
		} as models.resourceCategory.ResourceCategory);
	}

	return (
		<Table.Table
			columns={columns}
			isLoading={isLoading}
			emptyState={{
				title: t('No resources'),
				content: t('Get started by creating a new resource'),
				button: handleOpenModal ? (
					<Button secondary icon="add" onClick={handleOpenModal}>
						{t('New resource')}
					</Button>
				) : null,
				image: (
					<Icon
						name="resource"
						actualSize
						size={4}
						fill={styles.palette.gray[500]}
					/>
				),
			}}>
			{search
				? resources
						.filter((r) =>
							r.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
						)
						.map((r) => (
							<SearchRow
								key={r.id}
								resource={r}
								resources={resources}
								refresh={refresh}
								selectable={selectable}
								select={select}
								selected={selected}
								extraColumns={extraColumns}
								refreshCategories={refreshCategories}
							/>
						))
				: listedCategories
						.sort((a, b) => a.title.localeCompare(b.title))
						.map((c, key) => (
							<CategoryRow
								key={key}
								category={c}
								allResources={resources}
								resources={resources.filter(
									(r) =>
										(r.resourceCategoryId === c.id && !r.parentResourceId) ||
										(!r.resourceCategoryId && c.id === 0)
								)}
								refresh={refresh}
								extraColumns={extraColumns}
								refreshCategories={refreshCategories}>
								<Fragment>
									{resourceTree

										.filter(
											(r) =>
												r.resourceCategoryId === c.id ||
												(!r.resourceCategoryId && c.id === 0)
										)

										.map((r) => (
											<ResourceRow
												key={r.id}
												resource={r}
												resources={resources}
												refresh={refresh}
												selectable={selectable}
												select={select}
												selected={selected}
												extraColumns={extraColumns}
												level={1}
												refreshCategories={refreshCategories}
											/>
										))}
								</Fragment>
							</CategoryRow>
						))}
		</Table.Table>
	);
};

export default ResourcesTable;
