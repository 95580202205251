import { t } from '@transifex/native';

import * as eventSeriesServices from 'pkg/actions/services/event_series';
import * as flashActions from 'pkg/actions/flashes';

import { getTimeZoneByGmtString } from 'pkg/timezone';
import { pushState, replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

import { IEditSeriesFormState, IFormState } from 'routes/event/interfaces';

import {
	CreateEventSeriesPayload,
	UpdateEventSeriesPayload,
} from './services/event_series';

export const create = async (
	organizationId: number,
	data: IFormState,
	rRuleString: string,
	attachments: models.attachment.Attachment[] = []
): Promise<void> => {
	const eventData: CreateEventSeriesPayload = {
		groupId: data.groupId,
		title: data.title,
		description: data.description,
		location: data.location,
		type: data.type,
		duration: data.endsAt - data.startsAt,
		rRuleString,
		timezone: getTimeZoneByGmtString(data.timezone).name,
		isPrivate: data.isPrivate,
		isPublic: data.isPublic,
		hideParticipants: data.hideParticipants,
		sendInvites: data.sendInvites,
		users: data.selectedUsers.map((u) => ({
			userId: u.id,
			role: data.organizer[u.id] ? 'organizer' : 'participant',
		})),
		flags: data.flags,
	};

	if (data.meetBeforeMinutes) {
		eventData.meetBeforeMinutes = Number.parseInt(data.meetBeforeMinutes, 10);
	}

	if (data.rsvpBefore) {
		eventData.rsvpInterval = data.rsvpInterval;
		eventData.rsvpIntervalCount = data.rsvpIntervalCount;
	}

	const [request, response] = await models.create<
		CreateEventSeriesPayload,
		models.eventSeries.EventSeries
	>(endpoints.EventSeries.Create(), eventData);

	if (!request.ok) {
		flashActions.show({
			title: t('Something went wrong'),
			message: t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			),
		});

		return;
	}

	attachments.forEach((a) =>
		eventSeriesServices.addAttachment(response.id, a.id)
	);

	pushState(
		routes.EventSeries.View(organizationId, response.id) + '?firstLoad=true'
	);
};

export const remove = async (
	id: number,
	delete_all: boolean = false,
	organizationId: number
) => {
	const request = await sdk.destroy(
		endpoints.EventSeries.Delete(id),
		{},
		{ delete_all }
	);
	const response = await request.json();

	if (response.message !== 'ok') {
		return;
	}

	flashActions.show({
		title: !delete_all
			? t(`Successfully removed series.`)
			: t(`Successfully removed series and connected events.`),
	});

	replaceState(routes.Organization.Home(organizationId));
};

export const update = async (
	organizationId: number,
	eventSeries: models.eventSeries.EventSeries,
	data: IEditSeriesFormState,
	rRuleString: string,
	eventSeriesUsers: models.eventSeriesUser.EventSeriesUser[],
	attachments: models.attachment.Attachment[] = []
) => {
	const eventData: UpdateEventSeriesPayload = {
		timezone: data.timezone ? getTimeZoneByGmtString(data.timezone).name : '',
		title: data.title,
		location: data.location,
		description: data.description,
		duration: data.endsAt - data.startsAt,
		rRuleString,
		isPrivate: data.isPrivate,
		isPublic: data.isPublic,
		hideParticipants: data.hideParticipants,
		meetBeforeMinutes: 0,
		sendInvites: data.sendInvites,
		users: data.selectedUsers.map((u) => ({
			userId: u.id,
			role: data.organizer[u.id] ? 'organizer' : 'participant',
		})),
		flags: data.flags,
	};

	if (data.meetBeforeMinutes) {
		eventData.meetBeforeMinutes = Number.parseInt(data.meetBeforeMinutes, 10);
	}

	if (data.rsvpBefore) {
		eventData.rsvpInterval = data.rsvpInterval;
		eventData.rsvpIntervalCount = data.rsvpIntervalCount;
	} else {
		eventData.rsvpInterval = '';
		eventData.rsvpIntervalCount = 0;
	}

	const request = await eventSeriesServices.update(eventSeries.id, eventData);
	const response = await request.json();

	if (!response) {
		return;
	}

	await attachments.forEach((a) =>
		eventSeriesServices.addAttachment(eventSeries.id, a.id)
	);

	pushState(routes.EventSeries.View(organizationId, eventSeries.id));
};

export async function deleteSeriesAttachment(
	eventSeriesId: number,
	attachmentId: number
) {
	const request = await sdk.destroy(
		endpoints.EventSeries.RemoveAttachment(eventSeriesId, attachmentId)
	);

	if (request.ok) {
		return true;
	}

	flashActions.show({
		title: t('Someting went wrong'),
	});

	return false;
}
