import { Fragment, JSX } from 'react';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import useToggleState from 'pkg/hooks/useToggleState';
import store from 'pkg/store/createStore';
import { useCurrentOrganization } from 'pkg/identity';

import { FormStateData } from 'routes/payments/hooks/useContactForm';
import { generatePayload } from 'routes/payments/contacts/Create';
import { UserContextMenuItem } from 'routes/organization/user-profile/user-actions';

import SelectContact from 'containers/payment_platform/SelectContact';

import Avatar from 'components/avatar';

import { FormPayload } from 'components/form/Form';
import CreateContactModal from 'components/payment_platform/contacts/CreateModal';
import CollapsiblePanel from 'components/sidebar/collapsible-panel';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import Button from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface UserProfileOverviewProps {
	user: models.user.User;
	onUpdate: () => Promise<void>;
}

export default function BillingContact({
	user,
	onUpdate,
}: UserProfileOverviewProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const [selectBillingOpen, showSelectBilling, hideSelectBilling] =
		useToggleState();

	const [createContactOpen, showCreateContact, hideCreateContact] =
		useToggleState();

	const handleAssignBillingContact = (billingUserId: number) => {
		actions.users.update(user.id, { billingUserId })(store.dispatch);

		onUpdate();
	};

	const handleCreateBillingContact = async (
		data: FormPayload,
		contactData: FormStateData
	): Promise<void> => {
		const [, result] = await actions.users.createUser(
			generatePayload(data, contactData, org.id)
		)(store.dispatch);

		await actions.users.update(user.id, { billingUserId: result.id })(
			store.dispatch
		);

		onUpdate();
	};

	const handleDeleteBillingContact = async (): Promise<void> => {
		await actions.users.update(user.id, { billingUserId: null })(
			store.dispatch
		);

		onUpdate();
	};

	const canUpdate: boolean = models.hasAllowedAction(
		user,
		models.Action.UserUpdate
	);

	const contextItems = [];

	const userName = models.user.fullName(user);
	const billingUserName = models.user.fullName(user.billingUser);

	if (canUpdate) {
		contextItems.push(
			<UserContextMenuItem
				key="select_billing_contact"
				label={t('Select billing contact')}
				symbol="person_add"
				condition={true}
			/>,
			<Context.Divider key="billing_contact_divider" />,
			<UserContextMenuItem
				key="remove_billing_contact"
				label={t('Remove billing contact')}
				symbol="person_off"
				condition={true}
				caution
				dialog={{
					wide: true,
					destructive: true,
					symbol: 'person_off',
					symbolScale: 1.5,
					title: t('Remove {billingUserName} as billing contact?', {
						billingUserName,
					}),
					message: t(
						'{billingUserName} will longer recieve invoices/subscriptions assigned to {userName}.',
						{ billingUserName, userName }
					),
					onConfirm: () => handleDeleteBillingContact(),
				}}
			/>
		);
	}

	return (
		<Fragment>
			<CollapsiblePanel heading={t('Billing contact')}>
				{user?.billingUser ? (
					<Row columns="30px 1fr auto">
						<Avatar user={user.billingUser} />
						<Column spacing={styles.spacing._2}>
							<strong>{models.user.fullName(user.billingUser)}</strong>
							<span className={css.billingEmail}>{user.billingUser.email}</span>
						</Column>
						{contextItems.length > 0 && (
							<Context.Menu toggleWith={<Button secondary icon="more_horiz" />}>
								{contextItems}
							</Context.Menu>
						)}
					</Row>
				) : (
					<Fragment>
						{canUpdate && (
							<Column>
								<Button secondary onClick={showSelectBilling} icon="person_add">
									{t('Select billing contact')}
								</Button>
								<p>
									{t(
										'All invoices/subscriptions assigned to {user} would automatically be sent to the billing contact',
										{
											user: models.user.fullName(user),
										}
									)}
								</p>
							</Column>
						)}
					</Fragment>
				)}
			</CollapsiblePanel>
			{selectBillingOpen && (
				<SelectContact
					groupId={org.id}
					canCreateNew
					selectedContactId={user?.billingUserId}
					onConfirm={handleAssignBillingContact}
					onCreateNew={() => {
						showCreateContact();
						hideSelectBilling();
					}}
					onClose={hideSelectBilling}
				/>
			)}
			{createContactOpen && (
				<CreateContactModal
					groupId={org.id}
					onConfirm={handleCreateBillingContact}
					onClose={hideCreateContact}
				/>
			)}
		</Fragment>
	);
}
