import { t } from '@transifex/native';
import { JSX, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import AccountDeleteConfirm from 'routes/account/settings/privacy/AccountDeleteConfirm';
import AccountDeleteForm from 'routes/account/settings/privacy/AccountDeleteForm';

import StepModal, { Step } from 'components/step-modal';

import Column from 'components/layout/column';

interface AccountDeleteModalProps {
	account: models.account.Account;
	onClose: () => void;
	onDeletion?: () => void;
}

export default function AccountDeleteModal({
	account,
	onClose,
	onDeletion,
}: AccountDeleteModalProps): JSX.Element {
	const [deletedAccount, setDeletedAccount] =
		useState<models.account.Account>(null);

	return (
		<StepModal onClose={onClose}>
			<Step title={t(`Delete account`)} hideNext hidePrev>
				<Column spacing={styles.spacing._6}>
					{deletedAccount ? (
						<AccountDeleteConfirm account={deletedAccount} />
					) : (
						<AccountDeleteForm
							account={account}
							onClose={onClose}
							setAccountDeleted={setDeletedAccount}
							onDeletion={onDeletion}
						/>
					)}
				</Column>
			</Step>
		</StepModal>
	);
}
