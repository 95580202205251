import { JSX, ChangeEvent, useRef, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import Link from 'pkg/router/Link';

import Icon from 'components/icon';
import SectionDescription from 'components/SectionDescription';
import SectionTitle from 'components/SectionTitle';
import StepModal, { Step } from 'components/step-modal';

import InfoBox from 'components/form/info-box';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';

interface ConnectionModalProps {
	onClose: () => void;
	onSave: () => Promise<void>;
}

interface ErrorMessage {
	message: string;
	link?: string;
	linkText?: string;
}

export default function ConnectionModal({
	onClose,
	onSave,
}: ConnectionModalProps): JSX.Element {
	const [canGoNext, setCanGoNext] = useState<boolean>(false);

	const emailRef = useRef<HTMLInputElement>();

	const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
		setCanGoNext(event.target.validity.valid);
	};

	const handleErrors = (requestStatus: number | null): ErrorMessage => {
		switch (requestStatus) {
			case 400:
				return {
					message: t(`You can't connect to yourself`),
				};
			case 404:
				return {
					message: t(
						'The account could not be found, please double-check the information and try again.'
					),
				};
			case 403:
				return {
					message: t(
						'The account you want to connect to is already a parent, connect to your child instead to continue.'
					),
					link: 'https://help.360player.com/article/208-connect-to-a-child-with-an-existing-parent',
					linkText: t('Read a full guide here'),
				};
			default:
				return {
					message: t(
						'There was an error processing your request. Please try again.'
					),
				};
		}
	};

	const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>(null);

	const save = async (): Promise<boolean> => {
		const email = emailRef.current.value;
		setErrorMessage(null);

		const [request] = await actions.accountRelations.createRequest(
			'legalGuardian',
			email
		);

		if (request.ok && onSave) {
			onSave();
			return true;
		}

		if (!request.ok) {
			const errorMessageObj = handleErrors(request.status);
			setErrorMessage(errorMessageObj);
		}

		return false;
	};

	return (
		<StepModal onClose={onClose}>
			<Step
				canGoNext={canGoNext}
				onNext={save}
				title={t('Connect to your child')}
				prevLabel={t('Cancel')}
				nextLabel={t('Send request')}>
				<Column spacing={styles.spacing._6}>
					<Column spacing={styles.spacing._6}>
						<SectionTitle medium centered>
							{t('Connect with an existing account')}
						</SectionTitle>
						<SectionDescription centered maxWidth="440px">
							{t(
								`To connect to your child's 360Player account, enter the email address that they registered their account with. Your child will receive a notification in the app where they can accept your request.`
							)}
						</SectionDescription>
					</Column>
					<Input.Group label={t(`Your child's email address`)}>
						<Input.Field
							ref={emailRef}
							type="email"
							onChange={handleEmailChange}
						/>
					</Input.Group>
					{errorMessage && (
						<InfoBox color="orange">
							{errorMessage.message}
							{errorMessage.link && (
								<Link href={errorMessage.link}>
									{errorMessage.linkText} <Icon name="new-window" size={1.3} />
								</Link>
							)}
						</InfoBox>
					)}
				</Column>
			</Step>
		</StepModal>
	);
}
