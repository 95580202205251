import { JSX } from 'react';

import { useCurrentRoute } from 'pkg/router/hooks';

import { useClubLibrary } from 'routes/training/library/hooks';
import Library from 'routes/training/library/Library';

export default function Account(): JSX.Element {
	const { groupId } = useCurrentRoute();
	const library = useClubLibrary(groupId);

	return <Library library={library} />;
}
