import { JSX, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import Video from 'pkg/models/video';

import * as actions from 'pkg/actions/index';

import { useCurrentAccount, useCurrentUser } from 'pkg/identity';

import VideoListItem from 'components/video/ListItem';

interface ListItemProps {
	video: Video;
	currentTab?: string;
	linkToTaggedUser?: boolean;
}

const ListItem = ({ video, linkToTaggedUser }: ListItemProps): JSX.Element => {
	const dispatch = useDispatch();

	const user = useCurrentUser();
	const account = useCurrentAccount();

	const handleDeleteVideo = (id: number) => {
		if (
			!confirm(
				t('Do you want to delete this?', { _context: 'confirm messages' })
			)
		) {
			return;
		}
		actions.videos.removeVideo(id)(dispatch);
	};

	return (
		<Fragment>
			<VideoListItem
				video={video}
				handleDeleteVideo={handleDeleteVideo}
				linkToTaggedUser={linkToTaggedUser}
				user={user}
				account={account}
			/>
		</Fragment>
	);
};

export default ListItem;
