import { t } from '@transifex/native';
import { JSX, memo } from 'react';

import { startOfDay } from 'pkg/date';
import * as routes from 'pkg/router/routes';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';

import SingleEvent from 'routes/dashboard/shared/single-event';

import AssetImage from 'components/AssetImage';
import { ScrollableWrapper } from 'components/scrollable-wrapper';
import SectionTitle from 'components/SectionTitle';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import Column from 'components/layout/column';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

interface UpcomingEventsProps {
	groupId: number;
}

const UpcomingEvents = memo(({ groupId }: UpcomingEventsProps): JSX.Element => {
	const org = useCurrentOrganization();
	const membership = useCurrentMembership();

	const collection = useCollection<models.event.Event>(
		endpoints.Events.Index(),
		{
			queryParams: new URLSearchParams({
				from: Math.round(Number(startOfDay(Date.now())) / 1000).toString(),
				group_ids: [membership.groupId].toString(),
			}),
		}
	);

	const events = collection.records;

	const isAdminOrStaff = models.membership.isAdminOrStaff(membership);

	const renderEvents = () => {
		if (collection.isLoading) {
			return (
				<ScrollableWrapper chevrons>
					<Skeleton
						placeholderType={PlaceholderType.DashboardEvents}
						numItems={3}
					/>
				</ScrollableWrapper>
			);
		}

		if (events.length === 0) {
			if (isAdminOrStaff) {
				const button = (
					<Button
						secondary
						label={t(`Create an event!`)}
						href={routes.Event.Create(org.id)}
						icon="add"
					/>
				);

				const image = <AssetImage src="img/missing-entities/calendar.svg" />;

				return (
					<MissingEntities
						noBorder
						dashboard
						title={t(`Get started with the calendar`)}
						helpUrl="https://help.360player.com/article/28-how-to-create-an-event"
						description={t(
							`Add events to your team calendar, and your upcoming ones will show up here`
						)}
						actions={button}
						image={image}
					/>
				);
			}

			const image = <AssetImage src="img/missing-entities/calendar.svg" />;

			return (
				<MissingEntities
					noBorder
					dashboard
					title={t(`No team events yet!`)}
					description={t(
						`Here you can stay up to date with matches, trainings, and more.`
					)}
					image={image}
				/>
			);
		}

		return (
			<ScrollableWrapper chevrons>
				{events.map((event: models.event.Event) => (
					<SingleEvent event={event} key={event.id} />
				))}
			</ScrollableWrapper>
		);
	};

	return (
		<Column>
			<SectionTitle icon="calendar_today">
				{t(`Team events`)}

				<Button
					noPadding
					inline
					icon="chevron_right"
					iconPosition="right"
					href={routes.Calendar.Index(
						org.id,
						groupId,
						sessionStorage.getItem('currentMode') || 'schedule'
					)}
					label={t(`Show calendar`)}
				/>
			</SectionTitle>

			{renderEvents()}
		</Column>
	);
});

export default UpcomingEvents;
