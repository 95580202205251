import { JSX } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as palette from 'pkg/config/palette';
import * as shadow from 'pkg/config/box-shadow';

import Link from 'pkg/router/Link';

import Thumbnail from 'components/video/Thumbnail';

const VideoThumbnail = styled(Thumbnail)`
	transition: box-shadow 150ms ease-in-out;
`;

const VideoWrapper = styled(Link)`
	display: block;
	flex: 0 0 65%;
	width: 65%;
	margin-right: var(--spacing-4);
	scroll-snap-align: center;
	min-width: 0;

	@media (hover: hover) {
		scroll-snap-align: none;

		&:hover {
			${VideoThumbnail} {
				box-shadow: ${shadow.cardHover};
			}
		}
	}

	@media ${breakpoints.large}, ${breakpoints.nolimit} {
		flex: 0 0 270px;
		width: 270px;
	}

	&:last-child {
		margin-right: 0;
	}
`;

const VideoTitle = styled.span`
	display: block;
	margin-top: 0.5rem;
	font-size: var(--font-size-sm);
	font-weight: var(--font-weight-semibold);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: ${palette.gray[800]};
`;

const VideoCaption = styled.div`
	display: block;
	margin-top: 0.1rem;
	font-size: 0.75rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: ${palette.gray[500]};
`;

type VideoItemProps = {
	href?: string;
	title: string;
	duration: number;
	sourceUrl: string;
	caption: string | JSX.Element;
	to?: string;
	params?: Record<string, unknown>;
};

const VideoItem: React.FC<React.PropsWithChildren<VideoItemProps>> = ({
	href,
	title,
	duration,
	sourceUrl,
	caption,
	to,
	params,
}) => (
	<VideoWrapper href={href} to={to} params={params}>
		<VideoThumbnail sourceUrl={sourceUrl} duration={duration} />
		<VideoTitle>{title}</VideoTitle>
		{caption && <VideoCaption>{caption}</VideoCaption>}
	</VideoWrapper>
);

export default VideoItem;
