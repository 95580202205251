import { JSX } from 'react';

import { List } from 'components/notifications/List';

interface ViewProps {
	currentTab?: string;
}

export default function Notifications({ currentTab }: ViewProps): JSX.Element {
	return <List standAlone newNotifications={currentTab !== 'history'} />;
}
