import { t } from '@transifex/native';
import { useRef } from 'react';

import * as models from 'pkg/api/models';
import { pushState } from 'pkg/router/state';
import { ScheduleCreatePayload } from 'pkg/api/models/schedule';
import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import TemplateForm from 'routes/scheduling/templates/modals/Form';

import * as StepModal from 'components/step-modal';

import Form, { FormPayload } from 'components/form/Form';

interface CreateTemplateModalProps {
	groupId: number;

	hideModal: () => void;
}

const CreateTemplateModal = ({
	groupId,
	hideModal,
}: CreateTemplateModalProps) => {
	const org = useCurrentOrganization();
	const formRef = useRef(null);

	const handleSubmit = async (data: FormPayload) => {
		const payload: ScheduleCreatePayload = {
			groupId,
			title: data.title as string,
			description: data.description as string,
			days: 7,
			startingDay: Number.parseInt(data.startingDay as string, 10),
		};

		const [request, schedule] = await models.schedule.create(payload);

		if (request.ok) {
			pushState(routes.Templates.Show(org.id, groupId, schedule.id));
		}
	};

	const onNext = () =>
		formRef.current.dispatchEvent(
			new Event('submit', {
				cancelable: true,
				bubbles: true,
			})
		);

	return (
		<StepModal.Base onClose={hideModal}>
			<StepModal.Step title={t(`Create template`)} onNext={onNext}>
				<Form formRef={formRef} onSubmit={handleSubmit}>
					<TemplateForm />
				</Form>
			</StepModal.Step>
		</StepModal.Base>
	);
};

export default CreateTemplateModal;
