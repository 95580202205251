import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import { Group } from 'pkg/api/models/onboarding_info';
import * as routes from 'pkg/router/routes';

import Button from 'routes/public/styles/Button';
import { useOnboardingState } from 'routes/public/hooks';
import TeamBadge from 'routes/public/styles/TeamBadge';
import Title from 'routes/public/styles/Title';
import LogoutButton from 'routes/public/styles/LogoutButton';
import * as publicStyles from 'routes/public/styles/styles.css';
import {
	fetchCurrentAccount,
	useOnboardingContext,
} from 'routes/public/onboarding';

import Column from 'components/layout/column';

export default function StaffFinished(): JSX.Element {
	const { goTo, resetStore } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const group = onboardingState.get<Group>('group');
	const returnToForms = onboardingState.get<string>('returnToForms');

	const groupName = group.isOrganization
		? group.groupName
		: `${group.organizationName} ${group.groupName}`;

	const save = async () => {
		const [, account] = await fetchCurrentAccount();

		if (account.id) {
			const hasReturnToForms = returnToForms;

			resetStore();

			if (hasReturnToForms) {
				window.history.pushState(
					{},
					'',
					routes.Registration.Form(
						group.organizationSlug,
						onboardingState.get<string>('formGUID')
					)
				);
				window.location.reload();
			} else {
				window.history.pushState({}, '', '/');
				window.location.reload();
			}
		} else {
			goTo('start');

			actions.flashes.show({
				title: t('Something went wrong'),
				message: t(
					"If you think this error isn't supposed to happen, please contact 360Player support."
				),
			});
		}
	};

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<TeamBadge group={group} />

				<Title
					title={t('Pending request')}
					description={t(
						'An admin needs to accept your request before you will get access as staff in {group_name}. You will get notified once you are approved. In the meantime you can proceed to the club lobby to view club posts and registrations.',
						{
							group_name: groupName,
						}
					)}
					thinDescription
					testid="onboarding.signup.pending_staff_title"
				/>

				<Button primary label={t('Continue to club lobby')} onClick={save} />
			</Column>

			<div className={publicStyles.footer}>
				<LogoutButton />
			</div>
		</Fragment>
	);
}
