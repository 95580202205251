import { JSX, useState } from 'react';

import * as styles from 'pkg/config/styles';

import RangeSlider from 'components/form/RangeSlider';

const FormRangeSlider = (): JSX.Element => {
	const [value, setValue] = useState<number>(0);

	const handleChange = (data: number): void => setValue(data);

	return (
		<RangeSlider
			value={value}
			thumbSize="small"
			thumbColor={styles.palette.blue[500]}
			onChange={handleChange}
		/>
	);
};

export default FormRangeSlider;
