import { useMediaQuery } from 'react-responsive';
import {
	Bar,
	BarChart,
	Cell,
	LabelList,
	Legend,
	Line,
	LineChart,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
	TooltipProps,
	XAxis,
	YAxis,
} from 'recharts';
import { JSX, CSSProperties, Fragment, ReactNode, useMemo } from 'react';
import { t } from '@transifex/native';

import { breakpoint, palette, spacing } from 'pkg/config/styles';

import { cssClasses, cssVarList } from 'pkg/css/utils';

import Icon from 'components/icon';

import Column from 'components/layout/column';

import * as css from './styles.css';

interface RechartsPayload {
	x?: number;
	y?: number;
	cx?: number;
	cy?: number;
	width?: number;
	height?: number;
	midAngle?: number;
	innerRadius?: number;
	outerRadius?: number;
	percent: number;
	index: number;
	payload?: any;
}

const ChartAnimationsActive = true;
const ChartAnimationsDuration = 500;
const ChartDefaultFractionDigit = 1;

function getFillColor(item: ChartItem): string {
	if (!item.fillColor) {
		return palette.blue[200];
	}

	return item.fillColor;
}

function getTextColor(item: ChartItem): string {
	if (!item.textColor) {
		return palette.blue[900];
	}

	return item.textColor;
}

interface ChartEmptyStateProps {
	label?: string;
}

function ChartEmptyState({ label }: ChartEmptyStateProps): JSX.Element {
	return (
		<Column justify="center" className={css.empty} spacing={spacing._6}>
			<Icon name="database" size={3} />
			<span>{label || t('No data available', { _context: 'chart' })}</span>
		</Column>
	);
}

interface ChartTooltipProps {
	item: ChartItem;
	items: ChartItem[];
	itemTotal: number;
	formatter: ChartItemFormatter;
}

function ChartTooltip({
	item,
	items,
	itemTotal,
	formatter,
}: ChartTooltipProps): JSX.Element {
	return <div className={css.tooltip}>{formatter(item, itemTotal, items)}</div>;
}

export interface ChartItemTheme {
	fillColor: string;
	textColor: string;
}

export interface ChartItem extends Partial<ChartItemTheme> {
	title: string;
	value?: number;
	metadata?: {
		[key: string]: any;
	};
}

export type ChartVariant = 'donut' | 'horizontal-bar' | 'linear';

type ChartItemFormatter<T = ReactNode> = (
	item: ChartItem,
	itemTotal?: number,
	items?: ChartItem[]
) => T;

type ChartSortOrder = 'asc' | 'desc';

type ChartTheme = ChartItemTheme[];

interface ChartProps {
	items: ChartItem[];
	itemKey?: keyof ChartItem;

	sortKey?: keyof ChartItem;
	sortOrder?: ChartSortOrder;

	hideLegend?: boolean;
	hideTooltip?: boolean;
	formatLabel?: ChartItemFormatter;
	formatLegend?: ChartItemFormatter;
	formatTooltip?: ChartItemFormatter;

	emptyStateLabel?: string;
	theme?: ChartTheme;

	minWidth?: [string, string];
	minHeight?: [string, string];
	maxWidth?: [string, string];
	maxHeight?: [string, string];
}

export const DefaultChartTheme: ChartTheme = [
	// Bright
	{ fillColor: palette.blue[300], textColor: palette.blue[800] },
	{ fillColor: palette.green[300], textColor: palette.green[800] },
	{ fillColor: palette.orange[300], textColor: palette.orange[800] },
	{ fillColor: palette.red[300], textColor: palette.red[800] },
	{ fillColor: palette.purple[300], textColor: palette.purple[800] },

	// Medium
	{ fillColor: palette.blue[500], textColor: palette.blue[100] },
	{ fillColor: palette.green[500], textColor: palette.green[100] },
	{ fillColor: palette.orange[500], textColor: palette.orange[100] },
	{ fillColor: palette.red[500], textColor: palette.red[100] },
	{ fillColor: palette.purple[500], textColor: palette.purple[100] },

	// Darkest
	{ fillColor: palette.blue[700], textColor: palette.blue[200] },
	{ fillColor: palette.green[700], textColor: palette.green[200] },
	{ fillColor: palette.orange[700], textColor: palette.orange[200] },
	{ fillColor: palette.red[700], textColor: palette.red[200] },
	{ fillColor: palette.purple[700], textColor: palette.purple[200] },
];

export function applyTheme(
	items: ChartItem[],
	theme: ChartItemTheme[]
): ChartItem[] {
	return items.map((item: ChartItem, index: number) => {
		// If item already has a color set, retain it
		if (item.fillColor || item.textColor) {
			return item;
		}

		// If theme has only one item, apply that theme to all bars
		if (theme.length === 1) {
			return {
				...item,
				fillColor: theme[0].fillColor,
				textColor: theme[0].textColor,
			};
		}

		const themeIndex = index % theme.length;

		// Otherwise, apply theme in order and fall back to default theme if needed
		return {
			...item,
			fillColor:
				theme[themeIndex]?.fillColor ||
				DefaultChartTheme[themeIndex]?.fillColor,
			textColor:
				theme[themeIndex]?.textColor ||
				DefaultChartTheme[themeIndex]?.textColor,
		};
	});
}

export function sortItems(
	items: ChartItem[],
	sortKey?: keyof ChartItem,
	sortOrder?: ChartSortOrder
): ChartItem[] {
	if (!sortKey) {
		return [...items];
	}

	if (!sortOrder) {
		sortOrder = 'asc';
	}

	const sortString = (a: string, b: string, direction: ChartSortOrder) => {
		if (direction === 'desc') {
			return b.localeCompare(a);
		}

		return a.localeCompare(b);
	};

	const sortNumber = (a: number, b: number, direction: ChartSortOrder) => {
		if (direction === 'desc') {
			return b - a;
		}

		return a - b;
	};

	return [...items].sort((a: ChartItem, b: ChartItem) => {
		const _a = a[sortKey],
			_b = b[sortKey];

		if (typeof _a === 'string') {
			return sortString(_a, _b as string, sortOrder);
		} else if (typeof _a === 'number') {
			return sortNumber(_a, _b as number, sortOrder);
		}
	});
}

interface ChartWrapperSizeConstraintsProps {
	variant: ChartVariant;
	minWidth: [string, string];
	minHeight: [string, string];
	maxWidth: [string, string];
	maxHeight: [string, string];
}

interface ChartWrapperSizeConstraintsHook {
	style: CSSProperties;
	className: string;
}

function useChartWrapperSizeConstraints({
	variant,
	minWidth,
	minHeight,
	maxWidth,
	maxHeight,
}: ChartWrapperSizeConstraintsProps): ChartWrapperSizeConstraintsHook {
	const [smmwMin, lgmwMin] = minWidth || [];
	const [smmhMin, lgmhMin] = minHeight || [];
	const [smmwMax, lgmwMax] = maxWidth || [];
	const [smmhMax, lgmhMax] = maxHeight || [];

	const vars = cssVarList({
		[`sm-${variant}-min-w`]: smmwMin,
		[`sm-${variant}-min-h`]: smmhMin,
		[`lg-${variant}-min-w`]: lgmwMin,
		[`lg-${variant}-min-h`]: lgmhMin,
		[`sm-${variant}-max-w`]: smmwMax,
		[`sm-${variant}-max-h`]: smmhMax,
		[`lg-${variant}-max-w`]: lgmwMax,
		[`lg-${variant}-max-h`]: lgmhMax,
	});

	return {
		style: { ...vars, height: '100%' },
		className: cssClasses(css.wrapper, css[variant]),
	};
}

export function Donut({
	items,
	itemKey = 'value',

	sortKey,
	sortOrder,

	hideLegend,
	hideTooltip,
	formatLabel,
	formatLegend,
	formatTooltip,

	emptyStateLabel,
	theme,

	minWidth,
	minHeight,
	maxWidth,
	maxHeight,
}: ChartProps): JSX.Element {
	const nodeId = useMemo(() => Math.random(), []);

	const isSmallScreen = useMediaQuery({
		maxWidth: breakpoint.toMedium,
	});

	const innerRadius = isSmallScreen ? '40%' : '50%';

	const wrapper = useChartWrapperSizeConstraints({
		variant: 'donut',
		minWidth,
		minHeight,
		maxWidth,
		maxHeight,
	});

	const sortedItems = sortItems(
		applyTheme(items, theme || DefaultChartTheme),
		sortKey,
		sortOrder
	);

	if (!formatLabel) {
		formatLabel = (item: ChartItem, itemTotal: number) => {
			return `${((item.value / itemTotal) * 100).toFixed(
				ChartDefaultFractionDigit
			)}%`;
		};
	}

	if (!formatLegend) {
		formatLegend = (item: ChartItem) => {
			return item.title;
		};
	}

	if (!formatTooltip) {
		formatTooltip = (item: ChartItem, itemTotal: number) => {
			const percent = ((item.value / itemTotal) * 100).toFixed(
				ChartDefaultFractionDigit
			);

			return (
				<Fragment>
					<strong>{item.title}</strong>
					<span>{`${item.value} / ${itemTotal} (${percent}%)`}</span>
				</Fragment>
			);
		};
	}

	const itemTotal = items.reduce(
		(acc: number, item: ChartItem) => acc + item.value,
		0
	);

	const renderLabel = ({
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		payload,
	}: RechartsPayload) => {
		const radian = Math.PI / 180;
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

		const x = cx + radius * Math.cos(-midAngle * radian);
		const y = cy + radius * Math.sin(-midAngle * radian);

		const item: ChartItem = payload?.payload;

		if (Math.round(item.value) === 0) {
			return null;
		}

		return (
			<text
				x={x}
				y={y}
				fontWeight="bold"
				textAnchor="middle"
				fill={getTextColor(item)}
				dominantBaseline="central">
				{formatLabel(item, itemTotal, items)}
			</text>
		);
	};

	const renderLegend = (props: any) => {
		const items: ChartItem[] = props.payload.map((item: any) => ({
			title: item.payload.title,
			value: item.payload.value,
			fillColor: item.payload.fillColor,
			textColor: item.payload.textColor,
		}));

		return (
			<div className={css.legend__wrapper}>
				{items.map((item: ChartItem) => (
					<span
						className={css.legend__item}
						style={cssVarList({
							bg: item.fillColor,
							fg: item.textColor,
						})}>
						{formatLegend(item, itemTotal, items)}
					</span>
				))}
			</div>
		);
	};

	const renderTooltip = ({ active, payload }: TooltipProps<number, string>) => {
		if (active) {
			const item = payload[0].payload;

			return (
				<ChartTooltip
					item={item}
					items={items}
					itemTotal={itemTotal}
					formatter={formatTooltip}
				/>
			);
		}

		return null;
	};

	if (items.length === 0 || itemTotal === 0) {
		return <ChartEmptyState label={emptyStateLabel} />;
	}

	return (
		<div style={wrapper.style}>
			<ResponsiveContainer className={wrapper.className}>
				<PieChart>
					<Pie
						key={nodeId}
						data={sortedItems}
						dataKey={itemKey}
						cx="50%"
						cy="50%"
						labelLine={false}
						strokeWidth={10}
						innerRadius={innerRadius}
						outerRadius="90%"
						label={renderLabel}
						isAnimationActive={ChartAnimationsActive}
						animationDuration={ChartAnimationsDuration}>
						{sortedItems.map((item: ChartItem, index: number) => (
							<Cell key={`${nodeId}:${index}`} fill={getFillColor(item)} />
						))}
					</Pie>
					{!hideLegend && <Legend iconType="circle" content={renderLegend} />}
					{!hideTooltip && (
						<Tooltip
							content={renderTooltip}
							wrapperStyle={{ outline: 'none' }}
							cursor={{ fill: 'transparent' }}
						/>
					)}
				</PieChart>
			</ResponsiveContainer>
		</div>
	);
}

interface HorizontalBarProps
	extends Omit<ChartProps, 'hideLegend' | 'formatLegend'> {
	formatLegend?: ChartItemFormatter<string>;
	legendCharacterThreshold?: number;
}

export function HorizontalBar({
	items,
	itemKey = 'value',

	sortKey,
	sortOrder,

	hideTooltip,
	formatLabel,
	formatLegend,
	formatTooltip,

	emptyStateLabel,
	theme,
	minWidth,
	minHeight,
	maxWidth,
	maxHeight,

	legendCharacterThreshold = 16,
}: HorizontalBarProps): JSX.Element {
	// Assumes a single character is roughly 10px
	const legendWrapperWidth: number = legendCharacterThreshold * 10;

	const sortedItems = sortItems(
		applyTheme(items, theme || DefaultChartTheme),
		sortKey,
		sortOrder
	);

	const wrapper = useChartWrapperSizeConstraints({
		variant: 'horizontal-bar',
		minWidth,
		minHeight,
		maxWidth,
		maxHeight,
	});

	if (!formatLabel) {
		formatLabel = (item: ChartItem, itemTotal: number) => {
			return `${((item.value / itemTotal) * 100).toFixed(
				ChartDefaultFractionDigit
			)}%`;
		};
	}

	if (!formatLegend) {
		formatLegend = (item: ChartItem) => {
			const maxLength: number = legendCharacterThreshold;

			if (item.title.length > maxLength) {
				return `${item.title.substring(0, maxLength)}...`;
			}

			return item.title;
		};
	}

	if (!formatTooltip) {
		formatTooltip = (item: ChartItem, itemTotal: number) => {
			const percent = ((item.value / itemTotal) * 100).toFixed(
				ChartDefaultFractionDigit
			);

			return (
				<Fragment>
					<strong>{item.title}</strong>
					<span>{`${item.value} / ${itemTotal} (${percent}%)`}</span>
				</Fragment>
			);
		};
	}

	const itemTotal = items.reduce(
		(acc: number, item: ChartItem) => acc + item.value,
		0
	);

	const renderLabel = ({ x, y, height, index }: RechartsPayload) => {
		const item: ChartItem = sortedItems[index];

		if (Math.round(item.value) === 0) {
			return null;
		}

		return (
			<text
				x={x + height * 0.3}
				y={y + height / 2}
				fill={getTextColor(item)}
				fontWeight="bold"
				textAnchor="start"
				dominantBaseline="central">
				{formatLabel(item, itemTotal, items)}
			</text>
		);
	};

	const renderTooltip = ({ active, payload }: TooltipProps<number, string>) => {
		if (active) {
			const item = payload[0].payload;

			return (
				<ChartTooltip
					item={item}
					items={items}
					itemTotal={itemTotal}
					formatter={formatTooltip}
				/>
			);
		}

		return null;
	};

	if (items.length === 0 || itemTotal === 0) {
		return <ChartEmptyState label={emptyStateLabel} />;
	}

	const renderLegend = (_: string, index: number) => {
		return formatLegend(sortedItems[index], itemTotal, items);
	};

	return (
		<div style={wrapper.style}>
			<ResponsiveContainer className={wrapper.className}>
				<BarChart
					key={Math.random()}
					layout="vertical"
					data={sortedItems}
					margin={{
						top: 0,
						right: 50,
						left: 10,
						bottom: 0,
					}}>
					<XAxis type="number" tick={false} axisLine={false} />
					<YAxis
						tickFormatter={renderLegend}
						type="category"
						dataKey="title"
						axisLine={false}
						tickLine={false}
						width={legendWrapperWidth}
					/>
					<Bar
						radius={[5, 5, 5, 5]}
						dataKey={itemKey}
						isAnimationActive={ChartAnimationsActive}
						animationDuration={ChartAnimationsDuration}>
						<LabelList dataKey="title" position="right" content={renderLabel} />
						{sortedItems.map((item: ChartItem, index: number) => (
							<Cell key={index} fill={getFillColor(item)} />
						))}
					</Bar>

					{!hideTooltip && (
						<Tooltip
							content={renderTooltip}
							wrapperStyle={{ outline: 'none' }}
							cursor={{ fill: 'transparent' }}
						/>
					)}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
}

export function VerticalBar({
	items,
	itemKey = 'value',

	sortKey,
	sortOrder,

	hideTooltip,
	hideLegend,
	formatLabel,
	formatTooltip,

	emptyStateLabel,
	theme,
	minWidth,
	minHeight,
	maxWidth,
	maxHeight,
}: ChartProps) {
	const sortedItems = sortItems(
		applyTheme(items, theme || DefaultChartTheme),
		sortKey,
		sortOrder
	);

	const wrapper = useChartWrapperSizeConstraints({
		variant: 'horizontal-bar',
		minWidth,
		minHeight,
		maxWidth,
		maxHeight,
	});

	if (!formatLabel) {
		formatLabel = (item: ChartItem, itemTotal: number) => {
			return `${item.value} (${((item.value / itemTotal) * 100).toFixed(
				ChartDefaultFractionDigit
			)}%)`;
		};
	}

	if (!formatTooltip) {
		formatTooltip = (item: ChartItem, itemTotal: number) => {
			const percent = ((item.value / itemTotal) * 100).toFixed(
				ChartDefaultFractionDigit
			);

			return (
				<Fragment>
					<strong>{item.title}</strong>
					<span>{`${item.value} / ${itemTotal} (${percent}%)`}</span>
				</Fragment>
			);
		};
	}

	const itemTotal = items.reduce(
		(acc: number, item: ChartItem) => acc + item.value,
		0
	);

	const renderLabel = ({ x, y, width, index }: RechartsPayload) => {
		const item: ChartItem = sortedItems[index];
		const textHeight = 18;

		if (Math.round(item.value) === 0) {
			return null;
		}

		return (
			<text
				x={x + width / 2}
				y={y + textHeight}
				fill="#fff"
				textAnchor="middle"
				dominantBaseline="middle">
				{formatLabel(item, itemTotal, items)}
			</text>
		);
	};

	const renderTooltip = ({ active, payload }: TooltipProps<number, string>) => {
		if (active) {
			const item = payload[0].payload;

			return (
				<ChartTooltip
					item={item}
					items={items}
					itemTotal={itemTotal}
					formatter={formatTooltip}
				/>
			);
		}

		return null;
	};

	if (items.length === 0 || itemTotal === 0) {
		return <ChartEmptyState label={emptyStateLabel} />;
	}

	return (
		<div style={wrapper.style}>
			<ResponsiveContainer className={wrapper.className}>
				<BarChart
					key={Math.random()}
					data={sortedItems}
					margin={{
						top: 0,
						right: 50,
						left: 10,
						bottom: 0,
					}}>
					<XAxis dataKey="title" />
					<YAxis dataKey="value" />
					<Bar
						radius={[5, 5, 5, 5]}
						dataKey={itemKey}
						isAnimationActive={ChartAnimationsActive}
						animationDuration={ChartAnimationsDuration}>
						{!hideLegend && <LabelList dataKey="title" content={renderLabel} />}
						{sortedItems.map((item: ChartItem, index: number) => (
							<Cell key={index} fill={getFillColor(item)} />
						))}
					</Bar>

					{!hideTooltip && (
						<Tooltip
							content={renderTooltip}
							wrapperStyle={{ outline: 'none' }}
							cursor={{ fill: 'transparent' }}
						/>
					)}
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
}

interface LinearProps
	extends Omit<
		ChartProps,
		'hideLegend' | 'formatLegend' | 'formatLabel' | 'theme'
	> {
	fillColor?: string;
}

export function Linear({
	items,
	itemKey = 'value',

	sortKey,
	sortOrder,

	hideTooltip,
	formatTooltip,

	fillColor,

	emptyStateLabel,
	minWidth,
	minHeight,
	maxWidth,
	maxHeight,
}: LinearProps): JSX.Element {
	const sortedItems = sortItems(items, sortKey, sortOrder);

	const wrapper = useChartWrapperSizeConstraints({
		variant: 'linear',
		minWidth,
		minHeight,
		maxWidth,
		maxHeight,
	});

	if (!formatTooltip) {
		formatTooltip = (item: ChartItem, itemTotal: number) => {
			const percent = ((item.value / itemTotal) * 100).toFixed(
				ChartDefaultFractionDigit
			);

			return (
				<Fragment>
					<strong>{item.title}</strong>
					<span>{`${item.value} / ${itemTotal} (${percent}%)`}</span>
				</Fragment>
			);
		};
	}

	const itemTotal = items.reduce(
		(acc: number, item: ChartItem) => acc + item.value,
		0
	);

	const renderTooltip = ({ active, payload }: TooltipProps<number, string>) => {
		if (active) {
			const item = payload[0].payload;

			return (
				<ChartTooltip
					item={item}
					items={sortedItems}
					itemTotal={itemTotal}
					formatter={formatTooltip}
				/>
			);
		}

		return null;
	};

	if (items.length === 0 || itemTotal === 0) {
		return <ChartEmptyState label={emptyStateLabel} />;
	}

	return (
		<div style={wrapper.style}>
			<ResponsiveContainer className={wrapper.className}>
				<LineChart data={sortedItems}>
					<Line
						type="monotone"
						dataKey={itemKey}
						strokeWidth={2}
						stroke={fillColor || palette.blue[500]}
						isAnimationActive={ChartAnimationsActive}
						animationDuration={ChartAnimationsDuration}
					/>
					{!hideTooltip && (
						<Tooltip
							content={renderTooltip}
							wrapperStyle={{ outline: 'none' }}
							cursor={{ fill: 'transparent' }}
						/>
					)}
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
}

interface LineProps {
	value: string;
	fillColor: string;
	tooltipTranslation?: string;
}

interface MultiLineProps extends ChartProps {
	lineProps: LineProps[];
}

export function MultiLine({
	items,
	lineProps = [],

	sortKey,
	sortOrder,

	hideTooltip,

	emptyStateLabel,
	minWidth,
	minHeight,
	maxWidth,
	maxHeight,
}: MultiLineProps): JSX.Element {
	const sortedItems = sortItems(items, sortKey, sortOrder);

	const wrapper = useChartWrapperSizeConstraints({
		variant: 'linear',
		minWidth,
		minHeight,
		maxWidth,
		maxHeight,
	});

	const formatter = (value: any, key: string) => {
		const item = lineProps.find((i) => i.value === key);

		return [value, item.tooltipTranslation];
	};

	const labelFormatter = (label: number, payload: RechartsPayload[]) => {
		return payload[0]?.payload?.title;
	};

	if (items.length === 0) {
		return <ChartEmptyState label={emptyStateLabel} />;
	}

	return (
		<div style={wrapper.style}>
			<ResponsiveContainer className={wrapper.className}>
				<LineChart data={sortedItems}>
					{lineProps.map((line) => (
						<Line
							type="monotone"
							dataKey={line.value}
							strokeWidth={2}
							stroke={line.fillColor || palette.blue[500]}
							isAnimationActive={ChartAnimationsActive}
							animationDuration={ChartAnimationsDuration}
						/>
					))}
					{!hideTooltip && (
						<Tooltip
							formatter={formatter}
							labelFormatter={labelFormatter}
							wrapperStyle={{ outline: 'none' }}
							cursor={{ fill: 'transparent' }}
						/>
					)}
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
}
