import * as sdk from 'pkg/core/sdk';
import { tlog } from 'pkg/tlog';

type SdkAllowedMethods = 'delete' | 'patch' | 'post' | 'put';

type SdkAction<T, P> = (payload?: P) => Promise<T>;

interface SdkActionCallbacks<T> {
	onSuccess: (entity?: T) => void;
	onFailure: () => void;
}

/**
 *	Factory that abstracts API requests to be used inside actions with cleaner and consistent logic.
 *
 *	@example
 *		type RemoveUserLogCallback = (userLog: models.userLog.UserLog) => void;
 *
 *		 export async function remove(
 *			userLog: models.userLog.UserLog,
 *			onRemove: RemoveUserLogCallback
 *		 ): Promise<void> {
 *			withSdk('delete', endpoints.UserLogs.Delete(userLog.userId, userLog.id), {
 *				onSuccess: onRemove,
 *			})();
 *		}
 *
 *	...
 *
 *	await actions.userLogs.remove(userLog, () => console.log('deleted user log'));
 *
 *	@returns SdkAction<T, P>
 */
export function withSdk<T, P = Partial<T>>(
	method: SdkAllowedMethods,
	endpoint: string,
	callbacks?: Partial<SdkActionCallbacks<T>>,
	additionalHeaders: Record<string, string> = {}
): SdkAction<T, P> {
	return async (payload?: P): Promise<T> => {
		try {
			let callback: CallableFunction;

			if (method === 'delete') {
				callback = sdk.destroy;

				if (!!payload) {
					payload = {} as P;
				}
			} else {
				callback = sdk[method];
			}

			const request = await callback(endpoint, {}, payload, additionalHeaders);

			if (!request.ok) {
				tlog.error(`Request (${method}) to '${endpoint}' failed.`);

				if (callbacks?.onFailure) {
					callbacks.onFailure();
				}

				return null;
			}

			if (method === 'delete') {
				if (callbacks?.onSuccess) {
					callbacks.onSuccess();
				}

				return {} as T;
			}

			const response = await request.json();

			if (callbacks?.onSuccess) {
				callbacks.onSuccess(response);
			}

			return response;
		} catch (error: any) {
			tlog.error(error);
		}
	};
}
