import { t } from '@transifex/native';
import { JSX, Fragment, useState } from 'react';

import { PageWidths } from 'pkg/config/sizes';
import spacing from 'pkg/config/spacing';

import * as routes from 'pkg/router/routes';
import { useCollection } from 'pkg/api/use_collection';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentOrganization } from 'pkg/identity';
import * as arrays from 'pkg/arrays';
import { crash } from 'pkg/errors/errors';

import SectionTitle from 'components/SectionTitle';
import * as StepModal from 'components/step-modal';
import { LineupContainer, Sections } from 'components/football-field';
import Avatar from 'components/avatar';

import PositionSection from 'components/match/substitutions/PositionSection';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import StartingField from 'components/match/starting-field';
import {
	PageAction,
	usePageActions,
} from 'components/navigation/header/small_screen/page_actions/Context';
import Row from 'components/layout/row';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

function getPositionGroupName(
	positions: models.position.Position[],
	matchEvent: models.matchEvent.MatchEvent
): string {
	const position = positions.find(
		(position) => position.id === matchEvent.positionId
	);

	switch (position?.slug) {
		case 'gk':
			return t(`Goalkeepers`);
		case 'cb':
		case 'lb':
		case 'rb':
			return t(`Defenders`);
		case 'cdm':
		case 'cam':
		case 'cm':
		case 'lm':
		case 'rm':
			return t(`Midfielders`);
		case 'cf':
		case 'lw':
		case 'rw':
		case 'st':
			return t(`Forwards`);
	}

	return t(`Unknown`);
}

function getPositionName(
	positions: models.position.Position[],
	matchEvent: models.matchEvent.MatchEvent
): string {
	const position = positions.find(
		(position) => position.id === matchEvent.positionId
	);

	if (position) {
		return `(${position.name})`;
	}

	return '';
}

interface UnplacedUserProps {
	user: models.user.User;
	onUserSelect?: (user: models.user.User) => void;
}

function UnplacedUser({ user, onUserSelect }: UnplacedUserProps): JSX.Element {
	const handleClick = () => {
		if (onUserSelect) {
			onUserSelect(user);
		}
	};

	return (
		<Row columns="30px 1fr auto" key={user.id} align="center">
			<Avatar user={user} />
			<span>{models.user.fullName(user)}</span>
			<Button small secondary icon="add" onClick={handleClick} />
		</Row>
	);
}

interface PlacedUserProps {
	matchEvent: models.matchEvent.MatchEvent;
	positions: models.position.Position[];

	onPositionChange?: (matchEvent: models.matchEvent.MatchEvent) => void;
	onPositionRemove?: (matchEvent: models.matchEvent.MatchEvent) => void;
}

function PlacedUser({
	matchEvent,
	positions,

	onPositionChange,
	onPositionRemove,
}: PlacedUserProps): JSX.Element {
	const handlePositionChange = () => {
		onPositionChange(matchEvent);
	};

	const handlePositionRemove = () => {
		onPositionRemove(matchEvent);
	};

	if (!matchEvent.user) return null;

	return (
		<Row columns="30px 1fr auto" key={matchEvent.user.id} align="center">
			<Avatar user={matchEvent.user} />
			<span>
				{models.user.fullName(matchEvent.user)}{' '}
				{getPositionName(positions, matchEvent)}
			</span>
			<Context.Menu toggleWith={<Button small icon="more_horiz" />}>
				<Context.Item onClick={handlePositionChange}>
					<Context.ItemIcon name="sync_alt" />
					{t('Change position')}
				</Context.Item>
				<Context.Divider />
				<Context.Item onClick={handlePositionRemove}>
					<Context.ItemIcon name="move_item" />
					{t('Move to bench')}
				</Context.Item>
			</Context.Menu>
		</Row>
	);
}

interface LineupProps {
	event: models.event.Event;
	match: models.match.Match;
}

export default function Lineup({ event, match }: LineupProps): JSX.Element {
	const org = useCurrentOrganization();

	const { records: positions, isLoading: isLoadingPositions } =
		useCollection<models.position.Position>(endpoints.Positions.Index());

	const {
		records: matchEvents,
		isLoading: isLoadingMatchEvents,
		refresh,
	} = useCollection<models.matchEvent.MatchEvent>(
		endpoints.MatchEvent.Index(match.id)
	);

	const isLoading = isLoadingPositions || isLoadingMatchEvents;

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<models.user.User>(null);
	const [selectedMatchEvent, setSelectedMatchEvent] =
		useState<models.matchEvent.MatchEvent>(null);

	const pageActions: PageAction[] = [];

	const placedUserIds = matchEvents
		?.filter((me) => me.type === 'starting_user')
		.map((me) => me.userId);

	const placedUsers = arrays.groupBy<models.matchEvent.MatchEvent>(
		matchEvents,
		(me) => getPositionGroupName(positions, me)
	);

	const unplacedUsers = event.users?.filter(
		(eu) =>
			eu.role === models.eventUser.EventUserRole.Participant &&
			!placedUserIds.includes(eu.userId)
	);

	if (models.canEdit(match)) {
		pageActions.push({
			label: 'Edit',
			icon: 'edit',
			href: routes.Match.Edit(org.id, event.id, match.id, 'lineup'),
		});
	}

	const handleCloseModal = () => {
		setIsOpen(false);
		setSelectedUser(null);
		setSelectedMatchEvent(null);
	};

	const handleUserSelect = (user: models.user.User) => {
		setSelectedUser(user);
		setIsOpen(true);
	};

	const handlePositionSelect = async (position: models.position.Position) => {
		if (selectedMatchEvent) {
			const [success] = await actions.matchEvents.update(
				selectedMatchEvent.id,
				{
					positionId: position.id,
				}
			);

			if (success) {
				await refresh();
			} else {
				actions.flashes.show({
					title: crash().title,
					message: t('Could not update user position.'),
				});
			}
		} else {
			const [success] = await actions.matchEvents.createStartingUserMatchEvent(
				match.id,
				selectedUser.id,
				position.id
			);

			if (success) {
				await refresh();
			} else {
				actions.flashes.show({
					title: crash().title,
					message: t('Could not add starting user position.'),
				});
			}
		}

		handleCloseModal();
	};

	const handlePositionChange = (matchEvent: models.matchEvent.MatchEvent) => {
		setSelectedMatchEvent(matchEvent);
		setIsOpen(true);

		return;
	};

	const handlePositionRemove = async (
		matchEvent: models.matchEvent.MatchEvent
	) => {
		const success = await actions.matchEvents.destroy(matchEvent.id);

		if (success) {
			await refresh();
		}

		handleCloseModal();
	};

	usePageActions(pageActions, 'edit');

	return (
		<LargeScreenContent.Inner maxWidth={PageWidths.WIDE}>
			{isLoading ? (
				<Spinner />
			) : (
				<Fragment>
					<Row collapseOnSmallScreens columns="2fr 1fr" spacing={spacing._7}>
						<StartingField
							matchEvents={matchEvents}
							positions={positions}
							className={css.startingField}
						/>
						<Column spacing={spacing._8}>
							{unplacedUsers && (
								<Column>
									<SectionTitle>{t('Bench')}</SectionTitle>
									{unplacedUsers.map(({ user }) => (
										<UnplacedUser
											key={`user-${user.id}`}
											user={user}
											onUserSelect={handleUserSelect}
										/>
									))}
								</Column>
							)}
							{Array.from(placedUsers.entries()).map(
								([title, users]: [string, models.matchEvent.MatchEvent[]]) => (
									<Column>
										<SectionTitle>{title}</SectionTitle>
										{users.map((me) => (
											<PlacedUser
												matchEvent={me}
												positions={positions}
												onPositionChange={handlePositionChange}
												onPositionRemove={handlePositionRemove}
											/>
										))}
									</Column>
								)
							)}
						</Column>
					</Row>
					{isOpen && (
						<StepModal.Base onClose={handleCloseModal}>
							<StepModal.Step
								title={
									!!selectedMatchEvent
										? t('Change position')
										: t('Pick position')
								}
								hideNext
								hidePrev
								skipBody>
								<LineupContainer className={css.container}>
									<Sections className={css.sections}>
										{positions.map((position) => (
											<PositionSection
												key={`position-${position.id}`}
												onClick={handlePositionSelect}
												position={position}
												selected={
													selectedMatchEvent?.positionId === position.id
												}
											/>
										))}
									</Sections>
								</LineupContainer>
							</StepModal.Step>
						</StepModal.Base>
					)}
				</Fragment>
			)}
		</LargeScreenContent.Inner>
	);
}
