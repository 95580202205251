import styled from 'styled-components';
import { JSX, ReactNode } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import MaterialSymbol from 'components/material-symbols';
import Label from 'components/label';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import Divider from 'components/form/Divider';

export const Title = styled.div<{ inline?: boolean; hasDescription?: boolean }>`
	display: flex;
	align-items: center;
	font-size: var(--font-size-xl);
	font-weight: var(--font-weight-semibold);
	color: var(--palette-gray-800);
	letter-spacing: -0.3px;
	margin: 0 var(--spacing-3)
		${({ inline, hasDescription }) =>
			inline && !hasDescription ? 'var(--spacing-4)' : 'var(--spacing-5)'}
		0;
	gap: var(--spacing-3);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-lg);
	}
`;

export const Description = styled.div<{ inline?: boolean }>`
	color: var(--palette-gray-600);
	margin-top: calc(var(--spacing-4) * -1);
	margin-bottom: ${({ inline }) =>
		inline ? 'var(--spacing-4)' : 'var(--spacing-6)'};
`;

interface SectionProps {
	title?: string;
	description?: string;
	children?: ReactNode;
	hideDivider?: boolean;
	icon?: MaterialSymbolVariant;
	isBeta?: boolean;
	testid?: string;
	inline?: boolean;
}

const Section = ({
	title,
	description,
	children,
	hideDivider,
	icon,
	isBeta,
	testid,
	inline,
}: SectionProps): JSX.Element => {
	return (
		<div>
			{!hideDivider && inline && <Divider />}
			{title && (
				<Title
					data-testid={testid}
					inline={inline}
					hasDescription={!!description}>
					{icon && <MaterialSymbol variant={icon} scale={0.95} />}
					{title}
					{isBeta && <Label color="gray">{t('Beta')}</Label>}
				</Title>
			)}
			{description && <Description inline={inline}>{description}</Description>}
			{children}
			{!hideDivider && !inline && <Divider />}
		</div>
	);
};

export default Section;
