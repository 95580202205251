import { JSX, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';

import * as numbers from 'pkg/numbers';
import useKeyBindings from 'pkg/hooks/useKeyBindings';
import { useDebounce } from 'pkg/timings';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';

import * as styles from 'components/form/inputs/autocomplete/styles';
import * as Input from 'components/form/inputs';
import { Spinner } from 'components/loaders/spinner';

interface AutoCompleteSuggestionGroup<T> {
	items?: T[];
	hideLabel?: boolean;

	onSelect: (item: T) => void;

	filterWith?: (keyword: string, item: T) => boolean;
	renderWith: (item: T, index?: number, isActive?: boolean) => ReactNode;
}

export interface AutoCompleteSuggestions {
	[groupName: string]: AutoCompleteSuggestionGroup<unknown>;
}

interface AutoCompleteProps {
	children?: ReactNode | ReactNode[];
	autoFocus?: boolean;

	busy?: boolean;
	testid?: string;
	inline?: boolean;
	rounded?: boolean;
	large?: boolean;
	small?: boolean;
	transparent?: boolean;
	placeholder?: string;

	renderInitialWith?: (inputValue: string) => ReactNode;
	suggestions?: AutoCompleteSuggestions;

	onFocus?: () => void;
	onBlur?: () => void;
	onTyping?: (value: string) => void;
	onDidType?: (value: string) => void;
	onSelectFallback?: (value: string) => void;
}

function countSuggestions(items: AutoCompleteSuggestions): number {
	if (!items) return 0;

	return Object.values(items)
		.map(
			(source: AutoCompleteSuggestionGroup<unknown>) =>
				source.items?.length || 0
		)
		.reduce((a: number, b: number) => a + b, 0);
}

function filterSuggestions(
	items: AutoCompleteSuggestions,
	keyword: string
): AutoCompleteSuggestions {
	Object.entries(items).forEach(([label, group]) => {
		const groupItems = items[label].items;

		if ('filterWith' in group && groupItems?.length > 0) {
			items[label].items = groupItems.filter((item) =>
				group.filterWith(keyword, item)
			);
		}
	});

	return items;
}

export default function AutoComplete({
	children,
	autoFocus,

	busy,
	testid,
	inline,
	rounded,
	large,
	small,
	transparent,
	placeholder,

	renderInitialWith,
	suggestions,

	onTyping,
	onDidType,
	onFocus,
	onBlur,
	onSelectFallback,
}: AutoCompleteProps): JSX.Element {
	const inputRef = useRef<HTMLInputElement>();

	const zIndex = useNewTopIndex();

	const [isBusy, setBusy] = useState<boolean>(false);
	const [index, setIndex] = useState<number>(0);
	const [items, setItems] = useState<AutoCompleteSuggestions>(suggestions);

	/* Keybindings event loop is always 1 after, store in refs to consume in onPrev, onNext */
	const visibleItems = useRef<AutoCompleteSuggestions>(suggestions);
	const currentItem = useRef<unknown>(null);
	const currentGroup = useRef<AutoCompleteSuggestionGroup<unknown>>();

	useEffect(() => {
		setBusy(busy);
	}, [busy]);

	const getItem = (index: number) => {
		const availableItems = Object.values(visibleItems.current)
			.map((group: AutoCompleteSuggestionGroup<unknown>) => group.items)
			.flat();

		return availableItems?.[index] || inputRef.current.value;
	};

	const getItemGroup = (index: number) => {
		const itemGroup = Object.values(visibleItems.current).find(
			(group: AutoCompleteSuggestionGroup<unknown>) => {
				const indices = Array.from(group.items.keys());

				if (indices?.includes(index)) {
					return group;
				}
			}
		);

		return itemGroup;
	};

	const selectItem = (item: unknown) => {
		if (currentGroup.current && item) {
			currentGroup.current.onSelect(item);
		} else if (onSelectFallback) {
			onSelectFallback(inputRef.current.value);
		}

		setIndex(0);
		setItems({});

		currentItem.current = null;
		handleBlur();
	};

	const handleTyping = async () => {
		if (!isBusy) {
			setBusy(true);
		}

		if (onTyping) {
			onTyping(inputRef.current?.value);
		}
	};

	const handleChange = async () => {
		setBusy(false);

		if (inputRef.current?.value) {
			const items = filterSuggestions(suggestions, inputRef.current.value);

			setItems(items);

			visibleItems.current = items;
		}

		if (onDidType) {
			onDidType(inputRef.current?.value);
		}
	};

	const handleFocus = () => {
		if (onFocus) {
			onFocus();
		}
	};

	// Debounce needed to allow click on items
	const handleBlur = useDebounce(() => {
		setItems({});

		if (inputRef.current) {
			inputRef.current.value = '';
		}

		if (onBlur) {
			onBlur();
		}
	}, 350);

	const handleSelect = () => {
		selectItem(currentItem.current);
	};

	const handleDeselect = () => {
		setItems({});
	};

	const handlePrev = () => {
		setIndex((current: number) => {
			const next = numbers.clamp(current - 1, 0, current + 1);

			currentItem.current = getItem(next - 1);
			currentGroup.current = getItemGroup(next - 1);

			return next;
		});
	};

	const handleNext = () => {
		setIndex((current) => {
			const numItems = countSuggestions(visibleItems.current);
			const next = numbers.clamp(current + 1, 0, numItems);

			currentItem.current = getItem(next - 1);
			currentGroup.current = getItemGroup(next - 1);

			return next;
		});
	};

	const handleItemClick = (event: MouseEvent<HTMLLIElement>) => {
		const index =
			Number.parseInt(event.currentTarget.dataset.itemIndex, 10) - 1;

		currentItem.current = getItem(index);
		currentGroup.current = getItemGroup(index);

		selectItem(currentItem.current);
	};

	useKeyBindings(
		{
			preventDefaultArrowUp: true,
			onArrowUp: () => handlePrev,

			preventDefaultArrowDown: true,
			onArrowDown: () => handleNext,

			onEnter: () => handleSelect,
			onEscape: () => handleDeselect,
		},
		inputRef.current
	);

	let itemIndex = 0;

	const shouldShowSuggestions =
		countSuggestions(items) > 0 && document.activeElement === inputRef.current;

	return (
		<styles.AutocompleteWrapper>
			<Input.Field
				ref={inputRef}
				data-testid={testid}
				inline={inline}
				rounded={rounded}
				large={large}
				small={small}
				autoFocus={autoFocus}
				transparent={transparent}
				placeholder={placeholder}
				changeDelay={350}
				onTyping={handleTyping}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
				autoComplete="off">
				{isBusy && (
					<Input.Suffix inline>
						<Spinner size="16px" />
					</Input.Suffix>
				)}
				{children}
			</Input.Field>
			{shouldShowSuggestions && (
				<styles.SuggestionsWrapper style={{ zIndex }}>
					<styles.Suggestions data-inline={inline}>
						{renderInitialWith && (
							<styles.Suggestion
								onClick={handleItemClick}
								data-item-index={itemIndex}
								data-active={itemIndex === index}>
								{renderInitialWith(inputRef.current.value)}
							</styles.Suggestion>
						)}

						{Object.entries(items).map(([label, group]) => {
							const items = group.items;

							if (!items || items.length === 0) return null;

							return (
								<styles.SuggestionGroup key={label}>
									{group.hideLabel !== true && (
										<styles.SuggestionGroupLabel>
											{label}
										</styles.SuggestionGroupLabel>
									)}
									<ul>
										{group.items.map((item, n: number) => {
											itemIndex++;

											return (
												<styles.Suggestion
													key={n}
													onClick={handleItemClick}
													data-item-index={itemIndex}
													data-active={itemIndex === index}>
													{suggestions[label].renderWith(
														item,
														itemIndex,
														itemIndex === index
													)}
												</styles.Suggestion>
											);
										})}
									</ul>
								</styles.SuggestionGroup>
							);
						})}
					</styles.Suggestions>
				</styles.SuggestionsWrapper>
			)}
		</styles.AutocompleteWrapper>
	);
}
