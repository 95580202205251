import { JSX, useState, Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { pushState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentRoute } from 'pkg/router/hooks';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';

import { useSmallScreen } from 'components/MediaQuery';

import AddToCollectionModal from 'components/training-library/modals/AddToCollection';
import Group, { GroupWrapperProperties } from 'components/library/Group';
import TrainingSessionGroupItem from 'components/library/group-items/TrainingSession';
import { GroupItemAction } from 'components/library/GroupItem';
import DuplicateSessionModal from 'components/training/session/duplicate-session-modal';

import Button from 'design/button';

import * as css from './styles.css';

export default function Sessions({
	title,
	items,
	isLoading,
	hasNext,
	fetchNext,
	removeRecord,
}: GroupWrapperProperties<models.session.Session>): JSX.Element {
	const route = useCurrentRoute();
	const org = useCurrentOrganization();
	const membership = useCurrentMembership();

	const [sessionId, setSessionId] = useState<number>(0);
	const [duplicateSessionId, setDuplicateSessionId] = useState<number>(0);

	const handleAddToCollection = (session: models.session.Session) => {
		setSessionId(session.id);
	};

	const handleRemoveFromCollection = (session: models.session.Session) => {
		if (route?.collectionId && session.id) {
			models.trainingCollection.removeSession(
				org.id,
				route.collectionId,
				session.id
			);

			removeRecord(session.id);
		}
	};

	const { record: collection } =
		useEndpoint<models.trainingCollection.TrainingCollection>(
			route?.collectionId
				? endpoints.TrainingCollections.Show(org.id, route?.collectionId)
				: ''
		);

	const closeModal = () => setSessionId(0);
	const isSmallScreen = useSmallScreen();

	const openDuplicateSessionModal = (id: number) => setDuplicateSessionId(id);
	const closeDuplicateSessionModal = () => setDuplicateSessionId(0);

	if (items.length === 0) {
		return null;
	}

	return (
		<Fragment>
			<Group title={title} icon="timer" context="training-library">
				{items
					.sort((a, b) => b.createdAt - a.createdAt)
					.map((item: models.session.Session, n: number) => {
						const actions: GroupItemAction<models.session.Session>[] = [
							{
								icon: 'file_copy',
								label: t('Duplicate'),
								onClick: () => openDuplicateSessionModal(item.id),
							},
						];

						if (models.canEdit(item)) {
							actions.push({
								icon: 'edit',
								label: t('Edit'),
								onClick: () => {
									pushState(routes.TrainingSession.Edit(org.id, item.id));
								},
							});
						}

						if (models.membership.valid(membership)) {
							actions.push({
								icon: 'create_new_folder',
								label: t('Add to collection'),
								onClick: handleAddToCollection,
							});
						}

						if (collection?.id) {
							actions.push({
								icon: 'cancel',
								label: t('Remove from collection'),
								onClick: handleRemoveFromCollection,
							});
						}

						if (models.canDelete(item)) {
							actions.push({
								icon: 'delete',
								label: t('Delete'),
								confirm: t(
									'Do you want to delete {name}? This action cannot be undone.',
									{
										name: item.title,
									}
								),
								onClick: async () => {
									const success = await models.destroy(item);

									if (success) {
										removeRecord(item.id);
									}
								},
							});
						}

						return (
							<Fragment>
								<TrainingSessionGroupItem
									key={`${item.id}:${n}`}
									item={item}
									actions={actions}
								/>
								{duplicateSessionId === item.id && (
									<DuplicateSessionModal
										session={item}
										onClose={closeDuplicateSessionModal}
									/>
								)}
							</Fragment>
						);
					})}
			</Group>
			{hasNext && fetchNext && (
				<div className={css.divider}>
					<Button
						block={isSmallScreen}
						label={t('Load more')}
						onClick={fetchNext}
						busy={isLoading}
					/>
				</div>
			)}
			{sessionId !== 0 && (
				<AddToCollectionModal sessionId={sessionId} onClose={closeModal} />
			)}
		</Fragment>
	);
}
