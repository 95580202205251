import { t } from '@transifex/native';
import { JSX, Fragment, useEffect, useState } from 'react';

import copyString from 'pkg/copyString';

import * as Card from 'components/Card';
import Icon from 'components/icon';
import Modal from 'components/modal';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import Button from 'design/button';

import * as css from './styles.css';

interface InviteLinkModalSettings {
	groupCode: string;
	onClose: () => void;
}
export default function InviteLinkModal({
	groupCode,
	onClose,
}: InviteLinkModalSettings): JSX.Element {
	const inviteLink = 'https://app.360Player.com/join/' + groupCode;

	const [didCopyInviteLink, setDidCopyInviteLink] = useState(false);
	const handleCopyInviteLink = () => setDidCopyInviteLink(true);

	useEffect(() => {
		if (didCopyInviteLink) {
			copyString(inviteLink);
		}
	}, [didCopyInviteLink]);

	const displayLink = (
		<Fragment>
			<Input.Group>
				<Input.Field defaultValue={inviteLink} readOnly />
			</Input.Group>
			<Button
				primary
				large
				block
				onClick={handleCopyInviteLink}
				icon="content_copy"
				label={t('Click to copy')}
			/>
			{didCopyInviteLink && (
				<span className={css.inviteLinkCopyTrue}>
					<Fragment>
						<Icon name="check" /> {t('Invite link copied!')}
					</Fragment>
				</span>
			)}
		</Fragment>
	);
	return (
		<Modal onClose={onClose}>
			<Card.Base>
				<Card.Header>
					<Card.Heading>{t('Share invite link')}</Card.Heading>
				</Card.Header>
				<Card.Divider />
				<Card.Body>
					<Column>
						<Column className={css.base}>
							<div className={css.large}> {t('Get the team onboard!')}</div>
							<Row className={css.normal}>
								{t(
									"It's easy to get everyone onboard, simply share the invite link with your team."
								)}
							</Row>
							{displayLink}
						</Column>
					</Column>
				</Card.Body>
			</Card.Base>
		</Modal>
	);
}
