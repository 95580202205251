import { JSX } from 'react';
import { t } from '@transifex/native';

import {
	useLocalizedCurrencyFormatter,
	useOrderReport,
} from 'routes/payments/overview/hooks';

import * as DataCard from 'design/data_card';

export default function EstimatedRevenueWidget(): JSX.Element {
	const { estimatedMonthlyRecurringRevenue } = useOrderReport();
	const fmt = useLocalizedCurrencyFormatter();

	return (
		<DataCard.Base
			titleIcon="sync"
			title={t('Est. recurring revenue')}
			heading={fmt(estimatedMonthlyRecurringRevenue)}
			headingSuffix={t('Monthly')}>
			<DataCard.Breakdown
				items={[
					{
						title: t('Quarterly'),
						value: fmt(estimatedMonthlyRecurringRevenue * 3),
					},
					{
						title: t('Annually'),
						value: fmt(estimatedMonthlyRecurringRevenue * 12),
					},
				]}
			/>
		</DataCard.Base>
	);
}
