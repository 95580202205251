import { JSX, useRef, useState } from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import { clamp } from 'pkg/numbers';
import { useDebounce } from 'pkg/timings';

import * as config from 'routes/video/analyze/timeline/config';

import Icon from 'components/icon';

import RangeSlider from 'components/form/RangeSlider';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 16px 1fr 16px;
	place-items: center;
	color: var(--palette-white);
	gap: var(--spacing-2);
	width: 150px;
	margin-right: var(--spacing-3);

	@media ${styles.breakpoint.large} {
		width: 120px;
	}

	@media all and (min-width: 1400px) {
		margin-right: var(--spacing-5);
		width: 180px;
	}

	&[data-disabled='true'] {
		pointer-events: none;
		opacity: 0.6;
	}
`;

interface PrecisionControlProps {
	onChange: (precision: config.Precision, zoomLevel: number) => void;
	initialValue?: number;
}

export default function PrecisionControl({
	onChange,
	initialValue = 0,
}: PrecisionControlProps): JSX.Element {
	const [value, setValue] = useState<number>(initialValue);
	const sliderRef = useRef<HTMLInputElement>();

	const blurSlider = useDebounce(() => {
		sliderRef.current.blur();
	}, 500);

	const getPrecision = (value: number): config.Precision => {
		const min = config.multiplier[config.Precision.FiveMinutes];
		const max = config.multiplier[config.Precision.FiveSeconds];

		const precision = clamp(
			Math.floor((max - min) * value),
			config.PrecisionMinIndex,
			config.PrecisionMaxIndex
		) as config.Precision;

		return precision;
	};

	const handleChange = (value: number) => {
		setValue(value);
		onChange(getPrecision(value), 1 + value);
		blurSlider();
	};

	const decrease = () => {
		const nextValue = clamp(value - 0.1, 0, 1);

		setValue(nextValue);
		onChange(getPrecision(nextValue), 1 + nextValue);
	};

	const increase = () => {
		const nextValue = clamp(value + 0.1, 0, 1);

		setValue(nextValue);
		onChange(getPrecision(nextValue), 1 + nextValue);
	};

	return (
		<Wrapper>
			<Icon name="magnify-minus" onClick={decrease} />
			<RangeSlider
				ref={sliderRef}
				min={0}
				max={1}
				step={0.01}
				value={value}
				onChange={handleChange}
				thumbSize="small"
				trackColor={styles.palette.gray[700]}
				progressColor={styles.palette.gray[500]}
			/>
			<Icon name="magnify-plus" onClick={increase} />
		</Wrapper>
	);
}
