import React, { Fragment, useEffect, useState, JSX } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as routes from 'pkg/router/routes';
import * as actions from 'pkg/actions';
import { replaceState } from 'pkg/router/state';
import { useCheckRelationToUser, useUser } from 'pkg/hooks/selectors';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import Single from 'containers/user/profile/development/position-match/Single';
import List from 'containers/user/profile/development/position-match/List';

import TabSwitcher from 'components/TabSwitcher';
import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import UpdateRatingModal from 'components/rating/RatingFormModal';
import ComparisonModal from 'components/user/rating/ComparisonModal';
import Row from 'components/layout/row';
import { useTemplateContext } from 'components/layout/page-templates/context';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const Top = styled.div`
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	margin-bottom: var(--spacing-6);

	${TabSwitcher} {
		grid-column-start: 2;
	}
`;

const Buttons = styled.div`
	justify-self: end;
`;

type PositionMatchProps = {
	ratingId: number;
	compareId?: number;
	groupId: number;
	userId: number;
	query?: { [key: string]: string };
};

const PositionMatch = ({
	ratingId = 0,
	compareId = 0,
	groupId = 0,
	userId = 0,
	query,
}: PositionMatchProps): JSX.Element => {
	const { setPageActions } = useTemplateContext();
	const dispatch = useDispatch();
	const org = useCurrentOrganization();
	const [modal, setModal] = useState('');

	const user = useUser(userId);
	const currentGroup = useCurrentGroup();

	const { isParentToUser } = useCheckRelationToUser(userId);
	const forAccount = isParentToUser ? user.accountId : null;

	useEffect(() => {
		handleCloseModal();
	}, [compareId]);

	const handleToggleTab = (tab: string): void => {
		const currentTab = tab === 'active' ? '' : 'history';

		replaceState(
			routes.User.Profile.PositionMatch(org.id, groupId, userId, ratingId),
			{ tab: currentTab }
		);
	};

	const handleOpenCreate = (): void => {
		setModal('create');
	};

	const handleOpenUpdate = (): void => {
		setModal('update');
	};

	const handleOpenComparison = (): void => {
		setModal('compare');
	};

	const handleCloseModal = (): void => {
		setModal('');
	};

	const handleUpdate = (): void => {
		dispatch(
			actions.ratings.fetchUsersRatings(userId, groupId, false, forAccount)
		);
		dispatch(
			actions.ratings.fetchUsersPrimaryRating(userId, groupId, forAccount)
		);

		setModal('');
	};

	const SetSinglePageActions = (): void => {
		setPageActions(
			<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
				<ContextMenu.Item onClick={handleOpenComparison}>
					{t('Compare')}
				</ContextMenu.Item>
				<ContextMenu.Item onClick={handleOpenUpdate}>
					{t('Update')}
				</ContextMenu.Item>
			</ContextMenu.Menu>
		);
	};

	const SetListPageActions = (): void => {
		setPageActions(
			<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
				<ContextMenu.Item onClick={handleOpenCreate}>
					{t('New assessment')}
				</ContextMenu.Item>
			</ContextMenu.Menu>
		);
	};

	return (
		<Fragment>
			<Top>
				<TabSwitcher
					dark
					active={query.tab ? query.tab : 'active'}
					onChange={handleToggleTab}
					tabs={{
						active: t('Active'),
						history: t('History'),
					}}
				/>
				<LargeScreen>
					<Buttons>
						{!query.tab ? (
							<Row spacing={styles.spacing._3}>
								<Button
									inline
									icon="compare"
									noPadding
									onClick={handleOpenComparison}>
									{t('Compare')}
								</Button>
								<Button inline icon="edit" noPadding onClick={handleOpenUpdate}>
									{t('Update')}
								</Button>
							</Row>
						) : (
							<Button inline icon="add" noPadding onClick={handleOpenCreate}>
								{t('New Assessment')}
							</Button>
						)}
					</Buttons>
				</LargeScreen>
			</Top>
			{!query.tab ? (
				<Single
					ratingId={ratingId}
					compareId={compareId}
					userId={userId}
					setDefinedPageActions={SetSinglePageActions}
					forAccount={forAccount}
				/>
			) : (
				<List
					activeRatingId={ratingId}
					userId={userId}
					showCreateModal={modal === 'create'}
					onShowModal={handleOpenCreate}
					onCloseModal={handleCloseModal}
					setDefinedPageActions={SetListPageActions}
					forAccount={forAccount}
				/>
			)}
			{modal === 'update' && (
				<UpdateRatingModal
					ratingId={ratingId}
					groupId={groupId}
					organizationId={org.id}
					userId={userId}
					closeModal={handleCloseModal}
					handleSaved={handleUpdate}
					forAccount={forAccount}
				/>
			)}

			{modal === 'compare' && (
				<ComparisonModal
					ratingId={ratingId}
					groupId={groupId}
					activeGroupId={currentGroup.id}
					userId={userId}
					onClose={handleCloseModal}
					forAccount={forAccount}
				/>
			)}
		</Fragment>
	);
};

export default PositionMatch;
