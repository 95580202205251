import { t } from '@transifex/native';
import {
	JSX,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import sp from 'pkg/config/spacing';
import * as styles from 'pkg/config/styles';

import { cssClasses } from 'pkg/css/utils';

import MaterialSymbol from 'components/material-symbols';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import * as css from './style.css';

const NoOp = (): any => null;

interface DialogState {
	current: Nullable<DialogOptions>;
	set: (options?: DialogOptions) => void;

	visible: boolean;
	show: () => void;
	hide: () => void;
}

export const DialogContext = createContext<DialogState>({
	current: null,
	set: NoOp,

	visible: false,
	show: NoOp,
	hide: NoOp,
});

type DialogAction = () => (void | boolean) | Promise<boolean | void>;

export interface DialogOptions {
	symbol?: MaterialSymbolVariant;
	symbolScale?: number;

	title?: string;
	message: ReactNode;

	wide?: boolean;
	spacing?: styles.spacing;
	prominent?: boolean;
	destructive?: boolean;
	hideOnBusy?: boolean;

	onShow?: () => void;
	onHide?: () => void;

	onConfirm: DialogAction;
	confirmLabel?: string;

	onCancel?: DialogAction;
	cancelLabel?: string;

	dismissLabel?: string;
}

export function Dialog(): JSX.Element {
	// @note Cannot put HTMLDialogElement here, close and showModal is missing from that unfortunately
	const dialogRef = useRef<any>(null);
	const ctx = useContext(DialogContext);

	const [isDisabled, setDisabled] = useState<boolean>(false);

	const {
		symbol = 'info',
		symbolScale = 1,
		title,
		message,

		onShow,
		onHide,

		wide,
		spacing = sp._2,
		prominent,
		destructive,
		hideOnBusy,

		onConfirm,
		confirmLabel = t('OK'),

		onCancel,
		cancelLabel = t('Cancel'),
	} = ctx.current || {};

	useEffect(() => {
		if (ctx.visible) {
			dialogRef.current.showModal();

			if (onShow) {
				onShow();
			}
		} else {
			if (onHide) {
				onHide();
			}
		}
	}, [ctx.visible]);

	useEffect(() => {
		if (hideOnBusy && dialogRef.current) {
			dialogRef.current.close();
		}
	}, [isDisabled]);

	const handleConfirm = async () => {
		setDisabled(true);

		const didConfirm = await onConfirm();

		dialogRef.current?.close();
		ctx.hide();

		setDisabled(false);

		return didConfirm;
	};

	const handleCancel = async () => {
		setDisabled(true);

		let didCancel: boolean | void = true;

		if (onCancel) {
			didCancel = await onCancel();
		}

		dialogRef.current?.close();
		ctx.hide();

		setDisabled(false);

		return didCancel;
	};

	let body = (
		<span className={cssClasses(css.title, css.standalone)}>{message}</span>
	);

	if (title) {
		body = (
			<Column spacing={spacing}>
				<span className={css.title}>{title}</span>
				<span className={css.message}>{message}</span>
			</Column>
		);
	}

	if (!ctx.visible) {
		return null;
	}

	const classNames: string = cssClasses(
		css.dialog,
		wide ? css.wide : undefined,
		prominent ? css.prominent : undefined
	);

	return (
		<dialog ref={dialogRef} className={classNames}>
			<div className={css.wrapper}>
				{!symbol ? (
					<div className={css.main}>{body}</div>
				) : (
					<Row columns="16px 1fr" align="center" className={css.main}>
						<span className={css.icon}>
							<MaterialSymbol variant={symbol} scale={symbolScale} />
						</span>
						{body}
					</Row>
				)}
				<Row autoColumns="1fr" className={css.actions}>
					<button
						className={css.action}
						onClick={handleCancel}
						data-testid="dialog.cancel"
						disabled={isDisabled}>
						{cancelLabel}
					</button>
					<button
						className={cssClasses(
							css.action,
							destructive ? css.destructive : css.primary
						)}
						onClick={handleConfirm}
						data-testid="dialog.confirm"
						disabled={isDisabled}>
						{confirmLabel}
					</button>
				</Row>
			</div>
		</dialog>
	);
}

interface DialogProviderProps {
	children: ReactNode | ReactNode[];
}

export function DialogProvider({ children }: DialogProviderProps): JSX.Element {
	const [options, setOptions] = useState<DialogOptions>(null);
	const [visible, setVisible] = useState<boolean>(false);

	const set = (options: DialogOptions) => setOptions(options);
	const show = () => setVisible(true);
	const hide = () => setVisible(false);

	return (
		<DialogContext.Provider
			value={{ current: options, set, visible, show, hide }}>
			<Dialog />
			{children}
		</DialogContext.Provider>
	);
}

export type DialogActivator = () => void;

export function useDialog<T = any>(
	options: DialogOptions,
	observeables: T[] = []
): DialogActivator {
	const ctx = useContext(DialogContext);

	// Watch for option changes to ensure callbacks are valid.
	useEffect(() => {
		ctx.set(options);
	}, observeables);

	return () => {
		// @NOTE Set options on show to ensure the correct options are set for views with several useDialog.
		ctx.set(options);

		ctx.show();
	};
}
