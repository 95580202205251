import styled from 'styled-components';
import { JSX, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import Link from 'pkg/router/Link';
import { UserFieldType } from 'pkg/api/models/user_fields';

import { FormattedContent } from 'components/formatted-content';
import Icon, { IconName } from 'components/icon';

import { DataRowProps } from 'components/user-address/DataList';
import { GalleryModal } from 'components/attachment/gallery';
import Column from 'components/layout/column';

const Row = styled.div`
	display: grid;
	align-items: start;
	gap: var(--spacing-4);
	grid-auto-flow: column;
	grid-auto-columns: 25% minmax(0, 1fr);
	padding: var(--spacing-4) 0;
	border-bottom: 1px solid var(--palette-gray-300);
	font-size: var(--font-size-sm);

	@media ${styles.breakpoint.small} {
		grid-auto-flow: row;
		grid-auto-columns: 1fr;
		gap: var(--spacing-2);
	}

	&:first-child {
		padding-top: var(--spacing-3);
	}

	&:last-child {
		padding-bottom: var(--spacing-3);
		border-bottom: 0;
	}
`;

const Label = styled.div`
	font-weight: var(--font-weight-semibold);
`;

export default function DataRow({ label, value }: DataRowProps): JSX.Element {
	return (
		<Row>
			<Label>{label}</Label>
			<div>{value || ''}</div>
		</Row>
	);
}

export function CustomFieldRow({
	field,
}: {
	field: models.user.Field;
}): JSX.Element {
	switch (field.type) {
		case UserFieldType.Email:
			return (
				<Row>
					<Label>{field.label}</Label>
					<p>
						{field.values.map((val, i) => (
							<Link key={i} href={`mailto:${val}`}>
								{val}
							</Link>
						))}
					</p>
				</Row>
			);
		case UserFieldType.Phone:
			return (
				<Row>
					<Label>{field.label}</Label>
					<p>
						{field.values.map((val, i) => (
							<Link key={i} href={`tel:${val}`}>
								{val}
							</Link>
						))}
					</p>
				</Row>
			);
		case UserFieldType.LongText:
			return (
				<Row>
					<Label>{field.label}</Label>
					<p>
						{field.values.map((val, i) => (
							<FormattedContent key={i} raw={val} />
						))}
					</p>
				</Row>
			);
		default:
			return (
				<Row>
					<Label>{field.label}</Label>
					<p>{field.values.join(', ')}</p>
				</Row>
			);
	}
}

export function AttachmentRow({
	label,
	attachments,
}: {
	label: string;
	attachments: models.attachment.Attachment[];
}): JSX.Element {
	const [galleryOpenOnId, setGalleryOpenOnId] = useState(0);

	const handleCloseModal = () => {
		setGalleryOpenOnId(0);
	};

	return (
		<Row>
			<Label>{label}</Label>
			<Column>
				{attachments.map((a) => {
					let icon: IconName = 'file-generic';
					switch (a.type) {
						case models.attachment.AttachmentType.Image:
							icon = 'file-image';
					}

					return (
						<Link
							key={a.id}
							onClick={() => {
								setGalleryOpenOnId(a.id);
							}}>
							<Icon name={icon} /> {a.title}
						</Link>
					);
				})}
			</Column>

			{galleryOpenOnId > 0 && (
				<GalleryModal
					activeId={galleryOpenOnId}
					attachments={attachments}
					onClose={handleCloseModal}
				/>
			)}
		</Row>
	);
}
