// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cr5hviK84EXAxcdwG_L6 {
	margin: 0 auto;
	padding: var(--spacing-8);
	max-width: var(--page-width-wide);
}
@media (max-width: 768px) {
	.Cr5hviK84EXAxcdwG_L6 {
		padding: var(--spacing-6);
}
	}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/overview/styles.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,yBAAyB;CACzB,iCAAiC;AAKlC;AAHC;CALD;EAME,yBAAyB;AAE3B;CADC","sourcesContent":[".wrapper {\n\tmargin: 0 auto;\n\tpadding: var(--spacing-8);\n\tmax-width: var(--page-width-wide);\n\n\t@media (--small-viewport) {\n\t\tpadding: var(--spacing-6);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `Cr5hviK84EXAxcdwG_L6`;
export default ___CSS_LOADER_EXPORT___;
