import { JSX, ReactNode } from 'react';

import * as styles from 'pkg/config/styles';

import SectionTitle from 'components/SectionTitle';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import Column from 'components/layout/column';

interface SectionProps {
	title: string | ReactNode;
	icon?: MaterialSymbolVariant;
	iconScale?: number;
	iconActualSize?: boolean;
	action?: ReactNode;

	children: ReactNode | ReactNode[];
}

export default function Section({
	title,
	icon = null,
	iconScale = 1.25,
	iconActualSize,
	action = null,
	children,
}: SectionProps): JSX.Element {
	return (
		<Column spacing={styles.spacing._3}>
			<SectionTitle
				icon={icon}
				iconScale={iconScale}
				iconActualSize={iconActualSize}>
				{title}
				{action}
			</SectionTitle>
			{children}
		</Column>
	);
}
