import { t } from '@transifex/native';
import { JSX, Fragment, useState, ChangeEvent } from 'react';

import spacing from 'pkg/config/spacing';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import { GroupLinkFolder } from 'pkg/api/models/group_link_folder';

import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';
import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import { IconContextMenu } from 'components/material-symbols/icon-context-menu';

interface CustomLinksInitialModalProps {
	groupId: number;
	hideModal: () => void;
	refresh: () => void;
	folders: GroupLinkFolder[];
}

interface FolderPayload {
	groupId: number;
	title: string;
	iconName: string;
	sortOrder: number;
}

export default function InitialModal({
	groupId,
	hideModal,
	refresh,
	folders,
}: CustomLinksInitialModalProps): JSX.Element {
	const [iconName, setIconName] = useState<MaterialSymbolVariant>();
	const [folderTitle, setFolderTitle] = useState<string>('');

	const handleIconNameChange = (iconName: string) => {
		setIconName(iconName as MaterialSymbolVariant);
	};

	const handlefolderTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setFolderTitle(event.currentTarget.value);
	};

	const onSave = async () => {
		const payload: FolderPayload = {
			groupId: groupId,
			title: folderTitle,
			iconName: iconName,
			sortOrder:
				folders.length > 1 ? folders[folders.length - 1].sortOrder + 1 : 1,
		};

		const request = await sdk.post(
			endpoints.GroupLinksFolder.CreateFolder(),
			{},
			payload
		);

		if (!request.ok) {
			return false;
		}
		refresh();
		hideModal();
		return true;
	};
	return (
		<Fragment>
			<StepModal.Base onClose={hideModal}>
				<StepModal.Step
					canGoNext={iconName?.length > 0 && folderTitle.length > 0}
					title={t('New folder')}
					onNext={onSave}
					nextLabel={t('Save')}>
					<Column spacing={spacing._5}>
						{folders.length === 0 && (
							<InfoBox>
								{t(
									'Welcome! This will be your first folder for organizing links, but keep in mind that no links or folders will be visible in the navigation until you define visibility rules.',
									{ _context: 'Initial custom links modal' }
								)}
							</InfoBox>
						)}

						<Input.Group
							label={t('Folder title')}
							hint={t('This title will appear in the navigation for the user')}
							required>
							<Input.Field
								placeholder={t('My link...')}
								onChange={handlefolderTitleChange}
							/>
						</Input.Group>
						<Input.Group
							label={t('Icon')}
							hint={t('This icon will appear in the navigation for the user')}
							required>
							<IconContextMenu
								iconName={iconName}
								onIconSelect={handleIconNameChange}
							/>
						</Input.Group>
					</Column>
				</StepModal.Step>
			</StepModal.Base>
		</Fragment>
	);
}
