import { JSX } from 'react';

import { useCuratedLibrary } from 'routes/training/library/hooks';
import Library from 'routes/training/library/Library';

export default function Curated(): JSX.Element {
	const library = useCuratedLibrary();

	return <Library library={library} />;
}
