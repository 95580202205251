import { JSX, ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';

import { useOnboardingContext } from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import Title from 'routes/public/styles/Title';
import Form from 'routes/public/styles/forms';
import Field from 'routes/public/styles/inputs/field';
import { useOnboardingState } from 'routes/public/hooks';

import Column from 'components/layout/column';

export default function GdprRequest(): JSX.Element {
	const dispatch = useDispatch();
	const { goTo } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [parentalConsentEmail, setParentalConsentEmail] = useState<string>();

	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		setParentalConsentEmail(event.target.value);

	const handleSave = async () => {
		setIsSaving(true);

		onboardingState.set({ parentalConsentEmail });

		const success = await dispatch(
			actions.accounts.requestParentalConsent(parentalConsentEmail)
		);

		if (!success) {
			actions.flashes.show({
				title: t('Something went wrong'),
				message: t(
					"If you think this error isn't supposed to happen, please contact 360Player support."
				),
			});
		} else {
			goTo('gdpr-request-pending');
		}
	};

	return (
		<Column spacing={styles.spacing._8}>
			<Title
				title={t(`You need your parent's approval to use 360Player`)}
				description={t(
					`Please enter your parent's email below to send them a request to use 360Player.`
				)}
			/>

			<Form onSubmit={handleSave}>
				<Column>
					<Field
						name="email"
						type="email"
						placeholder={t('Email')}
						value={parentalConsentEmail}
						onChange={handleChange}
					/>

					<Button
						primary
						label={t('Send GDPR request')}
						type="submit"
						busy={isSaving}
					/>
				</Column>
			</Form>
		</Column>
	);
}
