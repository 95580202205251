import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as sdk from 'pkg/core/sdk';
import * as endpoints from 'pkg/api/endpoints/auto';
import { EventComment } from 'pkg/api/models/event_comment';
import * as models from 'pkg/api/models';

export const create = async (
	eventId: number,
	comment: string
): Promise<[boolean, EventComment]> => {
	const [request, response] = await models.create<
		{ comment: string },
		models.eventComment.EventComment
	>(endpoints.Events.AddComment(eventId), { comment });

	if (!request.ok) {
		return [false, null];
	}

	return [true, response];
};

export const update = async (id: number, comment: string) => {
	const request = await sdk.patch(
		endpoints.Events.UpdateComment(id),
		{},
		{ comment }
	);

	if (request.ok) {
		const resp = await request.json();

		return [true, resp];
	}

	return [false, null];
};

export const remove = async (id: number) => {
	const req = await sdk.destroy(endpoints.Events.DeleteComment(id));

	if (req.ok) {
		flashActions.show({
			title: t('Successfully removed comment.'),
		});

		return true;
	}

	flashActions.show({
		title: t('Something went wrong.'),
	});

	return false;
};
