import styled, { DefaultTheme } from 'styled-components';
import { JSX, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import * as styles from 'pkg/config/styles';

import {
	ImageEditorFormat,
	imagePreviewURL,
	SaveableImage,
	useImageEditor,
} from 'pkg/hooks/image-editor';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { addCssVar } from 'pkg/cssvars';

import Icon from 'components/icon';
import { useStepModalContext } from 'components/step-modal';

import {
	VideoPayload,
	VideoPayloadSetter,
} from 'components/video-library/modals/Video';
import Row from 'components/layout/row';
import RangeSlider from 'components/form/RangeSlider';

const Wrapper = styled.div`
	display: grid;
	place-items: center;
	row-gap: var(--spacing-6);
`;

const thumbColor = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.gray[500] : styles.palette.white
);

const trackColor = addCssVar((theme: DefaultTheme) =>
	theme.darkMode ? styles.palette.gray[600] : styles.palette.gray[300]
);

function calculateAspectHeight(width: number, aspectRatio: string): number {
	const [x, y] = aspectRatio.split(':').map((n) => Number.parseInt(n, 10));

	return Math.ceil((width / x) * y);
}

interface ThumbnailProps {
	payload: VideoPayload;
	setPayload: VideoPayloadSetter;
}

export default function Thumbnail({
	payload,
	setPayload,
}: ThumbnailProps): JSX.Element {
	const { withNext } = useStepModalContext();
	const [scale, setScale] = useState<number>(1);

	const isSmallScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	const imageEditor = useImageEditor({
		width: isSmallScreen ? 320 : 480,
		height: calculateAspectHeight(isSmallScreen ? 320 : 480, '16:9'),
		saveFormat: ImageEditorFormat.Blob,
		onSave: async (image: SaveableImage) => {
			const previewUrl = await imagePreviewURL(image);

			setPayload({ previewUrl, attachment: image as File });
		},
	});

	useComponentDidMount(() => {
		imageEditor.load(payload.file);
	});

	withNext(async () => {
		await imageEditor.save();

		return true;
	});

	const handleScaleChange = (scale: number) => {
		setScale(scale);

		imageEditor.scale(scale);
	};

	return (
		<Wrapper>
			{imageEditor.canvas}

			<Row
				columns="auto 1fr auto"
				spacing={styles.spacing._6}
				align="center"
				style={{ width: '100%', maxWidth: '320px' }}>
				<Icon name="magnify-minus" />
				<RangeSlider
					min={0.1}
					max={5}
					step={0.01}
					value={scale}
					onChange={handleScaleChange}
					thumbColor={thumbColor}
					trackColor={trackColor}
				/>
				<Icon name="magnify-plus" />
			</Row>
		</Wrapper>
	);
}
