import { JSX } from 'react';
import { t } from '@transifex/native';

import * as InternalEmbed from 'styles/InternalEmbeds';

export default function ContentUnavailable(): JSX.Element {
	return (
		<InternalEmbed.Wrapper>
			<InternalEmbed.Header noclip>
				<span>{t(`Content unavailable`)}</span>
				<span>
					{t(
						`Content missing or you do not have the right viewing permissions`
					)}
				</span>
			</InternalEmbed.Header>
		</InternalEmbed.Wrapper>
	);
}
