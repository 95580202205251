import { useT } from '@transifex/react';
import { JSX, useState } from 'react';

import * as styles from 'pkg/config/styles';

import { cssClasses } from 'pkg/css/utils';
import * as ls from 'pkg/local_storage';

import MaterialSymbol from 'components/material-symbols';
import { LargeScreen } from 'components/MediaQuery';

import Row, { RowProps } from 'components/layout/row';

import * as css from './styles.css';

interface ReturnProps {
	component: JSX.Element;
	view: ListLayout;
}

interface Props {
	localStorageKey: ls.LocalStorageKeys;
}

export function useViewToggle({ localStorageKey }: Props): ReturnProps {
	const localStorageView =
		(ls.getItem(localStorageKey) as ListLayout) || ListLayout.Grid;
	const [view, setView] = useState<ListLayout>(localStorageView);

	const handleListClick = () => {
		setView(ListLayout.List);
		ls.setItem(localStorageKey, 'list');
	};

	const handleGridClick = () => {
		setView(ListLayout.Grid);
		ls.setItem(localStorageKey, 'grid');
	};

	const component = (
		<ViewToggle
			view={view}
			onGridClick={handleGridClick}
			onListClick={handleListClick}
		/>
	);

	return {
		component,
		view,
	};
}

export enum ListLayout {
	List = 'list',
	Grid = 'grid',
}

interface ViewToggleProps {
	view: ListLayout;
	onGridClick: () => void;
	onListClick: () => void;
}

/**
 * Component to toggle between a list/grid view
 */
export default function ViewToggle({
	view,
	onGridClick,
	onListClick,
}: ViewToggleProps) {
	const t = useT();

	const rowProps: Partial<RowProps> = {
		spacing: styles.spacing._2,
		align: 'center',
		autoColumns: 'max-content',
	};

	return (
		<div className={css.wrapper}>
			<Row
				{...rowProps}
				onClick={onGridClick}
				className={cssClasses(
					css.symbolWrapper,
					view === ListLayout.Grid ? css.active : ''
				)}>
				<MaterialSymbol actualSize scale={1.2} variant="grid_view" />
				<LargeScreen>
					<span className={css.text}>{t('Grid')}</span>
				</LargeScreen>
			</Row>
			<Row
				{...rowProps}
				onClick={onListClick}
				className={cssClasses(
					css.symbolWrapper,
					view === ListLayout.List ? css.active : ''
				)}>
				<MaterialSymbol actualSize scale={1.2} variant="list" />
				<LargeScreen>
					<span className={css.text}>{t('List')}</span>
				</LargeScreen>
			</Row>
		</div>
	);
}
