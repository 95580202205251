import { AnimatePresence } from 'framer-motion';
import { JSX, ChangeEvent, useState, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { StartLoginStateFlows } from 'pkg/actions/sso';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as actions from 'pkg/actions';
import * as language from 'pkg/i18n/language';
import { useCurrentRoute } from 'pkg/router/hooks';
import Link from 'pkg/router/Link';
import * as ls from 'pkg/local_storage';
import { LoginTypes, UserLogin } from 'pkg/local_storage/login-info';
import * as models from 'pkg/api/models';

import Field from 'routes/public/styles/inputs/field';
import { useConnectWithApple } from 'routes/public/connect';
import { useOnboardingContext } from 'routes/public/onboarding';
import Button from 'routes/public/styles/Button';
import FooterNote from 'routes/public/styles/FooterNote';
import ForgotPasswordLink from 'routes/public/styles/ForgotPasswordLink';

import Avatar from 'components/avatar';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import { Form } from 'components/public/Form';

import * as css from './styles.css';

interface LastLoginProps {
	userLogin: UserLogin;
}

export default function LastLogin({ userLogin }: LastLoginProps): JSX.Element {
	const t = useT();
	const { goTo, resetStore } = useOnboardingContext();
	const [isLoading, setLoading] = useState<boolean>(false);
	const [password, setPassword] = useState<string>('');
	const [error, setError] = useState<{ title: string; message: string }>({
		title: '',
		message: '',
	});

	const route = useCurrentRoute();
	const dispatch = useDispatch();

	const apple = useConnectWithApple({
		failure: () => {
			actions.flashes.show({
				title: t('Could not create account'),
				message: t('Could not connect using Apple'),
			});
		},
	});

	useComponentDidMount(async () => {
		const authToken = route.query?.code;

		if (!authToken) return;

		await dispatch(actions.auth.authWithApple(authToken, language.get()));
	});

	const loginWithPassword = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoading(true);

		if (password.length === 0) {
			setLoading(false);
			return;
		}

		const success = await actions.auth.passwordAuth(userLogin.email, password);

		if (success) {
			resetStore();
		} else {
			setError({
				title: t('Could not log in'),
				message: t('Invalid email and/or password combination.'),
			});
		}

		setLoading(false);
	};

	const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) =>
		setPassword(event.target.value);

	const handleRemoveUserLogin = () => {
		ls.removeItem(ls.LocalStorageKeys.UserLogin);
		goTo('start');
	};

	const handleOtherLoginMethod = () => {
		ls.removeItem(ls.LocalStorageKeys.UserLogin);
		goTo('login');
	};

	const handleIdpLogin = (
		identityProvider: models.identityProvider.ScrubbedIdentityProvider
	) => {
		actions.sso.startLogin({
			identityProvider,
			state: {
				flow: StartLoginStateFlows.Login,
				organizationId: userLogin.organizationId,
			},
		});
	};

	let loginType = (
		<Form onSubmit={loginWithPassword}>
			<Column>
				<Field
					required
					type="password"
					name="password"
					placeholder={t('Your password')}
					value={password}
					onChange={handleChangePassword}
				/>
				<Button
					primary
					label={t('Sign in')}
					busy={isLoading}
					disabled={!password}
					type="submit"
				/>
			</Column>
		</Form>
	);

	if (userLogin.type === LoginTypes.Apple) {
		loginType = (
			<Button apple label={t('Continue with Apple')} onClick={apple.login} />
		);
	}

	if (userLogin.type === LoginTypes.SSO) {
		loginType = (
			<Button
				label={t('Sign in with {providerName}', {
					providerName: userLogin.identityProvider.displayName,
				})}
				onClick={() => handleIdpLogin(userLogin.identityProvider)}
			/>
		);
	}

	return (
		<Column spacing={styles.spacing._7}>
			<div className={css.accountWrapper}>
				<Avatar
					size={75}
					user={userLogin as any}
					customImageUrl={userLogin?.meta?.['profile-image-url']}
				/>
				<span>{t('Continue as:')}</span>
				<strong>
					{userLogin.firstName} {userLogin.lastName}{' '}
					<Link onClick={handleRemoveUserLogin}>{t('Not you?')}</Link>
				</strong>
				{userLogin.type === 'email' && (
					<em data-testid="onboarding.last_used_email">{userLogin.email}</em>
				)}
			</div>

			<AnimatePresence>
				{error.title && (
					<InfoBox color="red">
						<strong>{error.title}</strong>
						<span>{error.message}</span>
					</InfoBox>
				)}
			</AnimatePresence>

			{loginType}

			<FooterNote>
				<Link onClick={handleOtherLoginMethod}>
					{t('Use other login method')}
				</Link>
			</FooterNote>

			{userLogin.type === 'email' && <ForgotPasswordLink />}
		</Column>
	);
}
