import { JSX, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrefab } from '@prefab-cloud/prefab-cloud-react';

import * as routes from 'pkg/router/routes';
import { Flag, isDev, overrideFlag, useFlag } from 'pkg/flags';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';
import store from 'pkg/store/createStore';
import { useCurrentAccount } from 'pkg/identity';
import { usePushNotificationSender } from 'pkg/notifications';
import Link from 'pkg/router/Link';

import { DebugModal } from 'containers/debug/Debug';

import MaterialSymbol from 'components/material-symbols';

import * as Input from 'components/form/inputs';

import * as Context from 'design/context_menu';

import * as css from './style.css';

function FlagItem({ flag }: { flag: string }) {
	const flagVal = useFlag<boolean>(flag as Flag);
	const [enabled, setEnabled] = useState<boolean>(flagVal);

	return (
		<Context.Item
			tight
			closeOnClick={false}
			tooltip={flag}
			className={css.itemWithToggle}>
			<Context.ItemIcon name="flag" />
			<Link
				href={`https://app.prefab.cloud/account/projects/2244/flags/${flag}`}>
				{flag}
			</Link>
			<Input.Control
				standalone
				type="toggle"
				checked={enabled}
				onChange={() => {
					overrideFlag(flag, !enabled);
					setEnabled(!enabled);
				}}
			/>
		</Context.Item>
	);
}

export default function Menu(): JSX.Element {
	const dispatch = useDispatch();
	const darkMode = useSelector((state: RootState) => state.app.darkMode);
	const currentAccount = useCurrentAccount();
	const sendPushNotification = usePushNotificationSender();
	const prefab = usePrefab();

	const [showingDebugModal, setShowDebugModal] = useState<boolean>(false);

	const actAsAccounts: string[] = [
		'clubpres@360player.local',
		'coach@360player.local',
		'academy@360player.local',
		'staff@360player.local',
		'curator@360player.local',
		'parent@360player.local',
		'child@360player.local',
		'needs_consent@360player.local',
		'360admin@360player.local',
	];

	const showDebugModal = () => setShowDebugModal(true);
	const hideDebugModal = () => setShowDebugModal(false);

	const toggleDarkMode = () => dispatch(actions.app.setDarkMode(!darkMode));

	const logInAs = async (email: string) => {
		await actions.auth.logout()(store.dispatch);
		await actions.auth.passwordAuth(email, 'password', 'en-US', true);
	};

	/**
	 * @todo Set up logic to test PN dynamically
	 */
	const fakePushNotification = () => {
		sendPushNotification({
			id: 'pn:test',
			title: 'Fake Push Notification',
			body: "Hello there! I'm just a little test notification!",
			data: {
				'organization-id': 1,
				'resource-type': 'groupHome',
				'resource-id': 1,
				'trigger-id': 1,
				'target-id': 1,
			},
		});
	};

	if (!isDev()) return null;

	return (
		<Fragment>
			<Context.Menu
				className={css.developerMenu}
				toggleWith={
					<span className={css.developerMenuToggle}>
						<MaterialSymbol variant="settings" scale={1.6} />
					</span>
				}>
				<Context.Item
					tight
					closeOnClick={false}
					onClick={toggleDarkMode}
					className={css.itemWithToggle}>
					<Context.ItemIcon name={darkMode ? 'light_mode' : 'dark_mode'} />
					<span>Dark Mode</span>
					<Input.Control standalone type="toggle" checked={darkMode} />
				</Context.Item>

				<Context.Divider />

				<Context.Label>Flags</Context.Label>
				{prefab.keys.map((flag: string) => {
					return <FlagItem key={flag} flag={flag} />;
				})}
				<Context.Divider />

				<Context.Item icon="settings_b_roll" onClick={showDebugModal}>
					<span>
						Data Generator
						<span style={{ color: 'var(--palette-gray-500)' }}>(Debug)</span>
					</span>
				</Context.Item>

				<Context.Item
					icon="notifications_active"
					onClick={fakePushNotification}>
					<span>Send Fake Push Notification</span>
				</Context.Item>

				<Context.Divider />

				<Context.Label>Switch Account</Context.Label>

				{actAsAccounts.map((email: string) => (
					<Context.Item
						key={email}
						tight
						onClick={() => logInAs(email)}
						className={email === currentAccount.email ? css.activeItem : null}>
						{email}
					</Context.Item>
				))}

				<Context.Divider />

				<Context.Label>Documentation</Context.Label>
				<Context.LinkItem icon="faucet" href={routes.Debug.KitchenSink()}>
					Kitchen Sink
				</Context.LinkItem>
				<Context.LinkItem
					icon="webhook"
					href="https://ts-api-docs.azurewebsites.net">
					Threesixty API
				</Context.LinkItem>
			</Context.Menu>
			{showingDebugModal && <DebugModal onClose={hideDebugModal} />}
		</Fragment>
	);
}
