import { useT } from '@transifex/react';
import { Fragment, ChangeEvent, useRef, useState } from 'react';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import EditableRow from 'routes/group/settings/editable-row';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import * as Input from 'components/form/inputs';
import TextInput from 'components/form/TextInput';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface ServiceFeeProps {
	group: models.group.Group;
	providerSettings: models.providerSettings.ProviderSettings;

	refresh: () => void;
}

export default function ServiceFee({
	group,
	providerSettings,
	refresh,
}: ServiceFeeProps) {
	const t = useT();

	const [serviceFee, setServiceFee] = useState<string>(
		group.serviceFeePercent.toString() || '0'
	);
	const [isEditingServiceFee, setIsEditingServiceFee] = useState(false);
	const [hasError, setHasError] = useState(false);
	const serviceFeeRef = useRef(null);

	const handleIsEditingServiceFee = () => setIsEditingServiceFee(true);

	const handleNameCancel = () => {
		setIsEditingServiceFee(false);
		setServiceFee(group.serviceFeePercent.toString());
		setHasError(false);
	};

	const handleChangeServiceFee = (event: ChangeEvent<HTMLInputElement>) => {
		if (
			Number.parseFloat(event.currentTarget.value) > 100 ||
			Number.parseFloat(event.currentTarget.value) < 0 ||
			!event.currentTarget.value
		) {
			setHasError(true);
		} else {
			setHasError(false);
		}
		setServiceFee(event.currentTarget.value);
	};

	const handleUpdateServiceFee = async () => {
		await actions.groups.update(group.id, {
			serviceFeePercent: Number.parseFloat(serviceFee),
		});

		refresh();
		setIsEditingServiceFee(false);
	};

	if (!providerSettings.canHaveServiceFee) {
		return null;
	}

	return (
		<Column>
			<SectionTitle>{t('Service fee')}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					<EditableRow
						label={t('Service fee')}
						isEditing={isEditingServiceFee}
						staticComponent={serviceFee + '%'}
						hasError={hasError}
						editableComponent={
							<Fragment>
								<Input.Group hasError={hasError}>
									<TextInput
										ref={serviceFeeRef}
										value={serviceFee}
										onChange={handleChangeServiceFee}
										hasError={hasError}
										type="number"
										max={100}
										autoFocus
									/>
								</Input.Group>
								<div className={css.serviceFeeDescription}>
									{t(
										'Add a percentage service fee to all transactions in the organization. This will be added on top of the overall order value.'
									)}
								</div>
							</Fragment>
						}
						onEdit={handleIsEditingServiceFee}
						onSubmit={handleUpdateServiceFee}
						onCancel={handleNameCancel}
					/>
				</Card.Body>
			</Card.Base>
		</Column>
	);
}
