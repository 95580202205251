import { fabric } from 'fabric';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import {
	Circle,
	Rectangle,
	Triangle,
	toolConfig,
} from 'components/drawing/config';
import { SquigglyLine } from 'components/drawing/config/custom';

const _context = 'training_library/drawing';

// shared drawing tool prefs
interface Preferences {
	[key: string]: {
		type: string;
		label: () => void;
		get: (arg: unknown) => void;
		set: (arg: unknown) => void;
		inline?: boolean;
		opacity?: boolean;
		min?: number;
		max?: number;
		placeholder?: () => void;
		uppercase?: boolean;
		autofocus?: boolean;
	};
}

export const preferences: Preferences = {
	fillColor: {
		type: 'color',
		inline: true,
		opacity: true,
		label: () => t('Fill color', { _context }),
		get: ({ obj }: any) => (obj.getObjects?.()[0] || obj).fill,
		set: ({ value, obj, canvas }: any) => {
			(obj.getObjects?.()[0] || obj).set('fill', value);
			canvas.current.renderAll();
		},
	},

	showStroke: {
		type: 'toggle',
		label: () => t('Show stroke', { _context }),
		get: ({ obj }: any) => {
			if (!obj.getObjects) {
				return !!obj.stroke && obj.stroke !== 'transparent';
			} else {
				const circle = obj.getObjects().find((o: any) => o.type === 'circle');
				return !!circle && circle.stroke && circle.stroke !== 'transparent';
			}
		},
		set: ({ value, obj, canvas }: any) => {
			if (!obj.getObjects) {
				obj.set('stroke', value ? '#ffffff' : 'transparent');
			} else {
				const circle = obj.getObjects().find((o: any) => o.type === 'circle');
				if (circle) {
					circle.set('stroke', value ? '#ffffff' : 'transparent');
				}
			}
			canvas.current.renderAll();
		},
	},

	strokeColor: {
		type: 'color',
		label: () => t('Stroke color', { _context }),
		get: ({ obj }: any) => {
			if (!obj.getObjects) {
				return obj.stroke;
			}
			const circle = obj.getObjects().find((o: any) => o.type === 'circle');
			return circle?.stroke || '#000000';
		},
		set: ({ value, obj, canvas }: any) => {
			if (!obj.getObjects) {
				obj.set('stroke', value);
			} else {
				const circle = obj.getObjects().find((o: any) => o.type === 'circle');
				if (circle) {
					circle.set('stroke', value);
				}
			}
			canvas.current.renderAll();
		},
	},

	strokeColorMulti: {
		type: 'color',
		inline: true,
		opacity: true,
		label: () => t('Stroke color', { _context }),
		get: ({ obj }: any) => obj.getObjects()[0].stroke,
		set: ({ value, obj, canvas }: any) => {
			obj.getObjects().forEach((o: any) => {
				o.set('stroke', value);
			});
			canvas.current.renderAll();
		},
	},

	strokeAndFillColor: {
		type: 'color',
		label: () => t('Stroke color', { _context }),
		inline: true,
		opacity: true,
		get: ({ obj }: any) => obj.getObjects()[0].stroke,
		set: ({ value, obj, canvas }: any) => {
			obj.getObjects().forEach((o: any) => {
				if (o.stroke) o.set('stroke', value);
				if (o.type !== 'polyline' && o.fill !== 'transparent') {
					o.set('fill', value);
				}
			});
			canvas.current.renderAll();
		},
	},

	strokeAndFillColorSolid: {
		type: 'color',
		label: () => t('Stroke color', { _context }),
		inline: true,
		get: ({ obj }: any) => obj.getObjects()[0].stroke,
		set: ({ value, obj, canvas }: any) => {
			obj.getObjects().forEach((o: any) => {
				if (o.stroke) o.set('stroke', value);
				if (o.type !== 'polyline' && o.fill !== 'transparent') {
					o.set('fill', value);
				}
			});
			canvas.current.renderAll();
		},
	},

	strokeWidth: {
		type: 'range',
		label: () => t('Stroke width', { _context }),
		get: ({ obj }: any) => {
			if (!obj.getObjects) {
				return obj.strokeWidth;
			}
			const circle = obj.getObjects().find((o: any) => o.type === 'circle');
			return circle ? circle.strokeWidth : 1;
		},
		set: ({ value, obj, canvas }: any) => {
			if (!obj.getObjects) {
				obj.set('strokeWidth', value);
			} else {
				const circle = obj.getObjects().find((o: any) => o.type === 'circle');
				if (circle) {
					circle.set('strokeWidth', value);
				}
			}
			canvas.current.renderAll();
		},
	},

	strokeWidthMulti: {
		type: 'range',
		label: () => t('Stroke width', { _context }),
		min: 1,
		max: 20,
		get: ({ obj }: any) => obj.getObjects()[0].strokeWidth,
		set: ({ value, obj, canvas }: any) => {
			obj.getObjects().forEach((o: any) => {
				const offset = (value - o.strokeWidth) / 2;
				o.set('top', o.top - offset);
				o.set('left', o.left - offset);

				o.set('strokeWidth', value);
			});
			canvas.current.renderAll();
		},
	},

	strokeDash: {
		type: 'toggle',
		label: () => t('Stroke dash', { _context }),
		get: ({ obj }: any) => {
			return !!(obj.getObjects?.()[0] || obj).strokeDashArray?.[0];
		},
		set: ({ value, obj, canvas }: any) => {
			(obj.getObjects?.()[0] || obj).set(
				'strokeDashArray',
				value ? [12, 6] : null
			);
			canvas.current.renderAll();
		},
	},

	doubleArrow: {
		type: 'toggle',
		label: () => t('Double arrow', { _context }),
		get: ({ obj }: any) => {
			const start = obj.getObjects?.()[2];
			return start.fill !== 'transparent';
		},
		set: ({ value, obj, canvas }: any) => {
			const [, end, start] = obj.getObjects?.();
			start.set('fill', value ? end.fill : 'transparent');
			canvas.current.renderAll();
		},
	},

	equipmentFill: {
		type: 'color',
		inline: true,
		label: () => t('Equipment color', { _context }),
		get: ({ obj }: any) => {
			const o = (obj.getObjects?.() || [obj]).find(
				(o: any) =>
					(!o.fillOpacity && o.fill && o.fill.substring(0, 3) !== 'rgb') ||
					o.stroke
			);
			return o.fill || o.stroke;
		},
		set: ({ value, obj, canvas }: any) => {
			(obj.getObjects?.() || [obj]).forEach((o: any) => {
				if (o.fill && o.fill.substring(0, 3) !== 'rgb' && o.type !== 'text') {
					o.set(
						'fill',
						o.fillOpacity ? 'rgba(255, 255, 255, ' + o.fillOpacity + ')' : value
					);
				}
				if (o.stroke) {
					o.set('stroke', value);
				}
			});
			canvas.current.renderAll();
		},
	},

	backgroundColor: {
		type: 'color',
		label: () => t('Background color', { _context }),
		opacity: true,
		get: ({ obj }: any) => obj.backgroundColor,
		set: ({ value, obj, canvas }: any) => {
			obj.set('backgroundColor', value);
			canvas.current.renderAll();
		},
	},

	textColor: {
		type: 'color',
		label: () => t('Text color', { _context }),
		get: ({ obj }: any) => obj.fill,
		set: ({ value, obj, canvas }: any) => {
			obj.set('fill', value);
			canvas.current.renderAll();
		},
	},

	textLabel: {
		type: 'text',
		label: () => t('Text label', { _context }),
		placeholder: () => t('text_placeholder', { _context }),
		get: ({ obj }: any) => obj.text,
		set: ({ value, obj, canvas }: any) => {
			obj.set('text', value);
			canvas.current.renderAll();
		},
	},

	playerColor: {
		type: 'color',
		label: () => t('Player color', { _context }),
		get: ({ obj }: any) => {
			const circle = obj.getObjects?.().find((o: any) => o.type === 'circle');
			return circle.fill;
		},
		set: ({ value, obj, canvas }: any) => {
			const circle = obj.getObjects?.().find((o: any) => o.type === 'circle');
			circle.set('fill', value);

			// set direction gradient color
			const direction = obj.getObjects?.().find((o: any) => o.type === 'path');
			if (direction.fill !== 'transparent') {
				setPlayerDirectionColor(obj, value);
			}

			// set text color based on background brightness
			const text = obj.getObjects?.().find((o: any) => o.type === 'text');
			const bg =
				parseInt(value.substring(1, 3), 16) +
				parseInt(value.substring(3, 5), 16) +
				parseInt(value.substring(5, 7), 16);
			text.set('fill', bg < 540 ? styles.palette.white : styles.palette.black);

			canvas.current.renderAll();
		},
	},

	playerText: {
		type: 'text',
		autofocus: true,
		max: 3,
		label: () => t('Player text', { _context }),
		placeholder: () => t('Initials or position, max. 3 chars.', { _context }),
		uppercase: true,
		get: ({ obj }: any) => {
			const text = obj.getObjects?.().find((o: any) => o.type === 'text');
			return text.text;
		},
		set: ({ value, obj, canvas }: any) => {
			const text = obj.getObjects?.().find((o: any) => o.type === 'text');
			text.set('text', value);
			canvas.current.renderAll();
		},
	},

	playerTextSecondary: {
		type: 'text',
		label: () => t('Secondary text', { _context }),
		placeholder: () => t('Name or position', { _context }),
		get: ({ obj }: any) => {
			const text = obj
				.getObjects?.()
				.filter((o: any) => o.type === 'text')?.[1];
			return text.text;
		},
		set: ({ value, obj, canvas }: any) => {
			const text = obj
				.getObjects?.()
				.filter((o: any) => o.type === 'text')?.[1];
			text.set('text', value);
			canvas.current.renderAll();
		},
	},

	playerDirection: {
		type: 'toggle',
		label: () => t('Show direction', { _context }),

		get: ({ obj }: any) => {
			const direction = obj.getObjects?.().find((o: any) => o.type === 'path');
			return direction.fill !== 'transparent';
		},
		set: ({ value, obj, canvas }: any) => {
			const circle = obj.getObjects?.().find((o: any) => o.type === 'circle');
			setPlayerDirectionColor(obj, value && circle.fill);
			canvas.current.renderAll();
		},
	},
};

const setPlayerDirectionColor = (obj: any, color: string) => {
	const direction = obj.getObjects?.().find((o: any) => o.type === 'path');
	const gradient = color
		? new fabric.Gradient({
				colorStops: [
					{ offset: 0, color: color.substring(0, 7) + '66' },
					{ offset: 1, color: color.substring(0, 7) + '22' },
				],
				coords: { x1: 3.64706, y1: 3.66667, x2: 3.64706, y2: -0.166667 },
				gradientUnits: 'pixels',
				type: 'linear',
			})
		: 'transparent';
	direction.set('fill', gradient);
};

// create and load functions
export const createCircle = (obj: any) => {
	const { id, tool, color, left, top, variant } = obj;

	const circle: any = new fabric.Ellipse({
		fill: obj.fill || color,
		stroke: obj.stroke || '#000000ff',
		strokeUniform: true,
		strokeDashArray:
			obj.strokeDashArray || (variant === 'dashed' ? [12, 6] : null),
		left,
		top,
		rx: obj.rx || 0,
		ry: obj.ry || 0,
		width: obj.width || 0,
		height: obj.height || 0,
	});

	if (id) {
		circle.id = id;
		circle.tool = tool;
	}

	return circle;
};

export const saveCircle = ({ rx, ry }: Circle) => ({
	rx,
	ry,
});

export const createRectangle = (obj: any) => {
	const { id, tool, color, left, top, variant } = obj;

	const rectangle: any = new fabric.Rect({
		fill: obj.fill || color,
		stroke: obj.stroke || '#000000ff',
		strokeUniform: true,
		strokeDashArray:
			obj.strokeDashArray || (variant === 'dashed' ? [12, 6] : null),
		left,
		top,
		width: obj.width || 0,
		height: obj.height || 0,
	});

	if (id) {
		rectangle.id = id;
		rectangle.tool = tool;
	}

	return rectangle;
};

export const saveRectangle = ({ width, height }: Rectangle) => ({
	width,
	height,
});

export const createTriangle = (obj: any) => {
	const { id, tool, color, left, top, variant } = obj;

	const triangle: any = new fabric.Triangle({
		fill: obj.fill || color || '#000000',
		stroke: obj.stroke || '#000000ff',
		strokeUniform: true,
		strokeDashArray:
			obj.strokeDashArray || (variant === 'dashed' ? [12, 6] : null),
		left: left ?? 0,
		top: top ?? 0,
		width: obj.width || 0,
		height: obj.height || 0,
		originX: 'left',
		originY: 'top',
	});

	if (id) {
		triangle.id = id;
		triangle.tool = tool;
	}

	return triangle;
};

export const saveTriangle = ({ width, height }: Triangle) => ({
	width,
	height,
});

export const createIcon = async ({
	id,
	left,
	top,
	width,
	tool,
	variant,
}: any) => {
	return new Promise((resolve) => {
		if (!variant) {
			variant = tool.substring(5);
			tool = 'icon';
		}

		const icon = toolConfig.icons[variant];
		fabric.loadSVGFromURL(
			window.TS.assetUrl + '/' + icon.url,
			(objects, options) => {
				const svg: any = fabric.util.groupSVGElements(objects, options);

				const size = width || icon.width || 50;

				svg.lockScalingFlip = true;
				svg.scaleToWidth(size);
				svg.top = top - (id ? 0 : size / 2);
				svg.left = left - (id ? 0 : size / 2);
				svg.objectCaching = false;
				svg.setControlsVisibility({
					ml: false,
					mt: false,
					mr: false,
					mb: false,
				});

				if (id) {
					svg.id = id;
					svg.tool = tool + '_' + variant;
				}

				resolve(svg);
			},
			null,
			{ crossOrigin: 'Anonymous' }
		);
	});
};

export const saveIcon = ({}: any) => ({});

export const createText = ({ id, left, top, width, text }: any) => {
	const textObj: any = new fabric.Textbox(text || '', {
		editable: false,
		fontFamily: styles.font.family.default,
		fontSize: 26,
		fontWeight: styles.font.weight.semibold,
		fill: styles.palette.white + 'FF',
		backgroundColor: styles.palette.black,
		width: width || 270,
		shadow: 'rgba(0,0,0,0.2) 1px 1px 1px',
		textAlign: 'center',
		originX: 'center',
		originY: 'center',
		centeredRotation: true,
		left,
		top,
	});

	if (id) {
		textObj.id = id;
		textObj.tool = 'text';
	}

	textObj.setControlsVisibility({
		mt: false,
		mb: false,
	});

	return textObj;
};

export const saveText = ({ width }: any) => ({ width });

export const createFreehand = ({ id, paths }: any) => {
	// loop through each line
	const lines = paths.map((line: any) => {
		const l = new fabric.Path(line);
		l.set({ fill: 'transparent' });
		l.set({ strokeLineCap: 'round' });
		l.set({ strokeLineJoin: 'round' });
		return l;
	});

	const group: any = new fabric.Group(lines);
	group.tool = 'freehand';
	group.id = id;

	return group;
};

export const saveFreehand = (obj: any) => {
	const { width, height } = obj;
	const paths = obj.getObjects().map((line: any) => line.path);

	return {
		width,
		height,
		paths,
	};
};

export const createPlayer = ({
	id,
	left,
	top,
	color,
	angle,
	stroke,
	strokeWidth,
}: any) => {
	return new Promise((resolve) => {
		fabric.loadSVGFromURL(
			window.TS.assetUrl + '/img/drawing/player.svg',
			(objects, options) => {
				objects.forEach((o) => {
					if (o.type === 'circle') {
						o.fill = color;
						o.stroke = stroke || '#ffffff';
						o.strokeWidth = strokeWidth || 0.6;
					}
				});

				const player: any = fabric.util.groupSVGElements(objects, options);
				player.lockScalingFlip = true;

				const size = 80;
				player.scaleToWidth(size);
				player.top = top - size / 2;
				player.left = left - size / 2;
				player.angle = angle || 0;

				const text = new fabric.Text('', {
					fontFamily: styles.font.family.default,
					fontSize: 3.8,
					charSpacing: -50,
					fontWeight: styles.font.weight.semibold,
					fill: styles.palette.white,
					textAlign: 'center',
					originX: 'center',
					originY: 'center',
					left: -0.4,
					centeredRotation: true,
					shadow: 'rgba(0,0,0,0.2) 0.5px 0.5px 1px',
					angle: -(angle || 0),
				});
				player.add(text);

				player.setControlsVisibility({
					ml: false,
					mt: false,
					mr: false,
					mb: false,
				});

				if (id) {
					player.id = id;
					player.tool = 'player';
				}

				resolve(player);
			},
			null,
			{ crossOrigin: 'Anonymous' }
		);
	});
};

export const savePlayer = ({ angle }: any) => {
	return { angle };
};

export const createJersey = ({ id, left, top, color, angle }: any) => {
	return new Promise((resolve) => {
		fabric.loadSVGFromURL(
			window.TS.assetUrl + '/img/drawing/jersey.svg',
			(objects, options) => {
				objects.forEach((o) => {
					if (o.type === 'path') o.fill = color;
				});
				const player: any = fabric.util.groupSVGElements(objects, options);
				player.lockScalingFlip = true;
				const size = 80;
				player.scaleToWidth(size);
				player.top = top - size / 2;
				player.left = left - size / 2;
				player.angle = angle || 0;

				const text = new fabric.Text('', {
					fontFamily: styles.font.family.default,
					fontSize: 8,
					charSpacing: -65,
					fontWeight: styles.font.weight.semibold,
					fill: styles.palette.white,
					textAlign: 'center',
					originX: 'center',
					originY: 'center',
					top: -5,
					centeredRotation: true,
					shadow: 'rgba(0,0,0,0.2) 0.5px 0.5px 1px',
					angle: -(angle || 0),
				});
				player.add(text);
				const secondaryText = new fabric.Text('', {
					fontFamily: styles.font.family.default,
					fontSize: 6,
					fontWeight: styles.font.weight.semibold,
					fill: styles.palette.white,
					textAlign: 'center',
					originX: 'center',
					originY: 'center',
					top: 23,
					left: -0.6,
					centeredRotation: true,
					shadow: 'rgba(0,0,0,0.2) 0.5px 0.5px 1px',
					angle: -(angle || 0),
				});
				player.add(secondaryText);
				player.setControlsVisibility({
					ml: false,
					mt: false,
					mr: false,
					mb: false,
				});

				if (id) {
					player.id = id;
					player.tool = 'jersey';
				}

				resolve(player);
			},
			null,
			{ crossOrigin: 'Anonymous' }
		);
	});
};

export const saveJersey = ({ angle }: any) => {
	return { angle };
};

export const createMultipoint = ({ id, points, tool, color, variant }: any) => {
	if (points.length < 2) return;

	const line = new fabric.Polyline(points, {
		fill: tool === 'polygon' ? color : 'transparent',
		stroke: tool === 'polygon' || !color ? '#000000' : color?.substring(0, 7),
		strokeWidth: tool === 'polygon' ? 1 : 2,
		strokeDashArray: variant === 'dashed' ? [12, 6] : null,
	});

	// arrow heads
	let arrowEnd: any;
	let arrowStart: any;

	if (tool === 'arrow' || tool === 'arrow_dashed') {
		// arrow head
		const first = points[0];
		const last = points[points.length - 1];

		// calculate angle between two last points for arrow
		const size = 7;
		const angle =
			Math.atan2(first.y - last.y, first.x - last.x) * (180 / Math.PI) - 90;
		arrowEnd = new fabric.Triangle({
			left: last.x - size * 1.5,
			top: last.y - size * 2,
			width: size * 3,
			height: size * 4,
			centeredRotation: true,
			fill: color?.substring(0, 7) || '#000000',
		});
		arrowEnd.rotate(angle);

		arrowStart = new fabric.Triangle({
			left: first.x - size * 1.5,
			top: first.y - size * 2,
			width: size * 3,
			height: size * 4,
			centeredRotation: true,
			fill: 'transparent',
		});
		arrowStart.rotate(angle - 180);
	}

	// group line objects
	const groupItems = [line];
	if (arrowStart) groupItems.push(arrowEnd, arrowStart);
	const group: any = new fabric.Group(groupItems);

	if (id) {
		group.id = id;
		group.tool = tool;
	}
	group.lockScalingFlip = true;
	group.setControlsVisibility({
		ml: false,
		mt: false,
		mr: false,
		mb: false,
	});

	return group;
};

export const saveMultipoint = (obj: any) => {
	const points = obj.getObjects()[0].points;
	return { points };
};

export const createSquiggly = ({ id, points, tool, color }: any) => {
	const line =
		points.length === 2 &&
		new SquigglyLine([points[0].x, points[0].y, points[1].x, points[1].y], {
			strokeWidth: 3,
			stroke: color?.substring(0, 7) || '#000000',
			arrow: tool === 'arrow' || tool === 'arrow_squiggly',
		});

	if (!line) return;

	const groupItems = [line];
	const group: any = new fabric.Group(groupItems);

	if (id) {
		group.id = id;
		group.tool = tool;
	}
	group.lockScalingFlip = true;
	group.setControlsVisibility({
		ml: false,
		mt: false,
		mr: false,
		mb: false,
	});

	return group;
};

export const saveSquiggly = (obj: any) => {
	const { x1, y1, x2, y2 } = obj.getObjects()[0];
	return {
		points: [
			{ x: x1, y: y1 },
			{ x: x2, y: y2 },
		],
	};
};
