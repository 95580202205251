import { JSX, ReactNode } from 'react';
import styled from 'styled-components';

import User from 'pkg/models/user';
import Account from 'pkg/models/account';

import { UrlParser } from 'pkg/url';
import Link from 'pkg/router/Link';

import Avatar from 'components/avatar';

const urlParser = new UrlParser();

const Anchor = styled(Link)`
	display: flex;
	align-items: center;
`;

const Inline = styled.div`
	display: flex;
	align-items: center;
`;

const ChildrenWrapper = styled.span`
	margin-left: 10px;
`;

interface IAvatarLink {
	user?: User;
	account?: Account;
	noLink?: boolean;
	urlPattern?: string;
	size?: number;
	children?: ReactNode;
	className?: string;
}

const AvatarLink = ({
	user,
	account,
	noLink,
	urlPattern = '/user/profile/:userId',
	size = 25,
	children,
	className,
}: IAvatarLink): JSX.Element => {
	if (!user && !account) {
		return null;
	}

	const userPathUrl = urlParser.transform(urlPattern, {
		userId: user.id.toString(),
	});

	const renderedChildren = [
		<Avatar
			key="avatar-link-image"
			user={user}
			account={account}
			size={size}
		/>,
		<ChildrenWrapper key="avatar-link-children">{children}</ChildrenWrapper>,
	];

	if (noLink) {
		return <Inline className={className}>{renderedChildren}</Inline>;
	}

	return (
		<Anchor className={className} href={userPathUrl}>
			{renderedChildren}
		</Anchor>
	);
};

export default styled(AvatarLink)`
	${Avatar} {
		width: ${({ size }) => size};
		flex: 0 0 auto;
	}
`;
