import { JSX } from 'react';
import { t } from '@transifex/native';

import { palette } from 'pkg/config/styles';

import {
	useLocalizedCurrencyFormatter,
	useOrderReport,
} from 'routes/payments/overview/hooks';
import { Widget } from 'routes/payments/overview/widgets';

import * as DataCard from 'design/data_card';
import * as Chart from 'design/chart';

export default function PaymentDueTimings(): JSX.Element {
	const { paymentDueTimings } = useOrderReport();
	const fmt = useLocalizedCurrencyFormatter();

	const { paidInTime, paidInTimeSum, paidAfterDueDate, paidAfterDueDateSum } =
		paymentDueTimings;

	const chartData: Chart.ChartItem[] = [
		{
			title: t('On time'),
			value: paidInTime,
			fillColor: palette.green[200],
			textColor: palette.green[600],
			metadata: {
				sum: paidInTimeSum,
			},
		},
		{
			title: t('After due date'),
			value: paidAfterDueDate,
			fillColor: palette.red[200],
			textColor: palette.red[600],
			metadata: {
				sum: paidAfterDueDateSum,
			},
		},
	];

	const tooltipFormatter = (item: Chart.ChartItem) => {
		return fmt(item.metadata.sum);
	};

	return (
		<Widget>
			<DataCard.Base titleIcon="check_circle" title={t('Paid on time')}>
				<Chart.Donut
					items={chartData}
					formatTooltip={tooltipFormatter}
					emptyStateLabel={t('No data for selected period')}
				/>
			</DataCard.Base>
		</Widget>
	);
}
