import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';

import UserRow from 'routes/event/single/tabs/lok/user-row';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import SectionTitle from 'components/SectionTitle';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';

import * as css from './styles.css';

interface LokProps {
	event: models.event.Event;
}

export default function Lok({ event }: LokProps): JSX.Element {
	const lokLastReportedDate = event.lokLastReportedDate
		? new Date(event.lokLastReportedDate * 1000).toLocaleString(
				DateTime.getLocale()
			)
		: null;

	const lastLokReported = lokLastReportedDate
		? lokLastReportedDate
		: t('Never reported');

	const eventUsers = event.users || [];

	const leaders = eventUsers
		.filter((eventUser) => models.eventUser.lokLeader(eventUser))
		.map((eventUser) => (
			<div className={css.descriptionRow} key={eventUser.id}>
				<Avatar user={eventUser.user} size={24} />
				<div>
					{t('Event leader: {name}', {
						name: models.user.fullName(eventUser.user),
					})}
				</div>
			</div>
		));

	const participants = eventUsers.map((eventUser, i: number) => (
		<UserRow eventUser={eventUser} event={event} key={i} />
	));

	const content = (
		<Column spacing={styles.spacing._7}>
			<SectionTitle large>{t('LOK information')}</SectionTitle>
			<Column spacing={styles.spacing._5}>
				<div className={css.descriptionRow}>
					<Icon name="clock" />
					<div>
						{t('Last LOK report date: {lastLokReported}', { lastLokReported })}
					</div>
				</div>
				{leaders}
			</Column>
			<SectionTitle medium>{t('Participants')}</SectionTitle>
			<Column spacing={styles.spacing._5}>{participants}</Column>
		</Column>
	);

	if (!event.id || !models.canEdit(event)) {
		return null;
	}

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.STANDARD}>
					{content}
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
}
