import React, {
	useMemo,
	useReducer,
	useCallback,
	createContext,
	useContext,
	ReactNode,
	JSX,
} from 'react';

import { ToolSymbol } from 'routes/video/analyze/tool-symbols';

export interface Tool {
	guid: string;
	symbol: ToolSymbol;
	label: string;
	onClick?: () => void;
}

interface ToolbarState {
	tools?: Map<string, Tool>;
	activeTool?: string;
}

export enum ToolbarAction {
	Reset = 'reset',
	Tools = 'tools',
	SetActive = 'activeTool',
}

type ToolbarPayload = Map<string, Tool> | string;

export type Action =
	| { type: ToolbarAction.Reset; payload: ToolbarState }
	| { type: ToolbarAction.Tools; payload: ToolbarState }
	| { type: ToolbarAction.SetActive; payload: ToolbarState };

interface ToolbarStateDispatch {
	state: ToolbarState;
	dispatch?: React.Dispatch<Action>;
	emit?: (type: ToolbarAction, payload: ToolbarPayload) => void;
}

const initialState: ToolbarState = {
	tools: new Map<string, Tool>(),
	activeTool: '',
};

const ToolbarContext = createContext<ToolbarStateDispatch>({
	state: initialState,
});

interface ToolbarHookResponse {
	tools: Tool[];
	activeTool: string;

	reset: () => void;
	addTool: (tool: Tool) => void;
	removeTool: (guid: string) => void;
	setActiveTool: (guid: string) => void;
}

export const useToolbar = (): ToolbarHookResponse => {
	const { state, emit } = useContext(ToolbarContext);

	const tools = Array.from(state.tools.values());

	const reset = (): void => emit(ToolbarAction.Reset, null);

	const addTool = (tool: Tool): void => {
		const tools = state.tools;

		tools.set(tool.guid, tool);

		emit(ToolbarAction.Tools, tools);
	};

	const removeTool = (guid: string): void => {
		const tools = state.tools;

		if (tools.has(guid)) {
			tools.delete(guid);

			emit(ToolbarAction.Tools, tools);
		}
	};

	const setActiveTool = (guid: string): void => {
		emit(ToolbarAction.SetActive, guid);
	};

	return {
		tools,
		activeTool: state.activeTool,

		reset,
		addTool,
		removeTool,
		setActiveTool,
	} as ToolbarHookResponse;
};

interface StateProviderProps {
	children: ReactNode;
}

const reducer = (state: ToolbarState, action: Action) => {
	const mutateState = (
		state: ToolbarState,
		nextState: ToolbarState
	): ToolbarState => ({
		...state,
		...nextState,
	});

	const actionType = action.type;

	switch (actionType) {
		case ToolbarAction.Reset:
			return { ...initialState };
		case ToolbarAction.Tools:
		case ToolbarAction.SetActive:
			return mutateState(state, action.payload);
		default:
			throw new Error(`Invalid action '${actionType}'.`);
	}
};

const ToolbarStateProvider = ({
	children,
}: StateProviderProps): JSX.Element => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const emit = useCallback(
		(type: ToolbarAction, payload: ToolbarPayload) =>
			dispatch({
				type,
				payload: {
					[type]: payload,
				},
			}),
		[dispatch]
	);

	const store: ToolbarStateDispatch = useMemo(
		() => ({
			state,
			dispatch,
			emit,
		}),
		[state, dispatch, emit]
	);

	return (
		<ToolbarContext.Provider value={store}>{children}</ToolbarContext.Provider>
	);
};

export default ToolbarStateProvider;
