import {
	JSX,
	Fragment,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { createPortal } from 'react-dom';

function getLayoutPortalId(portalId: string): string {
	return `layout-portal-${portalId}`;
}

interface LayoutPortalProperties {
	portalId: string;
	children: ReactNode | ReactNode[];
	observeNode?: HTMLElement | DocumentFragment;
}

/**
 *	LayoutPortal allows for creating portals (render nodes) which is useful when you need a component to define JSX to be rendered elsewhere.
 *	This is useful to replace any passed-down setState in layouts and other components where we're rendering components, such as navigation, sidebars etc.
 *
 *	LayoutPortal is the content you define, for where the component should be rendered see LayoutPortalLocation.
 *
 *	@example ComponentA (Entry-point)
 *
 * 		<LayoutPortalLocation portalId="hello-world" />
 *
 *	@example ComponentB (Elsewhere)
 *
 *		<LayoutPortal portalId="hello-world">
 *			<p>I will only render if a node matching LayoutPortalLocation is somewhere in the current page and or render, regardless of node hierarchy.</p>
 *		</LayoyutPortal>
 */
export function LayoutPortal({
	portalId,
	children,
	observeNode = document.body,
}: LayoutPortalProperties): JSX.Element {
	const targetSelector = `#${getLayoutPortalId(portalId)}`;
	const [targetNode, setTargetNode] = useState<HTMLElement | null>();

	const setTargetNodeIfExists = useCallback(() => {
		const portalTargetNode: HTMLElement | null =
			document.querySelector(targetSelector);

		if (portalTargetNode! && !targetNode) {
			setTargetNode(portalTargetNode);
		}
	}, [targetNode, targetSelector]);

	const observer = useMemo(() => {
		return new MutationObserver((mutations) => {
			mutations.forEach((mutation: MutationRecord) => {
				if (!mutation.addedNodes) return;

				setTargetNodeIfExists();
			});
		});
	}, [setTargetNodeIfExists]);

	useEffect(() => {
		observer.observe(observeNode, {
			childList: true,
			subtree: true,
		});

		setTargetNodeIfExists();
	}, [observer, observeNode, setTargetNodeIfExists]);

	if (!targetNode) {
		return <Fragment />;
	}

	return createPortal(children, targetNode!);
}

interface LayoutPortalLocationProperties {
	portalId: string;
}

/**
 *	LayoutPortalLocation is the mounting node for the portal. It's ID must be a valid DOMElementId.
 */
export function LayoutPortalLocation({
	portalId,
}: LayoutPortalLocationProperties): JSX.Element {
	return <div id={getLayoutPortalId(portalId)} />;
}
