// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pKkAWlZCLnl9Ur7UPjXz {
	padding: var(--spacing-4);
}

.Cq0AQ9BCzFEBDCmL20Dq {
	margin-top: var(--spacing-4);
	font-size: var(--font-size-sm);
	color: var(--palette-red-600);
}

.zyZrZRiIPMyorEEAHK_Q {
	/* @note Fixes issue with editor height in small screens */
	height: 0;
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/notes/form/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;AAC1B;;AAEA;CACC,4BAA4B;CAC5B,8BAA8B;CAC9B,6BAA6B;AAC9B;;AAEA;CACC,0DAA0D;CAC1D,SAAS;AACV","sourcesContent":[".editorWrapper {\n\tpadding: var(--spacing-4);\n}\n\n.attachmentWarning {\n\tmargin-top: var(--spacing-4);\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-red-600);\n}\n\n.formFileInput {\n\t/* @note Fixes issue with editor height in small screens */\n\theight: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var editorWrapper = `pKkAWlZCLnl9Ur7UPjXz`;
export var attachmentWarning = `Cq0AQ9BCzFEBDCmL20Dq`;
export var formFileInput = `zyZrZRiIPMyorEEAHK_Q`;
export default ___CSS_LOADER_EXPORT___;
