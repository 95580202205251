import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { JSX, PointerEvent } from 'react';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import { tlog } from 'pkg/tlog';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

import { useDialog } from 'design/dialog';
import Button from 'design/button';

import * as css from '../styles.css';

interface InheritParentSettingsMap {
	profileImageUrl: boolean;
	primaryColor: boolean;
	seasonStartMonth: boolean;
	showRatingResults: boolean;
	performanceReviewTemplate: boolean;
}

const DefaultParentSettings: InheritParentSettingsMap = {
	profileImageUrl: true,
	primaryColor: true,
	seasonStartMonth: true,
	showRatingResults: true,
	performanceReviewTemplate: true,
};

interface InheritParentSettingsProps {
	group: models.group.Group;
	parentGroup: models.group.Group;
	onDidInherit: (group: models.group.Group) => Promise<void>;
}

export default function InheritParentSettings({
	group,
	parentGroup,
	onDidInherit,
}: InheritParentSettingsProps): JSX.Element {
	const [inherit, setInherit] = useMixedState<InheritParentSettingsMap>(
		DefaultParentSettings
	);

	const translations = {
		profileImageUrl: t('Group badge', { _context: 'inherit settings' }),
		primaryColor: t('Group brand color', { _context: 'inherit settings' }),
		seasonStartMonth: t('Season start', { _context: 'inherit settings' }),
		showRatingResults: t('PositionMatch', { _context: 'inherit settings' }),
		performanceReviewTemplate: t('Player dialogue template', {
			_context: 'inherit settings',
		}),
	};

	const toggleInheritSetting = (event: PointerEvent<HTMLLabelElement>) => {
		const inheritKey = event.currentTarget.dataset
			.inheritKey as keyof InheritParentSettingsMap;

		setInherit({
			[inheritKey]: inherit[inheritKey] ? false : true,
		});
	};

	const dialogMessage = (
		<Column>
			<p>
				<T
					_str="Inherit the following settings from {parentName}:"
					parentName={<strong>{parentGroup.name}</strong>}
				/>
			</p>

			{Object.entries(translations).map(
				([key, label]: [keyof InheritParentSettingsMap, string]) => (
					<label
						key={key}
						data-inherit-key={key}
						className={css.inheritSetting}
						onClick={toggleInheritSetting}>
						<Input.Control standalone type="checkbox" checked={inherit[key]} />
						{label}
					</label>
				)
			)}
		</Column>
	);

	const dialogConfirmCallback = async () => {
		const meta: models.group.MetaEntry[] = [];

		if (parentGroup.meta && inherit.performanceReviewTemplate) {
			const m = parentGroup.meta.find(
				(item) => item.key === models.group.MetaField.PerformanceReviewTemplate
			);

			if (m) {
				meta.push({
					key: models.group.MetaField.PerformanceReviewTemplate,
					value: m.value,
				});
			}
		}

		const payload: any = {
			meta,
		};

		if (inherit.profileImageUrl) {
			payload.profileImageUrl = parentGroup.profileImageUrl;
		}
		if (inherit.primaryColor) {
			payload.primaryColor = parentGroup.primaryColor;
		}
		if (inherit.seasonStartMonth) {
			payload.seasonStartMonth = parentGroup.seasonStartMonth;
		}
		if (inherit.showRatingResults) {
			payload.showRatingResults = parentGroup.showRatingResults;
		}

		try {
			const [request, response] = await models.update(group, payload);

			if (request.ok) {
				onDidInherit(response as models.group.Group);

				return true;
			} else {
				tlog.error('could not inherit parent settings');
			}
		} catch (e: unknown) {
			tlog.error((e as Error).message);
		}

		return false;
	};

	const inheritParentGroupSettings = useDialog(
		{
			wide: true,
			prominent: true,
			title: t('Inherit Parent Team Settings', {
				parentName: parentGroup?.name,
			}),
			message: dialogMessage,
			confirmLabel: t('Continue'),
			onConfirm: dialogConfirmCallback,
			onCancel: () => {
				setInherit(DefaultParentSettings);
			},
		},
		[inherit]
	);

	return (
		<Button primary onClick={inheritParentGroupSettings}>
			{t('Use parent team settings')}
		</Button>
	);
}
