import { t } from '@transifex/native';
import { JSX, useState } from 'react';

import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useGroup } from 'pkg/hooks/selectors';

import StepModal, { Step } from 'components/step-modal';

import { RoleStep } from 'components/group/add-users-modal/RoleStep';
import { UsersStep } from 'components/group/add-users-modal/UsersStep';

interface AddUserModalProps {
	groupId: number;
	onClose: () => void;
	onFinish?: () => void;
}

type AllowedRoles =
	| models.membership.MembershipRole.User
	| models.membership.MembershipRole.Staff
	| models.membership.MembershipRole.Admin;

export const AddUserModal = ({
	groupId,
	onClose,
	onFinish,
}: AddUserModalProps): JSX.Element => {
	const [role, setRole] = useState<AllowedRoles>(null);
	const [selectedUsers, setSelectedUsers] = useState<models.user.User[]>([]);

	const group = useGroup(groupId);

	const addUsersToGroup = async () => {
		const userIdsWithRole = selectedUsers.map((u) => ({ id: u.id, role }));

		const [request] = await models.create(
			endpoints.UserGroups.AddUsersToGroup(groupId),
			userIdsWithRole
		);

		if (request.ok) {
			actions.flashes.show({
				title: t('Added users to {group_name}', { group_name: group.name }),
			});

			if (onFinish) {
				onFinish();
			}
		} else {
			actions.flashes.show({
				title: t('Unable to add users to group'),
			});
		}
	};

	const modalTitle = t('Add users to {group_name}', { group_name: group.name });

	return (
		<StepModal onClose={onClose} onFinish={addUsersToGroup}>
			<Step title={modalTitle} nextLabel={t('Next')} canGoNext={!!role}>
				<RoleStep setRole={setRole} role={role} />
			</Step>
			<Step
				title={modalTitle}
				skipBody
				nextLabel={t('Add selected users')}
				canGoNext={!!selectedUsers.length}>
				<UsersStep
					groupId={groupId}
					role={role}
					selectedUsers={selectedUsers}
					setSelectedUsers={setSelectedUsers}
				/>
			</Step>
		</StepModal>
	);
};
