import {
	JSX,
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { createPortal } from 'react-dom';
import { t } from '@transifex/native';

import AccountRelation from 'pkg/models/account_relation';
import Membership from 'pkg/models/membership';

import { normalizedDispatch } from 'pkg/actions/utils';

import { tlog } from 'pkg/tlog';
import { resign, resume } from 'pkg/events';
import { WebSocketClient } from 'pkg/websocket/client';
import store from 'pkg/store/createStore';
import * as actions from 'pkg/actions';
import { useCurrentAccount } from 'pkg/identity';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';

import Icon from 'components/icon';

import { useApiConfig } from 'components/application/apiconfig';

import * as css from './styles.css';

export enum MessageType {
	NotificationsTotalUnreadCount = 'notifications.total_unread_count',
	ChatUnreadCount = 'chat.unread_count',
	ChatUpdated = 'chat.updated',
	ChatEvent = 'chat.event',
	ChatMessageCreated = 'chat.message.created',
	ChatMessageUpdated = 'chat.message.updated',
	ChatMessageDeleted = 'chat.message.deleted',
	ChatReactionCreated = 'chat.reaction.added',
	ChatReactionDeleted = 'chat.reaction.removed',
	ChatArchived = 'chat.archived',
	ChatDeleted = 'chat.deleted',
	ChatUnreadCountByOrganizationId = 'chat.unread_count_by_organization_id',
	// Should get the chat the user was created in as well (only returns the chatUser)
	ChatUserCreated = 'chat.user.created',
	ChatUserUpdated = 'chat.user.updated',
	ChatUserDeleted = 'chat.user.deleted',

	IdentityProviderCreated = 'identity_provider.created',
	IdentityProviderUpdated = 'identity_provider.updated',
	IdentityProviderDeleted = 'identity_provider.deleted',

	ScheduleItemCreated = 'schedule.item.created',
	ScheduleItemUpdated = 'schedule.item.updated',
	ScheduleItemDeleted = 'schedule.item.deleted',

	ScheduleUpdated = 'schedule.updated',

	SequenceCommentCreated = 'video_sequence_comment.created',
	SequenceCommentUpdated = 'video_sequence_comment.updated',
	SequenceCommentDeleted = 'video_sequence_comment.deleted',

	UserGroupUpdated = 'user_group.updated',
	UserGroupCreated = 'user_group.created',

	UserRelationCreated = 'userRelation.created',
	AccountRelationCreated = 'accountRelation.created',

	NotificationCreated = 'notification.created',

	ConfigVariable = 'config_var',
}

interface WebSocketConnection {
	client: WebSocketClient;
}

const WebSocketConnection = createContext<WebSocketConnection>({
	client: null,
});

interface WebSocketProviderProps {
	children: ReactNode | ReactNode[];
}

export function useWebSocketClient(): WebSocketClient {
	return useContext(WebSocketConnection).client;
}

interface WebSocketSubscriptionOptions {
	dependencies?: any[];
	condition?: boolean;
}

export function useWebSocketSubscription(
	channel: string,
	options: WebSocketSubscriptionOptions = {
		dependencies: [],
		condition: true,
	}
): void {
	const client = useWebSocketClient();
	const subscribe = () => client.subscribe(channel);

	const observeSubscription = () => {
		if (!options.condition || !client) return;

		subscribe();
		resume.off(subscribe);
		resume.on(subscribe);

		return () => {
			client.unsubscribe(channel);
			resume.off(subscribe);
		};
	};

	useEffect(observeSubscription, [
		channel,
		client,
		...(options.dependencies ?? []),
	]);
}

export function WebSocketProvider({
	children,
}: WebSocketProviderProps): JSX.Element {
	const { socketUrl } = useApiConfig();
	const [isUnstable, setUnstable] = useState<boolean>(false);

	const client = useMemo(() => {
		return new WebSocketClient<MessageType>(socketUrl, {
			onUnstableConnection: () => {
				tlog.error('Too many retries to establish websocket connection.');
			},
			onReconnect: () => {
				fetchSubscriptionTokens(client.getSubscribedChannels());
			},
		});
	}, [socketUrl]);

	const cleanCloseWebSocket = () => {
		client.disconnect();
	};

	const cleanOpenWebsocket = () => {
		if (!client.isConnected) {
			client.connect();
			fetchSubscriptionTokens(client.getSubscribedChannels());
		}
	};

	const errorMessageZIndex = useNewTopIndex();
	const account = useCurrentAccount();

	useComponentDidMount(
		() => {
			resume.on(cleanOpenWebsocket);
			resign.on(cleanCloseWebSocket);
		},
		() => {
			resume.off(cleanOpenWebsocket);
			resign.off(cleanCloseWebSocket);
		}
	);
	const fetchSubscriptionTokens = async (channels: string[]) => {
		const request = await sdk.postWithRetry(
			endpoints.AccountToken.CreatePushChannelTokens(account.id),
			{},
			channels
		);

		if (!request.ok) {
			const data = await request.text();

			tlog.error('failed to fetch tokens', {
				'http response': data,
				'http request': channels,
				status: request.status,
			});

			return;
		}

		const data = await request.json();

		client.send({
			type: 'setSubscriptions',
			data: {
				clientId: account.id.toString(),
				channels: data,
			},
		});
	};

	client.onConnectionChange(() => {
		if (client.isUnstable) {
			setUnstable(true);
		} else {
			setUnstable(false);
		}
	});

	// @NOTE Request subscription tokens for current client when subscriptions change
	client.onSubscriptionChange(fetchSubscriptionTokens);

	// Called on WebSocket MessageEvent unless a custom MessageType handler is set.
	// Example: client.onMessage(MessageType.Custom, (message) => ...);
	client.processMessage((type: MessageType, message: any) => {
		switch (type) {
			case MessageType.NotificationCreated:
				return actions.notifications.setHasUnread();
			case MessageType.AccountRelationCreated:
				return normalizedDispatch(
					message.data,
					AccountRelation.normalizr()
				)(store.dispatch);
			case MessageType.UserGroupUpdated:
				return normalizedDispatch(
					message.data,
					Membership.normalizr()
				)(store.dispatch);
			case MessageType.UserGroupCreated:
				return normalizedDispatch(
					message.data,
					Membership.normalizr()
				)(store.dispatch);
		}
	});

	return (
		<WebSocketConnection.Provider value={{ client }}>
			{isUnstable &&
				createPortal(
					<div
						className={css.errorMessage}
						style={{ zIndex: errorMessageZIndex }}>
						<Icon name="info-circle" />
						<span>{t('Connection lost, retrying...')}</span>
					</div>,
					document.querySelector('#dialog-portal-container')
				)}
			{children}
		</WebSocketConnection.Provider>
	);
}
