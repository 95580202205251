import { JSX } from 'react';
import { useT } from '@transifex/react';

import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';

import * as css from './styles.css';

interface AutoSaveMessageProps {
	event: models.event.Event;
	eventUsers: models.eventUser.EventUser[];
}

export default function AutoSaveMessage({
	event,
	eventUsers,
}: AutoSaveMessageProps): JSX.Element {
	const t = useT();

	let lastUpdated = 0;
	let attendanceReporter: models.user.User = null;

	eventUsers.map((eventUser) => {
		if (eventUser.attendanceLastUpdated > lastUpdated) {
			lastUpdated = eventUser.attendanceLastUpdated;
			attendanceReporter = eventUser.attendanceReporter;
		}
	});

	const readableDate = new Date(lastUpdated * 1000).toLocaleString(
		DateTime.getLocale()
	);

	if (!models.event.hasStarted(event) || !lastUpdated) {
		return null;
	}

	if (!models.event.hasEnded(event)) {
		return (
			<p className={css.attendanceSaveMessage}>
				{t(`Attendance will be auto saved after the event ends.`)}
			</p>
		);
	}

	if (attendanceReporter) {
		return (
			<p className={css.attendanceSaveMessage}>
				{t(`Saved {date} by {name}`, {
					date: readableDate,
					name: models.user.fullName(attendanceReporter),
				})}
			</p>
		);
	}

	return (
		<p className={css.attendanceSaveMessage}>
			{t(`Auto-saved {date}`, { date: readableDate })}
		</p>
	);
}
