import { t } from '@transifex/native';
import { JSX, Fragment, PointerEvent } from 'react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as arrays from 'pkg/arrays';
import { recursiveKeysOf } from 'pkg/objects';

import { Spinner } from 'components/loaders/spinner';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import useTableSet from 'design/table/use_table_set';
import * as Table from 'design/table';

import * as css from './style.css';

interface GroupNode {
	selected: number[];
	select: (groupIds: number[]) => void;
	deselect: (groupIds: number[]) => void;
}

interface GroupTreeProps extends GroupNode {
	rootGroupId: number;
}

export default function GroupTree({
	rootGroupId,
	selected,
	select,
	deselect,
}: GroupTreeProps): JSX.Element {
	const { record: root, isLoading: isLoadingRoot } =
		useEndpoint<models.group.Group>(endpoints.Groups.Show(rootGroupId), {
			queryParams: new URLSearchParams({
				recursive: '1',
				include_sub_group_count: '1',
			}),
		});

	const { records: children, isLoading: isLoadingChildren } =
		useCollection<models.group.Group>(
			endpoints.Groups.ShowChildren(rootGroupId),
			{
				queryParams: new URLSearchParams({
					recursive: '1',
					include_sub_group_count: '1',
				}),
			}
		);

	const nodes = models.group.buildGroupTree(
		root,
		children
	) as models.group.Group[];

	const tree = useTableSet<models.group.Group>(nodes, {
		defaultSortKey: 'name',
		pagination: false,
		expandOn: 'children',
		defaultOpenThreshold: 3,
		columns: [
			{
				content: t('Name'),
				columnKey: 'name',
				renderItemWith: (group: models.group.Group) => {
					return (
						<GroupBranch
							group={group}
							selected={selected}
							select={select}
							deselect={deselect}
						/>
					);
				},
			},
			{
				content: t('Groups'),
				columnKey: 'children',
				align: 'center',
				width: 'max-content',
				renderItemWith: (group: models.group.Group) => {
					const numChildren = group?.totalChildGroupCount || 0;

					return (
						<Table.Cell>
							{numChildren !== 0 && (
								<span className={css.cell}>{numChildren}</span>
							)}
						</Table.Cell>
					);
				},
			},
		],
	});

	if (isLoadingRoot || isLoadingChildren) {
		return <Spinner />;
	}

	return <Fragment>{tree.Table}</Fragment>;
}

interface GroupBranchProps extends GroupNode {
	group: models.group.Group;
}

function GroupBranch({
	group,
	selected,
	select,
	deselect,
}: GroupBranchProps): JSX.Element {
	const isSelected = selected.includes(group.id);

	let childGroupIds: number[] = [];

	if (group.children?.length > 0) {
		childGroupIds = recursiveKeysOf(group, {
			key: 'id',
			on: 'children',
		}) as number[];
	}

	const hasSelectedChildren =
		arrays.intersects(selected, childGroupIds).length > 0;

	const handleSelect = () => {
		if (selected.includes(group.id)) {
			deselect([group.id]);
		} else {
			if (childGroupIds.length > 0) {
				select([group.id, ...childGroupIds]);
			} else {
				select([group.id]);
			}
		}
	};

	const handleDeselectGroupChildren = (
		event: PointerEvent<HTMLTableCellElement>
	) => {
		event.stopPropagation();

		deselect(childGroupIds);
	};

	return (
		<Table.Cell onClick={handleSelect}>
			<Row
				align="center"
				justify="start"
				justifyContent="start"
				columns="auto 1fr auto"
				className={css.row}>
				<Input.Control
					standalone
					type="checkbox"
					value={group.id}
					checked={isSelected}
					onChange={handleSelect}
				/>
				<span>{group.name}</span>
				{hasSelectedChildren && (
					<span onClick={handleDeselectGroupChildren} className={css.deselect}>
						{t('Deselect child groups')}
					</span>
				)}
			</Row>
		</Table.Cell>
	);
}
