import { JSX, Fragment, useContext, useState } from 'react';
import { T, useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCollection } from 'pkg/api/use_collection';

import EventInformation from 'routes/event/create/EventInformation';
import UserList from 'routes/event/components/UserList';
import MatchDetails from 'routes/event/create/MatchDetails';
import PhysicalStrain from 'routes/event/components/PhysicalStrain';
import { InvitesNotice } from 'routes/event/components/Form';
import { EventFormContext } from 'routes/event/create';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import { useUserSelectModal } from 'components/user-select-modal';

import SelectGroups from 'components/organization/SelectGroups';
import Column from 'components/layout/column';
import Section from 'components/form/Section';
import { UseAttachmentsResults } from 'components/attachment/hooks/useAttachments';
import { ColumnDivider } from 'components/form/Divider';

import Button from 'design/button';

import * as css from './styles.css';

interface EventFormProps {
	useAttachments: UseAttachmentsResults;
}

const EventForm = ({ useAttachments }: EventFormProps): JSX.Element => {
	const t = useT();

	const [showSelectGroups, setShowSelectGroups] = useState(false);
	const handleGroupClick = () => setShowSelectGroups(true);

	const currentGroup = useCurrentGroup();

	const EventContext = useContext(EventFormContext);

	const handleGroupSelect = (groupIds: number[]) =>
		EventContext.setFormState({ groupId: groupIds[0] });
	const handleCloseGroupModal = () => setShowSelectGroups(false);

	const { selectedUsers, sendInvites } = EventContext.formState;
	const ignoredUserIds = selectedUsers.map((user: models.user.User) => user.id);

	const organizerUserIds = Object.keys(EventContext.formState.organizer).map(
		(id) => Number.parseInt(id, 10)
	);

	const organizers = selectedUsers.filter((user) =>
		organizerUserIds.includes(user.id)
	);

	const participants = selectedUsers.filter(
		(user) => !organizerUserIds.includes(user.id)
	);

	const numOrganizers = Object.keys(EventContext.formState.organizer).length;
	const hasOrganizers = numOrganizers > 0;
	const numParticipants =
		EventContext.formState.selectedUsers.length - numOrganizers;
	const hasParticipants = numParticipants > 0;

	const setSelectedOrganizers = (
		users: models.user.User[],
		mergePrevious: boolean = true
	) => {
		const next = mergePrevious ? [...selectedUsers, ...users] : users;
		const nextOrganizerUserIds = users.map((user) => user.id);

		const organizerIds: number[] = [
			...new Set([
				...nextOrganizerUserIds,
				...Object.keys(EventContext.formState.organizer).map((id) =>
					Number.parseInt(id, 10)
				),
			]),
		];

		const organizer = organizerIds.reduce(
			(memo, userId) => ({
				...memo,
				[userId]: true,
			}),
			{}
		);

		EventContext.setFormState({
			selectedUsers: next,
			organizer,
		});
	};

	const removeOrganizer = (userId: number) => {
		const next = selectedUsers.filter((user) => user.id !== userId);
		const nextOrganizer = { ...EventContext.formState.organizer };
		delete nextOrganizer[userId];

		EventContext.setFormState({
			selectedUsers: next,
			organizer: nextOrganizer,
		});
	};

	const setSelectedParticipants = (
		users: models.user.User[],
		mergePrevious: boolean = true
	) => {
		const next = mergePrevious ? [...selectedUsers, ...users] : users;

		EventContext.setFormState({
			selectedUsers: next,
		});
	};

	const removeParticipant = (userId: number) => {
		const next = selectedUsers.filter((user) => user.id !== userId);

		EventContext.setFormState({
			selectedUsers: next,
		});
	};

	const organizersSelect = useUserSelectModal({
		title: t('Add organizers'),
		saveLabel: t('Add organizers'),
		ignoredUserIds,
		defaultActiveTabId: 'admin',
		onSave: (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedOrganizers(users);
			}
		},
	});

	const participantsSelect = useUserSelectModal({
		title: t('Add participants'),
		saveLabel: t('Add participants'),
		ignoredUserIds,
		defaultActiveTabId: 'user',
		onSave: (users: models.user.User[]) => {
			if (users.length > 0) {
				setSelectedParticipants(users);
			}
		},
	});

	const makeOrganizer = (userId: number) => {
		const nextOrganizer = {
			...EventContext.formState.organizer,
			[userId]: true,
		};

		EventContext.setFormState({
			organizer: nextOrganizer,
		});
	};

	const makeParticipant = (userId: number) => {
		const nextOrganizer = { ...EventContext.formState.organizer };
		delete nextOrganizer[userId];

		EventContext.setFormState({
			organizer: nextOrganizer,
		});
	};

	if (
		EventContext.formState.type !== '' &&
		EventContext.formState.sendInvites === null
	) {
		if (EventContext.formState.type === 'practice') {
			EventContext.setFormState({ ['sendInvites']: false });
		} else {
			EventContext.setFormState({ ['sendInvites']: true });
		}
	}

	const { record: selectedGroup } = useEndpoint<models.group.Group>(
		endpoints.Groups.Show(EventContext.formState.groupId)
	);

	const { records: rootChildren } = useCollection<models.group.Group>(
		endpoints.Groups.ShowChildren(currentGroup.id)
	);

	const content = (
		<Fragment>
			<div>
				<div className={css.title}>
					<T
						_str="New event in: {group_name}"
						group_name={
							<div
								onClick={rootChildren.length > 0 && handleGroupClick}
								className={css.displayList}>
								{selectedGroup.name}
							</div>
						}
					/>
				</div>

				<Column spacing={styles.spacing._7}>
					<EventInformation useAttachments={useAttachments} />
					{EventContext.formState.type === 'match' && <MatchDetails />}
					{(EventContext.formState.type === 'match' ||
						EventContext.formState.type === 'practice') &&
						EventContext.formState.rRuleString === '' && <PhysicalStrain />}
				</Column>
			</div>
			<LargeScreen>
				<ColumnDivider />
			</LargeScreen>

			<Column spacing={styles.spacing._8}>
				<Section
					title={t('Organizers ({num})', {
						num: numOrganizers,
					})}
					hideDivider>
					<Column spacing={styles.spacing._6}>
						{hasOrganizers && (
							<UserList
								selectedUsers={organizers}
								onRemove={removeOrganizer}
								onMakeParticipant={makeParticipant}
							/>
						)}

						<Column spacing={styles.spacing._3}>
							<Button
								label={t('Add organizers')}
								secondary
								icon="add"
								onClick={organizersSelect.open}
								testid="events.invite.add_organizers"
							/>
							{sendInvites && (
								<InvitesNotice>
									{EventContext.formState.sendInvites
										? t('Invites will be sent to {num} organizers', {
												num: numOrganizers,
											})
										: t('All organizers will be added as Going to this event')}
								</InvitesNotice>
							)}
						</Column>
					</Column>
				</Section>

				<Section
					title={t('Participants ({num})', {
						num: numParticipants,
					})}
					hideDivider>
					<Column spacing={styles.spacing._6}>
						{hasParticipants && (
							<UserList
								selectedUsers={participants}
								onRemove={removeParticipant}
								onMakeOrganizer={makeOrganizer}
							/>
						)}

						<Column spacing={styles.spacing._3}>
							<Button
								label={t('Add participants')}
								secondary
								icon="add"
								onClick={participantsSelect.open}
								testid="events.invite.add_participants"
							/>
							{sendInvites && (
								<InvitesNotice>
									{EventContext.formState.sendInvites
										? t('Invites will be sent to {num} participants', {
												num: numParticipants,
											})
										: t(
												'All participants will be added as Going to this event'
											)}
								</InvitesNotice>
							)}
						</Column>
					</Column>
				</Section>
			</Column>
		</Fragment>
	);

	return (
		<Fragment>
			<LargeScreen>{content}</LargeScreen>
			<SmallScreen>
				<Column spacing={styles.spacing._7}>{content}</Column>
			</SmallScreen>
			{organizersSelect.Component}
			{participantsSelect.Component}
			{showSelectGroups && (
				<SelectGroups
					data={{ name: 'group', values: [currentGroup.id] }}
					onSelect={handleGroupSelect}
					onCloseModal={handleCloseGroupModal}
					singleSelect
				/>
			)}
		</Fragment>
	);
};

export default EventForm;
