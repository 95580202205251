import { JSX } from 'react';
import { t } from '@transifex/native';

import { ISO3166Alpha2Codes } from 'pkg/countries';
import * as models from 'pkg/api/models';

import DataRow from 'containers/user/profile/info-contact/DataRow';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import { DataRowProps } from 'components/user-address/DataList';

interface BaseInformationProps {
	user: models.user.User;
}

export default function BaseInformation({
	user,
}: BaseInformationProps): JSX.Element {
	const labels = {
		name: t(`Name`),
		age: t(`Age`),
		birthDate: t(`Birth date`),
		sex: t(`Gender`),
		height: t(`Height`),
		nationality: t(`Nationality`),
		lokSwedishPersonalId: t(`Personal ID Number`),
	};

	const rows: DataRowProps[] = [];

	// Name
	rows.push({
		label: labels.name,
		value: models.user.fullName(user),
	});

	// Age and birth date
	rows.push({
		label: labels.age,
		value: models.user.age(user),
	});
	rows.push({
		label: labels.birthDate,
		value: user.birthDate,
	});

	// Gender
	rows.push({
		label: labels.sex,
		value: models.user.translatedSexString(user.sex, ''),
	});

	// Height
	rows.push({
		label: labels.height,
		value: models.user.height(user),
	});

	// Nationality
	rows.push({
		label: labels.nationality,
		value: user.nationality
			? ISO3166Alpha2Codes().find((c) => c.code === user.nationality)
					.nationality
			: null,
	});

	// Personal ID
	if (models.group.isLOKActive(user.organization)) {
		rows.push({
			label: labels.lokSwedishPersonalId,
			value: user.lokSwedishPersonalId,
		});
	}

	if (rows.length === 0) {
		return null;
	}

	return (
		<Column>
			<SectionTitle icon="person">{t(`Base information`)}</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					{rows.map((row, i) => (
						<DataRow key={i} label={row.label} value={row.value} />
					))}
				</Card.Body>
			</Card.Base>
		</Column>
	);
}
