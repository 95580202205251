import { JSX, CSSProperties, Fragment } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useCurrentOrganization } from 'pkg/identity';
import * as arrays from 'pkg/arrays';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useQueryState } from 'pkg/hooks/query-state';
import { localeIncludes } from 'pkg/strings';

import Sessions from 'routes/training/library/group/Sessions';
import Exercises from 'routes/training/library/group/Exercises';
import CurrentFilter from 'routes/training/library/CurrentFilter';
import { useTrainingLibraryFilters } from 'routes/training/library/hooks';

import Avatar from 'components/avatar';

import { Spinner } from 'components/loaders/spinner';
import * as ActionBar from 'components/layout/ActionBar';
import ContentFilter from 'components/library/ContentFilter';
import { useContentTypeFilter } from 'components/library/hooks';
import SortOptions from 'components/library/SortOptions';
import TagSearchInput from 'components/form/TagSearchInput';
import EmptySearch from 'components/library/EmptySearch';

import * as ContextMenu from 'design/context_menu';
import OutlinePlaceholder from 'design/placeholders/outline';

const Hero = styled.div`
	--color: var(--palette-gray-500);

	padding: var(--spacing-9) var(--spacing-8);
	background-color: var(--color);
	background-image: linear-gradient(
		135deg,
		hsla(0, 0%, 0%, 0.5),
		hsla(0, 0%, 0%, 0.1)
	);
	background-position: 50% 50%;
	background-size: cover;
	color: var(--palette-white);
	display: grid;
	grid-auto-flow: row;
	justify-items: start;
	gap: var(--spacing-4);

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-9) var(--spacing-6);
	}

	&[data-is-branded='true'] {
		background-blend-mode: normal;
		box-shadow: inset 0 0 30px hsla(0, 0%, 0%, 0.2);
	}

	&[data-has-cover-image='true'] {
		position: relative;
		overflow: hidden;
	}
`;

const GroupProfileImage = styled.img`
	width: 220px;
	height: 220px;
	opacity: 0.2;
	filter: grayscale(1);
	transform: translate(-50%, -50%);
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 10;
	pointer-events: none;
`;

const Title = styled.span`
	padding: var(--spacing-1) var(--spacing-2);
	background: var(--palette-white);
	color: var(--palette-gray-800);
	font-size: var(--font-size-sm);
	border-radius: var(--radius-2);
	display: inline-block;
`;

const Heading = styled.h1`
	display: inline-grid;
	grid-auto-flow: column;
	gap: var(--spacing-5);
	place-content: center;
	place-items: center;
`;

const Byline = styled.div`
	display: grid;
	grid-template-columns: 25px 1fr;
	grid-template-rows: repeat(2, auto);
	column-gap: var(--spacing-3);
	row-gap: var(--spacing-1);
	align-items: center;
	justify-content: center;
	font-size: var(--font-size-xs);

	div:first-child {
		grid-area: 1 / 1 / 3 / 2;
	}
`;

const Wrapper = styled.div`
	margin: 0 auto;
	max-width: 1800px;
	padding: var(--spacing-8);
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-8);

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-6);
	}
`;

type SortOption = 'title' | 'date_added';

type SortDirection = 'asc' | 'desc';

function sortExercisesByTitle(
	exercises: models.trainingCollection.TrainingCollectionExercise[],
	direction: SortDirection
): models.trainingCollection.TrainingCollectionExercise[] {
	const sorted = exercises.sort(
		(
			a: models.trainingCollection.TrainingCollectionExercise,
			b: models.trainingCollection.TrainingCollectionExercise
		) => a.exercise.title.length - b.exercise.title.length
	);

	if (direction === 'desc') {
		return sorted.reverse();
	}

	return sorted;
}

function sortExercisesByDateAdded(
	exercises: models.trainingCollection.TrainingCollectionExercise[],
	direction: SortDirection
): models.trainingCollection.TrainingCollectionExercise[] {
	const sorted = exercises.sort(
		(
			a: models.trainingCollection.TrainingCollectionExercise,
			b: models.trainingCollection.TrainingCollectionExercise
		) => a.createdAt - b.createdAt
	);

	if (direction === 'desc') {
		return sorted.reverse();
	}

	return sorted;
}

function sortExercisesBy(
	sessions: models.trainingCollection.TrainingCollectionExercise[],
	sortOption: SortOption,
	direction: SortDirection
): models.trainingCollection.TrainingCollectionExercise[] {
	switch (sortOption) {
		case 'title':
			return sortExercisesByTitle(sessions, direction);
		case 'date_added':
			return sortExercisesByDateAdded(sessions, direction);
	}
}

function filterExercisesByTitle(
	exercises: models.trainingCollection.TrainingCollectionExercise[],
	partialTitle: string
): models.trainingCollection.TrainingCollectionExercise[] {
	if (exercises?.length === 0) return [];

	return exercises.filter(
		(item: models.trainingCollection.TrainingCollectionExercise) =>
			localeIncludes(item.exercise.title, partialTitle)
	);
}

function filterExercisesByTags(
	exercises: models.trainingCollection.TrainingCollectionExercise[],
	tags: string[]
): models.trainingCollection.TrainingCollectionExercise[] {
	if (exercises?.length === 0) return [];

	return exercises.filter(
		(item: models.trainingCollection.TrainingCollectionExercise) => {
			const itemTags = item.exercise.tags?.map(
				(tag: models.tag.Tag) => tag.name
			);

			if (!itemTags) {
				return false;
			}

			return arrays.intersects<string>(itemTags, tags).length > 0;
		}
	);
}

function sortSessionsByTitle(
	sessions: models.trainingCollection.TrainingCollectionSession[],
	direction: SortDirection
): models.trainingCollection.TrainingCollectionSession[] {
	const sorted = sessions.sort(
		(
			a: models.trainingCollection.TrainingCollectionSession,
			b: models.trainingCollection.TrainingCollectionSession
		) => a.session.title.length - b.session.title.length
	);

	if (direction === 'desc') {
		return sorted.reverse();
	}

	return sorted;
}

function sortSessionsByDateAdded(
	sessions: models.trainingCollection.TrainingCollectionSession[],
	direction: SortDirection
): models.trainingCollection.TrainingCollectionSession[] {
	const sorted = sessions.sort(
		(
			a: models.trainingCollection.TrainingCollectionSession,
			b: models.trainingCollection.TrainingCollectionSession
		) => a.createdAt - b.createdAt
	);

	if (direction === 'desc') {
		return sorted.reverse();
	}

	return sorted;
}

function sortSessionsBy(
	sessions: models.trainingCollection.TrainingCollectionSession[],
	sortOption: SortOption,
	direction: SortDirection
): models.trainingCollection.TrainingCollectionSession[] {
	switch (sortOption) {
		case 'title':
			return sortSessionsByTitle(sessions, direction);
		case 'date_added':
			return sortSessionsByDateAdded(sessions, direction);
	}
}

function filterSessionsByTitle(
	sessions: models.trainingCollection.TrainingCollectionSession[],
	partialTitle: string
): models.trainingCollection.TrainingCollectionSession[] {
	if (sessions?.length === 0) return [];

	return sessions.filter(
		(item: models.trainingCollection.TrainingCollectionSession) =>
			localeIncludes(item.session.title, partialTitle)
	);
}

function filterSessionsByTags(
	sessions: models.trainingCollection.TrainingCollectionSession[],
	tags: string[]
): models.trainingCollection.TrainingCollectionSession[] {
	if (sessions?.length === 0) return [];

	return sessions.filter(
		(item: models.trainingCollection.TrainingCollectionSession) => {
			const itemTags = item.session.tags?.map(
				(tag: models.tag.Tag) => tag.name
			);

			return arrays.intersects<string>(itemTags, tags).length > 0;
		}
	);
}

export default function Collection(): JSX.Element {
	const { collectionId } = useCurrentRoute();
	const org = useCurrentOrganization();
	const contentType = useContentTypeFilter();
	const filters = useTrainingLibraryFilters();
	const qs = useQueryState();

	const { isLoading, record: collection } =
		useEndpoint<models.trainingCollection.TrainingCollection>(
			endpoints.TrainingCollections.Show(org.id, collectionId)
		);

	const group = collection?.group;

	const collectionExercises =
		useCollection<models.trainingCollection.TrainingCollectionExercise>(
			endpoints.TrainingCollections.ShowExercises(org.id, collectionId),
			{
				showAllResults: true,
			}
		);

	let collectionExercisesRecords = collectionExercises.records;

	const collectionSessions =
		useCollection<models.trainingCollection.TrainingCollectionSession>(
			endpoints.TrainingCollections.ShowSessions(org.id, collectionId),
			{
				showAllResults: true,
			}
		);

	let collectionSessionsRecords = collectionSessions.records;

	if (qs.has('sort')) {
		const [sortBy, sortDirection] = (qs.get('sort') as string).split(':');

		collectionExercisesRecords = sortExercisesBy(
			collectionExercisesRecords,
			sortBy as SortOption,
			sortDirection as SortDirection
		);

		collectionSessionsRecords = sortSessionsBy(
			collectionSessionsRecords,
			sortBy as SortOption,
			sortDirection as SortDirection
		);
	}

	if (filters.hasTitleFilter) {
		collectionExercisesRecords = filterExercisesByTitle(
			collectionExercisesRecords,
			filters.title
		);

		collectionSessionsRecords = filterSessionsByTitle(
			collectionSessionsRecords,
			filters.title
		);
	}

	if (filters.hasTagsFilter) {
		collectionExercisesRecords = filterExercisesByTags(
			collectionExercisesRecords,
			filters.getTags()
		);

		collectionSessionsRecords = filterSessionsByTags(
			collectionSessionsRecords,
			filters.getTags()
		);
	}

	const exercises = collectionExercisesRecords.map(
		(item: models.trainingCollection.TrainingCollectionExercise) =>
			item.exercise
	);

	const sessions = collectionSessionsRecords.map(
		(item: models.trainingCollection.TrainingCollectionSession) => item.session
	);

	const hasContent =
		(exercises.length > 0 && contentType.exercises) ||
		(sessions.length > 0 && contentType.sessions);

	if (
		isLoading ||
		collectionExercises.isLoading ||
		collectionSessions.isLoading
	) {
		return <Spinner />;
	}

	let title = null;

	if (collection.groupId) {
		title = t('Team');
	}

	if (collection.groupId && collection.inherit) {
		title = t('Club');
	}

	let primaryColor: string = styles.palette.gray[500];

	if (group) {
		primaryColor = models.group.getPrimaryColorStylesheetString(group);
	}

	let emptyState = (
		<OutlinePlaceholder
			icon="add-folder"
			title={t('Empty collection')}
			description={t('There are no sessions or exercises in this collection.')}
		/>
	);

	if (filters.isFiltered) {
		emptyState = (
			<EmptySearch
				icon="search"
				heading={t(`We couldn't find anything matching current filters…`)}
			/>
		);
	}

	return (
		<Fragment>
			<Hero
				data-is-branded={!!collection.groupId}
				data-has-cover-image={!!collection?.group?.profileImageUrl?.length}
				style={
					{
						'--color': primaryColor,
					} as CSSProperties
				}>
				{title && <Title>{title}</Title>}
				<Heading>{collection.name}</Heading>
				<Byline>
					<Avatar author={collection.author} size={25} />
					<div>{t('Created by')}</div>
					<div>{collection.author.name}</div>
				</Byline>
				{!!collection?.group?.profileImageUrl?.length && (
					<GroupProfileImage src={collection.group.profileImageUrl} />
				)}
			</Hero>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<TagSearchInput />
				</ActionBar.PrimaryAction>
				<ContentFilter
					actions={[
						{
							label: t('Exercises'),
							icon: <ContextMenu.ItemIcon name="tactic" />,
							type: 'exercises',
						},
						{
							label: t('Sessions'),
							icon: <ContextMenu.ItemIcon name="schedule" />,
							type: 'sessions',
						},
					]}
				/>
				<SortOptions
					options={[
						{
							label: t('Sort options'),
							ascLabel: t('{title}, ascending', {
								title: t('Title'),
							}),
							descLabel: t('{title}, descending', {
								title: t('Title'),
							}),
							queryParam: 'title',
						},
						{
							label: t('Date added'),
							ascLabel: t('Most recent first'),
							descLabel: t('Oldest first'),
							queryParam: 'date_added',
						},
					]}
				/>
			</ActionBar.FilterBar>
			<Wrapper>
				<CurrentFilter />
				{hasContent ? (
					<Fragment>
						{contentType.exercises && (
							<Exercises
								title={t('Exercises')}
								items={exercises}
								removeRecord={collectionExercises.removeRecord}
							/>
						)}

						{contentType.sessions && (
							<Sessions
								title={t('Sessions')}
								items={sessions}
								removeRecord={collectionSessions.removeRecord}
							/>
						)}
					</Fragment>
				) : (
					emptyState
				)}
			</Wrapper>
		</Fragment>
	);
}
