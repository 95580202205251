import styled from 'styled-components';
import { JSX, useEffect, useState, Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Icon from 'components/icon';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useClipState } from 'components/video-analytics/ClipState';
import { IS_EDITING, IS_OUTSIDE_CLIP } from 'components/annotations/constants';

import * as ContextMenu from 'design/context_menu';

const Toggle = styled.div`
	position: relative;
	background: var(--palette-orange-500);
	width: 19px;
	height: 19px;
	border-radius: 50%;
	transform: translateX(-50%) scale(0);
	margin-left: 0.5px;
	z-index: ${styles.zIndex.videoAnalytics + 15};
	transition: transform 150ms ease-in-out;
	text-align: center;
	padding-top: 1px;

	&:hover {
		background: var(--palette-orange-700);
	}

	.${IS_EDITING} & {
		transform: translateX(-50%) scale(1);
	}

	.${IS_OUTSIDE_CLIP} & {
		opacity: 0.8;
		cursor: not-allowed;
	}
`;

interface PlaybackKeyframeButtonProps {
	disabled?: boolean;
}

export default function PlaybackKeyframeButton({
	disabled,
}: PlaybackKeyframeButtonProps): JSX.Element {
	const { controller } = usePlayerState();
	const clipState = useClipState();
	const { setAnnotations } = clipState;

	const { currentTime } = usePlaybackState();
	const currentMs = currentTime * 1000;

	const annotations = clipState.getParsedAnnotations();
	const keyframes = annotations?.playback?.keyframes || [];

	const addPlaybackKeyframe = (options: any) => {
		controller.pause();
		const playback: any = annotations.playback || {};

		playback.keyframes = playback.keyframes || [];

		const newKeyframe = {
			time: currentTime * 1000,
			...options,
		};

		playback.keyframes.push(newKeyframe);

		setAnnotations({ ...annotations, playback });
	};

	const [currentSpeed, setCurrentSpeed] = useState(false);
	useEffect(() => {
		// check if there is a speed change at current position
		const speed = keyframes.find(
			(frame) =>
				frame.action === 'speed' &&
				frame.time < currentMs &&
				frame.time + frame.duration > currentMs
		);

		setCurrentSpeed(!!speed);
	}, [currentTime]);

	const addPause = () => {
		addPlaybackKeyframe({ action: 'pause' });
	};
	const addPause1s = () => {
		addPlaybackKeyframe({ action: 'pause', duration: 1000 });
	};
	const addPause3s = () => {
		addPlaybackKeyframe({ action: 'pause', duration: 3000 });
	};
	const addSlomo1s = () => {
		addPlaybackKeyframe({
			action: 'speed',
			speed: 0.5,
			duration: 1000,
		});
	};
	const addSlomo3s = () => {
		addPlaybackKeyframe({
			action: 'speed',
			speed: 0.5,
			duration: 3000,
		});
	};
	const addhighspeed1s = () => {
		addPlaybackKeyframe({
			action: 'speed',
			speed: 2,
			duration: 1000,
		});
	};
	const addhighspeed3s = () => {
		addPlaybackKeyframe({
			action: 'speed',
			speed: 2,
			duration: 3000,
		});
	};

	return (
		<ContextMenu.Menu
			togglePosition={{
				left: `50%`,
				top: `10px`,
			}}
			toggleWith={
				<Toggle>
					<Icon name="add" fill={styles.palette.white} />
				</Toggle>
			}>
			<ContextMenu.Heading>Time control</ContextMenu.Heading>
			{disabled ? (
				<ContextMenu.Label>
					{t('Move the playhead inside the clip to add time control markers.')}
				</ContextMenu.Label>
			) : (
				<Fragment>
					<ContextMenu.Item onClick={addPause}>
						{t('Add pause')}
					</ContextMenu.Item>
					<ContextMenu.Item onClick={addPause1s}>
						{t('Pause, resume after {num}s', {
							num: 1,
						})}
					</ContextMenu.Item>
					<ContextMenu.Item onClick={addPause3s}>
						{t('Pause, resume after {num}s', {
							num: 3,
						})}
					</ContextMenu.Item>
					<ContextMenu.Divider tight />
					{currentSpeed && (
						<ContextMenu.Label>
							{t(
								'You can only add one speed change at a time, move the playhead outside current speed change to add more.'
							)}
						</ContextMenu.Label>
					)}
					<ContextMenu.Item disabled={currentSpeed} onClick={addSlomo1s}>
						{t('Slowmotion for {num}s', {
							num: 1,
						})}
					</ContextMenu.Item>
					<ContextMenu.Item disabled={currentSpeed} onClick={addSlomo3s}>
						{t('Slowmotion for {num}s', {
							num: 3,
						})}
					</ContextMenu.Item>
					<ContextMenu.Divider tight />
					<ContextMenu.Item disabled={currentSpeed} onClick={addhighspeed1s}>
						{t('High speed for {num}s', {
							num: 1,
						})}
					</ContextMenu.Item>
					<ContextMenu.Item disabled={currentSpeed} onClick={addhighspeed3s}>
						{t('High speed for {num}s', {
							num: 3,
						})}
					</ContextMenu.Item>
				</Fragment>
			)}{' '}
		</ContextMenu.Menu>
	);
}
