import { JSX } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as api from 'pkg/api';
import useConfirm from 'pkg/hooks/useConfirm';
import { useCollection } from 'pkg/api/use_collection';
import { useEventListener } from 'pkg/hooks/events';
import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import {
	MembershipRole,
	roleToTranslatedString,
} from 'pkg/api/models/membership';

import RelativeDateTime from 'components/RelativeDateTime';
import SectionTitle from 'components/SectionTitle';
import Avatar from 'components/avatar';

import * as UserCard from 'components/user/UserCard';
import Column from 'components/layout/column';

import Button from 'design/button';

import * as css from './styles.css';

interface UserItemProps {
	invite: models.accountInvite.AccountInvite;
	refreshPendingEmailInvites: () => void;
}

function UserItem({
	invite,
	refreshPendingEmailInvites,
}: UserItemProps): JSX.Element {
	const handleRevoke = useConfirm({
		message: t('Do you want to delete this invite?'),
		onConfirm: async () => {
			const [request] = await api.delete(
				endpoints.AccountInvites.Show(invite.id)
			);

			if (!request.ok) {
				actions.flashes.show({
					title: t('Something went wrong'),
					message: t(
						`If you think this error isn't supposed to happen, please contact 360Player support.`
					),
				});

				return;
			}

			actions.flashes.show({
				title: t('Invite succesfully revoked'),
			});

			refreshPendingEmailInvites();
		},
	});

	return (
		<UserCard.Base rows>
			<UserCard.User>
				<Avatar size={40} />

				<div>
					<strong className={css.email}>{invite.email}</strong>
					<span>
						{roleToTranslatedString(invite.role as MembershipRole)} -{' '}
						{t(`Sent`)} <RelativeDateTime dateTime={invite.created} hideTime />
					</span>
				</div>
			</UserCard.User>

			<UserCard.Actions noPadding>
				<Button caution label={t('Revoke')} onClick={handleRevoke} />
			</UserCard.Actions>
		</UserCard.Base>
	);
}

interface PendingEmailInvitesProps {
	groupId: number;
}

export default function PendingEmailInvites({
	groupId,
}: PendingEmailInvitesProps): JSX.Element {
	const {
		records,
		isLoading,
		refresh: refreshPendingEmailInvites,
	} = useCollection<models.accountInvite.AccountInvite>(
		endpoints.AccountInvites.FromGroup(groupId)
	);

	useEventListener('refresh-pending-email-invites', () =>
		refreshPendingEmailInvites()
	);

	if (isLoading || records.length === 0) {
		return null;
	}

	const pendingInvites = records.map(
		(invite: models.accountInvite.AccountInvite) => (
			<UserItem
				key={invite.id}
				invite={invite}
				refreshPendingEmailInvites={refreshPendingEmailInvites}
			/>
		)
	);

	return (
		<Column spacing={styles.spacing._4}>
			<SectionTitle>{t(`Sent invites`)}</SectionTitle>
			<Column spacing={styles.spacing._3}>{pendingInvites}</Column>
		</Column>
	);
}
