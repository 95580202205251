import { JSX, Dispatch, SetStateAction } from 'react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Filters } from 'pkg/filters/use_filters';
import { useCollection } from 'pkg/api/use_collection';

import OrganizationTable, {
	DialogProps,
} from 'routes/group/settings/OrganizationTable';

interface SearchedTreeProps {
	filters: Filters;
	dialog: DialogProps;
	setDialog: Dispatch<SetStateAction<DialogProps>>;
	rootGroup: models.group.Group;
}
export default function SearchedTree({
	filters,
	dialog,
	setDialog,
	rootGroup,
}: SearchedTreeProps): JSX.Element {
	const {
		records: childrenGroups,
		isLoading,
		refresh,
		removeRecord,
	} = useCollection<models.group.Group>(
		endpoints.Groups.SearchAll(rootGroup.id),
		{
			queryParams: filters.queryParam,
		}
	);

	return (
		<OrganizationTable
			rootGroup={rootGroup}
			groups={childrenGroups}
			dialog={dialog}
			setDialog={setDialog}
			isLoading={isLoading}
			refresh={refresh}
			removeRecord={removeRecord}
		/>
	);
}
