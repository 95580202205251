import { t } from '@transifex/native';
import { JSX, useState } from 'react';

import * as models from 'pkg/api/models';

import PlaylistModal from 'components/video-library/modals/Playlist';
import Group, { GroupWrapperProperties } from 'components/library/Group';
import { GroupItemAction } from 'components/library/GroupItem';
import GroupItemButton from 'components/library/GroupItemButton';
import VideoPlaylistGroupItem from 'components/library/group-items/VideoPlaylist';

export default function Playlists({
	title,
	items,
	hasNext,
	fetchNext,
	removeRecord,
	replaceRecord,
}: GroupWrapperProperties<models.videoPlaylist.VideoPlaylist>): JSX.Element {
	const [playlistId, setPlaylistId] = useState<number>(0);

	const closeModal = () => setPlaylistId(0);

	const handleEdit = (playlist: models.videoPlaylist.VideoPlaylist) => {
		setPlaylistId(playlist.id);
	};

	const handleDidSave = (playlist: models.videoPlaylist.VideoPlaylist) => {
		replaceRecord(playlist);
	};

	const handleDelete = async (playlist: models.videoPlaylist.VideoPlaylist) => {
		const success = await models.destroy(playlist);

		if (success) {
			removeRecord(playlist.id);
		}
	};

	return (
		<Group title={title}>
			{items.map((item: models.videoPlaylist.VideoPlaylist, n: number) => {
				const actions: GroupItemAction<models.videoPlaylist.VideoPlaylist>[] =
					[];

				if (models.canEdit(item)) {
					actions.push({
						icon: 'edit',
						label: t('Edit'),
						onClick: handleEdit,
					});
				}

				if (models.canDelete(item)) {
					actions.push({
						icon: 'delete',
						label: t('Delete'),
						confirm: t(
							`Do you want to delete "{name}"? This action cannot be undone.`,
							{
								name: item.title,
							}
						),
						onClick: handleDelete,
					});
				}

				return (
					<VideoPlaylistGroupItem
						key={`${item.id}:${n}`}
						item={item}
						actions={actions}
					/>
				);
			})}
			{hasNext && fetchNext && (
				<GroupItemButton label={t('Load more')} onClick={fetchNext} />
			)}

			{playlistId !== 0 && (
				<PlaylistModal
					playlistId={playlistId}
					onClose={closeModal}
					afterSave={handleDidSave}
				/>
			)}
		</Group>
	);
}
