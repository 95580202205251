import { JSX, Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { APP_SET_DARK_MODE } from 'pkg/actions/action-types';

import 'routes/kitchen_sink/formatters';

import store from 'pkg/store/createStore';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { RootState } from 'pkg/reducers';
import { useQueryState } from 'pkg/hooks/query-state';
import { cssClasses } from 'pkg/css/utils';

import Navigation, { KitchenSinkViews } from 'routes/kitchen_sink/navigation';
import { useNorrsken } from 'routes/kitchen_sink/components/norrsken';
import { usePostMessage } from 'routes/kitchen_sink/hooks';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';

import * as css from './styles.css';

export default function KitchenSink(): JSX.Element {
	const frameRef = useRef<HTMLIFrameElement>();
	const [frameWidth, setFrameWidth] = useState<string>('100%');
	const [isLoading, setLoading] = useState<boolean>(true);

	const qs = useQueryState();
	const view = qs.get('view') as KitchenSinkViews;

	const isDarkMode = useSelector((state: RootState) => state.app.darkMode);
	const isSmallScreen = frameWidth !== '100%';

	const entrypointPath = `/kitsink.html?view=${view}`;

	const postMessage = usePostMessage((data: string) => {
		if (data === 'mounted') {
			setLoading(false);
		}
	}, frameRef.current);

	useNorrsken({
		watch: [view],
	});

	useComponentDidMount(
		() => document.body.classList.add('kitchen-sink'),
		() => document.body.classList.remove('kitchen-sink')
	);

	useEffect(() => {
		setLoading(true);
	}, [view]);

	const handleToggleDarkMode = () => {
		store.dispatch({
			type: APP_SET_DARK_MODE,
			darkMode: !isDarkMode,
		});

		postMessage({ darkMode: !isDarkMode });
	};

	const handleToggleSmallScreen = () => {
		// @NOTE 375px seem to be the most common screen resolution as of 2023
		setFrameWidth(isSmallScreen ? '100%' : '375px');
	};

	return (
		<Fragment>
			{isLoading && (
				<div
					key="loading-idicator"
					className={cssClasses(css.loading, isLoading ? css.visible : null)}
				/>
			)}
			<Navigation />
			<LargeScreenContent.Wrapper disableScroll>
				<nav className={css.actionBar}>
					<Row
						columns="1fr 34px"
						align="center"
						onClick={handleToggleSmallScreen}
						className={css.action}>
						<span>Small Screen</span>
						<Input.Control
							standalone
							type="toggle"
							checked={frameWidth !== '100%'}
						/>
					</Row>
					<Row
						columns="1fr 34px"
						align="center"
						onClick={handleToggleDarkMode}
						className={css.action}>
						<span>Dark mode</span>
						<Input.Control standalone type="toggle" checked={isDarkMode} />
					</Row>
				</nav>
				<div className={css.canvas}>
					<iframe
						ref={frameRef}
						src={entrypointPath}
						className={css.frame}
						style={{ width: frameWidth }}
					/>
				</div>
			</LargeScreenContent.Wrapper>
		</Fragment>
	);
}
