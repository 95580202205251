import { t } from '@transifex/native';
import { JSX, Fragment, useEffect, useState } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import * as exerciseService from 'pkg/actions/services/exercises';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { destroy } from 'pkg/api/models/attachment';
import * as sdk from 'pkg/core/sdk';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { setConfirmNavigation } from 'pkg/router/utils';
import {
	useCurrentMembership,
	useCurrentOrganization,
	useCurrentUser,
} from 'pkg/identity';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Form, { FormPayload } from 'components/form/Form';
import * as ActionBar from 'components/layout/ActionBar';
import { Spinner } from 'components/loaders/spinner';
import { DRAWING_DATA_VERSION } from 'components/drawing/config';

import Button from 'design/button';

import { normalizeExerciseData } from './utils';
import ExerciseFormFields from './ExerciseFormFields';

interface EditExerciseProps {
	exerciseId: number;
	isInSession?: boolean;
	onCancel?: () => void;
	onSave?: (id: number) => void;
}

const EditExercise = ({
	exerciseId,
	onCancel,
	onSave,
}: EditExerciseProps): JSX.Element => {
	const membership = useCurrentMembership();
	const [isSaving, setIsSaving] = useState(false);
	const [tags, setTags] = useState(null);
	const [newAttachments, setNewAttachments] = useState([]);
	const [deletedAttachments, setDeletedAttachments] = useState([]);
	const [drawings, setDrawings] = useState({});
	const [deletedDrawings, setDeletedDrawings] = useState([]);
	const [thumbnail, setThumbnail] = useState(null);
	const userId = useCurrentUser().id;
	const org = useCurrentOrganization();

	// get existing data
	const { record: exercise, isLoading } = useEndpoint<models.exercise.Exercise>(
		exerciseId && endpoints.Exercises.Show(exerciseId)
	);

	useComponentDidMount(
		() => {
			setConfirmNavigation(
				t('Are you sure you want to leave without saving the exercise?', {
					_context: 'training_library',
				})
			);
		},
		() => {
			setConfirmNavigation(null);
		}
	);

	// load existing drawings into state
	useEffect(() => {
		if (exercise.exerciseDrawings) {
			// match existing drawings with state data structure
			const existingDrawings = exercise.exerciseDrawings.reduce(
				(prev: any, drawing: any) => {
					prev[drawing.id] = {
						...drawing,
						...JSON.parse(drawing.data),
					};

					// avoid duplicated data inside drawing.data when saving existing drawings to database
					delete prev[drawing.id].id;
					delete prev[drawing.id].data;

					return prev;
				},
				{}
			);

			setDrawings(existingDrawings);
		}
	}, [exercise]);

	const handleSave = async (data: FormPayload) => {
		// normalize form data
		const payload = normalizeExerciseData({ data, tags, thumbnail });

		if (!exercise.userId) {
			payload.userId = userId;
		}

		// save
		setIsSaving(true);
		await models.update(exercise, payload);

		// save or update drawings (ignore deleted drawings)
		for await (const [id, drawing] of Object.entries(drawings).filter(
			([id]) => !deletedDrawings.includes(id)
		)) {
			const payload = {
				exerciseId: exerciseId,
				version: DRAWING_DATA_VERSION,
				data: JSON.stringify(drawing),
			};

			if (id.startsWith('tempId-')) {
				// create new drawing
				await models.create(endpoints.Exercises.CreateDrawing(), payload);
			} else {
				// update existing drawing
				await sdk.patch(
					endpoints.Exercises.UpdateDrawing(Number.parseInt(id, 10)),
					{},
					payload
				);
			}
		}

		// delete drawings
		for await (const id of deletedDrawings) {
			await sdk.destroy(
				endpoints.Exercises.DeleteDrawing(Number.parseInt(id, 10))
			);
		}

		// link new attachments
		for await (const attachment of newAttachments) {
			await exerciseService.createExerciseAttachment(exerciseId, attachment.id);
		}

		// unlink deleted attachments
		for await (const id of deletedAttachments) {
			await exerciseService.deleteExerciseAttachment(exerciseId, id);
			await destroy(
				exercise.attachments.find((attachment) => attachment.id === id)
			);
		}

		// delete deleted thumbnail
		if (thumbnail === undefined && exercise.thumbnailAttachmentId) {
			sdk.destroy(`/attachments/${exercise.thumbnailAttachmentId}`);
		}

		setConfirmNavigation(null);
		if (onSave) {
			onSave(exerciseId);
		} else {
			pushState(routes.TrainingLibrary.Index(org.id, membership.groupId));
			setIsSaving(false);
		}
	};

	const [activeCollectionModal, setActiveCollectionModal] = useState(0);
	const toggleCollectionModal = () => {
		setActiveCollectionModal(activeCollectionModal ? 0 : exerciseId);
	};

	const deleteExistingDrawing = (id: number) => {
		const newDeleted = [...deletedDrawings];
		newDeleted.push(id);
		setDeletedDrawings(newDeleted);
	};

	const cancel = () => {
		setConfirmNavigation(null);
		onCancel();
	};

	const ActionBarActions = () => (
		<Fragment>
			<LargeScreen>
				<Button onClick={cancel}>{t('Cancel')}</Button>
				<Button primary type="submit" disabled={isSaving}>
					{t('Save')}
				</Button>
			</LargeScreen>
			<SmallScreen>
				<Button large block onClick={cancel}>
					{t('Cancel')}
				</Button>
				<Button primary block large type="submit" disabled={isSaving}>
					{t('Save')}
				</Button>
			</SmallScreen>
		</Fragment>
	);

	return (
		<Form onSubmit={handleSave}>
			<ActionBar.SaveBar maxWidth={PageWidths.WIDE}>
				<ActionBarActions />
			</ActionBar.SaveBar>
			{isLoading ? (
				<Spinner />
			) : (
				<ExerciseFormFields
					exercise={exercise}
					toggleCollectionModal={toggleCollectionModal}
					collectionModalExerciseId={activeCollectionModal}
					ActionBarActions={ActionBarActions}
					onTagUpdate={setTags}
					newAttachments={newAttachments}
					setNewAttachments={setNewAttachments}
					deletedAttachments={deletedAttachments}
					setDeletedAttachments={setDeletedAttachments}
					drawings={drawings}
					setDrawings={setDrawings}
					deletedDrawings={deletedDrawings}
					deleteExistingDrawing={deleteExistingDrawing}
					newThumbnail={thumbnail}
					setThumbnail={setThumbnail}
				/>
			)}
		</Form>
	);
};

export default EditExercise;
