import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PageWidths } from 'pkg/config/sizes';

import * as actions from 'pkg/actions';
import store from 'pkg/store/createStore';
import { pushState } from 'pkg/router/state';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import { UserAddressTypes } from 'pkg/api/models/user_address';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';
import { Flag, useFlag } from 'pkg/flags';

import useContactForm, {
	getAlteredValues,
} from 'routes/payments/hooks/useContactForm';
import { ContactActionBar } from 'routes/payments/contacts/shared/ContactFormActions';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Form, { FormPayload } from 'components/form/Form';

interface EditProps {
	organizationId: number;
	userId: number;
}

const EditContact: React.FC<React.PropsWithChildren<EditProps>> = ({
	organizationId,
	userId,
}) => {
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const newUserProfileFlag = useFlag(Flag.NewUserProfile);

	const { records } = useCollection<models.userAddress.UserAddress>(
		endpoints.UserAddress.Index(),
		{
			queryParams: new URLSearchParams({ user_id: userId.toString() }),
		}
	);

	const {
		contactData,
		alteredGroups,
		DetailsSection,
		BillingAddressSection,
		Modals,
		hasFetchedData,
		showBillingAddressFields,
		user,
	} = useContactForm({
		groupId: organizationId,
		userId,
		userAddressRecords: records,
		fetchMemberships: false,
	});

	const org = useCurrentOrganization();

	const removeGroups = async () => {
		const groups = alteredGroups.delete;

		if (groups.length) {
			await Promise.all(
				groups.map(async (group) => {
					await dispatch(
						actions.groups.deprecatedUpdateGroupMember(group.id, userId, {
							status: 3,
						})
					);
				})
			);
		}
	};

	const addGroups = async () => {
		const groups = alteredGroups.add;

		if (groups.length) {
			await actions.memberships.addUserToGroups(userId, groups);
		}
	};

	const updateRoles = async () => {
		const groups = alteredGroups.role;

		if (groups.length) {
			await Promise.all(
				groups.map(async (group) => {
					await dispatch(
						actions.groups.deprecatedUpdateGroupMember(group.id, userId, {
							status: group.role,
						})
					);
				})
			);
		}
	};

	const handleSave = async (data: FormPayload): Promise<void> => {
		setIsSaving(true);
		await Promise.all([updateRoles(), removeGroups(), addGroups()]);

		const alteredDetails = getAlteredValues(user, data);

		alteredDetails.fields = models.user.getFieldValuesAsPayload(
			alteredDetails.fields
		);

		if (contactData?.profileImage?.length > 0) {
			alteredDetails.profileImage = contactData.profileImage;
		}

		const req = await actions.users.update(
			userId,
			alteredDetails
		)(store.dispatch);

		const billingAddressData = data.billingAddress as FormPayload;
		if (req) {
			if (showBillingAddressFields && records.length > 0) {
				await models.userAddress.update(
					{ ...billingAddressData, type: UserAddressTypes.Billing },
					records[0]
				);
			} else if (
				showBillingAddressFields &&
				records.length === 0 &&
				billingAddressData.email
			) {
				await models.userAddress.create({
					...billingAddressData,
					userId,
					type: UserAddressTypes.Billing,
				});
			} else if (!showBillingAddressFields && records.length > 0) {
				await models.userAddress.destroy(records[0]);
			}

			if (newUserProfileFlag) {
				pushState(
					routes.Organization.User.Profile.Show(org.id, userId, 'overview')
				);
			} else {
				pushState(routes.Management.Contact.Show(org.id, userId, 'overview'));
			}
		}

		setIsSaving(false);
	};

	if (!hasFetchedData) {
		return null;
	}

	return (
		<Fragment>
			<Form onSubmit={handleSave}>
				<ContactActionBar isSaving={isSaving} />
				<LargeScreen>
					<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD} spacious>
						{DetailsSection}
						{BillingAddressSection}
					</LargeScreenContent.Inner>
				</LargeScreen>
				<SmallScreen>
					<LargeScreenContent.Inner>
						{DetailsSection}
						{BillingAddressSection}
					</LargeScreenContent.Inner>
				</SmallScreen>
			</Form>
			{Modals}
		</Fragment>
	);
};

export default EditContact;
