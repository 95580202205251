// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bwTC6C9O64750XdCSsau {
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);
}

.CaA0zpoKnyXkUq_8SYR8 {
	font-size: var(--font-size-sm);
	color: var(--palette-blue-500);
	font-weight: var(--font-weight-semibold);
}

.BaKLpyw6SJbupzfZaKtJ {
	margin: 0 auto;
	padding: var(--spacing-8);
	max-width: var(--page-width-wide);
}

@media (max-width: 768px) {

.BaKLpyw6SJbupzfZaKtJ {
		padding: var(--spacing-6);
		padding-top: var(--spacing-2);
		background-color: var(--palette-gray-200);
}
	}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/payments/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,wCAAwC;AACzC;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;CAC9B,wCAAwC;AACzC;;AAEA;CACC,cAAc;CACd,yBAAyB;CACzB,iCAAiC;AAOlC;;AALC;;AALD;EAME,yBAAyB;EACzB,6BAA6B;EAC7B,yCAAyC;AAE3C;CADC","sourcesContent":[".tableTitle {\n\tfont-size: var(--font-size-lg);\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.newAction {\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-blue-500);\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.wrapper {\n\tmargin: 0 auto;\n\tpadding: var(--spacing-8);\n\tmax-width: var(--page-width-wide);\n\n\t@media (--small-viewport) {\n\t\tpadding: var(--spacing-6);\n\t\tpadding-top: var(--spacing-2);\n\t\tbackground-color: var(--palette-gray-200);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var tableTitle = `bwTC6C9O64750XdCSsau`;
export var newAction = `CaA0zpoKnyXkUq_8SYR8`;
export var wrapper = `BaKLpyw6SJbupzfZaKtJ`;
export default ___CSS_LOADER_EXPORT___;
