import { useT } from '@transifex/react';
import { Fragment } from 'react';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { OnboardingStatus } from 'pkg/api/models/group_payment_config';

import AdyenTransferInstrument from 'routes/settings/payments/adyen/transfer-instrument';

import Label from 'components/label';

import {
	SettingDescription,
	SettingLabel,
	SettingRow,
	FullWidthSettingRow,
} from 'components/settings/Settings';

import Button from 'design/button';

interface AdyenSettingsProps {
	organization: models.group.Group;
	paymentConfig: models.groupPaymentConfig.GroupPaymentConfig;
}

export default function AdyenSettings({
	organization,
	paymentConfig,
}: AdyenSettingsProps) {
	const t = useT();

	const handleEditConfig = () =>
		actions.adyen.getOnboardingLink(organization.id, paymentConfig.id);

	const { statusText, labelColor, description } =
		models.groupPaymentConfig.getStatusTranslation(paymentConfig, t);

	const showEditConfigButton =
		paymentConfig.onboardingStatus !==
			models.groupPaymentConfig.OnboardingStatus.OnboardingStatusRejected &&
		models.hasAllowedAction(
			paymentConfig,
			models.Action.GroupPaymentConfigOnboard
		);

	return (
		<Fragment>
			<FullWidthSettingRow>{description}</FullWidthSettingRow>
			<SettingRow>
				<SettingLabel>{t('Status')}</SettingLabel>
				<Label color={labelColor}>{statusText}</Label>
				{showEditConfigButton && (
					<Button
						label={t('Edit payment config')}
						icon="edit"
						onClick={handleEditConfig}
					/>
				)}
			</SettingRow>
			<SettingRow>
				<SettingLabel>{t('Payment method')}</SettingLabel>
				{/* note: change later, we only support cards atm for POC */}
				<SettingDescription>{t('Cards')}</SettingDescription>
			</SettingRow>
			{paymentConfig.onboardingStatus ===
				OnboardingStatus.OnboardingStatusApproved && (
				<AdyenTransferInstrument
					organization={organization}
					paymentConfigId={paymentConfig.id}
				/>
			)}
		</Fragment>
	);
}
