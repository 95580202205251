import { JSX, memo } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import DateTime, { Granularity } from 'pkg/datetime';
import { getFirstDayOfWeek, getWeekNumberForDay } from 'pkg/date';

import { LargeScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import * as DateNavigator from 'components/actionbar/DateNavigator';

import Button, { ButtonGroup } from 'design/button';

const TitleWrapper = styled.div`
	display: grid;
	gap: var(--spacing-1);
`;

interface DateNavigatorFormatProps {
	currentTab: string;
	getStartAndEndDates: {
		from: number;
		to?: number;
	};
	currentDate: number;
}

const DateNavigatorFormat = memo(
	({
		currentTab,
		getStartAndEndDates,
		currentDate,
	}: DateNavigatorFormatProps): any => {
		const d = new Date(getStartAndEndDates.from * 1000);
		const weekNumber = getWeekNumberForDay(d);

		switch (currentTab) {
			case 'year':
				return new DateTime(d).toLocaleDateString({
					year: 'numeric',
				});
			case 'month':
				return new DateTime(new Date(currentDate * 1000)).toLocaleDateString({
					month: 'short',
					year: 'numeric',
				});
			case 'week':
				return (
					<TitleWrapper>
						<DateNavigator.DateWrapper>
							<span>
								{new DateTime(getFirstDayOfWeek(d)).toLocaleDateString({
									month: 'long',
								})}
							</span>
						</DateNavigator.DateWrapper>
						<DateNavigator.WeekNumber color="gray">
							{`${t('Week')} ${weekNumber}, ${new Date(
								currentDate * 1000
							).getFullYear()}`}
						</DateNavigator.WeekNumber>
					</TitleWrapper>
				);
			case 'day':
				return (
					<TitleWrapper>
						<DateNavigator.DateWrapper>
							<span>
								{new DateTime(new Date(currentDate * 1000)).toLocaleDateString({
									month: 'short',
									day: 'numeric',
									weekday: 'short',
								})}
							</span>
						</DateNavigator.DateWrapper>
						<DateNavigator.WeekNumber color="gray">
							{`${t('Week')} ${weekNumber}, ${new Date(
								currentDate * 1000
							).getFullYear()}`}
						</DateNavigator.WeekNumber>
					</TitleWrapper>
				);
			case 'schedule': {
				return (
					<TitleWrapper>
						<DateNavigator.DateWrapper>
							<span>
								{new DateTime(
									new Date(getStartAndEndDates.from * 1000)
								).toLocaleDateString({
									month: 'short',
								})}{' '}
							</span>
							–
							<span>
								{new DateTime(
									new Date(getStartAndEndDates.to * 1000)
								).toLocaleDateString({
									month: 'short',
								})}{' '}
							</span>
						</DateNavigator.DateWrapper>
					</TitleWrapper>
				);
			}
			default:
				return null;
		}
	}
);

interface DisplayedDatesControlsProps {
	getStartAndEndDates: {
		from: number;
		to?: number;
	};
	timestamp: number;
	currentTab: string;
	sessionChangeDate: (date: string | number) => void;
	todayVisible: boolean;
	toggleToday: () => void;
}

const DisplayedDatesControls = ({
	getStartAndEndDates,
	timestamp,
	currentTab,
	sessionChangeDate,
	todayVisible,
	toggleToday,
}: DisplayedDatesControlsProps): JSX.Element => {
	const handleNavigatePrev = () => {
		let nextDateTime;
		const dateTime = new DateTime(new Date(timestamp * 1000));

		switch (currentTab) {
			case 'year':
				nextDateTime = dateTime.prev(Granularity.year, 1).startOfDay;
				break;
			case 'month':
				nextDateTime = dateTime.setDay(1).prev(Granularity.month, 1).startOfDay;
				break;
			case 'week':
				nextDateTime = dateTime.prev(Granularity.week, 1).startOfDay;
				break;
			case 'day':
				nextDateTime = dateTime.prev(Granularity.day, 1).startOfDay;
				break;
			case 'schedule':
				nextDateTime = dateTime.prev(Granularity.month, 3).startOfDay;
				break;
		}

		sessionChangeDate(+new Date(nextDateTime / 1000));
	};

	const handleNavigateNext = () => {
		const dateTime = new DateTime(new Date(timestamp * 1000));
		let nextDateTime;

		switch (currentTab) {
			case 'year':
				nextDateTime = dateTime.next(Granularity.year, 1).startOfDay;
				break;
			case 'month':
				nextDateTime = dateTime.setDay(1).next(Granularity.month, 1).startOfDay;
				break;
			case 'week':
				nextDateTime = dateTime.next(Granularity.week, 1).startOfDay;
				break;
			case 'day':
				nextDateTime = dateTime.next(Granularity.day, 1).startOfDay;
				break;
			case 'schedule':
				nextDateTime = dateTime.next(Granularity.month, 3).startOfDay;
				break;
		}
		sessionChangeDate(+new Date(nextDateTime / 1000));
	};

	const buttonSpacing = useMediaQuery({ minWidth: styles.breakpoint.fromLarge })
		? styles.spacing._5
		: styles.spacing._4;

	return (
		<ButtonGroup align="center" spacing={buttonSpacing}>
			<ButtonGroup spacing={styles.spacing._2}>
				<DateNavigator.Link reverse onClick={handleNavigatePrev}>
					<Icon name="chevron" />
				</DateNavigator.Link>

				<DateNavigator.Link onClick={handleNavigateNext}>
					<Icon name="chevron" />
				</DateNavigator.Link>
			</ButtonGroup>
			<DateNavigator.Date>
				<DateNavigatorFormat
					currentTab={currentTab}
					getStartAndEndDates={getStartAndEndDates}
					currentDate={timestamp}
				/>
			</DateNavigator.Date>
			<LargeScreen>
				{!todayVisible && <Button label={t('Today')} onClick={toggleToday} />}
			</LargeScreen>
		</ButtonGroup>
	);
};

export default DisplayedDatesControls;
