import { t } from '@transifex/native';
import { JSX, ComponentType, createElement, Fragment } from 'react';

import { fullName, UserAddress } from 'pkg/api/models/user_address';
import { ISO3166Alpha2Codes } from 'pkg/countries';

export interface DataRowProps {
	label: string;
	value?: string | number | JSX.Element;
}

interface Props {
	userAddress: UserAddress;
	item: ComponentType<DataRowProps>;
}

const UserAddressDataList = ({ userAddress, item }: Props) => {
	const labels = {
		name: t(`Name`),
		email: t(`Email`),
		phoneNumber: t(`Cellphone number`),
		address: t(`Street address`),
		postalCode: t(`ZIP code`),
		city: t(`City`),
		region: t(`State or region`),
		country: t(`Country`),
		company: t(`Company name`),
	};

	const rows = [
		{
			label: labels.name,
			value: fullName(userAddress),
		},
		{
			label: labels.email,
			value: <a href={`mailto:${userAddress.email}`}>{userAddress.email}</a>,
		},
		{
			label: labels.company,
			value: userAddress.companyName,
		},
		{
			label: labels.phoneNumber,
			value: (
				<a href={`tel:${userAddress.phoneNumber}`}>{userAddress.phoneNumber}</a>
			),
		},
		{
			label: labels.address,
			value: userAddress.address,
		},
		{
			label: labels.postalCode,
			value: userAddress.postalCode,
		},
		{
			label: labels.city,
			value: userAddress.city,
		},
		{
			label: labels.region,
			value: userAddress.region,
		},
		{
			label: labels.country,
			value: userAddress.country
				? ISO3166Alpha2Codes().find(
						(c) => c.code === userAddress.country.toLowerCase()
					).country
				: '',
		},
	];

	return (
		<Fragment>
			{rows.map((row, index) =>
				createElement(item, {
					key: index,
					value: row.value,
					label: row.label,
				})
			)}
		</Fragment>
	);
};

export default UserAddressDataList;
