import { JSX } from 'react';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { ApiError } from 'pkg/errors/errors';

import Videos from 'routes/video/library/group/Videos';
import { UserProfileLayoutCrash } from 'routes/organization/user-profile/layout';

import { Spinner } from 'components/loaders/spinner';
import EmptyState from 'components/library/VideoEmptyState';

import * as css from './styles.css';

interface OrganizationUserProfileVideosProps {
	user: models.user.User;
}

export default function OrganizationUserProfileVideos({
	user,
}: OrganizationUserProfileVideosProps): JSX.Element {
	const t = useT();

	const {
		records: videos,
		isLoading,
		pagination,
		replaceRecord,
		removeRecord,
		hasError,
		response,
	} = useCollection<models.video.Video>(
		user.id ? endpoints.Users.ListVideos(user.id) : undefined
	);

	if (isLoading) {
		return <Spinner />;
	}

	if (hasError) {
		return <UserProfileLayoutCrash error={new ApiError(response?.status)} />;
	}

	if (videos.length === 0) {
		return <EmptyState />;
	}

	return (
		<div className={css.wrapper}>
			<Videos
				title={t('Videos')}
				items={videos}
				hasNext={pagination.hasNext}
				fetchNext={pagination.fetchNext}
				replaceRecord={replaceRecord}
				removeRecord={removeRecord}
				context="user-profile"
			/>
		</div>
	);
}
