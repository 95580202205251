import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { Account } from 'pkg/api/models/onboarding_info';

import * as publicStyles from 'routes/public/styles/styles.css';
import Title from 'routes/public/styles/Title';
import CancelButton from 'routes/public/styles/CancelButton';
import UserInformationForm from 'routes/public/steps/shared/UserInformationForm';
import { useOnboardingState } from 'routes/public/hooks';
import { OnboardingSlug } from 'routes/public/onboarding';

import Column from 'components/layout/column';

export default function AccountInviteUserInformation(): JSX.Element {
	const account = useOnboardingState().get<Account>('account');
	const nextSlug: OnboardingSlug = account?.ssoToken
		? 'account-invite-account-details'
		: 'account-invite-create-account';

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title title={t('Enter your details')} />

				<UserInformationForm goToSlug={nextSlug} />
			</Column>

			<div className={publicStyles.footer}>
				<CancelButton />
			</div>
		</Fragment>
	);
}
