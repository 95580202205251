import { t } from '@transifex/native';
import { JSX, Fragment, useState } from 'react';

import { formatPriceIntervalToText } from 'pkg/utils';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import useTooltip from 'pkg/hooks/useTooltip';
import { useCurrentOrganization } from 'pkg/identity';
import { newChatParams } from 'pkg/chat';
import { link } from 'pkg/router/utils';

import CancelModal from 'containers/payment_platform/subscriptions/CancelModal';

import Icon from 'components/icon';

import StatusLabel from 'components/payment_platform/status/label';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';

interface SubscriptionItemProps {
	subscription: models.subscription.Subscription;
	// The current user we're viewing, to determine paid by/paid on behalf of logic
	userId: number;
}

function SubscriptionItem({
	subscription,
}: SubscriptionItemProps): JSX.Element {
	const [showCancel, setShowCancel] = useState(false);
	const org = useCurrentOrganization();

	const created = new DateTime(new Date(subscription.createdAt * 1000));

	const productRows = models.subscription.findProductRows(subscription);
	const products = productRows.map((r) => r.productPrice.product);

	const cost = models.subscription.getSubscriptionRowsTotalCost(
		subscription.rows
	);

	const amount = formatPriceIntervalToText(
		subscription.recurringInterval,
		subscription.recurringIntervalCount
	);
	const productTitle =
		products.length === 1
			? products[0].name
			: products.length > 1
				? t(`{name} + {amount} more`, {
						name: products[0].name,
						amount: subscription.rows.length - 1,
					})
				: '';

	const hideCancel = () => setShowCancel(false);
	const showCancelModal = () => setShowCancel(true);

	const subscriptionShowUrl = routes.Subscription.Show(
		org.id,
		subscription.id,
		'overview'
	);

	const users: {
		[key: number]: models.user.User;
	} = {};
	subscription.rows?.forEach((row) => {
		if (row?.user) {
			users[row.user.id] = row.user;
		}
	});

	const assignedContacts = [
		...new Map(
			subscription.rows
				.filter((row) => row?.user)
				.map((row) => row.user)
				.map((user) => [user.id, user])
		).values(),
	];

	const assignedContact =
		assignedContacts.length > 0 ? assignedContacts[0] : null;

	const { onMouseEnter, tooltip } = useTooltip(
		assignedContacts.map((contact) => models.user.fullName(contact)).join(', ')
	);

	let assignedContactsContent = null;

	if (assignedContacts.length === 0) {
		assignedContactsContent = <span>-</span>;
	} else if (assignedContacts.length === 1) {
		assignedContactsContent = (
			<span>{models.user.fullName(assignedContact)}</span>
		);
	} else {
		assignedContactsContent = (
			<Fragment>
				{tooltip}
				<span onMouseEnter={onMouseEnter}>
					{t('{amount} contacts', {
						amount: assignedContacts.length,
					})}
				</span>
			</Fragment>
		);
	}

	const subscriptionContext = (
		<ContextMenu.Menu
			toggleWith={
				<ContextMenu.TableTrigger>
					<Icon name="context-menu" />
				</ContextMenu.TableTrigger>
			}>
			<ContextMenu.LinkItem href={subscriptionShowUrl}>
				<ContextMenu.ItemIcon name="arrow_forward" />
				{t('View')}
			</ContextMenu.LinkItem>
			<ContextMenu.Item
				disabled={!models.subscription.subscriptionCanBeEdited(subscription)}
				onClick={showCancelModal}
				caution>
				<ContextMenu.ItemIcon name="delete" />
				{t('Cancel')}
			</ContextMenu.Item>
			{subscription.customerUser.accountId && (
				<ContextMenu.LinkItem
					href={link(
						routes.Chat.New(org.id),
						newChatParams([subscription.customerUser])
					)}>
					<ContextMenu.ItemIcon name="add_comment" />
					{t('Chat')}
				</ContextMenu.LinkItem>
			)}

			<ContextMenu.LinkItem href={`mailto:${subscription.customerUser.email}`}>
				<ContextMenu.ItemIcon name="mail" />
				{t('Send email')}
			</ContextMenu.LinkItem>
		</ContextMenu.Menu>
	);

	return (
		<Fragment>
			<Table.Row>
				<Table.LinkCell href={subscriptionShowUrl}>
					{productTitle}
				</Table.LinkCell>
				<Table.LinkCell href={subscriptionShowUrl}>
					{formatToCurrency(cost, subscription.currency)} {amount}
				</Table.LinkCell>
				<Table.LinkCell href={subscriptionShowUrl}>
					<StatusLabel status={models.subscription.getStatus(subscription)} />
				</Table.LinkCell>
				<Table.LinkCell
					href={routes.Management.Contact.Show(
						org.id,
						subscription.customerUser.id,
						'overview'
					)}>
					{models.user.fullName(subscription.customerUser)}
				</Table.LinkCell>
				<Table.LinkCell href={subscriptionShowUrl}>
					{assignedContactsContent}
				</Table.LinkCell>
				<Table.LinkCell href={subscriptionShowUrl}>
					{created.toLocaleDateString({
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					})}
				</Table.LinkCell>
				<Table.ActionCell>{subscriptionContext}</Table.ActionCell>
			</Table.Row>

			{showCancel && (
				<CancelModal hideModal={hideCancel} subscription={subscription} />
			)}
		</Fragment>
	);
}
export default SubscriptionItem;
