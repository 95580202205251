import { t } from '@transifex/native';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import { ISO3166Alpha2Codes } from 'pkg/countries';
import { translatedSexString } from 'pkg/api/models/user';

import ProductCalculations from 'routes/form_submissions/single/ProductCalculations';

import MaterialSymbol from 'components/material-symbols';
import { FormattedContent } from 'components/formatted-content';
import Icon from 'components/icon';

import * as iconStyles from 'components/icon/styles.css';
import Column from 'components/layout/column';

import * as css from './styles.css';

const Divider = styled.hr`
	border: none;
	border-top: 1px solid var(--palette-gray-300);
	margin: var(--spacing-5) 0;
`;

const Title = styled.div`
	font-size: var(--font-size-xl);
	font-weight: var(--font-weight-semibold);
`;

const HeadingRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	.${iconStyles.icon} {
		margin-right: var(--spacing-3);
		font-size: var(--font-size-xl);
	}
`;

const DataRow = styled.div`
	p:first-child {
		font-weight: var(--font-weight-semibold);
	}
`;

interface ContentProps {
	groupId: number;
	data: { [key: string]: string };
	fields: models.form.Field[];
	order: models.order.Order;
}

const SubmissionContent: React.FC<React.PropsWithChildren<ContentProps>> = ({
	groupId,
	data,
	fields,
	order,
}) => (
	<Column spacing={styles.spacing._8}>
		<Column>
			<div>
				<HeadingRow>
					<Icon name="file-generic" />
					<Title>{t(`Submitted form`)}</Title>
				</HeadingRow>
				<Divider />
			</div>
			{fields.length > 0 &&
				Object.entries(data)
					.sort(
						(a, b) =>
							(fields.find((field) => field.key === a[0])?.sortOrder || 0) -
							(fields.find((field) => field.key === b[0])?.sortOrder || 0)
					)
					.map(([key, string]) => {
						const formDisplayField = fields.find((field) => field.key === key);

						// ignore fields such as billing_*
						if (!formDisplayField) {
							return;
						}

						const isChoice =
							key !== 'user_sex' &&
							(formDisplayField.type === models.form.FieldTypes.Choice ||
								formDisplayField.type === models.form.FieldTypes.MultiChoice);

						if (isChoice) {
							return (
								<DataRow>
									<p>{formDisplayField.label}</p>
									<p>
										{models.form.getFormatedChoices(
											string,
											formDisplayField.values
										)}
									</p>
									<Divider />
								</DataRow>
							);
						}

						if (key === 'user_sex') {
							string = translatedSexString(Number.parseInt(string, 10));
						}

						if (key === 'user_country' || key === 'user_nationality') {
							string =
								ISO3166Alpha2Codes().find(
									(c) => c.code === string.toLowerCase()
								)?.country || string;
						}

						return (
							<DataRow key={key}>
								<p>{formDisplayField.label}</p>
								{formDisplayField.description && (
									<div className={css.fieldDescription}>
										<MaterialSymbol variant="info" />
										<FormattedContent
											raw={formDisplayField.description}
											maxLength={80}
										/>
									</div>
								)}
								<p>{string}</p>
								<Divider />
							</DataRow>
						);
					})}
		</Column>
		{!!order && (
			<div>
				<HeadingRow>
					<Icon name="tag" />
					<Title>{t(`Purchased products`)}</Title>
				</HeadingRow>
				<Divider />
				<ProductCalculations groupId={groupId} order={order} />
			</div>
		)}
	</Column>
);

export default SubmissionContent;
