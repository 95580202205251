// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fKK1VgrUYucu1LGrn0Zv {
	padding-bottom: var(--spacing-5);
	min-height: 60px;
}

.J17RVdIbcZo7BNCb8tJw {
	width: 100%;
	height: 100%;
	position: relative;
	padding-top: var(--spacing-4);
}

.J17RVdIbcZo7BNCb8tJw::after {
		content: '';
		width: 1px;
		height: 100%;
		z-index: 9;
		background-color: var(--palette-gray-300);
		position: absolute;
		left: calc(50% - 1px);
		top: 0;
	}

.HL3gDQVASvLMyuC1fagx {
	--size: 24px;

	background-color: var(--palette-yellow-200);
	border-radius: var(--size);
	width: var(--size);
	height: var(--size);
	display: grid;
	-ms-flex-line-pack: center;
	    align-content: center;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	place-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	justify-items: center;
	place-items: center;
	position: relative;
	z-index: 10;
	color: var(--palette-yellow-700);
	-webkit-box-shadow:
		inset 0 0 0 1px var(--palette-yellow-300),
		0 0 0 4px var(--palette-gray-200);
	        box-shadow:
		inset 0 0 0 1px var(--palette-yellow-300),
		0 0 0 4px var(--palette-gray-200);
}

.OgU1TP9wyOUpOKFPLoiV {
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
}

.OgU1TP9wyOUpOKFPLoiV strong {
		color: var(--palette-gray-900);
	}

.AgmKIDBIC4w2JTjvx3Ea {
	font-size: var(--font-size-sm);
	color: var(--palette-gray-500);
}

.DtB2fTSPFXPWfexaxrsD {
	padding: var(--spacing-4);
}

._nKIwCwohT4R9XNEWrXB {
	color: var(--palette-gray-700);
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/notes/user-log/styles.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;CAChC,gBAAgB;AACjB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,6BAA6B;AAY9B;;AAVC;EACC,WAAW;EACX,UAAU;EACV,YAAY;EACZ,UAAU;EACV,yCAAyC;EACzC,kBAAkB;EAClB,qBAAqB;EACrB,MAAM;CACP;;AAGD;CACC,YAAY;;CAEZ,2CAA2C;CAC3C,0BAA0B;CAC1B,kBAAkB;CAClB,mBAAmB;CACnB,aAAa;CACb,0BAAqB;KAArB,qBAAqB;CAArB,wBAAqB;KAArB,qBAAqB;SAArB,uBAAqB;CAArB,qBAAqB;CACrB,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CAAnB,qBAAmB;CAAnB,mBAAmB;CACnB,kBAAkB;CAClB,WAAW;CACX,gCAAgC;CAChC;;mCAEkC;SAFlC;;mCAEkC;AACnC;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;AAK/B;;AAHC;EACC,8BAA8B;CAC/B;;AAGD;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,8BAA8B;AAC/B","sourcesContent":[".cardWrapper {\n\tpadding-bottom: var(--spacing-5);\n\tmin-height: 60px;\n}\n\n.timeline {\n\twidth: 100%;\n\theight: 100%;\n\tposition: relative;\n\tpadding-top: var(--spacing-4);\n\n\t&::after {\n\t\tcontent: '';\n\t\twidth: 1px;\n\t\theight: 100%;\n\t\tz-index: 9;\n\t\tbackground-color: var(--palette-gray-300);\n\t\tposition: absolute;\n\t\tleft: calc(50% - 1px);\n\t\ttop: 0;\n\t}\n}\n\n.indicator {\n\t--size: 24px;\n\n\tbackground-color: var(--palette-yellow-200);\n\tborder-radius: var(--size);\n\twidth: var(--size);\n\theight: var(--size);\n\tdisplay: grid;\n\tplace-content: center;\n\tplace-items: center;\n\tposition: relative;\n\tz-index: 10;\n\tcolor: var(--palette-yellow-700);\n\tbox-shadow:\n\t\tinset 0 0 0 1px var(--palette-yellow-300),\n\t\t0 0 0 4px var(--palette-gray-200);\n}\n\n.title {\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-500);\n\n\tstrong {\n\t\tcolor: var(--palette-gray-900);\n\t}\n}\n\n.timestamp {\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-500);\n}\n\n.logEntry {\n\tpadding: var(--spacing-4);\n}\n\n.trigger {\n\tcolor: var(--palette-gray-700);\n}\n"],"sourceRoot":""}]);
// Exports
export var cardWrapper = `fKK1VgrUYucu1LGrn0Zv`;
export var timeline = `J17RVdIbcZo7BNCb8tJw`;
export var indicator = `HL3gDQVASvLMyuC1fagx`;
export var title = `OgU1TP9wyOUpOKFPLoiV`;
export var timestamp = `AgmKIDBIC4w2JTjvx3Ea`;
export var logEntry = `DtB2fTSPFXPWfexaxrsD`;
export var trigger = `_nKIwCwohT4R9XNEWrXB`;
export default ___CSS_LOADER_EXPORT___;
