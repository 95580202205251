import { t } from '@transifex/native';
import { Fragment, Component } from 'react';
import styled from 'styled-components';

import * as breakpoints from 'pkg/config/breakpoints';
import * as styles from 'pkg/config/styles';

import { cssVarList } from 'pkg/css/utils';
import * as models from 'pkg/api/models';

import CurrentPosition from 'containers/user/rating/CurrentPosition';

import * as Card from 'components/Card';
import ToggleSwitch from 'components/ToggleSwitch';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import TrainingPriorityTable from 'components/user/rating/TrainingPriority';
import PositionMap from 'components/user/rating/PositionMap';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import * as css from './styles.css';

const FauxText = styled.div`
	width: 100%;
	background: var(--palette-gray-200);
	height: 1rem;
	border-radius: var(--radius-2);
`;

const InfoBody = styled(Card.Body)`
	display: grid;
	padding: 0 20px 20px 20px;
	grid-template-columns: 1fr;
	grid-row-gap: 25px;

	@media ${breakpoints.small} {
		padding: 0 20px 20px 20px;
	}
`;

const Score = styled.div`
	text-align: right;
	margin-left: 10px;
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-2xl);
	flex: 0;
	color: var(--palette-gray-800);
	position: relative;

	&::before {
		content: '${(props) => props.label}';
		display: block;
		position: absolute;
		max-width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

		margin: 0 0 5px 0;
		bottom: 100%;
		right: 0;

		text-align: right;
		line-height: 1;

		color: var(--palette-gray-600);
		font-size: var(--font-size-sm);
		font-weight: var(--font-weight-normal);
		text-align: right;
	}
`;

const TableWrapper = styled.div`
	${Card.Heading} {
		color: var(--palette-gray-800);
		margin-bottom: 1rem;
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-lg);
	}
`;

const MaturityToggle = styled(ToggleSwitch)`
	margin-top: var(--spacing-5);
	padding: 0;
	justify-content: flex-start;
	flex-flow: column;
	align-items: flex-end;

	@media ${breakpoints.small} {
		padding: 0;
	}

	> label {
		color: var(--palette-gray-600);
		font-size: var(--font-size-sm);
		margin: 0 0 0.5rem 0;
		font-weight: var(--font-weight-normal);
		text-align: right;
	}
`;

const BestPositionIcon = styled(Icon)`
	z-index: 10;
	height: 25px;
	width: 25px;
	margin-right: 8px;

	color: var(--palette-white);
	background: var(--palette-blue-500);
	border-radius: 50%;
	padding: 3px;
`;

const MapWrapper = styled.div`
	padding: var(--spacing-9) var(--spacing-6);

	${PositionMap} {
		max-width: 500px;
		margin: 0 auto;
	}

	@media ${breakpoints.small} {
		padding: var(--spacing-8) var(--spacing-3);
	}
`;

export default class PositionDetails extends Component {
	get hasComparison() {
		if (!this.props.comparison) {
			return false;
		}

		return !!this.props.comparison.id;
	}

	get showScores() {
		const { comparison, rating } = this.props;

		if (this.hasComparison && rating.id) {
			return !(comparison.hiddenScores || rating.hiddenScores);
		}

		if (!rating) {
			return true;
		}

		return !rating.hiddenScores;
	}

	get score() {
		if (!this.showScores) {
			return null;
		}

		let score = <FauxText />;

		const {
			maturityAdjusted,
			rating,
			comparison,
			currentPositionId,
			comparisonScores,
			positionScores,
		} = this.props;

		if (
			rating &&
			positionScores?.find((s) => s.positionId === currentPositionId)
		) {
			let p = positionScores.find((s) => s.positionId === currentPositionId);

			score = (maturityAdjusted ? p.maturityAdjustedValue : p.value).toFixed(2);
		}

		let compare = <FauxText />;

		if (
			this.hasComparison &&
			comparisonScores.find((s) => s.positionId === currentPositionId)
		) {
			let cp = comparisonScores.find((s) => s.positionId === currentPositionId);

			compare = (
				maturityAdjusted ? cp.maturityAdjustedValue : cp.value
			).toFixed(2);
		}

		if (this.hasComparison && comparison.author && rating.author) {
			return (
				<Fragment>
					<Score label={rating.author.firstName}>{score}</Score>
					<Score label={comparison.author.firstName}>{compare}</Score>
				</Fragment>
			);
		}

		return <Score data-testid="assessment.score">{score}</Score>;
	}

	get bestPosition() {
		const { positionScores, comparisonScores } = this.props;

		const positionValue = this.props.maturityAdjusted
			? 'maturityAdjustedValue'
			: 'value';

		let bestPosition = {};
		let bestComparisonPosition = {};

		positionScores?.forEach((item) => {
			if (!bestPosition[positionValue]) {
				bestPosition = item;
			}

			if (bestPosition[positionValue] < item[positionValue]) {
				bestPosition = item;
			}
		});

		comparisonScores?.forEach((item) => {
			if (!bestComparisonPosition[positionValue]) {
				bestComparisonPosition = item;
			}

			if (bestComparisonPosition[positionValue] < item[positionValue]) {
				bestComparisonPosition = item;
			}
		});

		return bestPosition;
	}

	get chosenPosition() {
		const positionId = this.props.rating?.suggestedPositionId || 0;

		const position = this.props.positionScores.find(
			(p) => p.positionId === positionId
		);

		return position;
	}

	get currentPosition() {
		const positionId = this.props.position?.id || 0;

		const position = this.props.positionScores.find(
			(p) => p.positionId === positionId
		);

		return position;
	}

	render() {
		if (!this.props.position?.id) {
			return null;
		}

		const bestPositionValue = this.props.maturityAdjusted
			? this.bestPosition?.maturityAdjustedValue
			: this.bestPosition?.value;

		const bestChosenValue = this.props.maturityAdjusted
			? this.chosenPosition?.maturityAdjustedValue
			: this.chosenPosition?.value;

		const bestCurrentValue = this.props.maturityAdjusted
			? this.currentPosition?.maturityAdjustedValue
			: this.currentPosition?.value;

		const chosenIsCurrent =
			this.currentPosition?.position.id === this.chosenPosition.position.id;

		return (
			<InfoBody key="information">
				<div>
					{this.props.isChild && (
						<MaturityToggle
							label={t(`Physical development`)}
							active={this.props.maturityAdjusted}
							onToggle={this.props.handleMaturityAdjustmentToggle}
						/>
					)}

					<MapWrapper>
						<CurrentPosition
							comparison={this.props.comparison}
							rating={this.props.rating}
						/>
					</MapWrapper>

					{this.bestPosition && this.chosenPosition && (
						<Row
							spacing="0px"
							columns="repeat(2, 1fr)"
							className={css.positionLegend}>
							{chosenIsCurrent ? (
								<Column
									className={css.chosenPosition}
									spacing={styles.spacing._2}>
									<Row
										align="center"
										columns="25px 1fr"
										spoacing={styles.spacing._3}
										justifyContent="end"
										className={css.positionLegendKey}>
										<Avatar user={this.props.rating.user} />
										<span>{t('Your position')}</span>
									</Row>
									<h2>
										{this.chosenPosition.position.name} (
										{bestChosenValue.toFixed(2)})
									</h2>
									<p>{this.chosenPosition.position.longName}</p>
								</Column>
							) : (
								<Column
									className={css.chosenPosition}
									spacing={styles.spacing._2}>
									<Row
										align="center"
										columns="25px 1fr"
										spoacing={styles.spacing._3}
										justifyContent="end"
										className={css.positionLegendKey}>
										<div
											style={cssVarList({
												hsl: models.userRating.getPositionScoreColor(
													this.currentPosition.positionId,
													this.props.positionScores,
													this.props.maturityAdjusted
												),
											})}
											className={css.trend}
										/>
										<span>{t('Selected position')}</span>
									</Row>
									<h2>
										{this.currentPosition.position.name} (
										{(bestCurrentValue ?? 0).toFixed(2)})
									</h2>
									<p>{this.currentPosition.position.longName}</p>
								</Column>
							)}

							<Column className={css.bestPosition} spacing={styles.spacing._2}>
								<Row
									align="center"
									columns="1fr 25px"
									spoacing={styles.spacing._3}
									className={css.positionLegendKey}>
									<span>{t('Matched position')}</span>
									<BestPositionIcon name="star" />
								</Row>
								<h2>
									{this.bestPosition.position.name} (
									{bestPositionValue.toFixed(2)})
								</h2>
								<p>{this.bestPosition.position.longName}</p>
							</Column>
						</Row>
					)}
				</div>

				{!this.props.hideTrainingSuggestions && (
					<TableWrapper>
						<Card.Heading className={css.heading}>
							{t(`Training priority `)}
						</Card.Heading>
						<p className={css.subHeading}>
							{t(`As {longName} ({shortName})`, {
								longName: this.props.position?.longName,
								shortName: this.props.position?.name,
								_context: 'training priority',
							})}
						</p>
						<TrainingPriorityTable
							maturityAdjusted={this.props.maturityAdjusted}
							ratingId={this.props.ratingId}
						/>
					</TableWrapper>
				)}
			</InfoBody>
		);
	}
}
