import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import { flashes } from 'pkg/actions';
import { crash } from 'pkg/errors/errors';

export interface CreateMatchEventsPayload {
	events: Partial<models.matchEvent.MatchEvent>[];
}

interface CreateMatchEventsResponse {
	events: models.matchEvent.MatchEvent[];
}

export async function create(
	matchId: number,
	payload: CreateMatchEventsPayload
): Promise<[boolean, CreateMatchEventsResponse]> {
	const { title, description } = crash();

	try {
		const [request, response, error] = await models.create<
			CreateMatchEventsPayload,
			CreateMatchEventsResponse
		>(endpoints.MatchEvent.Create(matchId), payload);

		if (!request.ok) {
			flashes.show({
				title: title,
				message: error.error,
			});

			return [false, null];
		}

		return [true, response];
	} catch {
		flashes.show({ title, message: description });

		return [false, null];
	}
}

export async function createStartingUserMatchEvent(
	matchId: number,
	userId: number,
	positionId: number
): Promise<[boolean, CreateMatchEventsResponse]> {
	return await create(matchId, {
		events: [
			{
				type: models.matchEvent.MatchEventType.MatchEventTypeStartingUser,
				userId,
				positionId,
			},
		],
	});
}

export type UpdateMatchEventPayload = Partial<models.matchEvent.MatchEvent>;

export async function update(
	matchEventId: number,
	payload: UpdateMatchEventPayload
): Promise<[boolean, models.matchEvent.MatchEvent]> {
	const { title, description } = crash();

	try {
		const request = await sdk.patch(
			endpoints.MatchEvent.Update(matchEventId),
			{},
			payload
		);

		const response = await request.json();

		if (!request.ok) {
			flashes.show({
				title: title,
				message: response?.error ?? description,
			});

			return [false, null];
		}

		return [true, response];
	} catch {
		flashes.show({ title, message: description });

		return [false, null];
	}
}

export async function destroy(matchEventId: number): Promise<boolean> {
	const { title, description } = crash();

	try {
		const request = await sdk.destroy(
			endpoints.MatchEvent.Destroy(matchEventId)
		);

		const response = await request.json();

		if (!request.ok) {
			flashes.show({
				title: title,
				message: response?.error ?? description,
			});

			return false;
		}

		return true;
	} catch {
		flashes.show({ title, message: description });

		return false;
	}
}
