import * as debugStyles from 'pkg/debug/menu/style.css';
import { isDev } from 'pkg/flags';
import { cssClasses } from 'pkg/css/utils';

import MaterialSymbol from 'components/material-symbols';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

export default function PublicDebug() {
	if (!isDev()) {
		return null;
	}

	return (
		<Context.Menu
			toggleClassName={css.debugTrigger}
			toggleWith={
				<span className={cssClasses(debugStyles.developerMenuToggle)}>
					<MaterialSymbol variant="settings" scale={1.6} />
				</span>
			}>
			<Context.Label>
				Flags{' '}
				<Button
					small
					inline
					className={debugStyles.inlineAction}
					onClick={() => window.location.reload()}>
					Apply
				</Button>
			</Context.Label>
		</Context.Menu>
	);
}
