import { JSX } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';

import FeatureSelect from 'components/feature_select';
import AssetImage from 'components/AssetImage';
import { useStepModalContext } from 'components/step-modal';

import Column from 'components/layout/column';
import Row from 'components/layout/row';
import {
	flashErrorMessage,
	useCreateMembership,
	useJoinModalContext,
} from 'components/group/join-modal';
import InfoBox from 'components/form/info-box';

import * as css from '../styles.css';

interface RoleSelectProps {
	isSelf: boolean;
}

export default function RoleSelect({ isSelf }: RoleSelectProps): JSX.Element {
	const joinContext = useJoinModalContext();
	const { withNext } = useStepModalContext();
	const createMembership = useCreateMembership();

	const userRoleSelected =
		joinContext.groupRole === models.membership.MembershipRole.User;

	const staffRoleSelected =
		joinContext.groupRole === models.membership.MembershipRole.Staff;

	const continueAsPlayer = () => {
		joinContext.set('groupRole', models.membership.MembershipRole.User);
	};

	const continueAsStaff = () => {
		joinContext.set('groupRole', models.membership.MembershipRole.Staff);
	};

	withNext(async () => {
		const { statusCode, membership } = await createMembership(
			joinContext.groupCode,
			joinContext.groupRole
		);

		if (statusCode >= 400 && statusCode !== 409) {
			flashErrorMessage(statusCode);
			return Promise.resolve(false);
		}

		if (statusCode === 409) {
			actions.flashes.show(
				{
					title: t('Already a member'),
					message: t('You are already a member of {group}.', {
						group: joinContext.connectionInfo.groupName,
					}),
				},
				statusCode
			);

			return Promise.resolve(false);
		}

		// @NOTE Joining an org does not return a valid membership
		if (!membership.hasOwnProperty('message')) {
			joinContext.set('membership', membership);
		}

		return Promise.resolve(true);
	});

	let showInfoBox = false;

	if (isSelf && userRoleSelected) {
		showInfoBox = true;
	}

	return (
		<Column spacing={styles.spacing._7}>
			<FeatureSelect active={userRoleSelected} onClick={continueAsPlayer}>
				<Row columns="40px 1fr" align="center">
					<AssetImage src="img/roles/player.svg" className={css.image} />
					<Column spacing={styles.spacing._2}>
						<strong>{t('Player')}</strong>
						<span className={css.subtitle}>
							{t('You are a player in the team')}
						</span>
					</Column>
				</Row>
			</FeatureSelect>

			<FeatureSelect active={staffRoleSelected} onClick={continueAsStaff}>
				<Row columns="40px 1fr" align="center">
					<AssetImage src="img/roles/staff.svg" className={css.image} />
					<Column spacing={styles.spacing._2}>
						<strong>{t('Coach or staff')}</strong>
						<span className={css.subtitle}>
							{t('You are a coach or staff member in the team or club')}
						</span>
					</Column>
				</Row>
			</FeatureSelect>

			{showInfoBox && (
				<InfoBox color="yellow">
					<span className={css.bold}>{t('Are you sure?')}</span>
					<span>
						{t(
							`You've selected to join as a Player. This is typically for children or youth participants. If you're a parent or guardian, you might want to:`
						)}
					</span>
					<ul className={css.list}>
						<li>{t('Select one of your children to join as a player')}</li>
						<li>
							{t(
								`Choose the "Coach or staff" role if you're assisting the team`
							)}
						</li>
					</ul>
					<span>
						{t(`If you're certain about joining as a player, you can proceed.`)}
					</span>
				</InfoBox>
			)}
		</Column>
	);
}
