import { JSX, MutableRefObject, ReactNode, useEffect } from 'react';

import { useCheckAuth } from 'pkg/actions/auth';

import useMixedState from 'pkg/hooks/useMixedState';
import { setTranslations } from 'pkg/i18n/t';
import * as language from 'pkg/i18n/language';

import DeepLinkObserver from 'components/DeepLinkObserver';
import { SplashScreenProvider } from 'components/splash-screen';
import { Page } from 'components/page';

import MaintenanceMode from 'components/application/maintenance_mode';
import PublicPage from 'components/public/Page';
import { ApiConfig } from 'components/application/apiconfig';
import {
	ApplicationState,
	ApplicationStateContext,
	initialApplicationState,
} from 'components/application/state';
import { useKeyboardListener } from 'components/application/keyboard';

import { DialogProvider } from 'design/dialog';
import { ActionSheetProvider } from 'design/actionsheet';

let __currentAppStateContextValues: Readonly<Partial<ApplicationState>>;

/**
 *	@warn This is is an "unsafe" way of getting current context values, and is *only* used in router middlewares.
 */
export function getCurrentAppStateContextValues(): Readonly<
	Partial<ApplicationState>
> {
	return __currentAppStateContextValues;
}

export default function Application(): JSX.Element {
	const [appState, setAppState] = useMixedState<ApplicationState>(
		initialApplicationState
	);

	const [authToken, checkedAuth] = useCheckAuth();

	useKeyboardListener((open: boolean) => {
		setAppState({ keyboardOpen: open });
	});

	useEffect(() => {
		language.set(appState.language);
		setTranslations(appState.language);
	}, [appState.language]);

	const setLanguage = (lang: string): void => {
		setAppState({ language: lang });
	};

	const setCollapsedDrawer = (collapsedDrawer: boolean) => {
		setAppState({ collapsedDrawer });
	};

	const setHasActionBar = (hasActionBar: boolean) => {
		setAppState({ hasActionBar });
	};

	const setHasPagination = (hasPagination: boolean) =>
		setAppState({ hasPagination });

	const setScrollElement = (scrollElement: MutableRefObject<any>) =>
		setAppState({ scrollElement });

	let content: ReactNode = null;

	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');

	const providedValue: ApplicationState = {
		...appState,

		setLanguage,
		setCollapsedDrawer,
		setScrollElement,
		setHasActionBar,
		setHasPagination,
	};

	__currentAppStateContextValues = Object.freeze(providedValue);

	if (!checkedAuth) {
		return null;
	}

	if (authToken !== '' && !token) {
		content = <Page />;
	} else {
		content = <PublicPage />;
	}

	return (
		<SplashScreenProvider>
			<ApplicationStateContext.Provider value={providedValue}>
				<ApiConfig>
					<DeepLinkObserver />
					<MaintenanceMode>
						<ActionSheetProvider>
							<DialogProvider>{content}</DialogProvider>
						</ActionSheetProvider>
					</MaintenanceMode>
				</ApiConfig>
			</ApplicationStateContext.Provider>
		</SplashScreenProvider>
	);
}
