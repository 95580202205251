import { t } from '@transifex/native';
import { JSX, Fragment, useState } from 'react';

import * as styles from 'pkg/config/styles';

import { Flag, useFlag } from 'pkg/flags';
import Link from 'pkg/router/Link';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import { formatPriceIntervalToText } from 'pkg/utils';
import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { SubscriptionTypes } from 'pkg/api/models/subscription';
import { cssClasses } from 'pkg/css/utils';

import InstallmentData from 'routes/user/billing/subscription_list/InstallmentData';
import SelectPaymentMethod from 'routes/user/billing/subscription_list/SelectPaymentMethod';

import Avatar from 'components/avatar';
import * as Card from 'components/Card';
import Icon from 'components/icon';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';
import StatusLabel from 'components/payment_platform/status/label';
import Row from 'components/layout/row';
import { Spinner } from 'components/loaders/spinner';

import * as css from './styles.css';

interface SubscriptionItemProps {
	subscription: models.subscription.Subscription;
	groupId: number;
	// The current user we're viewing, to determine paid by/paid on behalf of logic
	userId: number;
}

function SubscriptionItem({
	subscription,
	userId,
}: SubscriptionItemProps): JSX.Element {
	const paymentMethodsEnabled = useFlag<boolean>(Flag.PaymentMethods);

	const { records: paymentMethods, isLoading: isLoading } =
		useCollection<models.paymentMethod.PaymentMethod>(
			endpoints.Subscriptions.ListPossiblePaymentMethods(subscription.id)
		);

	const [newActivePaymentMethod, setNewActivePaymentMethod] =
		useState<models.paymentMethod.PaymentMethod>(null);

	const defaultActivePaymentMethod = paymentMethods.find(
		(method) => method.id === subscription.paymentMethodId
	);

	const activePaymentMethod = newActivePaymentMethod
		? newActivePaymentMethod
		: defaultActivePaymentMethod;

	const created = new DateTime(new Date(subscription.createdAt * 1000));

	const productRows = models.subscription.findProductRows(subscription);
	const products = productRows.map((r) => r.productPrice.product);

	const cost = models.subscription.getSubscriptionRowsTotalCost(
		subscription.rows
	);

	const amount = formatPriceIntervalToText(
		subscription.recurringInterval,
		subscription.recurringIntervalCount
	);
	const productTitle =
		products.length === 1
			? products[0].name
			: products.length > 1
				? t('{name} + {amount} more', {
						name: products[0].name,
						amount: subscription.rows.length - 1,
					})
				: '';

	const users = subscription.rows
		?.filter((row) => row.userId)
		.map((row) => row.user);

	const hideOnBehalf = users.length === 1 && users[0].id === userId;

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<Card.Base $noBorder>
				<Card.Body $narrowBody>
					<Row columns="1fr auto auto" align="center">
						<Row columns="auto 1fr">
							<div className={css.iconwrapper}>
								<Icon name="sync" fill={styles.palette.blue[500]} />
							</div>
							<Column spacing={styles.spacing._1}>
								<div className={css.title}>{productTitle}</div>
								<span>
									{t('Created {date}', {
										date: created.toLocaleDateString({
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}),
									})}
								</span>
							</Column>
						</Row>

						<Column justify="center" spacing={styles.spacing._2}>
							<StatusLabel
								status={models.subscription.getStatus(subscription)}
							/>
							<span className={cssClasses(css.gray, css.smallText)}>
								{formatToCurrency(cost, subscription.currency)} {amount}
							</span>
						</Column>
					</Row>
				</Card.Body>

				{subscription.type === SubscriptionTypes.Installment && (
					<Fragment>
						<Card.Divider />
						<Card.Body $narrowBody>
							<InstallmentData subscription={subscription} />
						</Card.Body>
					</Fragment>
				)}

				{(models.hasLink(subscription, 'show:payment_method') ||
					!hideOnBehalf ||
					activePaymentMethod) && (
					<Fragment>
						<Card.Divider />
						<Card.Body>
							{models.hasLink(subscription, 'show:payment_method') && (
								<Link href={subscription.links['show:payment_method']}>
									<Row
										spacing={styles.spacing._2}
										align="center"
										autoColumns="max-content"
										className={css.showPaymentMethod}>
										<Icon name="nav-payments" size={1.5} />
										<span>{t('Update payment method')}</span>
									</Row>
								</Link>
							)}

							<Row columns="auto 1fr" spacing={styles.spacing._7}>
								{!hideOnBehalf && (
									<Column spacing={styles.spacing._2}>
										<span className={cssClasses(css.smallText, css.gray)}>
											{t('On behalf of')}
										</span>
										{users.map((user) => (
											<Row
												key={user.id}
												columns="20px 1fr"
												spacing={styles.spacing._3}
												align="center">
												<Avatar user={user} size={20} />
												<span className={css.smallText}>
													{models.user.fullName(user)}
												</span>
											</Row>
										))}
									</Column>
								)}
								{paymentMethodsEnabled && activePaymentMethod && (
									<SelectPaymentMethod
										subscription={subscription}
										activePaymentMethod={activePaymentMethod}
										paymentMethods={paymentMethods}
										setNewActivePaymentMethod={setNewActivePaymentMethod}
									/>
								)}
							</Row>
							{paymentMethodsEnabled &&
								models.paymentMethod.expiresSoon(activePaymentMethod) && (
									<InfoBox className={css.expirationWarning} color="red">
										{t(
											'Your payment method expires soon, please review your payment method'
										)}
									</InfoBox>
								)}
						</Card.Body>
					</Fragment>
				)}
			</Card.Base>
		</Fragment>
	);
}

export default SubscriptionItem;
