import { JSX } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import GroupItem, {
	CoverTextContainer,
	CoverText,
	CoverType,
	GroupItemAction,
} from 'components/library/GroupItem';

const VideoCountContainer = styled.div`
	background: hsla(0, 0%, 0%, 0.25);
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 30%;
	display: grid;
	place-items: center;
	place-content: center;
	gap: var(--spacing-1);
	color: currentColor;
	z-index: 40;
`;

const VideoCountValue = styled.span`
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
	}
`;

const VideoCountLabel = styled.span`
	font-size: var(--font-size-sm);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}
`;

interface VideoCollectionGroupItemProps {
	href?: string;
	embedded?: boolean;
	item: models.videoCollection.VideoCollection;
	actions?: GroupItemAction<models.videoCollection.VideoCollection>[];
}

export default function VideoCollectionGroupItem({
	href,
	embedded,
	item,
	actions,
}: VideoCollectionGroupItemProps): JSX.Element {
	const { groupId } = useCurrentRoute();
	const org = useCurrentOrganization();
	const hasGroup = !!item.group;

	let visibilityLabel = null;

	if (item.groupId) {
		visibilityLabel = item.group?.name || t('Team');
	} else if (item.isClubCollection) {
		visibilityLabel = item.group?.name || t('Club');
	} else {
		visibilityLabel = t('Only me');
	}

	const brandColor = item.group
		? models.group.getPrimaryColorStylesheetString(item.group)
		: null;

	const backdropColor = hasGroup ? brandColor : styles.palette.gray[500];
	const backdropImage = item.group?.profileImageUrl || null;

	if (!href) {
		href = routes.VideoLibrary.Collection.Show(org.id, groupId, item.id);
	}

	return (
		<GroupItem
			item={item}
			actions={actions}
			account={item.account}
			title={item.name}
			createdAt={item.createdAt}
			isNew={models.videoCollection.isNew(item)}
			isBranded={hasGroup}
			href={href}
			visibilityLabel={visibilityLabel}
			backdropColor={backdropColor}
			embedded={embedded}>
			{backdropImage && <img src={backdropImage} loading="lazy" />}
			<CoverTextContainer>
				<CoverType>
					<Icon name="folder-list" size={1.6} />
					{t('Collection')}
				</CoverType>
				<CoverText>{item.name}</CoverText>
			</CoverTextContainer>
			<VideoCountContainer>
				<VideoCountValue>{item.videoCount || 0}</VideoCountValue>
				<VideoCountLabel>{t('videos')}</VideoCountLabel>
			</VideoCountContainer>
		</GroupItem>
	);
}
