import React, { useState, JSX } from 'react';
import { t } from '@transifex/native';

import PerformanceReview from 'pkg/models/performance_review';

import CardBase, * as Card from 'components/Card';
import Modal from 'components/modal';

import Label from 'components/form/Label';
import TextArea from 'components/form/TextArea';

import Button from 'design/button';

type EditModalProps = {
	review: PerformanceReview;
	onClose: () => void;
	onSave: (text: string) => void;
};

const EditModal = ({
	review,
	onClose,
	onSave,
}: EditModalProps): JSX.Element => {
	const [inputVal, setInputVal] = useState(review.performanceReview);

	const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
		setInputVal(e.currentTarget.value);
	};

	const saveReview = () => {
		onSave(inputVal);
	};

	return (
		<Modal onClose={onClose}>
			<CardBase>
				<Card.Header>
					<Card.Heading>
						{review.id
							? t(`Edit player dialogue`)
							: t(`Create new player dialogue`)}
					</Card.Heading>
				</Card.Header>

				<Card.Divider />

				<Card.Body $flex>
					<Label>{t(`Player dialogue`)}</Label>
					<TextArea value={inputVal} onChange={handleInputChange} minRows={6} />
				</Card.Body>
				<Card.Footer>
					<Button primary large onClick={saveReview}>
						{t('Save')}
					</Button>
				</Card.Footer>
			</CardBase>
		</Modal>
	);
};

export default EditModal;
