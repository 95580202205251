import { JSX, Fragment, ReactNode, useState } from 'react';

import Pagination from 'components/pagination';

import * as Table from 'design/table';

interface TableSetColumn<T> extends Table.HeaderColumn {
	columnKey: keyof T;
	renderItemWith?: (item: T) => ReactNode;
}

type TableSetSortOrder = 'asc' | 'desc';

interface TableSetOptions<T> {
	sortable?: boolean;
	defaultSortKey: keyof T;
	defaultSortOrder?: TableSetSortOrder;

	filterable?: boolean;
	defaultFilterKey?: keyof T;

	pagination?: boolean;
	itemsPerPage?: number;

	expandOn?: keyof T;
	defaultOpenThreshold?: number;
	columns?: TableSetColumn<T>[];

	wrapperClassName?: string;
}

interface TableSet<T> {
	sortBy: keyof T;
	sortOrder: TableSetSortOrder;

	hasPrev: boolean;
	hasNext: boolean;
	currentPage: number;
	totalPages: number;
	totalCount: number;
	selectionCount: number;
	itemsPerPage: number;
	empty: boolean;

	sort: (column: keyof T, sortOrder: TableSetSortOrder) => void;
	filter: (column: keyof T, value: string) => void;

	prev: () => void;
	next: () => void;
	goTo: (pageNumber: number) => void;

	items: T[];
	unsorted: T[];

	Table: ReactNode;
	Pagination: ReactNode;
}

function tableSetSorter<T>(
	items: T[],
	sortBy: keyof T,
	sortOrder?: TableSetSortOrder
): T[] {
	if (!sortOrder) {
		sortOrder = 'asc';
	}

	const sortString = (a: string, b: string, sortOrder: TableSetSortOrder) => {
		if (sortOrder === 'desc') {
			return b?.localeCompare(a);
		}

		return a?.localeCompare(b);
	};

	const sortNumber = (a: number, b: number, sortOrder: TableSetSortOrder) => {
		if (sortOrder === 'desc') {
			return b - a;
		}

		return a - b;
	};

	return [...items].sort((a: T, b: T) => {
		const _a = a[sortBy] as unknown,
			_b = b[sortBy] as unknown;

		if (typeof _a === 'string') {
			return sortString(_a as string, _b as string, sortOrder);
		} else if (typeof _a === 'number') {
			return sortNumber(_a as number, _b as number, sortOrder);
		}
	});
}

export default function useTableSet<T>(
	items: T[],
	options: TableSetOptions<T>
): TableSet<T> {
	const {
		sortable = true,
		defaultSortKey,
		defaultSortOrder = 'asc',

		pagination = true,
		itemsPerPage = 10,

		expandOn,
		defaultOpenThreshold,
		columns,

		wrapperClassName,
	} = options;

	const [sortBy, setSortBy] = useState<keyof T>(defaultSortKey);
	const [sortOrder, setSortDirection] =
		useState<TableSetSortOrder>(defaultSortOrder);

	const [filterBy, setFilterBy] = useState<keyof T>();
	const [filterValue, setFilterValue] = useState<string>('');

	const [currentPage, setCurrentPage] = useState<number>(1);
	const totalPages = Math.ceil(items.length / itemsPerPage) || 1;

	const limit = currentPage * itemsPerPage;
	const offset = limit - itemsPerPage;

	const sort = (column: keyof T, sortOrder: TableSetSortOrder) => {
		setSortBy(column);
		setSortDirection(sortOrder);
	};

	const filter = (column: keyof T, value: string) => {
		setFilterBy(column);
		setFilterValue(value.toLocaleLowerCase().trim());
	};

	const itemsCopy = [...items];
	const sortedItems = sortable
		? tableSetSorter(itemsCopy, sortBy, sortOrder)
		: itemsCopy;

	let result = pagination ? sortedItems.slice(offset, limit) : sortedItems;
	let unsorted = items;

	if (filterBy) {
		result = unsorted = result.filter((item: T) =>
			(item[filterBy] as unknown as string)?.includes(filterValue)
		);
	}

	const hasPrev = currentPage !== 1;
	const hasNext = currentPage < totalPages;

	const prev = () => {
		if (hasPrev) {
			setCurrentPage(currentPage - 1);
		}
	};

	const next = () => {
		if (hasNext) {
			setCurrentPage(currentPage + 1);
		}
	};

	const goTo = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};

	const TableRow = ({
		item,
		depth = 0,
	}: {
		item: T;
		depth?: number;
	}): JSX.Element => {
		const [isExpanded, setExpanded] = useState<boolean>(
			depth <= defaultOpenThreshold
		);

		const children = (item?.[expandOn] as unknown as T[]) || [];

		const handleTraverse = () => setExpanded((prev: boolean) => !prev);

		if (expandOn) {
			return (
				<Fragment>
					<Table.ExpandableRow
						level={depth}
						canExpand={children.length > 0}
						isExpanded={isExpanded}
						onClick={handleTraverse}>
						{columns.map((col: TableSetColumn<T>, n) => {
							return <TableCell key={n} column={col} item={item} />;
						})}
					</Table.ExpandableRow>
					{isExpanded &&
						children.map((child: T, index: number) => (
							<TableRow key={index} item={child} depth={depth + 1} />
						))}
				</Fragment>
			);
		}

		return (
			<Table.Row>
				{columns.map((col: TableSetColumn<T>, n) => {
					return <TableCell key={n} column={col} item={item} />;
				})}
			</Table.Row>
		);
	};

	const TableCell = ({
		column,
		item,
	}: {
		column: TableSetColumn<T>;
		item: T;
	}): JSX.Element => {
		let render = column.renderItemWith;

		if (!render) {
			render = (item: T) => (
				<Table.Cell>
					{item[column.columnKey] as unknown as ReactNode}
				</Table.Cell>
			);
		}

		return <Fragment>{render(item)}</Fragment>;
	};

	let tableColumns = columns;

	if (expandOn && tableColumns) {
		tableColumns = [
			...columns,
			{
				columnKey: null,
				width: 'max-content',
			},
		];
	}

	const TableComponent: ReactNode = tableColumns ? (
		<Table.Table
			columns={tableColumns}
			sortBy={sortBy}
			sortOrder={sortOrder}
			onSort={sort}
			className={wrapperClassName}>
			{result.map((item: T, i: number) => (
				<TableRow key={i} item={item} />
			))}
		</Table.Table>
	) : null;

	const PaginationComponent: ReactNode = (
		<Pagination
			currentPage={currentPage}
			totalCount={items.length}
			count={itemsPerPage}
			fetchNext={next}
			fetchPrev={prev}
			fetchPage={goTo}
			hasNext={hasNext}
			hasPrev={hasPrev}
		/>
	);

	return {
		sortBy,
		sortOrder,
		hasPrev,
		hasNext,
		currentPage,
		totalPages,
		totalCount: items.length,
		selectionCount: result.length,
		itemsPerPage,
		empty: result.length === 0,

		sort,
		filter,
		prev,
		next,
		goTo,

		items: result,
		unsorted,

		Table: TableComponent,
		Pagination: PaginationComponent,
	};
}
