import { JSX, useRef } from 'react';
import YouTubePlayer from 'youtube-player';
import styled from 'styled-components';

import useInterval from 'pkg/hooks/useInterval';
import { isApp } from 'pkg/platform';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import { ContainerProps } from 'components/video-analytics/Container';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { YouTubeUrl } from 'components/video-analytics/source/SourceUrl';
import { useCueState } from 'components/video-analytics/CueState';
import { ControllerInterfaceType } from 'components/video-analytics/Controller';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
`;

interface YouTubeContainerProps extends ContainerProps {
	source: YouTubeUrl;
}

const VIDEO_PLAYING: number = 1;
const VIDEO_PAUSED: number = 2;
const VIDEO_BUFFERING: number = 3;
const VIDEO_CUED: number = 5;

const TIME_UPDATE_INTERVAL: number = 250;

export default function YouTubeContainer({
	source,
	startsAt,

	onReady,
	onBuffering,
	onError,
	onHasDuration,
	onPlay,
	onPause,
	onTimeUpdate,
}: YouTubeContainerProps): JSX.Element {
	const nodeRef = useRef<HTMLDivElement>();

	const cueState = useCueState();
	const playerState = usePlayerState();
	const playbackState = usePlaybackState();

	useInterval(async () => {
		if (playerState.controller && playerState.isPlaying) {
			const currentTime = await playerState.controller.getCurrentTime();

			if (
				playerState.justScrubbed &&
				Math.round(currentTime) !== Math.round(playbackState.currentTime)
			) {
				return;
			}

			if (cueState.currentCue && !playerState.gaplessPlayback) {
				const { endsAt } = cueState.currentCue;

				if (endsAt && currentTime >= endsAt) {
					cueState.setCueActive(false);
				}
			}

			if (onTimeUpdate) {
				onTimeUpdate(currentTime);
			}

			if (playerState.justScrubbed) {
				playerState.setJustScrubbed(false);
			}

			if (currentTime !== playbackState.currentTime) {
				playbackState.setCurrentTime(currentTime);
			}
		}
	}, TIME_UPDATE_INTERVAL);

	const sourceId = source.getSourceId();

	useComponentDidMount(() => {
		const player = YouTubePlayer(nodeRef.current, {
			width: '100%',
			height: '100%',
			videoId: '',
			playerVars: {
				origin: window.location.origin,
				disablekb: 1,
				controls: isApp() ? 1 : 0,
				autoplay: 1,
				iv_load_policy: 3,
				modestbranding: 1,
				playsinline: 1,
				enablejsapi: 1,
				loop: 0,
				rel: 0,
				fs: 1,
			},
		});

		playerState.setController(player as unknown as ControllerInterfaceType);

		// This only fires when API is ready, see
		player.on('ready', async () => {
			// @NOTE Unable to find good (or any) information on the issue but startSeconds cannot be explicit 0, so we use 0.1 here.
			// This issue only seem to affect videos with long run times.
			await player.cueVideoById(sourceId, startsAt ?? 0.1);

			if (onReady) {
				onReady();
			}
		});

		player.on('error', () => {
			if (playerState.isReady) {
				playerState.setHasError(true);

				if (onError) {
					onError();
				}
			}
		});

		player.on('stateChange', async (event) => {
			switch (event.data) {
				case VIDEO_CUED:
					playerState.setBuffering(false);
					playerState.setReady(true);

					// Attempt to force autoplay
					await player.playVideo();
					break;
				case VIDEO_BUFFERING:
					playerState.setBuffering(true);
					playerState.setPlaying(false);

					if (onBuffering) {
						onBuffering();
					}

					break;
				case VIDEO_PLAYING:
					playerState.setBuffering(false);
					playerState.setPlaying(true);

					if (onPlay) {
						onPlay();
					}
					break;
				case VIDEO_PAUSED:
					playerState.setPlaying(false);

					if (onPause) {
						onPause();
					}
					break;
			}

			const duration = await player.getDuration();

			if (duration !== 0 && duration !== playerState.duration) {
				playerState.setDuration(duration);

				if (onHasDuration) {
					onHasDuration(duration);
				}
			}
		});
	});

	if (!source.isValid()) {
		return null;
	}

	return (
		<Wrapper>
			<div role="video" ref={nodeRef} data-testid="video.source.youtube" />
		</Wrapper>
	);
}
