import { JSX } from 'react';
import { List } from 'immutable';

import UserGoal from 'pkg/models/user_goal';

import Goal from 'containers/user/profile/development/goals/Single';

import Column from 'components/layout/column';

type ListProps = {
	goals: List<UserGoal>;
};

const GoalsList = ({ goals }: ListProps): JSX.Element => {
	return (
		<Column>
			{goals.map((g: UserGoal) => {
				return <Goal key={g.id} goal={g} />;
			})}
		</Column>
	);
};

export default GoalsList;
