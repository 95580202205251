import { Fragment, JSX, ReactNode } from 'react';

interface UserProfileProps {
	children: ReactNode | ReactNode[];
}

export default function UserProfile({
	children,
}: UserProfileProps): JSX.Element {
	return <Fragment>{children}</Fragment>;
}
