import { useT } from '@transifex/react';
import { JSX, Fragment, ChangeEvent } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import DateTime, { Granularity } from 'pkg/datetime';
import * as endpoints from 'pkg/api/endpoints/auto';
import { QueryObject, useQueryState } from 'pkg/hooks/query-state';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentOrganization } from 'pkg/identity';
import useDateFilter, { DateQueryFilters } from 'pkg/hooks/use-date-filter';
import { useCollection } from 'pkg/api/use_collection';
import { Record } from 'pkg/api/models/record';
import { createFilterGroups, FilterOperator } from 'pkg/filters';
import { useFilters } from 'pkg/filters/use_filters';
import * as models from 'pkg/api/models';
import { crash } from 'pkg/errors/errors';

import { useReportsNavigation } from 'routes/payments/reports';

import { Trigger } from 'components/ScrollSpy';

import * as ActionBar from 'components/layout/ActionBar';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';

import * as Table from 'design/table';
import Button from 'design/button';

interface PeriodSum {
	periodSum: { [key: string]: number };
}

interface ReportEntityData extends Record {
	periodSum: PeriodSum;
	title: string;
	total: number;
}

interface RevenueViewProps {
	organizationId: number;
	query: QueryObject;
}

export default function RevenueView({
	organizationId,
	query,
}: RevenueViewProps) {
	const t = useT();
	const qs = useQueryState();
	const org = useCurrentOrganization();
	const now = new Date();
	const nowDt = new DateTime(now);
	const nowUnixTimestamp = nowDt.getUnixTimestamp();

	useReportsNavigation({ organizationId });

	const filters = useFilters({
		groups: createFilterGroups({
			['Search']: {
				hidden: true,
				filters: {
					Search: {
						type: 'text',
						operator: FilterOperator.Contains,
						property: 'search',
					},
				},
			},
		}),
	});

	const dateFilters = query?.dateFilters
		? JSON.parse(query.dateFilters as string)
		: {};

	const setUrl = (d: DateQueryFilters) => {
		qs.set('dateFilters', JSON.stringify(d));
		qs.commit();
	};

	const { DateFilter, dates } = useDateFilter({
		filters: dateFilters,
		showDefaultDatePresets: false,
		setUrl,
		customDatePresets: [
			{
				name: t('Last 3 months'),
				startOfRangeDate: nowDt.prev(Granularity.month, 3).getUnixTimestamp(),
				endOfRangeDate: nowUnixTimestamp,
			},
			{
				name: t('Last 6 months'),
				startOfRangeDate: nowDt.prev(Granularity.month, 6).getUnixTimestamp(),
				endOfRangeDate: nowUnixTimestamp,
			},
			{
				name: t('Last 12 months'),
				startOfRangeDate: nowDt.prev(Granularity.year, 1).getUnixTimestamp(),
				endOfRangeDate: nowUnixTimestamp,
			},
			{
				name: t('Year to date'),
				startOfRangeDate: Math.ceil(nowDt.startOfYear / 1000),
				endOfRangeDate: nowUnixTimestamp,
			},
		],
	});

	const report = (query?.report as string) || 'product';
	const type = (query?.type as string) || 'paid';
	const showTotalRow = report !== 'product_metadata';

	const categoryLabels: { [key: string]: string } = {
		product: t('Product'),
		product_category: t('Product category'),
		product_metadata: t('Product metadata'),
		registration: t('Registration'),
		registration_category: t('Registration category'),
	};

	const currentLabel = categoryLabels[report];

	const queryParams = new URLSearchParams({
		report,
		type,
		filters: JSON.stringify(filters.currentFilters),
	});

	if (dates?.startOfRange) {
		queryParams.append('from_date', dates.startOfRange.toString());
	}

	if (dates?.endOfRange) {
		queryParams.append('to_date', dates.endOfRange.toString());
	}

	const { records, isLoading, meta, pagination } =
		useCollection<ReportEntityData>(
			endpoints.Organizations.RevenueReport(organizationId),
			{
				queryParams,
				showAllResults: true,
			}
		);

	const collectionMeta = meta as {
		recordsPerPage: number;
		total: number;
		totalCount: number;
		totalPeriodSum: { [key: string]: number };
	};

	let getDateColumns: Table.HeaderColumn[] = [];
	let totalCells: Table.FooterColumnProps[] = [];

	if (collectionMeta) {
		getDateColumns = Object.keys(collectionMeta.totalPeriodSum).map((p) => {
			return {
				content: new DateTime(new Date(p)).toLocaleDateString({
					year: 'numeric',
					month: 'short',
				}),
			};
		});

		totalCells = Object.values(collectionMeta?.totalPeriodSum).map((v) => {
			return {
				content: formatToCurrency(v / 100, org.currency),
			};
		});
	}

	const handleRevenueDataTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
		qs.set(e.target.name, e.target.value);
		qs.commit();
	};

	const handleExport = async () => {
		const [ok, csvData] = await models.createExport(
			endpoints.Organizations.RevenueReportExport(organizationId),
			{
				from_date: dates.startOfRange,
				to_date: dates.endOfRange,
				report,
				type,
			}
		);

		if (ok) {
			const fileName = 'Revenue-report.csv';

			const url = URL.createObjectURL(new Blob([csvData]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	const handleTrigger = (e: IntersectionObserverEntry) => {
		if (e.isIntersecting) {
			pagination.fetchNext();
		}
	};

	const rows: JSX.Element[] = records.map((r, i, arr) => (
		<Fragment>
			<Table.Row key={r.id}>
				<Table.Cell border>
					<span>{r.title}</span>
				</Table.Cell>
				<Table.Cell emphasized border>
					{formatToCurrency(r.total / 100, org.currency)}
				</Table.Cell>
				{Object.values(r.periodSum).map((v, i) => {
					return (
						<Table.Cell key={i} border>
							<span>{formatToCurrency(v / 100, org.currency)}</span>
						</Table.Cell>
					);
				})}
			</Table.Row>
			{pagination.hasNext && !isLoading && i === arr.length - 1 && (
				<Trigger onTrigger={handleTrigger} />
			)}
		</Fragment>
	));

	return (
		<Fragment>
			<ActionBar.Bar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search
						value={
							filters.currentFilters.find((f) => f.property === 'search')
								?.values[0] as string
						}
						placeholder={t('Search')}
						filter={{
							type: 'text',
							operator: FilterOperator.Contains,
							property: 'search',
						}}
						filterSetter={filters.setFilter}
					/>
				</ActionBar.PrimaryAction>
				<Row autoColumns="max-content">
					{DateFilter}
					<Input.Select
						name="report"
						defaultValue={report}
						small
						onChange={handleRevenueDataTypeChange}>
						<option value="product">{t('Products')}</option>
						<option value="product_category">{t('Product categories')}</option>
						<option value="product_metadata">{t('Product metadata')}</option>
						<option value="registration">{t('Registrations')}</option>
						<option value="registration_category">
							{t('Registration categories')}
						</option>
					</Input.Select>
					<Input.Select
						name="type"
						small
						defaultValue={type}
						onChange={handleRevenueDataTypeChange}>
						<option value="paid">{t('Paid volume')}</option>
						<option value="invoiced">{t('Invoiced volume')}</option>
					</Input.Select>
					<Button label={t('Export')} icon="ios_share" onClick={handleExport} />
				</Row>
			</ActionBar.Bar>
			<Table.Table
				columns={[
					{
						content: currentLabel,
					},
					{
						content: t('Total'),
					},
					...getDateColumns,
				]}
				isLoading={isLoading}
				footerColumns={
					showTotalRow
						? [
								{ content: t('Total') },
								{
									content: formatToCurrency(
										collectionMeta?.total / 100,
										org.currency
									),
								},
								...totalCells,
							]
						: []
				}>
				{rows}
			</Table.Table>
		</Fragment>
	);
}
