import { JSX, Fragment } from 'react';
import { useT } from '@transifex/react';

import { cssVarList } from 'pkg/css/utils';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';

import SubmittedUser from 'routes/user/registrations/open/registration/SubmittedUser';

import TextOverflow from 'components/TextOverflow';
import * as Card from 'components/Card';
import Badge from 'components/Badge';
import Label from 'components/label';

import Button from 'design/button';

import * as css from './styles.css';

interface RegistrationProps {
	form: models.form.Form;
	group: models.group.Group;
}

export default function Registration({
	form,
	group,
}: RegistrationProps): JSX.Element {
	const t = useT();

	const canRegister = form.status === models.form.Statuses.Open;
	const hasSubmittedUsers = !!form.submissions?.length;

	const primaryColor = models.group.getPrimaryColorStylesheetString(
		group as models.group.Group
	);

	const submittedUsers = () => {
		const users = form.submissions
			.filter(
				(submission) =>
					submission.status !==
					models.formSubmission.FormSubmissionStatuses.Draft
			)
			.filter((s) => !!s.submittedForUser)
			.map((submission: models.formSubmission.FormSubmission) => {
				return <SubmittedUser submission={submission} key={submission.id} />;
			});

		return (
			<Fragment>
				<Card.Divider />
				<Card.Body className={css.submittedUsersList} $narrowBody>
					{users}
				</Card.Body>
			</Fragment>
		);
	};

	let gridStyling = {
		'registration-grid-areas': `'badge title-description action'`,
		'registration-template-columns': '35px 1fr auto',
	};

	if (canRegister) {
		gridStyling = {
			'registration-grid-areas': `'badge title-description' 'action action'`,
			'registration-template-columns': '35px 1fr',
		};
	}

	return (
		<Fragment>
			<Card.Base $noBorder>
				<Card.Body
					className={css.registration}
					style={cssVarList(gridStyling)}
					$narrowBody>
					<div className={css.badge} style={{ backgroundColor: primaryColor }}>
						<Badge badgeUrl={group.profileImageUrl} />
					</div>
					<div className={css.titleDescription}>
						<strong>
							<TextOverflow maxLines={3}>{form.title}</TextOverflow>
						</strong>
						{form.description && (
							<span>
								<TextOverflow maxLines={2}>{form.description}</TextOverflow>
							</span>
						)}
					</div>
					{canRegister ? (
						<Button
							href={routes.Registration.Index(form.group.slug, form.guid)}
							className={css.action}
							label={t('Register')}
							secondary
						/>
					) : (
						<Label className={css.action} color="gray">
							{t('Closed')}
						</Label>
					)}
				</Card.Body>
				{hasSubmittedUsers && submittedUsers()}
			</Card.Base>
		</Fragment>
	);
}
