import { Fragment, useEffect, useState } from 'react';
import { useT } from '@transifex/react';

import spacing from 'pkg/config/spacing';

import { flashes } from 'pkg/actions';
import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCurrentAccount, useCurrentAccountWards } from 'pkg/identity';
import { isAnAdult } from 'pkg/api/models/account';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { pushState } from 'pkg/router/state';

import SectionTitle from 'components/SectionTitle';
import * as CardList from 'components/card-list';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import JoinGroupModal from 'components/group/join-modal';
import Section from 'components/form/Section';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

interface Props {
	form: models.form.Form;
	onSelectAccount: (user: models.account.Account) => void;
}

const SelectUser = ({ form, onSelectAccount }: Props) => {
	const account = useCurrentAccount();
	const accountWards = useCurrentAccountWards();
	const t = useT();

	const {
		record: formGroupAccount,
		isLoading,
		hasError,
		response,
		refresh,
	} = useEndpoint<models.account.Account>(
		endpoints.Self.ShowOrganizationSelf(form.groupId)
	);

	const handleError = async () => {
		pushState(routes.Home());

		flashes.show({
			title: t('Something went wrong!'),
			message: t(
				`If you think this error isn't supposed to happen, please contact 360Player support.`
			),
		});
		return;
	};

	useEffect(() => {
		if (hasError && response.status !== 401) {
			handleError();
		}
	}, [hasError]);

	const formGroupUser = formGroupAccount?.users?.find(
		(user) => user.organizationId === form.groupId
	);

	const [showAddChildModal, setShowChildModal] = useState<boolean>(false);

	const children =
		accountWards
			.filter((child) => child.approvedAt)
			.map((ward) => ward.targetAccount) || [];

	const handleSelectChild = (account: models.account.Account) => {
		onSelectAccount(account);
	};

	const handleAddChild = () => {
		setShowChildModal(true);
	};

	const handleCloseChildModal = () => {
		setShowChildModal(false);
		refresh();
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<Column spacing={spacing._8}>
				<SectionTitle large>
					{t('Who should register for {title}?', { title: form.title })}
				</SectionTitle>
				<Column spacing={spacing._1}>
					{children.length > 0 && (
						<Column>
							<Section title={t('Your children')}>
								<Column>
									{children.map((child) => {
										return (
											<CardList.Base
												testid="registration.child"
												key={child.id}
												onClick={() => handleSelectChild(child)}>
												<CardList.Image>
													<Avatar account={child} />
												</CardList.Image>
												<CardList.Title>
													{models.account.fullName(child)}
												</CardList.Title>
												<CardList.Actions>
													<Icon name="chevron" size={1.8} />
												</CardList.Actions>
											</CardList.Base>
										);
									})}
									{form.group.allowInvites && (
										<Button
											testid="registration.create.child"
											block
											icon="person_add"
											onClick={handleAddChild}
											label={t('Add a new child')}
										/>
									)}
								</Column>
							</Section>
						</Column>
					)}
					<Section title={t('Yourself')} hideDivider={children.length === 0}>
						<Column>
							<CardList.Base
								testid="registration.self"
								onClick={() => onSelectAccount(account)}>
								<CardList.Image>
									{formGroupUser ? (
										<Avatar user={formGroupUser} />
									) : (
										<Avatar account={account} />
									)}
								</CardList.Image>
								<CardList.Title>
									{formGroupUser
										? models.user.fullName(formGroupUser)
										: models.account.fullName(account)}
								</CardList.Title>
								<CardList.Actions>
									<Icon name="chevron" size={1.8} />
								</CardList.Actions>
							</CardList.Base>
							{formGroupAccount &&
								isAnAdult(account) &&
								children.length == 0 && (
									<Button
										block
										icon="person_add"
										onClick={handleAddChild}
										label={t('Add a new child')}
									/>
								)}
						</Column>
					</Section>
				</Column>
			</Column>

			{showAddChildModal && (
				<JoinGroupModal
					allowAccountCreate
					filterWardsInCurrentGroup
					autoJoinWithGroupCode
					onlyAccountCreate
					group={form.group}
					ignoreRedirect={true}
					onClose={handleCloseChildModal}
				/>
			)}
		</Fragment>
	);
};

export default SelectUser;
