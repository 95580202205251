import { JSX, Fragment, useEffect, useState } from 'react';
import { t } from '@transifex/native';
import { useMediaQuery } from 'react-responsive';

import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';

import { isOrganization } from 'pkg/api/models/group';
import copyString from 'pkg/copyString';
import { useEventDispatcher } from 'pkg/hooks/events';
import * as actions from 'pkg/actions';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useQueryState } from 'pkg/hooks/query-state';
import { crash } from 'pkg/errors/errors';
import { useCurrentGroup, useCurrentMembership } from 'pkg/identity';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';

import PendingRequests from 'routes/group/show/pending-requests';
import CreateUserModal from 'routes/group/show/CreateUserModal';
import UserItem from 'routes/group/show/user_item';
import PendingEmailInvites from 'routes/group/show/pending-email-invites';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';
import { useExport } from 'components/export';
import Icon from 'components/icon';
import { LargeScreen } from 'components/MediaQuery';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import { ButtonTrigger } from 'components/navigation/header/small_screen/page_actions/ButtonTrigger';
import EmailInviteModal from 'components/group/settings/EmailInviteModal';
import InviteLinkModal from 'components/group/settings/invite-link-modal/InviteLinkModal';
import * as ActionBar from 'components/layout/ActionBar';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import { AddUserModal } from 'components/group/add-users-modal';
import { ParagraphRow } from 'components/loaders/skeleton/placeholders/ParagraphRow';
import { useTemplateContext } from 'components/layout/page-templates/context';

import * as ContextMenu from 'design/context_menu';
import NoEntities from 'design/placeholders/inline';
import Button from 'design/button';

import * as css from './styles.css';

interface GroupViewProps {
	groupId: number;
}

export default function GroupView({ groupId }: GroupViewProps): JSX.Element {
	const { setPageSubTitle, setPageActions } = useTemplateContext();
	const queryState = useQueryState();

	const [currentModal, setCurrentModal] = useState<
		'invite-link' | 'email-invite' | 'add-users' | 'create-user'
	>(null);

	const refreshInvites = useEventDispatcher('refresh-pending-email-invites');

	const copyGroupCode = () => {
		copyString(group.inviteCode);

		actions.flashes.show({
			title: t('Invite code copied'),
		});
	};

	const currentMembership = useCurrentMembership();
	const group = useCurrentGroup();
	const country = group.country;
	const isAdmin = models.membership.isAdmin(currentMembership);

	const { records, isLoading, refresh, removeRecord, replaceRecord } =
		useCollection<models.membership.Membership>(
			endpoints.Groups.ShowUsers(groupId),
			{
				showAllResults: true,
				queryParams: new URLSearchParams({
					includeLegalGuardians: '1',
					includeAllowedActions: '1',
				}),
			}
		);

	const closeModal = () => {
		setCurrentModal(null);
	};

	const refreshTeamList = () => {
		refreshInvites();
		refresh();
	};

	const players: JSX.Element[] = [];
	const staff: JSX.Element[] = [];
	const parents: JSX.Element[] = [];

	let memberships = records;

	const handleSearch = (value: string) => {
		if (value) {
			queryState.set('search', value);
			queryState.commit();
		} else {
			queryState.flush();
		}
	};

	const searchString = queryState.get('search')?.toString().toLowerCase();

	if (searchString) {
		memberships = memberships.filter(
			(membership: models.membership.Membership) => {
				// Full name
				let keywords: string = models.user.fullName(membership.user);

				// Child name
				const childName = models.user.legalGuardianFor(membership.user);
				if (childName) {
					keywords += ` ${childName}`;
				}

				// Parent name
				memberships.find((parentMembership: models.membership.Membership) => {
					const childFullName = models.user.fullName(membership.user);
					const legalGuardianFor = models.user.legalGuardianFor(
						parentMembership.user
					);

					if (childFullName === legalGuardianFor) {
						keywords += ` ${models.user.fullName(parentMembership.user)}`;
						return true;
					}
				});

				// Title
				if (membership.title) {
					keywords += ` ${membership.title}`;
				}

				return keywords.toLowerCase().includes(searchString);
			}
		);
	}
	const isSmallScreen = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	let actionMenuItems: JSX.Element;
	let actionType = '';

	if (!isOrganization(group)) {
		actionType = 'groupActions';
		actionMenuItems = (
			<Fragment>
				{group.allowInvites && group.inviteCode && (
					<ContextMenu.Item
						className={css.groupCodeItem}
						onClick={() => setCurrentModal('invite-link')}>
						<ContextMenu.ItemIcon name="share" />
						{t('Share invite link')}
					</ContextMenu.Item>
				)}

				{group.allowInvites && group.inviteCode && (
					<ContextMenu.Item onClick={copyGroupCode}>
						<ContextMenu.ItemIcon name="attachment" />
						{t('Copy invite code')} <strong>{group.inviteCode}</strong>
					</ContextMenu.Item>
				)}

				{group.allowInvites && (
					<ContextMenu.LinkItem onClick={() => setCurrentModal('email-invite')}>
						<ContextMenu.ItemIcon name="mail" />
						{t('Send email invite')}
					</ContextMenu.LinkItem>
				)}

				<ContextMenu.LinkItem onClick={() => setCurrentModal('add-users')}>
					<ContextMenu.ItemIcon name="person_add" />
					{t('Add users to group')}
				</ContextMenu.LinkItem>

				<ContextMenu.LinkItem onClick={() => setCurrentModal('create-user')}>
					<ContextMenu.ItemIcon name="person_add" />
					{t('Create virtual user')}
				</ContextMenu.LinkItem>
			</Fragment>
		);
	} else {
		actionType = 'normal';
		actionMenuItems = (
			<Fragment>
				{group.allowInvites && (
					<ContextMenu.LinkItem onClick={() => setCurrentModal('email-invite')}>
						<ContextMenu.ItemIcon name="mail" />
						{t('Send email invite')}
					</ContextMenu.LinkItem>
				)}

				<ContextMenu.LinkItem onClick={() => setCurrentModal('add-users')}>
					<ContextMenu.ItemIcon name="person_add" />
					{t('Add users to group')}
				</ContextMenu.LinkItem>
			</Fragment>
		);
	}

	useEffect(() => {
		if (isAdmin && setPageActions) {
			setPageActions(
				<ContextMenu.Menu toggleWith={<ButtonTrigger icon="more_horiz" />}>
					<ContextMenu.Label>{t('Invite team member')}</ContextMenu.Label>
					{actionMenuItems}
					<ContextMenu.Divider />
					<ContextMenu.Item key="export" onClick={exportAction.open}>
						<ContextMenu.ItemIcon name="download" />
						{t('Export')}
					</ContextMenu.Item>
				</ContextMenu.Menu>
			);
		}
	}, [isAdmin, actionType]);

	useComponentDidMount(() => setPageSubTitle(group.name));

	const inviteMemberBlock = (
		<Column spacing={styles.spacing._4}>
			<SectionTitle>{t('Invite team members')}</SectionTitle>
			<Column spacing={styles.spacing._3}>
				<Card.Base $noBorder>
					<div className={css.inviteCard}>
						<div className={css.icon}>
							<Icon
								name="user-add"
								fill={styles.palette.blue[500]}
								size={1.8}
							/>
						</div>
						<div className={css.groupCodeContainer}>
							<div className={css.groupCode}>
								{t('Group code: ')}
								{group.inviteCode}
							</div>
							<div className={css.groupCodeInfo}>
								{t('Share group code to staff, parents and players')}
							</div>
						</div>
						<div className={css.inviteButton}>
							<ContextMenu.Menu
								toggleWith={
									isSmallScreen ? (
										<Button
											primary
											label={t('Invite team member')}
											block
											icon="person_add"
										/>
									) : (
										<Button
											primary
											label={t('Invite team member')}
											icon="person_add"
										/>
									)
								}>
								{actionMenuItems}
							</ContextMenu.Menu>
						</div>
					</div>
				</Card.Base>
			</Column>
		</Column>
	);

	memberships
		.sort((a, b) =>
			`${a.user.firstName} ${a.user.lastName}`.localeCompare(
				`${b.user.firstName} ${b.user.lastName}`
			)
		)
		.map((membership: models.membership.Membership) => {
			const listItem = (
				<UserItem
					key={membership.userId}
					membership={membership}
					currentMembership={currentMembership}
					removeRecord={removeRecord}
					replaceRecord={replaceRecord}
					group={group}
				/>
			);

			if (models.membership.isPlayer(membership)) {
				players.push(listItem);
			} else if (models.membership.isAdminOrStaff(membership)) {
				staff.push(listItem);
			} else if (models.membership.isParent(membership)) {
				parents.push(listItem);
			}
		});

	let content: JSX.Element;

	if (isLoading) {
		content = (
			<Fragment>
				<Column spacing={styles.spacing._1}>
					<ParagraphRow height={24} width={100} />
					<Skeleton numItems={3} placeholderType={PlaceholderType.List} />
				</Column>
				<Column spacing={styles.spacing._1}>
					<ParagraphRow height={24} width={100} />
					<Skeleton numItems={4} placeholderType={PlaceholderType.List} />
				</Column>
				<Column spacing={styles.spacing._1}>
					<ParagraphRow height={24} width={100} />
					<Skeleton numItems={2} placeholderType={PlaceholderType.List} />
				</Column>
			</Fragment>
		);
	} else if (memberships.length === 0 && searchString) {
		content = (
			<Fragment>
				<NoEntities>
					<Icon name="person" />
					{t('No users found')}
				</NoEntities>
				{group.allowInvites &&
					isAdmin &&
					!models.group.isOrganization(group) &&
					inviteMemberBlock}
			</Fragment>
		);
	} else if (memberships.length === 0) {
		content = (
			<Fragment>
				{group.allowInvites &&
					isAdmin &&
					!models.group.isOrganization(group) &&
					inviteMemberBlock}
			</Fragment>
		);
	} else {
		content = (
			<Fragment>
				{players.length > 0 && (
					<Column spacing={styles.spacing._4}>
						<SectionTitle>
							{t('Players')} ({players.length})
						</SectionTitle>
						<Column spacing={styles.spacing._3}>{players}</Column>
					</Column>
				)}
				{staff.length > 0 && (
					<Column spacing={styles.spacing._4}>
						<SectionTitle>
							{t('Staff')} ({staff.length})
						</SectionTitle>
						<Column spacing={styles.spacing._3}>{staff}</Column>
					</Column>
				)}
				{parents.length > 0 && (
					<Column spacing={styles.spacing._4}>
						<SectionTitle>
							{t('Parents')} ({parents.length})
						</SectionTitle>
						<Column spacing={styles.spacing._3}>{parents}</Column>
					</Column>
				)}
				{group.allowInvites &&
					isAdmin &&
					!models.group.isOrganization(group) &&
					inviteMemberBlock}
			</Fragment>
		);
	}

	const pendingMembers = (
		<Fragment>
			<PendingRequests groupId={groupId} refreshTeamList={refresh} />
			<PendingEmailInvites groupId={groupId} />
		</Fragment>
	);

	const exportFields = models.group.getExportFields(country);

	const onCreateExport = async (fields: models.group.ExportUsersFields[]) => {
		const payload: { [key: string]: boolean } = {};

		fields.forEach((f) => (payload[f] = true));

		const [ok, csvData] = await models.group.exportUsers(groupId, payload);

		if (ok) {
			const fileName = `${group.name}-members.csv`;

			const url = URL.createObjectURL(new Blob([csvData]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} else {
			const err = crash();
			flashActions.show({
				title: err.title,
				message: err.description,
			});
		}
	};

	const exportAction = useExport(exportFields, onCreateExport);

	return (
		<Fragment>
			<ActionBar.FilterBar>
				<ActionBar.PrimaryAction>
					<ActionBar.Search
						value={searchString}
						placeholder={t('Search')}
						onChange={handleSearch}
					/>
				</ActionBar.PrimaryAction>
				{isAdmin && (
					<LargeScreen>
						<Button onClick={exportAction.open} icon="download">
							{t('Export')}
						</Button>
						<ContextMenu.Menu
							toggleWith={
								<Button
									primary
									label={t('Invite team member')}
									icon="person_add"
								/>
							}>
							{actionMenuItems}
						</ContextMenu.Menu>
					</LargeScreen>
				)}
			</ActionBar.FilterBar>
			<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.STANDARD}>
				<Column spacing={styles.spacing._8}>
					{!isLoading && isAdmin && pendingMembers}
					{content}
				</Column>
			</LargeScreenContent.Inner>

			{exportAction.modal}

			{currentModal === 'email-invite' && (
				<EmailInviteModal
					groupId={group.id}
					onClose={closeModal}
					onFinish={refreshTeamList}
				/>
			)}

			{currentModal === 'invite-link' && (
				<InviteLinkModal groupCode={group.inviteCode} onClose={closeModal} />
			)}

			{currentModal === 'create-user' && (
				<CreateUserModal
					groupId={group.id}
					refreshTeamList={refreshTeamList}
					onClose={closeModal}
				/>
			)}

			{currentModal === 'add-users' && (
				<AddUserModal
					groupId={groupId}
					onClose={closeModal}
					onFinish={refreshTeamList}
				/>
			)}
		</Fragment>
	);
}
