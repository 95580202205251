import { JSX } from 'react';
import { t } from '@transifex/native';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as actions from 'pkg/actions';

import Icon from 'components/icon';

import { PlaceholderType, Skeleton } from 'components/loaders/skeleton';
import ListItem from 'components/group/assess/ListItem';
import Column from 'components/layout/column';

import InlinePlaceholder from 'design/placeholders/inline';

interface AssessProps {
	groupId: number;
}

export default function Assess({ groupId }: AssessProps): JSX.Element {
	const { records: players, isLoading } =
		useCollection<models.membership.Membership>(
			endpoints.Groups.ShowUsers(groupId),
			{
				queryParams: new URLSearchParams({
					role: 'users',
					includeRatings: '1',
				}),
			}
		);

	const activeMembership = useCurrentMembership();
	const organization = useCurrentOrganization();

	const mountTime = Math.ceil(+new Date() / 1000);

	const onSave = () => {
		actions.flashes.show({
			title: t(`Assessment saved`),
			message: t(`Assessment saved successfully!`),
		});
	};
	if (isLoading) {
		return (
			<Skeleton
				placeholderType={PlaceholderType.UserRating}
				key="placeholders"
			/>
		);
	}

	if (players.length === 0) {
		return (
			<InlinePlaceholder>
				<Icon name="nav-dialogue" />
				{t(`No players in team`)}
			</InlinePlaceholder>
		);
	}

	return (
		<Column>
			{players.map((membership: models.membership.Membership) => {
				return (
					<ListItem
						showHeatmap
						key={membership.userId}
						groupId={groupId}
						organizationId={organization.id}
						isAdminOrStaff={models.membership.isAdminOrStaff(activeMembership)}
						onSave={onSave}
						nowTimestamp={mountTime}
						primaryRating={membership.lastRating || {}}
						lastSelfRating={membership.lastSelfRating || {}}
						membership={membership}
					/>
				);
			})}
		</Column>
	);
}
