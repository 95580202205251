import { Dispatch } from 'redux';
import { batch } from 'react-redux';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Membership, { Capabilities } from 'pkg/models/membership';
import User from 'pkg/models/user';
import Group from 'pkg/models/group';

import * as userService from 'pkg/actions/services/users';
import { normalizedDispatch } from 'pkg/actions/utils';
import { passwordAuth } from 'pkg/actions/auth';
import { triggerError } from 'pkg/actions/app';
import { show } from 'pkg/actions/flashes';
import { setActiveMembership } from 'pkg/actions/memberships';
import * as membershipService from 'pkg/actions/services/membership';
import * as actionTypes from 'pkg/actions/action-types';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import store from 'pkg/store/createStore';
import * as selectors from 'pkg/selectors';
import * as sdk from 'pkg/core/sdk';
import { RootState } from 'pkg/reducers';
import { setThemeColor } from 'pkg/meta_tags';
import rgba from 'pkg/rgba';
import { CustomHeaders } from 'pkg/api/headers';
import { crash } from 'pkg/errors/errors';
import { getCurrentIdentityContextValues } from 'pkg/identity';
import * as localStorageHelpers from 'pkg/local_storage';
import { OrganizationsLocalStorageData } from 'pkg/identity/account';
import { getOrganizationIdentityContextValues } from 'pkg/identity/organization';

import { CalendarFilterState } from 'routes/group/calendar/filters';

import { Event, trackEvent } from 'components/analytics';

interface Action {
	type: string;
	payload?: any;
	user?: any;
	id?: number;
	eventId?: number;
}

export const addUserEntities = (payload: any): Action => {
	return {
		type: actionTypes.Users.ADD_USERS,
		payload,
	};
};

export const acceptedInvite = (eventId: number): Action => {
	return {
		type: actionTypes.ACCEPTED_EVENT_INVITE,
		eventId,
	};
};

export const removePendingEvent = (id: number): Action => {
	return {
		type: actionTypes.REMOVE_PENDING_EVENT,
		id,
	};
};

export const removeUsers = (
	userIds: number[]
): { type: string; userIds: number[] } => ({
	type: actionTypes.Users.REMOVE_USERS,
	userIds,
});

export interface InheritedMembership {
	userId: number;
	role: string | number;
	capabilities: Capabilities[];
}

export const setActiveGroup =
	(
		group: models.group.Group,
		targetUserId: number = 0,
		inheritedMembership: InheritedMembership = null
	) =>
	async (
		dispatch: Dispatch,
		getState: () => RootState
	): Promise<Nullable<models.membership.Membership>> => {
		if (!group.id) return null;

		const state = getState();

		const identity = getCurrentIdentityContextValues();
		const { user } = getOrganizationIdentityContextValues();

		let membership: Membership;
		const membershipGroup = new Group(group);

		if (targetUserId !== 0) {
			// Parent->Child – User is a parent for a child (targetUserId)
			const wardMembership = selectors.groups
				.getMembershipsForUser(state, {
					userId: targetUserId,
				})
				.find((m: Membership) => m.groupId === group.id);

			if (!wardMembership || wardMembership.isInherited) {
				return null;
			}

			const targetUser = selectors.users.find(state, targetUserId);
			const parentUser = selectors.users.findByOrganizationIdAndAccountId(
				state,
				targetUser.organizationId,
				user.accountId
			);

			const userId = parentUser?.id;

			membership = new Membership({
				groupId: group.id,
				group: membershipGroup,
				userId,
				targetUserId,
				isLegalGuardian: true,
				status: 1,
				capabilities: wardMembership.capabilities,
			});

			identity.setMembership(
				membership.toJS() as unknown as models.membership.Membership
			);
		} else if (inheritedMembership) {
			// Inherited role – User has a role higher up in the node that can be inherited
			membership = new Membership({
				groupId: group.id,
				group: membershipGroup,
				userId: inheritedMembership.userId,
				isInherited: true,
				status: 1,
				role: inheritedMembership.role,
				capabilities: inheritedMembership.capabilities,
			});
		} else {
			// Direct member - User has a direct membership, targetUser is 0, inherit is not set
			const activeAccountUserIds =
				selectors.app.activeAccountUserIds(getState());

			membership = selectors.users
				.findAllMemberships(state, activeAccountUserIds, false)
				.find((m: Membership) => m.groupId === group.id);

			if (!membership) {
				return null;
			}

			membership = membership.set('group', membershipGroup);
		}

		if (!membership) {
			return null;
		}

		if (!membership.userId) {
			return null;
		}

		identity.setMembership(
			membership.toJS() as unknown as models.membership.Membership
		);

		const localStorageFilters = localStorage.getItem('calendarFilters');
		const localFilters: CalendarFilterState = JSON.parse(
			localStorage.getItem('calendarFilters')
		);

		if (localStorageFilters !== null) {
			localFilters.groups = [];

			localStorage.setItem('calendarFilters', JSON.stringify(localFilters));
		}

		batch((): void => {
			normalizedDispatch(membership.toJS(), Membership.normalizr())(dispatch);
			setActiveMembership(membership, targetUserId)(dispatch);
		});

		setThemeColor(
			group.primaryColor
				? group.primaryColor.replace(':', '(') + ')'
				: rgba(styles.palette.navBar.backgroundColor)
		);

		sdk.setDefaultRequestHeader(
			CustomHeaders.CurrentUserId,
			membership.userId.toString()
		);

		return membership.toJS() as models.membership.Membership;
	};

export const setActiveOrganization =
	(groupId: number, forceClubLobby = false) =>
	async (
		dispatch: Dispatch,
		getState: () => RootState
	): Promise<Nullable<models.membership.Membership>> => {
		const state = getState();
		const identity = getCurrentIdentityContextValues();
		const { user: currentUser } = getOrganizationIdentityContextValues();
		const group = selectors.groups.find(state, groupId);
		const accountId = currentUser.accountId;
		const account = selectors.accounts.find(state, accountId);
		const user = selectors.users
			.findAll(state)
			.find(
				(user: User) =>
					user.organizationId === groupId && user.accountId === account.id
			);

		if (!user) {
			return null;
		}

		let membership = new Membership({
			groupId,
			group,
			userId: user.id,
			isOrganizationMembership: true,
		});

		if (!forceClubLobby) {
			const parsedLocalStorageOrgData =
				localStorageHelpers.getJsonItem<OrganizationsLocalStorageData>(
					localStorageHelpers.LocalStorageKeys.Organizations
				);

			if (parsedLocalStorageOrgData[groupId].activeMembership) {
				const activeMembership: any =
					parsedLocalStorageOrgData[groupId].activeMembership;

				// We need to convert group/user to old models for redux
				activeMembership.group = new Group(activeMembership.group);
				if (activeMembership.user) {
					activeMembership.user = new User(activeMembership.user);
				}

				membership = new Membership(activeMembership);
			}
		}

		setActiveMembership(membership)(dispatch);

		identity.setMembership(
			membership.toJS() as unknown as models.membership.Membership
		);

		setThemeColor(
			group.primaryColor
				? group.primaryColor.replace(':', '(') + ')'
				: rgba(styles.palette.navBar.backgroundColor)
		);

		sdk.setDefaultRequestHeader(
			CustomHeaders.CurrentUserId,
			membership.userId.toString()
		);

		return membership.toJS() as models.membership.Membership;
	};

export const fetchUser =
	(userId: number, params?: { include_user_products: boolean }) =>
	async (dispatch: Dispatch): Promise<void> => {
		const request = await userService.getUser(userId, params);
		const response = await request.json();

		if (!request.ok) {
			return triggerError(request, response)(dispatch);
		}

		normalizedDispatch(response, User.normalizr())(dispatch);
	};

export const getUserMemberships =
	(groupId: number, userId: number) =>
	async (dispatch: Dispatch): Promise<number[]> => {
		const request = await membershipService.getUserMemberships(groupId, userId);

		if (request.ok) {
			const response = await request.json();
			const data = normalizedDispatch(response.records, [
				Membership.normalizr(),
			])(dispatch);

			const arr: number[] = [];

			data.result.forEach((i: number) => {
				arr.push(Number.parseInt(i.toString(), 10));
			});

			return arr;
		}

		return;
	};

export const removeUserFromGroups = async (
	groupId: number,
	userId: number
): Promise<boolean> => {
	const requestUrl = `/groups/${groupId}/users/${userId}`;
	const request = await sdk.destroy(requestUrl);

	if (request.ok) {
		store.dispatch(removeUsers([userId]));

		actions.flashes.show({
			title: t('Successfully removed user'),
		});
		return true;
	}

	actions.flashes.show({
		title: t('Something went wrong'),
		message: t('Failed to remove user from group'),
	});

	return false;
};

export const archiveUsers =
	(userIds: number[]) =>
	async (dispatch: Dispatch): Promise<void> => {
		const request = await userService.archiveUsers(userIds);
		const response: { fail: number[]; success: number[] } =
			await request.json();

		if (!request.ok) {
			show({
				title: t(`Failed to archive user`),
				message: t(`Contacts could not be archived.`),
			});
		} else {
			show({
				title: t(`Archived user`),
				message: t(`Contact(s) were succesfully archived.`),
			});

			dispatch(removeUsers(response.success));
		}
	};

export const restoreUser = async (userId: number): Promise<void> => {
	const request = await userService.restoreUser(userId);
	const response = await request.json();

	if (!request.ok) {
		show({
			title: crash().title,
			message: response.error,
		});
	} else {
		show({
			title: t(`Restoration complete`),
			message: t(`Contact were succesfully restored.`),
		});

		normalizedDispatch(response, User.normalizr())(store.dispatch);
	}
};

export const update =
	(
		userId: number,
		data: Record<string, any>,
		authenticateOnChange: boolean = false
	) =>
	async (dispatch: Dispatch): Promise<boolean> => {
		const request = await userService.updateUser(userId, data);

		if (!request.ok) {
			dispatch({
				type: actionTypes.Users.USER_PATCH_ERROR,
			});

			if (request.status === 409) {
				const msg = await request.json();
				if (msg.error === 'duplicate lok swedish personal id') {
					show(
						{
							title: t('The Personal ID already exists in this organization'),
							message: t(
								'Contact an organization administrator to resolve this, then return to complete this form.'
							),
						},
						request.status
					);
					return false;
				}
			}

			show(
				{
					title: t('An error occured'),
				},
				request.status
			);

			return false;
		}

		const response = await request.json();

		show(
			{
				title: t(`Successfully saved!`),
			},
			request.status
		);

		if (authenticateOnChange) {
			passwordAuth(response.email, data.currentPassword);
		}

		normalizedDispatch(response, User.normalizr())(dispatch);

		return true;
	};

export async function requestPasswordChange(email: string): Promise<boolean> {
	const response = await sdk.post(
		'request-password-reset',
		{},
		{ email: email }
	);

	if (!response.ok) {
		switch (response.status) {
			case 400:
				show({
					title: t('An error occured'),
				});
				break;
			case 500:
				show({
					title: t('Something went wrong'),
				});
				break;
			case 404:
				show({
					title: t(`{email} not found.`, { email }),
				});
				break;
		}

		return false;
	}

	show(
		{
			title: t(`Reset link sent`),
			message: t(`Sent to {email}`, { email }),
		},
		200
	);

	return true;
}

export const passwordChange =
	(password: string, token: string, showSuccessFlash: boolean = true) =>
	async (): Promise<boolean> => {
		const response = await sdk.post(
			'reset-password',
			{},
			{
				password: password,
			},
			{
				'X-User-Token': token,
			}
		);

		const json = await response.json();

		if (!response.ok) {
			switch (response.status) {
				case 400: {
					if (json.error === 'password must be at least 16 characters') {
						show({
							title: t('Your password needs to be at least 16 characters long'),
						});
						break;
					} else {
						show({
							title: t('An error occured'),
						});
						break;
					}
				}
				case 403:
					show({
						title: t('Forbidden'),
					});
					break;
				case 500:
					show({
						title: t('Something went wrong'),
					});
					break;
				case 404:
					show({
						title: t(`Invalid reset code. It could be old or already used.`),
					});
					break;
				default:
			}

			return false;
		}

		if (showSuccessFlash) {
			show(
				{
					title: t(
						`Your password has been changed, you can now log in using your new password.`
					),
				},
				200
			);
		}

		return true;
	};

export const createUser =
	(payload: userService.UserPayload) =>
	async (dispatch: Dispatch): Promise<[Response, any]> => {
		const [request, response, error] = await models.create<
			userService.UserPayload,
			User
		>(endpoints.Users.Create(), payload);

		if (!request.ok) {
			if (request.status === 409) {
				if (error.error === 'duplicate lok swedish personal id') {
					show(
						{
							title: t('The Personal ID already exists in this organization'),
							message: t(
								'Contact an organization administrator to resolve this, then return to complete this form.'
							),
						},
						request.status
					);
				}
			} else {
				show({
					title: error.error,
				});
			}

			return [request, response];
		}

		normalizedDispatch(response, User.normalizr())(dispatch);

		return [request, response];
	};

export const mergeUsers = async (
	userId: number,
	sourceUserId: number,
	data: { [key: string]: string | number }
): Promise<boolean> => {
	const request = await userService.mergeUsers(userId, sourceUserId);
	const resp = await request.json();

	if (!request.ok) {
		const flashMessage = {
			title: t('Something went wrong'),
			message: t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			),
		};

		if (request.status === 400) {
			flashMessage.message = resp.error;
		}

		show(flashMessage);
		return false;
	}

	const patchRequest = await update(userId, data)(store.dispatch);

	if (!patchRequest) {
		show({
			title: t('Something went wrong'),
			message: t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			),
		});
		return false;
	}

	store.dispatch(removeUsers([sourceUserId]));

	show({
		title: t(`Merge completed!`),
		message: t(`{name} has been updated.`, {
			name: data.firstName,
		}),
	});

	trackEvent(Event.UserMerge);
	return true;
};

export const fetchPaymentSource = async (userId: number): Promise<boolean> => {
	const request = await userService.fetchPaymentMethod(userId);

	if (!request.ok) {
		return false;
	}

	return true;
};

export const setLegalGuardianAsBillingContact = async (
	userIds: number[]
): Promise<[User[], Response]> => {
	const request = await userService.setBillingUsers(userIds);

	if (!request.ok) {
		show({
			title: t('Something went wrong'),
			message: t(
				"If you think this error isn't supposed to happen, please contact 360Player support."
			),
		});
		return null;
	}

	const response = await request.json();

	normalizedDispatch(response, [User.normalizr()])(store.dispatch);
	return [response, request];
};
