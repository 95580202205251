import { useT } from '@transifex/react';
import { JSX, Fragment, ReactNode, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentOrganization } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import { roleToTranslatedString } from 'pkg/api/models/membership';
import * as routes from 'pkg/router/routes';
import * as arrays from 'pkg/arrays';
import DateTime from 'pkg/datetime';

import Section from 'components/section';
import * as CardList from 'components/card-list';
import Badge from 'components/Badge';

import { useRemoveUserDialog } from 'components/user/remove';
import Column from 'components/layout/column';
import Row from 'components/layout/row';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface MembershipsProps {
	user: models.user.User;
}

export default function Memberships({ user }: MembershipsProps): JSX.Element {
	const t = useT();

	const [showingHistorical, setShowingHistorical] = useState<boolean>(false);

	const showHistorical = () => setShowingHistorical(true);

	const { records: memberships, refresh } =
		useCollection<models.membership.Membership>(
			endpoints.UserGroups.Index1(user.id),
			{
				queryParams: new URLSearchParams({
					include_historical: '1',
				}),
			}
		);

	if (!memberships?.length) {
		return null;
	}

	const grouped = arrays.groupBy<models.membership.Membership>(
		memberships ?? [],
		(m) => {
			return m.inactivatedAt ? 'inactiveMemberships' : 'activeMemberships';
		}
	);

	const inactiveMemberships: models.membership.Membership[] =
		grouped.get('inactiveMemberships') || [];

	const numInactiveMemberships = inactiveMemberships?.length ?? 0;

	const activeMemberships: models.membership.Membership[] =
		grouped.get('activeMemberships');

	const historicalMembershipList = showingHistorical ? (
		inactiveMemberships.map((membership) => (
			<Membership
				key={membership.id}
				user={user}
				membership={membership}
				onRemove={refresh}
			/>
		))
	) : (
		<div>
			<Button inline noPadding onClick={showHistorical}>
				{t('View historical groups ({num})', {
					num: numInactiveMemberships,
				})}
			</Button>
		</div>
	);

	return (
		<Fragment>
			<Section title={t('Groups')} icon="groups">
				{activeMemberships.map((membership) => (
					<Membership
						key={membership.id}
						user={user}
						membership={membership}
						onRemove={refresh}
					/>
				))}
				{numInactiveMemberships > 0 && historicalMembershipList}
			</Section>
		</Fragment>
	);
}

interface MembershipProps {
	user: models.user.User;
	membership: models.membership.Membership;
	onRemove: () => void;
}

function Membership({
	user,
	membership,
	onRemove,
}: MembershipProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const handleRemoveUser = async (
		removeUsersRecursively: boolean,
		removeFromFutureEvents: boolean
	) => {
		const success = await actions.memberships.removeUsersFromGroup(
			membership.groupId,
			{
				userIds: [user.id],
				removeUsersRecursively,
				removeFromFutureEvents,
			}
		);

		if (success && onRemove) {
			onRemove();
		}
	};

	const removeUser = useRemoveUserDialog({
		handleRemoveUsers: handleRemoveUser,
	});

	const membershipUrl = membership.isOrganizationMembership
		? routes.Organization.Home(org.id)
		: routes.Group.Show(org.id, membership.groupId);

	const userActions: ReactNode[] = [];

	if (models.hasAllowedAction(membership.group, models.Action.GroupView)) {
		userActions.push(
			<ContextMenu.LinkItem
				key="membership-url"
				href={membershipUrl}
				className={css.contextItemWithExtraIcon}>
				<ContextMenu.ItemIcon name="group" />
				<span>
					{t(
						membership.isOrganizationMembership
							? 'Go to organization'
							: 'Go to group'
					)}
				</span>
				<ContextMenu.ItemIcon name="arrow_forward" />
			</ContextMenu.LinkItem>
		);
	}

	if (
		membership.group &&
		models.hasAllowedAction(membership.group, models.Action.GroupRemoveUser)
	) {
		if (userActions.length === 1) {
			userActions.push(<ContextMenu.Divider />);
		}

		userActions.push(
			<ContextMenu.Item caution onClick={removeUser}>
				<ContextMenu.ItemIcon name="person_off" />
				{t('Remove user from {group}', {
					group: membership.group?.name || org.name,
				})}
			</ContextMenu.Item>
		);
	}

	const joinedAt = DateTime.fromTimestamp(membership.createdAt).toDateString();

	const inactivatedAt = DateTime.fromTimestamp(
		membership.inactivatedAt
	).toDateString();

	return (
		<Fragment>
			<CardList.Base noBorder key={membership.id}>
				<CardList.IconWrapper>
					<Badge branded badgeUrl={membership.group?.profileImageUrl} />
				</CardList.IconWrapper>
				<CardList.Title>
					<div>{membership.group?.name}</div>
					<CardList.SubTitle>
						{roleToTranslatedString(
							membership.role as models.membership.MembershipRole
						)}
					</CardList.SubTitle>
				</CardList.Title>
				<CardList.Actions>
					{membership.inactivatedAt ? (
						<Row spacing={styles.spacing._5}>
							<Column justify="center" spacing={styles.spacing._1}>
								<p>{t('Left')}</p>
								<strong>{inactivatedAt}</strong>
							</Column>
							<Column justify="center" spacing={styles.spacing._1}>
								<p>{t('Joined')}</p>
								<strong>{joinedAt}</strong>
							</Column>
						</Row>
					) : (
						userActions.length > 0 && (
							<ContextMenu.Menu
								toggleWith={
									<ContextMenu.TableTrigger>
										<Button secondary icon="more_horiz" />
									</ContextMenu.TableTrigger>
								}>
								{userActions}
							</ContextMenu.Menu>
						)
					)}
				</CardList.Actions>
			</CardList.Base>
		</Fragment>
	);
}
