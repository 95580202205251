import { JSX, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
interface TDProps {
	linkStyle?: boolean;
	verticalAlign?: 'start' | 'center' | 'end';
}
const Wrapper = styled.td<TDProps>`
	padding: 0 !important;
	position: relative;
	align-content: ${({ verticalAlign }) => verticalAlign || 'center'};
	align-items: ${({ verticalAlign }) => verticalAlign || 'center'};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	a {
		display: block;
		flex: 1;
		padding: var(--spacing-4);
		color: inherit;

		${({ align }) =>
			align &&
			align === 'right' &&
			css`
				text-align: ${align};
				justify-content: flex-end;
			`};

		${({ align }) =>
			align &&
			align === 'center' &&
			css`
				text-align: ${align};
				justify-content: center;
			`};

		${({ linkStyle }) =>
			linkStyle &&
			css`
				color: var(--palette-blue-500);
			`};

		@media (hover: hover) {
			&:hover {
				color: inherit;
			}
		}

		@media ${styles.breakpoint.small} {
			padding: var(--spacing-4) var(--spacing-3);
		}
	}

	:first-child a {
		padding-left: var(--spacing-4);
	}

	:last-child a {
		padding-right: var(--spacing-4);
	}
`;

export interface LinkCellProps {
	href?: string;
	to?: string;
	replace?: string;
	params?: { [key: string]: any };
	query?: { [key: string]: any };
	onClick?: () => void;
	align?: 'left' | 'center' | 'right';
	children: ReactNode;
	linkStyle?: boolean;
	verticalAlign?: 'start' | 'center' | 'end';
}

function LinkCell({
	href,
	to,
	replace,
	params,
	query,
	onClick,
	align,
	children,
	linkStyle,
	verticalAlign,
}: LinkCellProps): JSX.Element {
	const linkProps = { href, to, replace, params, query, onClick };

	return (
		<Wrapper align={align} verticalAlign={verticalAlign} linkStyle={linkStyle}>
			<Link {...linkProps}>{children}</Link>
		</Wrapper>
	);
}

export default LinkCell;
