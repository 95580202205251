import { t } from '@transifex/native';
import { JSX, Fragment, useState } from 'react';

import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { useFetchIdentity } from 'pkg/identity';

import AccountDeleteModal from 'routes/account/settings/privacy/AccountDeleteModal';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';

import Button from 'design/button';

interface AccountDeleteProps {
	account: models.account.Account;
}

export default function AccountDelete({
	account,
}: AccountDeleteProps): JSX.Element {
	const [showModal, setShowModal] = useState(false);
	const fetchIdentity = useFetchIdentity();

	const accountUnderDeletion = account.willBeDeletedAt;

	const handleToggleModal = () => setShowModal(!showModal);

	const handleCancelDelete = async () => {
		const confirm = window.confirm(t(`Are you sure?`));

		if (!confirm) return;

		await models.account.cancelDelete(account);

		fetchIdentity();
	};

	const willBeDeletedAt = new DateTime(
		new Date(account.willBeDeletedAt * 1000)
	).toLocaleDateString({
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});

	return (
		<Fragment>
			<Column>
				<SectionTitle>{t(`Delete your account`)}</SectionTitle>
				<Card.Base $noBorder>
					<Card.Body>
						<div>
							{accountUnderDeletion
								? t(
										`Your account has been scheduled for deletion, and will be deleted on {date}, you can cancel the deletion at any time before that date.`,
										{
											date: willBeDeletedAt,
										}
									)
								: t(
										`When you press the button below all your account data will be removed permanently and will not be recoverable.`
									)}
						</div>
					</Card.Body>
					<Card.Divider />
					<Card.Body>
						{accountUnderDeletion ? (
							<Button
								label={t(`Cancel my delete request`)}
								caution
								onClick={handleCancelDelete}
							/>
						) : (
							<Button
								label={t(`Permanently delete my account`)}
								caution
								onClick={handleToggleModal}
							/>
						)}
					</Card.Body>
				</Card.Base>
			</Column>
			{showModal && (
				<AccountDeleteModal
					account={account}
					onClose={handleToggleModal}
					onDeletion={fetchIdentity}
				/>
			)}
		</Fragment>
	);
}
