// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NJHGpzkSXNh9MOM1jOAA {
	border: 1px solid var(--palette-gray-300);
	background: var(--palette-white);
	border-radius: var(--radius-4);
}

.D8aIg6lO4HL2VWc02u02 {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	gap: var(--spacing-3);
	cursor: pointer;
	color: var(--palette-gray-900);
	-webkit-transition: color 0.3s ease;
	transition: color 0.3s ease;
}

.D8aIg6lO4HL2VWc02u02:hover {
	color: var(--palette-blue-500);
}

.w0N_dTy5BovZZi6B_wim {
	-ms-flex-item-align: center;
	    align-self: center;
}
`, "",{"version":3,"sources":["webpack://./routes/user_fields/settings/category_fields/styles.css"],"names":[],"mappings":"AAAA;CACC,yCAAyC;CACzC,gCAAgC;CAChC,8BAA8B;AAC/B;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,yBAAmB;KAAnB,sBAAmB;SAAnB,mBAAmB;CACnB,qBAAqB;CACrB,eAAe;CACf,8BAA8B;CAC9B,mCAA2B;CAA3B,2BAA2B;AAC5B;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,2BAAkB;KAAlB,kBAAkB;AACnB","sourcesContent":[".fieldsWrapper {\n\tborder: 1px solid var(--palette-gray-300);\n\tbackground: var(--palette-white);\n\tborder-radius: var(--radius-4);\n}\n\n.headingTitle {\n\tdisplay: flex;\n\talign-items: center;\n\tgap: var(--spacing-3);\n\tcursor: pointer;\n\tcolor: var(--palette-gray-900);\n\ttransition: color 0.3s ease;\n}\n\n.headingTitle:hover {\n\tcolor: var(--palette-blue-500);\n}\n\n.drag {\n\talign-self: center;\n}\n"],"sourceRoot":""}]);
// Exports
export var fieldsWrapper = `NJHGpzkSXNh9MOM1jOAA`;
export var headingTitle = `D8aIg6lO4HL2VWc02u02`;
export var drag = `w0N_dTy5BovZZi6B_wim`;
export default ___CSS_LOADER_EXPORT___;
