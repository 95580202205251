import styled from 'styled-components';
import { JSX, ChangeEvent, useEffect, useState } from 'react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import useMixedState from 'pkg/hooks/useMixedState';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';

import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import * as Input from 'components/form/inputs';
import InfoBox from 'components/form/info-box';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-6);
`;

const ShareWrapper = styled.div`
	display: grid;
	grid-template-rows: repeat(2, auto);
	place-items: start;
	place-content: start;
	gap: var(--spacing-2);
	row-gap: var(--spacing-5);
`;

const Description = styled.div`
	font-size: var(--font-size-sm);
`;

const Options = styled.div`
	display: inline-grid;
	gap: var(--spacing-3);
	grid-auto-flow: column;
`;

const Option = styled.div`
	position: relative;
`;

const ButtonLabel = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	gap: var(--spacing-3);
	align-items: center;
	justify-content: center;
`;

interface CollectionData {
	name: string;
	sharedWith: models.SharedWith;
}

interface CollectionProps {
	collectionId?: number;
	onClose?: () => void;
	afterSave?: (
		collection: models.trainingCollection.TrainingCollection
	) => void;
}

export default function Collection({
	collectionId,
	onClose,
	afterSave,
}: CollectionProps): JSX.Element {
	const group = useCurrentGroup();
	const org = useCurrentOrganization();
	const membership = useCurrentMembership();

	const [isBusy, setBusy] = useState<boolean>(false);
	const [data, setData] = useMixedState<CollectionData>({
		name: '',
		sharedWith: models.SharedWith.Account,
	});

	const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		setData({ name: event.target.value });
	};

	const shareWithMe = () => setData({ sharedWith: models.SharedWith.Account });

	const shareWithTeam = () => setData({ sharedWith: models.SharedWith.Group });

	const shareWithClub = () => setData({ sharedWith: models.SharedWith.Club });

	const { record: collection } =
		useEndpoint<models.trainingCollection.TrainingCollection>(
			endpoints.TrainingCollections.Show(org.id, collectionId)
		);

	useEffect(() => {
		if (collection.id) {
			setData({ name: collection.name });

			if (collection.groupId && !collection.inherit) {
				setData({ sharedWith: models.SharedWith.Group });
			}

			if (collection.groupId && collection.inherit) {
				setData({ sharedWith: models.SharedWith.Club });
			}
		}
	}, [collection?.id]);

	const save = async () => {
		setBusy(true);

		if (collectionId) {
			const payload: models.trainingCollection.TrainingCollectionUpdatePayload =
				{
					name: data.name,
				};

			if (collection.groupId !== 0) {
				if (data.sharedWith === models.SharedWith.Group) {
					payload.inherit = false;
				} else if (data.sharedWith === models.SharedWith.Club) {
					payload.inherit = true;
				}
			}

			const [req, updatedCollection] = await models.trainingCollection.update(
				collection,
				payload
			);

			if (afterSave && req.ok) {
				afterSave(updatedCollection);
			}
		} else {
			const payload: models.trainingCollection.TrainingCollectionCreatePayload =
				{
					name: data.name,
				};

			if (data.sharedWith === models.SharedWith.Group) {
				payload.groupId = group.id;
				payload.inherit = false;
			} else if (data.sharedWith === models.SharedWith.Club) {
				payload.groupId = group.id;
				payload.inherit = true;
			}

			const [req, newCollection] = await models.trainingCollection.create(
				org.id,
				payload
			);

			if (afterSave && req.ok) {
				afterSave(newCollection);
			}
		}

		setBusy(false);
		return true;
	};

	let shareLabel = (
		<ButtonLabel>
			<Icon name="user" />
			<span>{t('Only you')}</span>
		</ButtonLabel>
	);

	if (data.sharedWith === 'team') {
		shareLabel = (
			<ButtonLabel>
				<Icon name="nav-members" />
				<span>{t('Shared with team')}</span>
			</ButtonLabel>
		);
	} else if (data.sharedWith === 'club') {
		shareLabel = (
			<ButtonLabel>
				<Icon name="nav-badge" />
				<span>{t('Shared with club')}</span>
			</ButtonLabel>
		);
	}

	const isDisabled = collectionId && !collection?.groupId;

	const triggerButton = (
		<Button
			small
			primary
			icon="expand_more"
			iconPosition="right"
			disabled={isDisabled}>
			{shareLabel}
		</Button>
	);

	return (
		<StepModal.Base onClose={onClose}>
			<StepModal.Step
				slug="createCollection"
				title={collectionId ? t('Edit collection') : t('New collection')}
				nextLabel={collectionId ? t('Save') : t('Create')}
				canGoNext={data.name.length > 0}
				onNext={save}
				busy={isBusy}>
				<Wrapper>
					<Input.Field
						placeholder={t('Give your collection a name…')}
						value={data.name}
						onChange={handleNameChange}>
						<Input.Prefix inline>
							<Icon name="add-folder" size={1.4} />
						</Input.Prefix>
					</Input.Field>
					{models.membership.isAdminOrStaff(membership) ? (
						<InfoBox>
							<ShareWrapper>
								<Description>
									{data.sharedWith === 'club' && (
										<T
											_str="Visible to everyone in {team_name} and all child groups."
											team_name={<strong>{group.name}</strong>}
										/>
									)}
									{data.sharedWith === 'only_you' && t('Visible only to you.')}
									{data.sharedWith === 'team' && (
										<T
											_str="Visible to everyone in {team_name}."
											team_name={<strong>{group.name}</strong>}
										/>
									)}
								</Description>

								<Options>
									<Option>
										{isDisabled ? (
											triggerButton
										) : (
											<ContextMenu.Menu toggleWith={triggerButton}>
												{!collectionId && (
													<ContextMenu.Item onClick={shareWithMe}>
														<ContextMenu.ItemIcon name="person" />
														{t('Only you')}
													</ContextMenu.Item>
												)}

												<ContextMenu.Item onClick={shareWithTeam}>
													<ContextMenu.ItemIcon name="group" />
													{t('Shared with team')}
												</ContextMenu.Item>

												<ContextMenu.Item onClick={shareWithClub}>
													<ContextMenu.ItemIcon name="groups" />
													{t('Shared with club')}
												</ContextMenu.Item>
											</ContextMenu.Menu>
										)}
									</Option>
								</Options>
							</ShareWrapper>
						</InfoBox>
					) : (
						<InfoBox>{t('Visible only to you.')}</InfoBox>
					)}
				</Wrapper>
			</StepModal.Step>
		</StepModal.Base>
	);
}
