import { t } from '@transifex/native';
import { JSX, Fragment, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import {
	useContentTypeFilter,
	ContentTypeFilterKey,
} from 'components/library/hooks';
import * as Input from 'components/form/inputs';

import Button, { FilterButton } from 'design/button';
import * as ContextMenu from 'design/context_menu';

const Item = styled(ContextMenu.Item)`
	display: grid;
	grid-template-columns: 22px 1fr 22px;
	align-items: center;
`;

interface ContentFilterAction {
	label: string;
	type: ContentTypeFilterKey;
	icon: ReactNode;
}

interface ContentFilterProps {
	actions: ContentFilterAction[];
	standalone?: boolean;
}

export default function ContentFilter({
	actions,
	standalone = false,
}: ContentFilterProps): JSX.Element {
	const contentType = useContentTypeFilter();

	const updateContentFilters = (event: MouseEvent<HTMLDivElement>) => {
		contentType.toggle(
			event.currentTarget.dataset.contentType as ContentTypeFilterKey
		);
	};

	const toggle = (
		<Fragment>
			<LargeScreen>
				<Button icon="tune">{t(`Filter`)}</Button>
			</LargeScreen>
			<SmallScreen>
				<FilterButton />
			</SmallScreen>
		</Fragment>
	);

	const items = actions.map(({ label, icon, type }: ContentFilterAction) => (
		<Item
			key={type}
			closeOnClick={false}
			data-content-type={type}
			onClick={updateContentFilters}>
			{icon}
			<span>{label}</span>
			<Input.Control
				type="checkbox"
				standalone
				checked={contentType[type as ContentTypeFilterKey]}
			/>
		</Item>
	));

	if (standalone) {
		return <Fragment>{items}</Fragment>;
	}

	return <ContextMenu.Menu toggleWith={toggle}>{items}</ContextMenu.Menu>;
}
