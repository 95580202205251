import { t } from '@transifex/native';
import { show, shutdown, showNewMessage } from '@intercom/messenger-js-sdk';

import * as models from 'pkg/api/models';
import { isDev } from 'pkg/flags';

import { Event, trackEvent } from 'components/analytics';

import { isIntercomInitialized } from 'components/support/Intercom';

enum ChatType {
	Support = 'support',
	Bug = 'bug',
	Payment = 'payment',
}

interface AccountInfo {
	account: models.account.Account;
	membership?: models.membership.Membership;
}

function identify(accountInfo: AccountInfo, secure: boolean = true) {
	const ident: {
		name: string;
		accountId: number;
		userId: number;
		role?: string;
		email?: string;
		signature?: string;
		avatar?: string;
		languageCode: string;
		isLegalGuardian: string;
		country: string;
		group: string;
	} = {
		name: models.account.fullName(accountInfo.account),
		accountId: accountInfo.account.id,
		userId: accountInfo.membership.id,
		languageCode: accountInfo.account.languageCode,
		isLegalGuardian: models.membership.isParent(accountInfo.membership)
			? 'Yes'
			: 'No',
		country: accountInfo.account.country.code,
		group: `${accountInfo.membership.group.id} - ${accountInfo.membership.group.name}`,
	};

	if (accountInfo.membership.role) {
		ident.role = models.membership.roleToString(accountInfo.membership.role);
	}

	if (accountInfo.account.email && !isDev()) {
		ident.email = accountInfo.account.email;

		if (secure) {
			ident.signature = accountInfo.account.helpScoutSignature;
		}
	}

	if (accountInfo.account.profileImageUrl) {
		ident.avatar = accountInfo.account.profileImageUrl;
	}

	window.Beacon('identify', ident);
}

function configTranslations() {
	window.Beacon('config', {
		labels: {
			suggestedForYou: t(`Instant Answers`),
			getInTouch: t(`Get in touch`),
			searchLabel: t(`What can we help you with?`),
			tryAgain: t(`Try again`),
			defaultMessageErrorText: t(
				`There was a problem sending your message. Please try again in a moment.`
			),
			beaconButtonClose: t(`Close`),
			beaconButtonChatMinimize: t(`Minimise chat`),
			beaconButtonChatOpen: t(`Open chat`),
			answer: t(`Answers`),
			ask: t(`Ask`),
			messageButtonLabel: t(`Email`),
			noTimeToWaitAround: t(
				`No time to wait around? We usually respond within a few hours`
			),
			chatButtonLabel: t(`Chat`),
			chatButtonDescription: t(
				`We're online right now, talk with our team in real-time`
			),
			wereHereToHelp: t(`Start a conversation`),
			whatMethodWorks: t(`What channel do you prefer?`),
			previousMessages: t(`Previous Conversations`),
			cantFindAnswer: t(`Can't find an answer?`),
			relatedArticles: t(`Related Articles`),
			nothingFound: t(`Hmm…`),
			docsSearchEmptyText: t(
				`We couldn't find any articles that match your search.`
			),
			tryBroaderTerm: t(`Try searching a broader term, or`),
			docsArticleErrorText: t(
				`There was a problem retrieving this article. Please double-check your internet connection and try again.`
			),
			docsSearchErrorText: t(
				`There was a problem retrieving articles. Please double-check your internet connection and try again.`
			),
			escalationQuestionFeedback: t(`Did this answer your question?`),
			escalationQuestionFeedbackNo: t(`No`),
			escalationQuestionFeedbackYes: t(`Yes`),
			escalationSearchText: t(
				`Browse our help docs for an answer to your question`
			),
			escalationSearchTitle: t(`Keep searching`),
			escalationTalkText: t(`Talk with a friendly member of our support team`),
			escalationTalkTitle: t(`Talk to us`),
			escalationThanksFeedback: t(`Thanks for the feedback`),
			escalationWhatNext: t(`What next?`),
			sendAMessage: t(`Send a message`),
			firstAFewQuestions: t(`Let's begin with a few questions`),
			howCanWeHelp: t(`How can we help?`),
			responseTime: t(`We usually respond in a few hours`),
			history: t(`History`),
			uploadAnImage: t(`Upload an image`),
			attachAFile: t(`Attach a file`),
			continueEditing: t(`Continue writing…`),
			lastUpdated: t(`Last updated`),
			you: t(`You`),
			nameLabel: t(`Name`),
			subjectLabel: t(`Subject`),
			emailLabel: t(`Email address`),
			messageLabel: t(`How can we help?`),
			messageSubmitLabel: t(`Send a message`),
			next: t(`Next`),
			weAreOnIt: t(`We're on it!`),
			messageConfirmationText: t(
				`You'll receive an email reply within a few hours.`
			),
			viewAndUpdateMessage: t(`You can view and update your message in`),
			mayNotBeEmpty: t(`May not be empty`),
			customFieldsValidationLabel: t(`Please complete all fields`),
			emailValidationLabel: t(`Please use a valid email address`),
			attachmentErrorText: t(
				`There was a problem uploading your attachment. Please try again in a moment.`
			),
			attachmentSizeErrorText: t(`Attachments may be no larger than 10MB`),
			addReply: t(`Add a reply`),
			addYourMessageHere: t(`Add your message here...`),
			sendMessage: t(`Send message`),
			received: t(`Received`),
			waitingForAnAnswer: t(`Waiting for an answer`),
			previousMessageErrorText: t(
				`There was a problem retrieving this message. Please double-check your Internet connection and try again.`
			),
			justNow: t(`Just Now`),
			chatHeadingTitle: t(`Chat with our team`),
			chatHeadingSublabel: t(`We'll be with you soon`),
			chatEndCalloutHeading: t(`All done!`),
			chatEndCalloutMessage: t(
				`A copy of this conversation will land in your inbox shortly.`
			),
			chatEndCalloutLink: t(`Return home`),
			chatEndUnassignedCalloutHeading: t(`Sorry about that`),
			chatEndUnassignedCalloutMessage: t(
				`It looks like nobody made it to your chat. We'll send you an email response as soon as possible.`
			),
			chatEndWaitingCustomerHeading: t(`Sorry about that`),
			chatEndWaitingCustomerMessage: t(
				`Your question has been added to our email queue and we'll get back to you shortly.`
			),
			ending: t(`Ending...`),
			endChat: t(`End chat`),
			chatEnded: t(` ended the chat`),
			chatConnected: t(`Connected to `),
			chatbotName: t(`Help Bot`),
			chatbotGreet: t(
				`Hi there! You can begin by asking your question below. Someone will be with you shortly.`
			),
			chatbotPromptEmail: t(
				`Got it. Real quick, what's your email address? We'll use it for any follow-up messages.`
			),
			chatbotConfirmationMessage: t(
				`Thanks! Someone from our team will jump into the chat soon.`
			),
			chatbotGenericErrorMessage: t(
				`Something went wrong sending your message, please try again in a few minutes.`
			),
			chatbotInactivityPrompt: t(
				`Since the chat has gone idle, I'll end this chat in a few minutes.`
			),
			chatbotInvalidEmailMessage: t(
				`Looks like you've entered an invalid email address. Want to try again?`
			),
			chatbotAgentDisconnectedMessage: t(
				` has disconnected from the chat. It's possible they lost their internet connection, so I'm looking for someone else to take over. Sorry for the delay!`
			),
			chatAvailabilityChangeMessage: t(
				`Our team's availability has changed and there's no longer anyone available to chat. Send us a message instead and we'll get back to you.`
			),
			emailHeading: t(`Today's chat with `),
			emailGreeting: t(`Hey `),
			emailCopyOfDiscussion: t(`Here's a copy of your discussion`),
			emailContinueConversation: t(
				`If you've got any other questions, feel free to hit reply and continue the conversation.`
			),
			emailJoinedLineItem: t(` joined the chat`),
			emailEndedLineItem: t(` ended the chat`),
			emailYou: t(`You`),
		},
	});
}

export const openSupport = (ai: AccountInfo) => {
	if (isIntercomInitialized) {
		show();
		return;
	}

	trackEvent(Event.SupportChatActivated, { type: ChatType.Support });
	window.Beacon('init', window.TS.helpScoutKey);
	identify(ai, true);
	configTranslations();
	window.Beacon('once', 'close', () => {
		window.Beacon('destroy');
	});
	window.Beacon('open');
};

// opens a beacon for payment support
export const openPaymentSupport = (ai: AccountInfo) => {
	if (isIntercomInitialized) {
		show();
		return;
	}

	trackEvent(Event.SupportChatActivated, { type: ChatType.Payment });
	window.Beacon('init', 'd8c4ce68-d753-42a5-ac5e-25237e8cff92');
	identify(ai, false);
	configTranslations();
	window.Beacon('once', 'close', () => {
		window.Beacon('destroy');
	});
	window.Beacon('open');
};

// opens a beacon to report a bug
export const openBugReport = (ai: AccountInfo) => {
	if (isIntercomInitialized) {
		showNewMessage(t('Bug report'));
		return;
	}

	trackEvent(Event.SupportChatActivated, { type: ChatType.Bug });
	window.Beacon('init', 'd05d3f50-e269-4cf8-af49-7d9b300fe790');
	identify(ai, false);
	configTranslations();
	window.Beacon('once', 'close', () => {
		window.Beacon('destroy');
	});
	window.Beacon('prefill', {
		subject: `Bug report`,
	});
	window.Beacon('open');
};

// opens a beacon to report objectionable UGC content
export const openUGCReport = (
	ai: AccountInfo,
	contentType: string,
	contentId: number
) => {
	window.Beacon('init', 'a452a629-c4cc-4bc2-8ad9-99775e1f76d4');
	identify(ai, false);
	configTranslations();
	window.Beacon('once', 'close', () => {
		window.Beacon('destroy');
	});
	window.Beacon('prefill', {
		subject: `UGC Report ${contentType}:${contentId}`,
		text: "Hi! I've found some questionable content in 360Player.",
		fields: [
			{
				id: 37141,
				value: contentType,
			},
			{
				id: 37142,
				value: contentId.toString(),
			},
		],
	});
	window.Beacon('open');
};

export const logout = (endActiveChat = true) => {
	if (isIntercomInitialized) {
		shutdown();
	}
	window.Beacon('logout', { endActiveChat });
};
