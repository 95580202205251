import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import * as routes from 'pkg/router/routes';
import DateTime from 'pkg/datetime';
import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import Link from 'pkg/router/Link';
import copyString from 'pkg/copyString';
import { flashes } from 'pkg/actions';
import * as models from 'pkg/api/models';
import { SubscriptionTypes } from 'pkg/api/models/subscription';
import { useCurrentOrganization } from 'pkg/identity';
import { Flag, useFlag } from 'pkg/flags';

import * as Card from 'components/Card';
import Icon from 'components/icon';

import SideBarIcon from 'components/sidebar/Icon';
import * as SideBarInfo from 'components/sidebar/Info';
import * as SideBarLayout from 'components/layout/side-bar-page';
import StatusLabel from 'components/payment_platform/status/label';
import Column from 'components/layout/column';
import * as SideBarList from 'components/sidebar/List';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface SideBarProps {
	subscription: models.subscription.Subscription;
	showCancelSubscriptionModal: () => void;
}

const SideBar: React.FC<React.PropsWithChildren<SideBarProps>> = ({
	subscription,
	showCancelSubscriptionModal,
}) => {
	const org = useCurrentOrganization();
	const paymentProviderContext = usePaymentProviderContext();
	const subscriptionAllowedActionsEnabled = useFlag<boolean>(
		Flag.SubscriptionAllowedActions
	);
	const newUserProfileFlag = useFlag(Flag.NewUserProfile);

	const createdAtDateTime = new DateTime(
		new Date(subscription.createdAt * 1000)
	);
	const startsAtDateTime = new DateTime(new Date(subscription.startAt * 1000));
	const endAtDateTime = new DateTime(new Date(subscription.endAt * 1000));
	const periodStartDateTime = new DateTime(
		new Date(subscription.currentPeriodStart * 1000)
	);
	const periodEndDateTime = new DateTime(
		new Date(subscription.currentPeriodEnd * 1000)
	);

	const user = subscription.customerUser;
	const billingAddress = models.user.getBillingAddress(user);
	let canEdit = models.subscription.subscriptionCanBeEdited(subscription);

	let typeString = t('Subscription');

	const handleReactivateSub = async () => {
		const req = await sdk.post(
			endpoints.Subscriptions.Reactivate(subscription.id)
		);

		if (req.ok) {
			flashActions.show({
				title: t('Successfully reactivated subscription.'),
			});
		} else {
			flashActions.show({
				title: t('Could not reactivate subscription.'),
			});
		}
	};

	const handleCopyUrl = () => {
		copyString(subscription.publicUrl);

		flashes.show({
			title: t('Link copied to clipboard!'),
		});
	};

	if (subscription.type === SubscriptionTypes.Installment) {
		typeString = t('Installment');
	}

	let canReactivate =
		paymentProviderContext.settings.allowSubscriptionReactivation &&
		(subscription.status ===
			models.subscription.SubscriptionStatus.Uncollectible ||
			subscription.status === models.subscription.SubscriptionStatus.Canceled);

	if (subscriptionAllowedActionsEnabled) {
		canEdit = models.hasAllowedAction(
			subscription,
			models.Action.SubscriptionUpdate
		);
		canReactivate = models.hasAllowedAction(
			subscription,
			models.Action.SubscriptionReactivate
		);
	}

	return (
		<SideBarLayout.SideBar>
			<SideBarLayout.SideBarBody>
				<SideBarInfo.CenterColumn>
					<SideBarIcon name="reload" />
					<SideBarInfo.Title>{models.user.fullName(user)}</SideBarInfo.Title>
					<div className={css.labelWrapper}>
						<StatusLabel
							status={models.subscription.getStatus(subscription)}
							jobStatus={subscription.jobStatus}
						/>
					</div>
					<ContextMenu.Menu
						toggleWith={
							<Button primary hasIcon iconPosition="right" block>
								{t(`Actions`)}
								<Icon name="chevron" rotate="90deg" />
							</Button>
						}>
						<ContextMenu.LinkItem
							disabled={!canEdit}
							href={routes.Subscription.Edit(org.id, subscription.id)}>
							<ContextMenu.ItemIcon name="edit" />
							{t('Edit')}
						</ContextMenu.LinkItem>
						{canReactivate && (
							<ContextMenu.ConfirmItem
								message={t(
									'Are you sure you want to reactivate this subscription plan?'
								)}
								confirmLabel={t('Yes')}
								onConfirm={handleReactivateSub}>
								<ContextMenu.ItemIcon name="redo" />
								{t('Reactivate')}
							</ContextMenu.ConfirmItem>
						)}
						<ContextMenu.Item
							caution
							disabled={!canEdit}
							onClick={showCancelSubscriptionModal}>
							<ContextMenu.ItemIcon name="archive" />
							{t(`Cancel subscription`)}
						</ContextMenu.Item>
					</ContextMenu.Menu>
				</SideBarInfo.CenterColumn>
			</SideBarLayout.SideBarBody>
			<Card.Divider />
			<SideBarLayout.SideBarBody>
				<Column spacing={styles.spacing._8}>
					<Column>
						<SideBarList.Header>
							<SideBarList.Title>{t(`Subscription details`)}</SideBarList.Title>
						</SideBarList.Header>
						<SideBarList.Row>
							<SideBarList.Name>{t('Created')}</SideBarList.Name>
							<span>
								{createdAtDateTime.toLocaleDateString({
									month: 'short',
									day: 'numeric',
									year: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
								})}
							</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Starts on`)}</SideBarList.Name>
							<span>
								{startsAtDateTime.toLocaleDateString({
									month: 'short',
									day: 'numeric',
									year: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
								})}
							</span>
						</SideBarList.Row>
						{subscription.endAt && (
							<SideBarList.Row>
								<SideBarList.Name>{t(`Cancels at`)}</SideBarList.Name>
								<span>
									{endAtDateTime.toLocaleDateString({
										month: 'short',
										day: 'numeric',
										year: 'numeric',
										hour: 'numeric',
										minute: 'numeric',
									})}
								</span>
							</SideBarList.Row>
						)}
						{subscription.currentPeriodStart &&
							subscription.currentPeriodEnd && (
								<SideBarList.Row>
									<SideBarList.Name>{t(`Current period`)}</SideBarList.Name>
									<span>
										{`${periodStartDateTime.toLocaleDateString({
											month: 'short',
											day: 'numeric',
										})} — ${periodEndDateTime.toLocaleDateString({
											month: 'short',
											day: 'numeric',
											year: 'numeric',
										})}`}
									</span>
								</SideBarList.Row>
							)}
						<SideBarList.Row>
							<SideBarList.Name>{t(`ID`)}</SideBarList.Name>
							<span>{subscription.paymentProviderId}</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Collection method`)}</SideBarList.Name>
							<span>
								{models.subscription.getCollectionMethodString(subscription)}
							</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t('Type')}</SideBarList.Name>
							<span>{typeString}</span>
						</SideBarList.Row>
						{subscription.publicUrl && (
							<SideBarList.Row>
								<SideBarList.Name>
									{t('Subscription link')}{' '}
									<Link onClick={handleCopyUrl}>
										<Icon name="collection" />
										<span>{t('Copy')}</span>
									</Link>
								</SideBarList.Name>

								<Link href={subscription.publicUrl}>
									{subscription.publicUrl}
								</Link>
							</SideBarList.Row>
						)}
					</Column>
					<Column>
						<SideBarList.Header>
							<SideBarList.Title>{t(`Billed to`)}</SideBarList.Title>
						</SideBarList.Header>
						<SideBarList.Row>
							<SideBarList.Name>{t(`First name`)}</SideBarList.Name>
							<span>{billingAddress.firstName}</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Last name`)}</SideBarList.Name>
							<span>{billingAddress.lastName}</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Email`)}</SideBarList.Name>
							<span>
								{billingAddress.email}{' '}
								<Link
									href={
										newUserProfileFlag
											? routes.Organization.User.Profile.Show(
													org.id,
													user.id,
													'overview'
												)
											: routes.Management.Contact.Show(
													org.id,
													user.id,
													'overview'
												)
									}>
									{t('View')}
								</Link>
							</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Phone number`)}</SideBarList.Name>
							<span>{billingAddress.phoneNumber}</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Address`)}</SideBarList.Name>
							<span>{billingAddress.address}</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`City`)}</SideBarList.Name>
							<span>{billingAddress.city}</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`Country`)}</SideBarList.Name>
							<span>{billingAddress.country}</span>
						</SideBarList.Row>
						<SideBarList.Row>
							<SideBarList.Name>{t(`ZIP code`)}</SideBarList.Name>
							<span>{billingAddress.postalCode}</span>
						</SideBarList.Row>
					</Column>
				</Column>
			</SideBarLayout.SideBarBody>
		</SideBarLayout.SideBar>
	);
};

export default SideBar;
