import { JSX } from 'react';

import { useEventDispatcher } from 'pkg/hooks/events';
import useKeyBindings from 'pkg/hooks/useKeyBindings';

import { usePlayerState } from 'components/video-analytics/PlayerState';

export default function KeyBindings(): JSX.Element {
	const { controller } = usePlayerState();

	const handleArrowUp = useEventDispatcher('video-arrow-up');
	const handleArrowDown = useEventDispatcher('video-arrow-down');
	const handleArrowLeft = useEventDispatcher('video-arrow-left');
	const handleArrowRight = useEventDispatcher('video-arrow-right');
	const handleSpacebar = useEventDispatcher('video-spacebar');

	useKeyBindings(
		{
			onArrowUp: () => handleArrowUp,
			onArrowDown: () => handleArrowDown,
			onArrowLeft: () => handleArrowLeft,
			onArrowRight: () => handleArrowRight,
			onSpacebar: () => handleSpacebar,
		},
		window,
		[controller]
	);

	return null;
}
