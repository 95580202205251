import { JSX } from 'react';
import styled, { css, keyframes } from 'styled-components';

import * as palette from 'pkg/config/palette';

import rgba from 'pkg/rgba';

const progress = keyframes`
	0% {
		width: 0;
	}

	100% {
		width: 100%;
	}
`;

const ProgressBarStyles = css`
	height: 20px;
	background: ${palette.gray[300]};
	border-radius: 12.5px;
	overflow: hidden;
`;

const ProgressBarOuter = styled.div`
	${ProgressBarStyles};
`;

export const FakeProgressBar = styled.div`
	${ProgressBarStyles};

	&::before {
		content: '';
		display: block;
		position: relative;
		background: var(--palette-blue-500);
		height: 100%;
		transition: width ease-out 200ms;
		max-width: 100%;
		animation: ${progress} 3s ease-in-out;
	}
`;

const ProgressBarInner = styled.div<ProgressBarProps>`
	display: block;
	position: relative;
	width: ${(props) => props.progress}%;
	background: ${palette.blue[500]};
	height: 100%;
	transition: width ease-out 200ms;
	max-width: 100%;

	&::after {
		content: '${(props) =>
			props.completed ? props.label : props.progress + '%'}';
		font-size: var(--font-size-xs);
		font-weight: var(--font-weight-semibold);
		position: absolute;
		top: 50%;
		right: ${(props) => (props.completed ? '50%' : '5px')};
		transform: ${(props) =>
			props.completed ? 'translate(50%, -50%)' : 'translateY(-50%)'};
		color: ${rgba(palette.white, 0.8)};
	}
`;

type ProgressBarProps = {
	completed: boolean;
	progress: number;
	label: string;
};

const ProgressBar = ({
	completed,
	progress,
	label,
}: ProgressBarProps): JSX.Element => {
	return (
		<ProgressBarOuter>
			<ProgressBarInner
				completed={completed}
				progress={progress}
				label={label}
			/>
		</ProgressBarOuter>
	);
};

export default ProgressBar;
