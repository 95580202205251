import { t } from '@transifex/native';

import Price from 'pkg/models/price';

import { normalizedDispatch } from 'pkg/actions/utils';
import * as productServices from 'pkg/actions/services/products';

import store from 'pkg/store/createStore';
import * as models from 'pkg/api/models';
import { flashes } from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';

export const create = async (
	payload: productServices.PricePayload,
	recurringOptionString: models.providerSettings.RecurringOptionStrings,
	providerSettings: models.providerSettings.ProviderSettings
): Promise<[Response, any]> => {
	if (payload.recurring && recurringOptionString !== 'custom') {
		const recurringOption =
			providerSettings.availableRecurringOptions[recurringOptionString];

		payload.recurringInterval = recurringOption.recurringInterval;
		payload.recurringIntervalCount = recurringOption.recurringIntervalCount;
	}

	const [request, response] = await models.create<
		productServices.PricePayload,
		models.productPrice.ProductPrice
	>(endpoints.ProductPrices.Create(), payload);

	if (!request.ok) {
		return [request, null];
	}

	normalizedDispatch(response, Price.normalizr())(store.dispatch);

	return [request, response];
};

export const update = async (
	priceId: number,
	payload: productServices.IUpdatePricePayload
): Promise<boolean> => {
	const request = await productServices.updateProductPrice(priceId, payload);

	if (!request.ok) {
		flashes.show({
			title: t('Something went wrong!'),
			message: t(
				`If you think this error isn't supposed to happen, please contact 360Player support.`
			),
		});
		return false;
	}

	return true;
};
