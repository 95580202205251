import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { MembershipRole } from 'pkg/models/membership';

import Link from 'pkg/router/Link';
import { Account } from 'pkg/api/models/onboarding_info';
import * as actions from 'pkg/actions';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';

import {
	fetchCurrentAccount,
	useOnboardingContext,
} from 'routes/public/onboarding';
import {
	useOnboardingAccountPayload,
	OnboardingAccountPayload,
	useOnboardingState,
} from 'routes/public/hooks';
import FooterNote from 'routes/public/styles/FooterNote';
import Title from 'routes/public/styles/Title';
import LogoutButton from 'routes/public/styles/LogoutButton';
import * as publicStyles from 'routes/public/styles/styles.css';
import AccountDetailsForm, {
	AccountDetailsPayload,
} from 'routes/public/steps/shared/AccountDetailsForm';

import Column from 'components/layout/column';

export default function WardAccountDetails(): JSX.Element {
	const { goTo, resetStore } = useOnboardingContext();
	const onboardingState = useOnboardingState();

	const targetAccountFromState = onboardingState.get<Account>('targetAccount');

	const [isSaving, setIsSaving] = useState(false);
	const [payload, setPayload] = useMixedState<AccountDetailsPayload>({
		...targetAccountFromState,
	});

	const getPayload = useOnboardingAccountPayload();

	const canContinue =
		payload.email &&
		payload.email === payload.emailConfirmation &&
		payload.password &&
		payload.password === payload.passwordConfirmation;

	const handleSave = async () => {
		setIsSaving(true);

		const [ok, parentAccount] = await fetchCurrentAccount();

		if (!ok) {
			actions.flashes.show({
				title: t(`Couldn't auth user`),
			});

			resetStore();
			goTo('start');
			return;
		}

		const wardAccount: Account = {
			...targetAccountFromState,
			email: payload.email,
			password: payload.password,
			countryId: parentAccount.countryId,
		};

		await onboardingState.setTargetAccount(wardAccount);

		let canContinue: boolean;

		try {
			const [request, wardAccount] = await api.post<
				models.account.Account,
				OnboardingAccountPayload
			>(endpoints.Accounts.CreateChildAccountForAccount(parentAccount.id), {
				...getPayload(true),
				email: payload.email,
				password: payload.password,
			});

			if (!request.ok) {
				setIsSaving(false);

				throw new Error(`${request.status}`);
			}

			const groupCode = onboardingState.get<string>('groupCode');

			const [joinRequest] = await api
				.forAccount(wardAccount.id)
				.post(endpoints.Groups.JoinFromInviteCode(), {
					code: groupCode,
					role: MembershipRole.User,
				});

			if (!joinRequest.ok) {
				setIsSaving(false);

				throw new Error(`${joinRequest.status}`);
			}

			canContinue = true;
		} catch (error: unknown) {
			canContinue = false;

			const status = Number.parseInt((error as Error).message, 10);

			let title = t('Something went wrong');
			let message = t(
				`If you think this error isn't supposed to happen, please contact 360Player support.`
			);

			switch (status) {
				case 400:
					title = t('Could not create account');
					break;
				case 409:
					title = t('Email already in use');
					message = t('This email is already registered in 360Player');
					break;
			}

			actions.flashes.show({
				title,
				message,
			});
		} finally {
			if (!canContinue) return;

			goTo('ward-finished');
		}
	};

	return (
		<Fragment>
			<Column spacing={styles.spacing._8} className={publicStyles.main}>
				<Title title={t('Create account')} />

				<AccountDetailsForm
					handleSave={handleSave}
					payload={payload}
					setPayload={setPayload}
					canContinue={canContinue}
					isSaving={isSaving}
					isWard
				/>

				<FooterNote>
					<T
						_str="By creating your child's account you agree to our {terms_and_conditions_link}"
						terms_and_conditions_link={
							<Link href="https://www.360player.com/terms-of-service">
								{t('terms and conditions')}
							</Link>
						}
					/>
				</FooterNote>
			</Column>

			<div className={publicStyles.footer}>
				<LogoutButton />
			</div>
		</Fragment>
	);
}
