import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import { crash } from 'pkg/errors/errors';
import * as endpoints from 'pkg/api/endpoints/auto';
import DateTime from 'pkg/datetime';
import * as sdk from 'pkg/core/sdk';
import * as models from 'pkg/api/models';

export async function deleteAttachment(attachmentId: number, postId: number) {
	return sdk.destroy(
		endpoints.GroupPosts.RemoveAttachment(postId, attachmentId)
	);
}

interface Payload {
	newAttachments: models.attachment.Attachment[];
	content: string;
	title: string;
	status: string;
	authorGroupId?: number;
	isSticky: boolean;
	stickyUntil: number;
	commentsDisabled: boolean;
	notify: boolean;
	publishedAt: number;
	visibleToLegalGuardians: boolean;
	visibleToUsers: boolean;
	visibleToOrganization: boolean;
	isPublic: boolean;
	includeGroupIds: [];
}

export const updateGroupPost = async (postId: number, payload: Payload) => {
	await Promise.all(
		Object.values(payload.newAttachments || {}).map((a) => {
			return sdk.post(endpoints.GroupPosts.AddAttachment(postId, a.id));
		})
	);

	const request = await sdk.patch(
		endpoints.GroupPosts.Update(postId),
		{},
		payload
	);
	const result = await request.json();

	if (request.ok) {
		flashActions.show(
			{
				title: t(`Post saved`),
			},
			request.status,
			'add-post'
		);
	}

	return [request, result];
};

export const createGroupPost = async (groupId: number, payload: Payload) => {
	const [request, response] = await models.create<
		Payload,
		models.groupPost.GroupPost
	>(endpoints.GroupPosts.Create(groupId), payload);

	if (request.ok) {
		response.attachments = [];

		Object.values(payload.newAttachments || {}).forEach((a) => {
			response.attachments.push(a);
			sdk.post(endpoints.GroupPosts.AddAttachment(response.id, a.id));
		});

		let flashTitle = '';
		let flashMessage = '';
		switch (response.status) {
			case 'draft':
				flashTitle = t('Draft created');
				flashMessage = t('Find it in drafts');
				break;
			case 'published':
				flashTitle = t('Post created');
				flashMessage = t(`It's now available on the wall`);
				break;
			case 'scheduled':
				flashTitle = t('Post scheduled');
				flashMessage = t('It will auto publish on {publish_date}', {
					publish_date: new DateTime(
						new Date(response.publishedAt * 1000)
					).toLocaleDateString(),
				});
				break;
		}

		flashActions.show(
			{
				title: flashTitle,
				message: flashMessage,
			},
			request.status,
			'add-post'
		);
	}

	return [request, response];
};

export const markAsSeen = (userId: number, groupId: number) => {
	sdk.post(endpoints.GroupPosts.AddView(groupId));
};

export const deleteGroupPost = async (id: number) => {
	const request = await sdk.destroy(endpoints.GroupPosts.Delete(id), {});
	if (request.ok) {
		flashActions.show(
			{
				title: t(`Post removed`),
			},
			200,
			'add-post'
		);
		return true;
	}

	return false;
};

export const toggleLike = async (
	isLiked: boolean,
	post: models.groupPost.GroupPost
) => {
	if (isLiked) {
		sdk.destroy(endpoints.GroupPosts.AddLike(post.id), {
			id: post.id,
		});
	} else {
		models.create<null, models.groupPostComment.GroupPostComment>(
			endpoints.GroupPosts.AddLike(post.id),
			null
		);
	}
};

export const addComment = async (
	userId: number,
	postId: number,
	commentText: string
) => {
	const [request, response, error] = await models.create<
		{ userId: number; comment: string },
		models.groupPostComment.GroupPostComment
	>(endpoints.GroupPosts.AddComment(postId), { userId, comment: commentText });

	if (!request.ok) {
		flashActions.show({
			title: crash().title,
			message: error.error,
		});

		return null;
	}

	return response;
};

export const updateComment = async (id: number, comment: string) => {
	const req = await sdk.patch(
		endpoints.GroupPosts.UpdateComment(id),
		{},
		{ comment }
	);

	if (!req.ok) {
		return;
	}

	const resp = await req.json();
	return resp;
};

export const deleteComment = async (commentId: number) => {
	const req = await sdk.destroy(endpoints.GroupPosts.DeleteComment(commentId));
	if (!req.ok) {
		return false;
	}

	return true;
};
