import { JSX, ChangeEvent, useState } from 'react';

import spacing from 'pkg/config/spacing';

import * as formatting from 'routes/kitchen_sink/formatting.css';

import CountrySelect from 'components/CountrySelect';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

export default function CountrySelectView(): JSX.Element {
	const [country, setCountry] = useState<string>('');
	const [suggested, setSuggested] = useState<string>('');

	const handleCountryChange = (event: ChangeEvent<HTMLOptionElement>) => {
		setCountry(event.target.value);
	};

	const handleSuggestedChange = (event: ChangeEvent<HTMLOptionElement>) => {
		setSuggested(event.target.value);
	};

	return (
		<Column spacing={spacing._7}>
			<h1 className={formatting.h1}>Country Select</h1>

			<p>
				Country select is a global <strong>controlled</strong> component used to
				display countries and nationalities.
			</p>

			<hr className={formatting.divider} />

			<h2 className={formatting.h2}>Country Select</h2>

			<Column spacing={spacing._7}>
				<Input.Group label="Country Selector">
					<CountrySelect
						name="country"
						value={country}
						onChange={handleCountryChange}
					/>
				</Input.Group>

				<Input.Group label="Country Selector with suggested country">
					<CountrySelect
						name="suggested"
						suggestedCountry="se"
						value={suggested}
						onChange={handleSuggestedChange}
					/>
				</Input.Group>
			</Column>

			<hr className={formatting.divider} />

			<h2 className={formatting.h2}>Nationality Selector</h2>

			<p>
				Used by setting <code className={formatting.code}>role</code> to{' '}
				<code className={formatting.code}>"nationality"</code>.
			</p>

			<Input.Group label="Nationality Selector">
				<CountrySelect
					name="suggested"
					role="nationality"
					suggestedCountry="se"
					value={suggested}
					onChange={handleSuggestedChange}
				/>
			</Input.Group>
		</Column>
	);
}
