import { useT } from '@transifex/react';
import { ChangeEvent, ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { cssClasses, cssVarList } from 'pkg/css/utils';

import * as css from './styles.css';

interface EditorProps {
	// @note min/max height, max height is focused/filled height
	minHeight?: number;
	maxHeight?: number;

	placeholder?: string;
	value: string;

	onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;

	spacious?: boolean;

	innerContent?: ReactNode | ReactNode[];
	children?: ReactNode | ReactNode[];
}

export default function Editor({
	minHeight = 45,
	maxHeight = 100,

	placeholder,
	value,

	onChange,

	spacious,

	innerContent,
	children,
}: EditorProps): JSX.Element {
	const t = useT();

	if (!placeholder) {
		placeholder = t('Start typing…');
	}

	const vars = cssVarList({
		'min-height': `${minHeight}px`,
		'max-height': `${maxHeight}px`,
	});

	return (
		<fieldset className={css.wrapper} style={vars}>
			<textarea
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				className={cssClasses(
					css.textArea,
					spacious ? css.spacious : null
				)}></textarea>
			<AnimatePresence>
				{innerContent && (
					<motion.div
						layout
						transition={{
							default: { ease: 'linear' },
							layout: { duration: 0.3 },
						}}
						className={css.innerContent}>
						{innerContent}
					</motion.div>
				)}
			</AnimatePresence>
			<section className={css.actionBar}>{children}</section>
		</fieldset>
	);
}
