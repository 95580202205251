import { JSX, Fragment, FunctionComponent } from 'react';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import AppearanceSettings from 'routes/group/settings/AppearanceSettings';
import CoreSettings from 'routes/group/settings/core_settings';
import InviteCodeSettings from 'routes/group/settings/GroupCodeSettings';
import LOK from 'routes/group/settings/tabs/Lok';
import CustomLinks from 'routes/group/settings/tabs/group_links';
import ContactSettings from 'routes/group/settings/ContactSettings';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import Column from 'components/layout/column';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';

interface GroupSettingsProps {
	group: models.group.Group;
	currentTab: string;
	refresh: () => void;
	replaceRecord?: (record: models.group.Group) => void;
}

const OrganizationSettings: FunctionComponent<GroupSettingsProps> = ({
	group,
	currentTab,
	refresh,
	replaceRecord,
}): JSX.Element => {
	const groupHasCustomLinksEnabled =
		group && models.group.hasFeature(group, models.group.Features.CustomLinks);

	const renderTabViews = (currentTab: string) => {
		switch (currentTab) {
			case 'lok': {
				return <LOK groupId={group.id} />;
			}
			case 'custom-links': {
				return groupHasCustomLinksEnabled ? (
					<CustomLinks groupId={group.id} />
				) : null;
			}

			default:
				return (
					<Column spacing={styles.spacing._6}>
						<InviteCodeSettings group={group} refresh={refresh} />
						<CoreSettings
							group={group}
							replaceRecord={replaceRecord}
							refresh={refresh}
						/>
						<ContactSettings groupId={group.id} />
						<AppearanceSettings group={group} refresh={refresh} />
					</Column>
				);
		}
	};

	const wideViews = ['user-management', 'organization'];
	const fullScreenViews = ['user-fields', 'custom-links'];

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Wrapper key={currentTab}>
					{fullScreenViews.includes(currentTab) ? (
						renderTabViews(currentTab)
					) : (
						<LargeScreenContent.Inner
							maxWidth={
								wideViews.includes(currentTab)
									? PageWidths.WIDE
									: PageWidths.STANDARD
							}>
							{renderTabViews(currentTab)}
						</LargeScreenContent.Inner>
					)}
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Wrapper key={currentTab}>
					{fullScreenViews.includes(currentTab) ? (
						renderTabViews(currentTab)
					) : (
						<LargeScreenContent.Inner
							maxWidth={
								wideViews.includes(currentTab)
									? PageWidths.WIDE
									: PageWidths.STANDARD
							}>
							{renderTabViews(currentTab)}
						</LargeScreenContent.Inner>
					)}
				</LargeScreenContent.Wrapper>
			</SmallScreen>
		</Fragment>
	);
};

export default OrganizationSettings;
