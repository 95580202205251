import { useT } from '@transifex/react';

import { Flag, useFlag } from 'pkg/flags';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as routes from 'pkg/router/routes';

import { useTemplateContext } from 'components/layout/page-templates/context';

interface Props {
	organizationId: number;
}

export function useReportsNavigation({ organizationId }: Props) {
	const t = useT();
	const flag = useFlag(Flag.RevenueTable);
	const { setPageSubNavItems } = useTemplateContext();

	useComponentDidMount(() => {
		const navItems = [
			{
				title: t('Payouts'),
				href: routes.Payments.Payouts(organizationId),
			},
		];

		if (flag) {
			navItems.push({
				title: t('Revenue'),
				href: routes.Payments.Revenue(organizationId),
			});
		}

		setPageSubNavItems(navItems);
	});
}
