import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';

import spacing from 'pkg/config/spacing';

import { StartLoginStateFlows } from 'pkg/actions/sso';
import { AuthOptions } from 'pkg/actions/auth';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import Link from 'pkg/router/Link';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentRoute } from 'pkg/router/hooks';

import { useOnboardingState } from 'routes/public/hooks';
import Title from 'routes/public/styles/Title';
import Button from 'routes/public/styles/Button';
import FooterNote from 'routes/public/styles/FooterNote';
import * as publicStyles from 'routes/public/onboarding/styles.css';
import {
	OnboardingAnimationDirection,
	useOnboardingContext,
} from 'routes/public/onboarding';

import Badge from 'components/Badge';

import Column from 'components/layout/column';

interface FetchAuthOptionsProps {
	slug: string;
	afterFetch: (resp: AuthOptions) => void;
}

function FetchAuthOptions({
	slug,
	afterFetch,
}: FetchAuthOptionsProps): JSX.Element {
	useEndpoint<AuthOptions>(
		endpoints.Auth.Options(slug as string),
		{},
		(resp) => {
			afterFetch(resp);
		}
	);

	return null;
}

export default function SSOLogin() {
	const onboardingCtx = useOnboardingContext();
	const onboardingState = useOnboardingState();
	const identityProviders =
		onboardingState.get<models.identityProvider.ScrubbedIdentityProvider[]>(
			'identityProviders'
		);

	const group = onboardingState.get<models.onboardingInfo.Group>('group');
	const route = useCurrentRoute();

	const handleIdpLogin = (
		identityProvider: models.identityProvider.ScrubbedIdentityProvider
	) => {
		actions.sso.startLogin({
			identityProvider,
			state: {
				flow: StartLoginStateFlows.Login,
			},
		});
	};

	const afterFetch = (resp: AuthOptions) => {
		onboardingState.set({
			group: {
				organizationId: resp.organizationId,
				groupName: resp.organizationName,
				organizationName: resp.organizationName,
				isOrganization: true,
				profileImageUrl: resp.profileImageUrl,
				primaryColor: resp.primaryColor,
				country: null,
			},
			identityProviders: resp.identityProviders,
		});
	};

	useComponentDidMount(() => {
		if (!group && !route?.slug) {
			onboardingCtx.goTo('sso-org-slug', OnboardingAnimationDirection.Out);
		}
	});

	return (
		<Fragment>
			{route?.slug && (
				<FetchAuthOptions slug={route.slug} afterFetch={afterFetch} />
			)}
			<Column spacing={spacing._8}>
				<Column justify="center">
					<Badge badgeUrl={group?.profileImageUrl} size="90px" />
					<Column spacing={spacing._1} justify="center">
						<span className={publicStyles.faded}>{t('Sign in to')}</span>
						<Title title={group?.groupName} center />
					</Column>
				</Column>

				<Column spacing={spacing._5}>
					<span className={publicStyles.description}>
						{t('Select authentication method to continue')}
					</span>
					<Column spacing={spacing._4}>
						{identityProviders?.map((idp) => (
							<Button
								key={idp.id}
								label={t('Sign in with {name}', { name: idp.displayName })}
								onClick={() => handleIdpLogin(idp)}
							/>
						))}
					</Column>
				</Column>

				<FooterNote>
					<Link href="https://help.360player.com/article/213-login-using-sso">
						{t('Need help?')}
					</Link>
				</FooterNote>
			</Column>
		</Fragment>
	);
}
