import { JSX, ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding-top: calc(100% * (9 / 16));
	position: relative;

	+ a[href='#read-more'] {
		margin-top: var(--spacing-4);
	}
`;

const Container = styled.div`
	background: var(--palette-black);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border-radius: var(--radius-5);

	iframe,
	video {
		width: 100%;
		height: 100%;
		border-radius: var(--radius-5);
		outline: none;
	}
`;

interface EmbedProps {
	html?: string;
	children?: ReactElement | ReactElement[];
}

export default function Embed({ html, children }: EmbedProps): JSX.Element {
	return (
		<Wrapper>
			{html ? (
				<Container dangerouslySetInnerHTML={{ __html: html }} />
			) : (
				<Container>{children}</Container>
			)}
		</Wrapper>
	);
}
