import { JSX, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'pkg/actions';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRoute } from 'pkg/router/hooks';
import { RootState } from 'pkg/reducers';
import { cuePointsFromSequences } from 'pkg/video';
import * as selectors from 'pkg/selectors';
import { useWebSocketSubscription } from 'pkg/websocket';

import {
	FilterHighlights,
	FilterPrivate,
	useFilterState,
} from 'components/video-analytics/FilterState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { CueGroupType, useCueState } from 'components/video-analytics/CueState';

export default function Bootstrap(): JSX.Element {
	const dispatch = useDispatch();

	const { videoId, query } = useCurrentRoute();
	const { source, setSource, setDuration, setGaplessPlayback } =
		usePlayerState();
	const { setCuePoints } = useCueState();
	const filters = useFilterState();

	const video = useSelector((state: RootState) =>
		selectors.videos.find(state, videoId)
	);

	const filteredSequences = useSelector((state: RootState) =>
		selectors.videoSequences.findAllFilteredSequences(state, videoId, filters)
	);

	const numFilteredSequences = filteredSequences.size;

	useWebSocketSubscription(`p.video.${videoId}`);

	useComponentDidMount(async () => {
		setGaplessPlayback(true);

		if (query.hasOwnProperty('search')) {
			filters.setSearch(query.search);
		}

		if (query.hasOwnProperty('authors')) {
			const authorIds = query.authors
				.split(',')
				.map((n: string) => Number.parseInt(n, 10));

			filters.setAuthors(authorIds);
		}

		if (query.hasOwnProperty('tagged')) {
			const taggedUserIds = query.tagged
				.split(',')
				.map((n: string) => Number.parseInt(n, 10));

			filters.setTaggedUsers(taggedUserIds);
		}

		if (query.hasOwnProperty('highlights')) {
			let highlightClips = FilterHighlights.Show;

			if (query.highlights === '1') {
				highlightClips = FilterHighlights.Only;
			} else if (query.highlights === '0') {
				highlightClips = FilterHighlights.Hide;
			}

			filters.setHighlights(highlightClips);
		}

		if (query.hasOwnProperty('private')) {
			let privateClips = FilterPrivate.Show;

			if (query.private === '1') {
				privateClips = FilterPrivate.Only;
			} else if (query.private === '0') {
				privateClips = FilterPrivate.Hide;
			}

			filters.setPrivate(privateClips);
		}
	});

	const updateVideoDuration = (duration: number) => {
		const canUpdate = video.hasIn(['links', 'edit']);
		const shouldUpdate = duration > 0 && video.duration !== duration;

		if (canUpdate && shouldUpdate) {
			dispatch(actions.videos.updateVideo(video.id, { duration }, false));
		}
	};

	useEffect(() => {
		if (!source && video.uri) {
			setSource(video.uri, {
				onHasDuration: (duration: number) => {
					setDuration(duration);
					// Save duration in MS
					updateVideoDuration(Math.round(duration * 1000));
				},
			});

			setGaplessPlayback(false);
			setDuration(video.duration / 1000);
		}
	}, [video.uri]);

	useEffect(() => {
		if (numFilteredSequences > 0) {
			const cuePoints = cuePointsFromSequences(filteredSequences);

			setCuePoints(cuePoints, CueGroupType.All);
		}
	}, [numFilteredSequences]);

	return null;
}
