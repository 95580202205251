import { JSX, Fragment, ReactNode, RefObject, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import * as share from 'pkg/share';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useVideoAspectRatio } from 'pkg/hooks/dimensions';

import SmallScreenRearrangeLayout from 'routes/video/layouts/SmallScreenRearrangeLayout';
import SmallScreenAnalyzeLayout from 'routes/video/layouts/SmallScreenAnalyzeLayout';
import ErrorOverlay from 'routes/video/shared/ErrorOverlay';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import * as Card from 'components/Card';
import TextOverflow from 'components/TextOverflow';

import Back from 'components/navigation/Back';
import * as LargeScreenHeader from 'components/navigation/header/large_screen/Styles';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as SmallScreenHeader from 'components/navigation/header/small_screen/Styles';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import { useCueState } from 'components/video-analytics/CueState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import InfoBox from 'components/form/info-box';
import Annotations from 'components/annotations/Annotations';
import VideoDescriptionModal from 'components/video-library/modals/VideoDescription';
import Row from 'components/layout/row';
import { useAppState } from 'components/application/state';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

enum LayoutSizes {
	Controls = 50,
	WideSidebar = 320,
	NarrowSidebar = 260,
}

const ContextMenuWrapper = styled.span`
	position: relative;
	display: inline-block;
	left: -10px;
	top: -3px;
	width: 20px;
	height: 20px;

	@media ${styles.breakpoint.small} {
		left: 0px;
		top: -8px;
	}
`;

const Wrapper = styled.div`
	padding: var(--spacing-3);
	padding-top: 0;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr ${LayoutSizes.NarrowSidebar}px;

	@media ${styles.breakpoint.nolimit} {
		grid-template-columns: 1fr ${LayoutSizes.WideSidebar}px;
	}

	@media ${styles.breakpoint.small} {
		padding: 0;
		display: block;
		overflow: auto;
	}

	@media all and (display-mode: fullscreen) {
		grid-template-columns: 1fr 0px;
	}
`;

const Container = styled.div<{
	clampWorkspaceHeight?: boolean;
}>`
	padding-top: 0;

	${({ clampWorkspaceHeight }) =>
		clampWorkspaceHeight &&
		css`
			display: flex;
			flex-flow: column;
		`}

	@media ${styles.breakpoint.small} {
		padding: 0;
	}

	@media not ${styles.breakpoint.small} {
		overflow-x: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
`;

const CurrentClip = styled.div<{
	workspaceHeight: number;
	clampWorkspaceHeight: boolean;
}>`
	background: var(--palette-gray-800);
	border-radius: var(--radius-3);

	@media not ${styles.breakpoint.small} {
		margin-top: var(--spacing-3);
	}

	${({ workspaceHeight }) =>
		workspaceHeight > 0 &&
		css`
			min-height: ${workspaceHeight}px;
		`}

	${({ clampWorkspaceHeight }) =>
		clampWorkspaceHeight &&
		css`
			overflow: auto;
			flex: 1;
		`}
`;

const Controls = styled.div`
	padding-bottom: var(--sat);
	background-color: var(--palette-gray-800);
	color: var(--palette-gray-100);
	border-radius: 0 0 5px 5px;
	flex-shrink: 0;
	display: grid;
	grid-template-rows: auto ${LayoutSizes.Controls}px;
	z-index: ${styles.zIndex.videoAnalytics};
	font-size: var(--font-size-sm);

	&[data-standalone='true'] {
		padding-top: var(--spacing-2);
		background-color: transparent;
	}

	@media ${styles.breakpoint.small} {
		background-color: var(--palette-gray-900);
		width: 100%;
		border-radius: 0;
		position: sticky;
		bottom: 0;
		border-top: solid 1px var(--palette-gray-700);
	}
`;

const Sidebar = styled(Container)`
	@media ${styles.breakpoint.small} {
		padding: var(--spacing-5);

		&[data-is-trimming='true'] {
			padding: 0;
			height: calc((100vh * 9 / 16) - 27px);
		}
	}
`;

const Source = styled.div<{ workspaceHeight: number }>`
	background-color: #000;
	border-radius: 5px 5px 0 0;
	overflow: hidden;
	position: relative;
	display: grid;
	place-items: center;
	z-index: ${styles.zIndex.videoAnalytics};
	flex-grow: 1;

	@media ${styles.breakpoint.small} {
		border-radius: 0;
		width: 100%;
		height: calc(100vw * 9 / 16);
		position: fixed;
		top: calc(60px + var(--sat));
		z-index: ${styles.zIndex.videoAnalytics + 10};
		border-bottom: solid 1px var(--palette-gray-700);
		box-shadow: var(--palette-black) 0 0 10px;
	}

	@media not ${styles.breakpoint.small} {
		&[data-standalone-controls] {
			border-radius: var(--radius-3);
		}

		${({ workspaceHeight }) => css`
			height: calc(
				100vh - (${workspaceHeight + 50}px + env(safe-area-inset-bottom))
			);
		`}
	}

	@media all and (display-mode: fullscreen) {
		height: calc(100vh - (70px + env(safe-area-inset-bottom)));
	}
`;

const Video = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

const SmallScreenContainer = styled.div`
	position: relative;
	display: block;
	min-height: calc(100% - (var(--sat) + 85px));

	&::before {
		content: '';
		padding-top: 56.25%;
		display: block;
	}
`;

const InvalidSourceWrapper = styled.div`
	position: absolute;
	width: 100%;
	z-index: ${styles.zIndex.videoAnalytics + 10};
	top: 0;
	left: 0;
`;

const AspectWrapper = styled.div`
	position: relative;
`;

const VideoTitle = styled(TextOverflow)`
	max-width: 640px;
`;

interface AspectRatioWrapperProps {
	children: ReactNode | ReactNode[];
	forwardedRef: RefObject<HTMLElement>;
}

function AspectRatioWrapper({
	children,
	forwardedRef,
}: AspectRatioWrapperProps): JSX.Element {
	const [width, height] = useVideoAspectRatio(forwardedRef);

	return (
		<AspectWrapper
			style={{
				width,
				height,
			}}>
			{children}
		</AspectWrapper>
	);
}

export enum LayoutContext {
	Analyze,
	Playlist,
	Play,
}

interface LayoutProps {
	context: LayoutContext;

	title?: string;
	subTitle?: string;
	pageAction?: ReactNode;
	contextItems?: ReactNode;

	bootstrap?: ReactNode;
	videoSource: ReactNode;
	currentClip: ReactNode;
	clipList: ReactNode;
	controls: ReactNode;
	timeline: ReactNode;

	clampWorkspaceHeight?: boolean;
	workspaceHeight?: number;
}

export default function Layout({
	context,
	title,
	subTitle,
	pageAction,
	contextItems,

	bootstrap,
	videoSource,
	currentClip,
	clipList,
	controls,
	timeline,

	clampWorkspaceHeight = false,
	workspaceHeight = 140,
}: LayoutProps): JSX.Element {
	const observeableRef = useRef<HTMLDivElement>();

	const cueState = useCueState();
	const playerState = usePlayerState();

	const isSmallScreen = useMediaQuery({ maxWidth: styles.breakpoint.toMedium });
	const useStandalone = !isSmallScreen;

	const { keyboardOpen } = useAppState();

	useComponentDidMount(
		() => {
			if (context === LayoutContext.Playlist) {
				playerState.setGaplessPlayback(true);
			}
		},
		() => {
			cueState.reset();
			playerState.reset();
		}
	);

	const layout = (
		<Wrapper>
			<Container clampWorkspaceHeight={clampWorkspaceHeight}>
				<Source
					ref={observeableRef}
					workspaceHeight={workspaceHeight}
					data-standalone-controls={useStandalone}>
					{playerState.isReady && !playerState.hasValidSource && (
						<InvalidSourceWrapper>
							<InfoBox color="red" text={t('Invalid video source URL')} />
						</InvalidSourceWrapper>
					)}
					<AspectRatioWrapper forwardedRef={observeableRef}>
						<Annotations>
							<Video>{videoSource}</Video>
						</Annotations>
					</AspectRatioWrapper>
				</Source>
				<LargeScreen>
					{!keyboardOpen && (
						<Controls data-standalone={useStandalone}>
							{timeline}
							{controls}
						</Controls>
					)}
					<CurrentClip
						clampWorkspaceHeight={clampWorkspaceHeight}
						workspaceHeight={clampWorkspaceHeight ? workspaceHeight : 0}>
						<Card.Base>
							<Card.Body>{currentClip}</Card.Body>
						</Card.Base>
					</CurrentClip>
				</LargeScreen>
			</Container>
			<LargeScreen>
				<Sidebar>{clipList}</Sidebar>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenContainer>
					<Sidebar data-is-trimming={playerState.isTrimming}>
						{playerState.isTrimming ? currentClip : clipList}
					</Sidebar>
				</SmallScreenContainer>
				{!playerState.isEditing && !keyboardOpen && (
					<Controls>
						{timeline}
						{controls}
					</Controls>
				)}
			</SmallScreen>
		</Wrapper>
	);

	if (isSmallScreen && playerState.isRearranging) {
		return (
			<Fragment>
				<SmallScreenHeader.Header compact>
					<Back />
					<SmallScreenHeader.PageTitle compact>
						{t('Rearrange clips')}
					</SmallScreenHeader.PageTitle>
				</SmallScreenHeader.Header>
				<SmallScreenContent noScroll noBorderRadius noBackground>
					<SmallScreenRearrangeLayout>{clipList}</SmallScreenRearrangeLayout>
				</SmallScreenContent>
			</Fragment>
		);
	}

	return (
		<Fragment>
			{playerState.hasError && <ErrorOverlay />}
			{bootstrap}
			<LargeScreen>
				<LargeScreenHeader.Wrap>
					<LargeScreenHeader.Header>
						<Back largeLayout />
						<LargeScreenHeader.PageTitle
							context={subTitle}
							data-testid="video.page_title">
							<Row
								columns="auto 1fr"
								align="center"
								justify="start"
								spacing={styles.spacing._2}>
								<VideoTitle>{title}</VideoTitle>
								<VideoDescriptionModal />
							</Row>
						</LargeScreenHeader.PageTitle>
						<LargeScreenHeader.Actions>
							<Button
								small
								transparent
								icon="link"
								onClick={share.copyCurrentUrl}
							/>
							{share.hasShareApi() && (
								<Button
									small
									transparent
									icon="share"
									iconSize={1.2}
									onClick={share.currentUrl}
								/>
							)}
							{contextItems && (
								<ContextMenu.Menu
									toggleWith={<Button small transparent icon="edit" />}>
									{contextItems}
								</ContextMenu.Menu>
							)}
							{pageAction}
						</LargeScreenHeader.Actions>
					</LargeScreenHeader.Header>
				</LargeScreenHeader.Wrap>
				<LargeScreenContent.Wrapper disableScroll>
					<LargeScreenContent.Inner noPadding maxWidth={PageWidths.ULTRA_WIDE}>
						{layout}
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			{context === LayoutContext.Analyze ? (
				<SmallScreenAnalyzeLayout
					bootstrap={bootstrap}
					videoSource={videoSource}
					currentClip={currentClip}
					controls={controls}
					contextItems={contextItems}
				/>
			) : (
				<SmallScreen>
					<SmallScreenHeader.Header compact>
						<Back />
						<SmallScreenHeader.PageTitle compact context={subTitle}>
							<span>
								{title}
								<VideoDescriptionModal />
							</span>
						</SmallScreenHeader.PageTitle>
						{contextItems && (
							<ContextMenuWrapper>
								<ContextMenu.Menu>{contextItems}</ContextMenu.Menu>
							</ContextMenuWrapper>
						)}
					</SmallScreenHeader.Header>
					<SmallScreenContent noScroll noBorderRadius noBackground>
						{layout}
					</SmallScreenContent>
				</SmallScreen>
			)}
		</Fragment>
	);
}
