import { JSX, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { RootState } from 'pkg/reducers';
import { useCollection } from 'pkg/api/use_collection';
import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCurrentAccount } from 'pkg/identity';

import AccountDeleteModal from 'routes/account/settings/privacy/AccountDeleteModal';
import ConsentModal from 'routes/account/settings/ConsentModal';
import ChildItem from 'routes/account/settings/child-relations/active-relations/ChildItem';
import GroupItem from 'routes/account/settings/child-relations/active-relations/GroupItem';
import { Group } from 'routes/account/settings/child-relations/styles';
import ParentItem from 'routes/account/settings/child-relations/active-relations/ParentItem';
import CreateChildLoginModal from 'routes/account/settings/child-relations/active-relations/create-child-login-modal';

import * as Card from 'components/Card';
import Heading from 'components/Heading';

import Column from 'components/layout/column';
import JoinGroupModal from 'components/group/join-modal';
import InviteParentModal from 'components/account/invite-parent-modal';

const SmallHeading = styled(Heading)<{ marginTop?: boolean }>`
	font-size: var(--font-size-sm);
	margin-bottom: var(--spacing-5);

	${({ marginTop }) =>
		marginTop &&
		css`
			margin-top: var(--spacing-5);
		`}
`;

interface ActiveRelationItemProps {
	relation: models.accountRelation.AccountRelation;
	refreshRelations: () => Promise<void>;
}

function ActiveRelationItem({
	relation,
	refreshRelations,
}: ActiveRelationItemProps): JSX.Element {
	const dispatch = useDispatch();
	const account = useCurrentAccount();

	const [showModal, setShowModal] = useState<
		| 'add-to-group'
		| 'consent'
		| 'account-delete'
		| 'create-login'
		| 'invite-parent'
	>(null);

	const consentAgeThreshold =
		useSelector((state: RootState) =>
			state.countries.items.get(account.countryId)
		)?.consentAgeLimit || 16;

	const connected = useCollection<models.accountRelation.AccountRelation>(
		endpoints.AccountRelations.Index(),
		{
			queryParams: new URLSearchParams({
				account_id: relation.targetAccountId.toString(),
			}),
		}
	);

	const additionalGuardians = connected.records.filter(
		(record: models.accountRelation.AccountRelation) =>
			record.targetAccountId !== account.id
	);

	const wardUsers = relation?.targetAccount?.users;

	const removeRelation = async (relationId: number) => {
		await dispatch(actions.accountRelations.remove(relationId));
		await refreshRelations();

		const childName = models.account.fullName(relation.targetAccount);

		actions.flashes.show({
			title: t('Successfully saved!'),
			message: t('You are no longer connected to {child_name}', {
				child_name: childName,
			}),
		});
	};

	const closeModal = () => {
		setShowModal(null);
		refreshRelations();
	};

	return (
		<Fragment>
			<Card.Base $noBorder>
				<Card.Body $narrowBody>
					<ChildItem
						consentAgeThreshold={consentAgeThreshold}
						relation={relation}
						openConsentModal={() => setShowModal('consent')}
						openDeleteAccountModal={() => setShowModal('account-delete')}
						openCreateLoginModal={() => setShowModal('create-login')}
						openAddToGroupModal={() => setShowModal('add-to-group')}
						openInviteParentModal={() => setShowModal('invite-parent')}
						removeRelation={removeRelation}
					/>
					{wardUsers?.length > 0 && (
						<Fragment>
							<SmallHeading marginTop>{t('Club memberships')}</SmallHeading>
							<Column spacing={styles.spacing._5}>
								{relation.targetAccount.users.map((user) => (
									<GroupItem user={user} key={user.id} />
								))}
							</Column>
						</Fragment>
					)}
				</Card.Body>
				{additionalGuardians.length > 0 && (
					<Fragment>
						<Card.Divider />
						<Card.Body $narrowBody>
							<SmallHeading>{t('Additional legal guardians')}</SmallHeading>
							<Column>
								{additionalGuardians.map(
									(relation: models.accountRelation.AccountRelation) => (
										<ParentItem
											key={relation.id}
											relation={relation}
											removeRelation={removeRelation}
										/>
									)
								)}
							</Column>
						</Card.Body>
					</Fragment>
				)}
			</Card.Base>
			{showModal === 'consent' && (
				<ConsentModal
					relation={relation}
					onClose={closeModal}
					onSave={refreshRelations}
				/>
			)}
			{showModal === 'account-delete' && (
				<AccountDeleteModal
					account={relation.targetAccount}
					onClose={closeModal}
				/>
			)}
			{showModal === 'add-to-group' && (
				<JoinGroupModal
					targetAccount={relation.targetAccount}
					onClose={closeModal}
				/>
			)}
			{showModal === 'create-login' && (
				<CreateChildLoginModal
					account={relation.targetAccount}
					onClose={closeModal}
				/>
			)}
			{showModal === 'invite-parent' && (
				<InviteParentModal
					targetAccounts={[relation.targetAccount]}
					onClose={closeModal}
				/>
			)}
		</Fragment>
	);
}

interface ActiveRelationsProps {
	relations: models.accountRelation.AccountRelation[];
	refreshRelations: () => Promise<void>;
}

export default function ActiveRelations({
	relations,
	refreshRelations,
}: ActiveRelationsProps): JSX.Element {
	relations = relations
		.filter(
			(relation: models.accountRelation.AccountRelation) =>
				!relation.targetAccount.willBeDeletedAt
		)
		.sort(
			(
				a: models.accountRelation.AccountRelation,
				b: models.accountRelation.AccountRelation
			) => a.targetAccount.firstName.localeCompare(b.targetAccount.firstName)
		);

	if (relations.length === 0) {
		return null;
	}

	return (
		<Group>
			<Heading>{t('Child connections')}</Heading>
			{relations.map((relation: models.accountRelation.AccountRelation) => (
				<ActiveRelationItem
					key={relation.id}
					relation={relation}
					refreshRelations={refreshRelations}
				/>
			))}
		</Group>
	);
}
