import { JSX } from 'react';

import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCurrentGroupId, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { isApp } from 'pkg/platform';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import * as Drawer from 'design/navigation/drawer';

interface GroupLinkFoldersProps {
	displayChildren: string;
	handleDisplayChildren: (slug: string) => void;

	clubLobby?: boolean;
}

export default function GroupLinkFolders({
	displayChildren,
	handleDisplayChildren,
	clubLobby,
}: GroupLinkFoldersProps): JSX.Element {
	const clubLobbyParams = {
		showAllResults: true,
		queryParams: new URLSearchParams({ isClubLobby: 'true' }),
	};
	const groupId = useCurrentGroupId();
	const org = useCurrentOrganization();

	const { records: folders } =
		useCollection<models.groupLinkFolder.GroupLinkFolder>(
			endpoints.Groups.ShowGroupLinks(groupId),
			clubLobby && clubLobbyParams
		);

	if (folders.length === 0) {
		return null;
	}

	return (
		<Drawer.ItemGroup>
			{folders
				.sort((a, b) => a.sortOrder - b.sortOrder)
				.map((folder: models.groupLinkFolder.GroupLinkFolder) => {
					const safeTitle = folder.title.toLowerCase().replace(/\s+/g, '-');
					const safeSlug = encodeURIComponent(`custom-links-${safeTitle}`);

					return (
						<Drawer.ItemGroupCollapsible
							key={folder.title}
							title={folder.title}
							slug={safeSlug}
							expand={displayChildren}
							onClick={handleDisplayChildren}
							icon={folder.iconName as MaterialSymbolVariant}>
							{folder.links
								.sort((a, b) => a.sortOrder - b.sortOrder)
								.map((link) => (
									<Drawer.Item
										key={link.title}
										href={
											link.embed && !isApp()
												? routes.CustomLinks.Show(
														org.id,
														encodeURIComponent(safeTitle),
														link.id
													)
												: link.url
										}
										name={link.title}
									/>
								))}
						</Drawer.ItemGroupCollapsible>
					);
				})}
		</Drawer.ItemGroup>
	);
}
