import { JSX } from 'react';
import { t } from '@transifex/native';
import * as React from 'react';
import styled from 'styled-components';

import * as styles from 'pkg/config/styles';

import Group, { Features } from 'pkg/models/group';
import User from 'pkg/models/user';

import { useCheckRelationToUser, useGroup } from 'pkg/hooks/selectors';
import rgba from 'pkg/rgba';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { newChatParams } from 'pkg/chat';

import Badge from 'components/Badge';
import { LargeScreen } from 'components/MediaQuery';
import Avatar from 'components/avatar';

import Actions from 'components/user/profile/overview/Actions';

import Button from 'design/button';

export const Outer = styled.div`
	background: ${(props) =>
		props.theme.colorProfileGradient
			? `linear-gradient(155deg, ${props.theme.colorProfileGradient})`
			: rgba(styles.palette.navBar.backgroundColor)};
	color: var(--palette-white);
	overflow: hidden;
	margin-bottom: var(--spacing-6);

	@media ${styles.breakpoint.small} {
		background: transparent;
		overflow: visible;
		padding: 0 var(--spacing-6);
		margin-bottom: var(--spacing-8);
	}
`;

export const Branding = styled(Badge)`
	position: absolute;
	width: 300px;
	top: 0;
	left: -150px;

	-webkit-mask-image: -webkit-gradient(
		linear,
		left top,
		right bottom,
		from(rgba(0, 0, 0, 0.1)),
		to(rgba(0, 0, 0, 0))
	);
`;

const BrandedButton = styled(Button)`
	&& {
		background-color: ${(props) =>
			props.theme.accentColorFaded || styles.palette.gray[900]};
		color: ${(props) => props.theme.accentColor || styles.palette.white};
		border: none;

		&:hover {
			color: ${(props) =>
				props.theme.accentColor || styles.palette.white} !important;
			border: none !important;
			box-shadow: ${(props) =>
					props.theme.accentColorHover || styles.palette.white}
				0 0 0 4px !important;
		}
	}
`;

const MessageButton = styled(BrandedButton)`
	margin-right: var(--spacing-3);
`;

export const UserInfo = styled.div`
	margin-left: var(--spacing-5);
	max-width: 100%;

	@media ${styles.breakpoint.small} {
		margin-left: 0;
	}
`;

export const Inner = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: auto 1fr auto auto;
	padding: var(--spacing-8) 0;
	align-items: center;
	width: ${styles.size.PageWidths.STANDARD}px;
	max-width: 100%;
	margin: 0 auto;

	@media ${styles.breakpoint.small} {
		display: flex;
		flex-direction: column;
		gap: var(--spacing-3);
		padding: 0;

		${Branding} {
			left: calc(100% - 200px);
		}

		${Avatar} {
			grid-row: 1;
			grid-column: 2;
		}

		${UserInfo} {
			grid-row: 1;
			grid-column: 1;
		}
	}
`;

export const Title = styled.h1`
	font-size: var(--font-size-3xl);
	line-height: var(--font-line-height-2xl);
	font-weight: var(--font-weight-bold);
	letter-spacing: -0.4px;
	padding-right: var(--spacing-4);
	color: ${(props) => props.theme.accentColor || styles.palette.white};
	word-break: break-word;

	@media ${styles.breakpoint.small} {
		text-align: center;
		font-size: var(--font-size-2xl);
		line-height: var(--font-line-height-xl);
		padding-right: 0;
	}
`;

export const Team = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: var(--spacing-3);
	color: ${(props) => props.theme.accentColor || styles.palette.white};

	@media ${styles.breakpoint.small} {
		justify-content: center;

		span {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	${Badge} {
		margin-right: var(--spacing-3);
		margin-left: calc(-1 * var(--spacing-2));

		@media ${styles.breakpoint.small} {
			margin-right: var(--spacing-2);
		}
	}
`;

type OverviewHeaderProps = {
	user: User;
	group: Group;
};

const OverviewHeader: React.FC<
	React.PropsWithChildren<OverviewHeaderProps>
> = ({ user, group }): JSX.Element => {
	const activeMembership = useCurrentMembership();
	const organizationGroup = useGroup(user.organizationId);
	const org = useCurrentOrganization();

	const { isSelfOrParentToUser } = useCheckRelationToUser(user.id);

	let showChatButton: boolean;

	if (!activeMembership.isOrganizationMembership) {
		showChatButton =
			group.hasFeature(Features.Chat) &&
			activeMembership.userId !== user.id &&
			!!user.accountId;
	}

	return (
		<Outer>
			<Inner>
				<Branding badgeUrl={organizationGroup.profileImageUrl} />
				<Avatar user={user} size={100} />
				<UserInfo>
					<Title>{user.fullName}</Title>
					<Team>
						<Badge badgeUrl={organizationGroup.profileImageUrl} size="30px" />
						<span>{organizationGroup.name}</span>
					</Team>
				</UserInfo>
				<LargeScreen>
					{showChatButton && (
						<MessageButton
							large
							icon="comment"
							href={link(
								routes.Chat.New(org.id),
								newChatParams([user.toJS() as models.user.User])
							)}>
							{t(`Message`)}
						</MessageButton>
					)}
					{(models.membership.isAdminOrStaff(activeMembership) ||
						isSelfOrParentToUser) && (
						<Actions
							toggle={
								<BrandedButton
									testid="user_profile.actions_button"
									large
									icon="more_horiz"
								/>
							}
							group={group}
							user={user}
						/>
					)}
				</LargeScreen>
			</Inner>
		</Outer>
	);
};

export default OverviewHeader;
