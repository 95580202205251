import { JSX } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import { ItemColumn } from 'routes/account/settings/child-relations/styles';

import Icon from 'components/icon';
import Label from 'components/label';
import Avatar from 'components/avatar';

import Row from 'components/layout/row';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';

interface ChildItemProps {
	relation: models.accountRelation.AccountRelation;
	consentAgeThreshold: number;
	removeRelation: (id: number) => void;
	openDeleteAccountModal: () => void;
	openCreateLoginModal: () => void;
	openConsentModal: () => void;
	openAddToGroupModal: () => void;
	openInviteParentModal: () => void;
}

export default function ChildItem({
	relation,
	consentAgeThreshold,
	removeRelation,
	openDeleteAccountModal,
	openCreateLoginModal,
	openConsentModal,
	openAddToGroupModal,
	openInviteParentModal,
}: ChildItemProps): JSX.Element {
	const handleRemoveRelation = async () => {
		removeRelation(relation.id);
	};

	const userFullName = models.account.fullName(relation.targetAccount);

	const actions = [
		<ContextMenu.Item onClick={openInviteParentModal} key="invite-parent">
			<ContextMenu.ItemIcon name="person_add" />
			{t('Connect a parent to {child_first_name}', {
				child_first_name: relation.targetAccount.firstName,
			})}
		</ContextMenu.Item>,
	];

	if (relation.approvedAt > 0) {
		if (!models.account.isAnAdult(relation.targetAccount)) {
			actions.push(
				<ContextMenu.Item onClick={openAddToGroupModal} key="add-to-group">
					<ContextMenu.ItemIcon name="add" />
					{t('Add {child_first_name} to group', {
						child_first_name: relation.targetAccount.firstName,
					})}
				</ContextMenu.Item>
			);
		}

		if (
			models.account.needsParentalConsent(
				relation.targetAccount,
				consentAgeThreshold
			)
		) {
			actions.push(
				<ContextMenu.Item onClick={openConsentModal} key="manage-consent">
					<ContextMenu.ItemIcon name="person_check" />
					{t('Manage consent')}
				</ContextMenu.Item>
			);
		}
	}

	if (actions.length > 0) {
		actions.push(<ContextMenu.Divider key="create-login-divider" />);
	}

	if (!relation.targetAccount.hasEmail) {
		actions.push(
			<ContextMenu.Item onClick={openCreateLoginModal} key="create-login">
				<ContextMenu.ItemIcon name="person_check" />
				{t('Create login for your child')}
			</ContextMenu.Item>
		);
	} else {
		actions.push(
			<ContextMenu.ConfirmItem
				caution
				closeOnClick
				onConfirm={handleRemoveRelation}
				confirmLabel={t('Remove connection')}
				message={t(
					'You are about to remove {child_name} as a connection, this cannot be undone.',
					{
						child_name: userFullName,
					}
				)}
				key="remove-connection">
				<ContextMenu.ItemIcon name="link_off" />
				{t('Remove connection')}
			</ContextMenu.ConfirmItem>
		);
	}

	if (actions.length > 0) {
		actions.push(<ContextMenu.Divider key="remove-account-divider" />);
	}

	actions.push(
		<ContextMenu.Item
			caution
			onClick={openDeleteAccountModal}
			key="remove-account">
			<ContextMenu.ItemIcon name="cancel" />
			{t('Permanently delete this account')}
		</ContextMenu.Item>
	);

	return (
		<Row columns="35px 1fr auto auto" align="center">
			<Avatar account={relation.targetAccount} />
			<ItemColumn spacing={styles.spacing._1}>
				<strong>{models.account.fullName(relation.targetAccount)}</strong>
				<span>
					{relation.targetAccount.email
						? relation.targetAccount.email
						: t('Account without login')}
				</span>
			</ItemColumn>
			<span>
				{models.accountRelation.parentalConsentMissing(
					relation,
					consentAgeThreshold
				) && (
					<Label color="orange">
						<Icon name="error" /> {t('Consent missing')}
					</Label>
				)}
			</span>
			<ContextMenu.Menu
				toggleWith={<Button icon="more_horiz" small secondary />}>
				{actions}
			</ContextMenu.Menu>
		</Row>
	);
}
