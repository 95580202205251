import styled from 'styled-components';
import { JSX, CSSProperties } from 'react';

import * as styles from 'pkg/config/styles';

import { eventTypeToTranslatedString } from 'pkg/models/event';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as routes from 'pkg/router/routes';
import rgba from 'pkg/rgba';
import DateTime from 'pkg/datetime';
import { pushState } from 'pkg/router/state';
import uuid from 'pkg/uuid';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import { Spinner } from 'components/loaders/spinner';
import ContentUnavailable from 'components/embeds/ContentUnavailable';
import VideoCollectionGroupItem from 'components/library/group-items/VideoCollection';
import VideoPlaylistGroupItem from 'components/library/group-items/VideoPlaylist';
import VideoGroupItem from 'components/library/group-items/Video';

const SkeletonEmbed = styled.div`
	aspect-ratio: 16 / 9;
	min-width: 120px;
	max-width: 250px;
	width: 100%;
	background: var(--palette-gray-200);
	border-radius: var(--radius-5);
	box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1);
	display: grid;
	place-items: center;

	&[data-compact] {
		aspect-ratio: 2 / 1;
	}

	/* @TODO Remove when we can drop iOS 14 */
	@supports not (aspect-ratio: 16 / 9) {
		&::before {
			float: left;
			padding-top: 56.25%;
			content: '';
		}

		&::after {
			display: block;
			content: '';
			clear: both;
		}

		&[data-compact]::before {
			float: left;
			padding-top: 25%;
			content: '';
		}
	}
`;

interface EmbedProps {
	contentId: number;
	queryString?: string;
}

export function VideoEmbed({
	contentId,
	queryString,
}: EmbedProps): JSX.Element {
	const id = uuid();

	const { isLoading, record: video } = useEndpoint<models.video.Video>(
		endpoints.Video.Show(contentId)
	);

	if (isLoading) {
		return (
			<SkeletonEmbed>
				<Spinner />
			</SkeletonEmbed>
		);
	}

	if (!isLoading && !video?.id) {
		return <ContentUnavailable />;
	}

	return (
		<VideoGroupItem embedded key={id} item={video} queryString={queryString} />
	);
}

export function PlaylistEmbed({
	contentId,
	queryString,
}: EmbedProps): JSX.Element {
	const id = uuid();

	const { isLoading, record: playlist } =
		useEndpoint<models.videoPlaylist.VideoPlaylist>(
			endpoints.VideoPlaylist.Show(contentId)
		);

	if (isLoading) {
		return (
			<SkeletonEmbed>
				<Spinner />
			</SkeletonEmbed>
		);
	}

	if (!isLoading && !playlist?.id) {
		return <ContentUnavailable />;
	}

	return (
		<VideoPlaylistGroupItem
			embedded
			key={id}
			item={playlist}
			queryString={queryString}
		/>
	);
}

export function CollectionEmbed({ contentId }: EmbedProps): JSX.Element {
	const id = uuid();

	const { isLoading, record: collection } =
		useEndpoint<models.videoCollection.VideoCollection>(
			endpoints.VideoCollections.Show(contentId)
		);

	if (isLoading) {
		return (
			<SkeletonEmbed>
				<Spinner />
			</SkeletonEmbed>
		);
	}

	if (!isLoading && !collection?.id) {
		return <ContentUnavailable />;
	}

	return <VideoCollectionGroupItem embedded key={id} item={collection} />;
}

const EventEmbedWrapper = styled.div`
	--bg: var(--palette-gray-200);
	--fg: var(--palette-gray-700);

	background: var(--bg);
	color: var(--fg);
	border-radius: var(--radius-5);
	box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1);
	cursor: pointer;
`;

const EventEmbedTitle = styled.div`
	margin-bottom: var(--spacing-3);
	padding: var(--spacing-4);
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: repeat(2, auto);
	column-gap: var(--spacing-4);
	row-gap: var(--spacing-1);
	color: currentColor;

	time {
		background: var(--palette-white);
		padding: var(--spacing-2) var(--spacing-3);
		grid-area: 1 / 1 / 3 / 2;
		display: grid;
		grid-template-rows: repeat(2, auto);
		gap: var(--spacing-1);
		place-content: center;
		place-items: center;
		border-radius: var(--radius-2);
		box-shadow: 0 1px 1px hsla(0, 0%, 0%, 0.1);

		span {
			font-size: var(--font-size-xs);
		}
	}

	> span {
		font-size: var(--font-size-xs);
		font-weight: var(--font-weight-semibold);
		text-transform: uppercase;
	}

	> div {
		font-size: var(--font-size-lg);
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
`;

const EventEmbedRow = styled.div`
	margin-left: var(--spacing-2);
	display: grid;
	grid-template-columns: auto 1fr;
	padding: var(--spacing-4);
	padding-top: 0;
	gap: var(--spacing-3);
	font-size: var(--font-size-xs);
`;

export function EventEmbed({
	contentId,
	queryString = '',
}: EmbedProps): JSX.Element {
	const org = useCurrentOrganization();

	const { isLoading, record: event } = useEndpoint<models.event.Event>(
		endpoints.Events.Show(contentId)
	);

	if (isLoading) {
		return (
			<SkeletonEmbed data-compact>
				<Spinner />
			</SkeletonEmbed>
		);
	}

	if (!isLoading && !event?.id) {
		return <ContentUnavailable />;
	}

	const vars = {
		'--bg': rgba(styles.palette.events[event.type].backgroundColor),
		'--fg': rgba(styles.palette.events[event.type].foregroundColor),
	};

	const navigate = () => {
		pushState(routes.Event.View(org.id, contentId, 'overview') + queryString);
	};

	const startDate = new DateTime(new Date(event.startsAt * 1000));

	return (
		<EventEmbedWrapper onClick={navigate} style={vars as CSSProperties}>
			<EventEmbedTitle>
				<time dateTime={startDate.toTimeString()}>
					<strong>{startDate.toLocaleDateString({ day: 'numeric' })}</strong>
					<span>{startDate.toLocaleDateString({ month: 'short' })}</span>
				</time>
				<span>{eventTypeToTranslatedString(event.type)}</span>
				<div>{event.title}</div>
			</EventEmbedTitle>
			<EventEmbedRow>
				<Icon name="nav-group" size={1.3} />
				<span>{event.group.name}</span>
			</EventEmbedRow>
			{event.location && (
				<EventEmbedRow>
					<Icon name="location" size={1.6} />
					<span>{event.location}</span>
				</EventEmbedRow>
			)}
		</EventEmbedWrapper>
	);
}
