import { JSX, useCallback, useEffect } from 'react';

import * as json from 'pkg/json';

import { CueType, useCueState } from 'components/video-analytics/CueState';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useClipState } from 'components/video-analytics/ClipState';

export default function CueObserver(): JSX.Element {
	const { currentTime, setCurrentTime } = usePlaybackState();
	const currentTimeMs = Math.round(currentTime * 1000);

	const { isPlaying, isRearranging, gaplessPlayback, setSource, controller } =
		usePlayerState();

	const clipState = useClipState();
	const cueState = useCueState();

	const { cuePoints, currentCue, nextCue } = cueState;

	const numCuePoints: number = cuePoints.length;
	const hasCuePoints: boolean = numCuePoints > 0;

	const validStartsAt = (cue: CueType): number => {
		let startsAt = cue.startsAtMs / 1000 || cue.startsAt;

		// If milliseconds is invalid, use full seconds instead
		if (cue.startsAtMs < cue.startsAt) {
			startsAt = cue.startsAt;
		}

		return startsAt;
	};

	const cueWithinRange = (currentTimeMs: number, cue?: CueType): boolean => {
		// @NOTE Adjust start and end time to check for "did end" within this scope
		const adjustTimeMs = 500;

		const startsAtMs = cue.startsAtMs || cue.startsAt * 1000;
		const endsAtMs = cue.endsAtMs || cue.endsAt * 1000;

		return (
			cue &&
			currentTimeMs >= startsAtMs - adjustTimeMs &&
			currentTimeMs <= endsAtMs + adjustTimeMs
		);
	};

	const shouldObserve = useCallback(() => {
		return (
			isPlaying &&
			!isRearranging &&
			gaplessPlayback &&
			hasCuePoints &&
			cueWithinRange(currentTimeMs, currentCue)
		);
	}, [
		isPlaying,
		currentTimeMs,
		hasCuePoints,
		currentCue,
		isRearranging,
		gaplessPlayback,
	]);

	const cueDidLeave = useCallback(() => {
		const endsAtMs = currentCue.endsAtMs || currentCue.endsAt * 1000;

		return (
			cueWithinRange(currentTimeMs, currentCue) && currentTimeMs >= endsAtMs
		);
	}, [currentTimeMs, currentCue]);

	useEffect(() => {
		if (cuePoints.length > 0) {
			const { startsAt } = cuePoints[0];

			controller.seekTo(startsAt);
		}
	}, [controller.signature]);

	useEffect(() => {
		if (shouldObserve() && cueDidLeave()) {
			if (nextCue && gaplessPlayback) {
				const nextStartsAt = validStartsAt(nextCue);

				if (nextCue.sourceUrl) {
					setSource(nextCue.sourceUrl, {
						startsAt: nextStartsAt,
					});
				}

				cueState.next();

				setCurrentTime(nextStartsAt);
				controller.seekTo(nextStartsAt, true);

				if (nextCue.annotations && nextCue.annotations !== '{}') {
					const annotations = json.parse(nextCue.annotations);

					if (annotations) {
						clipState.setAnnotations(annotations);
					}
				} else {
					clipState.setAnnotations(null);
				}
			} else {
				const startsAt = validStartsAt(currentCue);

				setCurrentTime(startsAt);
				controller.seekTo(startsAt, true);
				controller.pause();
			}
		}
	}, [shouldObserve]);

	return null;
}
