import { JSX, useState } from 'react';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import Icon from 'components/icon';

import { EllipsisIcon } from 'components/loaders/spinner';

import * as css from './style.css';

interface AttachmentProps {
	attachment: models.attachment.Attachment;

	onDelete: (attachment: models.attachment.Attachment) => void;
}

function AttachmentFormItem({
	attachment,
	onDelete,
}: AttachmentProps): JSX.Element {
	const [isLoaded, setIsLoaded] = useState(false);

	const handleDidLoad = () => setIsLoaded(true);

	// Attachment with preview images/thumbnails
	if (attachment.previewUrl) {
		return (
			<div className={css.imageAttachmentWrapper}>
				<img
					src={attachment.previewUrl}
					onLoad={handleDidLoad}
					className={cssClasses(css.image, isLoaded ? css.imageLoaded : '')}
					loading="lazy"
				/>

				{attachment.status === models.attachment.AttachmentStatus.Normal && (
					<div className={css.remove} onClick={() => onDelete(attachment)}>
						<Icon name="close" className={css.removeIcon} />
					</div>
				)}

				{attachment.status === models.attachment.AttachmentStatus.Uploading && (
					<div className={css.loading}>
						<EllipsisIcon className={css.loadingIcon} />
					</div>
				)}
			</div>
		);
	}

	return (
		<div className={css.attachmentWrapper}>
			<Icon name="file-generic" className={css.fileIcon} />
			<div className={css.fileName}>
				<span>{attachment.title}</span>
			</div>

			{attachment.status === models.attachment.AttachmentStatus.Normal && (
				<div className={css.remove} onClick={() => onDelete(attachment)}>
					<Icon name="close" className={css.removeIcon} />
				</div>
			)}

			{attachment.status === models.attachment.AttachmentStatus.Uploading && (
				<div className={css.loading}>
					<EllipsisIcon className={css.loadingIcon} />
				</div>
			)}
		</div>
	);
}

export default AttachmentFormItem;
