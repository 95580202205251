import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';

import { Features } from 'pkg/models/group';

import * as actions from 'pkg/actions';
import {
	useUser,
	useGroup,
	useMembership,
	useCheckRelationToUser,
	useMemberships,
} from 'pkg/hooks/selectors';
import * as routes from 'pkg/router/routes';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

import { UserReportTable } from 'routes/group/report/user';
import UserProfileCalendar from 'routes/user/profile/calendar';

import Log from 'containers/user/profile/Log';
import Overview from 'containers/user/profile/overview/View';
import OverviewHeader from 'containers/user/profile/overview/Header';
import Development from 'containers/user/profile/development/View';
import Statistics from 'containers/user/profile/statistics/View';
import Videos from 'containers/user/profile/Videos';
import InfoContact from 'containers/user/profile/info-contact';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import * as LargeLayout from 'components/navigation/header/large_screen/Styles';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';

type ProfileProps = {
	groupId: number;
	userId: number;
	currentTab: string;
	title: string;
	icon: MaterialSymbolVariant;
};

const View: React.FC<React.PropsWithChildren<ProfileProps>> = ({
	groupId = 0,
	userId = 0,
	currentTab = '',
	title = '',
	icon,
}) => {
	const dispatch = useDispatch();

	const [pageTitle, setPageTitle] = useState(title);
	const [pageActions, setPageActions] = useState(null);

	const user = useUser(userId);
	const activeMembership = useCurrentMembership();
	const group = useGroup(groupId);
	const org = useCurrentOrganization();
	const currentProfileMembership = useMembership(groupId, userId);
	const currentProfileMemberships = useMemberships(groupId, userId);
	const isSmallScreen = useSmallScreen();

	useEffect((): void => {
		async function fetchSquadAndUser() {
			await actions.groups.fetchSquad(groupId, {
				includeLegalGuardians: 1,
				includeRatings: 1,
				preloadactivities: 1,
			});
			await dispatch(actions.users.fetchUser(userId));
		}
		fetchSquadAndUser();
	}, [groupId, userId, dispatch]);

	useEffect((): void => {
		if (isSmallScreen && currentTab === 'overview') {
			setPageTitle('');
		} else if (user.id) {
			setPageTitle(user.fullName);
		}
	}, [user.id, currentTab, isSmallScreen]);

	const { isParentToUser, isSelf, isSelfOrParentToUserOrAdmin } =
		useCheckRelationToUser(userId);

	if (!currentProfileMembership) {
		return null;
	}

	const isParentProfile = currentProfileMembership.isParent();

	const hasAdminUser = currentProfileMemberships.some((m) =>
		m.isAdminOrStaff()
	);

	const isParent = isParentProfile && !isParentToUser && !hasAdminUser;

	const renderTabView = (): React.ReactNode => {
		if (isParent) {
			return (
				<Fragment>
					<LargeScreen>
						<OverviewHeader
							groupId={groupId}
							userId={userId}
							setPageActions={setPageActions}
						/>
						<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
							<InfoContact userId={userId} />
						</LargeScreenContent.Inner>
					</LargeScreen>
					<SmallScreen>
						<InfoContact userId={userId} />
					</SmallScreen>
				</Fragment>
			);
		}

		switch (currentTab) {
			case 'development':
				return (
					<Development
						groupId={groupId}
						userId={userId}
						membership={currentProfileMembership}
					/>
				);
			case 'stats':
				return <Statistics userId={userId} />;
			case 'videos':
				if (!group.hasFeature(Features.Video)) {
					return null;
				}

				return (
					<Videos
						userId={userId}
						isSelf={isSelf}
						setPageActions={setPageActions}
					/>
				);
			case 'player-logs':
				return <Log userId={userId} activeMembership={activeMembership} />;
			case 'info-contact':
				return <InfoContact userId={userId} setPageActions={setPageActions} />;
			case 'attendance':
				return <UserReportTable groupId={groupId} userId={userId} profile />;
			case 'calendar':
				return <UserProfileCalendar userId={userId} />;
			case 'overview': {
				if (hasAdminUser) {
					return (
						<Fragment>
							<LargeScreen>
								<OverviewHeader
									groupId={groupId}
									userId={userId}
									setPageActions={setPageActions}
								/>
								<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
									<InfoContact userId={userId} />
								</LargeScreenContent.Inner>
							</LargeScreen>
							<SmallScreen>
								<InfoContact userId={userId} />
							</SmallScreen>
						</Fragment>
					);
				} else {
					return <Overview groupId={groupId} userId={userId} />;
				}
			}
		}
	};

	const IS_OVERVIEW: boolean = currentTab === 'overview';
	const IS_ATTENDANCE: boolean = currentTab === 'attendance';
	const IS_CALENDAR: boolean = currentTab === 'calendar';

	const getNavs = () => {
		const items = [
			{
				title: t('Overview'),
				currentTab: 'overview',
				show: true,
			},
		];

		if (hasAdminUser) {
			items.push(
				{
					title: t('Videos'),
					currentTab: 'videos',
					show: isSelfOrParentToUserOrAdmin && group.hasFeature(Features.Video),
				},
				{
					title: t('Development'),
					currentTab: 'development',
					show: isSelfOrParentToUserOrAdmin,
				},
				{
					title: t('Attendance'),
					currentTab: 'attendance',
					show: isSelfOrParentToUserOrAdmin,
				}
			);
		}

		if (currentProfileMembership.isPlayer()) {
			items.push(
				{
					title: t('Development'),
					currentTab: 'development',
					show: isSelfOrParentToUserOrAdmin,
				},
				{
					title: t('Statistics'),
					currentTab: 'stats',
					show: isSelfOrParentToUserOrAdmin,
				},
				{
					title: t('Attendance'),
					currentTab: 'attendance',
					show: isSelfOrParentToUserOrAdmin,
				},
				{
					title: t('Videos'),
					currentTab: 'videos',
					show: isSelfOrParentToUserOrAdmin && group.hasFeature(Features.Video),
				},
				{
					title: t('Notes'),
					currentTab: 'player-logs',
					show: models.membership.isAdminOrStaff(activeMembership),
				},
				{
					title: t('Calendar'),
					currentTab: 'calendar',
					show: isSelfOrParentToUserOrAdmin,
				},
				{
					title: t('Info & Contact'),
					currentTab: 'info-contact',
					show: isSelfOrParentToUserOrAdmin,
				}
			);
		}

		return items.filter(({ show = true }) => show);
	};

	const nav = getNavs();

	const getNavProps = (currentTab: string) => {
		return {
			href: routes.User.Profile.Show(org.id, groupId, userId, currentTab),
		};
	};

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={icon}>
					{nav.length > 1 &&
						nav.map((nav) => (
							<LargeLayout.SubNavItem
								key={nav.title}
								{...getNavProps(nav.currentTab)}>
								{nav.title}
							</LargeLayout.SubNavItem>
						))}
				</LargeScreenHeader>
				<LargeScreenContent.Wrapper
					key={currentTab}
					backgroundColor={IS_CALENDAR && styles.palette.white}
					disableScroll={IS_CALENDAR}>
					{IS_OVERVIEW ||
					IS_ATTENDANCE ||
					IS_CALENDAR ||
					IS_CALENDAR ||
					isParent ? (
						renderTabView()
					) : (
						<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
							{renderTabView()}
						</LargeScreenContent.Inner>
					)}
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />
				<SmallScreenContent noScroll={IS_CALENDAR}>
					<SmallLayout.Top>
						{(IS_OVERVIEW || isParent) && (
							<OverviewHeader
								groupId={groupId}
								userId={userId}
								setPageActions={setPageActions}
							/>
						)}
						{nav.length > 1 && (
							<SmallLayout.SubNav>
								{nav.map((nav) => (
									<SmallLayout.SubNavItem
										key={nav.title}
										{...getNavProps(nav.currentTab)}>
										{nav.title}
									</SmallLayout.SubNavItem>
								))}
							</SmallLayout.SubNav>
						)}
					</SmallLayout.Top>
					{IS_ATTENDANCE || IS_CALENDAR ? (
						renderTabView()
					) : (
						<LargeScreenContent.Inner>
							{renderTabView()}
						</LargeScreenContent.Inner>
					)}
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

export default View;
