import { t } from '@transifex/native';
import { Fragment } from 'react/jsx-runtime';
import { JSX, useState } from 'react';

import * as styles from 'pkg/config/styles';

import { useCurrentAccount } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';

import SectionTitle from 'components/SectionTitle';
import SectionDescription from 'components/SectionDescription';
import Connection from 'components/connection';
import Badge from 'components/Badge';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import { useJoinModalContext } from 'components/group/join-modal';
import InviteParentModal from 'components/account/invite-parent-modal';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

import * as css from './styles.css';

export default function Connected(): JSX.Element {
	const [showModal, setShowModal] = useState(false);
	const joinContext = useJoinModalContext();
	const currentAccount = useCurrentAccount();

	const { isLoading, records } =
		useCollection<models.accountRelation.AccountRelation>(
			joinContext.targetAccount?.id ? endpoints.AccountRelations.Index() : null,
			{
				queryParams: new URLSearchParams({
					account_id: joinContext.targetAccount?.id.toString(),
				}),
			}
		);

	const isSelf =
		!joinContext.targetAccount ||
		currentAccount.id === joinContext.targetAccount?.id;

	const isPending =
		joinContext.groupRole === models.membership.MembershipRole.Staff;

	const account = isSelf ? currentAccount : joinContext.targetAccount;

	const groupName =
		joinContext.group?.name || joinContext.connectionInfo?.groupName;

	const groupBadgeUrl =
		joinContext.group?.profileImageUrl ||
		joinContext.connectionInfo?.profileImageUrl ||
		'';

	let title = t('Welcome to {group}, {name}!', {
		group: groupName,
		name: account.firstName,
	});

	let description = t('You are now a member of {group}', {
		group: groupName,
	});

	let showConnectToAnotherParent = false;

	if (!isSelf) {
		title = t('A warm welcome to {name}!', {
			name: account.firstName,
		});

		description = t(
			'{name} is now a member of {group}. As a parent, you also have access to their group.',
			{
				group: groupName,
				name: account.firstName,
			}
		);

		if (records.length < 2) {
			showConnectToAnotherParent = true;
		}
	}

	if (isPending) {
		title = t('Almost there {name}!', { name: account.firstName });

		description = t(
			'You have requested to join {group} and an existing admin must approve your request before you get access. You will receive a notification when you have been approved.',
			{
				group: groupName,
			}
		);
	}

	const handleToggleModal = () => setShowModal(!showModal);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<Column
				spacing={styles.spacing._8}
				blockSpacing={styles.spacing._8}
				inlineSpacing={styles.spacing._8}>
				<Connection
					pending={isPending}
					leftComponent={<Avatar account={account} />}
					rightComponent={<Badge badgeUrl={groupBadgeUrl} />}
				/>

				<Column spacing={styles.spacing._2}>
					<SectionTitle medium centered>
						{title}
					</SectionTitle>
					<SectionDescription centered>{description}</SectionDescription>
				</Column>

				{showConnectToAnotherParent && (
					<div className={css.infoBox}>
						<Column justify="center" spacing={styles.spacing._5}>
							<Column justify="center" spacing={styles.spacing._2}>
								<div className={cssClasses(css.bold, css.infoBoxTitle)}>
									{t('Connect to another parent')}
								</div>
								<div className={css.textCenter}>
									{t(
										'Inviting another parent or guardian can improve communication and coordination for {name} activities.',
										{ name: models.account.fullName(account) }
									)}
								</div>
							</Column>
							<Button
								label={t('Invite parent/guardian')}
								icon="share"
								onClick={handleToggleModal}
							/>
						</Column>
					</div>
				)}
			</Column>
			{showModal && (
				<InviteParentModal
					targetAccounts={[account]}
					onClose={handleToggleModal}
				/>
			)}
		</Fragment>
	);
}
