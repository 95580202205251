import { t } from '@transifex/native';

import { AllowedActionable } from 'pkg/api/models/allowed_actionable';
import * as models from 'pkg/api/models';
import { Record } from 'pkg/api/models/record';

export function translatedRatingItemCategory(cat: string): string {
	switch (cat) {
		case 'base':
			return t('Base', {
				_context: 'rating-item-category',
			});
		case 'defending-skills':
			return t('Defending', {
				_context: 'rating-item-category',
			});
		case 'dribbling-skills':
			return t('Technique', {
				_context: 'rating-item-category',
			});
		case 'goalkeeping':
			return t('Goalkeeper', {
				_context: 'rating-item-category',
			});
		case 'heading-skills':
			return t('Heading', {
				_context: 'rating-item-category',
			});
		case 'mental':
			return t('Mental', {
				_context: 'rating-item-category',
			});
		case 'pace-skills':
			return t('Pace', {
				_context: 'rating-item-category',
			});
		case 'passing-skills':
			return t('Passing', {
				_context: 'rating-item-category',
			});
		case 'physical':
			return t('Physical', {
				_context: 'rating-item-category',
			});
		case 'physical-skills':
			return t('Physique', {
				_context: 'rating-item-category',
			});
		case 'shooting-skills':
			return t('Attacking', {
				_context: 'rating-item-category',
			});
		case 'skills':
			return t('Skills', {
				_context: 'rating-item-category',
			});
		case 'vision-skills':
			return t('Vision', {
				_context: 'rating-item-category',
			});
		default:
			return t('Unknown category', {
				_context: 'rating-item-category',
			});
	}
}

export interface UserRating extends Record, AllowedActionable {
	userId: number;
	user: models.user.User;
	author: models.user.User;

	hiddenScores: boolean;
	groupId: number;
	raterUserId: number;
	isPrimaryRating: boolean;
	suggestedPositionId: number;
	positionScores: PositionScore[];
	maturityAdjustment: number;
}

export interface PositionScore {
	value: number;
	maturityAdjustedValue: number;

	positionId: number;
	position?: models.position.Position;
}

export function getPositionScoreColorValues(
	positionId: number,
	positionScores: PositionScore[],
	isMaturityAdjusted: boolean = false
): [number, number, number] {
	const valueKey = isMaturityAdjusted ? 'maturityAdjustedValue' : 'value';

	positionScores = positionScores.sort((a, b) => a[valueKey] - b[valueKey]);

	const averages = positionScores.map((i) => i[valueKey]).sort();

	const min = Math.min(...averages);
	const max = Math.max(...averages);

	const median = averages[Math.round(averages.length / 2)];
	const mdiff = 1 - (median - min) / (max - min);

	const score = positionScores.find((i) => i.positionId === positionId);

	if (!score) {
		return [0, 0, 0];
	}

	const avg = score[valueKey];
	const diff = 1 - (avg - min) / (max - min);

	let hue: number = 360; // @note Red
	let sat = 100 * diff;
	const lum = Math.min(100 * (1 - Math.abs(diff - 0.5)), 85);

	if (diff < mdiff) {
		hue = 120; // @note Green
		sat = 100 - 100 * diff;
	}

	return [hue, sat, lum];
}

export function getPositionScoreColor(
	positionId: number,
	positionScores: PositionScore[],
	isMaturityAdjusted: boolean = false
): string {
	const [h, s, l] = getPositionScoreColorValues(
		positionId,
		positionScores,
		isMaturityAdjusted
	);

	return `${h} ${s}% ${l}%`;
}
