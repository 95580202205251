import { ChangeEvent, Fragment, ReactNode, useState } from 'react';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';

import * as StepModal from 'components/step-modal';

import Form from 'components/form/Form';
import * as Input from 'components/form/inputs';

type PerformanceReviewModalSaveHandler = (
	performanceReview: models.performanceReview.PerformanceReview
) => Promise<void>;

interface PerformanceReviewModalHook {
	Component: ReactNode;

	open: (
		performanceReview?: models.performanceReview.PerformanceReview,
		onSave?: PerformanceReviewModalSaveHandler
	) => void;
	close: () => void;
}

export function usePerformanceReviewModal(
	user: models.user.User,
	onSave: PerformanceReviewModalSaveHandler
): PerformanceReviewModalHook {
	const t = useT();

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [performanceReview, setPerformanceReview] =
		useState<models.performanceReview.PerformanceReview>(null);

	const [state, setState, forceState] =
		useMixedState<models.performanceReview.PerformanceReview>(
			{} as models.performanceReview.PerformanceReview
		);

	const handleFieldChange = (
		event: ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>
	) => {
		const fieldName = event.currentTarget.name;
		const fieldValue = event.currentTarget.value;

		if (fieldValue !== undefined) {
			setState({ [fieldName]: fieldValue });
		}
	};

	const open = (
		performanceReview?: models.performanceReview.PerformanceReview
	) => {
		setPerformanceReview(performanceReview ?? null);
		setState({ ...performanceReview });

		setIsOpen(true);
	};

	const close = () => {
		setPerformanceReview(null);
		setIsOpen(false);
		forceState({} as models.performanceReview.PerformanceReview);
	};

	const handleModalNext = async () => {
		await onSave(state);

		return true;
	};

	const hasPerformanceReview = performanceReview !== null;
	const canGoNext = state.performanceReview?.length > 0;

	const title = hasPerformanceReview
		? t('Update performance review')
		: t('Create new performance review');

	const label = hasPerformanceReview ? t('Update review') : t('Create review');

	const Component = (
		<Fragment>
			{isOpen && (
				<StepModal.Base key="user-performance-review-modal" onClose={close}>
					<StepModal.Step
						key={
							hasPerformanceReview
								? 'update-user-performance-review'
								: 'create-user-performance-review'
						}
						title={title}
						nextLabel={label}
						canGoNext={canGoNext}
						onNext={handleModalNext}>
						<Form>
							<Input.Area
								name="performanceReview"
								minRows={12}
								placeholder={t(`Performance review with {user}`, {
									user: user.firstName,
								})}
								onChange={handleFieldChange}
								value={state.performanceReview}
							/>
						</Form>
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Fragment>
	);

	return {
		Component,

		open,
		close,
	};
}
