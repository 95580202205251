import { t } from '@transifex/native';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { JSX, MouseEvent, useState } from 'react';

import * as styles from 'pkg/config/styles';

import VideoSequence from 'pkg/models/video_sequence';
import Tag from 'pkg/models/tag';
import User from 'pkg/models/user';

import * as selectors from 'pkg/selectors';
import { formatTime } from 'pkg/timeline';
import { RootState } from 'pkg/reducers';
import { useCurrentMembership } from 'pkg/identity';

import ListItemContextMenu from 'routes/video/shared/clips/ListItemContextMenu';
import { useClipActions } from 'routes/video/shared/hooks/clip-actions';
import { ClipListContext } from 'routes/video/shared/ClipList';
import ClipDiscussion from 'routes/video/shared/ClipDiscussion';
import ClipComment from 'routes/video/shared/ClipComment';

import TextOverflow from 'components/TextOverflow';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';
import Avatar from 'components/avatar';

import PlaylistModal from 'components/video-library/modals/Playlist';
import {
	CueGroupType,
	CueType,
	useCueState,
} from 'components/video-analytics/CueState';
import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';

import Button from 'design/button';

const Item = styled.div`
	position: relative;
`;

const ContextMenuTrigger = styled(Icon)`
	padding: var(--spacing-3);
	width: 28px;
	height: 28px;
	position: absolute;
	top: 5px;
	right: 5px;
`;

const Wrapper = styled.article`
	background: var(--palette-gray-800);
	border-radius: var(--radius-3);
	user-select: none;
	cursor: pointer;
	color: var(--palette-white);
	transition: background-color 150ms ease-in-out;

	&[data-active='true'] {
		box-shadow: 0 0 0 2px var(--palette-blue-600);
	}

	@media (hover: hover) {
		&:hover {
			background: var(--palette-gray-700);
		}
	}

	@media ${styles.breakpoint.small} {
		background: var(--palette-gray-700);
	}
`;

const Header = styled.header`
	padding: var(--spacing-4);
	padding-bottom: var(--spacing-2);
	font-weight: var(--font-weight-semibold);
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: var(--spacing-2);
	align-items: center;
	justify-content: start;
`;

const Title = styled.span`
	font-size: var(--font-size-sm);
	display: grid;
	grid-auto-flow: column;
	gap: var(--spacing-2);
	align-items: center;
	justify-content: start;
	width: calc(100% - 24px);

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		display: inline-block;
	}

	&[data-item-type='highlight'] {
		color: var(--palette-yellow-500);
	}

	&[data-item-type='private'] {
		color: var(--palette-blue-400);
	}

	&[data-item-type='private-highlight'] {
		color: var(--palette-orange-400);
	}
`;

const Description = styled.div`
	font-size: var(--font-size-sm);
	padding: var(--spacing-4);
	padding-top: 0;
`;

const Timestamps = styled.div`
	padding: 0 0 var(--spacing-4) var(--spacing-4);
	display: grid;
	grid-auto-flow: column;
	gap: var(--spacing-3);
	align-items: center;
	justify-content: start;
	font-size: var(--font-size-xs);
`;

const TimeRanges = styled.div`
	color: var(--palette-gray-400);
`;

const Duration = styled.div`
	color: var(--palette-gray-500);
`;

const Tags = styled.div`
	padding: 0 var(--spacing-4);
	padding-bottom: var(--spacing-2);

	&:empty {
		padding: 0;
	}
`;

const TagItem = styled.span`
	padding: var(--spacing-2) var(--spacing-3);
	margin: 0 var(--spacing-2) var(--spacing-2) 0;
	background: var(--palette-gray-800);
	font-size: var(--font-size-xs);
	height: 24px;
	border-radius: var(--radius-2);
	border: 1px solid var(--palette-gray-600);
	display: inline-grid;
	grid-auto-flow: column;
	gap: var(--spacing-3);
	place-items: center;
	position: relative;

	&[data-tag-type='user'] {
		padding-left: var(--spacing-2);

		${Avatar} {
			border-radius: var(--radius-3);
		}
	}

	&[data-tag-type='tag'] {
		top: -3px;
	}
`;

const Discussion = styled.div`
	padding: var(--spacing-4);
	padding-top: 0;
	text-align: right;
`;

interface ListItemProps {
	context: ClipListContext;
	clip: VideoSequence;
	selected?: boolean;
	selectable?: boolean;
	cuePoints?: CueType[];
	cueGroup?: CueGroupType;
	onClick?: () => Promise<void>;
	onToggle?: (clipId: number) => void;
}

export default function ListItem({
	context,
	clip,
	selected,
	selectable,
	cuePoints,
	cueGroup,
	onClick,
	onToggle,
}: ListItemProps): JSX.Element {
	const membership = useCurrentMembership();
	let itemType = 'normal';

	if (clip.reviewed && clip.private) {
		itemType = 'private-highlight';
	} else if (clip.reviewed) {
		itemType = 'highlight';
	} else if (clip.private) {
		itemType = 'private';
	}

	const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
	const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
	const [discussionModalOpen, setDiscussionModalOpen] =
		useState<boolean>(false);

	const { currentCue, setCuePoints } = useCueState();
	const { activate, edit, copy, link, destroy } = useClipActions(clip.id);
	const isActive = currentCue?.cueId === clip.id;

	const taggedUsers = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findSequenceUsers(state, clip.id)
	);

	const tags = useSelector((state: RootState) =>
		selectors.tags.findAllByIds(state, clip.tags)
	);

	const activeSequenceUser = useSelector((state: RootState) =>
		selectors.videoSequenceUsers.findActiveSequenceUser(
			state,
			membership.targetUserId || membership.userId,
			clip.id
		)
	);

	const adjustedCommentCount =
		activeSequenceUser?.description.length > 0 ? 1 : 0;

	const numComments =
		useSelector((state: RootState) =>
			selectors.videoSequenceComments.findAllBySequenceId(
				state,
				clip.id as number
			)
		).size + adjustedCommentCount;

	const canComment = clip.hasIn(['links', 'create:video_sequence_comment']);

	const openContextMenu = () => setContextMenuOpen(true);

	const closeContextMenu = () => setContextMenuOpen(false);

	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		activate();

		if (context === 'edit-mode' && isActive && clip.hasIn(['links', 'edit'])) {
			edit();
		}

		if (cuePoints?.length > 0) {
			const clipId = Number.parseInt(event.currentTarget.dataset.clipId, 10);

			setCuePoints(cuePoints, cueGroup || CueGroupType.All, clipId);
		}

		if (onClick) {
			onClick();
		}
	};

	const openAddModal = () => setAddModalOpen(true);

	const closeAddModal = () => setAddModalOpen(false);

	const openDiscussionModal = () => setDiscussionModalOpen(true);

	const closeDiscussionModal = () => setDiscussionModalOpen(false);

	const item = (
		<Item>
			{!contextMenuOpen ? (
				<ContextMenuTrigger name="context-menu" onClick={openContextMenu} />
			) : (
				<ListItemContextMenu
					clipId={clip.id}
					edit={context === 'edit-mode' && edit}
					copy={context === 'edit-mode' && copy}
					link={link}
					destroy={context === 'edit-mode' && destroy}
					addToPlaylist={openAddModal}
					onClose={closeContextMenu}
				/>
			)}
			<Wrapper
				data-active={isActive}
				data-clip-id={clip.id}
				onClick={handleClick}>
				<Header>
					<Title data-item-type={itemType}>
						{clip.reviewed && <Icon name="star" />}
						{clip.private && <Icon name="eye-hidden" />}
						<span>{clip.title}</span>
					</Title>
				</Header>
				<Timestamps>
					<TimeRanges>
						{formatTime(clip.getStartsAt())} - {formatTime(clip.getEndsAt())}
					</TimeRanges>
					<Duration>({formatTime(clip.getDuration())})</Duration>
				</Timestamps>
				{clip.description && (
					<Description>
						<LargeScreen>
							<TextOverflow maxLines={3}>{clip.description}</TextOverflow>
						</LargeScreen>
						<SmallScreen>
							{isActive ? (
								clip.description
							) : (
								<TextOverflow maxLines={3}>{clip.description}</TextOverflow>
							)}
						</SmallScreen>
					</Description>
				)}
				<Tags>
					{taggedUsers?.map((user: User) => (
						<TagItem key={user.id} data-tag-type="user">
							<Avatar user={user} size={14} />
							<span>{user.fullName}</span>
						</TagItem>
					))}
					{tags?.map((tag: Tag) => (
						<TagItem key={tag.name} data-tag-type="tag">
							{tag.name.replace(/\_/g, ' ')}
						</TagItem>
					))}
				</Tags>
				{canComment && (
					<SmallScreen>
						<Discussion>
							<Button
								small
								transparent
								onClick={openDiscussionModal}
								icon="comment">
								{numComments > 0
									? t('{num} comments', { num: numComments })
									: t('Add comment')}
							</Button>
						</Discussion>
					</SmallScreen>
				)}
			</Wrapper>
			{addModalOpen && (
				<PlaylistModal clipIds={[clip.id]} onClose={closeAddModal} />
			)}
			{discussionModalOpen && (
				<StepModal.Base onClose={closeDiscussionModal}>
					<StepModal.Step
						title={t('{num} comments', { num: numComments })}
						hidePrev
						nextLabel={t('Close')}>
						<ClipComment clipId={clip.id} />
						<ClipDiscussion />
					</StepModal.Step>
				</StepModal.Base>
			)}
		</Item>
	);

	if (selectable) {
		return (
			<Row columns="auto 1fr" spacing={styles.spacing._5} align="center">
				<Input.Control
					type="checkbox"
					standalone
					value={clip.id}
					checked={selected}
					onChange={onToggle}
				/>
				{item}
			</Row>
		);
	}

	return item;
}
