import { JSX, ReactNode, createContext, useContext, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import * as ls from 'pkg/local_storage';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import { cssClasses, cssVarList } from 'pkg/css/utils';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { delay } from 'pkg/timings';
import { isApp } from 'pkg/platform';
import * as models from 'pkg/api/models';

import Lottie from 'components/Lottie';

import * as css from './style.css';

const SplashScreenDelay: number = 3000;

interface SplashScreenState {
	primaryColor: string;
	imageUrl: string;
	badgeUrl: string;
}

export const DefaultSplashScreenState: SplashScreenState = {
	primaryColor: 'hsl(247deg 46% 21%)',
	imageUrl: '',
	badgeUrl: '',
};

export function deleteSplashScreenState(): void {
	ls.removeItem(ls.LocalStorageKeys.SplashScreen);
}

export function storeSplashScreenState(state: SplashScreenState): void {
	ls.setJsonItem<SplashScreenState>(ls.LocalStorageKeys.SplashScreen, {
		...DefaultSplashScreenState,
		...state,
	});
}

export function fetchSplashScreenState(): SplashScreenState {
	const state =
		ls.getJsonItem<SplashScreenState>(ls.LocalStorageKeys.SplashScreen) ??
		DefaultSplashScreenState;

	// Normalize color
	if (state.primaryColor?.startsWith('hsl:')) {
		state.primaryColor = state.primaryColor.replace('hsl:', 'hsl(') + ')';
	}

	return state;
}

export const SplashScreenContext = createContext<SplashScreenState>(
	DefaultSplashScreenState
);

export function useSplashScreen(): SplashScreenState {
	return useContext(SplashScreenContext);
}

interface SplashScreenProviderProps {
	children: ReactNode | ReactNode[];
}

export function SplashScreenProvider({
	children,
}: SplashScreenProviderProps): JSX.Element {
	return (
		<SplashScreenContext.Provider value={fetchSplashScreenState()}>
			{isApp() && <SplashScreen />}
			{children}
		</SplashScreenContext.Provider>
	);
}

interface FigureProps {
	src: string;
	className?: string;
}

function Figure({ src, className }: FigureProps): JSX.Element {
	const [didLoad, setDidLoad] = useState<boolean>(false);

	const handleDidLoad = async () => {
		setDidLoad(true);
	};

	const source = (
		<img
			src={src}
			loading="lazy"
			onLoad={handleDidLoad}
			className={cssClasses(
				css.figure,
				didLoad ? css.figureLoaded : null,
				className
			)}
		/>
	);

	if (src === '') return null;

	return <figure>{source}</figure>;
}

export function SplashScreen(): JSX.Element {
	const [isVisible, setVisible] = useState<boolean>(true);
	const zIndex = useNewTopIndex();
	const localSplash = useSplashScreen();

	const badgeImageUrl = localSplash.badgeUrl;
	const splashImageUrl = localSplash.imageUrl;
	const splashPrimaryColor = localSplash.primaryColor;
	const hasContents = !!splashImageUrl;

	const vars = cssVarList(
		{ primaryColor: splashPrimaryColor, zIndex },
		'zIndex'
	);

	useComponentDidMount(async () => {
		await delay(SplashScreenDelay);

		// @note This takes care of a "flashbang" bug when splash screen is loaded
		document.querySelector('#app').classList.remove('pending-splash-screen');
		document.body.classList.remove('pending-splash-screen');

		setVisible(false);
	});

	return (
		<AnimatePresence>
			{isVisible && (
				<motion.div
					style={vars}
					className={cssClasses(
						css.splashScreen,
						hasContents ? css.hasContent : null
					)}
					initial={{ opacity: 1 }}
					exit={{ opacity: 0, scale: 1.25 }}
					transition={{
						ease: 'linear',
					}}>
					<section>
						{badgeImageUrl ? (
							<Figure
								src={badgeImageUrl}
								className={cssClasses(
									css.badge,
									hasContents ? css.hasContent : null
								)}
							/>
						) : (
							<Lottie
								autoplay
								name="logo-single-static"
								className={css.badge}
							/>
						)}
					</section>
					<section className={css.imageWrapper}>
						<Figure src={splashImageUrl} />
					</section>
				</motion.div>
			)}
		</AnimatePresence>
	);
}

interface EditableSplashScreenProps {
	group: models.group.Group;
	children: ReactNode | ReactNode[];
}

export function EditableSplashScreen({
	group,
	children,
}: EditableSplashScreenProps): JSX.Element {
	const zIndex = useNewTopIndex();

	const groupSplash = models.group.getSplashScreenSettings(group);
	const badgeImageUrl = group.profileImageUrl;
	const splashImageUrl = groupSplash.imageUrl;
	const splashPrimaryColor = models.group.getPrimaryColorValue(group);
	const hasContents = !!splashImageUrl;

	const vars = cssVarList(
		{ primaryColor: splashPrimaryColor, zIndex },
		'zIndex'
	);

	return (
		<AnimatePresence>
			<motion.div
				style={vars}
				className={cssClasses(
					css.splashScreen,
					css.preview,
					hasContents ? css.hasContent : null
				)}
				initial={{ opacity: 1 }}
				exit={{ opacity: 0, scale: 1.25 }}
				transition={{
					ease: 'linear',
				}}>
				<section>
					{badgeImageUrl ? (
						<Figure src={badgeImageUrl} className={css.badge} />
					) : (
						<Lottie autoplay name="logo-single-static" className={css.badge} />
					)}
				</section>
				<section className={css.imageWrapper}>{children}</section>
			</motion.div>
		</AnimatePresence>
	);
}
