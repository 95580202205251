// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AT6kYLRXcg9HGCmpDveG {
	min-width: 100%;
	margin-top: var(--spacing-5);
	border: 1px solid var(--palette-gray-300);
	border-radius: 12px;
	background-color: var(--palette-gray-100);
	overflow: auto;
	padding: var(--spacing-3) 0;
}

@media (max-width: 768px) {

.AT6kYLRXcg9HGCmpDveG {
		margin-top: 0;
		border-radius: 0;
}
	}

.ChdDC_DpKEPk3HiAUJTA {
	display: grid;
	border-collapse: collapse;
}
`, "",{"version":3,"sources":["webpack://./design/table/table-footer/styles.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,4BAA4B;CAC5B,yCAAyC;CACzC,mBAAmB;CACnB,yCAAyC;CACzC,cAAc;CACd,2BAA2B;AAM5B;;AAJC;;AATD;EAUE,aAAa;EACb,gBAAgB;AAElB;CADC;;AAGD;CACC,aAAa;CACb,yBAAyB;AAC1B","sourcesContent":[".wrapper {\n\tmin-width: 100%;\n\tmargin-top: var(--spacing-5);\n\tborder: 1px solid var(--palette-gray-300);\n\tborder-radius: 12px;\n\tbackground-color: var(--palette-gray-100);\n\toverflow: auto;\n\tpadding: var(--spacing-3) 0;\n\n\t@media (--small-viewport) {\n\t\tmargin-top: 0;\n\t\tborder-radius: 0;\n\t}\n}\n\n.table {\n\tdisplay: grid;\n\tborder-collapse: collapse;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `AT6kYLRXcg9HGCmpDveG`;
export var table = `ChdDC_DpKEPk3HiAUJTA`;
export default ___CSS_LOADER_EXPORT___;
