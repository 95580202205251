import Lottie, { AnimationItem } from 'lottie-web';
import { JSX, useEffect, useRef, useState } from 'react';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { cssClasses } from 'pkg/css/utils';

import * as css from './styles.css';

export default function Logo(): JSX.Element {
	const targetNodeRef = useRef<HTMLDivElement>();

	const [animation, setAnimation] = useState<AnimationItem>();

	const [willAnimate, setWillAnimate] = useState<boolean>(false);

	useComponentDidMount(async () => {
		if (animation) {
			return;
		}
		const hasAnimatedLogotype = sessionStorage.getItem('has-animated-logotype');

		const animationData = await import(
			'assets/lottie-animations/logo-single-static.json'
		);

		const animationItem = Lottie.loadAnimation({
			animationData: animationData.default,
			container: targetNodeRef.current,
			renderer: 'svg',
			autoplay: false,
			loop: false,
		});

		setAnimation(animationItem);

		if (!hasAnimatedLogotype) {
			setWillAnimate(() => {
				sessionStorage.setItem('has-animated-logotype', '1');

				return true;
			});
		}
	});

	useEffect(() => {
		if (animation) {
			if (willAnimate) {
				animation.play();
			} else {
				animation.goToAndStop(47, true);
			}
		}
	}, [animation, willAnimate]);

	return (
		<div
			ref={targetNodeRef}
			className={cssClasses(css.logo, willAnimate ? css.animated : '')}
		/>
	);
}
