import { t } from '@transifex/native';

import * as flashActions from 'pkg/actions/flashes';

import * as sdk from 'pkg/core/sdk';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

export async function createUserFieldCategory(
	groupId: number,
	requestPayload: models.userFieldCategories.UserFieldCategory
): Promise<[boolean, models.userFieldCategories.UserFieldCategory]> {
	const [request, response] = await models.create<
		models.userFieldCategories.UserFieldCategory,
		models.userFieldCategories.UserFieldCategory
	>(endpoints.UserFieldCategories.Create(groupId), requestPayload);

	if (!request.ok) {
		flashActions.show({ title: t('Something went wrong') }, request.status);
		return [false, null];
	}

	return [true, response];
}
export async function updateUserFieldCategory(
	groupId: number,
	payload: { title: string },
	categoryInEditId: number
): Promise<[boolean, models.userFieldCategories.UserFieldCategory]> {
	const req = await sdk.patch(
		endpoints.UserFieldCategories.Update(groupId, categoryInEditId),
		{},
		payload
	);

	const json = await req.json();

	if (!req.ok) {
		flashActions.show({ title: t('Something went wrong') }, json.error);
		return [false, null];
	}

	if (req.ok) {
		return [true, json];
	}
}

export async function updateUserFieldCategorySortOrder(
	groupId: number,
	categoryIds: number[]
): Promise<[boolean, models.userFieldCategories.UserFieldCategory[]]> {
	const req = await sdk.patch(
		endpoints.UserFieldCategories.UpdateSortOrder(groupId),
		{},
		categoryIds
	);

	const json = await req.json();

	if (!req.ok) {
		flashActions.show({ title: t('Something went wrong') }, json.error);
		return [false, null];
	}

	if (req.ok) {
		return [true, json];
	}
}
