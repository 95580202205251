import { t } from '@transifex/native';
import { JSX, useEffect, Fragment } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';

import {
	fetchMatchEventTypes,
	fetchMatchEvents,
} from 'pkg/actions/match_events_deprecated';
import { getEventUsers } from 'pkg/actions/events';
import { deprecatedFetchPositions } from 'pkg/actions/positions';
import { fetchMatchById } from 'pkg/actions/matches_deprecated';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import * as selectors from 'pkg/selectors';
import { replaceState } from 'pkg/router/state';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';

import Lineup from 'routes/event/single/tabs/lineup';

import EditMatch from 'containers/match/edit/Match';

import { Spinner } from 'components/loaders/spinner';
import { useTemplateContext } from 'components/layout/page-templates/context';

interface MatchEditProps {
	matchId: number;
	eventId: number;
}

export default function MatchEdit({
	matchId,
	eventId,
}: MatchEditProps): JSX.Element {
	const { setPageTitle, currentTab } = useTemplateContext();
	const dispatch = useDispatch();
	const org = useCurrentOrganization();
	const membership = useCurrentMembership();

	const { record: match, isLoading: isLoadingMatch } =
		useEndpoint<models.match.Match>(endpoints.Match.Show(matchId));

	const { record: event, isLoading: isLoadingEvent } =
		useEndpoint<models.event.Event>(endpoints.Events.Show(eventId));

	const isLoading = isLoadingMatch || isLoadingEvent;

	const matchEventTypes = useSelector(
		selectors.matchEvents.getFilteredMatchEventTypes
	);
	const positions = useSelector(selectors.positions.getPositions);

	const isAdminOrStaff = models.membership.isAdminOrStaff(membership);

	useEffect(() => {
		const fetchData = async () => {
			const matchResponse: any = await dispatch(fetchMatchById(matchId));

			batch(() => {
				getEventUsers(matchResponse.eventId);
				dispatch(fetchMatchEvents(matchId));

				if (matchEventTypes.size === 0) {
					dispatch(fetchMatchEventTypes());
				}

				if (positions.length === 0) {
					dispatch(deprecatedFetchPositions());
				}
			});

			if (!isAdminOrStaff) {
				replaceState(
					routes.Event.View(org.id, matchResponse.eventId, 'overview')
				);
			}
		};

		fetchData();
	}, [
		isAdminOrStaff,
		matchEventTypes.size,
		matchId,
		positions.length,
		dispatch,
	]);

	useEffect(() => {
		if (match.title) {
			setPageTitle(t(`Editing {title}`, { title: match.title }));
		}
	}, [match.title]);

	const renderTabViews = (currentTab: string) => {
		switch (currentTab) {
			case 'result':
				return <EditMatch matchId={matchId} />;
			case 'lineup':
				return (
					<Fragment>
						<Lineup event={event} match={match} />
					</Fragment>
				);
			default:
				return null;
		}
	};

	if (isLoading) {
		return <Spinner />;
	}

	return renderTabViews(currentTab);
}
