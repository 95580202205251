import { JSX } from 'react';
import { useT } from '@transifex/react';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import * as routes from 'pkg/router/routes';

import Videos from 'routes/video/library/group/Videos';

import Section from 'components/section';

import Row from 'components/layout/row';

import Button from 'design/button';

interface TaggedVideosProps {
	user: models.user.User;
}

export default function TaggedVideos({ user }: TaggedVideosProps): JSX.Element {
	const t = useT();

	const {
		records: videos,
		isLoading,
		pagination,
		replaceRecord,
		removeRecord,
	} = useCollection<models.video.Video>(endpoints.Users.ListVideos(user.id), {
		count: 3,
	});

	if (isLoading || videos.length === 0) {
		return null;
	}

	return (
		<Section
			title={t('Tagged videos')}
			icon="animated_images"
			action={
				<Button
					small
					inline
					icon="arrow_forward_ios"
					iconPosition="right"
					href={routes.Organization.User.Profile.Show(
						user.organizationId,
						user.id,
						'videos'
					)}>
					{t('Show all')}
				</Button>
			}>
			<Row collapseOnSmallScreens columns="repeat(3, 1fr)">
				<Videos
					skipWrapper
					title={t('Videos')}
					items={videos}
					hasNext={pagination.hasNext}
					fetchNext={pagination.fetchNext}
					replaceRecord={replaceRecord}
					removeRecord={removeRecord}
					context="user-profile"
				/>
			</Row>
		</Section>
	);
}
