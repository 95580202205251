import * as models from 'pkg/api/models';
import { Dateable } from 'pkg/api/models/dateable';

enum AccountTokenType {
	AccountTokenTypePasswordReset = 0,
	AccountTokenTypeOneTimeLogin = 1,
	AccountTokenTypeCalendarSubscription = 2,
	AccountTokenTypeParentalConsent = 3,
}

export interface AccountToken extends Dateable {
	accountId: number;
	token: string;
	type: AccountTokenType;
	expired: boolean;
	account: models.account.Account;
}
