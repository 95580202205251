import {
	JSX,
	useState,
	useRef,
	useEffect,
	cloneElement,
	Fragment,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled, { css } from 'styled-components';

import Icon from 'components/icon';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { useClipState } from 'components/video-analytics/ClipState';
import { toolConfig } from 'components/annotations/tools/ToolConfig';
import AnnotationResizer from 'components/annotations/AnnotationResizer';
import AnnotationPreferences from 'components/annotations/AnnotationPreferences';
import Spine from 'components/annotations/tools/Spine';
import { extractPoints } from 'components/annotations/AnnotationHooks';
import {
	ANNOTATION_PREFIX,
	IS_DRAGGING,
	IS_EDITING,
	IS_PAUSED,
} from 'components/annotations/constants';

const Wrapper = styled.div<{
	isDragging: boolean;
	isCurrent: boolean;
	multipoint: boolean;
}>`
	position: absolute;
	pointer-events: none;

	.${IS_EDITING} & {
		svg > * {
			pointer-events: visiblePainted;
			cursor: grab;
		}
	}
	.${IS_EDITING} &.${IS_DRAGGING} {
		svg > * {
			cursor: grabbing;
		}
	}

	${({ multipoint }) =>
		multipoint &&
		css`
			width: 100%;
			height: 100%;
			cursor: default;
		`}

	opacity: 0;
	.${IS_EDITING}.${IS_PAUSED} &[style*='opacity: 0'] {
		opacity: 0.3 !important;
	}
`;
// the [opacity: 0] selector shows objects that are not visible at the current playhead position when editing to allow re-enabling or adding keyframes

const Annotation = styled(motion.div)`
	position: relative;
	width: 100%;
	height: 100%;
`;

export const FloatingButton = styled(motion.button)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 27px;
	height: 27px;
	border: none;
	background: #292c34;
	border-radius: 50%;
	cursor: pointer;

	&:hover {
		background: #33363b;
	}

	svg {
		margin-left: 1px;
	}

	&.red {
		background: #ea3b3b;
	}
	&.blue {
		background: #0066ff;
	}
`;

// todo: set actual types
interface SingleAnnotationProps {
	annotationId: string;
	annotation: Record<string, unknown> | any;
	annotations: Record<string, unknown> | any;
	setActiveAnnotation: (annotationId: string) => void;
	isCurrent: boolean;
	coverSize: {
		width: number;
		height: number;
	};
}

interface DragPosition {
	left: number;
	top: number;
	width?: number;
	height?: number;
	zIndex?: number;
}

export default function SingleAnnotation({
	annotationId,
	annotation,
	annotations,
	setActiveAnnotation,
	isCurrent,
	coverSize,
}: SingleAnnotationProps): JSX.Element {
	const el = useRef<HTMLDivElement>(null);
	const { controller } = usePlayerState();
	const { currentTime } = usePlaybackState();
	const currentMs = currentTime * 1000;
	const { setAnnotations, setActiveKeyframe, setAnnotation } = useClipState();

	const updateKeyframe = (value: DragPosition, hide?: boolean) => {
		const keyframes = [...annotation.keyframes];

		// find or create current keyframe
		let currentKeyframeIndex = keyframes.findIndex(
			(keyframe: any) => Math.abs(keyframe.time - currentMs) < 100 // find existing keyframe within +- 100ms
		);

		if (currentKeyframeIndex < 0) {
			currentKeyframeIndex = keyframes.push({ time: currentMs }) - 1;
		}

		// convert pixel value to relative value
		const relativeValue = Object.entries(value).reduce(
			(prev: JSONObject, [key, value]: any) => {
				if (key === 'left' || key === 'width') value = value / coverSize.width;
				if (key === 'top' || key === 'height') value = value / coverSize.height;
				prev[key] = value;

				return prev;
			},
			{}
		);

		// add values
		keyframes[currentKeyframeIndex] = {
			...keyframes[currentKeyframeIndex],
			...relativeValue,
		};

		// recalculate points for multipoint annotations
		if (config.move && config.multipoint && value.left) {
			// extract points and positions from current path
			const currentPath = multipointRef?.current?.getAttribute('d');
			const points: any = {};
			extractPoints(currentPath).forEach((point: any, i: number) => {
				const [left, top] = point.split(' ');
				points[i] = {
					left: +left / 100 + keyframes[currentKeyframeIndex].left,
					top: +top / 100 + keyframes[currentKeyframeIndex].top,
				};
			});

			// add points, remove offset
			keyframes[currentKeyframeIndex].points = points;
			delete keyframes[currentKeyframeIndex].left;
			delete keyframes[currentKeyframeIndex].top;
		}

		// toggle visibility
		if (hide !== undefined) {
			keyframes[currentKeyframeIndex].hide =
				!keyframes[currentKeyframeIndex].hide;
		}

		// keyframes must be added in chronological order
		keyframes.sort((a: Keyframe, b: Keyframe) => (a.time >= b.time ? 1 : -1));

		setAnnotation(annotationId, { ...annotation, keyframes });

		// set active keyframe
		const sortedIndex = keyframes.findIndex(
			(keyframe) => keyframe.time === currentMs
		);
		setActiveKeyframe(sortedIndex);
	};

	const updatePreference = (value: Record<string, unknown>) => {
		setAnnotation(annotationId, { ...annotation, ...value });
	};

	// get current tool config and visual component
	const config = toolConfig[annotation?.tool];
	const ToolComponent = config?.component;

	// store some dragging state
	const [dragStart, setDragStart] = useState<DragPosition>();
	const [dragPosition, setDragPosition] = useState<DragPosition>();
	const isDragging = !!dragStart;
	const [isPreferencesOpen, setIsPreferencesOpen] = useState<boolean>(false);

	const startDragging = () => {
		const currentPosition = {
			left: el.current?.offsetLeft,
			top: el.current?.offsetTop,
			width: el.current?.offsetWidth,
			height: el.current?.offsetHeight,
		};

		setDragPosition(currentPosition);
		setDragStart(currentPosition);
		setIsPreferencesOpen(false);
	};

	const stopDragging = () => {
		setDragStart(null);
	};

	const onPanStart = () => {
		controller.pause();
		startDragging();
	};

	const onPan = (_e: Event, { offset }: any) => {
		if (dragStart) {
			const position: DragPosition = {
				left: dragStart.left + offset.x,
				top: dragStart.top + offset.y,
			};

			if (config.resize) {
				position.width = dragStart.width;
				position.height = dragStart.height;
			}

			setDragPosition(position);
		}
	};

	const onPanEnd = () => {
		stopDragging();
		updateKeyframe(dragPosition);
	};

	const togglePreferences = () => {
		setIsPreferencesOpen(!isPreferencesOpen);
	};

	// close preferences when annotation is unselected
	useEffect(() => {
		if (!isCurrent && isPreferencesOpen) setIsPreferencesOpen(false);
	}, [isCurrent]);

	// set inline style while dragging and with current z-index position
	const style: JSONObject | DragPosition = dragStart ? dragPosition : {};
	if (annotation.z) style.zIndex = annotation.z;

	// current actual positions, calculated by timeline animation OR drag position
	const currentLeft = el.current?.offsetLeft || dragPosition?.left;
	const currentTop = el.current?.offsetTop || dragPosition?.top;
	const currentWidth = el.current?.offsetWidth || dragPosition?.width;
	const currentHeight = el.current?.offsetHeight || dragPosition?.height;

	// figure out preferences toggle and pane position
	const isOutsideRight = currentLeft + currentWidth > coverSize.width;
	const isOutsideTop = currentTop < 0;
	const toggleLeft = isOutsideRight
		? currentLeft - 35
		: currentLeft + currentWidth + 12;
	const toggleTop = isOutsideTop ? currentTop + currentHeight - 20 : currentTop;

	// set ref to multipoint annotation to sync path with spine
	const multipointRef = useRef<SVGSVGElement>();

	let visiblePoint;
	if (config.multipoint) {
		const currentPath = multipointRef?.current?.getAttribute('d');
		visiblePoint =
			extractPoints(currentPath)
				.reverse()
				.find((point: any) => {
					const [left, top] = point.split(' ');

					return left > 0 && left < 90 && top > 0 && top < 90;
				})
				?.split(' ')
				.map((val: number) => val / 100) || [];
	}

	const setCurrent = () => {
		setActiveAnnotation(annotationId);
	};

	return (
		<Fragment>
			<Wrapper
				ref={el}
				style={style}
				isCurrent={isCurrent}
				isDragging={isDragging}
				multipoint={!!config.multipoint}
				className={
					ANNOTATION_PREFIX +
					annotationId +
					' ' +
					(isDragging ? IS_DRAGGING : '')
				}>
				<Annotation
					onPointerUp={setCurrent}
					onPanStart={onPanStart}
					onPan={onPan}
					onPanEnd={onPanEnd}>
					{ToolComponent &&
						cloneElement(ToolComponent, {
							annotationId,
							annotation,
							config,
							updatePreference,
							multipointRef,
							coverSize,
						})}
				</Annotation>
				{isCurrent && config.multipoint && multipointRef?.current && (
					<Spine
						multipointRef={multipointRef}
						closed={config.closedPath}
						updateKeyframe={updateKeyframe}
					/>
				)}
			</Wrapper>
			{isCurrent && config.resize && (
				<AnnotationResizer
					updateKeyframe={updateKeyframe}
					dragStart={dragStart}
					startDragging={startDragging}
					stopDragging={stopDragging}
					dragPosition={dragPosition}
					setDragPosition={setDragPosition}
					style={{
						left: currentLeft,
						top: currentTop,
						width: currentWidth,
						height: currentHeight,
					}}
				/>
			)}
			<AnimatePresence>
				{isCurrent && !isPreferencesOpen && !isDragging && (
					<FloatingButton
						onClick={togglePreferences}
						style={{
							position: 'absolute',
							left: config.multipoint
								? (visiblePoint && visiblePoint[0] * coverSize.width + 50) || 0
								: toggleLeft || 0,
							top: config.multipoint
								? (visiblePoint && visiblePoint[1] * coverSize.height - 50) || 0
								: toggleTop || 0,
							zIndex: 1000,
						}}
						initial={{ opacity: 0, scale: 0 }}
						exit={{ opacity: 0, scale: 0 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{ ease: 'easeOut', duration: 0.2 }}>
						<Icon name="nav-settings" fill="white" size={1.5} />
					</FloatingButton>
				)}
				{isCurrent && isPreferencesOpen && (
					<AnnotationPreferences
						updatePreference={updatePreference}
						updateKeyframe={updateKeyframe}
						setAnnotations={setAnnotations}
						annotations={annotations}
						annotationId={annotationId}
						config={toolConfig[annotation?.tool]}
						closePreferences={togglePreferences}
						singlepointRef={el}
						multipointRef={multipointRef}
						coverSize={coverSize}
						isOutsideRight={!config.multipoint && isOutsideRight}
						style={
							config.multipoint
								? {
										left:
											visiblePoint[0] * coverSize.width +
											el.current.offsetParent.getBoundingClientRect().x +
											50,
										top: visiblePoint[1] * coverSize.height + 10,
									}
								: {
										left:
											(isOutsideRight ? currentLeft - 188 : toggleLeft) +
											el.current.offsetParent.getBoundingClientRect().x,
										top:
											(isOutsideTop
												? currentTop + currentHeight - 20
												: toggleTop) +
											el.current.offsetParent.getBoundingClientRect().y,
									}
						}
					/>
				)}
			</AnimatePresence>
		</Fragment>
	);
}
