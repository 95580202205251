import { JSX, Dispatch, Fragment } from 'react';

import * as models from 'pkg/api/models';

import useGroupSelectTable from 'components/group/use-select-table';

interface RecipientsStepProps {
	formData: any;
	localDispatch: Dispatch<any>;
	groupId: number;
}

export default function RecipientsStep({
	formData,
	localDispatch,
	groupId,
}: RecipientsStepProps): JSX.Element {
	const groupSelectTable = useGroupSelectTable({
		groupId,
		defaultSelectedGroupIds: formData.includeGroupIds,
		onChange: (groups: models.group.Group[]) => {
			localDispatch({
				type: 'set_include_group',
				payload: groups.map((group: models.group.Group) => group.id),
			});
		},
	});

	return <Fragment>{groupSelectTable.Component}</Fragment>;
}
