import { Fragment, JSX } from 'react';
import { useT } from '@transifex/react';
import { AnimatePresence } from 'framer-motion';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';

import UserLog from 'routes/organization/user-profile/notes/user-log';
import UserLogCreateForm from 'routes/organization/user-profile/notes/form/create';
import useMembershipSelect from 'routes/organization/user-profile/use-membership-select';

import * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import MaterialSymbol from 'components/material-symbols';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

import * as css from './styles.css';

interface OrganizationUserProfileNotesProps {
	user: models.user.User;
}

export default function OrganizationUserProfileNotes({
	user,
}: OrganizationUserProfileNotesProps): JSX.Element {
	const t = useT();

	const { MembershipSelect, selectedMembership } = useMembershipSelect(user);

	const canList = models.hasAllowedAction(user, models.Action.UserListNotes);

	const canCreate = models.hasAllowedAction(
		user,
		models.Action.UserCreateUserLog
	);

	const {
		records: userLogs,
		addRecord,
		removeRecord,
		replaceRecord,
		isLoading,
		pagination,
	} = useCollection<models.userLog.UserLog>(
		canList ? endpoints.UserLogs.Index(user.id) : null,
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				group_id: selectedMembership?.groupId.toString(),
			}),
		}
	);

	const handleCreate = async (userLog: models.userLog.UserLog) => {
		addRecord(userLog, true);
	};

	const handleRemove = (userLog: models.userLog.UserLog) => {
		removeRecord(userLog.id);
	};

	const handleUpdate = (userLog: models.userLog.UserLog) => {
		replaceRecord(userLog);
	};

	if (!canList) {
		return (
			<Column className={css.wrapper} spacing={styles.spacing._7}>
				<Card.Base>
					<MissingEntities centered>
						<MaterialSymbol actualSize variant="visibility_off" scale={3} />
						<h3>{t('Cannot show user notes')}</h3>
						<p>
							{t(
								`You do not have suffucuent permission to view this user's notes.`
							)}
						</p>
					</MissingEntities>
				</Card.Base>
			</Column>
		);
	}

	return (
		<Fragment>
			{MembershipSelect}
			<Column className={css.wrapper} spacing={styles.spacing._7}>
				{canCreate && <UserLogCreateForm user={user} onCreate={handleCreate} />}

				{isLoading && <Spinner />}

				<section>
					<AnimatePresence>
						{userLogs.map((userLog: models.userLog.UserLog) => (
							<UserLog
								key={userLog.id}
								user={user}
								userLog={userLog}
								onUpdate={handleUpdate}
								onRemove={handleRemove}
							/>
						))}
					</AnimatePresence>
				</section>

				{pagination.hasNext && (
					<div>
						<Button onClick={pagination.fetchNext}>
							{t('Load older notes')}
						</Button>
					</div>
				)}
			</Column>
		</Fragment>
	);
}
