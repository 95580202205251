import React, {
	useMemo,
	useReducer,
	useCallback,
	createContext,
	useContext,
	ReactNode,
	JSX,
} from 'react';
import VimeoPlayer from '@vimeo/player';

import { Precision } from 'routes/video/analyze/timeline/config';

import { ContainerProps } from 'components/video-analytics/Container';
import YouTubeContainer from 'components/video-analytics/YouTubeContainer';
import VideoContainer from 'components/video-analytics/VideoContainer';
import VimeoContainer from 'components/video-analytics/VimeoContainer';
import YouTubeController from 'components/video-analytics/YouTubeController';
import VideoController from 'components/video-analytics/VideoController';
import VimeoController from 'components/video-analytics/VimeoController';
import StubController from 'components/video-analytics/StubController';
import {
	YouTubeUrl,
	VimeoUrl,
	VideoUrl,
	typeOfSource,
	SourceType,
	isValidSource,
	isValidYouTubeSource,
	isValidVimeoSource,
	isValidVideoSource,
} from 'components/video-analytics/source/SourceUrl';
import {
	ControllerInterfaceType,
	ControllerType,
	typeOfController,
} from 'components/video-analytics/Controller';

type PlayerSource =
	| typeof VideoContainer
	| typeof YouTubeContainer
	| typeof VimeoContainer;

type PlayerController =
	| YouTubeController
	| VideoController
	| VimeoController
	| StubController;

interface PlayerState {
	isReady?: boolean;
	isBuffering?: boolean;
	hasError?: boolean;

	isPlaying?: boolean;
	isScrubbing?: boolean;
	justScrubbed?: boolean;
	isRearranging?: boolean;
	isEditing?: boolean;
	isTrimming?: boolean;
	isRecording?: boolean;
	justRecorded?: boolean;

	timelineZoom?: number;
	timelinePrecision?: Precision;

	showingClipList?: boolean;
	hasValidSource?: boolean;
	duration?: number;
	volume?: number;
	playbackRate?: number;
	gaplessPlayback?: boolean;

	source?: PlayerSource;
	sourceType?: SourceType;
	sourceUrl?: string;
	controller?: PlayerController;
}

export enum PlayerAction {
	Ready = 'isReady',
	Buffering = 'isBuffering',
	HasError = 'hasError',
	Reset = 'reset',

	Playing = 'isPlaying',
	Scrubbing = 'isScrubbing',
	JustScrubbed = 'justScrubbed',
	Rearranging = 'isRearranging',
	Editing = 'isEditing',
	Trimming = 'isTrimming',
	Recording = 'isRecording',
	JustRecorded = 'justRecorded',

	TimelineZoom = 'timelineZoom',
	TimelinePrecision = 'timelinePrecision',
	ShowingClipList = 'showingClipList',

	Duration = 'duration',
	Volume = 'volume',
	PlaybackRate = 'playbackRate',
	GaplessPlayback = 'gaplessPlayback',

	Source = 'source',
	Controller = 'controller',
}

interface PlayerControllerPayload {
	controller: ControllerInterfaceType;
}

interface PlayerSourcePayload {
	source: string;
	hasValidSource: boolean;
	options?: ContainerProps;
}

export type Action =
	| { type: PlayerAction.Ready; payload: PlayerState }
	| { type: PlayerAction.Buffering; payload: PlayerState }
	| { type: PlayerAction.HasError; payload: PlayerState }
	| { type: PlayerAction.Reset; payload: PlayerState }
	| { type: PlayerAction.Playing; payload: PlayerState }
	| { type: PlayerAction.Scrubbing; payload: PlayerState }
	| { type: PlayerAction.JustScrubbed; payload: PlayerState }
	| { type: PlayerAction.Rearranging; payload: PlayerState }
	| { type: PlayerAction.Editing; payload: PlayerState }
	| { type: PlayerAction.Trimming; payload: PlayerState }
	| { type: PlayerAction.Recording; payload: PlayerState }
	| { type: PlayerAction.JustRecorded; payload: PlayerState }
	| { type: PlayerAction.TimelineZoom; payload: PlayerState }
	| { type: PlayerAction.TimelinePrecision; payload: PlayerState }
	| { type: PlayerAction.ShowingClipList; payload: PlayerState }
	| { type: PlayerAction.Duration; payload: PlayerState }
	| { type: PlayerAction.Volume; payload: PlayerState }
	| { type: PlayerAction.PlaybackRate; payload: PlayerState }
	| { type: PlayerAction.GaplessPlayback; payload: PlayerState }
	| { type: PlayerAction.Source; payload: PlayerSourcePayload }
	| { type: PlayerAction.Controller; payload: PlayerControllerPayload };

interface PlayerStateDispatch {
	state: PlayerState;
	dispatch?: React.Dispatch<Action>;
	emit?: (type: PlayerAction, payload: any) => void;
}

const initialState: PlayerState = {
	isReady: false,
	isBuffering: false,
	hasError: false,

	isPlaying: false,
	isScrubbing: false,
	justScrubbed: false,
	isRearranging: false,
	isEditing: false,
	isTrimming: false,
	isRecording: false,
	justRecorded: false,

	timelineZoom: 1.5,
	timelinePrecision: Precision.OneMinute,

	showingClipList: false,
	hasValidSource: false,
	duration: 0,
	volume: 1,
	playbackRate: 1,
	gaplessPlayback: false,

	source: null,
	sourceUrl: '',
	controller: new StubController(),
};

const PlayerContext = createContext<PlayerStateDispatch>({
	state: initialState,
});

export interface PlayerHookResponse extends PlayerState {
	reset: () => void;

	setReady: (isReady: boolean) => void;
	setBuffering: (isBuffering: boolean) => void;
	setHasError: (hasError: boolean) => void;
	setPlaying: (isPlaying: boolean) => void;
	setScrubbing: (isScrubbing: boolean) => void;
	setJustScrubbed: (justScrubbed: boolean) => void;
	setRearranging: (isRearranging: boolean) => void;
	setEditing: (isEditing: boolean) => void;
	setTrimming: (isTrimming: boolean) => void;
	setRecording: (isRecording: boolean) => void;
	setJustRecorded: (justRecorded: boolean) => void;

	setTimelineZoom: (zoomLelve: number) => void;
	setTimelinePrecision: (precision: Precision) => void;

	setShowingClipList: (showingClipList: boolean) => void;
	setDuration: (duration: number) => void;
	setVolume: (volume: number) => void;
	setPlaybackRate: (playbackRate: number) => void;
	setGaplessPlayback: (gaplessPlayback: boolean) => void;

	setSource: (sourceUrl: string, options?: ContainerProps) => void;
	setController: (controller: ControllerInterfaceType) => void;
}

export const usePlayerState = (): PlayerHookResponse => {
	const { state, dispatch, emit } = useContext(PlayerContext);

	const reset = (): void => emit(PlayerAction.Reset, null);

	/**
	 *	Performs a state reset, but maintains controller, volume, playbackRate and gaplessPlayback.
	 *	Used for playlists so that user settings are the same when source changes.
	 */
	const softReset = (): void => {
		const { volume, playbackRate, gaplessPlayback, controller } = state;

		emit(PlayerAction.Reset, {
			volume,
			playbackRate,
			gaplessPlayback,
			controller,
		});
	};

	const setReady = (isReady: boolean): void => {
		emit(PlayerAction.Ready, isReady);
		emit(PlayerAction.HasError, false);
	};

	const setBuffering = (isBuffering: boolean): void =>
		emit(PlayerAction.Buffering, isBuffering);

	const setHasError = (hasError: boolean): void =>
		emit(PlayerAction.HasError, hasError);

	const setPlaying = (isPlaying: boolean): void =>
		emit(PlayerAction.Playing, isPlaying);

	const setScrubbing = (isScrubbing: boolean): void =>
		emit(PlayerAction.Scrubbing, isScrubbing);

	const setJustScrubbed = (justScrubbed: boolean): void =>
		emit(PlayerAction.JustScrubbed, justScrubbed);

	const setRearranging = (isRearranging: boolean): void =>
		emit(PlayerAction.Rearranging, isRearranging);

	const setEditing = (isEditing: boolean): void =>
		emit(PlayerAction.Editing, isEditing);

	const setTrimming = (isTrimming: boolean): void =>
		emit(PlayerAction.Trimming, isTrimming);

	const setRecording = (isRecording: boolean): void => {
		emit(PlayerAction.Recording, isRecording);

		if (!isRecording) {
			emit(PlayerAction.JustRecorded, isRecording);
		}
	};

	const setJustRecorded = (justRecorded: boolean): void =>
		emit(PlayerAction.JustRecorded, justRecorded);

	const setTimelineZoom = (zoomLevel: number): void =>
		emit(PlayerAction.TimelineZoom, zoomLevel);

	const setTimelinePrecision = (precision: Precision): void =>
		emit(PlayerAction.TimelinePrecision, precision);

	const setShowingClipList = (showingClipList: boolean): void =>
		emit(PlayerAction.ShowingClipList, showingClipList);

	const setDuration = (duration: number): void =>
		emit(PlayerAction.Duration, duration);

	const setVolume = (volume: number): void => emit(PlayerAction.Volume, volume);

	const setPlaybackRate = (playbackRate: number): void =>
		emit(PlayerAction.PlaybackRate, playbackRate);

	const setGaplessPlayback = (gaplessPlayback: boolean): void =>
		emit(PlayerAction.GaplessPlayback, gaplessPlayback);

	const setSource = (source: string, options: ContainerProps = {}): void => {
		softReset();

		dispatch({
			type: PlayerAction.Source,
			payload: {
				source,
				hasValidSource: isValidSource(source),
				...options,
			},
		});
	};

	const setController = (controller: ControllerInterfaceType): void =>
		emit(PlayerAction.Controller, controller);

	return {
		...state,
		dispatch,
		emit,

		reset,
		setReady,
		setBuffering,
		setHasError,

		setPlaying,
		setScrubbing,
		setJustScrubbed,
		setRearranging,
		setTrimming,
		setEditing,
		setRecording,
		setJustRecorded,

		setTimelineZoom,
		setTimelinePrecision,

		setShowingClipList,
		setDuration,
		setVolume,
		setPlaybackRate,
		setGaplessPlayback,

		setSource,
		setController,
	} as PlayerHookResponse;
};

interface StateProviderProps {
	children: ReactNode;
}

const reducer = (state: PlayerState, action: Action) => {
	const mutateState = (
		state: PlayerState,
		nextState: PlayerState
	): PlayerState => ({
		...state,
		...nextState,
	});

	const actionType = action.type;

	let payload;

	switch (actionType) {
		case PlayerAction.Reset:
			const { reset } = action.payload as any;
			return { ...initialState, ...reset };
		case PlayerAction.Ready:
		case PlayerAction.Buffering:
		case PlayerAction.HasError:
		case PlayerAction.Playing:
		case PlayerAction.Scrubbing:
		case PlayerAction.JustScrubbed:
		case PlayerAction.Rearranging:
		case PlayerAction.Trimming:
		case PlayerAction.Editing:
		case PlayerAction.Recording:
		case PlayerAction.JustRecorded:
		case PlayerAction.TimelineZoom:
		case PlayerAction.TimelinePrecision:
		case PlayerAction.ShowingClipList:
		case PlayerAction.Duration:
		case PlayerAction.Volume:
		case PlayerAction.PlaybackRate:
		case PlayerAction.GaplessPlayback:
			return mutateState(state, action.payload as PlayerState);

		case PlayerAction.Source:
			payload = action.payload as PlayerSourcePayload;

			let container: unknown;

			switch (typeOfSource(payload.source)) {
				case SourceType.YouTube:
					container = (
						<YouTubeContainer
							key={payload.source}
							{...payload}
							source={new YouTubeUrl(payload.source)}
						/>
					);

					return mutateState(state, {
						source: container as typeof YouTubeContainer,
						sourceUrl: payload.source,
						sourceType: SourceType.YouTube,
						hasValidSource: isValidYouTubeSource(payload.source),
					});
				case SourceType.Vimeo:
					container = (
						<VimeoContainer
							key={payload.source}
							{...payload}
							source={new VimeoUrl(payload.source)}
						/>
					);

					return mutateState(state, {
						source: container as typeof VimeoContainer,
						sourceUrl: payload.source,
						sourceType: SourceType.Vimeo,
						hasValidSource: isValidVimeoSource(payload.source),
					});
				case SourceType.Video:
					container = (
						<VideoContainer
							key={payload.source}
							{...payload}
							source={new VideoUrl(payload.source)}
						/>
					);

					return mutateState(state, {
						source: container as typeof VideoContainer,
						sourceUrl: payload.source,
						sourceType: SourceType.Video,
						hasValidSource: isValidVideoSource(payload.source),
					});
			}

		case PlayerAction.Controller:
			payload = action.payload as PlayerControllerPayload;

			let controller: PlayerController;

			switch (typeOfController(payload.controller)) {
				case ControllerType.YouTube:
					controller = new YouTubeController(payload.controller as YT.Player);
					break;
				case ControllerType.Vimeo:
					controller = new VimeoController(payload.controller as VimeoPlayer);
					break;
				case ControllerType.Video:
					controller = new VideoController(payload.controller as any);
					break;
			}

			if (controller) {
				controller.setPlaybackRate(state.playbackRate);
				controller.setVolume(state.volume);
			}

			return mutateState(state, { controller });
		default:
			throw new Error(`Invalid action '${actionType}'.`);
	}
};

const PlayerStateProvider = ({ children }: StateProviderProps): JSX.Element => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const emit = useCallback(
		(type: PlayerAction, payload: any) =>
			dispatch({
				type,
				payload: {
					[type]: payload,
				},
			} as any),
		[dispatch]
	);

	const store: PlayerStateDispatch = useMemo(
		() => ({
			state,
			dispatch,
			emit,
		}),
		[state, dispatch, emit]
	);

	return (
		<PlayerContext.Provider value={store}>{children}</PlayerContext.Provider>
	);
};

export default PlayerStateProvider;
