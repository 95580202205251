import { JSX, PointerEvent } from 'react';
import { t } from '@transifex/native';

import spacing from 'pkg/config/spacing';

import * as models from 'pkg/api/models';
import { useCurrentGroupId } from 'pkg/identity';
import { cssClasses } from 'pkg/css/utils';
import { pushState } from 'pkg/router/state';
import * as json from 'pkg/json';
import * as routes from 'pkg/router/routes';
import { MembershipStatus } from 'pkg/api/models/membership';

import { Event, trackEvent } from 'components/analytics';
import Badge from 'components/Badge';
import MaterialSymbol from 'components/material-symbols';
import Label from 'components/label';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import { useGroupSwitcher } from 'components/navigation/menus/group_switcher/Provider';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface GroupSwitcherItemProps {
	userId?: number;
	group: models.group.Group;
	supHeading?: string;
	subHeading?: string;

	onTraverse?: (nextParentGroupId: number) => void;
}

export default function GroupSwitcherItem({
	userId,
	group,
	supHeading,
	subHeading,

	onTraverse,
}: GroupSwitcherItemProps): JSX.Element {
	const currentGroupId = useCurrentGroupId();
	const { search, memberships, isTraversing, nodeTree } = useGroupSwitcher();

	const membershipGroupIds = memberships.map(
		(membership: models.membership.Membership) => membership.groupId
	);

	const hasDirectMembership = membershipGroupIds.includes(group.id);

	const hasNodeMembership =
		membershipGroupIds.includes(group.organizationId) ||
		membershipGroupIds.includes(group.parentGroupId);

	const getRelevantMembership = () => {
		if (hasNodeMembership && hasDirectMembership && isTraversing) {
			const rootGroupId = nodeTree?.[0];

			if (rootGroupId) {
				const rootMembership = memberships.find(
					(membership: models.membership.Membership) =>
						membership.groupId === rootGroupId
				);

				if (rootMembership) {
					return rootMembership;
				}
			}
		}

		if (hasDirectMembership) {
			// Current account is a member of this group

			return memberships.find(
				(membership: models.membership.Membership) =>
					membership.groupId === group.id
			);
		} else if (hasNodeMembership) {
			// Current account a member of the group parent, or organization

			const parentMembership = memberships.find(
				(membership: models.membership.Membership) =>
					membership.groupId === group.parentGroupId
			);

			const organizationMembership = memberships.find(
				(membership: models.membership.Membership) =>
					membership.groupId === group.organizationId
			);

			if (parentMembership) {
				return parentMembership;
			} else if (organizationMembership) {
				return organizationMembership;
			} else {
				return null;
			}
		}

		return null;
	};

	const membership = getRelevantMembership();
	const hasPendingMembership = membership?.status === MembershipStatus.Pending;

	const isActive = currentGroupId === group.id;

	const canTraverse =
		models.hasLink(group, 'show:children') && search.length === 0;

	const handleTraverse = (event: PointerEvent<HTMLButtonElement>) => {
		event.preventDefault();
		event.stopPropagation();

		if (onTraverse) {
			onTraverse(group.id);
		}
	};

	const handleGroupActivate = () => {
		trackEvent(Event.GroupSwitcherGroupChange);

		// If the user has a membership in node tree, but is traversing, use node membership not the direct memberhsip
		if (hasNodeMembership && hasDirectMembership && isTraversing) {
			pushState(routes.Group.Change(), {
				groupId: group.id.toString(),
				membershipData: json.stringify({
					userId: membership.userId,
					role: membership.role,
					capabilities: membership.capabilities,
				}),
			});
			return;
		}

		// Parent acting on child's behalf
		if (userId) {
			pushState(routes.Group.Change(), {
				groupId: group.id.toString(),
				userId: userId.toString(),
			});
			return;
		}

		if (hasDirectMembership) {
			pushState(routes.Group.Change(), {
				groupId: group.id.toString(),
			});
			return;
		}

		// Account is a member of a node in group tree
		if (hasNodeMembership) {
			pushState(routes.Group.Change(), {
				groupId: group.id.toString(),
				membershipData: json.stringify({
					userId: membership.userId,
					role: membership.role,
					capabilities: membership.capabilities,
				}),
			});
			return;
		}

		const rootGroupId = nodeTree?.[0];

		if (rootGroupId) {
			const rootMembership = memberships.find(
				(membership: models.membership.Membership) =>
					membership.groupId === rootGroupId
			);

			if (rootMembership) {
				pushState(routes.Group.Change(), {
					groupId: group.id.toString(),
					membershipData: json.stringify({
						userId: rootMembership.userId,
						role: rootMembership.role,
						capabilities: rootMembership.capabilities,
					}),
				});
			}
		}
	};

	return (
		<Context.Item
			tight
			className={css.itemWrapper}
			onClick={handleGroupActivate}>
			<Row columns="40px 1fr auto" align="center">
				<div className={cssClasses(css.badgeWrapper, isActive && css.active)}>
					<Badge badgeUrl={group.profileImageUrl} className={css.badge} />
				</div>
				<Column spacing={spacing._2}>
					{supHeading && (
						<span className={css.itemSubHeading}>{supHeading}</span>
					)}
					<strong className={hasPendingMembership ? css.isPending : ''}>
						{group.name}
					</strong>
					{subHeading && (
						<Row autoColumns="max-content" justifyContent="space-between">
							<span className={css.itemSubHeading}>{subHeading}</span>
						</Row>
					)}
				</Column>
				<Row spacing={spacing._2} autoColumns="max-content" align="center">
					{hasPendingMembership && <Label>{t('Pending')}</Label>}
					{canTraverse && (
						<Button className={css.traverseButton} onClick={handleTraverse}>
							<MaterialSymbol variant="arrow_forward_ios" />
						</Button>
					)}
				</Row>
			</Row>
		</Context.Item>
	);
}
