import { t } from '@transifex/native';
import { JSX, useState } from 'react';

import * as styles from 'pkg/config/styles';

import { setFieldAsRequired } from 'pkg/actions/user_fields';

import * as models from 'pkg/api/models';
import { Flag, useFlag } from 'pkg/flags';

import Field from 'routes/group/settings/user-fields/list/Field';

import Heading from 'components/Heading';
import * as Draggable from 'components/Draggable';

import Column from 'components/layout/column';
import InfoBox from 'components/form/info-box';

import Button from 'design/button';

import * as css from './styles.css';

interface OtherFieldsListProps {
	userFields: models.userFields.UserField[];
	isEditingSort: boolean;
	onReplace: (item: models.userFields.UserField) => void;
	onRemove: (itemId: number) => void;
	onEdit: (item: models.userFields.UserField) => void;
	onDrag: (userFields: models.userFields.UserField[]) => void;
	onCreate: () => void;
}

interface ReservedListProps {
	group: models.group.Group;
	userFields: models.userFields.UserField[];
	showList: boolean;
	onToggle: () => void;
	onReplace: (item: models.userFields.UserField) => void;
	onRefresh: () => void;
}

const FieldLists = ({
	children,
}: {
	children: JSX.Element | JSX.Element[];
}) => {
	return (
		<Column spacing={styles.spacing._8}>
			<InfoBox>
				{t(
					'User properties is the data that can be collected as a standard on all users and contacts created in your organization. You may edit the visibility, if they are required, or create your own ones.'
				)}
			</InfoBox>
			<Column spacing={styles.spacing._7}>{children}</Column>
		</Column>
	);
};

export const ReservedFields = ({
	group,
	userFields,
	showList,
	onToggle,
	onReplace,
	onRefresh,
}: ReservedListProps) => {
	const [isSavingLOKFields, setIsSavingLOKFields] = useState(false);
	const reservedFields: models.userFields.UserField[] = models.userFields
		.getReservedFieldsToDisplay(group)
		.map((field) => {
			return {
				key: `user.${field}`,
				label: models.userFields.getReservedTranslation(
					field as models.userFields.ReservedUserField
				),
				type: models.userFields.UserFieldType.Reserved,
				groupId: group.id,
				required: false,
			};
		});

	let fields = reservedFields.map((reservedField) => {
		const existingUserField = userFields.find(
			(field) => field.key === reservedField.key
		);

		if (existingUserField) {
			existingUserField.label = models.userFields.getReservedTranslation(
				existingUserField.key
					.split('.')
					.pop() as models.userFields.ReservedUserField
			);
			return existingUserField;
		} else {
			return reservedField;
		}
	});

	const requiredLOKFields = [
		'user.firstName',
		'user.lastName',
		'user.birthDate',
		'user.country',
		'user.address',
		'user.city',
		'user.postalCode',
		'user.nationality',
		'user.lokSwedishPersonalId',
		'user.region',
	];

	let missingRequiredLOKFields: string[] = [];

	const setAllRequiredLokFields = async () => {
		setIsSavingLOKFields(true);
		await Promise.all(
			fields
				.filter((f) => missingRequiredLOKFields.includes(f.key))
				.map((f) => setFieldAsRequired(f))
		);

		onRefresh();
	};

	if (group?.country?.code === 'SE') {
		fields = fields.map((field) => {
			if (requiredLOKFields.includes(field.key)) {
				field.description = t('Required for LOK');
			}
			return field;
		});

		missingRequiredLOKFields = requiredLOKFields.filter(
			(f) => !fields.find((field) => field.key === f && field.required)
		);
	}

	return (
		<Column>
			{missingRequiredLOKFields.length > 0 && (
				<InfoBox
					color="yellow"
					title={t('Missing required fields for LOK grants')}
					text={t(
						`The following fields aren't required for your users, without them events sent to IdrottOnline might not be eligble for LOK.`
					)}>
					<ul>
						{fields
							.filter((f) => missingRequiredLOKFields.includes(f.key))
							.map((f) => {
								return <li key={f.key}>{f.label}</li>;
							})}
					</ul>

					<Button
						disabled={isSavingLOKFields}
						block
						onClick={setAllRequiredLokFields}>
						{t('Set all missing fields as required now')}
					</Button>
				</InfoBox>
			)}
			<Heading>
				{t('Default user fields')}
				<Button
					onClick={onToggle}
					icon={showList ? 'expand_less' : 'expand_more'}
					iconSize={2}
					iconPosition="right"
					transparent
					small>
					{showList ? t('Collapse') : t('Expand')}
				</Button>
			</Heading>

			{showList && (
				<div className={css.fieldsWrapper}>
					{fields.map((field) => (
						<Field
							key={field.key}
							item={field}
							onReplace={onReplace}
							onRefresh={onRefresh}
						/>
					))}
				</div>
			)}
		</Column>
	);
};

// TODO: see if there is another, better way with less prop-sending.
export const OtherFields = ({
	userFields,
	isEditingSort,
	onReplace,
	onRemove,
	onEdit,
	onDrag,
	onCreate,
}: OtherFieldsListProps) => {
	const userFieldCateriesEnabled = useFlag<boolean>(Flag.UserFieldCategories);
	const fields = userFields.filter(
		(field) => field.type !== models.userFields.UserFieldType.Reserved
	);

	const allFieldsHaveCategory =
		fields.length > 0 && fields.every((field) => field.userFieldCategoryId);

	return (
		<Column>
			<Heading>
				{userFieldCateriesEnabled ? t('Other fields') : t('Custom fields')}
			</Heading>
			{fields.length > 0 && !allFieldsHaveCategory ? (
				isEditingSort ? (
					<div className={css.fieldsWrapper}>
						<Draggable.List
							items={userFields}
							onReorder={onDrag}
							spacing={styles.spacing._0}>
							{fields.map(
								(field) =>
									!field.userFieldCategoryId && (
										<Draggable.Item key={field.key} item={field}>
											<Field
												key={field.id}
												item={field}
												custom
												isReordering
												onEdit={onEdit}
												onReplace={onReplace}
												onRemove={onRemove}
											/>
										</Draggable.Item>
									)
							)}
						</Draggable.List>
					</div>
				) : (
					<div className={css.fieldsWrapper}>
						{fields.map(
							(field) =>
								!field.userFieldCategoryId && (
									<Field
										key={field.id}
										item={field}
										custom
										onEdit={onEdit}
										onReplace={onReplace}
										onRemove={onRemove}
									/>
								)
						)}
					</div>
				)
			) : (
				<div>
					<Button icon="add" inline noPadding onClick={onCreate}>
						{t('New field')}
					</Button>
				</div>
			)}
		</Column>
	);
};

export default FieldLists;
