import { JSX, useEffect } from 'react';
import mixpanel, { Mixpanel } from 'mixpanel-browser';

import { useCurrentRoute } from 'pkg/router/hooks';
import * as models from 'pkg/api/models';
import { AccountFlags, hasFlag } from 'pkg/api/models/account';
import { isCypress, isDev, isDevServer } from 'pkg/flags';
import { isAndroid, isIOS } from 'pkg/platform';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import {
	useCurrentAccount,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';

export enum Event {
	AdView = 'adview',
	PageView = '$mp_web_page_view',
	Create = 'create',
	Export = 'export',
	UserMerge = 'user_merge',
	GroupSwitcherClubLobby = 'group_switcher_club_lobby',
	GroupSwitcherGroupChange = 'group_switcher_group_change',
	MissingInformationView = 'missing_information_view',
	SupportChatActivated = 'support_chat_activated',
	DashboardQuickActionClick = 'dashboard_quick_action_click',
	OrderMarkAs = 'order_mark_as',
	SubscriptionCancel = 'subscription_cancel',
	ConnectSessionToEvent = 'connect_session_to_event',
	TimedFormSubmissionSubmit = 'timed_form_submission_submit',
}

const token = isDev()
	? 'f9ccd4faa2baa978628853f0bc6ad0c8'
	: '85da8ff7f9c1ed9c342386eb553f8f4b';

let customEventQueue: {
	event: Event;
	props?: { [key: string]: unknown };
}[] = [];

let client: Mixpanel;

const shouldNotTrack = () => !client || isCypress();

export const trackEvent = (
	event: Event,
	props?: { [key: string]: unknown }
) => {
	if (isCypress()) {
		return;
	}

	if (!client) {
		customEventQueue.push({ event, props });
		return;
	}

	client.track(event, props);
};

export const startTimedTrackEvent = (event: Event) => {
	if (shouldNotTrack()) {
		return;
	}

	client.time_event(event);
};

export const stopTimedTrackEvent = (
	event: Event,
	props?: { [key: string]: unknown }
) => {
	if (shouldNotTrack()) {
		return;
	}

	client.track(event, props);
};

export const resetClient = () => {
	if (!client) {
		return;
	}

	client.reset();
	client.unregister(token);
};

function Analytics(): JSX.Element {
	const account = useCurrentAccount();
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();

	const setPerson = () => {
		const userInfo: {
			role?: string;
			organization?: number;
			group?: number;
		} = {};

		if (membership?.groupId) {
			userInfo.role = models.membership.roleString(membership);
			userInfo.group = membership.groupId;
		}

		if (org?.id) {
			userInfo.organization = org.id;
		}

		client.identify(account.trackingHash);

		client.people.set_once(userInfo);
	};

	useComponentDidMount(async () => {
		if (hasFlag(account, AccountFlags.OptOutAnalytics)) {
			return;
		}

		client = mixpanel.init(
			token,
			{
				debug: isDevServer(),
				ignore_dnt: isDevServer(),
				persistence: 'cookie',
			},
			'webtracker'
		);

		let platform = 'web';
		if (isIOS()) {
			platform = 'ios';
		} else if (isAndroid()) {
			platform = 'android';
		}

		const iosVersionReg = navigator.userAgent.match(
			/(iPhone|iPad);.+?Version\/([\d\.]+)/
		);

		let osVersion: string = undefined;

		if (navigator.userAgentData) {
			const ua = await navigator.userAgentData.getHighEntropyValues([
				'platformVersion',
			]);

			if (ua.platformVersion) {
				osVersion = ua.platformVersion;
			}
		} else if (iosVersionReg?.length > 0) {
			osVersion = iosVersionReg[2];
		}

		// disable current_url and current_url_search to avoid sending PII
		client.register({
			$current_url: '',
			current_url_search: '',
			environment: isDev() ? 'development' : 'production',
			app_version: window.TS.version,
			os_version: osVersion,
			platform,
		});

		// track any events that was tracked before the client was initialized
		customEventQueue.forEach((ev) => {
			client.track(ev.event, ev.props);
		});
		customEventQueue = [];

		setPerson();
	});

	useEffect(() => {
		if (hasFlag(account, AccountFlags.OptOutAnalytics) && client) {
			client.reset();
			client.unregister(token);
		}
	}, [...account.flags, client]);

	return <PageView />;
}

function PageView(): JSX.Element {
	const route = useCurrentRoute();
	const whitelistedParams = [':currentTab'];

	useEffect(() => {
		const patternParts = `/${route.pattern}`.split('/');
		const path = route.path
			.split('/')
			.map((p: string, i: number) => {
				// part isn't a param, safe to set
				if (!patternParts[i].startsWith(':')) {
					return p;
				}

				if (whitelistedParams.includes(patternParts[i])) {
					return p;
				}

				return '_';
			})

			.join('/');

		trackEvent(Event.PageView, {
			$current_url: '',
			current_url_search: '',
			current_url_path: path,
			current_domain: document.location.hostname,
			current_url_protocol: document.location.protocol,
		});
	}, [route.path]);

	return null;
}

export default Analytics;
