/**
 * Moves an array item from one postion to another.
 *
 * @param array
 * @param fromIndex Index of item to move
 * @param toIndex Index of new position for item,
 */
export function moveItem(
	array: any[],
	fromIndex: number,
	toIndex: number
): any[] {
	array = [...array];

	const startIndex: number =
		fromIndex < 0 ? array.length + fromIndex : fromIndex;

	if (startIndex >= 0 && startIndex < array.length) {
		const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

		const [item] = array.splice(fromIndex, 1);

		array.splice(endIndex, 0, item);
	}

	return array;
}

export function equals(a: any[], b: any[]): boolean {
	if (!Array.isArray(a) || !Array.isArray(b)) return false;

	if (a.length !== b.length) return false;

	a = a.sort();
	b = b.sort();

	return a.every((n: any, i: number) => n === b[i]);
}

export function intersects<T = unknown>(a: T[], b: T[]): T[] {
	return a.filter((value: T) => b.includes(value));
}

export function unique<T = unknown>(a: T[]): T[] {
	return [...new Set<T>(a)];
}

export function remove<T = unknown>(a: T[], remove: T[]): T[] {
	return a.filter((item: T) => !remove.includes(item));
}

/**
 *	Behaves like Array.prototype.splice, but does not mutate the original array.
 */
export function eject<T = unknown>(
	a: T[],
	index: number,
	count: number = 1
): T[] {
	const next = [...a];
	next.slice(index, count);

	return next;
}

export function chunk<T = unknown>(a: T[], size: number): T[][] {
	const chunks: T[][] = [];

	for (let n = 0, l = a.length; n < l; n += size) {
		chunks.push(a.slice(n, n + size));
	}

	return chunks;
}

/**
 * Splits an array at index, returning two parts.
 *
 * @param a
 * @param index
 *
 * @return array
 */
export function splitAt<T = unknown>(a: T[], index: number): [T[], T[]] {
	if (index < 0) {
		index = a.length + index;
	}

	return [a.slice(0, index), a.slice(index)];
}

/**
 * allocates every nth entry of an array for input element.
 *
 * @param item T
 * @param nth number Injects item every nth entry in array
 */
export function allocate<A = unknown, T = unknown>(
	items: A[],
	item: (index: number) => T,
	nth: number
): (T | A)[] {
	if (nth < 1) {
		nth = 1;
	} else if (nth > items.length) {
		nth = items.length;
	}

	const copy: (T | A)[] = items.slice(0);

	let n = nth;

	while (n - 1 <= items.length) {
		copy.splice(n, 0, item(n));
		n += nth + 1;
	}

	return copy;
}

/**
 *	filters out duplicate objects based on key
 *
 *	@param arr array to check
 *	@param key key of object
 *
 *	@returns filtered array of objects
 */
export function filterDuplicatesByKey<T>(arr: T[], key: keyof T) {
	return [...new Map(arr.map((item) => [item[key], item])).values()];
}

/**
 *	Filters out a single element from an array of objects
 *
 *	@param items
 *	@param key
 *	@param value
 *
 *	@returns
 */
export function filterByKey<T, KeyValue = number>(
	items: T[],
	key: keyof T,
	value: KeyValue
): T[] {
	return items.filter((item: T) => item[key] !== value);
}

type GroupByKeyGenerator<T, K> = (item: T) => K;

/**
 * Groups an array of objects by object key and or value.
 *
 * @param list
 * @param getter
 * @returns Map<K, T[]>
 */
export function groupBy<T, K = string>(
	list: T[],
	getter: GroupByKeyGenerator<T, K>
): Map<K, T[]> {
	const map = new Map<K, T[]>();

	list.forEach((item: T) => {
		const key: K = getter(item);
		const collection = map.get(key);

		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});

	return map;
}

/**
 *	Joins an array with a penultimate separator. Joiners are not added for zero and single item arrays.
 *
 * 	@example
 * 		penultimateJoin(names, ', ', 'and', ' ');
 * 		// Will return "Foo, Bar and Baz"
 *
 *	@param a unknown[]
 *	@param separator
 *	@param lastSeparator
 *	@param joiner ''
 *
 *	@returns string
 */
export function penultimateJoin<T = unknown>(
	a: T[],
	separator: string,
	lastSeparator: string,
	joiner: string = ''
): string {
	if (a.length === 0) return '';

	if (a.length === 1) {
		return a.join(joiner).trim();
	}

	return [a.slice(0, -1).join(separator), lastSeparator, a.slice(-1)]
		.join(joiner)
		.trim();
}
