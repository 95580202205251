import { JSX } from 'react';
import { t } from '@transifex/native';

import { useCurrentRoute } from 'pkg/router/hooks';
import { useGroupedTaggedUsers } from 'pkg/hooks/selectors';

import ClipList from 'routes/video/shared/ClipList';
import SelectableList from 'routes/video/shared/SelectableList';

import Modal, { Step } from 'components/step-modal';

import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useFilterState } from 'components/video-analytics/FilterState';

export default function SelectableTaggedUsersModal(): JSX.Element {
	const { videoId } = useCurrentRoute();
	const filterState = useFilterState();

	const groupedTaggedUsers = useGroupedTaggedUsers(videoId);

	const { controller, setShowingClipList } = usePlayerState();

	const handleTaggedUsersChange = (userIds: number[]) =>
		filterState.setTaggedUsers(userIds);

	const closeListModal = () => {
		controller.play();

		setShowingClipList(false);
	};

	const listItemClick = async () => setShowingClipList(false);

	return (
		<Modal onClose={closeListModal}>
			<Step slug="home" hideNext title={t('Clip list')}>
				<ClipList context="edit-mode" onItemClick={listItemClick} />
			</Step>
			<Step
				hideNext
				skipBody
				slug="tags"
				canClose={false}
				title={t('Clip list')}>
				<SelectableList
					selectable
					multipleChoice
					showControls
					showUngrouped={false}
					items={groupedTaggedUsers.users}
					groupBy={groupedTaggedUsers.groupBy}
					selectedIds={filterState.taggedUserIds}
					onChange={handleTaggedUsersChange}
				/>
			</Step>
		</Modal>
	);
}
