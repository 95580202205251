import { JSX } from 'react';
import { t } from '@transifex/native';

import User from 'pkg/models/user';

import DataRow from 'containers/user/profile/info-contact/DataRow';

import * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import { DataRowProps } from 'components/user-address/DataList';

interface OptionalInformationProps {
	user: User;
}

export default function OptionalInformation({
	user,
}: OptionalInformationProps): JSX.Element {
	const labels = {
		relativeOneName: t(`Relative One Name`),
		relativeOneNumber: t(`Relative One Phone`),
		relativeOneEmail: t(`Relative One Email`),
		relativeTwoName: t(`Relative Two Name`),
		relativeTwoNumber: t(`Relative Two Phone`),
		relativeTwoEmail: t(`Relative Two Email`),
	};

	const rows: DataRowProps[] = [];

	if (user.getIn(['meta', 'relative-one-name'])) {
		rows.push({
			label: labels.relativeOneName,
			value: user.getIn(['meta', 'relative-one-name']).toString(),
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.relativeOneName,
		});
	}

	if (user.getIn(['meta', 'relative-one-number'])) {
		const number = user.getIn(['meta', 'relative-one-number']).toString();

		rows.push({
			label: labels.relativeOneNumber,
			value: <a href={`tel:${number}`}>{number}</a>,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.relativeOneNumber,
		});
	}

	if (user.getIn(['meta', 'relative-one-email'])) {
		const email = user.getIn(['meta', 'relative-one-email']).toString();

		rows.push({
			label: labels.relativeOneEmail,
			value: <a href={`mailto:${email}`}>{email}</a>,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.relativeOneEmail,
		});
	}

	if (user.getIn(['meta', 'relative-two-name'])) {
		rows.push({
			label: labels.relativeTwoName,
			value: user.getIn(['meta', 'relative-two-name']).toString(),
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.relativeTwoName,
		});
	}

	if (user.getIn(['meta', 'relative-two-number'])) {
		const number = user.getIn(['meta', 'relative-two-number']).toString();

		rows.push({
			label: labels.relativeTwoNumber,
			value: <a href={`tel:${number}`}>{number}</a>,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.relativeTwoNumber,
		});
	}

	if (user.getIn(['meta', 'relative-two-email'])) {
		const email = user.getIn(['meta', 'relative-two-email']).toString();

		rows.push({
			label: labels.relativeTwoEmail,
			value: <a href={`mailto:${email}`}>{email}</a>,
		});
	} else if (!user.isScrubbed()) {
		rows.push({
			label: labels.relativeTwoEmail,
		});
	}

	if (rows.length === 0 || rows.every((row) => !row.value)) {
		return null;
	}

	return (
		<Column>
			<SectionTitle icon="group">
				{t(`Relative contact information`)}
			</SectionTitle>
			<Card.Base $noBorder>
				<Card.Body>
					{rows.map((row, i) => (
						<DataRow key={i} label={row.label} value={row.value} />
					))}
				</Card.Body>
			</Card.Base>
		</Column>
	);
}
