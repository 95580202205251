import {
	JSX,
	ChangeEvent,
	Fragment,
	ReactNode,
	useContext,
	useState,
} from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import { tags } from 'pkg/config/training-filters';
import { getAlias } from 'pkg/config/tags';

import { useCurrentSport } from 'pkg/identity';
import * as models from 'pkg/api/models';

import { useTrainingLibraryFilters } from 'routes/training/library/hooks';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import FilterLabel from 'components/filters/FilterLabel';
import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';
import ContentFilter from 'components/library/ContentFilter';

import * as ContextMenu from 'design/context_menu';
import Button, { FilterButton } from 'design/button';

const Choice = styled.div`
	padding: var(--spacing-2) 0;
`;

interface FilterMenuItemProps {
	label: string;
	open?: boolean;
	isActive?: boolean;
	children: ReactNode | ReactNode[];
}

function FilterMenuItem({
	label,
	open = false,
	isActive,
	children,
}: FilterMenuItemProps): JSX.Element {
	const [isOpen, setOpen] = useState<boolean>(open);

	const toggleOpenState = () => setOpen(!isOpen);

	const onClearFilter = (): void => null;

	return (
		<Fragment>
			<ContextMenu.Item
				closeOnClick={false}
				onClick={toggleOpenState}
				justifyContent="normal">
				<FilterLabel
					label={label}
					isOpen={isOpen}
					isActive={isActive}
					isSelected={false}
					onClearFilter={onClearFilter}
				/>
			</ContextMenu.Item>
			{isOpen && children}
		</Fragment>
	);
}
function SoccerTagFilterOptions(): JSX.Element {
	const sport = useCurrentSport();
	const filters = useTrainingLibraryFilters();

	const isChecked = (tag: string) => filters.getTags().includes(tag);

	const handleChange = (tag: string) => {
		if (isChecked(tag)) {
			filters.removeTag(tag);
		} else {
			filters.addTag(tag);
		}
	};

	if (!models.sport.isFootball(sport)) return null;

	return (
		<FilterMenuItem label={t('Tags')}>
			<ContextMenu.Pane>
				{tags.map((tag: string) => (
					<Choice key={tag}>
						<Input.Control
							type="checkbox"
							value={tag}
							label={getAlias(tag)}
							position="after"
							checked={isChecked(tag)}
							onChange={handleChange}
						/>
					</Choice>
				))}
			</ContextMenu.Pane>
		</FilterMenuItem>
	);
}

function FilterMenuHeader(): JSX.Element {
	const filters = useTrainingLibraryFilters();
	const ctx = useContext(ContextMenu.MenuContext);

	const resetFilters = () => {
		ctx.close();
		filters.reset();
	};

	return (
		<Fragment>
			<ContextMenu.Pane>
				<Row columns="1fr auto" align="center" spacing={styles.spacing._3}>
					<strong>{t('Filters')}</strong>
					<Button small onClick={resetFilters}>
						{t('Clear')}
					</Button>
				</Row>
			</ContextMenu.Pane>
			<ContextMenu.Divider tightTop />
		</Fragment>
	);
}

export default function FilterMenu(): JSX.Element {
	const filters = useTrainingLibraryFilters();

	const handleMinTimeChange = (event: ChangeEvent<HTMLInputElement>) =>
		filters.setMinTime(Number.parseInt(event.target.value, 10));

	const handleMaxTimeChange = (event: ChangeEvent<HTMLInputElement>) =>
		filters.setMaxTime(Number.parseInt(event.target.value, 10));

	const handleMinAgeChange = (event: ChangeEvent<HTMLInputElement>) =>
		filters.setMinAge(Number.parseInt(event.target.value, 10));

	const handleMaxAgeChange = (event: ChangeEvent<HTMLInputElement>) =>
		filters.setMaxAge(Number.parseInt(event.target.value, 10));

	const handleMinParticipantsChange = (event: ChangeEvent<HTMLInputElement>) =>
		filters.setMinParticipants(Number.parseInt(event.target.value, 10));

	const handleMaxParticipantsChange = (event: ChangeEvent<HTMLInputElement>) =>
		filters.setMaxParticipants(Number.parseInt(event.target.value, 10));

	return (
		<ContextMenu.Menu
			toggleWith={
				<Fragment>
					<LargeScreen>
						<Button icon="tune">{t('Filter')}</Button>
					</LargeScreen>
					<SmallScreen>
						<FilterButton />
					</SmallScreen>
				</Fragment>
			}>
			<FilterMenuHeader />
			<SoccerTagFilterOptions />
			<FilterMenuItem label={t('Duration')}>
				<ContextMenu.Pane>
					<Row>
						<Input.Field
							type="number"
							min={0}
							step={10}
							defaultValue={filters.minTime}
							onChange={handleMinTimeChange}
							changeDelay={500}>
							<Input.Prefix>{t('From')}</Input.Prefix>
						</Input.Field>
						<Input.Field
							type="number"
							min={0}
							max={100}
							step={10}
							defaultValue={filters.maxTime}
							onChange={handleMaxTimeChange}
							changeDelay={500}>
							<Input.Prefix>{t('To')}</Input.Prefix>
						</Input.Field>
					</Row>
				</ContextMenu.Pane>
			</FilterMenuItem>
			<FilterMenuItem label={t('Age')}>
				<ContextMenu.Pane>
					<Row>
						<Input.Field
							type="number"
							min={0}
							defaultValue={filters.minAge}
							onChange={handleMinAgeChange}
							changeDelay={500}>
							<Input.Prefix>{t('From')}</Input.Prefix>
						</Input.Field>
						<Input.Field
							type="number"
							min={0}
							max={100}
							defaultValue={filters.maxAge}
							onChange={handleMaxAgeChange}
							changeDelay={500}>
							<Input.Prefix>{t('To')}</Input.Prefix>
						</Input.Field>
					</Row>
				</ContextMenu.Pane>
			</FilterMenuItem>
			<FilterMenuItem label={t('Players')}>
				<ContextMenu.Pane>
					<Row>
						<Input.Field
							type="number"
							min={0}
							defaultValue={filters.minParticipants}
							onChange={handleMinParticipantsChange}
							changeDelay={500}>
							<Input.Prefix>{t('Min')}</Input.Prefix>
						</Input.Field>
						<Input.Field
							type="number"
							min={0}
							max={100}
							defaultValue={filters.maxParticipants}
							onChange={handleMaxParticipantsChange}
							changeDelay={500}>
							<Input.Prefix>{t('Max')}</Input.Prefix>
						</Input.Field>
					</Row>
				</ContextMenu.Pane>
			</FilterMenuItem>
			<FilterMenuItem label={t('Content type')}>
				<ContentFilter
					standalone
					actions={[
						{
							label: t('Exercises'),
							icon: <ContextMenu.ItemIcon name="tactic" />,
							type: 'exercises',
						},
						{
							label: t('Sessions'),
							icon: <ContextMenu.ItemIcon name="schedule" />,
							type: 'sessions',
						},
						{
							label: t('Collections'),
							icon: <ContextMenu.ItemIcon name="folder" />,
							type: 'collections',
						},
					]}
				/>
			</FilterMenuItem>
		</ContextMenu.Menu>
	);
}
