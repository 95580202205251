import React, {
	Fragment,
	ReactNode,
	Children,
	createContext,
	useRef,
	useEffect,
	useState,
	JSX,
	MutableRefObject,
} from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import Link from 'pkg/router/Link';
import uuid from 'pkg/uuid';
import { addCssVar } from 'pkg/cssvars';

import Icon from 'components/icon';
import Label from 'components/label';
import { useModalContext } from 'components/modal';

import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import { useFullScreenTableContext } from 'components/layout/page-templates';

import ToggleExpand from 'design/table/table-header/ToggleExpand';

import Cell from './table-data/Cell';
import ExpandableRow from './table-row/ExpandableRow';
import FootCell, { FootCellProps } from './table-footer/FootCell';
import HeadCell from './table-header/HeadCell';
import HeadCellSort, { SortIcons } from './table-header/HeadCellSort';
import LinkCell from './table-data/LinkCell';
import ActionCell from './table-data/ActionCell';
import Row from './table-row/Row';
import * as footerStyles from './table-footer/styles.css';

interface ContainerProps {
	fullPageTable?: boolean;
}

const Container = styled.div<ContainerProps>`
	position: relative;
	overflow: auto;
	flex: 1 1 100%;
	background: var(--palette-white);
	display: flex;
	flex-flow: column;

	${({ fullPageTable }) =>
		fullPageTable &&
		css`
			border: 1px solid var(--palette-gray-300);
			border-radius: 12px;
		`};
`;

const CenteredContentWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const HelpLink = styled(Link)`
	font-size: var(--font-size-sm);
`;

const EmptyState = styled(Column)`
	text-align: center;
	padding: var(--spacing-5);

	p {
		color: var(--palette-gray-500);
		font-weight: var(--font-weight-normal);
		font-size: var(--font-size-sm);
		line-height: var(--font-line-height-sm);
		width: 300px;
	}

	h4 {
		color: var(--palette-gray-800);
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
	}
`;

interface WrapperProps {
	isLoading: boolean;
	stickyHeader?: boolean;
	stickyFooter?: boolean;
}

const Wrapper = styled.table<WrapperProps>`
	display: grid;
	border-collapse: collapse;
	min-width: 100%;

	tr[data-interactable='true'] {
		cursor: pointer;
	}

	tr[data-disabled='true'] {
		cursor: not-allowed;
	}

	thead,
	tbody,
	tfoot,
	tr {
		display: contents;
	}

	thead tr,
	thead tr th {
		background-color: var(--palette-gray-100);
		box-shadow: inset 0px -1px 0px var(--palette-gray-300);
		color: var(--palette-gray-900);

		${({ theme }) =>
			theme.darkMode &&
			css`
				background-color: var(--palette-gray-900);
				box-shadow: inset 0px -1px 0px var(--palette-gray-700);
				color: var(--palette-gray-200);
			`}
	}

	tbody tr,
	tbody tr td {
		background-color: var(--palette-white);
		box-shadow: inset 0px -1px 0px var(--palette-gray-300);

		${({ theme }) =>
			theme.darkMode &&
			css`
				background-color: var(--palette-gray-800);
				box-shadow: inset 0px -1px 0px var(--palette-gray-700);
				color: var(--palette-gray-300);
			`}
	}

	tbody tr:last-child,
	tbody tr:last-child td {
		box-shadow: none;
	}

	tbody:not(:last-child) tr:last-child,
	tbody:not(:last-child) tr:last-child td {
		box-shadow: none;
	}

	tfoot tr,
	tfoot tr td {
		background-color: var(--palette-gray-100);
		box-shadow: inset 0px 1px 0px var(--palette-gray-300);
		border-bottom: 1px solid var(--palette-gray-300);
		color: var(--palette-gray-900);

		${({ theme }) =>
			theme.darkMode &&
			css`
				background-color: var(--palette-gray-900);
				box-shadow: inset 0px 1px 0px var(--palette-gray-700);
				border-bottom: 1px solid var(--palette-gray-700);
				color: var(--palette-gray-200);
			`}
	}

	tbody tr:hover,
	tbody tr:hover td {
		background-color: var(--palette-gray-200);

		${({ theme }) =>
			theme.darkMode &&
			css`
				background-color: var(--palette-gray-700);
			`}
	}

	thead tr th,
	tbody tr td,
	tfoot tr td {
		display: flex;
		padding: var(--spacing-3) var(--spacing-4);
		font-size: var(--font-size-sm);
		min-height: 41px;

		> a {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		@media ${styles.breakpoint.small} {
			padding: var(--spacing-3) var(--spacing-3);
			min-height: 50px;

			&:first-child {
				padding-left: var(--spacing-4);
			}

			&:last-child {
				padding-right: var(--spacing-4);
			}
		}
	}

	tbody td {
		color: var(--palette-gray-900);
	}

	thead th {
		border-right: 1px solid
			${addCssVar(
				(theme: DefaultTheme) => styles.palette.gray[theme.darkMode ? 700 : 300]
			)};

		&:last-of-type {
			border-right: none;
		}
	}

	${({ stickyHeader }) =>
		stickyHeader &&
		css`
			thead tr th {
				position: sticky;
				top: 0;
				z-index: 1;
			}
		`}

	${({ stickyFooter }) =>
		stickyFooter &&
		css`
			tfoot tr td {
				position: sticky;
				bottom: 0;
				z-index: 1;
			}
		`}
`;

interface TBodyProps {
	isLoading: boolean;
}

const TBody = styled.tbody<TBodyProps>`
	${(props) =>
		props.isLoading &&
		css`
			opacity: 0;
		`}
`;

export interface ColumnProps {
	width?: string;
	hide?: boolean;
	align?: any;
	onClick?: (item?: unknown) => void;
}

// ExtraColumn is used to render additional content on each row.
// The component passed will be cloned to pass the resource model.
//
// @todo(jbfm): only used in scheduling, probably shouldn't live here.
export interface ExtraColumn extends HeaderColumn {
	component: JSX.Element;
}

export interface FooterColumnProps extends FootCellProps {
	content?: JSX.Element | string;
	/** Choose which cells to summarize (matches `slug` from cell props) */
	sum?: string;
	/** Pill color (renders a Label component with that color) */
	pill?: string;
	/** Choose which column to calculate percentage off of,
	 * `percentOf` total & `sum` total are used for the percentage calculation. */
	percentOf?: string;
}

export interface EmptyStateProps {
	title: JSX.Element | string;
	content?: JSX.Element | string;
	image?: JSX.Element | string;
	button?: JSX.Element;
	helpUrl?: string;
	helpUrlText?: string;
	className?: string;
}

export interface HeaderColumn extends ColumnProps {
	// title or JSX content of the head cell
	content?: JSX.Element | string;

	// set to the key of the sortable column if this column should be sortable.
	sortKey?: string;
}

function EmptyContentState({
	title,
	content,
	image,
	button,
	helpUrl,
	helpUrlText,
	className,
}: EmptyStateProps) {
	return (
		<EmptyState
			spacing={styles.spacing._5}
			blockSpacing={styles.spacing._5}
			justify="center"
			className={className}>
			<div>{image}</div>
			<Column spacing={styles.spacing._3}>
				<h4>{title}</h4>
				{content && <p>{content}</p>}
			</Column>
			{button}
			{helpUrl && (
				<HelpLink href={helpUrl} target="_blank">
					{helpUrlText || t('Learn more')}
				</HelpLink>
			)}
		</EmptyState>
	);
}

function EmptyFilterState(): JSX.Element {
	return (
		<EmptyState
			spacing={styles.spacing._5}
			blockSpacing={styles.spacing._5}
			justify="center">
			<div>
				<Icon name="search" fill={styles.palette.gray[500]} size={2.5} />
			</div>
			<Column spacing={styles.spacing._3}>
				<h4>{t(`Found Nothing`)}</h4>
				<p>{t(`The search did not return any results`)}</p>
			</Column>
		</EmptyState>
	);
}

interface FooterCellProps {
	column: FooterColumnProps;
	columnRefs: MutableRefObject<HTMLDivElement[]>;
	index: number;
	getColumnSum: (sum: string) => number;
}

function FooterCell({
	column,
	columnRefs,
	index,
	getColumnSum,
}: FooterCellProps) {
	let total = 0;
	let percentageString = null;

	if (column.sum) {
		total = getColumnSum(column.sum);

		if (column.percentOf) {
			const totalNum = getColumnSum(column.percentOf);

			if (totalNum > 0) {
				const percentage = Math.round((total / totalNum) * 100);
				percentageString = ` (${percentage}%)`;
			}
		}
	}

	const content = column.sum ? total : column.content;

	return (
		<FootCell
			key={uuid()}
			align={column.align}
			justify={column.justify}
			style={{ minWidth: columnRefs.current[index]?.offsetWidth }}>
			{column.pill ? (
				<Label color={column.pill}>
					{content}
					{percentageString}
				</Label>
			) : (
				<Fragment>
					{content}
					{percentageString}
				</Fragment>
			)}
		</FootCell>
	);
}

export const TableContext = createContext({
	hideIndexes: [],
});

interface TableProps {
	columns: HeaderColumn[];
	footerColumns?: FooterColumnProps[];
	isLoading?: boolean;
	stickyHeader?: boolean;
	stickyFooter?: boolean;
	children?: ReactNode;
	testid?: string;
	// heavily encouraged to use an empty state but
	// there are a few exceptions
	emptyState?: EmptyStateProps;
	filtersApplied?: boolean;
	// set to a key defined under `sortKey` in `columns`.
	// set to number and symbol to allow "keyof T" types
	sortBy?: string | number | symbol;
	sortOrder?: 'asc' | 'desc';
	onSort?: (
		sortKey: string | number | symbol,
		sortOrder: 'asc' | 'desc'
	) => void;
	className?: string;
}

function Table({
	columns = [],
	footerColumns = [],
	isLoading = false,
	stickyHeader = false,
	stickyFooter = false,
	children,
	testid,
	filtersApplied = false,
	emptyState,
	sortBy,
	sortOrder,
	onSort,
	className,
}: TableProps): JSX.Element {
	const tableBodyRef = useRef(null);
	const columnRefs = useRef<(HTMLDivElement | null)[]>([]);
	const { fullPageTable } = useFullScreenTableContext();
	const { inModal } = useModalContext();

	const [cellToSum, setCellsToSum] = useState<
		{ slug: string; value: string }[]
	>([]);

	const hideIndexes: number[] = columns
		.filter((col) => col.hasOwnProperty('hide') && col.hide)
		.map((col) => columns.indexOf(col));

	let gridTemplateColumns = '';

	columns.map((column) => {
		if (column.hide) {
			return;
		}

		if (column.width) {
			gridTemplateColumns += `${column.width} `;
			return;
		}

		gridTemplateColumns += `minmax(max-content, 1fr) `;
	});

	useEffect(() => {
		const tableCells = tableBodyRef?.current?.querySelectorAll(
			'[data-cell-slug][data-cell-value]'
		);

		if (tableCells) {
			const newCellsToSum = [];

			for (const cell of tableCells) {
				const slug = cell.getAttribute('data-cell-slug');
				const value = cell.getAttribute('data-cell-value');

				newCellsToSum.push({ slug, value });
			}

			setCellsToSum(newCellsToSum);
		}
	}, [columns]);

	const getColumnSum = (column: string) => {
		let total = 0;

		cellToSum.map((cell) => {
			if (cell.slug === column) {
				total += parseInt(cell.value, 10);
			}
		});

		return total;
	};

	const createOnSortHandler = (sortBy: string, sortOrder: 'asc' | 'desc') => {
		return () => {
			if (onSort) {
				onSort(sortBy, sortOrder);
			}
		};
	};

	return (
		<TableContext.Provider value={{ hideIndexes }}>
			<Container
				className={className}
				// We don't want to apply the compact styling in modals
				fullPageTable={fullPageTable && !inModal}>
				<Wrapper
					isLoading={isLoading}
					stickyHeader={stickyHeader}
					stickyFooter={stickyFooter}
					style={{ gridTemplateColumns }}
					data-testid={testid}>
					<thead>
						<Row>
							{columns.map((column: HeaderColumn, i: number) =>
								column.sortKey ? (
									<HeadCellSort
										key={column.sortKey}
										onClick={createOnSortHandler(
											column.sortKey,
											sortOrder === 'asc' ? 'desc' : 'asc'
										)}
										active={column.sortKey === sortBy.toString()}
										align={column.align}
										ref={(el) => (columnRefs.current[i] = el)}>
										{column.content}
										<SortIcons
											active={column.sortKey === sortBy}
											sortOrder={sortOrder}>
											<Icon name="arrow-up" />
											<Icon name="arrow-down" />
										</SortIcons>
									</HeadCellSort>
								) : (
									<HeadCell
										key={i}
										isEmpty={!column.width && !column.content}
										onClick={column.onClick}
										align={column.align}
										ref={(el) => (columnRefs.current[i] = el)}>
										{column.content}
									</HeadCell>
								)
							)}
						</Row>
					</thead>

					<TBody isLoading={isLoading} ref={tableBodyRef}>
						{Children.count(children) > 0 && children}
					</TBody>
				</Wrapper>

				{isLoading && (
					<CenteredContentWrapper>
						<Spinner />
					</CenteredContentWrapper>
				)}

				{!isLoading && Children.count(children) === 0 && !filtersApplied && (
					<CenteredContentWrapper>
						<EmptyContentState {...emptyState} />
					</CenteredContentWrapper>
				)}
				{!isLoading && Children.count(children) === 0 && filtersApplied && (
					<CenteredContentWrapper>
						<EmptyFilterState />
					</CenteredContentWrapper>
				)}
			</Container>

			{footerColumns.length > 0 && (
				<div className={footerStyles.wrapper}>
					<table style={{ gridTemplateColumns }} className={footerStyles.table}>
						{footerColumns.map((column: FooterColumnProps, i: number) => {
							return (
								<FooterCell
									column={column}
									columnRefs={columnRefs}
									index={i}
									getColumnSum={getColumnSum}
								/>
							);
						})}
					</table>
				</div>
			)}
		</TableContext.Provider>
	);
}

export {
	Table,
	Cell,
	LinkCell,
	ActionCell,
	HeadCell,
	HeadCellSort,
	FootCell,
	ExpandableRow,
	Row,
	ToggleExpand,
};
