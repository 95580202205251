import { JSX, Fragment, useState, useEffect, createContext } from 'react';

import * as styles from 'pkg/config/styles';
import { PageWidths } from 'pkg/config/sizes';

import { PaymentPreviewPayload, PaymentRow } from 'pkg/actions/payment_preview';

import { emptyForm, Field, Statuses, Visibilities } from 'pkg/api/models/form';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import { PaymentPreview } from 'pkg/api/models/payment_preview';
import * as actions from 'pkg/actions';

import * as Modals from 'routes/forms/hooks/modals/Fields';
import * as Sections from 'routes/forms/hooks/sections';
import AddProductModal from 'routes/forms/create/products/AddModal';
import EditProductModal from 'routes/forms/create/products/EditModal';
import { FormActionBar, ActionFooter } from 'routes/forms/ActionBars';
import { preSelectedFields } from 'routes/forms/hooks/sections/fields';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';

interface ComparisonData {
	[key: string]: unknown;
}

export interface FormDataProps {
	title?: string;
	description?: string;
	formCategoryId?: number;
	fields: Field[];
	attachment: models.attachment.Attachment;
	submissionEmailEnabled: boolean;
	submissionEmailSubject?: string;
	submissionEmailContent?: string;
	forceAuth?: boolean;

	priceOptions: PriceOption[];
	selectedProductsInfo: SelectedProductInfo[];
}

// Interface of the products that is selected in the form.
export interface SelectedProductInfo {
	taxRate?: models.taxRate.TaxRate;
	taxRateId?: number;
	minQuantity?: number;
	maxQuantity?: number;
	required?: boolean;
	validTo?: number;
	subscriptionStartAt?: number;
	payFirstOrderNow?: boolean;

	product: models.product.Product;
}

export interface PriceOption {
	installmentCount: number;
	bundleNumber: number;
	subscriptionStartAt?: number;
	payFirstOrderNow?: boolean;
	type?: string;

	productPrices: models.productPrice.ProductPrice[];
}

export type PriceOptionType = 'direct' | 'installment' | 'subscription' | '';

export function findPriceOptionType(priceOption: PriceOption): PriceOptionType {
	if (priceOption.installmentCount !== null) {
		return 'installment';
	} else if (priceOption.productPrices.some((price) => price.recurring)) {
		return 'subscription';
	} else {
		return 'direct';
	}
}

const generateFormData = (formData: models.form.Form): FormDataProps => {
	const data: FormDataProps = {
		fields:
			formData.fields?.length > 0
				? (formData.fields.sort((a, b) => a.sortOrder - b.sortOrder) as Field[])
				: preSelectedFields().map((field) => ({ ...field, required: true })),
		priceOptions: [],
		attachment: formData.attachment || ({} as models.attachment.Attachment),
		submissionEmailEnabled: formData.submissionEmailEnabled,
		submissionEmailSubject: formData.submissionEmailSubject,
		submissionEmailContent: formData.submissionEmailContent,
		forceAuth: formData.forceAuth,
		selectedProductsInfo: [],
	};

	if (formData.productPrices) {
		const products = formData.productPrices.map(
			(formProductPrice) => formProductPrice.productPrice.product
		);

		const uniqueProducts = [
			...new Map(products.map((item) => [item['id'], item])).values(),
		];

		// Find all unique products in the different formProductPrices and add them
		// to selectedProductsInfo
		data.selectedProductsInfo = uniqueProducts.map((p) => {
			const prodPrice = formData.productPrices.find(
				(pp) => pp.productPrice.productId === p.id
			);

			return {
				taxRate: prodPrice.taxRate,
				taxRateId: prodPrice.taxRateId,
				maxQuantity: prodPrice.maxQuantity,
				minQuantity: prodPrice.minQuantity,
				required: prodPrice.required,
				validTo: prodPrice.validTo,
				subscriptionStartAt: prodPrice.subscriptionStartAt,
				payFirstOrderNow: prodPrice.payFirstOrderNow,
				product: p,
			};
		});

		// Go through formProductPrice to create different price options
		formData.productPrices.forEach((formProductPrice) => {
			if (formProductPrice.bundleNumber === 0) {
				return;
			}

			const existingIndex = data.priceOptions.findIndex(
				(priceOption) =>
					priceOption.bundleNumber === formProductPrice.bundleNumber
			);

			if (existingIndex !== -1) {
				data.priceOptions[existingIndex].productPrices = [
					...data.priceOptions[existingIndex].productPrices,
					formProductPrice.productPrice,
				];
			} else {
				data.priceOptions.push({
					installmentCount: formProductPrice.installmentCount,
					bundleNumber: formProductPrice.bundleNumber,
					productPrices: [formProductPrice.productPrice],
					subscriptionStartAt: formProductPrice.subscriptionStartAt,
					payFirstOrderNow: formProductPrice.payFirstOrderNow,
				});
			}
		});
	}

	if (formData.title) {
		data.title = formData.title;
	}

	if (formData.description) {
		data.description = formData.description;
	}

	if (formData.formCategoryId) {
		data.formCategoryId = formData.formCategoryId;
	}

	return data;
};

const checkForChanges = (
	initialData: FormDataProps | ComparisonData,
	currentData: FormDataProps | ComparisonData
): boolean => {
	let hasChanges = false;

	if (
		Object.keys(initialData).length === 0 &&
		Object.keys(currentData).length > 0
	) {
		return true;
	}

	for (const entries of Object.entries(initialData)) {
		const [key, value]: [string, unknown] = entries;

		if (!currentData) {
			break;
		}

		if (hasChanges) {
			break;
		}

		if (!value && !currentData[key as keyof FormDataProps]) {
			continue;
		}

		if (Array.isArray(value)) {
			const currentItem = currentData[key as keyof FormDataProps] as [];

			if (!currentItem) {
				continue;
			}

			if (value.length === 0 && currentItem.length === 0) {
				continue;
			}

			if (value.length !== currentItem.length) {
				hasChanges = true;
				continue;
			}

			hasChanges =
				value
					.map((item: ComparisonData, index: number) =>
						checkForChanges(item, currentItem[index])
					)
					.filter((value: boolean) => value !== false).length > 0;
			continue;
		}

		if (value && typeof value === 'object') {
			hasChanges = checkForChanges(
				value as ComparisonData,
				currentData[key as keyof FormDataProps] as ComparisonData
			);
			continue;
		}

		hasChanges = currentData[key as keyof FormDataProps] !== value;
	}

	return hasChanges;
};

interface ReturnProps {
	formData: FormDataProps;
	FormContent: JSX.Element;
}

export const FormEngineContext = createContext<{
	formState: FormDataProps;
	setFormState: MixedStateSetter<FormDataProps>;
	form: models.form.Form;
	previews: PaymentPreview[];
}>({
	formState: {
		title: '',
		description: '',
		formCategoryId: 0,
		priceOptions: [],
		fields: [],
		attachment: {} as models.attachment.Attachment,
		submissionEmailEnabled: true,
		submissionEmailSubject: '',
		submissionEmailContent: '',
		forceAuth: true,
		selectedProductsInfo: [],
	},
	form: {} as models.form.Form,
	setFormState: () => {
		return;
	},
	previews: [],
});

interface FormEngineProps {
	prevData?: models.form.Form;
	edit?: boolean;
}

const useFormEngine = ({
	prevData = emptyForm,
	edit = false,
}: FormEngineProps): ReturnProps => {
	const organization = useCurrentOrganization();
	const groupId = useCurrentGroup().id;

	const { records: userFields, isLoading: isUserFieldsLoading } =
		useCollection<models.userFields.UserField>(endpoints.UserField.Index(), {
			queryParams: new URLSearchParams({ group_id: groupId.toString() }),
		});

	const generatedPrevData = generateFormData(prevData || emptyForm);

	const [data, setData] = useMixedState<FormDataProps>({
		title: prevData?.title,
		description: prevData?.description,
		formCategoryId: prevData?.formCategoryId || 0,
		priceOptions: [],
		fields: [],
		attachment: {} as models.attachment.Attachment,
		submissionEmailEnabled: true,
		submissionEmailSubject: '',
		submissionEmailContent: '',
		forceAuth: prevData?.forceAuth,
		selectedProductsInfo: generatedPrevData.selectedProductsInfo,
	});

	const [previews, setPreviews] = useState<PaymentPreview[]>([]);

	const [openModal, setOpenModal] = useState<string>('');
	const [editProductId, setEditProductId] = useState<number>(0);
	const [editFieldData, setEditFieldData] = useState({
		index: 0,
		data: null,
	});

	const dataHasBeenChanged = checkForChanges(generatedPrevData, data);

	useEffect(() => {
		const fetchData = async () => {
			if (data.priceOptions.length < 1) {
				return;
			}

			const payload = data.priceOptions.map((po, index) => {
				// Filter out productPrices where we can't find a corresponding selected product
				// this can happen when a product is removed and this function runs before the useEffect
				// updating the priceOptions productPrices runs
				const rows = po.productPrices.filter(
					(pp) =>
						data.selectedProductsInfo.find(
							(spi) => spi.product.id === pp.productId
						) !== undefined
				);

				const singlePreviewPayload: PaymentPreviewPayload = {
					groupId,
					bundleNumber: index + 1,
					rows: rows.map((pp) => {
						const formProductPrice = data.selectedProductsInfo.find(
							(spi) => spi.product.id === pp.productId
						);

						const row: PaymentRow = {
							productPriceId: pp.id,
							quantity: formProductPrice.minQuantity,
						};

						if (formProductPrice.taxRate) {
							row.taxRateId = formProductPrice.taxRateId;
						}

						return row;
					}),
				};

				if (po.installmentCount) {
					singlePreviewPayload.installmentCount = po.installmentCount;
				}

				if (po.subscriptionStartAt) {
					singlePreviewPayload.subscriptionStartAt = po.subscriptionStartAt;
					singlePreviewPayload.delayedSubscriptionStart = true;
				}

				return singlePreviewPayload;
			});

			const [req, resp] =
				await actions.paymentPreview.getBundlesPreviews(payload);

			if (req) {
				setPreviews(resp);
			}
		};

		fetchData();
	}, [
		JSON.stringify(data.priceOptions),
		JSON.stringify(data.selectedProductsInfo),
	]);

	useEffect(() => {
		setData(generatedPrevData);
	}, [prevData.id]);

	useEffect(() => {
		// This means that all selected products have been removed so we remove all price options
		if (data.selectedProductsInfo.length === 0) {
			setData({
				priceOptions: [],
			});
			// This check is for when products are removed and we have set price options
			// we want to remove the set price option for that product
		} else if (data.priceOptions.length > 0) {
			const productIds = data.selectedProductsInfo.map((pi) => pi.product.id);

			const priceOptions = [...data.priceOptions];
			priceOptions.forEach((po, i) => {
				priceOptions[i].productPrices = po.productPrices.filter((pp) =>
					productIds.includes(pp.productId)
				);
			});

			setData({
				priceOptions,
			});
		}
	}, [data.selectedProductsInfo.length]);

	const isEdit = edit;

	const handleOpenFieldsModal = () => setOpenModal('fields');

	const handleOpenCustomFieldModal = (
		field?: Field,
		isNewSection?: boolean
	) => {
		if (field) {
			setEditFieldData({
				index: data.fields.indexOf(field),
				data: field,
			});
		} else if (isNewSection) {
			setEditFieldData({
				index: data.fields.length,
				data: {
					type: models.form.FieldTypes.Section,
				},
			});
		}

		setOpenModal('custom_field');
	};
	const handleOpenDefaultFieldModal = (field?: Field) => {
		if (field) {
			setEditFieldData({
				index: data.fields.indexOf(field),
				data: field,
			});
		}

		setOpenModal('default_field');
	};

	const handleOpenAddProductsModal = () => setOpenModal('product_add');

	const handleOpenEditProductsModal = (productId: number) => {
		setEditProductId(productId);
		setOpenModal('product_edit');
	};

	const handleCloseModal = () => setOpenModal('');

	const handleCloseCustomFieldModal = () => {
		if (editFieldData.data) {
			setEditFieldData({ index: 0, data: null });
		}

		setOpenModal('');
	};

	const handleAddFields = async (fields: Field[]) => {
		setData({ fields: [...data.fields, ...fields] });
	};

	const handleGeneralFieldChange = (field: string, value: string) => {
		setData({ [field]: value });
	};

	const handleEmailFieldChange = (field: string, value: string) => {
		setData({ [field]: value });
	};

	const handleEmailToggleSend = () => {
		setData({ ['submissionEmailEnabled']: !data.submissionEmailEnabled });
	};

	const resetEmailCustomization = () => {
		setData({
			...data,
			['submissionEmailSubject']: '',
			['submissionEmailContent']: '',
		});
	};

	const handleAddCustomField = async (field: Field, isEdit: boolean) => {
		if (isEdit) {
			const fields = [...data.fields];
			fields[editFieldData.index] = field;
			setData({ fields });
		} else {
			if (data.fields.length) {
				field.sortOrder = data.fields[data.fields.length - 1].sortOrder + 1;
			} else {
				field.sortOrder = 0;
			}

			setData({ fields: [...data.fields, field] });
		}
	};

	const removeSingleField = (field: Field) => {
		const fields = data.fields.filter((item) => item.key !== field.key);

		setData({
			fields,
		});
	};

	const handleToggleRequired = (field: Field) => {
		const fields = data.fields;
		const index = fields.findIndex((item) => item.key === field.key);
		fields[index].required = !fields[index].required;

		setData({
			fields,
		});
	};

	const handleFieldDrag = (fields: Field[]) => setData({ fields });

	const handleSaveProduct = (productData: SelectedProductInfo) => {
		const oldState = [...data.selectedProductsInfo];
		const exist = data.selectedProductsInfo.findIndex(
			(p) => p.product.id === productData.product.id
		);

		if (exist !== -1) {
			oldState[exist] = { ...oldState[exist], ...productData };

			setData({
				selectedProductsInfo: oldState,
			});
		} else {
			setData({
				selectedProductsInfo: [...data.selectedProductsInfo, productData],
			});
		}
	};

	// Check if all selected products exist inside priceoptions products
	const hasPriceOptions = data.selectedProductsInfo.length
		? data.selectedProductsInfo.every((productInfo) => {
				return data.priceOptions.length
					? data.priceOptions.every((option) =>
							option.productPrices
								.map((p) => p.product.id)
								.includes(productInfo.product.id)
						)
					: false;
			})
		: true;

	let hasCorrectPriceOptions = true;

	data.priceOptions.forEach((po) => {
		const type = findPriceOptionType(po);

		if (type !== 'direct' && po.productPrices.every((po) => !po.recurring)) {
			hasCorrectPriceOptions = false;
		}

		// Check if the priceOption has a subscription start set and if there's
		// no one time products set
		if (
			po.subscriptionStartAt &&
			po.productPrices.filter((p) => !p.recurring).length === 0
		) {
			hasCorrectPriceOptions = false;
		}
	});

	const hasValidPriceOptions = hasCorrectPriceOptions && hasPriceOptions;
	const hasRequiredFields = data.fields && data.fields.length > 0;

	const submissionEmailData = {
		submissionEmailEnabled: data.submissionEmailEnabled,
		submissionEmailSubject: data.submissionEmailSubject,
		submissionEmailContent: data.submissionEmailContent,
	};

	const sectionsContent = (
		<Fragment>
			<Sections.General
				data={{
					title: data.title,
					formCategoryId: data.formCategoryId,
					description: data.description,
					attachment: data.attachment,
					fields: data.fields,
					formId: prevData?.id,
					status: prevData?.status,
				}}
				onGeneralFieldChange={handleGeneralFieldChange}
			/>
			<Sections.Fields
				data={{ fields: data.fields }}
				isLoading={isUserFieldsLoading}
				onAddField={handleOpenFieldsModal}
				onDrag={handleFieldDrag}
				handleOpenFieldsModal={handleOpenFieldsModal}
				handleOpenCustomFieldModal={handleOpenCustomFieldModal}
				handleOpenDefaultFieldModal={handleOpenDefaultFieldModal}
				removeSingleField={removeSingleField}
				handleToggleRequired={handleToggleRequired}
			/>
			{models.group.hasActivePaymentProvider(organization) && (
				<Column spacing={styles.spacing._6}>
					<Sections.Products
						data={{
							selectedProducts: data.selectedProductsInfo,
						}}
						onAddProduct={handleOpenAddProductsModal}
						onEditProduct={handleOpenEditProductsModal}
					/>
					{data.selectedProductsInfo.length > 0 && <Sections.PriceOptions />}
				</Column>
			)}
			<Sections.SubmissionEmail
				data={submissionEmailData}
				onChange={handleEmailFieldChange}
				onToggle={handleEmailToggleSend}
				resetEmailCustomization={resetEmailCustomization}
			/>
			<Sections.Visibilities
				data={{
					visibility: prevData?.visibility,
				}}
				visibilities={[
					Visibilities.Public,
					Visibilities.Organization,
					Visibilities.Unlisted,
				]}
			/>
			<Sections.Availability
				data={{
					maxSubmissions: prevData?.maxSubmissions,
					status: prevData?.status,
					forceAuth: prevData?.forceAuth,
				}}
				statuses={[Statuses.Open, Statuses.Closed, Statuses.Draft]}
			/>
			<LargeScreen>
				<ActionFooter
					dataHasBeenChanged={dataHasBeenChanged}
					isEdit={isEdit}
					hasRequiredFields={hasRequiredFields}
					groupId={groupId}
					hasValidPriceOptions={hasValidPriceOptions}
				/>
			</LargeScreen>
		</Fragment>
	);

	return {
		formData: data,
		FormContent: (
			<FormEngineContext.Provider
				value={{
					formState: data,
					setFormState: setData,
					form: prevData,
					previews,
				}}>
				<FormActionBar
					dataHasBeenChanged={dataHasBeenChanged}
					isEdit={isEdit}
					hasRequiredFields={hasRequiredFields}
					hasValidPriceOptions={hasValidPriceOptions}
					groupId={groupId}
				/>
				<LargeScreen>
					<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD} spacious>
						{sectionsContent}
					</LargeScreenContent.Inner>
				</LargeScreen>
				<SmallScreen>
					<LargeScreenContent.Inner>{sectionsContent}</LargeScreenContent.Inner>
				</SmallScreen>
				{openModal === 'fields' && (
					<Modals.FieldsModal
						userFields={userFields}
						prevFields={data.fields}
						onDone={handleAddFields}
						onClose={handleCloseModal}
					/>
				)}
				{openModal === 'custom_field' && (
					<Modals.CustomFieldModal
						addedFields={data.fields}
						prevData={editFieldData.data}
						onDone={handleAddCustomField}
						onClose={handleCloseCustomFieldModal}
					/>
				)}
				{openModal === 'default_field' && (
					<Modals.EditDefaultFieldModal
						addedFields={data.fields}
						prevData={editFieldData.data}
						onDone={handleAddCustomField}
						onClose={handleCloseCustomFieldModal}
					/>
				)}

				{openModal === 'product_add' && (
					<AddProductModal
						onClose={handleCloseModal}
						onDone={handleSaveProduct}
					/>
				)}

				{openModal === 'product_edit' && (
					<EditProductModal
						data={data.selectedProductsInfo.find(
							(p) => p.product.id === editProductId
						)}
						onDone={handleSaveProduct}
						onClose={handleCloseModal}
					/>
				)}
			</FormEngineContext.Provider>
		),
	};
};

export default useFormEngine;
