import { JSX } from 'react';
import styled, { css } from 'styled-components';
import { t } from '@transifex/native';

import CheckBox from 'components/form/CheckBox';
import { useFullScreenTableContext } from 'components/layout/page-templates';

const Container = styled.div<{ compact: boolean }>`
	display: grid;
	grid-auto-flow: column;
	grid-gap: var(--spacing-6);
	justify-content: flex-start;
	align-items: center;
	padding: var(--spacing-4);
	font-size: var(--font-size-sm);
	background-color: var(--palette-white);
	z-index: 2;
	${({ compact }) =>
		compact
			? css`
					border: 1px solid var(--palette-gray-300);
					border-radius: 12px;
					margin-bottom: var(--spacing-5);
				`
			: css`
					border-bottom: 1px solid var(--palette-gray-300);
				`};
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
`;

interface Filter {
	name: string;
	label: JSX.Element | string;
}

interface QuickFilterProps {
	filters: Filter[];
	selectedFilters: string[];
	onCheck: (filter: string) => void;
}

const QuickFilter: React.FC<React.PropsWithChildren<QuickFilterProps>> = ({
	filters,
	selectedFilters,
	onCheck,
}) => {
	const tableViewCtx = useFullScreenTableContext();

	return (
		<Container compact={tableViewCtx.fullPageTable}>
			<span>{t('Only show')}:</span>
			{filters.map((filter: Filter) => (
				<Wrapper key={filter.name}>
					<CheckBox
						id={filter.name}
						selected={selectedFilters.includes(filter.name)}
						onClick={() => onCheck(filter.name)}
						label={filter.label}
					/>
				</Wrapper>
			))}
		</Container>
	);
};

export default QuickFilter;
