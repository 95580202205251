import { useDispatch, useSelector } from 'react-redux';
import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import * as routes from 'pkg/router/routes';
import { pushState, replaceState } from 'pkg/router/state';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';

import CurrentClip from 'routes/video/play/CurrentClip';
import ClipList from 'routes/video/shared/ClipList';
import Controls from 'routes/video/shared/Controls';
import Timeline from 'routes/video/shared/Timeline';
import Source from 'routes/video/shared/Source';
import Layout, { LayoutContext } from 'routes/video/layouts/Layout';
import Bootstrap from 'routes/video/play/Bootstrap';
import KeyBindings from 'routes/video/play/KeyBindings';

import { formatRelativeDateTimeString } from 'components/RelativeDateTime';
import Icon from 'components/icon';

import VideoModal from 'components/video-library/modals/Video';
import CueState from 'components/video-analytics/CueState';
import CueObserver from 'components/video-analytics/CueObserver';
import PlayerState from 'components/video-analytics/PlayerState';
import PlaybackState from 'components/video-analytics/PlaybackState';
import Analyze from 'components/video-analytics/controls/Analyze';
import ClipState from 'components/video-analytics/ClipState';

import * as ContextMenu from 'design/context_menu';

interface VideoProps {
	videoId: number;
}

export default function Show({ videoId }: VideoProps): JSX.Element {
	const [isEditing, setEditing] = useState<boolean>(false);
	const dispatch = useDispatch();
	const org = useCurrentOrganization();

	const openEditModal = () => setEditing(true);
	const closeEditModal = () => setEditing(false);

	const video = useSelector((state: RootState) =>
		selectors.videos.find(state, videoId)
	);

	const group = useCurrentGroup();

	const createdAt = formatRelativeDateTimeString(video?.createdAt, true);

	const canEdit = video.hasIn(['links', 'edit']);
	const canDelete = video.hasIn(['links', 'delete']);
	const canAnalyze =
		video.hasIn(['links', 'create:video_sequence']) &&
		models.group.hasFeature(group, Features.Video);

	const isValid = video.validSource();

	const showContextMenu = isValid && (canEdit || canDelete || canAnalyze);

	useComponentDidMount(async () => {
		await actions.videos.fetch(videoId);
	});

	const handleDelete = async () => {
		await dispatch(actions.videos.removeVideo(videoId));

		replaceState(routes.VideoLibrary.Index(org.id, group.id));
	};

	if (!video) return null;

	const enterAnalyzeMode = () =>
		pushState(routes.Video.Analyze(org.id, videoId));

	return (
		<Fragment>
			<ClipState>
				<PlayerState>
					<PlaybackState>
						<CueState>
							<CueObserver />
							<KeyBindings />
							<Layout
								workspaceHeight={250}
								context={LayoutContext.Play}
								title={video.title}
								subTitle={createdAt}
								bootstrap={<Bootstrap />}
								videoSource={<Source />}
								currentClip={<CurrentClip />}
								clipList={<ClipList context="play-mode" groupByAccount />}
								controls={<Controls context="video" />}
								timeline={<Timeline disabled={!isValid} />}
								pageAction={
									<Fragment>
										{canAnalyze && isValid && <Analyze videoId={videoId} />}
									</Fragment>
								}
								contextItems={
									showContextMenu && (
										<Fragment>
											{canAnalyze && (
												<Fragment>
													<ContextMenu.Item onClick={enterAnalyzeMode}>
														<Icon name="video-placeholder" size={1.25} />
														{t('Analyze video')}
													</ContextMenu.Item>
												</Fragment>
											)}
											{canEdit && (
												<ContextMenu.Item onClick={openEditModal}>
													<Icon name="edit" size={1.25} />
													{t('Edit video')}
												</ContextMenu.Item>
											)}
											{canDelete && (
												<ContextMenu.ConfirmItem
													caution
													message={t(
														'Are you sure? This action cannot be undone.'
													)}
													confirmLabel={t('Yes, delete')}
													onConfirm={handleDelete}>
													<Icon name="delete" size={1.25} />
													{t('Delete')}
												</ContextMenu.ConfirmItem>
											)}
										</Fragment>
									)
								}
							/>
						</CueState>
					</PlaybackState>
				</PlayerState>

				{isEditing && <VideoModal videoId={videoId} onClose={closeEditModal} />}
			</ClipState>
		</Fragment>
	);
}
