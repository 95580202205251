import { t } from '@transifex/native';
import { JSX, Fragment, useState } from 'react';

import { PageWidths } from 'pkg/config/sizes';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';

import InitialModal from 'routes/group/settings/tabs/group_links/InitialModal';
import PopulatedIndex from 'routes/group/settings/tabs/group_links/PopulatedIndex';

import Icon from 'components/icon';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';
import MissingEntities from 'design/placeholders/block';

interface CustomLinksProps {
	groupId: number;
}

export default function CustomLinks({
	groupId,
}: CustomLinksProps): JSX.Element {
	const [initialModal, setInitialModal] = useState(false);

	const hideInitialModal = () => setInitialModal(false);
	const openInitialModal = () => setInitialModal(true);

	const {
		records: folders,
		refresh,
		isLoading,
	} = useCollection<models.groupLinkFolder.GroupLinkFolder>(
		endpoints.Groups.ShowAllGroupLinks(groupId)
	);

	if (isLoading) {
		return <Spinner />;
	}

	const emptyState = (
		<MissingEntities
			noBorder
			title={t('No custom links yet')}
			description={t(
				'Create custom links to show in the 360Player navigation for your organization'
			)}
			helpUrl="https://help.360player.com/article/180-add-custom-navigation-links"
			helpUrlText={t('Guide - How to set up custom links')}
			icon="link"
			actions={
				<Button
					primary
					label={t('Set up your custom links')}
					onClick={openInitialModal}
				/>
			}
		/>
	);

	return (
		<Fragment>
			<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
				<Column>
					{folders.length > 0 ? (
						<Column>
							<PopulatedIndex
								groupId={groupId}
								folders={folders}
								refresh={refresh}
							/>
							<Button large transparent onClick={openInitialModal}>
								<Icon name="add" />
								{t('New folder')}
							</Button>
						</Column>
					) : (
						<div>{emptyState}</div>
					)}
				</Column>
			</LargeScreenContent.Inner>
			{initialModal && (
				<InitialModal
					hideModal={hideInitialModal}
					groupId={groupId}
					refresh={refresh}
					folders={folders}
				/>
			)}
		</Fragment>
	);
}
