import { JSX } from 'react';

import GroupUsersList from 'routes/group/settings/user_management/AllUsersList';
import BannedUsersList from 'routes/group/settings/user_management/BannedUsersList';

interface UserManagementProps {
	organizationId: number;
	groupId: number;
	currentTab: string;
}

export default function UserManagement({
	organizationId,
	groupId,
	currentTab,
}: UserManagementProps): JSX.Element {
	let content;

	if (currentTab === 'all') {
		content = (
			<GroupUsersList organizationId={organizationId} groupId={groupId} />
		);
	}
	if (currentTab === 'banned') {
		content = <BannedUsersList groupId={groupId} />;
	}

	return content;
}
