import { JSX } from 'react';
import styled from 'styled-components';

import { ToolProps } from 'components/annotations/tools/ToolProps';
import { generatePath } from 'components/annotations/AnnotationHooks';
import { MULTIPOINT } from 'components/annotations/constants';
import { getPreferenceValue } from 'components/annotations/tools/ToolConfig';

const SVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export default function Line({
	annotationId,
	annotation,
	config,
	multipointRef,
}: ToolProps): JSX.Element {
	const d = generatePath(annotation.keyframes[0].points);

	let color = getPreferenceValue(annotation, 'color');
	color += (+(
		annotation.opacity || config.preferences.opacity.defaultValue
	)).toString(16);

	return (
		<SVG
			viewBox="0 0 100 100"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/SVG">
			<path
				ref={multipointRef}
				d={d}
				fill={
					annotation.striped ? 'url(#area-stripes-' + annotationId + ')' : color
				}
				vectorEffect="non-scaling-stroke"
				className={MULTIPOINT}
			/>
			{annotation.striped && (
				<defs>
					<pattern
						id={'area-stripes-' + annotationId}
						patternUnits="userSpaceOnUse"
						width="3"
						height="3"
						patternTransform="rotate(31)">
						<line
							x1="0"
							y="0"
							x2="0"
							y2="3"
							stroke={color}
							strokeWidth="3.5 "
						/>
					</pattern>
				</defs>
			)}
		</SVG>
	);
}
