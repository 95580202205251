import { JSX, MouseEvent } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import { getAlias } from 'pkg/config/tags';

import Icon from 'components/icon';

import Column from 'components/layout/column';

const Item = styled.span`
	background: var(--palette-blue-500);
	display: inline-block;
	margin: 0 var(--spacing-2) var(--spacing-2) 0;
	padding: var(--spacing-3);
	font-size: var(--font-size-xs);
	border-radius: var(--radius-2);
	cursor: pointer;

	svg {
		margin-left: var(--spacing-2);
		pointer-events: none;
	}

	&[data-predefined] {
		background: var(--palette-gray-600);

		@media (hover: hover) {
			&:hover {
				background: var(--palette-blue-500);
			}
		}
	}
`;

interface TagsProps {
	tags: string[];
	onAdd: (tag: string) => void;
	onRemove: (tag: string) => void;
}

export default function Tags({
	tags,
	onAdd,
	onRemove,
}: TagsProps): JSX.Element {
	const predefinedTags = [
		'attacking_play',
		'ball_lost',
		'ball_won',
		'chance_against',
		'chance_to_score',
		'corner',
		'defensive_play',
		'finish',
		'free_kick',
		'goal_conceded',
		'goal_kick',
		'goal',
		'penalty',
		'pressing',
		'red_card',
		'yellow_card',
	];

	const handleAdd = (event: MouseEvent<HTMLSpanElement>) => {
		onAdd(event.currentTarget.title);
	};

	const handleRemove = (event: MouseEvent<HTMLSpanElement>) => {
		onRemove(event.currentTarget.title);
	};

	return (
		<Column spacing={styles.spacing._3}>
			{tags.length > 0 && (
				<div>
					{tags.map((tag: string) => (
						<Item key={tag} title={tag} onClick={handleRemove}>
							{tag}
							<Icon name="close" size={1.35} />
						</Item>
					))}
				</div>
			)}
			<Column spacing={styles.spacing._3}>
				<p>{t('Predefined tags')}</p>
				<div>
					{predefinedTags.map((tag: string) => (
						<Item
							key={tag}
							data-predefined
							onClick={handleAdd}
							title={getAlias(tag)}>
							{getAlias(tag)}
						</Item>
					))}
				</div>
			</Column>
		</Column>
	);
}
