import {
	JSX,
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import { EndpointResponse, useEndpoint } from 'pkg/api/use_endpoint';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import { CollectionResponse, useCollection } from 'pkg/api/use_collection';
import { EventTypes } from 'pkg/api/models/event';

import EventParticipants from 'routes/event/single/tabs/participants';
import EventOverview from 'routes/event/single/tabs/overview';
import EventSessions from 'routes/event/single/tabs/sessions';
import EventNotes from 'routes/event/single/tabs/notes';
import Lok from 'routes/event/single/tabs/lok';
import MatchReport from 'routes/event/single/tabs/report';
import MatchNotes from 'routes/event/single/tabs/match-notes';
import MatchVideo from 'routes/event/single/tabs/videos';
import MatchLineup from 'routes/event/single/tabs/lineup';
import MatchSummary from 'routes/event/single/tabs/summary';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import MissingEntities from 'components/missing-entities';
import Icon from 'components/icon';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as LargeLayout from 'components/navigation/header/large_screen/Styles';
import { Spinner } from 'components/loaders/spinner';
import SmallScreenContent from 'components/layout/SmallScreenContent';

import Button from 'design/button';

export interface EventContextProps {
	eventEndpointResponse: EndpointResponse<models.event.Event>;
	membershipsCollection?: CollectionResponse<models.membership.Membership>;
	setIsDeleted?: Dispatch<SetStateAction<boolean>>;
}

export const EventContext = createContext<EventContextProps>(null);

export function useEventContext() {
	return useContext(EventContext);
}

interface EventProps {
	eventId: number;
	icon: MaterialSymbolVariant;
	currentTab?: string;
}

export default function Event({
	eventId,
	currentTab,
	icon,
}: EventProps): JSX.Element {
	const t = useT();
	const [isDeleted, setIsDeleted] = useState(false);
	const currentMembership = useCurrentMembership();
	const isAdminOrStaff = models.membership.isAdminOrStaff(currentMembership);

	const eventEndpointResponse = useEndpoint<models.event.Event>(
		endpoints.Events.Show(eventId)
	);
	const membershipsCollection = useCollection<models.membership.Membership>(
		endpoints.Groups.ShowUsers(currentMembership.groupId),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({ includeLegalGuardians: '1' }),
		}
	);
	const event = eventEndpointResponse.record;

	const org = useCurrentOrganization();
	const currentGroup = useCurrentGroup();
	const group = event?.group;
	const organization = group?.organization;
	const groupSport = group?.sport;

	const hasMatchesEnabled = models.sport.hasMatchesEnabled(groupSport);
	const groupHasMatchFeature = models.group.hasFeature(
		group,
		models.group.Features.Matches
	);
	const groupHasVideoFeature = models.group.hasFeature(
		group,
		models.group.Features.Video
	);

	const match = event?.matches?.[0];

	const isMobile = useMediaQuery({ maxWidth: styles.breakpoint.toMedium });
	const showLokSubItem =
		models.group.isLOKActive(organization) && models.canEdit(event);

	const isMatch = event.type === EventTypes.Match && match?.id;

	let canViewSession =
		models.canEdit(event) &&
		models.group.hasFeature(group, models.group.Features.TrainingLibrary);

	if (!canViewSession) {
		canViewSession = event?.sessions?.some(
			(s: models.session.Session) => s.playerVisible
		);
	}

	let content = <Spinner />;
	if (event?.id) {
		content = (
			<EventOverview
				eventRecord={eventEndpointResponse.record}
				eventEndpoint={eventEndpointResponse}
				membershipsCollection={membershipsCollection}
			/>
		);

		switch (currentTab) {
			case 'sessions':
				if (
					!models.group.hasFeature(group, models.group.Features.TrainingLibrary)
				) {
					return null;
				}

				content = (
					<EventSessions
						event={event}
						refresh={eventEndpointResponse.refresh}
					/>
				);
				break;
			case 'notes':
				content = <EventNotes eventEndpoint={eventEndpointResponse} />;
				break;
			case 'participants':
				content = <EventParticipants event={event} />;
				break;
			case 'lok':
				content = <Lok event={event} />;
				break;
			case 'match-notes':
				if (isAdminOrStaff) {
					content = <MatchNotes match={match} />;
				}
				break;
			case 'report':
				content = <MatchReport match={match} />;
				break;
			case 'video':
				content = <MatchVideo match={match} />;
				break;
			case 'players':
				content = <MatchLineup match={match} event={event} />;
				break;
			case 'summary':
				content = <MatchSummary match={match} event={event} />;
		}
	}

	const navigation = [
		{
			title: t('Event'),
			href: routes.Event.View(org.id, eventId, 'overview'),
			show: true,
		},
		{
			title: t('Participants'),
			href: routes.Event.View(org.id, eventId, 'participants'),
			show: isMobile,
		},
		{
			title: 'LOK',
			href: routes.Event.View(org.id, event.id, 'lok'),
			show: showLokSubItem,
		},
		{
			title: t('Session'),
			href: routes.Event.View(org.id, eventId, 'sessions'),
			show: event.type === 'practice' && canViewSession,
		},
		{
			title: t('Summary'),
			href: routes.Match.View(org.id, eventId, match?.id, 'summary'),
			show: hasMatchesEnabled && groupHasMatchFeature && isMatch,
		},
		{
			title: t('Lineup'),
			href: routes.Match.View(org.id, eventId, match?.id, 'players'),
			show: hasMatchesEnabled && groupHasMatchFeature && isMatch,
		},
		{
			title: t('Video'),
			href: routes.Match.View(org.id, eventId, match?.id, 'video'),
			show: groupHasVideoFeature && groupHasMatchFeature && isMatch,
		},
		{
			title: t('Report'),
			href: routes.Match.View(org.id, eventId, match?.id, 'report'),
			show: groupHasMatchFeature && isMatch,
		},
		{
			title: t('Notes'),
			href: isMatch
				? routes.Match.View(org.id, eventId, match?.id, 'match-notes')
				: routes.Event.View(org.id, eventId, 'notes'),
			show: models.canEdit(event),
		},
	];

	const isLoading = eventEndpointResponse.isLoading;

	const eventHasBeenDeleted = event.deletedAt || isDeleted;

	if (eventHasBeenDeleted) {
		return (
			<LargeScreenContent.Wrapper>
				<MissingEntities centered>
					<Icon name="delete" />
					<h3>{t('The event was deleted')}</h3>
					<p>{t('Please return to the calendar or use the navigation.')}</p>
					<Button
						label={t('Go to calendar')}
						primary
						href={routes.Calendar.Index(
							org.id,
							currentGroup.id,
							sessionStorage.getItem('currentMode') || 'month'
						)}
					/>
				</MissingEntities>
			</LargeScreenContent.Wrapper>
		);
	}

	return (
		<EventContext.Provider
			value={{
				eventEndpointResponse,
				membershipsCollection,
				setIsDeleted,
			}}>
			<LargeScreen>
				<LargeScreenHeader title={event?.title} icon={icon}>
					{navigation.map(
						(item, index) =>
							item.show && (
								<LargeLayout.SubNavItem key={index} href={item.href}>
									{item.title}
								</LargeLayout.SubNavItem>
							)
					)}
				</LargeScreenHeader>
				<LargeScreenContent.Wrapper backgroundColor={styles.palette.white}>
					{!isLoading && content}
					{isLoading && <Spinner />}
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenHeader title={event?.title} />
				<SmallScreenContent backgroundColor={styles.palette.white}>
					<SmallLayout.SubNav>
						{navigation.map(
							(item, index) =>
								item.show && (
									<SmallLayout.SubNavItem key={index} href={item.href}>
										{item.title}
									</SmallLayout.SubNavItem>
								)
						)}
					</SmallLayout.SubNav>
					{!isLoading && content}
					{isLoading && <Spinner />}
				</SmallScreenContent>
			</SmallScreen>
		</EventContext.Provider>
	);
}
