import { JSX } from 'react';

import { Submissions } from 'routes/user/registrations/submissions';
import OpenRegistrations from 'routes/user/registrations/open';

import {
	FullScreenTablePage,
	SubNavItemProps,
	WidePage,
} from 'components/layout/page-templates';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

interface RegistrationProps {
	title?: string;
	icon?: MaterialSymbolVariant;
	subNavItems?: SubNavItemProps[];
	currentTab?: string;
}

export default function Registrations(props: RegistrationProps): JSX.Element {
	switch (props.currentTab) {
		case 'open':
			return (
				<WidePage {...props}>
					<OpenRegistrations />
				</WidePage>
			);
		case 'submissions':
			return (
				<FullScreenTablePage {...props}>
					<Submissions />
				</FullScreenTablePage>
			);
	}

	return null;
}
