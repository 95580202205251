import { JSX, Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import UserGoals from 'routes/organization/user-profile/development/user-goals';
import PerformanceReviews from 'routes/organization/user-profile/development/performance-reviews';
import Assessment from 'routes/organization/user-profile/development/assessment';
import useMembershipSelect from 'routes/organization/user-profile/use-membership-select';

import Column from 'components/layout/column';

import * as css from './styles.css';

interface OrganizationUserProfileDevelopmentProps {
	user: models.user.User;
}

export default function OrganizationUserProfileDevelopment({
	user,
}: OrganizationUserProfileDevelopmentProps): JSX.Element {
	const { MembershipSelect, selectedMembership } = useMembershipSelect(user);

	if (!user?.id || !selectedMembership?.groupId) {
		return null;
	}

	return (
		<Fragment>
			{MembershipSelect}
			<Column spacing={styles.spacing._7} className={css.wrapper}>
				<UserGoals user={user} />
				<PerformanceReviews user={user} groupId={selectedMembership.groupId} />
				<Assessment user={user} groupId={selectedMembership.groupId} />
			</Column>
		</Fragment>
	);
}
