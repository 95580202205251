import { JSX } from 'react';
import styled from 'styled-components';

import { ToolProps } from 'components/annotations/tools/ToolProps';
import { IS_EDITING } from 'components/annotations/constants';
import { getPreferenceValue } from 'components/annotations/tools/ToolConfig';

const Label = styled.div`
	display: inline-block;
	padding: 7px 10px;
	border-radius: var(--radius-1);
	color: white;
	background: rgba(0, 0, 0, 0.8);
	white-space: nowrap;
	user-select: none;
	transform-origin: 0 0;

	&:focus {
		outline: none;
	}

	.${IS_EDITING} & {
		pointer-events: visible;
	}
`;

export default function Circle({
	annotation,
	config,
	coverSize,
}: ToolProps): JSX.Element {
	const fontSize = getPreferenceValue(annotation, 'fontSize');

	return (
		<Label
			style={{
				fontSize: fontSize + 'px',
				transform: `scale(${coverSize.width / 1200})`,
			}}>
			{annotation.text || config.preferences.text.defaultValue}
		</Label>
	);
}
