import { JSX } from 'react';
import styled from 'styled-components';

import Timeline, { TimelineContext } from 'routes/video/play/Timeline';

const Wrapper = styled.div`
	padding: var(--spacing-5);
	padding-bottom: var(--spacing-3);
	background: var(--palette-gray-900);
	width: 100%;
	display: grid;
	place-items: center;
	color: var(--palette-gray-500);
`;

interface SmallScreenTimelineProps {
	startsAt?: number;
	endsAt: number;

	context?: TimelineContext;
	plotCuePoints?: boolean;
	disabled?: boolean;
}

export default function SmallScreenTimeline({
	startsAt = 0,
	endsAt,

	context,
	plotCuePoints,
	disabled,
}: SmallScreenTimelineProps): JSX.Element {
	return (
		<Wrapper>
			<Timeline
				context={context}
				plotCuePoints={plotCuePoints}
				startsAt={startsAt}
				endsAt={endsAt}
				disabled={disabled}
			/>
		</Wrapper>
	);
}
