import { JSX, ReactNode, createContext, useContext } from 'react';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import useMixedState from 'pkg/hooks/useMixedState';
import * as ls from 'pkg/local_storage';
import DateTime from 'pkg/datetime';
import { tlog } from 'pkg/tlog';
import store from 'pkg/store/createStore';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { resume } from 'pkg/events';
import { ShowResponse } from 'pkg/api/endpoints/self';

import { Spinner } from 'components/loaders/spinner';
import { useAppState } from 'components/application/state';

const NoOp = (): void => null;

interface AccountIdentityProviderProps {
	children: ReactNode;
}

interface AccountIdentityContextState {
	refreshAccountIdentity: () => void;

	account: models.account.Account;
	setAccount: (account: models.account.Account) => void;

	organizations: models.group.OrganizationData[];
	organization: models.group.Group;

	setOrganization: (organization: models.group.Group) => void;
	setOrganizationById: (organizationId: number) => void;
	setOrganizations: (organizations: models.group.OrganizationData[]) => void;
}

export interface OrganizationsLocalStorageData {
	// The key will be the ID of the org
	[key: string]: {
		activeMembership: models.membership.Membership;
		orgToken: string;
	};
}

const DefaultAccountIdentityContext: AccountIdentityContextState = {
	refreshAccountIdentity: NoOp,
	account: null,
	setAccount: NoOp,
	organizations: [],
	organization: null,
	setOrganization: NoOp,
	setOrganizationById: NoOp,
	setOrganizations: NoOp,
};

export const AccountIdentityContext =
	createContext<AccountIdentityContextState>(DefaultAccountIdentityContext);

export const useAccountIdentity = () => {
	return useContext(AccountIdentityContext);
};

export function AccountIdentityProvider({
	children,
}: AccountIdentityProviderProps): JSX.Element {
	const dispatch = store.dispatch;

	const re = document.location.pathname.match(
		/organization\/(?<orgIdFromUrl>\d+)/i
	);

	const orgIdFromUrl = Number.parseInt(re?.groups.orgIdFromUrl ?? '0', 10);

	const [state, setState] = useMixedState<AccountIdentityContextState>(
		DefaultAccountIdentityContext
	);

	const { setLanguage } = useAppState();

	const { isLoading, refresh } = useEndpoint<ShowResponse>(
		endpoints.Self.ShowSelf(),
		{},
		async (data, response) => {
			if (!response) {
				return;
			}

			if (response.status === 401) {
				// auth is no longer valid
				actions.auth.logout()(dispatch);
				return;
			}

			const hasData: boolean = Object.keys(data)?.length > 0;

			actions.app.setupOrganizationLocalStorage(data.organizations || []);

			if (hasData) {
				setState(data);

				tlog.setUserContext(data.account.id.toString());

				DateTime.resolveDateTimeOptions();
				DateTime.setHourFormat(
					data.account.hourFormat === models.account.AccountHourFormat['12h']
						? '12h'
						: '24h'
				);

				setLanguage(data.account.languageCode);
				DateTime.setLocale(data.account.languageCode);

				if (state.organization === null && data.organizations?.length > 0) {
					const lsOrganizationId = Number.parseInt(
						ls.getItem(ls.LocalStorageKeys.ActiveOrganizationId),
						10
					);

					const localStorageOrganization = data.organizations.find(
						(o: models.group.Group) => o.id === lsOrganizationId
					);

					const organizationFromUrl = data.organizations.find(
						(o: models.group.Group) => o.id === orgIdFromUrl
					);

					let org = data.organizations[0];

					if (
						organizationFromUrl &&
						organizationFromUrl.id !== state.organization?.id
					) {
						org = organizationFromUrl;
					} else if (localStorageOrganization) {
						org = localStorageOrganization;
					}

					setOrganization(org);
				}
			}
		}
	);

	useComponentDidMount(
		() => {
			resume.on(refresh);
		},
		() => {
			resume.off(refresh);
		}
	);

	const setAccount = (account: models.account.Account) => {
		setState({ account });
	};

	const setOrganization = (organization: models.group.Group) => {
		if (!models.group.isOrganization(organization)) {
			throw new Error('Not an organization!');
		}
		const nextOrganizationId = organization.id;

		ls.setItem(
			ls.LocalStorageKeys.ActiveOrganizationId,
			nextOrganizationId.toString()
		);

		setState({ organization });
	};

	const setOrganizationById = (organizationId: number) => {
		const organization = state.organizations.find(
			(o) => o.id === organizationId
		);

		if (organization) {
			if (!models.group.isOrganization(organization)) {
				throw new Error('Not an organization!');
			}

			setOrganization(organization);
		}
	};

	const setOrganizations = (organizations: models.group.OrganizationData[]) => {
		setState({ organizations });
	};

	const providedState: AccountIdentityContextState = {
		...state,
		setAccount,
		setOrganization,
		setOrganizationById,
		setOrganizations,
		refreshAccountIdentity: refresh,
	};

	if (isLoading && !providedState.account) {
		return <Spinner center />;
	}

	return (
		<AccountIdentityContext.Provider value={providedState}>
			{children}
		</AccountIdentityContext.Provider>
	);
}
