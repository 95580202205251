import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useFilters, FilterGroups } from 'pkg/filters/use_filters';
import { FilterOperator, createFilterGroups } from 'pkg/filters';
import { useCurrentOrganization } from 'pkg/identity';

import { CustomFieldInput } from 'routes/payments/hooks/contact_form_sections/CustomFields';
import { FormattedPrevData } from 'routes/user_fields/custom_categories';

import Section from 'components/form/Section';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';

interface OtherFieldsInputProps {
	prevData: models.user.Field[];
}

export default function OtherFieldsInput({
	prevData = [],
}: OtherFieldsInputProps): JSX.Element {
	const org = useCurrentOrganization();

	const filterGroups: FilterGroups = createFilterGroups({
		[t('Label')]: {
			hidden: true,
			filters: {
				Label: {
					type: 'text',
					operator: FilterOperator.Contains,
					property: 'label',
				},
			},
		},
	});
	const filters = useFilters({
		groups: filterGroups,
	});
	filters.queryParam.set('group_id', org.id.toString());

	const { records: userFields, isLoading } =
		useCollection<models.userFields.UserField>(endpoints.UserField.Index(), {
			queryParams: filters.queryParam,
		});

	const formattedPrevData: FormattedPrevData = {};

	for (const data of prevData) {
		formattedPrevData[data.key] = {
			value: data.values.join(','),
		};

		if (data.attachments) {
			formattedPrevData[data.key].attachments = [...data.attachments];
		}
	}
	const fields = userFields.filter(
		(field) => field.type !== models.userFields.UserFieldType.Reserved
	);

	const uncategorizedFields = fields.filter(
		(field) => !field.userFieldCategoryId
	);

	if (isLoading) {
		return <Spinner />;
	}

	if (uncategorizedFields.length === 0) {
		return null;
	}

	return (
		<Fragment>
			<Section title={t('Other fields')} hideDivider>
				<Column>
					{uncategorizedFields.map((field) => (
						<CustomFieldInput
							key={field.key}
							field={field}
							defaultValue={formattedPrevData[field.key]?.value}
							defaultAttachments={formattedPrevData[field.key]?.attachments}
						/>
					))}
				</Column>
			</Section>
		</Fragment>
	);
}
