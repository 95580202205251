import { JSX, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useQueryParams } from 'pkg/hooks/query-state';
import * as endpoints from 'pkg/api/endpoints/auto';
import { Account } from 'pkg/api/models/account';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as objects from 'pkg/objects';
import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import { cssClasses } from 'pkg/css/utils';
import * as api from 'pkg/api';
import * as actions from 'pkg/actions';

import Title from 'routes/public/styles/Title';
import Button from 'routes/public/styles/Button';
import { useOnboardingContext } from 'routes/public/onboarding';
import * as publicStyles from 'routes/public/styles/styles.css';

import { FormattedContent } from 'components/formatted-content';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

export default function GdprConsent(): JSX.Element {
	const { reset, goTo } = useOnboardingContext();
	const query = useQueryParams();

	const [hasError, setHasError] = useState<boolean>(false);
	const [isRequesting, setRequesting] = useState<boolean>(false);

	const token: string = (query?.token as string) || '';

	const goToLogin = () => {
		goTo('login');
	};

	const {
		record: account,
		isLoading,
		refresh,
	} = useEndpoint<Account>(endpoints.Accounts.ShowParentalConsent(), {
		requestHeaders: {
			'X-Account-Token': token,
		},
	});

	const name = account?.firstName;
	const user_email = account?.email;
	const parent_email = account?.parentalConsentEmail;

	const consentGivenAt = models.account.parentalConsentGrantedAt(account);
	const consentRevokedAt = models.account.parentalConsentRevokedAt(account);

	const [hasConsent, setHasConsent] = useState<boolean>(
		models.account.hasParentalConsent(account) &&
			consentGivenAt > 0 &&
			consentRevokedAt === 0
	);

	const grantParentalConsent = async () => {
		setRequesting(true);

		const [request] = await api.post(
			endpoints.Accounts.GrantParentalConsentWithToken(),
			null,
			{
				headers: {
					'X-Account-Token': token,
				},
			}
		);

		if (request.ok) {
			actions.flashes.show({
				title: t('You have granted permisson!'),
			});

			await refresh();

			setHasConsent(true);
		} else {
			actions.flashes.show({
				title: t(
					'If you copied the link from the email, make sure you copied the whole link without any extra spaces.'
				),
			});

			setHasError(true);
		}

		setRequesting(false);
	};

	const revokeParentalConsent = async () => {
		setRequesting(true);

		const [request] = await api.delete(
			endpoints.Accounts.RemoveParentalConsentWithToken(),
			{
				headers: {
					'X-Account-Token': token,
				},
			}
		);

		if (request.ok) {
			actions.flashes.show({
				title: t('You have granted permisson!'),
			});

			setHasConsent(false);
		} else {
			actions.flashes.show({
				title: t(
					'If you copied the link from the email, make sure you copied the whole link without any extra spaces.'
				),
			});

			setHasError(true);
		}

		setRequesting(false);
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (hasError || objects.empty(account)) {
		return (
			<Column spacing={styles.spacing._8}>
				<Column>
					<Title title={t('We failed to load the request')} />
					<FormattedContent
						raw={t(
							'If you copied the link from the email, make sure you copied the whole link without any extra spaces.'
						)}
						blockClassName={publicStyles.text}
					/>
					<FormattedContent
						raw={t(
							'If your problem persists, please ask your child to re-send a request.'
						)}
						blockClassName={publicStyles.text}
					/>
				</Column>
				<Column>
					<Button
						transparent
						type="button"
						onClick={reset}
						label={t('Go to start')}
					/>
				</Column>
			</Column>
		);
	}

	if (hasConsent) {
		return (
			<Column spacing={styles.spacing._8}>
				<Column>
					<Title title={t('You have granted permisson!')} />
					<p className={cssClasses(publicStyles.gdpr, publicStyles.timestamp)}>
						{t('You granted permission on {date}.', {
							date: new Date(consentGivenAt * 1000).toLocaleString(
								DateTime.getLocale()
							),
						})}
					</p>
				</Column>
				<Column>
					<Button
						primary
						type="button"
						label={t('Sign in')}
						onClick={goToLogin}
					/>
					<Button
						transparent
						type="button"
						busy={isRequesting}
						label={t('Withdraw permission')}
						onClick={revokeParentalConsent}
					/>
				</Column>
			</Column>
		);
	}

	return (
		<Column spacing={styles.spacing._8}>
			<Column>
				<Title title={t('What data do we process?')} />
				<FormattedContent
					raw={t(
						'We try to process as little personal data as we can. To use 360Player we need the following data.'
					)}
					blockClassName={publicStyles.text}
				/>

				<ul className={publicStyles.text}>
					<li>{t('First and last name')}</li>
					<li>{t('Email address')}</li>
					<li>{t('Date of birth')}</li>
				</ul>

				<FormattedContent
					allowedFormatters={['emails']}
					blockClassName={publicStyles.text}
					raw={t(
						`In addition we might collect other contact information for {name} or you. This is entirely optional and is up to you, {name}, and the teams {name} is a member of. Please refer to our Privacy Policy for details on what we collect and how we use it.`,
						{
							name,
						}
					)}
				/>
			</Column>

			<Column>
				<Title title={t(`What happens if I don't give permission?`)} />
				<FormattedContent
					allowedFormatters={['emails']}
					blockClassName={publicStyles.text}
					raw={t(
						`{name} won't be able to use 360Player. If you want to delete the data we have on {name}, email compliance@360player.com from {parent_email} or {user_email}.`,
						{
							name,
							user_email,
							parent_email,
						}
					)}
				/>
			</Column>

			<Column>
				<Title title={t(`What happens after I give permission?`)} />
				<FormattedContent
					blockClassName={publicStyles.text}
					raw={t(
						`{name} will be able to use 360Player and our services. If you want to handle {name}'s permission to use 360Player in the future, bookmark this page.`,
						{
							name,
						}
					)}
				/>
			</Column>

			<div className={cssClasses(publicStyles.gdpr, publicStyles.action)}>
				<Button
					primary
					type="button"
					busy={isRequesting}
					label={t('Grant permission')}
					onClick={grantParentalConsent}
				/>
			</div>
		</Column>
	);
}
