import { JSX } from 'react';

import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import FormSubmissions from 'routes/organization/contacts/single/Submissions';

interface OrganizationUserProfileRegistrationsProps {
	user: models.user.User;
}

export default function OrganizationUserProfileRegistrations({
	user,
}: OrganizationUserProfileRegistrationsProps): JSX.Element {
	const org = useCurrentOrganization();

	return <FormSubmissions groupId={org.id} contactId={user.id} />;
}
