import { Fragment, useState, ReactNode } from 'react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentGroupId } from 'pkg/identity';

import Badge from 'components/Badge';
import MaterialSymbol from 'components/material-symbols';

import Row from 'components/layout/row';

import * as Context from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface MembershipSelectHook {
	selectedMembership: Nullable<models.membership.Membership>;

	MembershipSelect: ReactNode;
}

export default function useMembershipSelect(
	user: models.user.User
): MembershipSelectHook {
	const groupId = useCurrentGroupId();

	const [selectedGroupId, setSelectedGroupId] = useState<number>(groupId);

	const canShowGroups = models.hasAllowedAction(
		user,
		models.Action.UserListGroups
	);

	const { records: memberships, isLoading } =
		useCollection<models.membership.Membership>(
			canShowGroups ? endpoints.UserGroups.Index1(user.id) : null
		);

	const selectedMembership = memberships?.find(
		(membership: models.membership.Membership) =>
			membership.groupId === selectedGroupId
	);

	let MembershipSelect: ReactNode = (
		<Fragment>
			{canShowGroups && memberships.length > 0 && (
				<div className={css.groupSelect}>
					<Context.Menu
						toggleWith={
							<Button>
								<Row columns="1fr auto">
									<span>{selectedMembership?.group.name}</span>
									<MaterialSymbol variant="keyboard_arrow_down" scale={1.5} />
								</Row>
							</Button>
						}>
						{memberships.map((membership: models.membership.Membership) => (
							<Context.Item
								className={css.groupSelectItem}
								onClick={() => setSelectedGroupId(membership.groupId)}>
								<Badge branded badgeUrl={membership.group?.profileImageUrl} />
								<span>{membership.group.name}</span>
								{membership.groupId === selectedGroupId ? (
									<MaterialSymbol variant="check" scale={1.3} />
								) : (
									<span />
								)}
							</Context.Item>
						))}
					</Context.Menu>
				</div>
			)}
		</Fragment>
	);

	if (!canShowGroups || (isLoading && memberships.length === 0)) {
		MembershipSelect = null;
	}

	return {
		selectedMembership,

		MembershipSelect,
	};
}
