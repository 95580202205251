import { AllowedActionable } from 'pkg/api/models/allowed_actionable';
import { Dateable } from 'pkg/api/models/dateable';
import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';

export enum UserGoalCategories {
	General = 'general',
	Mental = 'mental',
	Physical = 'physical',
	Social = 'social',
	Tactical = 'tactical',
	Technical = 'technical',
}

export interface UserGoal
	extends Record,
		Dateable,
		Linkable,
		AllowedActionable {
	name: string;

	userId: number;
	accountId: number;

	category: UserGoalCategories;
	description: string;
	goal: string;
	progress: number;
}
