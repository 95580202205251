import { JSX, PointerEvent } from 'react';

import { cssClasses } from 'pkg/css/utils';

import * as css from './styles.css';

interface EmojiProps {
	raw: string;
	label?: string;
	className?: string;
	onClick?: (event: PointerEvent<HTMLSpanElement>) => void;
}

export function Emoji({
	raw,
	label,
	className,
	...props
}: EmojiProps): JSX.Element {
	return (
		<span
			{...props}
			role="img"
			aria-label={label}
			className={cssClasses(className, css.emoji)}>
			{raw}
		</span>
	);
}
