import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import * as models from 'pkg/api/models';

import SelectDiscountModal from 'routes/payments/discounts/select_modal';

import { useSmallScreen } from 'components/MediaQuery';

import { DiscountFormTable } from 'components/discount/form_table';
import * as Input from 'components/form/inputs';

import Button from 'design/button';

interface DiscountInputProps {
	handleAddDiscount: (discount: models.discount.Discount) => void;
	handleRemoveDiscount: () => void;

	discount?: models.discount.Discount;
}

export default function DiscountInput({
	handleAddDiscount,
	handleRemoveDiscount,
	discount,
}: DiscountInputProps) {
	const [showModal, setShowModal] = useState(false);
	const paymentProviderCtx = usePaymentProviderContext();

	const isSmallScreen = useSmallScreen();

	const handleShowModal = () => setShowModal(true);

	const onCloseDiscountModal = (newDiscount: models.discount.Discount) => {
		setShowModal(false);

		if (newDiscount) {
			handleAddDiscount(newDiscount);
		} else {
			handleRemoveDiscount();
		}
	};

	if (!paymentProviderCtx.settings.canHaveDiscountCode) {
		return null;
	}

	return (
		<Fragment>
			<Input.Group label={t('Add discount')}>
				{discount ? (
					<DiscountFormTable
						discount={discount}
						handleRemoveDiscount={handleRemoveDiscount}
					/>
				) : (
					<div>
						<Button
							label={t('Add discount')}
							secondary
							icon="add"
							block={isSmallScreen}
							onClick={handleShowModal}
						/>
					</div>
				)}
			</Input.Group>
			{showModal && <SelectDiscountModal onClose={onCloseDiscountModal} />}
		</Fragment>
	);
}
