import {
	JSX,
	ChangeEvent,
	PointerEvent,
	ReactNode,
	createContext,
	useContext,
	useState,
} from 'react';
import { t } from '@transifex/native';

import {
	EmojiDefinition,
	EmojiDefinitionGroup,
	EmojiSkinToneVariant,
	getFilteredEmojis,
} from 'pkg/emoji';
import emojis from 'pkg/emoji/emoji-data.json';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { HapticFeedbackImpact, useHapticFeedback } from 'pkg/haptics';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import { cssClasses } from 'pkg/css/utils';
import * as lang from 'pkg/i18n/language';

import Icon from 'components/icon';
import { Emoji } from 'components/emoji';

import * as Input from 'components/form/inputs';

import * as Context from 'design/context_menu';

import * as css from './styles.css';

const DefaultReactions = ['❤️', '😃', '😮', '😢', '😠', '👍'];

const SupportedFilterLanguages = ['en_US', 'en_GB'];

interface EmojiPickerContextState {
	skinToneVariant: EmojiSkinToneVariant;
	setSkinToneVariant?: (skinToneVariant: EmojiSkinToneVariant) => void;
	onSelect: (emoji: string) => void;
}

export const EmojiPickerContext = createContext<EmojiPickerContextState>({
	skinToneVariant: EmojiSkinToneVariant.None,
	onSelect: null,
});

interface EmojiPickerProps {
	toggleWith?: ReactNode;
	customReactions?: string[];

	onSelect: (emoji: string) => void | Promise<void>;
	onPickerOpen?: () => void;
	onPickerClose?: () => void;
}

export default function EmojiPicker({
	toggleWith,
	customReactions,

	onSelect,
	onPickerOpen,
	onPickerClose,
}: EmojiPickerProps): JSX.Element {
	const zIndex = useNewTopIndex();

	const [filter, setFilter] = useState<string>('');
	const [pickerOpen, setPickerOpen] = useState<boolean>(false);
	const [skinToneVariant, setSkinToneVariant] = useState<EmojiSkinToneVariant>(
		EmojiSkinToneVariant.None
	);

	const emitHapticFeedback = useHapticFeedback();

	const handleSetSkinToneVariant = (skinToneVariant: EmojiSkinToneVariant) => {
		setSkinToneVariant(skinToneVariant);

		localStorage.setItem('emoji:skintone:variant', skinToneVariant);
	};

	const handleReaction = (event: PointerEvent<HTMLDivElement>) => {
		event.stopPropagation();

		const reaction = event.currentTarget.querySelector('span').innerHTML;

		onSelect(reaction);

		emitHapticFeedback(HapticFeedbackImpact.Light);
	};

	useComponentDidMount(() => {
		const variant =
			(localStorage.getItem(
				'emoji:skintone:variant'
			) as EmojiSkinToneVariant) || EmojiSkinToneVariant.None;

		if (variant !== EmojiSkinToneVariant.None) {
			setSkinToneVariant(variant);
		}
	});

	const handlePickerOpen = () => {
		if (onPickerOpen) {
			onPickerOpen();
		}

		setPickerOpen(true);
	};

	const handlePickerClose = () => {
		if (onPickerClose) {
			onPickerClose();
		}

		setPickerOpen(false);
	};

	const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
		setFilter(event.currentTarget.value);
	};

	let search = null;

	if (SupportedFilterLanguages.includes(lang.get())) {
		search = (
			<Context.Pane>
				<Input.Field
					small
					placeholder={t('Search emojis...', { _context: 'emoji' })}
					onChange={handleFilter}>
					<Input.Prefix inline>
						<Icon name="search" />
					</Input.Prefix>
				</Input.Field>
			</Context.Pane>
		);
	}

	let results: ReactNode | ReactNode[] = Object.entries(emojis).map(
		([heading, group]) => (
			<EmojiPickerGroup key={heading} heading={heading} group={group} />
		)
	);

	if (filter?.length > 0) {
		results = (
			<EmojiPickerGroup
				heading={t('Search results')}
				group={getFilteredEmojis(filter)}
			/>
		);
	}

	if (toggleWith) {
		return (
			<EmojiPickerContext.Provider
				value={{
					skinToneVariant,
					setSkinToneVariant: handleSetSkinToneVariant,
					onSelect,
				}}>
				<div className={css.inner} style={{ zIndex }}>
					<Context.Menu
						toggleWith={toggleWith}
						onOpen={handlePickerOpen}
						onClose={handlePickerClose}>
						{search}
						{results}
					</Context.Menu>
				</div>
			</EmojiPickerContext.Provider>
		);
	}

	let reactions: string[];

	if (!customReactions) {
		reactions = DefaultReactions;
	} else {
		// @NOTE Only pick six
		reactions = [...customReactions, ...DefaultReactions].slice(0, 6);
	}

	return (
		<EmojiPickerContext.Provider
			value={{
				skinToneVariant,
				setSkinToneVariant: handleSetSkinToneVariant,
				onSelect,
			}}>
			<div className={css.wrapper} style={{ zIndex }}>
				<div
					className={cssClasses(
						css.reactions,
						pickerOpen ? css.pickerToggleOpen : undefined
					)}>
					{reactions.map((emoji: string, index: number) => (
						<div key={index} className={css.reaction} onClick={handleReaction}>
							<span>{emoji}</span>
						</div>
					))}
					<Context.Menu
						onOpen={handlePickerOpen}
						onClose={handlePickerClose}
						toggleClassName={css.reaction}
						toggleWith={
							<span>
								<Icon name="add" />
							</span>
						}>
						{search}
						{results}
					</Context.Menu>
				</div>
			</div>
		</EmojiPickerContext.Provider>
	);
}

interface EmojiPickerGroupProps {
	heading: string;
	group: EmojiDefinitionGroup;
}

function EmojiPickerGroup({
	heading,
	group,
}: EmojiPickerGroupProps): JSX.Element {
	const getTranslatedHeading = (heading: string) => {
		switch (heading) {
			case 'smileys_people':
				return t('Smileys & People', { _context: 'emoji' });
			case 'animals_nature':
				return t('Animals & Nature', { _context: 'emoji' });
			case 'food_drink':
				return t('Food & Drink', { _context: 'emoji' });
			case 'travel_places':
				return t('Travel & Places', { _context: 'emoji' });
			case 'activities':
				return t('Activities', { _context: 'emoji' });
			case 'objects':
				return t('Objects', { _context: 'emoji' });
			case 'symbols':
				return t('Symbols', { _context: 'emoji' });
			case 'flags':
				return t('Flags', { _context: 'emoji' });
			default:
				return heading;
		}
	};

	if (Object.keys(group).length === 0) {
		return (
			<article className={cssClasses(css.group, css.noResults)}>
				😞 {t('No results')}
			</article>
		);
	}

	return (
		<article className={css.group}>
			<div className={css.heading}>{getTranslatedHeading(heading)}</div>
			<div className={css.items}>
				{Object.entries(group).map(
					([slug, emoji]: [string, EmojiDefinition]) => (
						<EmojiPickerItem key={slug} emoji={emoji} />
					)
				)}
			</div>
		</article>
	);
}

interface EmojiPickerItemProps {
	emoji: EmojiDefinition;
}

function EmojiPickerItem({ emoji }: EmojiPickerItemProps): JSX.Element {
	const ctx = Context.useContextMenu();
	const emitHapticFeedback = useHapticFeedback();

	const { skinToneVariant, onSelect } = useContext(EmojiPickerContext);
	const variant = emoji.variants?.[skinToneVariant] || emoji.variants.none;

	const handleSelect = () => {
		if (onSelect) {
			onSelect(variant);
		}

		emitHapticFeedback(HapticFeedbackImpact.Light);

		ctx.close();
	};

	return (
		<Emoji
			raw={variant}
			label={emoji.description}
			className={css.item}
			onClick={handleSelect}
		/>
	);
}
