import { JSX, Fragment } from 'react';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import * as actions from 'pkg/actions';
import {
	FilterOperator,
	createQueryFilter,
	QueryFilter,
	string,
} from 'pkg/filters';
import { useFilters } from 'pkg/filters/use_filters';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';

import SubscriptionTable from 'routes/payments/subscriptions/list';
import OrderList from 'routes/payments/orders/list';
import SideBar from 'routes/payments/products/single/SideBar';
import ContactTableView from 'routes/organization/contacts/list/Table';
import {
	usersFilterConfig,
	getFilters,
} from 'routes/organization/contacts/utils';

import PricingList from 'containers/products/PricingList';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as SideBarLayout from 'components/layout/side-bar-page';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import InfoBox from 'components/form/info-box';
import { Spinner } from 'components/loaders/spinner';
import { useTemplateContext } from 'components/layout/page-templates/context';

import * as css from './styles.css';

interface SingleProductProps {
	productId: number;
	organizationId: number;
}

const SingleProduct = ({
	productId,
	organizationId,
}: SingleProductProps): JSX.Element => {
	const { currentTab } = useTemplateContext();
	const {
		record: product,
		isLoading: isLoadingProduct,
		refresh: refreshProduct,
	} = useEndpoint<models.product.Product>(endpoints.Products.Show(productId));

	const filters = useFilters({
		groups: {
			...getFilters([
				usersFilterConfig().product_status,
				usersFilterConfig().product_valid,
				usersFilterConfig().product_created,
				usersFilterConfig().birth_date,
				usersFilterConfig().archived,
			]),
		},
		filterBarMobileTrigger: true,
	});

	const defaultExportFilters = {
		filters: JSON.stringify([
			{
				property: 'product_ids',
				operator: FilterOperator.Includes,
				values: [productId],
			},
		]),
	};

	const handleRestore = () => {
		const didConfirm = window.confirm(
			t('Are you sure you want to restore this product?')
		);

		if (didConfirm) {
			actions.products.update(product.id, { archived: false });
		}
	};

	const stringifiedFilters = string(filters.currentFilters);
	const parsedFilters: QueryFilter[] = JSON.parse(stringifiedFilters);

	parsedFilters.push(
		createQueryFilter({
			property: 'product_ids',
			operator: FilterOperator.Includes,
			values: [productId],
		})
	);

	const newQueryParams = new URLSearchParams(
		`filters=${JSON.stringify(parsedFilters)}`
	);

	let content;

	switch (currentTab) {
		case 'assigned-contacts':
			content = (
				<SideBarLayout.Table>
					<ContactTableView
						organizationId={organizationId}
						filters={filters}
						queryParams={newQueryParams}
						defaultExportFilters={defaultExportFilters}
					/>
				</SideBarLayout.Table>
			);
			break;

		case 'purchase-history':
			content = (
				<SideBarLayout.Table>
					<OrderList organizationId={organizationId} productId={productId} />
				</SideBarLayout.Table>
			);
			break;

		case 'subscriptions':
			content = (
				<SideBarLayout.Table>
					<SubscriptionTable
						organizationId={organizationId}
						productId={productId}
					/>
				</SideBarLayout.Table>
			);
			break;

		default:
			content = (
				<SideBarLayout.Content>
					<LargeScreenContent.Inner maxWidth={PageWidths.WIDE}>
						<PricingList
							prices={product.prices}
							productId={product.id}
							archived={!!product.archivedAt}
							refreshProduct={refreshProduct}
						/>
					</LargeScreenContent.Inner>
				</SideBarLayout.Content>
			);
			break;
	}

	if (isLoadingProduct) {
		return <Spinner />;
	}

	return (
		<Fragment>
			{product.archivedAt && (
				<InfoBox color="orange">
					<span>
						{t('This product is archived, to be able to edit -')}{' '}
						<span className={css.active} onClick={handleRestore}>
							{t('Re-activate product')}
						</span>
					</span>
				</InfoBox>
			)}
			<SideBarLayout.Layout>
				<LargeScreen>
					<SideBar product={product} />
				</LargeScreen>
				<SmallScreen>
					{currentTab === 'overview' && <SideBar product={product} />}
				</SmallScreen>

				{content}
			</SideBarLayout.Layout>
		</Fragment>
	);
};

export default SingleProduct;
