import { t } from '@transifex/native';
import {
	JSX,
	useEffect,
	useState,
	MouseEvent,
	Fragment,
	ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Video from 'pkg/models/video';
import VideoSequence from 'pkg/models/video_sequence';
import VideoPlaylistSequence from 'pkg/models/video_playlist_sequence';

import * as json from 'pkg/json';
import { cuePointsFromPlaylistSequences } from 'pkg/video';
import * as selectors from 'pkg/selectors';
import * as actions from 'pkg/actions';
import { RootState } from 'pkg/reducers';
import { useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';

import Clip from 'routes/video/shared/clips/Clip';
import ClipList from 'routes/video/shared/clips/List';
import ClipGroup from 'routes/video/shared/clips/Group';
import ClipDiscussion from 'routes/video/shared/ClipDiscussion';

import Icon from 'components/icon';
import * as StepModal from 'components/step-modal';

import * as Sortable from 'components/dnd/sortable';
import PlaylistModal from 'components/video-library/modals/Playlist';
import { usePlayerState } from 'components/video-analytics/PlayerState';
import { useCueState } from 'components/video-analytics/CueState';
import { usePlaybackState } from 'components/video-analytics/PlaybackState';
import { FilterState } from 'components/video-analytics/FilterState';
import Row from 'components/layout/row';
import { useClipState } from 'components/video-analytics/ClipState';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';

import * as css from './style.css';

const Wrapper = styled.div`
	margin-top: var(--spacing-1);
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-5);
`;

const Actions = styled.div`
	margin-bottom: var(--spacing-3);
`;

interface ClipItemProps {
	item: VideoPlaylistSequence;
	video: Video;
	clip: VideoSequence;
}

function ClipItem({ item, video, clip }: ClipItemProps): JSX.Element {
	const { setSource, controller } = usePlayerState();
	const { cueActive, currentCue, setCueActive, setCueId } = useCueState();
	const { setCurrentTime } = usePlaybackState();
	const clipState = useClipState();

	const numComments: number = useSelector((state: RootState) =>
		selectors.videoSequenceComments.findAllBySequenceId(state, clip.id)
	).size;

	const canComment: boolean = video.hasIn([
		'links',
		'create:video_sequence_comment',
	]);

	const [addModal, setAddModal] = useState<boolean>(false);
	const [discussionModal, setDiscussionModal] = useState<boolean>(false);

	const setVideoSource = () => {
		setSource(video.uri, {
			startsAt: clip.getStartsAt(),
		});

		setCurrentTime(clip.getStartsAt());
		controller.seekTo(clip.getStartsAt(), true);

		setCueId(clip.id);
		setCueActive(true);

		if (clip.annotations) {
			const annotations = json.parse(clip.annotations);

			if (annotations) {
				clipState.setAnnotations(annotations);
			}
		} else {
			clipState.setAnnotations(null);
		}
	};

	const addToPlaylist = () => setAddModal(true);

	const openDiscussionModal = async () => {
		setDiscussionModal(true);

		await controller.pause();
	};

	const closeDiscussionModal = async () => {
		setDiscussionModal(false);

		await controller.play();
	};

	const isActive = cueActive && currentCue?.cueId === clip.id;

	return (
		<Fragment>
			<Clip
				compact={isActive}
				title={clip.title}
				subTitle={video.title}
				startsAt={clip.getStartsAt()}
				endsAt={clip.getEndsAt()}
				isActive={isActive}
				isPrivate={clip.private}
				isHighlight={clip.reviewed}
				description={clip.description}
				thumbnailUrl={!isActive && video.thumbnailUrl}
				onClick={setVideoSource}
				onCommentClick={openDiscussionModal}
				numComments={numComments}
				canComment={canComment}
				renderActionWith={
					<ClipContextMenu
						playlistSequence={item}
						onAddToPlaylist={addToPlaylist}
					/>
				}
			/>
			{discussionModal && (
				<StepModal.Base onClose={closeDiscussionModal}>
					<StepModal.Step
						title={t('Clip comments')}
						hidePrev
						nextLabel={t('Close')}>
						<ClipDiscussion />
					</StepModal.Step>
				</StepModal.Base>
			)}
			{addModal && (
				<PlaylistModal clipIds={[clip.id]} onClose={() => setAddModal(false)} />
			)}
		</Fragment>
	);
}

const ContextMenuWrapper = styled.div`
	width: 20px;
	height: 20px;
	position: relative;
	top: 2px;
`;

interface ClipContextMenuProps {
	playlistSequence: VideoPlaylistSequence;
	onAddToPlaylist: (clipId: number) => void;
}
function ClipContextMenu({
	playlistSequence,
	onAddToPlaylist,
}: ClipContextMenuProps): JSX.Element {
	const playlist = useSelector((state: RootState) =>
		selectors.videoPlaylists.find(state, playlistSequence.videoPlaylistId)
	);

	const membership = useCurrentMembership();

	const canRemoveClips = playlist.hasIn(['links', 'create:video_sequence']);
	const canAddtoPlaylist: boolean = !models.membership.isParent(membership);

	const handleRemove = async (): Promise<void> => {
		await actions.videoPlaylists.removeSequences(
			playlistSequence.videoPlaylistId,
			playlistSequence.videoSequenceId
		);
	};

	const disableEventBubble = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
	};

	const handleAddtoPlaylist = () =>
		onAddToPlaylist(playlistSequence.videoSequenceId);

	if (!canRemoveClips) {
		return null;
	}

	return (
		<ContextMenuWrapper onClick={disableEventBubble}>
			<ContextMenu.Menu toggleWith={<Icon name="context-menu" />}>
				{canAddtoPlaylist && (
					<ContextMenu.Item onClick={handleAddtoPlaylist}>
						<Icon name="playlist-add" />
						<span>{t('Add to playlist')}</span>
					</ContextMenu.Item>
				)}
				{canRemoveClips && (
					<ContextMenu.ConfirmItem
						caution
						closeOnClick
						message={t('Are you sure you want to remove this clip?')}
						onConfirm={handleRemove}>
						<Icon name="block" />
						{t('Remove from playlist')}
					</ContextMenu.ConfirmItem>
				)}
			</ContextMenu.Menu>
		</ContextMenuWrapper>
	);
}

interface ClipsProps {
	playlistId: number;
	filters: FilterState;
}

export default function Clips({
	playlistId,
	filters,
}: ClipsProps): JSX.Element {
	const cueState = useCueState();
	const playerState = usePlayerState();
	const clipState = useClipState();

	const playlist = useSelector((state: RootState) =>
		selectors.videoPlaylists.find(state, playlistId)
	);

	const filteredSequences = useSelector((state: RootState) =>
		selectors.videoPlaylists.findAllFilteredSequences(
			state,
			playlistId,
			filters
		)
	);

	const [orderedSequences, setOrderedSequences] = useState<
		VideoPlaylistSequence[]
	>(filteredSequences.toArray());

	const setNewOrderedSequences = (sequences: VideoPlaylistSequence[]) => {
		setOrderedSequences(sequences);
	};

	const onCuePointsChange = async () => {
		const cues = cuePointsFromPlaylistSequences(filteredSequences);
		const firstSequence: VideoPlaylistSequence = filteredSequences.first();

		cueState.setCuePoints(cues);
		cueState.setCueActive(true);

		if (firstSequence) {
			playFromFirstCue();
		}
	};

	useEffect(() => {
		setOrderedSequences(filteredSequences.toArray());
		onCuePointsChange();
	}, [filters]);

	const playFromFirstCue = async () => {
		if (filteredSequences.size > 0 && playerState.controller) {
			const firstSequence: VideoPlaylistSequence = filteredSequences.first();

			cueState.setCueActive(true);
			cueState.setCueId(Number.parseInt(firstSequence.id, 10));
			cueState.setCueIndex(0);

			if (firstSequence.videoSequence.annotations) {
				clipState.setAnnotations(
					firstSequence.videoSequence.getAnnotationsObject()
				);
			}

			if (!playerState.isRearranging) {
				const startsAt = firstSequence.videoSequence.getStartsAt();

				if (playerState.sourceUrl !== firstSequence.video.uri) {
					await playerState.setSource(firstSequence.video.uri, {
						startsAt,
					});
				}

				await playerState.controller.seekTo(startsAt, true);
			}
		}
	};

	const changeSequenceOrder = () => {
		playerState.setRearranging(true);
	};

	const cancelSequenceOrder = () => {
		playerState.setRearranging(false);

		setOrderedSequences(filteredSequences.toArray());
	};

	const updateSequenceOrder = async () => {
		playerState.setRearranging(false);

		await actions.videoPlaylists.updateSequenceOrders(
			playlistId,
			orderedSequences.map(({ videoSequenceId }) => videoSequenceId)
		);
	};

	return (
		<Wrapper>
			<ClipList>
				<Actions>
					{playerState.isRearranging ? (
						<Row>
							<Button small onClick={cancelSequenceOrder}>
								{t('Cancel')}
							</Button>
							<Button small primary onClick={updateSequenceOrder}>
								{t('Save')}
							</Button>
						</Row>
					) : (
						<Row columns="1fr auto">
							<Button small icon="play_arrow" onClick={playFromFirstCue}>
								{t('Play all')}
							</Button>
							{playlist.hasIn(['links', 'edit']) && (
								<Button
									small
									transparent
									icon="playlist_play"
									onClick={changeSequenceOrder}>
									{t('Edit clip order')}
								</Button>
							)}
						</Row>
					)}
				</Actions>
				<ClipGroup>
					{playerState.isRearranging ? (
						<DraggableClipList
							sequences={orderedSequences}
							onReorder={setNewOrderedSequences}
						/>
					) : (
						<Fragment>
							{orderedSequences.map((item: VideoPlaylistSequence) => (
								<ClipItem
									key={item.id}
									video={item.get('video')}
									clip={item.get('videoSequence')}
									item={item}
								/>
							))}
						</Fragment>
					)}
				</ClipGroup>
			</ClipList>
		</Wrapper>
	);
}

const DraggableClipItem: React.FC<
	React.PropsWithChildren<{ item: any; dragElement?: JSX.Element }>
> = ({ item, dragElement }) => {
	const clip = item.get('videoSequence');
	const video = item.get('video');

	return (
		<Clip
			isDraggable
			title={clip.title}
			subTitle={video.title}
			startsAt={clip.startsAt}
			endsAt={clip.endsAt}
			isPrivate={clip.private}
			isHighlight={clip.reviewed}
			thumbnailUrl={video.thumbnailUrl}
			renderActionWith={dragElement}
		/>
	);
};

interface DraggableClipListProps {
	sequences: VideoPlaylistSequence[];
	onReorder: (list: VideoPlaylistSequence[]) => void;
}

function DraggableClipList({
	sequences,
	onReorder,
}: DraggableClipListProps): JSX.Element {
	const theme: Sortable.Theme = {
		item: css.item,
		activeItem: css.active,
		overlayItem: css.overlay,
	};

	const handleChange = (list: VideoPlaylistSequence[]) => {
		onReorder(list);
	};

	const renderItem = (item: VideoPlaylistSequence): ReactNode => {
		return (
			<Sortable.Item id={item.id}>
				<DraggableClipItem
					item={item}
					dragElement={
						<div className={css.handle}>
							<Icon name="drag-handle" size={1.5} />
						</div>
					}
				/>
			</Sortable.Item>
		);
	};

	return (
		<Sortable.Container
			items={sequences}
			renderWith={renderItem}
			onChange={handleChange}
			theme={theme}
		/>
	);
}
