import { ChangeEvent, useState } from 'react';
import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';
import * as actions from 'pkg/actions';

import Row from 'components/layout/row';
import useAttachments from 'components/attachment/hooks/useAttachments';

import Editor from 'design/editor';
import Button from 'design/button';

import * as css from './styles.css';

interface UserLogCreateFormProps {
	user: models.user.User;

	onCreate: (userLog: models.userLog.UserLog) => void;
}

export default function UserLogCreateForm({
	user,
	onCreate,
}: UserLogCreateFormProps): JSX.Element {
	const t = useT();
	const group = useCurrentGroup();
	const [content, setContent] = useState<string>();

	const {
		FileUploader,
		AttachmentItems,
		allAttachmentsUploaded,
		newAttachments,
		reset,
	} = useAttachments({
		hideButtonLabel: true,
		buttonIcon: 'attachment',
	});

	const handleContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setContent(event.target.value);
	};

	const handleCreate = async () => {
		const userLog = await actions.userLogs.create(user, group, {
			userId: user.id,
			groupId: group.id,
			content,
		});

		if (userLog && newAttachments.length > 0) {
			await actions.userLogs.addAttachments(userLog, newAttachments);

			// Add them manually so the changes are reflected in the frontend instead of refreshing entire collection.
			userLog.attachments = newAttachments;
		}

		onCreate(userLog);

		setContent('');
		reset();
	};

	const hasContent: boolean = content?.length > 0;
	const canSend = hasContent && allAttachmentsUploaded;

	return (
		<Editor
			value={content}
			onChange={handleContentChange}
			placeholder={t('Add a note…')}
			innerContent={AttachmentItems}>
			<Row columns="auto 1fr auto">
				<div className={css.formFileInput}>{FileUploader}</div>
				<div />
				<Button primary onClick={handleCreate} disabled={!canSend}>
					{t('Add note')}
				</Button>
			</Row>
		</Editor>
	);
}
