import { JSX } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import rgba from 'pkg/rgba';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { useNewTopIndex } from 'pkg/hooks/useTopIndex';
import { crash } from 'pkg/errors/errors';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import Row from 'components/layout/row';

import Button from 'design/button';

const Wrapper = styled.div`
	position: fixed;
	background: ${rgba(styles.palette.black, 0.8)};
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	overflow: hidden;
	display: grid;
	place-items: center;
`;

const Container = styled.div`
	background: var(--palette-gray-800);
	color: var(--palette-white);
	padding: var(--spacing-7);
	padding-top: var(--spacing-8);
	width: 90vw;
	max-width: 420px;
	border-radius: var(--radius-5);
	display: grid;
	place-items: center;
	gap: var(--spacing-7);

	strong {
		margin-bottom: var(--spacing-3);
		display: block;
	}

	span {
		line-height: 1.5;
	}
`;

const IconWrapper = styled.div`
	height: 50px;

	svg {
		width: 100%;
		height: 100%;
	}
`;

export default function ErrorOverlay(): JSX.Element {
	const group = useCurrentGroup();
	const org = useCurrentOrganization();
	const zIndex = useNewTopIndex();

	const goToLibrary = () => {
		replaceState(routes.VideoLibrary.Index(org.id, group.id));
	};

	return (
		<Wrapper style={{ zIndex }}>
			<Container>
				<IconWrapper>
					<Icon name="error" />
				</IconWrapper>
				<span>
					<strong>{crash().title}</strong>
					{t(
						`We're sorry, but it seems this video could not be loaded properly.`
					)}
				</span>
				<Row>
					<Button transparent onClick={goToLibrary}>
						{t('Go to video library')}
					</Button>
				</Row>
			</Container>
		</Wrapper>
	);
}
