import { T, useT } from '@transifex/react';
import { JSX, Fragment, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useDispatch } from 'react-redux';

import * as styles from 'pkg/config/styles';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import supportLinks from 'pkg/support/links';
import { cssVarList } from 'pkg/css/utils';
import { useCurrentAccount } from 'pkg/identity';

import { useUserGoalModal } from 'routes/organization/user-profile/development/user-goals/modal';

import Section from 'components/section';
import MaterialSymbol from 'components/material-symbols';
import AssetImage from 'components/AssetImage';
import Label from 'components/label';
import { FormattedContent } from 'components/formatted-content';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import MissingEntities from 'design/placeholders/block';
import * as Card from 'design/card';
import Button from 'design/button';
import * as Context from 'design/context_menu';
import { useDialog } from 'design/dialog';

import * as css from './styles.css';

interface UserGoalCategoriesHook {
	list: Record<string, string>;
	entries: [string, string][];
	translated: (key: string) => string;
}

export function useUserGoalCategories(): UserGoalCategoriesHook {
	const t = useT();

	const list: Record<string, string> = {
		general: t('General'),
		mental: t('Mental'),
		physical: t('Physical'),
		social: t('Social'),
		tactical: t('Tactical'),
		technical: t('Technical'),
	};

	const entries = Object.entries(list);

	const translated = (key: string): string => {
		if (list.hasOwnProperty(key)) {
			return list[key];
		}

		return t('Unknown');
	};

	return {
		list,
		entries,
		translated,
	};
}

interface UserGoalsProps {
	user: models.user.User;
}

export default function UserGoals({ user }: UserGoalsProps): JSX.Element {
	const t = useT();
	const account = useCurrentAccount();
	const dispatch = useDispatch();

	const [showingCompleted, setShowingCompleted] = useState<boolean>(false);

	const {
		records: userGoals,
		removeRecord,
		isLoading,
		refresh,
		pagination,
	} = useCollection<models.userGoal.UserGoal>(
		endpoints.UserGoals.UserIndex(user?.id),
		{
			showAllResults: true,
			queryParams: new URLSearchParams({
				'include-completed': '1',
			}),
		}
	);

	const canCreate = models.hasAllowedAction(
		user,
		models.Action.UserCreateUserGoal
	);

	const getForAccountId = (
		userGoal: models.userGoal.UserGoal
	): Nullable<number> => {
		const isForAccount =
			userGoal.userId !== user.id && user.accountId !== account?.id;

		const forAccountId = isForAccount ? user.accountId : null;

		return forAccountId;
	};

	const handleSaveUserGoal = async (
		userGoal: models.userGoal.UserGoal,
		refreshAfterSave: boolean = true
	) => {
		const canUpdate = models.hasAllowedAction(
			userGoal,
			models.Action.UserGoalUpdate
		);

		const forAccountId = getForAccountId(userGoal);

		if (userGoal?.id && canUpdate) {
			await dispatch(actions.userGoals.updateGoal(userGoal, forAccountId));
		} else if (canCreate) {
			await dispatch(
				actions.userGoals.createGoal(user.id, userGoal, forAccountId)
			);
		}

		userGoalModal.close();

		if (refreshAfterSave) {
			await refresh();
		}
	};

	const handleDeleteUserGoal = async (userGoal: models.userGoal.UserGoal) => {
		const canDelete = models.hasAllowedAction(
			userGoal,
			models.Action.UserGoalRemove
		);

		if (!canDelete || !userGoal?.id) return;

		const forAccountId = getForAccountId(userGoal);

		await dispatch(actions.userGoals.removeGoal(userGoal, forAccountId));

		removeRecord(userGoal.id);
	};

	const userGoalModal = useUserGoalModal(handleSaveUserGoal);

	const handleCreateUserGoal = () => {
		userGoalModal.open(null);
	};

	const handleEditUserGoal = (userGoal: models.userGoal.UserGoal) => {
		userGoalModal.open(userGoal);
	};

	if (isLoading) {
		return null;
	}

	if (!isLoading && userGoals.length === 0) {
		return (
			<Fragment>
				<MissingEntities
					noBorder
					helpUrl={supportLinks.userGoals}
					title={t('Set up goals to track individual progress!')}
					description={t(
						'With individual goals you can track progress in an easy and structured way.'
					)}
					actions={
						canCreate && (
							<Button
								icon="add"
								secondary
								onClick={handleCreateUserGoal}
								label={t('Create new goal', {
									_context: 'user profile development',
								})}
							/>
						)
					}
					image={<AssetImage src="img/missing-entities/goals.svg" />}
				/>
				{userGoalModal.Component}
			</Fragment>
		);
	}

	const activeGoals = userGoals.filter((ug) => ug.progress < 100);

	const completedGoals = userGoals.filter((ug) => ug.progress === 100);

	return (
		<Fragment>
			<Section
				title={t('Active goals')}
				action={
					canCreate && (
						<Button inline icon="add" onClick={handleCreateUserGoal}>
							{t('Add goal')}
						</Button>
					)
				}>
				<Column>
					{activeGoals.map((userGoal) => (
						<UserGoal
							key={userGoal.id}
							userGoal={userGoal}
							onEdit={handleEditUserGoal}
							onUpdate={handleSaveUserGoal}
							onDelete={handleDeleteUserGoal}
						/>
					))}
					{(showingCompleted || activeGoals.length === 0) && (
						<Fragment>
							{completedGoals.map((userGoal) => (
								<UserGoal
									key={userGoal.id}
									userGoal={userGoal}
									onEdit={handleEditUserGoal}
									onUpdate={handleSaveUserGoal}
									onDelete={handleDeleteUserGoal}
								/>
							))}
						</Fragment>
					)}
				</Column>
				<Fragment>
					{!showingCompleted && (
						<div>
							<Button inline onClick={() => setShowingCompleted(true)}>
								{t('Show completed goals ({num})', {
									num: completedGoals.length,
								})}
							</Button>
						</div>
					)}
					{showingCompleted && pagination.hasNext && (
						<div className={css.loadMore}>
							<Button primary onClick={pagination.fetchNext}>
								{t('Fetch more user goals')}
							</Button>
						</div>
					)}
				</Fragment>
			</Section>
			{userGoalModal.Component}
		</Fragment>
	);
}

interface UserGoalProps {
	userGoal: models.userGoal.UserGoal;

	onEdit: (userGoal: models.userGoal.UserGoal) => void;
	onUpdate: (
		userGoal: models.userGoal.UserGoal,
		refreshAfterSave?: boolean
	) => void;
	onDelete: (userGoal: models.userGoal.UserGoal) => void;
}

function UserGoal({
	userGoal,
	onEdit,
	onUpdate,
	onDelete,
}: UserGoalProps): JSX.Element {
	const t = useT();

	const isCompleted = userGoal.progress === 100;

	const canUpdate = models.hasAllowedAction(
		userGoal,
		models.Action.UserGoalUpdate
	);

	const canDelete = models.hasAllowedAction(
		userGoal,
		models.Action.UserGoalRemove
	);

	const canShowActions = canUpdate || canDelete;

	const [open, setOpen] = useState<boolean>(false);

	const toggleOpen = () => setOpen(!open);

	const handleEdit = () => onEdit(userGoal);

	const currentVariant = open ? 'active' : 'inactive';

	const handleDelete = useDialog({
		destructive: true,
		wide: true,
		symbol: 'delete',
		title: t('Delete this goal?'),
		message: (
			<T
				_str="You are about to delete {goal}, this action cannot be undone."
				goal={<strong>{userGoal?.goal}</strong>}
			/>
		),
		confirmLabel: t('Delete goal'),
		onConfirm: () => {
			onDelete(userGoal);
		},
	});

	const handleDecrement = () => {
		if (userGoal.progress === 0) return;

		if (userGoal.progress >= 10) {
			userGoal.progress -= 10;
		}

		onUpdate(userGoal, false);
	};

	const handleIncrement = () => {
		if (userGoal.progress === 100) return;

		if (userGoal.progress < 100) {
			userGoal.progress += 10;
		}

		onUpdate(userGoal, false);
	};

	const handleToggleComplete = (progress: number) => {
		userGoal.progress = progress;

		onUpdate(userGoal, false);
	};

	const transition = {
		type: 'tween',
		ease: 'linear',
		duration: 0.2,
		delay: 0,
	};

	const toggleVariants = {
		active: {
			rotate: 180,
		},
		inactive: {
			rotate: 0,
		},
	};

	const descriptionVariants = {
		active: {
			height: 'auto',
			opacity: 1,
		},
		inactive: {
			height: 0,
			opacity: 0,
		},
	};

	const progressActionVariants = {
		active: {
			scale: 1,
			opacity: 1,
		},
		inactive: {
			scale: 0,
			opacity: 0,
		},
	};

	const progressBarVariants = {
		active: {
			x: 0,
		},
		inactive: {
			x: -25,
		},
	};

	const progressValueVariants = {
		active: {
			x: 0,
		},
		inactive: {
			x: -50,
		},
	};

	const categoryColor: Record<string, string> = {
		general: 'gray',
		tactical: 'green',
		technical: 'orange',
		physical: 'blue',
		mental: 'purple',
		social: 'yellow',
	};

	const translatedGoalCategory = (category: string): string => {
		switch (category) {
			case 'general':
				return t('General');
			case 'mental':
				return t('Mental');
			case 'physical':
				return t('Physical');
			case 'social':
				return t('Social');
			case 'tactical':
				return t('Tactical');
			case 'technical':
				return t('Technical');
		}
	};

	return (
		<Card.Base $noBorder className={css.userGoal}>
			<Card.Body>
				<Column>
					<Row
						columns="auto 30px 1fr auto"
						align="center"
						spacing={styles.spacing._3}>
						<motion.div
							className={css.collapseToggle}
							onClick={toggleOpen}
							transition={transition}
							variants={toggleVariants}
							animate={currentVariant}>
							<MaterialSymbol variant="keyboard_arrow_down" scale={1.7} />
						</motion.div>
						<Input.Control
							type="checkbox"
							standalone
							large
							rounded
							checked={isCompleted}
							onChange={handleToggleComplete}
							value={isCompleted ? 0 : 100}
						/>
						<strong>{userGoal.goal}</strong>
						{canShowActions && (
							<Context.Menu
								toggleWith={<Button secondary small icon="more_horiz" />}>
								{canUpdate && (
									<Context.Item icon="edit_square" onClick={handleEdit}>
										{t('Edit goal')}
									</Context.Item>
								)}
								{canDelete && canDelete && <Context.Divider />}
								{canDelete && (
									<Context.Item caution icon="delete" onClick={handleDelete}>
										{t('Remove goal')}
									</Context.Item>
								)}
							</Context.Menu>
						)}
					</Row>
					<AnimatePresence>
						{open ? (
							<motion.div
								style={{ overflow: 'hidden' }}
								key="user-goal-description"
								transition={transition}
								initial="inactive"
								animate="active"
								exit="inactive"
								variants={descriptionVariants}>
								<FormattedContent raw={userGoal.description} />
							</motion.div>
						) : null}
						<article className={css.userGoalData}>
							<Row columns="auto 1fr auto auto" align="center">
								<motion.span
									initial="inactive"
									className={css.progressAction}
									transition={transition}
									variants={progressActionVariants}
									animate={currentVariant}
									onClick={handleDecrement}>
									–
								</motion.span>
								<motion.div
									initial="inactive"
									className={css.progressBar}
									transition={transition}
									variants={progressBarVariants}
									animate={currentVariant}>
									<div
										className={css.progressBarValue}
										style={cssVarList({ progress: `${userGoal.progress}%` })}
									/>
								</motion.div>
								<motion.span
									initial="inactive"
									className={css.progressAction}
									transition={transition}
									variants={progressActionVariants}
									animate={currentVariant}
									onClick={handleIncrement}
									aria-disabled={userGoal.progress === 100}>
									+
								</motion.span>
								<motion.var
									transition={transition}
									variants={progressValueVariants}
									animate={currentVariant}
									initial="inactive"
									className={css.progressLabel}>
									{userGoal.progress}%
								</motion.var>
							</Row>
							<Row columns="auto" justifyContent="end">
								<Label color={categoryColor[userGoal.category]}>
									{translatedGoalCategory(userGoal.category)}
								</Label>
							</Row>
						</article>
					</AnimatePresence>
				</Column>
			</Card.Body>
		</Card.Base>
	);
}
