import { JSX, useEffect } from 'react';

import { usePrevious } from 'pkg/hooks/component-lifecycle';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useAccountIdentity } from 'pkg/identity/account';

export function OrganizationChangeObserver(): JSX.Element {
	const route = useCurrentRoute();
	const { setOrganizationById } = useAccountIdentity();

	const prevOrgId = usePrevious<number>(route?.organizationId ?? 0);
	const nextOrgId = route?.organizationId ?? 0;
	const orgIdDidChange = !!(nextOrgId !== 0 && prevOrgId !== nextOrgId);

	useEffect(() => {
		if (orgIdDidChange) {
			setOrganizationById(nextOrgId);
		}
	}, [orgIdDidChange]);

	return null;
}
