import { JSX, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import {
	generatePath,
	extractPoints,
} from 'components/annotations/AnnotationHooks';

const SVG = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;

	.joint {
		pointer-events: all !important; // shame!
		cursor: move !important; // todo: unshame!
	}
`;

interface SpineProps {
	path?: any;
	multipointRef?: any;
	closed?: boolean;
	filled?: boolean;
	drawing?: boolean;
	updateKeyframe?: any;
}

export default function Spine({
	path,
	multipointRef,
	closed,
	filled,
	drawing,
	updateKeyframe,
}: SpineProps): JSX.Element {
	const ref = useRef<SVGSVGElement>(null);
	const mouse = useMousePosition();
	const svg: any = ref?.current?.getBoundingClientRect();
	const mouseLeft = ((mouse.x - svg?.x) / svg?.width) * 100;
	const mouseTop = ((mouse.y - svg?.y) / svg?.height) * 100;

	// get path from current annotation
	if (multipointRef?.current) path = multipointRef?.current?.getAttribute('d');

	if (svg && drawing) {
		// draw cursor point
		path += 'L' + mouseLeft + ' ' + mouseTop;
	}

	const joints: any = !drawing && {};
	const [isMovingJoint, setIsMovingJoint] = useState<any>();
	if (!drawing) {
		// extract points
		extractPoints(path).forEach((point: any, i: number) => {
			const [left, top] = point.split(' ');
			joints[i] =
				isMovingJoint === i
					? // use mouse position for current joint
						{
							left: mouseLeft / 100,
							top: mouseTop / 100,
						}
					: // otherwise use extracted point
						{ left: +left / 100, top: +top / 100 };
		});

		// scrub raised path
		path = path.replaceAll(/Q\d+\.?\d* \d+\.?\d* /g, 'L');

		// replace path while moving a joint
		if (isMovingJoint !== undefined) {
			path = generatePath(joints);
		}
	}

	// closed path?
	if (svg && closed) path += 'Z';

	const saveJoint = () => {
		updateKeyframe({ points: joints });
		setIsMovingJoint(undefined);
	};

	return (
		<SVG
			ref={ref}
			viewBox="0 0 100 100"
			preserveAspectRatio="none"
			xmlns="http://www.w3.org/2000/SVG">
			<defs>
				<marker refX="1.5" refY="1.9" id="joint" markerUnits="userSpaceOnUse">
					<rect
						x="1"
						y="1"
						width="1"
						height="1.8"
						fill="white"
						stroke="#509af8"
						strokeWidth="1"
						vectorEffect="non-scaling-stroke"
					/>
				</marker>
			</defs>
			<path
				d={svg && path}
				stroke="#509af8"
				strokeWidth="1"
				vectorEffect="non-scaling-stroke"
				fill={filled ? '#509af833' : 'none'}
				markerStart="url(#joint)"
				markerMid="url(#joint)"
				markerEnd={drawing ? 'none' : 'url(#joint)'}
			/>

			{joints &&
				Object.values(joints).map((j: any, i: number) => (
					<motion.rect
						key={i}
						x={j.left * 100 - 1}
						y={j.top * 100 - 1.8}
						width="2"
						height="3.6"
						fill="none"
						className="joint"
						pointerEvents="all"
						onPanStart={() => {
							setIsMovingJoint(i);
						}}
						onPanEnd={saveJoint}
					/>
				))}
		</SVG>
	);
}

// todo: move somewhere global?
const useMousePosition = () => {
	const [position, setPosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		const onMove = (e: MouseEvent) =>
			setPosition({ x: e.clientX, y: e.clientY });
		window.addEventListener('mousemove', onMove);

		return () => {
			window.removeEventListener('mousemove', onMove);
		};
	}, []);

	return position;
};
