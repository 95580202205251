import { t } from '@transifex/native';
import { JSX, Fragment, ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import DateTime from 'pkg/datetime';

import { useOrderReport } from 'routes/payments/overview/hooks';

import Row from 'components/layout/row';

import * as css from './styles.css';

interface WidgetProps {
	children: ReactNode | ReactNode[];
}

export function Widget({ children }: WidgetProps): JSX.Element {
	const { isLoading } = useOrderReport();

	return (
		<article className={css.widget} aria-busy={isLoading}>
			<AnimatePresence>
				{isLoading && (
					<motion.div
						transition={{ duration: 0.2 }}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className={css.loading}>
						<motion.div
							transition={{ duration: 0.2 }}
							initial={{ y: 20, opacity: 0 }}
							animate={{ y: 0, opacity: 1 }}
							exit={{ y: -20, opacity: 0 }}>
							<span className={css.label}>
								{t('Fetching data...', { _context: 'payments dashboard' })}
							</span>
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
			<div className={css.widget__content}>{children}</div>
		</article>
	);
}

interface DateSpanFooterProps {
	granularity: 'months' | 'weeks' | 'days';
}

export function DateSpanFooter({
	granularity,
}: DateSpanFooterProps): JSX.Element {
	const { startOfRange, endOfRange } = useOrderReport();

	const startDate = DateTime.fromTimestamp(startOfRange);
	const endDate = DateTime.fromTimestamp(endOfRange);

	const formattedStartDate =
		granularity === 'months'
			? startDate.toLocaleDateString({ year: 'numeric', month: 'short' })
			: startDate.toDateString();

	let formattedEndDate =
		granularity === 'months'
			? endDate.toLocaleDateString({ year: 'numeric', month: 'short' })
			: endDate.toDateString();

	if (DateTime.isToday(endDate.getTimestamp())) {
		formattedEndDate = t('Today');
	}

	return (
		<Row columns="auto 1fr auto">
			<time dateTime={startDate.toISODateString()}>{formattedStartDate}</time>
			<span />
			<time dateTime={endDate.toISODateString()}>{formattedEndDate}</time>
		</Row>
	);
}

export function DateSpanLabel(): JSX.Element {
	const { startOfRange, endOfRange } = useOrderReport();

	const isToday = DateTime.isToday(endOfRange * 1000);
	const startDate = DateTime.fromTimestamp(startOfRange).toDateString();
	const endDate = DateTime.fromTimestamp(endOfRange).toDateString();

	if (isToday) {
		return <Fragment>{t('Since {startDate}', { startDate })}</Fragment>;
	}

	return (
		<Fragment>
			{t('Between {startDate} — {endDate}', { startDate, endDate })}
		</Fragment>
	);
}
