import { JSX } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import copyString from 'pkg/copyString';
import * as api from 'pkg/api';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import useConfirm from 'pkg/hooks/useConfirm';
import { CollectionResponse } from 'pkg/api/use_collection';

import {
	Group,
	ItemColumn,
} from 'routes/account/settings/child-relations/styles';

import * as Card from 'components/Card';
import Heading from 'components/Heading';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Avatar from 'components/avatar';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

interface PendingInvitesRowProps {
	invite: models.accountInvite.AccountInvite;
	refresh?: () => Promise<void>;
}

function PendingInvitesRow({
	invite,
	refresh,
}: PendingInvitesRowProps): JSX.Element {
	const removeMessage = invite.email
		? t(
				'You are about to remove the connection request sent to {email}, this cannot be undone.',
				{ email: invite.email }
			)
		: t('Are you sure you want to revoke this connection link?');

	const remove = useConfirm({
		message: removeMessage,
		onConfirm: async () => {
			const [request] = await api.delete(
				endpoints.AccountInvites.Show(invite.id)
			);

			if (!request.ok) {
				actions.flashes.show({
					title: t('Something went wrong'),
					message: t(
						`If you think this error isn't supposed to happen, please contact 360Player support.`
					),
				});

				return;
			}

			actions.flashes.show({
				title: t('Connection request succesfully revoked'),
			});

			refresh();
		},
	});

	if (invite.used) {
		return null;
	}

	const targets = invite.targets
		.map((target: any) => {
			if (target.type !== 'child') {
				return;
			}

			return `${target.child.firstName} ${target.child.lastName}`;
		})
		.join(', ');

	if (!targets.length) {
		return null;
	}

	const isEmailInvite = !!invite.email;

	const link = `https://app.360Player.com/claim/${invite.key}`;
	const title = isEmailInvite ? invite.email : link;

	const description = t('Connection to {targets}', {
		targets,
	});

	const copy = () => {
		copyString(link);

		actions.flashes.show({
			title: t('Invite link copied'),
		});
	};

	return (
		<Card.Base $noBorder>
			<Card.Body $narrowBody>
				<LargeScreen>
					<Row
						columns={isEmailInvite ? '35px 1fr auto' : '35px 1fr auto auto'}
						align="center">
						<Avatar size={35} />
						<ItemColumn spacing={styles.spacing._1}>
							<strong>{title}</strong>
							<span>{description}</span>
						</ItemColumn>
						{!isEmailInvite && <Button onClick={copy}>{t('Copy link')}</Button>}
						<Button caution onClick={remove}>
							{t('Revoke')}
						</Button>
					</Row>
				</LargeScreen>
				<SmallScreen>
					<Column>
						<Row columns="35px 1fr">
							<Avatar size={35} />
							<ItemColumn spacing={styles.spacing._1}>
								<strong>{title}</strong>
								<span>{description}</span>
							</ItemColumn>
						</Row>
						{!isEmailInvite && <Button onClick={copy}>{t('Copy link')}</Button>}
						<Button caution onClick={remove}>
							{t('Revoke')}
						</Button>
					</Column>
				</SmallScreen>
			</Card.Body>
		</Card.Base>
	);
}

interface PendingInvitesProps {
	invites: CollectionResponse<models.accountInvite.AccountInvite>;
}

export default function PendingInvites({
	invites,
}: PendingInvitesProps): JSX.Element {
	if (!invites.records.length) {
		return null;
	}

	let hasChildTargets = 0;

	invites.records.map((invite: any) =>
		invite.targets.map((target: any) => {
			if (target.type == 'child') {
				hasChildTargets++;
			}
		})
	);

	if (hasChildTargets === 0) {
		return null;
	}

	return (
		<Group>
			<Heading>{t('Sent connection requests')}</Heading>
			{invites.records.map((invite: models.accountInvite.AccountInvite) => (
				<PendingInvitesRow
					key={invite.id}
					invite={invite}
					refresh={invites.refresh}
				/>
			))}
		</Group>
	);
}
