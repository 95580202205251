import { JSX, CSSProperties, Fragment, ReactNode } from 'react';

import * as styles from 'pkg/config/styles';

import { EventTypes, eventTypeToTranslatedString } from 'pkg/models/event';

import rgba from 'pkg/rgba';
import useTooltip from 'pkg/hooks/useTooltip';

import * as css from './styles.css';

interface TypeProps {
	type: EventTypes;
	children: ReactNode;
}

export const Type = ({ type, children }: TypeProps): JSX.Element => {
	const { onMouseEnter, tooltip } = useTooltip(
		eventTypeToTranslatedString(type)
	);
	return (
		<Fragment>
			{tooltip}
			<div
				className={css.type}
				style={
					{
						'--background-color': rgba(
							styles.palette.events[type].backgroundColor
						),
						'--foreground-color': rgba(
							styles.palette.events[type].foregroundColor
						),
					} as CSSProperties
				}
				onMouseEnter={onMouseEnter}>
				{children}
			</div>
		</Fragment>
	);
};
