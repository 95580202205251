import { JSX } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import Payments from 'routes/organization/user-profile/overview/payments';
import Relationships from 'routes/organization/user-profile/overview/relationships';
import Registrations from 'routes/organization/user-profile/overview/registrations';
import Memberships from 'routes/organization/user-profile/overview/memberships';
import Attendance from 'routes/organization/user-profile/overview/attendance';
import PhysicalStrain from 'routes/organization/user-profile/overview/physical-strain';
import TaggedVideos from 'routes/organization/user-profile/overview/tagged-videos';

import Column from 'components/layout/column';

import * as css from './styles.css';

interface UserProfileOverviewProps {
	user: models.user.User;
	organizationId: number;
}
export default function UserProfileOverview({
	user,
	organizationId,
}: UserProfileOverviewProps): JSX.Element {
	const canViewRelationships = models.hasAllowedAction(
		user,
		models.Action.UserListRelationships
	);

	const canViewRegistrations = models.hasAllowedAction(
		user,
		models.Action.UserListFormSubmissions
	);

	const canViewMemberships = models.hasAllowedAction(
		user,
		models.Action.UserListGroups
	);

	const canViewTaggedVideos = models.hasAllowedAction(
		user,
		models.Action.UserListVideos
	);

	const canViewAttendance = models.hasAllowedAction(
		user,
		models.Action.UserListAttendanceStats
	);

	const canViewPhysicalStrain = models.hasAllowedAction(
		user,
		models.Action.UserListPhysicalStrainStats
	);

	return (
		<div className={css.wrapper}>
			<Column spacing={styles.spacing._7}>
				{canViewRelationships && <Relationships user={user} />}
				<Payments user={user} organizationId={organizationId} />
				{canViewRegistrations && <Registrations user={user} />}
				{canViewMemberships && <Memberships user={user} />}
				{canViewTaggedVideos && <TaggedVideos user={user} />}
				{canViewAttendance && <Attendance user={user} />}
				{canViewPhysicalStrain && <PhysicalStrain user={user} />}
			</Column>
		</div>
	);
}
