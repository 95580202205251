import { JSX } from 'react';
export default function Line(): JSX.Element {
	return (
		<svg
			viewBox="0 0 25 25"
			width="25"
			height="25"
			preserveAspectRatio="xMidYMid meet"
			fill="none">
			<path d="M6.71484 18L18.7148 6" stroke="currentColor" strokeWidth="1.3" />
		</svg>
	);
}
