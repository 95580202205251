import { JSX, useRef, useState } from 'react';
import styled from 'styled-components';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { isAndroid } from 'pkg/platform';
import * as actions from 'pkg/actions';
import * as endpoints from 'pkg/api/endpoints/auto';
import DateTime, { Granularity } from 'pkg/datetime';
import { useCurrentAccount } from 'pkg/identity';

import * as Card from 'components/Card';
import * as StepModal from 'components/step-modal';

import { Spinner } from 'components/loaders/spinner';
import DelayedTextInput from 'components/form/DelayedTextInput';
import Column from 'components/layout/column';

import Button from 'design/button';

const Actions = styled.div`
	display: grid;
	grid-auto-flow: column;
	gap: var(--spacing-5);
	align-items: center;
	justify-items: center;

	@media all and (max-width: ${styles.breakpoint.toSmall}px) {
		grid-auto-flow: dense;
		justify-items: stretch;
		gap: var(--spacing-3);
	}
`;

interface ICSFilters {
	user_ids?: string;
	group_ids?: string;
	from: string;
	to?: string;
}

interface SubscriptionDialogProps {
	userIds?: number[];
	groupIds?: number[];
	onClose: () => void;
}

export default function SubscriptionDialog({
	userIds = [],
	groupIds = [],
	onClose,
}: SubscriptionDialogProps): JSX.Element {
	const t = useT();
	const tokenRef = useRef<HTMLInputElement>();
	const acc = useCurrentAccount();

	const [isLoading, setLoading] = useState<boolean>(false);
	const [didCopy, setDidCopy] = useState<boolean>(false);
	const [subscriptionToken, setSubscriptionToken] = useState<string>();

	useComponentDidMount(async () => {
		setLoading(true);

		const token = await actions.groups.createCalendarSubscriptionToken(acc.id);
		if (token.length > 0) {
			setSubscriptionToken(token);
		}

		setLoading(false);
	});

	const copyToken = () => {
		if (tokenRef.current) {
			tokenRef.current.focus();
			tokenRef.current.setSelectionRange(0, tokenRef.current.value.length);

			if (document.execCommand('copy')) {
				setDidCopy(true);
			}
		}
	};

	const filters: ICSFilters = {
		from: DateTime.now()
			.prev(Granularity.month, 1)
			.getUnixTimestamp()
			.toString(),
	};

	if (userIds.length > 0) {
		filters.user_ids = userIds.toString();
	} else {
		filters.group_ids = groupIds.toString();
	}

	const f = new URLSearchParams({
		token: subscriptionToken,
		...filters,
	});

	const webcalUrl = `${[
		window.TS.apiUrl.replace(/https|http/, 'webcal'),
		endpoints.Events.IndexEventsICS(),
	].join('')}?${f}`;

	return (
		<StepModal.Base onClose={onClose}>
			<StepModal.Step
				title={t('Subscribe to events')}
				skipBody
				hideNext={!didCopy}
				hidePrev={didCopy}
				nextLabel={t('Finish')}
				prevLabel={t('Close')}>
				<Card.Body>
					{isLoading || !subscriptionToken ? (
						<Spinner />
					) : (
						<Column>
							<p>
								{t(
									'Anyone with the subscription link can view your 360Player calendar.'
								)}
							</p>
							<DelayedTextInput ref={tokenRef} readOnly value={webcalUrl} />
							{didCopy && (
								<span>{t('The link was copied to your clipboard.')}</span>
							)}
							<Actions>
								{!isAndroid() && (
									<Button
										href={webcalUrl}
										icon="download"
										testid="events.subscribe.add_to_my_calendar">
										{t('Add to my calendar')}
									</Button>
								)}
								<Button transparent onClick={copyToken}>
									{t('Copy link to clipboard')}
								</Button>
							</Actions>
						</Column>
					)}
				</Card.Body>
			</StepModal.Step>
		</StepModal.Base>
	);
}
