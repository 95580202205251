import { JSX, cloneElement, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import { BaseFilterProps } from 'pkg/filters/types';
import {
	FilterRow,
	FilterValue,
	ModalFilterSettings,
} from 'pkg/filters/use_filters';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';

import Button from 'design/button';

export interface FilterModalProps {
	selected?: number[];

	handleClose?: () => void;
	handleNext?: (ids?: number[]) => Promise<void>;

	handleSelectItem?: (id: number) => void;
	handleSelectAll?: (ids: number[]) => void;
}

interface ModalFilterProps extends BaseFilterProps {
	filterLabel: string;
}

// ModalFilter is a filter that opens a modal of choice, used for selecting different entities,
// for example selecting a bunch of user products the user is assigned
export function ModalFilter({
	filter,
	setFilter,
	currentFilters,
	filterLabel,
}: ModalFilterProps): JSX.Element {
	const currentFilter = currentFilters.find(
		(f) => f.property === filter.property && f.operator === filter.operator
	);
	const [selected, setSelected] = useState<FilterValue[]>(
		currentFilter?.values || []
	);
	const currentFilterLength = currentFilter?.values?.length;

	const settings = (filter.settings || {}) as ModalFilterSettings;

	const [showModal, setShowModal] = useState(false);
	const [checked, setChecked] = useState(!!currentFilterLength ? true : false);

	const hideModal = () => setShowModal(false);
	const setModalVisible = () => setShowModal(true);

	const handleNext = () => {
		setFilter(filter, selected);
	};

	const handleClose = () => {
		hideModal();
	};

	const handleChange = () => {
		if (checked) {
			// If the current filter is set and we uncheck the box setFilter will handle
			// the removal of the filter from the query state
			if (currentFilter !== undefined) {
				setFilter(filter, []);
			}
			setChecked(false);
		} else {
			setChecked(true);
		}
	};

	const handleSelectItem = (incomingId: number) => {
		setSelected((prev) => {
			return prev.includes(incomingId)
				? prev.filter((id) => id !== incomingId)
				: [...prev, incomingId];
		});
	};

	const handleSelectAll = (ids: number[]) => setSelected(ids);

	const clonedModal = cloneElement(settings.modal, {
		selected,
		handleClose,
		handleNext,
		handleSelectItem,
		handleSelectAll,
	});

	let button = null;

	if (!settings.hideButton || checked) {
		button = (
			<Button
				label={
					!!currentFilterLength
						? t('{amount} selected', { amount: currentFilterLength })
						: settings.buttonLabel
				}
				block
				onClick={setModalVisible}
				active={!!currentFilterLength}
			/>
		);
	}

	return (
		<Fragment>
			<FilterRow>
				<Column>
					{settings.hideButton && (
						<Input.Control
							type="checkbox"
							label={filterLabel}
							checked={checked}
							onChange={handleChange}
						/>
					)}
					{button}
				</Column>
			</FilterRow>
			{showModal && clonedModal}
		</Fragment>
	);
}
