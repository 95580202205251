import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import { MetaField } from 'pkg/api/models/user';
import { cssClasses } from 'pkg/css/utils';

import Icon from 'components/icon';
import Avatar from 'components/avatar';

import * as css from './styles.css';

const NOOP = () => {
	return;
};

interface ListItemProps {
	selectable: boolean;

	isSelected: (user: models.user.User) => boolean;
	selectItem: (user: models.user.User) => void;

	user: models.user.User;
	memberships: models.membership.Membership[];
}

export default function ListItem({
	user,
	selectable,
	selectItem,
	isSelected,
	memberships = [],
}: ListItemProps) {
	const t = useT();
	const handleClick = () => selectItem(user);

	const legalGuardianMetaValue = models.user.getMeta(
		user,
		MetaField.LegalGuardian
	);

	const membership = memberships.find((m) => m.userId === user.id);

	let subTitle = '';

	if (legalGuardianMetaValue && membership?.title) {
		subTitle = t('{title} - Parent of: {children}', {
			children: legalGuardianMetaValue,
			title: membership.title,
		});
	} else if (legalGuardianMetaValue) {
		subTitle = t('Parent of: {children}', { children: legalGuardianMetaValue });
	} else if (membership?.title) {
		subTitle = membership.title;
	}

	return (
		<li
			className={cssClasses(
				css.item,
				selectable && isSelected(user) ? css.selected : ''
			)}
			key={`selectableList-item-${user.id}`}
			data-user-id={user.id}
			onClick={selectable ? handleClick : NOOP}
			data-testid="selectable_user_list.item">
			<Avatar style={{ width: '2rem' }} user={user} />
			<div className={css.name}>
				{models.user.fullName(user)}
				{subTitle && <span className={css.wardNames}>{subTitle}</span>}
			</div>
			{selectable && (
				<div
					className={cssClasses(
						css.listItemStatus,
						isSelected(user) ? css.selected : ''
					)}>
					<Icon name="check-filled" className={css.icon} />
				</div>
			)}
		</li>
	);
}
