import { JSX } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import { Account } from 'pkg/api/models/account';
import * as models from 'pkg/api/models';

import Avatar from 'components/avatar';

const AccountWrapper = styled.div`
	display: grid;
	grid-template-areas: 'avatar name' 'avatar email';
	grid-auto-columns: auto 1fr;
	gap: var(--spacing-1) var(--spacing-4);
	align-items: center;

	strong {
		grid-area: name;
		align-self: end;
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
	}

	span {
		grid-area: email;
		align-self: start;
		font-size: var(--font-size-sm);
		color: var(--palette-gray-500);
	}

	${Avatar} {
		grid-area: avatar;
		width: 44px;
		height: 44px;
	}
`;

interface AccountCardProps {
	account: Account;
}

export default function AccountCard({
	account,
}: AccountCardProps): JSX.Element {
	return (
		<AccountWrapper>
			<Avatar account={account} />
			<strong data-testid="account_card.name">
				{models.account.fullName(account)}
			</strong>
			<span data-testid="account_card.email">
				{account.email ? account.email : t('Account without login')}
			</span>
		</AccountWrapper>
	);
}
