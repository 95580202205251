import { JSX } from 'react';
import * as React from 'react';
import styled from 'styled-components';

import Column from 'components/layout/column';
import * as iconStyles from 'components/icon/styles.css';

const Element = styled(Column)`
	height: 100%;
	color: var(--palette-gray-500);
	padding: var(--spacing-4);

	> .${iconStyles.icon} {
		font-size: var(--font-size-4xl);
	}

	h3 {
		color: var(--palette-black);
		font-size: var(--font-size-base);
		font-weight: var(--font-weight-semibold);
	}

	p {
		max-width: 185px;
		margin: 0 auto;
		font-size: var(--font-size-sm);
	}
`;

const Centered = styled(Element)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;
`;

export type DefaultProps = {
	centered?: boolean;
	children: JSX.Element[];
};

const Default: React.FC<React.PropsWithChildren<DefaultProps>> = ({
	centered,
	children,
}) =>
	centered ? <Centered>{children}</Centered> : <Element>{children}</Element>;

export default Default;
