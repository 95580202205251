import { JSX, Fragment } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import { useCurrentRoute } from 'pkg/router/hooks';

import {
	useAggregatedPlaylistSearch,
	useAggregatedVideoSearch,
	usePlaylistSearch,
	useVideoSearch,
} from 'routes/video/library/hooks';
import Videos from 'routes/video/library/group/Videos';
import Playlists from 'routes/video/library/group/Playlists';

import Icon from 'components/icon';

import EmptySearch from 'components/library/EmptySearch';
import { useContentTypeFilter } from 'components/library/hooks';
import { Spinner } from 'components/loaders/spinner';

interface SearchProps {
	keyword: string;
}

const ResultStatus = styled.div`
	max-width: 1800px;
	width: 100%;
	margin: 0 auto;

	svg {
		margin-right: var(--spacing-3);
	}

	mark {
		background-color: var(--palette-green-300);
		border-radius: var(--radius-3);
		box-shadow: 0 0 0 2px var(--palette-green-300);
	}
`;

export default function Search({ keyword }: SearchProps): JSX.Element {
	const contentType = useContentTypeFilter();
	const { contentGroup } = useCurrentRoute();

	const searchGroup = contentGroup === 'your' ? 'your' : 'team';
	const isBrowse = !contentGroup;

	const videos = useVideoSearch(keyword, searchGroup);
	const aggregatedVideos = useAggregatedVideoSearch(keyword);

	const playlists = usePlaylistSearch(keyword, searchGroup);
	const aggregatedPlaylists = useAggregatedPlaylistSearch(keyword);

	const isLoading =
		videos.isLoading ||
		aggregatedVideos.isLoading ||
		playlists.isLoading ||
		aggregatedPlaylists.isLoading;

	const videoNext = videos.pagination.hasNext
		? videos.pagination.fetchNext
		: null;

	const videoRecords = isBrowse ? aggregatedVideos.records : videos.records;
	const videoFetchNext = isBrowse ? null : videoNext;
	const videoReplaceRecord = isBrowse ? null : videos.replaceRecord;
	const videoRemoveRecord = isBrowse ? null : videos.removeRecord;

	const playlistNext = playlists.pagination.hasNext
		? playlists.pagination.fetchNext
		: null;

	const playlistRecords = isBrowse
		? aggregatedPlaylists.records
		: playlists.records;
	const playlistFetchNext = isBrowse ? null : playlistNext;
	const playlistReplaceRecord = isBrowse ? null : playlists.replaceRecord;
	const playlistRemoveRecord = isBrowse ? null : playlists.removeRecord;

	if (isLoading) {
		return <Spinner />;
	}

	if (!videoRecords.length && !playlists.records.length) {
		return (
			<EmptySearch
				icon="search"
				heading={
					<T
						_str="Sorry, we couldn't find anything searching for {keyword}..."
						keyword={<mark>{keyword}</mark>}
					/>
				}
			/>
		);
	}

	let contentGroupComponent = <Fragment>{t(`your team's library`)}</Fragment>;

	if (contentGroup === 'your') {
		contentGroupComponent = <Fragment>{t('your library')}</Fragment>;
	}

	return (
		<Fragment>
			<ResultStatus>
				<Icon name="info-circle" size={1.25} />
				<span>
					{isBrowse ? (
						<T
							_str="Here's what we found searching for {keyword}"
							keyword={<mark>{keyword}</mark>}
						/>
					) : (
						<T
							_str="Here's what we found searching for {keyword} in {content_group}"
							keyword={<mark>{keyword}</mark>}
							content_group={contentGroupComponent}
						/>
					)}
				</span>
			</ResultStatus>

			{videoRecords.length > 0 && contentType.videos && (
				<Videos
					title={t('Videos')}
					items={videoRecords}
					fetchNext={videoFetchNext}
					removeRecord={videoRemoveRecord}
					replaceRecord={videoReplaceRecord}
				/>
			)}

			{playlistRecords.length > 0 && contentType.playlists && (
				<Playlists
					title={t('Playlists')}
					items={playlistRecords}
					fetchNext={playlistFetchNext}
					removeRecord={playlistRemoveRecord}
					replaceRecord={playlistReplaceRecord}
				/>
			)}
		</Fragment>
	);
}
