import { JSX, Fragment, useContext } from 'react';

import * as models from 'pkg/api/models';
import { useQueryState } from 'pkg/hooks/query-state';

import { findGroupsByFilterByScheduleItems } from 'routes/scheduling/utils';
import GroupRow from 'routes/scheduling/templates/single/groups/GroupRow';
import {
	defaultFilters,
	hasBeenFiltered,
} from 'routes/scheduling/templates/filters';
import { TemplateContext } from 'routes/scheduling/templates/single';

interface groupRow {
	group: models.group.Group;
	items: models.scheduleItem.ScheduleItem[];
}

export interface groupGrid {
	[key: number]: groupRow;
}

export function GroupGrid(): JSX.Element {
	const qs = useQueryState();
	const search = qs.get('search')?.toString().toLowerCase();
	const qsFilters = qs.get('schedulingFilters');
	const filters =
		qsFilters !== 'undefined' && qsFilters
			? JSON.parse(qsFilters as string)
			: defaultFilters;
	const hasFiltered = hasBeenFiltered(filters);

	const templateContext = useContext(TemplateContext);

	const grid = new Map<string, groupRow>();

	templateContext.flattenedGroups.forEach((group) => {
		grid.set(group.id.toString(), { group, items: [] });
	});

	templateContext.scheduleItems.forEach((item) => {
		if (!grid.has(item.groupId?.toString())) {
			if (!item.group) {
				return;
			}

			grid.set(item.groupId.toString(), { group: item.group, items: [] });
		}

		const gridItem = grid.get(item.groupId.toString());
		grid.set(item.groupId.toString(), {
			...gridItem,
			items: [...gridItem.items, item],
		});
	});

	if (search) {
		for (const k of grid.keys()) {
			if (!grid.get(k).group.name.toLocaleLowerCase().includes(search)) {
				grid.delete(k);
			}
		}
	}

	const groupsToList = findGroupsByFilterByScheduleItems(
		templateContext.flattenedGroups,
		templateContext.scheduleItems,
		filters,
		templateContext.conflicts
	);

	const groupIds = [...new Set(groupsToList.map((g) => g.id))];

	if (hasFiltered) {
		for (const k of grid.keys()) {
			if (!groupIds.includes(Number.parseInt(k, 10))) {
				grid.delete(k);
			}
		}
	}

	if (grid.size === 0) {
		return null;
	}

	const groupRowItems: JSX.Element[] = [];

	grid.forEach((row) =>
		groupRowItems.push(
			<GroupRow
				key={row.group.id}
				group={row.group}
				items={row.items}
				filters={filters}
			/>
		)
	);

	return <Fragment>{groupRowItems}</Fragment>;
}
