// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mNJ_nre0mmkrhDYZnrhl {
	margin-left: auto;
	margin-right: auto;
	padding: var(--spacing-6);
	max-width: var(--page-width-wide);
	width: 100%;
}
@media (max-width: 768px) {
	.mNJ_nre0mmkrhDYZnrhl {
		padding-top: var(--spacing-1);
}
	}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/notes/styles.css"],"names":[],"mappings":"AAAA;CACC,iBAAmB;CAAnB,kBAAmB;CACnB,yBAAyB;CACzB,iCAAiC;CACjC,WAAW;AAKZ;AAHC;CAND;EAOE,6BAA6B;AAE/B;CADC","sourcesContent":[".wrapper {\n\tmargin-inline: auto;\n\tpadding: var(--spacing-6);\n\tmax-width: var(--page-width-wide);\n\twidth: 100%;\n\n\t@media (--small-viewport) {\n\t\tpadding-top: var(--spacing-1);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `mNJ_nre0mmkrhDYZnrhl`;
export default ___CSS_LOADER_EXPORT___;
