import { useT } from '@transifex/react';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import Registration from 'routes/user/registrations/open/registration';
import * as css from 'routes/user/registrations/categories/styles.css';

import { ListLayout } from 'components/view-toggle';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import FormsGridItem from 'components/forms/grid-item';

interface BaseProps {
	group: models.group.Group;
	view?: ListLayout;
}

interface FormCategoryWithItemsProps extends BaseProps {
	forms: models.form.Form[];
}

interface FormItemProps extends BaseProps {
	form: models.form.Form;
}

function FormItem({ form, group, view }: FormItemProps) {
	if (view === ListLayout.Grid) {
		return <FormsGridItem form={form} group={group} />;
	}

	return <Registration form={form} group={group} />;
}

export function FormItems({
	forms,
	group,
	view = ListLayout.List,
}: FormCategoryWithItemsProps) {
	forms.sort((a, b) => a.title.localeCompare(b.title));

	const items = forms.map((f) => (
		<FormItem form={f} group={group} view={view} />
	));

	if (view === ListLayout.Grid) {
		return <div className={css.grid}>{items}</div>;
	}

	return items;
}

// Groups form items into their categories
export default function FormCategoryWithItems({
	forms,
	group,
	view,
}: FormCategoryWithItemsProps) {
	const t = useT();
	const categories: models.formCategory.FormCategory[] = [];

	forms.forEach((form: models.form.Form) => {
		const categoryIds = categories.map((c) => c.id);

		if (form.formCategory?.id && !categoryIds.includes(form.formCategory?.id)) {
			categories.push(form.formCategory);
		} else if (!form.formCategoryId && !categoryIds.includes(0)) {
			const noCategory: models.formCategory.FormCategory = {
				id: 0,
				title: t('No category'),
				description: '',
				groupId: 0,
				group: null,
			};

			categories.push(noCategory);
		}
	});

	categories.sort((a, b) => {
		if (a.id === 0) return 1;
		if (b.id === 0) return -1;
		return a.title.localeCompare(b.title);
	});

	return (
		<Fragment>
			{categories.map((c) => (
				<Column spacing={styles.spacing._3}>
					<SectionTitle thin>{c.title}</SectionTitle>
					<FormItems
						forms={forms.filter(
							(f) =>
								f.formCategoryId === c.id || (!f.formCategoryId && c.id === 0)
						)}
						group={group}
						view={view}
					/>
				</Column>
			))}
		</Fragment>
	);
}
