import { JSX, Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as strings from 'pkg/strings';
import * as models from 'pkg/api/models';

import Sessions from 'routes/training/library/group/Sessions';
import Exercises from 'routes/training/library/group/Exercises';
import Collections from 'routes/training/library/group/Collections';
import {
	LibraryResponse,
	useTrainingLibraryFilters,
} from 'routes/training/library/hooks';
import CurrentFilter from 'routes/training/library/CurrentFilter';

import EmptySearch from 'components/library/EmptySearch';
import { Spinner } from 'components/loaders/spinner';
import EmptyState from 'components/library/TrainingEmptyState';
import { useContentTypeFilter } from 'components/library/hooks';

interface LibraryProps {
	library: LibraryResponse;
}

export default function Library({ library }: LibraryProps): JSX.Element {
	const contentType = useContentTypeFilter();
	const filters = useTrainingLibraryFilters();

	const sessions = library.sessions?.records;
	const hasSessions = sessions?.length > 0;

	const exercises = library.exercises?.records;
	const hasExercises = exercises?.length > 0;

	const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

	let collections = library.collections?.records;

	// Filter out collections here as they are not searched for in the backend
	if (filters.hasTitleFilter) {
		collections = collections.filter(
			(collection: models.trainingCollection.TrainingCollection) =>
				strings.localeIncludes(collection.name, filters.title)
		);
	}

	const hasCollections = collections?.length > 0;

	const hasContent =
		!library.isLoading && (hasSessions || hasExercises || hasCollections);

	const handleFetchNextExercises = () => {
		setIsInitialLoad(false);

		if (library.exercises.pagination.hasNext) {
			library.exercises.pagination.fetchNext();
		}
	};

	const handleFetchNextSessions = () => {
		setIsInitialLoad(false);

		if (library.sessions.pagination.hasNext) {
			library.sessions.pagination.fetchNext();
		}
	};

	const handleFetchNextCollections = () => {
		setIsInitialLoad(false);

		if (library.collections.pagination.hasNext) {
			library.collections.pagination.fetchNext();
		}
	};

	let emptyState = null;

	if (!hasContent && !library.isLoading) {
		emptyState = <EmptyState />;

		if (filters.isFiltered) {
			emptyState = (
				<EmptySearch
					icon="search"
					heading={t(
						`Sorry, we couldn't find anything matching current filters…`
					)}
				/>
			);
		}
	}

	if (isInitialLoad && library.isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<CurrentFilter />
			{emptyState}
			{hasExercises && contentType.exercises && (
				<Exercises
					title={t('Exercises')}
					items={exercises}
					isLoading={library.exercises.isLoading}
					hasNext={library.exercises.pagination.hasNext}
					fetchNext={handleFetchNextExercises}
					replaceRecord={library.exercises.replaceRecord}
					removeRecord={library.exercises.removeRecord}
				/>
			)}

			{hasSessions && contentType.sessions && (
				<Sessions
					title={t('Sessions')}
					items={sessions}
					isLoading={library.sessions.isLoading}
					hasNext={library.sessions.pagination.hasNext}
					fetchNext={handleFetchNextSessions}
					replaceRecord={library.sessions.replaceRecord}
					removeRecord={library.sessions.removeRecord}
				/>
			)}

			{hasCollections && contentType.collections && (
				<Collections
					title={t('Collections')}
					items={collections}
					isLoading={library.collections.isLoading}
					hasNext={library.collections.pagination.hasNext}
					fetchNext={handleFetchNextCollections}
					replaceRecord={library.collections.replaceRecord}
					removeRecord={library.collections.removeRecord}
				/>
			)}
		</Fragment>
	);
}
