import { useT } from '@transifex/react';
import { JSX } from 'react';

import * as models from 'pkg/api/models';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as objects from 'pkg/objects';

import Section from 'components/section';

import Row from 'components/layout/row';

import * as DataCard from 'design/data_card';

interface AttendanceProps {
	user: models.user.User;
}

export default function Attendance({ user }: AttendanceProps): JSX.Element {
	const t = useT();

	const { record: attendanceStats } =
		useEndpoint<models.user.UserAttendanceStats>(
			endpoints.Users.AttendanceStats(user.id)
		);

	if (objects.empty(attendanceStats)) {
		return null;
	}

	const {
		attendancePercentage = 0,
		matchCount = 0,
		practiceCount = 0,
		totalEventCount = 0,
	} = attendanceStats ?? {};

	return (
		<Section title={t('Season Attendance')} icon="person_check">
			<Row collapseOnSmallScreens columns="repeat(3, 1fr)">
				<DataCard.WithLargeIcon
					title={t('Matches')}
					symbol="trophy"
					heading={matchCount.toString()}
				/>
				<DataCard.WithLargeIcon
					title={t('Practices')}
					symbol="cardiology"
					heading={practiceCount.toString()}
				/>
				<DataCard.WithLargeIcon
					title={t('Attendance')}
					symbol="event_available"
					heading={`${Math.floor(attendancePercentage)}%`}
					headingSuffix={t('of {num} events', { num: totalEventCount })}
				/>
			</Row>
		</Section>
	);
}
