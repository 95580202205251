import { t } from '@transifex/native';
import { JSX, useEffect, useState } from 'react';

import * as Input from 'components/form/inputs';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

const useTableFields = (
	items: string[],
	defaultUnchecked: string[] = []
): {
	FieldsMenuItem: JSX.Element;
	contextMenuItems: JSX.Element[];
	disabledFields: string[];
} => {
	const [disabledFields, setDisabledFields] =
		useState<string[]>(defaultUnchecked);

	const handleSelectField = (field: string): void => {
		setDisabledFields((prev: string[]) =>
			prev.includes(field)
				? prev.filter((item: string) => item !== field)
				: [...prev, field]
		);
	};

	// if defaultUnchecked is empty and page is still loading - force a state-update when defaultUnchecked is populated.
	useEffect(() => {
		setDisabledFields(defaultUnchecked);
	}, [defaultUnchecked.length]);

	const contextMenuItems = items.map((field: string) => (
		<ContextMenu.Item
			key={field}
			closeOnClick={false}
			onClick={() => handleSelectField(field)}>
			<Input.Control
				type="checkbox"
				checked={!disabledFields.includes(field)}
				label={field}
			/>
		</ContextMenu.Item>
	));

	return {
		FieldsMenuItem: (
			<ContextMenu.Menu
				toggleWith={<Button icon="location_on">{t('Fields')}</Button>}>
				{contextMenuItems}
			</ContextMenu.Menu>
		),
		contextMenuItems: contextMenuItems,
		disabledFields,
	};
};

export default useTableFields;
