import { JSX, ReactNode } from 'react';

import * as styles from 'pkg/config/styles';

import { useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { cssVarList } from 'pkg/css/utils';
import { useCurrentRoute } from 'pkg/router/hooks';

import UserHeader from 'routes/organization/user-profile/user-header';

import Badge from 'components/Badge';

import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import { SubNavItemProps } from 'components/layout/page-templates';
import Back from 'components/navigation/Back';

import * as css from './styles.css';

interface BrandedHeaderLayoutProps {
	user: models.user.User;

	children: ReactNode | ReactNode[];
	pageSubNavItems: SubNavItemProps[];
	backgroundColor: string;
}

export default function BrandedHeaderLayout({
	user,
	children,
	pageSubNavItems = [],
	backgroundColor,
}: BrandedHeaderLayoutProps): JSX.Element {
	const org = useCurrentOrganization();
	const { currentTab } = useCurrentRoute();

	const onOverviewPage = currentTab === 'overview';
	const onDevelopmentPage = currentTab === 'development';

	let innerBgColor = styles.palette.white;

	if (onOverviewPage || onDevelopmentPage) {
		innerBgColor = styles.palette.gray[200];
	}

	return (
		<div
			className={css.brandedWrapper}
			style={cssVarList({ bg: backgroundColor })}>
			<div className={css.brandedHeader}>
				<div className={css.brandedHeaderBack}>
					<Back />
				</div>
				<Badge badgeUrl={org.profileImageUrl} className={css.brandedBadge} />
				<UserHeader user={user} />
				<SmallLayout.SubNav>
					{pageSubNavItems.map((item, n) => (
						<SmallLayout.SubNavItem key={n} {...item}>
							{item.title}
						</SmallLayout.SubNavItem>
					))}
				</SmallLayout.SubNav>
			</div>
			<div
				className={css.brandedInner}
				style={cssVarList({ innerBg: innerBgColor })}>
				<div className={css.brandedInnerWrapper}>{children}</div>
			</div>
		</div>
	);
}
