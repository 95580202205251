import { Fragment } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import {
	useCurrentAccountUserIds,
	useCurrentAccountWards,
	useCurrentUser,
} from 'pkg/identity';
import { EndpointResponse } from 'pkg/api/use_endpoint';
import { CollectionResponse } from 'pkg/api/use_collection';

import Comments from 'routes/event/single/comments';
import EventUsers from 'routes/event/single/EventUsers';
import PhysicalStrainBreakdown from 'routes/event/single/tabs/overview/PhysicalStrainBreakdown';
import SessionList from 'routes/event/single/tabs/overview/SessionList';
import Heading from 'routes/event/single/event-heading';
import EventUserAttendance from 'routes/event/attendance';
import Description from 'routes/event/single/description';
import MatchStatistics from 'routes/event/single/match-statistics';
import EventUsersSummary from 'routes/event/single/event-users-summary';
import RSVPInfoBox from 'routes/event/components/rsvp/info-box';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import SectionTitle from 'components/SectionTitle';

import Divider, { ColumnDivider } from 'components/form/Divider';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import ResourceCardItem from 'components/scheduling/resources';

interface EventOverviewProps {
	eventRecord: models.event.Event;

	eventEndpoint: EndpointResponse<models.event.Event>;
	membershipsCollection: CollectionResponse<models.membership.Membership>;
}

export default function EventOverview({
	eventRecord,
	eventEndpoint,
	membershipsCollection,
}: EventOverviewProps) {
	const user = useCurrentUser();

	const activeUserIds = useCurrentAccountUserIds();
	const wardRelations = useCurrentAccountWards();
	const wardUserIds = models.accountRelation.findAllUserIds(
		wardRelations.filter((ar) => models.accountRelation.isApproved(ar))
	);

	const allEventUsers = models.eventUser.findAllEventUsers(
		eventEndpoint.record.users,
		[...activeUserIds, ...wardUserIds]
	);
	const eventUsers = models.eventUser.sortEventUsers(
		allEventUsers,
		[],
		eventRecord
	);

	const group = eventRecord.group;
	const groupSport = group.sport;

	const match = eventRecord.matches?.[0];

	const hasAggregatedStatistics = models.group.hasFeature(
		group,
		models.group.Features.AggregatedStatistics
	);

	const primaryResourceIds: number[] = [];
	const resources: models.resource.Resource[] = [];

	eventRecord.bookings?.forEach((b) => {
		b.resources?.forEach((r) => resources.push(r.resource));

		if (b?.resource) {
			resources.push(b.resource);
			primaryResourceIds.push(b.resource.id);
		}
	});

	const sortedResources = resources
		.filter((r, index, arr) => index === arr.findIndex((i) => i.id === r.id))
		.sort((a, b) =>
			primaryResourceIds.includes(a.id)
				? -1
				: primaryResourceIds.includes(b.id)
					? 1
					: 0
		);

	let resourcesContent = null;

	if (resources?.length > 0) {
		resourcesContent = (
			<Column spacing={styles.spacing._3}>
				<SectionTitle>{t('Resources')}</SectionTitle>
				{sortedResources.map((r) => (
					<ResourceCardItem
						resource={r}
						isPrimary={primaryResourceIds.includes(r.id)}
					/>
				))}
			</Column>
		);
	}

	const eventUsersContent = eventUsers.length > 0 && (
		<Column>
			{eventUsers.map((eu) => (
				<EventUserAttendance
					key={eu.id}
					eventUser={eu}
					event={eventRecord}
					eventEndpoint={eventEndpoint}
				/>
			))}
		</Column>
	);

	const showSessionList = models.group.hasFeature(
		group,
		models.group.Features.TrainingLibrary
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Inner
					maxWidth={styles.size.PageWidths.WIDE}
					columns={3}
					columnSpacing="5%"
					columnSizes="1fr 1px 0.6fr">
					<Column spacing={styles.spacing._7}>
						<Heading event={eventRecord} />

						{eventRecord.rsvpBefore && <RSVPInfoBox event={eventRecord} />}

						{eventUsersContent}

						<Description
							event={eventRecord}
							currentGroupMemberships={membershipsCollection.records}
						/>

						{hasAggregatedStatistics && (
							<PhysicalStrainBreakdown event={eventRecord} />
						)}

						{resourcesContent}

						{eventRecord.type === models.event.EventTypes.Match &&
							models.sport.hasMatchesEnabled(groupSport) &&
							models.group.hasFeature(group, models.group.Features.Matches) && (
								<MatchStatistics match={match} />
							)}
						{showSessionList &&
							eventRecord.type === models.event.EventTypes.Practice && (
								<SessionList event={eventRecord} />
							)}
						<Divider />
						<Comments event={eventRecord} user={user} />
					</Column>
					<ColumnDivider />
					<Column>
						<EventUsers event={eventRecord} />
					</Column>
				</LargeScreenContent.Inner>
			</LargeScreen>

			<SmallScreen>
				<LargeScreenContent.Inner>
					<Column spacing={styles.spacing._8}>
						<Column spacing={styles.spacing._6}>
							<Heading event={eventRecord} />

							{eventUsersContent}

							<Description
								event={eventRecord}
								currentGroupMemberships={membershipsCollection.records}
							/>

							<EventUsersSummary event={eventRecord} />

							{resourcesContent}

							{eventRecord.type === models.event.EventTypes.Match &&
								models.sport.hasMatchesEnabled(groupSport) && (
									<MatchStatistics match={match} />
								)}
							{hasAggregatedStatistics && (
								<PhysicalStrainBreakdown event={eventRecord} />
							)}

							{showSessionList &&
								eventRecord.type === models.event.EventTypes.Practice && (
									<SessionList event={eventRecord} />
								)}

							<Divider />
							<Comments event={eventRecord} user={user} />
						</Column>
					</Column>
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Fragment>
	);
}
