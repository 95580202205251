import { JSX, Fragment } from 'react';

import LocationsView from 'routes/scheduling/locations/list';
import ResourcesView from 'routes/scheduling/resources/list/Resources';

import { useTemplateContext } from 'components/layout/page-templates/context';

export enum ResourcesTabs {
	Resources = 'overview',
	Locations = 'locations',
}

interface ResourcesProps {
	groupId: number;
}

const Resources = ({ groupId }: ResourcesProps): JSX.Element => {
	let viewContent;
	const { currentTab } = useTemplateContext();

	switch (currentTab) {
		case ResourcesTabs.Locations:
			viewContent = <LocationsView groupId={groupId} />;
			break;
		default:
			viewContent = <ResourcesView groupId={groupId} />;
	}

	return <Fragment>{viewContent}</Fragment>;
};

export default Resources;
