import { useEffect, useRef } from 'react';
import {
	AdyenPlatformExperience,
	PayoutsOverview,
	ReportsOverview,
	TransactionsOverview,
	all_locales,
} from '@adyen/adyen-platform-experience-web';

import * as actions from 'pkg/actions';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { PaymentProvider } from 'pkg/api/models/group';
import { InitTransferInstrumentResponse } from 'pkg/api/payment_providers/adyen';
import { getAdyenPlatformExperienceLocale } from 'pkg/adyen/utils';

interface Props {
	organizationId: number;
}

export function useAdyenReportingComponents({ organizationId }: Props) {
	const payoutsRef = useRef(null);
	const reportsRef = useRef(null);
	const transactionsRef = useRef(null);

	const { record: org, isLoading: isLoadingOrganization } =
		useEndpoint<models.group.Group>(
			endpoints.Organizations.Show(organizationId)
		);
	const adyenConfig = org.paymentConfigs?.find(
		(c) => c.paymentProvider === PaymentProvider.Adyen
	);

	const { record, isLoading: isInitiating } =
		useEndpoint<InitTransferInstrumentResponse>(
			adyenConfig &&
				endpoints.Adyen.InitiateReporting(organizationId, adyenConfig.id),
			{
				postRequest: true,
			}
		);

	const onSessionCreate = async () => {
		const [ok, resp] = await actions.adyen.initReportingSession(
			org.id,
			adyenConfig.id
		);

		if (ok) {
			return { id: resp.sessionId, token: resp.sessionToken };
		}

		return null;
	};

	useEffect(() => {
		const initAdyenPlatformExperience = async () => {
			const locale = (await getAdyenPlatformExperienceLocale()) as any;
			const core = await AdyenPlatformExperience({
				availableTranslations: [all_locales],
				environment: 'test',
				locale,
				onSessionCreate,
			});

			if (payoutsRef.current !== null) {
				const payoutsOverview = new PayoutsOverview({ core });

				payoutsOverview.mount(payoutsRef.current);
			}

			if (reportsRef.current !== null) {
				const reportsOverview = new ReportsOverview({ core });

				reportsOverview.mount(reportsRef.current);
			}

			if (transactionsRef.current !== null) {
				const transactionsOverview = new TransactionsOverview({ core });

				transactionsOverview.mount(transactionsRef.current);
			}
		};

		if (record?.sessionId) {
			initAdyenPlatformExperience();
		}
	}, [
		record.sessionId,
		payoutsRef.current,
		reportsRef.current,
		transactionsRef.current,
	]);

	return {
		isLoading: isLoadingOrganization || isInitiating,
		PayoutsComponent: <div ref={payoutsRef}></div>,
		ReportsComponent: <div ref={reportsRef}></div>,
		TransactionComponent: <div ref={transactionsRef}></div>,
	};
}
