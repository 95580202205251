import { T, useT } from '@transifex/react';
import { Fragment, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import spacing from 'pkg/config/spacing';

import { CreateChildAccountPayload } from 'pkg/actions/accounts';

import * as actions from 'pkg/actions';
import { useCurrentAccount } from 'pkg/identity';
import { useOrganizationIdentity } from 'pkg/identity/organization';
import Link from 'pkg/router/Link';

import SectionTitle from 'components/SectionTitle';
import MaterialSymbol from 'components/material-symbols';

import Column from 'components/layout/column';
import * as Input from 'components/form/inputs';
import Form, { asString, FormPayload } from 'components/form/Form';
import Row from 'components/layout/row';
import { useJoinModalContext } from 'components/group/join-modal';

import Button from 'design/button';

import * as css from './styles.css';

interface FormItemsProps {
	isSubmitting: boolean;
	toggleForm: () => void;
}

function FormItems({ isSubmitting, toggleForm }: FormItemsProps) {
	const t = useT();
	const formCtx = useFormContext();
	const createAccount = formCtx.watch('createAccount');
	const email = formCtx.watch('email');
	const confirmEmail = formCtx.watch('email-confirm');

	const maxDate = new Date().toISOString().split('T')[0];

	const validateEmail = () => {
		if (email !== confirmEmail) {
			return false;
		}

		return true;
	};

	return (
		<div className={css.body}>
			<Column spacing={spacing._6}>
				<Column>
					<Row align="center">
						<SectionTitle>{t('Add new child')}</SectionTitle>
						<div className={css.close} onClick={toggleForm}>
							<MaterialSymbol actualSize scale={1.4} variant="close" />
						</div>
					</Row>
					<Input.Group label={t('First name')}>
						<Input.Field name="firstName" required />
					</Input.Group>
					<Input.Group label={t('Last name')}>
						<Input.Field name="lastName" required />
					</Input.Group>
					<Input.Group label={t(`Child's date of birth`)}>
						<Input.Field
							type="date"
							name="dateOfBirth"
							required
							min="1900-01-01"
							max={maxDate}
						/>
					</Input.Group>
					<Input.Control
						name="createAccount"
						type="checkbox"
						label={t('Child should be able to access their own account')}
					/>
					{createAccount && (
						<Fragment>
							<span className={css.collectEmail}>
								{t(
									`We will need to collect a unique email address for your child. If you don't create a login for your child, you will still be able to manage team related activities on their behalf.`
								)}
							</span>
							<Input.Group label={t(`Child's email`)}>
								<Input.Field name="email" validate={validateEmail} required />
							</Input.Group>
							<Input.Group label={t(`Confirm child's email`)}>
								<Input.Field
									name="email-confirm"
									validate={validateEmail}
									required
								/>
							</Input.Group>
							<Input.CreatePassword />
						</Fragment>
					)}
				</Column>

				<Column>
					<span className={css.terms}>
						<T
							_str="By submitting this you agree to our {terms_and_conditions_link}."
							terms_and_conditions_link={
								<Link href="https://www.360player.com/terms-of-service">
									{t('terms and conditions')}
								</Link>
							}
						/>
					</span>

					<Button
						type="submit"
						primary
						label={t('Add child')}
						isLoading={isSubmitting}
					/>
				</Column>
			</Column>
		</div>
	);
}

export function CreateWard() {
	const t = useT();
	const [showForm, setShowForm] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const currentAccount = useCurrentAccount();
	const { refreshOrganizationIdentity } = useOrganizationIdentity();
	const { setAll } = useJoinModalContext();

	const toggleForm = () => setShowForm(!showForm);

	const handleSubmit = async (data: FormPayload) => {
		setIsSubmitting(true);

		const payload: CreateChildAccountPayload = {
			firstName: asString(data.firstName),
			lastName: asString(data.lastName),
			birthDate: asString(data.dateOfBirth),
			countryId: currentAccount.countryId,
			languageCode: currentAccount.languageCode,
		};

		if (data.createAccount) {
			payload.email = asString(data.email);
			payload.password = asString(data.password);
		}

		const [ok, account] = await actions.accounts.createChildAccount(
			currentAccount.id,
			payload
		);

		if (ok) {
			refreshOrganizationIdentity();
			setAll({ targetAccount: account });
			toggleForm();
		}

		setIsSubmitting(false);
	};

	if (showForm) {
		return (
			<Form onSubmit={handleSubmit}>
				<FormItems isSubmitting={isSubmitting} toggleForm={toggleForm} />
			</Form>
		);
	}

	return (
		<Button label={t('Add/Create child')} icon="add" onClick={toggleForm} />
	);
}
