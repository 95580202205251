// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AJP8u25of8fTy0E7FODg {
	padding: var(--spacing-6);
	max-width: var(--page-width-wide);
}
@media (max-width: 768px) {
	.AJP8u25of8fTy0E7FODg {
		padding-top: 0;
}
	}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/videos/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,iCAAiC;AAKlC;AAHC;CAJD;EAKE,cAAc;AAEhB;CADC","sourcesContent":[".wrapper {\n\tpadding: var(--spacing-6);\n\tmax-width: var(--page-width-wide);\n\n\t@media (--small-viewport) {\n\t\tpadding-top: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `AJP8u25of8fTy0E7FODg`;
export default ___CSS_LOADER_EXPORT___;
