import { JSX } from 'react';
import { useT } from '@transifex/react';

import useMixedState from 'pkg/hooks/useMixedState';
import {
	getGmtStringByTimeZoneName,
	getTimeZoneFromSessionStorage,
} from 'pkg/timezone';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import * as actions from 'pkg/actions';
import { RSVPInterval } from 'pkg/api/models/event';
import DateTime from 'pkg/datetime';

import EditForm from 'routes/event/edit/Form';
import { IFormState } from 'routes/event/interfaces';
import { emptyFormState, EventFormContext } from 'routes/event/create';

import { Spinner } from 'components/loaders/spinner';
import useAttachments from 'components/attachment/hooks/useAttachments';
import { useTemplateContext } from 'components/layout/page-templates/context';

interface EventEditWrapperProps {
	eventId: number;
}

export default function EventEditWrapper({ eventId }: EventEditWrapperProps) {
	const [formState, setFormState] = useMixedState<IFormState>(emptyFormState);

	const { record: event, isLoading } = useEndpoint<models.event.Event>(
		endpoints.Events.Show(eventId),
		{
			// We don't want to fetch when we have a groupId in the formState because that means
			// that we've already fetched this information once
			delayFetch: !!formState.groupId,
		},
		(event) => {
			const meetBeforeMinutes = event.meetBeforeMinutes
				? event.meetBeforeMinutes.toString()
				: '0';

			// Check if there actually is a match connected (Can there be more than one match?)
			const match = event.matches?.[0];

			const selectedUsers = event.users?.length
				? event.users.map((eventUser) => eventUser.user)
				: [];

			const organizer: { [key: number]: boolean } = {};

			event.users?.forEach((user) => {
				if (user.role === 'organizer') {
					organizer[user.userId] = true;
				}
			});

			const initState: IFormState = {
				groupId: event.groupId,
				title: event.title,
				startsAt: event.startsAtLocal
					? new DateTime(new Date(event.startsAtLocal)).getUnixTimestamp()
					: event.startsAt,
				endsAt: event.endsAtLocal
					? new DateTime(new Date(event.endsAtLocal)).getUnixTimestamp()
					: event.endsAt,
				timezone:
					getGmtStringByTimeZoneName(event.timezone) ||
					getGmtStringByTimeZoneName(getTimeZoneFromSessionStorage()?.name),
				location: event.location,
				description: event.description,
				sendInvites: event.type === 'practice' ? false : true,
				selectedUsers,
				organizer,
				physicalStrainDefault: event.physicalStrainDefault,
				isPrivate: event.isPrivate,
				isPublic: event.isPublic,
				hideParticipants: event.hideParticipants,
				meetBeforeMinutes,
				type: event.type,
				rsvpBefore: event.rsvpBefore ? true : false,
				rsvpInterval: event.rsvpInterval || RSVPInterval.RSVPIntervalDay,
				rsvpIntervalCount: event.rsvpIntervalCount || 1,
				flags: event.flags,
				match: {
					opponentName: match?.opponentName,
					startsAt: match?.startsAtLocal
						? new DateTime(new Date(match.startsAtLocal)).getUnixTimestamp()
						: match?.startsAt,
					duration: match?.duration,
					location: match?.location,
					matchCompetitionId: match?.matchCompetitionId,
					type: match?.type,
					isHome: match?.isHome,
				},
				errors: {
					title: false,
					location: false,
					type: false,
					timezone: false,
					kickOff: false,
					rsvpBefore: false,
				},
			};

			setFormState({
				...initState,
			});
		}
	);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<EventFormContext.Provider value={{ formState, setFormState }}>
			<EventEdit event={event} />
		</EventFormContext.Provider>
	);
}

interface EventEditProps {
	event: models.event.Event;
}

const EventEdit = ({ event }: EventEditProps): JSX.Element => {
	const t = useT();
	const eventUsers = event.users;
	const { setPageTitle } = useTemplateContext();

	const onRemoveAttachment = (attachmentId: number) =>
		actions.events.deleteEventAttachment(event.id, attachmentId);

	const UseAttachments = useAttachments({
		attachments: event.attachments,
		onRemoveAttachment,
	});

	useComponentDidMount(() => {
		setPageTitle(t(`Editing {title}`, { title: event.title }));
	});

	return (
		<EditForm
			event={event}
			eventUsers={eventUsers}
			useAttachments={UseAttachments}
		/>
	);
};
