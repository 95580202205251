import { JSX } from 'react';
import { t } from '@transifex/native';

import { useOrderReport } from 'routes/payments/overview/hooks';
import { DateSpanLabel } from 'routes/payments/overview/widgets';

import * as DataCard from 'design/data_card';

export default function NewSubscriptionsWidget(): JSX.Element {
	const { newSubscriptions } = useOrderReport();

	return (
		<DataCard.WithLargeIcon
			icon="nav-members"
			title={t('New subscriptions')}
			heading={newSubscriptions.toString()}
			footer={<DateSpanLabel />}
			noFooterDivider
		/>
	);
}
