import { JSX, CSSProperties, Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import { useCurrentRoute } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { replaceState } from 'pkg/router/state';
import * as routes from 'pkg/router/routes';
import { localeIncludes } from 'pkg/strings';
import { useCurrentOrganization } from 'pkg/identity';

import Videos from 'routes/video/library/group/Videos';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import CollectionModal from 'components/video-library/modals/add-to-collection';
import { Spinner } from 'components/loaders/spinner';
import * as ActionBar from 'components/layout/ActionBar';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';
import OutlinePlaceholder from 'design/placeholders/outline';

const Hero = styled.div`
	--color: var(--palette-gray-500);

	padding: var(--spacing-9) var(--spacing-8);
	background-color: var(--color);
	background-image: linear-gradient(
		135deg,
		hsla(0, 0%, 0%, 0.5),
		hsla(0, 0%, 0%, 0.1)
	);
	background-position: 50% 50%;
	background-size: cover;
	color: var(--palette-white);
	display: grid;
	grid-auto-flow: row;
	justify-items: start;
	gap: var(--spacing-4);

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-9) var(--spacing-6);
	}

	&[data-is-branded='true'] {
		background-blend-mode: normal;
		box-shadow: inset 0 0 30px hsla(0, 0%, 0%, 0.2);
	}

	&[data-has-cover-image='true'] {
		position: relative;
		overflow: hidden;
	}
`;

const GroupProfileImage = styled.img`
	width: 220px;
	height: 220px;
	opacity: 0.2;
	filter: grayscale(1);
	transform: translate(-50%, -50%);
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 10;
	pointer-events: none;
`;

const Title = styled.span`
	padding: var(--spacing-1) var(--spacing-2);
	background: var(--palette-white);
	color: var(--palette-gray-800);
	font-size: var(--font-size-sm);
	border-radius: var(--radius-2);
	display: inline-block;
`;

const Heading = styled.h1`
	display: inline-grid;
	grid-auto-flow: column;
	gap: var(--spacing-5);
	place-content: center;
	place-items: center;
`;

const HeadingContextMenuTrigger = styled.span`
	padding: var(--spacing-1) var(--spacing-3);
	border-radius: var(--radius-3);
	box-shadow: 0 0 0 1px var(--palette-white);
	font-size: var(--font-size-sm);
	display: block;
	cursor: pointer;
`;

const Byline = styled.div`
	display: grid;
	grid-template-columns: 25px 1fr;
	grid-template-rows: repeat(2, auto);
	column-gap: var(--spacing-3);
	row-gap: var(--spacing-1);
	align-items: center;
	justify-content: center;
	font-size: var(--font-size-xs);

	div:first-child {
		grid-area: 1 / 1 / 3 / 2;
	}
`;

const Wrapper = styled.div`
	margin: 0 auto;
	max-width: 1800px;
	padding: var(--spacing-8);
	display: grid;
	grid-auto-flow: row;
	gap: var(--spacing-8);

	@media ${styles.breakpoint.small} {
		padding: var(--spacing-6);
	}
`;

export default function Collection(): JSX.Element {
	const { collectionId, groupId } = useCurrentRoute();
	const org = useCurrentOrganization();
	const [isEditing, setEditing] = useState<boolean>(false);
	const [isSearching, setIsSearching] = useState<boolean>(false);

	const [orderedItems, setOrderedItems] = useState<
		models.videoCollection.VideoCollectionItem[]
	>([]);

	const [sortBy, setSortBy] = useState<'recent_first' | 'oldest_first'>(
		'recent_first'
	);

	const {
		isLoading,
		record: collection,
		removeInRecord,
	} = useEndpoint<models.videoCollection.VideoCollection>(
		endpoints.VideoCollections.Show(collectionId)
	);

	useEffect(() => {
		if (collection.videoCount > 0) {
			const ordered = collection.videos.sort(
				(
					a: models.videoCollection.VideoCollectionItem,
					b: models.videoCollection.VideoCollectionItem
				) => a.video.id - b.video.id
			);

			setOrderedItems(ordered);
		}
	}, [collection.videos]);

	if (isLoading) {
		return <Spinner />;
	}

	let title = null;

	if (collection.groupId) {
		title = t('Team');
	}

	if (collection.isClubCollection) {
		title = t('Club');
	}

	const handleRemoveRecord = (videoId: number) => {
		const video = collection.videos.find((n) => n.videoId === videoId);

		if (video) {
			removeInRecord(collection, 'videos', video);
		}
	};

	const sortByRecent = () => {
		const newOrder = collection.videos.sort(
			(
				a: models.videoCollection.VideoCollectionItem,
				b: models.videoCollection.VideoCollectionItem
			) => a.video.id - b.video.id
		);

		setSortBy('recent_first');
		setOrderedItems(newOrder);
	};

	const sortByOldest = () => {
		const newOrder = collection.videos.sort(
			(
				a: models.videoCollection.VideoCollectionItem,
				b: models.videoCollection.VideoCollectionItem
			) => b.video.id - a.video.id
		);

		setSortBy('oldest_first');
		setOrderedItems(newOrder);
	};

	const handleSearch = (keywords: string) => {
		if (keywords.length > 0) {
			if (sortBy === 'oldest_first') {
				sortByOldest();
			} else {
				sortByRecent();
			}

			const filtered = collection.videos.filter(
				(item: models.videoCollection.VideoCollectionItem) =>
					localeIncludes(item.video.title, keywords)
			);

			setIsSearching(true);
			setOrderedItems(filtered);
		} else {
			setIsSearching(false);
			sortByRecent();
		}
	};

	const canEdit = models.canEdit(collection);
	const canDelete = models.canDelete(collection);

	const openModal = () => setEditing(true);
	const closeModal = () => setEditing(false);

	const handleRemove = async () => {
		await models.destroy(collection);

		replaceState(routes.VideoLibrary.Index(org.id, groupId));
	};

	const items: models.video.Video[] = orderedItems.map(
		(item: models.videoCollection.VideoCollectionItem) => {
			// Set video created at to collection item created at to be able to use library/group/Videos
			item.video.createdAt = item.createdAt;

			return item.video;
		}
	);

	const showFilterBar = items.length > 0 || isSearching;

	return (
		<Fragment>
			<Hero
				data-is-branded={!!collection.groupId}
				data-has-cover-image={!!collection?.group?.profileImageUrl?.length}
				style={
					{
						'--color': models.group.getPrimaryColorStylesheetString(
							collection?.group
						),
					} as CSSProperties
				}>
				{title && <Title>{title}</Title>}
				<Heading>
					{collection.name}

					{(canEdit || canDelete) && (
						<ContextMenu.Menu
							toggleWith={
								<HeadingContextMenuTrigger>
									<Icon name="context-menu" />
								</HeadingContextMenuTrigger>
							}>
							{canEdit && (
								<ContextMenu.Item onClick={openModal}>
									{t('Edit')}
								</ContextMenu.Item>
							)}
							{canDelete && (
								<Fragment>
									{canEdit && <ContextMenu.Divider />}
									<ContextMenu.ConfirmItem
										caution
										closeOnClick
										onConfirm={handleRemove}
										message={t(
											`Do you want to delete "{name}"? This action cannot be undone.`,
											{
												name: collection.name,
											}
										)}>
										{t('Delete')}
									</ContextMenu.ConfirmItem>
								</Fragment>
							)}
						</ContextMenu.Menu>
					)}
				</Heading>
				<Byline>
					<Avatar account={collection.account} size={25} />
					<div>{t('Created by')}</div>
					<div>{models.account.fullName(collection.account)}</div>
				</Byline>
				{!!collection?.group?.profileImageUrl?.length && (
					<GroupProfileImage src={collection.group.profileImageUrl} />
				)}
			</Hero>
			{showFilterBar && (
				<ActionBar.FilterBar>
					<ActionBar.PrimaryAction>
						<ActionBar.Search
							placeholder={t('Search in this collection…')}
							onChange={handleSearch}
						/>
					</ActionBar.PrimaryAction>
					<ContextMenu.Menu
						toggleWith={
							<Fragment>
								<LargeScreen>
									<Button icon="swap_vert">{t('Sort options')}</Button>
								</LargeScreen>
								<SmallScreen>
									<Button large icon="sort" />
								</SmallScreen>
							</Fragment>
						}>
						<ContextMenu.Item onClick={sortByRecent}>
							<ContextMenu.ItemIcon
								name="check"
								style={{
									opacity: sortBy === 'recent_first' ? 1 : 0,
									transform: 'scale(.8)',
								}}
							/>
							{t('Most recent first')}
						</ContextMenu.Item>
						<ContextMenu.Item onClick={sortByOldest}>
							<ContextMenu.ItemIcon
								name="check"
								style={{
									opacity: sortBy === 'oldest_first' ? 1 : 0,
									transform: 'scale(.8)',
								}}
							/>
							{t('Oldest first')}
						</ContextMenu.Item>
					</ContextMenu.Menu>
				</ActionBar.FilterBar>
			)}
			<Wrapper>
				{items.length > 0 ? (
					<Videos
						title={t('Videos')}
						items={items}
						removeRecord={handleRemoveRecord}
					/>
				) : (
					<OutlinePlaceholder
						icon="add-folder"
						title={t('Empty collection')}
						description={t('There are no videos in this collection.')}
					/>
				)}
			</Wrapper>

			{isEditing && (
				<CollectionModal collectionId={collectionId} onClose={closeModal} />
			)}
		</Fragment>
	);
}
