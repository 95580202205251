import { JSX } from 'react';
import { t } from '@transifex/native';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { useCurrentAccount, useFetchIdentity } from 'pkg/identity';

import Heading from 'components/Heading';
import { FormattedContent } from 'components/formatted-content';
import StepModal, { Step } from 'components/step-modal';

import InfoBox from 'components/form/info-box';
import Column from 'components/layout/column';

import Button from 'design/button';

const Text = styled(FormattedContent)`
	font-size: var(--font-size-sm);
`;

const CenteredColumn = styled(Column)`
	place-items: center;
	place-content: center;
`;

interface ConsentModalProps {
	relation: models.accountRelation.AccountRelation;
	onClose: () => void;
	onSave: () => Promise<void>;
}

export default function ConsentModal({
	relation,
	onClose,
	onSave,
}: ConsentModalProps): JSX.Element {
	const dispatch = useDispatch();
	const account = useCurrentAccount();
	const fetchIdentity = useFetchIdentity();

	const grantConsent = async () => {
		await dispatch(
			actions.accounts.grantAccountParentalConsent(relation.targetAccountId)
		);

		await fetchIdentity();
		await onSave();
		onClose();
	};

	const revokeConsent = async () => {
		await dispatch(
			actions.accounts.revokeAccountParentalConsent(relation.targetAccountId)
		);

		await fetchIdentity();
		await onSave();
		onClose();
	};

	let consentButton = (
		<Button primary large icon="person_check" onClick={grantConsent}>
			{t(`Grant permission`)}
		</Button>
	);

	if (
		models.accountRelation.hasParentalConsent(relation) &&
		relation.targetAccount.parentalConsentRevoked !== null
	) {
		consentButton = (
			<Button caution large onClick={revokeConsent}>
				{t(`Withdraw permission`)}
			</Button>
		);
	}

	return (
		<StepModal onClose={onClose}>
			<Step
				title={t(`Manage consent`)}
				hideNext
				spaciousBody
				prevLabel={t('Cancel')}>
				<Column spacing={styles.spacing._8}>
					<InfoBox color="blue">
						{t(
							`{name} is underage, and we need your permission to handle personal data regarding {name}.`,
							{
								name: relation.targetAccount.firstName,
							}
						)}
					</InfoBox>

					<Column>
						<Heading>{t(`What data do we process?`)}</Heading>
						<Text
							raw={t(
								`We try to process as little personal data as we can. To use 360Player we need the following data.`
							)}
						/>
						<Text
							allowedFormatters={['emails']}
							raw={t(
								`In addition we might collect other contact information for {name} or you. This is entirely optional and is up to you, {name}, and the teams {name} is a member of. Please refer to our Privacy Policy for details on what we collect and how we use it.`,
								{
									name: relation.targetAccount.firstName,
								}
							)}
						/>
					</Column>

					<Column>
						<Heading>{t(`What happens if I don't give permission?`)}</Heading>
						<Text
							allowedFormatters={['emails']}
							raw={t(
								`{name} won't be able to use 360Player. If you want to delete the data we have on {name}, email compliance@360player.com from {parent_email} or {user_email}.`,
								{
									name: relation.targetAccount.firstName,
									parent_email:
										relation.targetAccount?.parentalConsentEmail ||
										account?.email,
									user_email: relation.targetAccount.email,
								}
							)}
						/>
					</Column>

					<Column>
						<Heading>{t(`What happens after I give permission?`)}</Heading>
						<Text
							raw={t(
								` {name} will be able to use 360Player and our services. If you want to handle {name}'s permission to use 360Player in the future, bookmark this page.`,
								{
									name: relation.targetAccount.firstName,
								}
							)}
						/>
					</Column>

					<CenteredColumn>{consentButton}</CenteredColumn>
				</Column>
			</Step>
		</StepModal>
	);
}
