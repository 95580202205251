import { JSX, memo, ReactElement } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import { cssClasses, cssVarList } from 'pkg/css/utils';

import Avatar from 'components/avatar';

import * as css from './styles.css';

interface AvatarStackProps {
	size?: number;
	showRest?: boolean;
	prefixWith?: ReactElement<any, any>;
	renderTriggerWith?: ReactElement<any, any>;
	renderEmptyWith?: ReactElement<any, any>;
	renderRestWith?: string | JSX.Element;
	backdropColor?: string;
	// Max amount of items we want to show
	threshold?: number;

	onClick?: () => void;

	children?: JSX.Element;
	users?: models.user.User[];
	accounts?: models.account.Account[];
}

// This is the same as 'components/avatar/Stack' but this uses the new models.
function AvatarStack({
	users,
	accounts,
	size = 30,
	showRest = true,
	threshold = null,
	onClick = null,
	prefixWith = null,
	renderTriggerWith = null,
	renderEmptyWith = null,
	renderRestWith = null,
	backdropColor,
	children,
}: AvatarStackProps) {
	if ((!accounts || accounts.length === 0) && (!users || users.length === 0)) {
		return renderEmptyWith;
	}

	const numAccounts = accounts?.length;
	const numUsers = users?.length;

	let truncatedAccounts: models.account.Account[] = [];
	let truncatedUsers: models.user.User[] = [];

	let multiplier: number;
	let num: number;
	let restNum: number;

	if (numAccounts && numAccounts > 0) {
		if (threshold) {
			truncatedAccounts = accounts.slice(
				0,
				threshold + (renderTriggerWith ? 1 : 0)
			);
		} else {
			truncatedAccounts = accounts.slice(0, renderTriggerWith ? 2 : 3);
		}

		multiplier = truncatedAccounts.length + (renderTriggerWith ? 1 : 0);
		num = truncatedAccounts.length;
		restNum = accounts.length - truncatedAccounts.length;
	} else if (numUsers && numUsers > 0) {
		if (threshold) {
			truncatedUsers = users.slice(0, threshold + (renderTriggerWith ? 1 : 0));
		} else {
			truncatedUsers = users.slice(0, renderTriggerWith ? 2 : 3);
		}

		multiplier = truncatedUsers.length + (renderTriggerWith ? 1 : 0);
		num = truncatedUsers.length;
		restNum = users.length - truncatedUsers.length;
	}

	let wrapperSize = size * multiplier;

	if (num === 1) {
		wrapperSize = size;
	} else {
		// Since we're transforming all items -30% on the X axis we remove 30% of the total width of the wrapper
		// the last part "size * 0.3" is to add a third of the width from the first item that we don't transform
		wrapperSize = wrapperSize - wrapperSize * 0.3 + size * 0.3;
	}

	return (
		<div
			className={cssClasses(css.wrapper, onClick ? css.onClick : '')}
			onClick={onClick}>
			{prefixWith}
			<div
				className={css.stackedAvatars}
				style={cssVarList({
					size: `${wrapperSize}px`,
				})}>
				{truncatedAccounts?.map((account, n: number) => (
					<Avatar
						key={`stacked-account-${n}`}
						size={size}
						account={account}
						className={css.stackedAvatar}
						style={cssVarList({
							backdropColor,
							transform: `translateX(${-30 * n}%)`,
						})}
					/>
				))}
				{truncatedUsers?.map((user, n: number) => (
					<Avatar
						key={`stacked-user-${n}`}
						size={size}
						user={user}
						className={css.stackedAvatar}
						style={cssVarList({
							backdropColor,
							transform: `translateX(${-30 * n}%)`,
						})}
					/>
				))}
				{renderTriggerWith && (
					<div
						className={css.trigger}
						style={cssVarList({
							size: `${size}px`,
							transform: `translateX(${-30 * num + 1}%)`,
						})}>
						{renderTriggerWith}
					</div>
				)}
			</div>
			{showRest && !renderRestWith && restNum > 0 && (
				<div className={css.rest}>{t('+{num} more', { num: restNum })}</div>
			)}
			{renderRestWith && <div className={css.rest}>{renderRestWith}</div>}
			{children}
		</div>
	);
}

export default memo(AvatarStack);
