import styled from 'styled-components';
import { JSX, ReactNode } from 'react';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: var(--spacing-2);
	border-radius: var(--radius-2);

	@media (hover: hover) {
		&:hover {
			background-color: var(--palette-blue-200);
			color: var(--palette-blue-500);
		}
	}
`;

const IconBackground = ({ children }: { children: ReactNode }): JSX.Element => {
	return <Wrapper>{children}</Wrapper>;
};

export default IconBackground;
