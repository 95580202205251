import { t } from '@transifex/native';
import { JSX, Fragment } from 'react';
import { T, useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import { useCurrentOrganization, useCurrentUser } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { newChatParams } from 'pkg/chat';
import { link } from 'pkg/router/utils';
import useToggleState from 'pkg/hooks/useToggleState';
import * as actions from 'pkg/actions';
import store from 'pkg/store/createStore';

import { FormStateData } from 'routes/payments/hooks/useContactForm';
import { generatePayload } from 'routes/payments/contacts/Create';

import AddToGroupsModal from 'containers/user/AddToGroupsModal';
import AssignProductModal from 'containers/payment_platform/contacts/AssignProductModal';
import SelectContact from 'containers/payment_platform/SelectContact';

import CreateContactModal from 'components/payment_platform/contacts/CreateModal';
import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import { FormPayload } from 'components/form/Form';

import * as Context from 'design/context_menu';
import Button from 'design/button';
import { DialogOptions, useDialog } from 'design/dialog';

import * as css from './styles.css';

export interface UserAction {
	label: string;
	symbol: MaterialSymbolVariant;
	condition: boolean | models.Action;

	caution?: boolean;
	divider?: boolean;

	href?: string;
	onClick?: () => void;

	dialog?: DialogOptions;
}

interface UserActionProps {
	user: models.user.User;
}

interface UserGroupPayload {
	id: number;
	role: number;
}

export function UserActions({ user }: UserActionProps): JSX.Element {
	const org = useCurrentOrganization();

	const [addToGroupOpen, showAddToGroup, hideAddToGroup] = useToggleState();

	const [assignProductOpen, showAssignProduct, hideAssignProduct] =
		useToggleState();

	const [selectBillingOpen, showSelectBilling, hideSelectBilling] =
		useToggleState();

	const [createContactOpen, showCreateContact, hideCreateContact] =
		useToggleState();

	let userActions = usePossibleUserActions(user, {
		onAddToGroup: showAddToGroup,
		onAssignProduct: showAssignProduct,
		onSelectBillingContact: showSelectBilling,
		onArchiveContact: () => {
			return true;
		},
	});

	const numActions: number = userActions.length;

	// Filter out allowed actions or met conditions
	userActions = userActions.filter(({ condition }) => {
		if (typeof condition === 'boolean') {
			return condition;
		} else {
			return models.hasAllowedAction(user, condition);
		}
	});

	let visibleActions: UserAction[] = [];
	let contextActions: UserAction[] = [];

	if (numActions > 4) {
		visibleActions = userActions.slice(0, 3);
		contextActions = userActions.slice(3);
	} else {
		visibleActions = userActions;
	}

	const handleGroupAdd = (groups: UserGroupPayload[]) => {
		actions.memberships.addUserToGroups(user.id, groups);
	};

	const handleAssignBillingContact = (billingUserId: number) => {
		actions.users.update(user.id, { billingUserId })(store.dispatch);
	};

	const handleCreateBillingContact = async (
		data: FormPayload,
		contactData: FormStateData
	): Promise<void> => {
		const [, result] = await actions.users.createUser(
			generatePayload(data, contactData, org.id)
		)(store.dispatch);

		await actions.users.update(user.id, { billingUserId: result.id })(
			store.dispatch
		);
	};

	return (
		<Fragment>
			<div className={css.wrapper}>
				{visibleActions.map(({ label, symbol, href, onClick }) => (
					<Button
						iconPosition="top"
						icon={symbol}
						href={href}
						onClick={onClick}
						secondary
						block>
						{label}
					</Button>
				))}
				{contextActions.length > 0 && (
					<Context.Menu
						toggleWith={
							<Button secondary block iconPosition="top" icon="more_horiz">
								{t('More')}
							</Button>
						}>
						{contextActions.map((itemProperties, index) => (
							<Fragment>
								{itemProperties?.divider && index !== 0 && <Context.Divider />}
								<UserContextMenuItem {...itemProperties} />
							</Fragment>
						))}
					</Context.Menu>
				)}
			</div>
			{addToGroupOpen && (
				<AddToGroupsModal
					groupId={org.id}
					userId={user.id}
					onConfirm={handleGroupAdd}
					onClose={hideAddToGroup}
				/>
			)}
			{assignProductOpen && (
				<AssignProductModal
					organizationId={org.id}
					userIds={[user.id]}
					onClose={hideAssignProduct}
				/>
			)}
			{selectBillingOpen && (
				<SelectContact
					groupId={org.id}
					canCreateNew
					selectedContactId={user?.billingUserId}
					onConfirm={handleAssignBillingContact}
					onCreateNew={() => {
						showCreateContact();
						hideSelectBilling();
					}}
					onClose={hideSelectBilling}
				/>
			)}
			{createContactOpen && (
				<CreateContactModal
					groupId={org.id}
					onConfirm={handleCreateBillingContact}
					onClose={hideCreateContact}
				/>
			)}
		</Fragment>
	);
}

export function UserContextMenuItem({
	label,
	symbol,
	caution,
	href,
	onClick,
	dialog,
}: UserAction): JSX.Element {
	const ctx = Context.useContextMenu();

	const dialogAction = useDialog({
		...dialog,
		// Intercept and handle confirm action
		onConfirm: async () => {
			const didConfirm = await dialog.onConfirm();

			if (didConfirm) {
				ctx.close();
			}

			return didConfirm;
		},
	});

	const item = (
		<Fragment>
			<Context.ItemIcon name={symbol} />
			{label}
		</Fragment>
	);

	if (dialog) {
		return (
			<Context.Item
				caution={caution}
				closeOnClick={false}
				onClick={dialogAction}>
				{item}
			</Context.Item>
		);
	}

	if (href) {
		return <Context.LinkItem href={href}>{item}</Context.LinkItem>;
	}

	return <Context.Item onClick={onClick}>{item}</Context.Item>;
}

interface PossibleUserActionsCallbacks {
	onAddToGroup: () => void;
	onAssignProduct: () => void;
	onSelectBillingContact: () => void;
	onArchiveContact: () => boolean;
}

function usePossibleUserActions(
	user: models.user.User,
	{
		onAddToGroup,
		onAssignProduct,
		onSelectBillingContact,
		onArchiveContact,
	}: PossibleUserActionsCallbacks
): UserAction[] {
	const t = useT();
	const org = useCurrentOrganization();
	const currentUser = useCurrentUser();

	const isNotCurrentUser: boolean = user.id !== currentUser.id;

	return [
		{
			label: t('Edit'),
			symbol: 'edit',
			condition: models.Action.UserUpdate,
			href: routes.User.Settings(org.id, user.id),
		},
		{
			label: t('Invoice'),
			symbol: 'request_quote',
			condition: models.Action.UserCreateInvoice,
			href: link(routes.Invoice.New(org.id), {
				userIds: [user.id],
				returnUrl: routes.Organization.User.Profile.Show(
					org.id,
					user.id,
					'overview'
				),
			}),
		},
		{
			label: t('Group'),
			symbol: 'group_add',
			condition: models.Action.UserAddToGroup,
			onClick: onAddToGroup,
		},
		{
			label: t('Chat'),
			symbol: 'chat_bubble',
			condition: models.Action.UserCreateChat && isNotCurrentUser,
			href: link(routes.Chat.New(org.id), newChatParams([user])),
		},
		{
			label: t('Add note'),
			symbol: 'note_stack_add',
			condition: models.Action.UserCreateUserLog,
			href: routes.Organization.User.Profile.Show(org.id, user.id, 'notes'),
		},
		{
			label: t('Email'),
			symbol: 'outgoing_mail',
			condition: user.email?.length > 0 && isNotCurrentUser,
			href: `mailto:${user.email}`,
		},
		{
			divider: true,
			label: t('Assign product'),
			symbol: 'box_add',
			condition: models.Action.UserCreateUserProduct,
			onClick: onAssignProduct,
		},
		{
			label: t('Select billing contact'),
			symbol: 'person_add',
			condition: models.Action.UserUpdate,
			onClick: onSelectBillingContact,
		},
		{
			divider: true,
			label: t('Archive contact'),
			symbol: 'delete',
			condition: models.Action.UserRemove,
			caution: true,
			dialog: {
				destructive: true,
				symbol: 'person_off',
				symbolScale: 1.5,
				title: t('Archive {user}?', { user: user.firstName }),
				message: (
					<T
						_str="Archiving {user} will make them lose their access to {org}."
						user={<strong>{models.user.fullName(user)}</strong>}
						org={<strong>{org.name}</strong>}
					/>
				),
				confirmLabel: t('Archive'),
				onConfirm: onArchiveContact,
			},
		},
	];
}
