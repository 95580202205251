import { JSX } from 'react';
import { useT } from '@transifex/react';

import { cssVarList } from 'pkg/css/utils';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import DateTime from 'pkg/datetime';

import { useSmallScreen } from 'components/MediaQuery';
import Label from 'components/label';
import Avatar from 'components/avatar';

import Button from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './styles.css';

interface SubmittedUserProps {
	submission: models.formSubmission.FormSubmission;
}

export default function SubmittedUser({
	submission,
}: SubmittedUserProps): JSX.Element {
	const t = useT();

	const user: models.user.User = submission.submittedForUser;

	const date = DateTime.fromTimestamp(
		submission.createdAt
	).toLocaleDateString();

	const hasOrder = !!submission.orderId;
	const isSmallScreen = useSmallScreen();

	const gridStyling = hasOrder
		? {
				areas: `'avatar name date actions'`,
				columns: '24px 1fr auto auto',
			}
		: {
				areas: `'avatar name date'`,
				columns: '24px 1fr auto',
			};

	return (
		<div className={css.submittedUser} style={cssVarList(gridStyling)}>
			<Avatar className={css.submittedUserAvatar} user={user} size={24} />
			<strong>
				{user.firstName} {user.lastName}
			</strong>
			<Label
				small={isSmallScreen}
				className={css.submittedUserDate}
				color="green">
				{t('Registered {date}', { date })}
			</Label>
			{hasOrder && (
				<div className={css.submittedUserActions}>
					<Context.Menu toggleWith={<Button inline small icon="more_horiz" />}>
						<Context.LinkItem
							icon="request_quote"
							href={routes.Self.Invoice(
								user.organizationId,
								submission.orderId
							)}>
							{t('View invoice')}
						</Context.LinkItem>
					</Context.Menu>
				</div>
			)}
		</div>
	);
}
