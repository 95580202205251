export const brand: string = '#FCE84E';
export const white: string = '#FFFFFF';
export const black: string = '#0F0F10';

export type ColorSwatch = {
	100: string;
	200: string;
	300: string;
	400: string;
	500: string;
	600: string;
	700: string;
	800: string;
	900: string;
};

export type Rgb = {
	[key: string]: number[];
};

type NestedRgb = {
	[key: string]: number[] | Rgb;
};

type EventsRgb = {
	[key: string]: Rgb;
};

export enum Colors {
	gray,
	red,
	orange,
	green,
	blue,
	yellow,
	purple,
}

export type Color = keyof typeof Colors;

export const gray: ColorSwatch = {
	100: '#F9FAFB',
	200: '#F3F3F3',
	300: '#E1E1E1',
	400: '#C7C7C7',
	500: '#9D9D9D',
	600: '#656565',
	700: '#424242',
	800: '#303030',
	900: '#1E1E1E',
};

export const red: ColorSwatch = {
	100: '#FDE9E9',
	200: '#FDDEDE',
	300: '#F6A2A2',
	400: '#EB5757',
	500: '#EA3B3B',
	600: '#D21A1A',
	700: '#B61111',
	800: '#850B0B',
	900: '#610C0C',
};

export const orange: ColorSwatch = {
	100: '#FFF4EA',
	200: '#FEE1C7',
	300: '#FDA85B',
	400: '#FF9331',
	500: '#F07302',
	600: '#CE6200',
	700: '#9F4C00',
	800: '#753700',
	900: '#3E1D00',
};

export const green: ColorSwatch = {
	100: '#F2FDF6',
	200: '#DDFCE7',
	300: '#9CDAA7',
	400: '#52B664',
	500: '#34A749',
	600: '#1F7A30',
	700: '#236E31',
	800: '#175122',
	900: '#0F3316',
};

export const blue: ColorSwatch = {
	100: '#F2F6F9',
	200: '#EDF1FB',
	300: '#ABC1F7',
	400: '#509AF8',
	500: '#0066FF',
	600: '#0552C5',
	700: '#003B93',
	800: '#012C6E',
	900: '#001F4F',
};

export const yellow: ColorSwatch = {
	100: '#FEFAEF',
	200: '#FDF5DD',
	300: '#FDEBB5',
	400: '#FADB7E',
	500: '#FECD36',
	600: '#FDBE00',
	700: '#D99C00',
	800: '#8E6F00',
	900: '#4F4200',
};

export const purple: ColorSwatch = {
	100: '#FAF2FC',
	200: '#F8EDFB',
	300: '#E4C2FF',
	400: '#AA3DFF',
	500: '#8F00FF',
	600: '#7700D4',
	700: '#6700B8',
	800: '#4A0085',
	900: '#370062',
};

export const main: Rgb = {
	foregroundColor: [21, 21, 22],
	actionableLinkColor: [0, 102, 255],
	avatarBackgroundColor: [134, 147, 158],
};

export const icon: Rgb = {
	idleFillColor: [189, 198, 207],
	activeFillColor: [0, 102, 255],
};

export const card: NestedRgb = {
	backgroundColor: [255, 255, 255],
	innerDividerColor: [236, 241, 244],
	timestampColor: [126, 135, 143],
	contextMenuColor: [189, 198, 207],
	fieldBorderColor: [204, 213, 222],
	discussion: {
		backgroundColor: [240, 244, 247],
	},
	heading: {
		foregroundColor: [126, 135, 143],
	},
	impressions: {
		avatarBackgroundColor: [189, 198, 207],
	},
};

export const contextMenu: Rgb = {
	backgroundColor: [255, 255, 255],
	dividerColor: [236, 241, 244],
	borderColor: [236, 241, 244],
};

export const checklist: Rgb = {
	incompleteStatusColor: [255, 62, 3],
	completeStatusColor: [105, 206, 78],
};

export const navBar: NestedRgb = {
	backgroundColor: [41, 44, 52],
	blurredBackgroundColor: [37, 40, 49],
	menuItemColor: [255, 255, 255],
	subMenuItemColor: [21, 21, 22],
	menuItemDividerColor: [103, 108, 115],
	indicatorColor: [255, 232, 1],
	popoutMenu: {
		backgroundColor: [255, 255, 255],
	},
};

export const drawer: Rgb = {
	backgroundColor: [216, 224, 231],
	headerBackgroundColor: [240, 244, 247],
};

export const textField: Rgb = {
	backgroundColor: [240, 244, 247],
	borderColor: [189, 198, 207],
	focusBorderColor: [3, 169, 244],
};

export const pageTitle: Rgb = {
	foregroundColor: [47, 50, 54],
};

export const loader: Rgb = {
	fillColor: [0, 102, 255],
};

export const goals: Rgb = {
	barColor: [0, 102, 255],
	barColorComplete: [128, 173, 218],
};

export const slider: Rgb = {
	thumbColor: [0, 102, 255],
};

export const events: EventsRgb = {
	match: {
		backgroundColor: [212, 244, 213],
		foregroundColor: [7, 73, 12],
	},
	practice: {
		backgroundColor: [254, 243, 199],
		foregroundColor: [194, 65, 12],
	},
	meeting: {
		backgroundColor: [194, 236, 255],
		foregroundColor: [0, 61, 109],
	},
	camp: {
		backgroundColor: [245, 215, 250],
		foregroundColor: [74, 20, 140],
	},
	cup: {
		backgroundColor: [255, 204, 188],
		foregroundColor: [152, 0, 0],
	},
	uncategorized: {
		backgroundColor: [236, 239, 241],
		foregroundColor: [38, 50, 56],
	},
};

export const videoEditor: Rgb = {
	sidebar: [41, 44, 52],
	workspace: [59, 63, 75],
	gutter: [30, 31, 35],
	timeControls: [70, 76, 91],
	separator: [85, 91, 108],
};

export const videoPlayer = {
	timeline: [41, 44, 52],
	controls: [15, 15, 16],
};

export function getColorSwatch(color: Color): ColorSwatch {
	switch (color) {
		case 'blue':
			return blue;
		case 'gray':
			return gray;
		case 'green':
			return green;
		case 'orange':
			return orange;
		case 'purple':
			return purple;
		case 'red':
			return red;
		case 'yellow':
			return yellow;
	}
}
