export function ConfigurationWebhook(): string {
	return `/v1/external/adyen/webhooks/configuration`;
}

export function GetOnboardingLink(
	groupID: number,
	groupPaymentConfigID: number
): string {
	return `/v1/organizations/${groupID}/group-payment-config/${groupPaymentConfigID}/adyen-onboarding-link`;
}

export function InitiateManageTransferInstruments(
	groupID: number,
	groupPaymentConfigID: number
): string {
	return `/v1/organizations/${groupID}/group-payment-config/${groupPaymentConfigID}/adyen-manage-transfer-instruments`;
}

export function InitiateReporting(
	groupID: number,
	groupPaymentConfigID: number
): string {
	return `/v1/organizations/${groupID}/group-payment-config/${groupPaymentConfigID}/adyen-reporting`;
}

export function PaymentWebhook(): string {
	return `/v1/external/adyen/webhooks/payments`;
}
