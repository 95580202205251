import { JSX, Fragment, ChangeEvent } from 'react';
import { t } from '@transifex/native';

import { FilterOperator } from 'pkg/filters';
import { BaseFilterProps } from 'pkg/filters/types';
import DateTime, { Granularity } from 'pkg/datetime';
import useMixedState from 'pkg/hooks/useMixedState';
import {
	getDecrementedTimeStamp,
	getIncrementedTimestamp,
	msToSeconds,
} from 'pkg/date';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { DateFilterSettings } from 'pkg/filters/use_filters';

import DatePicker from 'components/form/date-picker';
import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

const getOperatorTranslation = (operator: OperatorKeys) => {
	switch (operator) {
		case 'last': {
			return t('is in the last');
		}
		case 'coming': {
			return t('is in the coming');
		}
		case 'after': {
			return t('is after');
		}
		case 'before': {
			return t('is before');
		}
		case 'equals': {
			return t('is equal to');
		}
		case 'between': {
			return t('is between');
		}
	}
};

type Intervals = 'month' | 'year' | 'day';

type DateProps = BaseFilterProps;

export type OperatorKeys =
	| 'last'
	| 'coming'
	| 'after'
	| 'equals'
	| 'between'
	| 'before';

export function DateSelect({
	filter,
	currentFilters,
	setFilter,
}: DateProps): JSX.Element {
	const settings = (filter.settings || {}) as DateFilterSettings;
	const now = new DateTime(new Date());
	const hasDateOperators = settings?.dateFilterOperators;

	const operatorMap: {
		[key in OperatorKeys]: FilterOperator;
	} = {
		last: FilterOperator.Between,
		coming: FilterOperator.Between,
		after: FilterOperator.GreaterThan,
		before: FilterOperator.LessThan,
		equals: FilterOperator.Between,
		between: FilterOperator.Between,
	};

	const hasCurrentFilter = currentFilters.length;
	const filterValues = currentFilters?.[0]?.values || [
		msToSeconds(now.startOfDay),
		msToSeconds(now.endOfDay) - 1,
	];

	let initOperator: OperatorKeys = 'last';
	// Check if we have a current filter and set correct operator
	if (hasCurrentFilter && currentFilters?.[0]?.operator) {
		initOperator = Object.keys(operatorMap).find(
			(key: OperatorKeys) => operatorMap[key] === currentFilters[0].operator
		) as OperatorKeys;

		if (currentFilters[0].operator === FilterOperator.Between) {
			initOperator = 'between';
		}
	} else if (hasDateOperators) {
		// if we have dateFilterOperators we want to set to the first one in the list
		initOperator = settings.dateFilterOperators[0];
	}

	const [filterState, setFilterState] = useMixedState<{
		operator: OperatorKeys;
		dates: number[];
		count: number;
		interval: Intervals;
	}>({
		operator: initOperator,
		dates: filterValues as number[],
		count: 1,
		interval: 'month',
	});

	const showDatePickerValues = ['after', 'before', 'equals', 'between'];

	// since the filter component has pre-filled values, insta set the filter when opening the filter.
	useComponentDidMount(() => {
		if (!hasCurrentFilter) {
			const now = new DateTime(new Date());
			setFilter({ ...filter, operator: FilterOperator.Between }, [
				now.prev(Granularity.month, 1).getUnixTimestamp(),
				now.getUnixTimestamp(),
			]);
		}
	});

	const handleDatePickerChange = (dates: Date[]) => {
		let timestampedDates = [
			msToSeconds(new DateTime(new Date(dates[0])).startOfDay),
		];

		if (dates.length > 1) {
			timestampedDates = [
				...timestampedDates,
				msToSeconds(new DateTime(new Date(dates[1])).endOfDay) - 1,
			];
		} else if (filterState.operator === 'equals') {
			timestampedDates = [
				...timestampedDates,
				msToSeconds(new DateTime(new Date(dates[0])).endOfDay) - 1, // avoid the next day to appear selected as well
			];
		}

		setFilterState({
			dates: timestampedDates,
		});

		setFilter(
			{ ...filter, operator: operatorMap[filterState.operator] },
			timestampedDates,
			false
		);
	};

	const handleIntervalChange = (e: ChangeEvent<HTMLSelectElement>) => {
		const { value } = e.target;
		const now = new DateTime(new Date());
		let dates = [];

		if (filterState.operator === 'last') {
			dates = [
				msToSeconds(
					getDecrementedTimeStamp(
						value,
						new DateTime(new Date()).startOfDay,
						filterState.count
					)
				),
				msToSeconds(now.endOfDay),
			];
		} else {
			dates = [
				msToSeconds(now.startOfDay),
				msToSeconds(
					getIncrementedTimestamp(
						value,
						new DateTime(new Date()).endOfDay,
						filterState.count
					)
				),
			];
		}

		setFilterState({
			dates,
			interval: value as Intervals,
		});

		setFilter(
			{ ...filter, operator: operatorMap[filterState.operator] },
			dates
		);
	};

	const handleCountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const now = new DateTime(new Date());
		let dates = [];

		if (filterState.operator === 'last') {
			dates = [
				msToSeconds(
					getDecrementedTimeStamp(
						filterState.interval,
						new DateTime(new Date()).startOfDay,
						Number.parseInt(value, 10)
					)
				),
				msToSeconds(now.endOfDay),
			];
		} else {
			dates = [
				msToSeconds(now.startOfDay),
				msToSeconds(
					getIncrementedTimestamp(
						filterState.interval,
						new DateTime(new Date()).endOfDay,
						Number.parseInt(value, 10)
					)
				),
			];
		}

		setFilterState({
			dates,
			count: Number.parseInt(value, 10),
		});

		setFilter(
			{ ...filter, operator: operatorMap[filterState.operator] },
			dates
		);
	};

	const handleOperatorChange = (
		e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const { value } = e.target;

		const handleNewTimestamps = () => {
			const now = new DateTime(new Date());

			if (showDatePickerValues.includes(value)) {
				if (operatorMap[value as OperatorKeys] === FilterOperator.Between) {
					return [now.startOfDay, now.endOfDay];
				}

				return [new DateTime(new Date()).getTimestamp()];
			}

			if (value === 'last') {
				return [
					getDecrementedTimeStamp(
						filterState.interval,
						new DateTime(new Date()).startOfDay,
						filterState.count
					),
					now.endOfDay,
				];
			}

			if (value === 'coming') {
				return [
					now.startOfDay,
					getIncrementedTimestamp(
						filterState.interval,
						new DateTime(new Date()).endOfDay,
						filterState.count
					),
				];
			}
		};

		let dates = handleNewTimestamps();
		dates = dates.map((d) => msToSeconds(d));

		setFilterState({
			dates,
			operator: value as OperatorKeys,
		});

		setFilter(
			{ ...filter, operator: operatorMap[value as OperatorKeys] },
			dates
		);
	};

	return (
		<Fragment>
			<Column>
				<Input.Select
					name="operator"
					defaultValue={filterState.operator}
					onChange={handleOperatorChange}>
					{hasDateOperators
						? settings.dateFilterOperators.map((operator, index) => (
								<option key={index} value={operator}>
									{getOperatorTranslation(operator)}
								</option>
							))
						: Object.keys(operatorMap).map((operator: OperatorKeys, index) => (
								<option key={index} value={operator}>
									{getOperatorTranslation(operator)}
								</option>
							))}
				</Input.Select>
				{showDatePickerValues.includes(filterState.operator) ? (
					<DatePicker
						dates={[
							DateTime.fromTimestamp(filterState.dates[0]).toDate(),
							DateTime.fromTimestamp(filterState.dates[1]).toDate(),
						]}
						handleChange={handleDatePickerChange}
						multipleDates={filterState.operator === FilterOperator.Between}
					/>
				) : (
					<Row>
						<Input.Field
							name="count"
							placeholder="Amount"
							type="number"
							value={filterState.count}
							onChange={handleCountChange}
						/>
						<Input.Select
							name="interval"
							value={filterState.interval}
							onChange={handleIntervalChange}>
							<option value="month">{t('months')}</option>
							<option value="week">{t('weeks')}</option>
							<option
								value="day"
								data-testid="filters.date_select.interval.days">
								{t('days')}
							</option>
						</Input.Select>
					</Row>
				)}
			</Column>
		</Fragment>
	);
}
