import { JSX, ChangeEvent, Fragment, useContext, useState } from 'react';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import DateTime from 'pkg/datetime';
import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentGroup } from 'pkg/identity';
import { isBetween } from 'pkg/date';

import SelectMatchCompetition from 'routes/event/create/MatchCompetitions';
import { EventFormContext } from 'routes/event/create';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';

import Column from 'components/layout/column';
import Row from 'components/layout/row';
import * as FormTable from 'components/form/Table';
import * as Input from 'components/form/inputs';
import { Title } from 'components/form/Section';
import { Spinner } from 'components/loaders/spinner';
import MatchCompetitionModal from 'components/match-competition/modal';
import { asString, FormPayload } from 'components/form/Form';

import Button from 'design/button';

const MatchDetails = (): JSX.Element => {
	const EventContext = useContext(EventFormContext);

	const [modal, setModal] = useState('');
	const group = useCurrentGroup();

	const competitionCollection =
		useCollection<models.matchCompetition.MatchCompetition>(
			endpoints.MatchCompetition.List(),
			{
				queryParams: new URLSearchParams({
					group_id: group.id.toString(),
				}),
			}
		);
	const matchCompetitions = competitionCollection.records;

	const matchStartsAtDate = new Date(
		EventContext.formState.match.startsAt * 1000
	);
	const timePickerValue = [
		matchStartsAtDate.getHours(),
		matchStartsAtDate.getMinutes(),
	]
		.map((p) => p.toString().padStart(2, '0'))
		.join(':');

	const selectedMatchCompetition = matchCompetitions.find(
		(competition) =>
			competition.id === EventContext.formState.match.matchCompetitionId
	);

	const handleMatchChange = (
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const { name, value, type, checked } = event.target as HTMLInputElement;

		let newValue: string | boolean | number =
			type === 'checkbox' ? checked : value;

		if (name === 'duration') {
			newValue = Number.parseInt(newValue as string, 10);
		}

		const newMatchData = {
			...EventContext.formState.match,
			[name]: newValue,
		};

		EventContext.setFormState({ match: newMatchData });
	};

	const handleRemoveSelectedCompetition = () => {
		const newMatchData = {
			...EventContext.formState.match,
			matchCompetitionId: 0,
		};

		EventContext.setFormState({ match: newMatchData });
	};

	const handlePracticeToggle = () => {
		const newMatchData = {
			...EventContext.formState.match,
			type:
				EventContext.formState.match.type === 'default'
					? 'practice'
					: 'default',
		};
		EventContext.setFormState({ match: newMatchData });
	};

	const handleKickoffTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
		const nextDate = new Date(EventContext.formState.startsAt * 1000);
		const [h, m] = event.target.value
			.split(':')
			.map((p) => Number.parseInt(p, 10));

		nextDate.setHours(h, m, 0);

		const startsAt = new DateTime(nextDate).getUnixTimestamp();

		const newMatchData = {
			...EventContext.formState.match,
			startsAt,
		};

		EventContext.setFormState({
			errors: {
				...EventContext.formState.errors,
				kickOff: !isBetween(
					startsAt,
					EventContext.formState.startsAt,
					EventContext.formState.endsAt
				),
			},
			match: newMatchData,
		});
	};

	const handleMatchCompetitionChange = (id: number) => {
		const newMatchData = {
			...EventContext.formState.match,
			matchCompetitionId: id,
		};

		EventContext.setFormState({ match: newMatchData });
		closeModal();
	};

	const handleNewCompetition = async (data: FormPayload) => {
		const payload: models.matchCompetition.CreatePayload = {
			groupId: group.id,
			title: asString(data.title),
			description: asString(data.description),
		};

		const [req, resp] = await models.create<
			models.matchCompetition.MatchCompetitionPayload,
			models.matchCompetition.MatchCompetition
		>(endpoints.MatchCompetition.Create(), payload);

		if (req.ok) {
			competitionCollection.addRecord(resp);

			const newMatchData = {
				...EventContext.formState.match,
				matchCompetitionId: resp.id,
			};

			EventContext.setFormState({ match: newMatchData });

			closeModal();
		}
	};

	const closeModal = () => setModal('');
	const showNewCompetition = () => setModal('newCompetition');
	const showSelectCompetitionModal = () => setModal('selectCompetition');

	if (competitionCollection.isLoading) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<div>
				<Title>{t(`Match details`)}</Title>
				<Column spacing={styles.spacing._7}>
					<Input.Group label={t(`Opponent (Optional)`)}>
						<Input.Field
							name="opponentName"
							defaultValue={EventContext.formState.match.opponentName}
							onChange={handleMatchChange}
							changeDelay={100}
							testid="events.form.match.opponent"
							maxLength={255}
						/>
					</Input.Group>
					<Input.Group>
						<Row autoColumns="max-content">
							<Input.Control
								type="radio"
								label={t('Home', { _context: 'match details' })}
								checked={EventContext.formState.match.isHome}
								onChange={() => {
									EventContext.setFormState({
										match: { ...EventContext.formState.match, isHome: true },
									});
								}}
							/>
							<Input.Control
								type="radio"
								label={t('Away')}
								checked={!EventContext.formState.match.isHome}
								onChange={() => {
									EventContext.setFormState({
										match: { ...EventContext.formState.match, isHome: false },
									});
								}}
							/>
						</Row>
					</Input.Group>
					<Input.Group
						label={t(`Kickoff time`)}
						errorMessage={
							EventContext.formState.errors.kickOff &&
							t(
								`The kickoff must be scheduled within the event's start and end times.`
							)
						}>
						<Input.Field
							name="time"
							placeholder={t(`Select time`)}
							defaultValue={timePickerValue}
							onChange={handleKickoffTimeChange}
							type="time"
							changeDelay={100}
							testid="events.form.match.kickoff"
						/>
					</Input.Group>
					<Input.Group label={t(`Duration (minutes)`)}>
						<Input.Field
							name="duration"
							placeholder="90"
							defaultValue={EventContext.formState.match.duration}
							onChange={handleMatchChange}
							autoComplete="off"
							pattern="[0-9]*"
							type="number"
							changeDelay={100}
						/>
					</Input.Group>
					<Input.Group label={t(`Competition`)} optional>
						{!selectedMatchCompetition && (
							<Fragment>
								<LargeScreen>
									<Row autoColumns="min-content">
										<Button
											icon="search"
											label={t('Select competition')}
											secondary
											onClick={showSelectCompetitionModal}
										/>
										<Button
											icon="add"
											secondary
											label={t(`New competition`)}
											onClick={showNewCompetition}
										/>{' '}
									</Row>
								</LargeScreen>
								<SmallScreen>
									<Column>
										<Button
											icon="search"
											label={t('Select competition')}
											secondary
											onClick={showSelectCompetitionModal}
											block
										/>
										<Button
											icon="add"
											secondary
											label={t(`New competition`)}
											onClick={showNewCompetition}
											block
										/>
									</Column>
								</SmallScreen>
							</Fragment>
						)}
						{modal === 'selectCompetition' && (
							<SelectMatchCompetition
								matchCompetitions={matchCompetitions}
								handleMatchCompetitionChange={handleMatchCompetitionChange}
								selectedMatchCompetition={selectedMatchCompetition}
								closeModal={closeModal}
							/>
						)}
						{selectedMatchCompetition && (
							<FormTable.Table>
								<tbody>
									<FormTable.TableRow>
										<FormTable.Cell>
											{selectedMatchCompetition.title}
										</FormTable.Cell>
										<FormTable.IconCell onClick={showSelectCompetitionModal}>
											<Icon name="edit" />
										</FormTable.IconCell>
										<FormTable.IconCell
											onClick={handleRemoveSelectedCompetition}>
											<Icon name="close-circle" />
										</FormTable.IconCell>
									</FormTable.TableRow>
								</tbody>
							</FormTable.Table>
						)}
					</Input.Group>
					<Input.Group label={t(`Field`)} optional>
						<Input.Field
							name="location"
							defaultValue={
								EventContext.formState.match.location ||
								EventContext.formState.location
							}
							onChange={handleMatchChange}
							autoComplete="off"
							maxLength={255}
							changeDelay={100}>
							<Input.Prefix>
								<Icon name="location" />
							</Input.Prefix>
						</Input.Field>
					</Input.Group>
					<Input.Control
						type="toggle"
						label={t(`Practice game`)}
						description={t(
							`If this match is a practice game, toggle this switch to be able to filter it out in reporting.`
						)}
						checked={EventContext.formState.match.type === 'practice'}
						onChange={handlePracticeToggle}
					/>
				</Column>
			</div>
			{modal === 'newCompetition' && (
				<MatchCompetitionModal
					handleSave={handleNewCompetition}
					onClose={closeModal}
				/>
			)}
		</Fragment>
	);
};

export default MatchDetails;
