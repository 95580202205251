import { JSX } from 'react';
import styled from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Icon from 'components/icon';

import GroupItem, {
	CoverTextContainer,
	CoverText,
	CoverType,
	GroupItemAction,
} from 'components/library/GroupItem';

const ClipCountContainer = styled.div`
	background: hsla(0, 0%, 0%, 0.25);
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 30%;
	display: grid;
	place-items: center;
	place-content: center;
	gap: var(--spacing-1);
	color: currentColor;
	z-index: 40;
`;

const ClipCountValue = styled.span`
	font-size: var(--font-size-lg);
	font-weight: var(--font-weight-semibold);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-base);
	}
`;

const ClipCountLabel = styled.span`
	font-size: var(--font-size-sm);

	@media ${styles.breakpoint.small} {
		font-size: var(--font-size-xs);
	}
`;

interface VideoPlaylistGroupItemProps {
	href?: string;
	embedded?: boolean;
	item: models.videoPlaylist.VideoPlaylist;
	actions?: GroupItemAction<models.videoPlaylist.VideoPlaylist>[];
	queryString?: string;
}

export default function VideoPlaylistGroupItem({
	href,
	embedded,
	item,
	actions,
	queryString,
}: VideoPlaylistGroupItemProps): JSX.Element {
	const org = useCurrentOrganization();
	const visibilityLabel = item.groupId
		? item.group?.name || t('Team')
		: t('Only me');

	if (!href) {
		href = routes.VideoPlaylist.Show(org.id, item.id);
	}

	if (queryString) {
		href += queryString;
	}

	let sequenceCount = item.sequenceCount;

	if (!sequenceCount && 'sequences' in item) {
		sequenceCount = item.sequences.length;
	}

	return (
		<GroupItem
			item={item}
			actions={actions}
			account={item.account}
			title={item.title}
			createdAt={item.createdAt}
			isNew={models.videoPlaylist.isNew(item)}
			href={href}
			visibilityLabel={visibilityLabel}
			backdropColor={styles.palette.green[500]}
			embedded={embedded}>
			<CoverTextContainer>
				<CoverType>
					<Icon name="video-playlist" size={1.8} />
					{t('Playlist')}
				</CoverType>
				<CoverText>{item.title}</CoverText>
			</CoverTextContainer>
			<ClipCountContainer>
				<ClipCountValue>{sequenceCount || 0}</ClipCountValue>
				<ClipCountLabel>{t('clips')}</ClipCountLabel>
			</ClipCountContainer>
		</GroupItem>
	);
}
