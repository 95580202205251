import { JSX } from 'react';
import { t } from '@transifex/native';
import styled from 'styled-components';
import { List } from 'immutable';

import Video from 'pkg/models/video';

import * as models from 'pkg/api/models';

import ListItem from 'containers/video/ListItem';

import AssetImage from 'components/AssetImage';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import MissingEntities from 'design/placeholders/block';
import Button from 'design/button';

const NextButton = styled(Button)`
	justify-self: center;
	margin-top: var(--spacing-4);
`;

interface VideoListProps {
	title: string;
	activeMembership: models.membership.Membership;
	linkToTaggedUser?: boolean;
	videos: List<Video>;
	collectionId?: number;
	currentTab?: string;
	showAddVideoButton?: boolean;
	contextMenu?: JSX.Element;
	next?: boolean;
	isFetching?: boolean;
	onNext?: () => void;
	openAddVideo?: () => void;
}

export default function VideoList({
	title,
	videos,
	currentTab = 'my',
	contextMenu,
	showAddVideoButton,
	openAddVideo,
	next,
	isFetching,
	onNext,
	activeMembership,
	linkToTaggedUser,
}: VideoListProps): JSX.Element {
	const image = <AssetImage src="img/missing-entities/video-analytics.svg" />;

	const button = (
		<Button
			secondary
			icon="add"
			label={t(`Add video!`)}
			onClick={openAddVideo}
			testid="match.add_video_button"
		/>
	);

	let missingEntitiesTitle = t('My videos');

	let missingEntitiesDescription = models.membership.isAdminOrStaff(
		activeMembership
	)
		? t(`Use our powerful video analytics tool to get insights for yourself.`)
		: t(`Here you can see your own videos to analyze and create highlights!`);

	if (currentTab === 'team') {
		missingEntitiesTitle = models.membership.isAdminOrStaff(activeMembership)
			? t(`Analyze your teams videos`)
			: t(`No videos added yet!`);

		missingEntitiesDescription = models.membership.isAdminOrStaff(
			activeMembership
		)
			? t(`Use our powerful video analytics tool to get insights for team.`)
			: t(
					`Here you can see your team's videos to analyze and create highlights!`
				);
	}

	return (
		<Column>
			<SectionTitle>
				{title}
				{contextMenu}

				{showAddVideoButton && videos.size > 0 && openAddVideo && (
					<Button icon="add" label={t(`Add video`)} onClick={openAddVideo} />
				)}
			</SectionTitle>
			{videos.map((video: Video, index: number) => {
				return (
					<ListItem
						video={video}
						key={index}
						linkToTaggedUser={linkToTaggedUser}
						currentTab={currentTab}
					/>
				);
			})}
			{isFetching && <Spinner />}
			{next && !isFetching && (
				<NextButton onClick={onNext}>{t('Load more')}</NextButton>
			)}
			{videos.size === 0 && !isFetching && (
				<MissingEntities
					helpUrl="https://help.360player.com/article/18-how-to-add-a-video"
					title={missingEntitiesTitle}
					description={missingEntitiesDescription}
					actions={
						(models.membership.isAdminOrStaff(activeMembership) ||
							currentTab === 'my') &&
						openAddVideo &&
						button
					}
					image={image}
				/>
			)}
		</Column>
	);
}
