import { JSX, ChangeEvent, Fragment, useState } from 'react';
import { t } from '@transifex/native';
import { useFormContext } from 'react-hook-form';

import spacing from 'pkg/config/spacing';

import * as objects from 'pkg/objects';

import ConditionRulesInput from 'routes/group/settings/tabs/group_links/ConditionRulesInput';
import { ConditionType } from 'routes/group/settings/tabs/group_links/GroupLink';

import SectionTitle from 'components/SectionTitle';
import * as Card from 'components/Card';
import Icon from 'components/icon';

import * as Input from 'components/form/inputs';
import Row from 'components/layout/row';
import Column from 'components/layout/column';

import Button from 'design/button';

import * as css from './styles.css';

interface Condition {
	id: string;
	type: string;
	value: any;
}
interface Conditions {
	[key: string]: Condition;
}
interface RulesDisplayProps {
	groupId: number;
	deleteRule: (index: number) => void;

	existingConditions?: Conditions;
	ruleNumber?: number;
}

export default function RulesDisplay({
	groupId,
	existingConditions,
	ruleNumber,
	deleteRule,
}: RulesDisplayProps): JSX.Element {
	const [conditions, setConditions] = useState<Conditions>(existingConditions);
	const { unregister } = useFormContext();
	const handleAllMustMatch = (event: ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		if (value === 'all') {
			createAllMustMatchCondition(true);
		}
		if (value === 'any') {
			createAllMustMatchCondition(false);
		}
	};
	const createAllMustMatchCondition = (
		allMustMatch: boolean
	): Promise<void> => {
		const id = `rules.rule${ruleNumber}.conditions.cond0`;

		setConditions({
			...conditions,
			[id]: { value: allMustMatch, type: 'allMustMatch', id },
		});
		return Promise.resolve();
	};
	const handleTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const value = event.currentTarget.value;
		const name = event.currentTarget.dataset.conditionName;

		setConditions({
			...conditions,
			[name]: { ...conditions[name], type: value, value: null },
		});
	};
	const removeCondition = async (conditionId: string, inputId: string) => {
		setConditions(objects.omit(conditions, conditionId));
		unregister(inputId);
	};

	const removeRule = (index: number) => {
		deleteRule(index);
		unregister(`rules.rule${index}`);
	};

	const handleValueChange = (name: string, value: any) => {
		setConditions({
			...conditions,
			[name]: { ...conditions[name], value },
		});
	};
	const selectedConditions =
		Object.values(conditions).map((condition) => {
			return condition.type;
		}) || [];

	const remainingConditions = Object.values(ConditionType).find(
		(condition) =>
			!selectedConditions.includes(condition) && condition !== 'allMustMatch'
	);

	const addCondition = () => {
		const nextIndex = Object.keys(conditions).length + 1;
		const id = `rules.rule${ruleNumber}.conditions.cond${nextIndex}`;

		setConditions({
			...conditions,
			[id]: {
				value: '',
				type: remainingConditions,
				id,
			},
		});
	};

	return (
		<Card.Base className={css.linkWrapper}>
			<Column spacing={spacing._4}>
				<SectionTitle>
					<div>{t('Rule {number}', { number: ruleNumber + 1 })}</div>
					<Button
						secondary
						caution
						inline
						icon="delete"
						noPadding
						onClick={() => removeRule(ruleNumber)}>
						{t('Remove rule')}
					</Button>
				</SectionTitle>
				<Input.Select
					small
					onChange={handleAllMustMatch}
					name={`rules.rule${ruleNumber}.conditions.cond0.allMustMatch`}
					defaultValue={
						conditions
							? Object.values(conditions)[0]?.value === false
								? 'any'
								: 'all'
							: 'all'
					}>
					<option value="all">
						{t('Show link if ALL of the following conditions are met')}
					</option>
					<option value="any">
						{t('Show link if ANY of the following conditions are met')}
					</option>
				</Input.Select>
				{Object.values(conditions).filter(
					(value) => value.type !== 'allMustMatch'
				).length > 0 && (
					<Row columns="1fr 3fr 20px">
						<div className={css.conditionColumnTitle}>{t('Condition')}</div>
						<div className={css.conditionColumnTitle}>{t('Value')}</div>
					</Row>
				)}
				{Object.entries(conditions).map(([key, condition], index) => {
					if (condition.type === 'allMustMatch') {
						return null;
					}

					return (
						<Fragment key={index}>
							<Row columns="1fr 3fr 20px">
								<Input.Group>
									<Input.Select
										small
										name={`${condition.id}.type`}
										data-condition-name={condition.id}
										onChange={handleTypeChange}
										defaultValue={condition.type}>
										<option
											value={ConditionType.Groups}
											disabled={selectedConditions?.includes(
												ConditionType.Groups
											)}>
											{t('Groups')}
										</option>
										<option
											value={ConditionType.Age}
											disabled={selectedConditions?.includes(
												ConditionType.Age
											)}>
											{t('Age')}
										</option>
										<option
											value={ConditionType.Capability}
											disabled={selectedConditions?.includes(
												ConditionType.Capability
											)}>
											{t('Capability')}
										</option>
										<option
											value={ConditionType.ClubLobby}
											disabled={selectedConditions?.includes(
												ConditionType.ClubLobby
											)}>
											{t('Club lobby')}
										</option>
										<option
											value={ConditionType.Parent}
											disabled={selectedConditions?.includes(
												ConditionType.Parent
											)}>
											{t('Parents')}
										</option>
										<option
											value={ConditionType.Gender}
											disabled={selectedConditions?.includes(
												ConditionType.Gender
											)}>
											{t('Gender')}
										</option>
										<option
											value={ConditionType.Role}
											disabled={selectedConditions?.includes(
												ConditionType.Role
											)}>
											{t('Role')}
										</option>
									</Input.Select>
								</Input.Group>
								<div>
									<ConditionRulesInput
										handleValueChange={handleValueChange}
										groupId={groupId}
										condition={condition.type}
										name={condition.id}
										initialValue={condition.value}
									/>
								</div>
								<Icon
									name="delete"
									size={1.2}
									onClick={() => removeCondition(key, condition.id)}
									className={css.deleteIcon}
								/>
							</Row>
						</Fragment>
					);
				})}
				<Row justify="start">
					<Button
						secondary
						inline
						icon="add"
						noPadding
						onClick={addCondition}
						disabled={!remainingConditions}>
						{t('Add condition')}
					</Button>
				</Row>
			</Column>
		</Card.Base>
	);
}
