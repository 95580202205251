import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as flashActions from 'pkg/actions/flashes';

import { CollectionResponse, CollectionSort } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import copyString from 'pkg/copyString';
import { useCurrentOrganization } from 'pkg/identity';
import { formatToCurrency } from 'pkg/i18n/formatters';
import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';

import Label from 'components/label';
import Icon from 'components/icon';

import * as Input from 'components/form/inputs';

import * as Context from 'design/context_menu';
import * as Table from 'design/table';
import Button from 'design/button';

interface DiscountRowProps {
	selectable: boolean;

	discountsCollection: CollectionResponse<models.discount.Discount>;

	discount: models.discount.Discount;
}

function DiscountRow({
	discount,
	discountsCollection,
	selectable,
}: DiscountRowProps) {
	const organization = useCurrentOrganization();
	const status = models.discount.getStatus(discount);

	const isActive = models.discount.isActive(discount);

	const handleCopyCode = () => {
		flashActions.show({
			title: t('Code copied to clipboard'),
		});
		copyString(discount.code);
	};

	const updateStatus = async () => {
		const [req, resp] = await models.discount.update(discount, {
			isActive: !isActive,
		});

		if (req) {
			discountsCollection.replaceRecord(resp);
		}
	};

	const handleDelete = async () => {
		const req = await models.discount.remove(discount);

		if (req) {
			flashActions.show({
				title: t('Discount has been removed'),
			});
			discountsCollection.removeRecord(discount.id);
		}
	};

	const handleSelect = () => {
		discountsCollection.selection.selectOnlyOne(discount.id);
	};

	let recurringSettingString = t('Only initial invoice');

	if (discount.amountOff && discount.splitAmount) {
		recurringSettingString = t('Divide total amount');
	}

	if (discount.maxRecurringCount === 0) {
		recurringSettingString = t('Discount every invoice');
	}

	let recurringCountString = '';

	if (discount.maxRecurringCount > 1) {
		recurringCountString = discount.maxRecurringCount.toString();
	}

	return (
		<Table.Row>
			<Table.Cell onClick={handleSelect || undefined}>
				{selectable && (
					<Input.Control
						type="radio"
						onChange={handleSelect}
						checked={discountsCollection.selection.isSelected(discount.id)}
					/>
				)}
				{discount.title}
			</Table.Cell>
			<Table.Cell onClick={handleSelect || undefined}>
				{discount.code}
			</Table.Cell>
			<Table.Cell onClick={handleSelect || undefined}>
				{models.discount.getDiscountType(discount)}
			</Table.Cell>
			<Table.Cell onClick={handleSelect || undefined}>
				{models.discount.isPercentage(discount)
					? `${discount.percentOff}%`
					: `${formatToCurrency(
							discount.amountOff / 100,
							organization.currency
						)}`}
			</Table.Cell>
			<Table.Cell onClick={handleSelect || undefined}>
				<Label color={status.color}>{status.text}</Label>
			</Table.Cell>
			<Table.Cell onClick={handleSelect || undefined}>
				{discount.currentUsageCount}
			</Table.Cell>
			<Table.Cell onClick={handleSelect || undefined}>
				{discount.maxUsageCount}
			</Table.Cell>
			<Table.Cell onClick={handleSelect || undefined}>
				{recurringCountString}
			</Table.Cell>
			<Table.Cell>{recurringSettingString}</Table.Cell>
			<Table.Cell onClick={handleSelect || undefined}>
				{discount.updatedAt
					? DateTime.fromTimestamp(discount.updatedAt).toLocaleDateString()
					: '-'}
			</Table.Cell>
			<Table.Cell onClick={handleSelect || undefined}>
				{DateTime.fromTimestamp(discount.createdAt).toLocaleDateString()}
			</Table.Cell>
			<Table.ActionCell>
				<Context.Menu toggleWith={<Icon name="context-menu" />}>
					<Context.LinkItem
						href={routes.Discounts.Edit(organization.id, discount.id)}>
						<Context.ItemIcon name="edit" />
						{t('Edit')}
					</Context.LinkItem>
					<Context.Item onClick={handleCopyCode}>
						<Context.ItemIcon name="content_copy" />
						{t('Copy discount code')}
					</Context.Item>
					<Context.Divider />
					<Context.Label>{t('Availibilty')}</Context.Label>
					<Context.Item justifyContent="stretch">
						<Input.Control
							type="radio"
							label={t('Active')}
							position="after"
							checked={isActive}
							onChange={updateStatus}
						/>
					</Context.Item>
					<Context.Item justifyContent="stretch">
						<Input.Control
							type="radio"
							label={t('Inactive')}
							position="after"
							checked={!isActive}
							onChange={updateStatus}
						/>
					</Context.Item>
					<Context.Divider />
					<Context.ConfirmItem
						message={t('Are you sure you want to remove this discount?')}
						caution
						onConfirm={handleDelete}>
						<Context.ItemIcon name="delete" />
						{t('Delete')}
					</Context.ConfirmItem>
				</Context.Menu>
			</Table.ActionCell>
		</Table.Row>
	);
}

interface DiscountsTableProps {
	sort: CollectionSort;
	hideContextColumn?: boolean;
	selectable?: boolean;

	discountsCollection: CollectionResponse<models.discount.Discount>;
}

export default function DiscountsTable({
	sort,
	discountsCollection,
	hideContextColumn = false,
	selectable = false,
}: DiscountsTableProps) {
	const org = useCurrentOrganization();

	const emptyState = {
		title: t('No discounts'),
		content: t('Create your first discount'),
		image: (
			<Icon
				name="discount"
				actualSize
				size={4}
				fill={styles.palette.gray[500]}
			/>
		),
		button: (
			<Button
				label={t('Create new')}
				icon="add"
				href={routes.Discounts.Create(org.id)}
				primary
			/>
		),
	};

	const columns = [
		{
			content: t('Title'),
			sortKey: 'title',
		},
		{
			content: t('Discount code'),
		},
		{
			content: 'Type',
		},
		{ content: 'Amount' },
		{ content: 'Status' },
		{ content: 'Current usages', sortKey: 'currentUsage' },
		{ content: 'Usage limit', sortKey: 'usageLimit' },
		{ content: 'Recurring limit', sortKey: 'recurringLimit' },
		{ content: 'Recurring settings' },
		{ content: 'Updated at', sortKey: 'updatedAt' },
		{ content: 'Created at', sortKey: 'createdAt' },
		{ content: '', width: 'max-content', hide: hideContextColumn },
	];

	return (
		<Table.Table
			onSort={sort.setSort}
			sortBy={sort.column}
			sortOrder={sort.order}
			columns={columns}
			emptyState={emptyState}
			isLoading={discountsCollection.isLoading}>
			{discountsCollection.records.map((d) => (
				<DiscountRow
					key={d.id}
					discount={d}
					selectable={selectable}
					discountsCollection={discountsCollection}
				/>
			))}
		</Table.Table>
	);
}
