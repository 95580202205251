import { Fragment } from 'react';
import { t } from '@transifex/native';

import { PageWidths } from 'pkg/config/sizes';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

import FormSubmissionRow from 'routes/organization/contacts/single/SubmissionRow';

import CardAnatomy, * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import Icon from 'components/icon';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

import * as Table from 'design/table';
import Button from 'design/button';

interface ContactSubmissionsProps {
	groupId: number;
	contactId: number;
}

const ContactSubmissions: React.FC<
	React.PropsWithChildren<ContactSubmissionsProps>
> = ({ groupId, contactId }) => {
	const params = {
		submitted_for_user_ids: [contactId].toString(),
		relations: 'Form',
	};
	const { records, isLoading, pagination, removeRecord } =
		useCollection<models.formSubmission.FormSubmission>(
			endpoints.FormSubmissions.Index(),
			{
				showAllResults: true,
				queryParams: new URLSearchParams(params),
			}
		);

	if (isLoading) {
		return <Spinner />;
	}

	const tableColumns: Table.HeaderColumn[] = [
		{ content: t('Title'), align: 'left' },
		{ content: t('Submitted'), align: 'left' },
		{ content: '', width: 'max-content' },
	];

	return (
		<Fragment>
			<LargeScreenContent.Inner maxWidth={PageWidths.WIDE} spacious>
				{records.length > 0 ? (
					<Column>
						<Card.Base $noBorder>
							<Table.Table columns={tableColumns}>
								{records.map((submission) => (
									<FormSubmissionRow
										key={submission.id}
										groupId={groupId}
										submission={submission}
										removeRecord={removeRecord}
									/>
								))}
							</Table.Table>
						</Card.Base>
					</Column>
				) : (
					<CardAnatomy $noBorder>
						<Card.Body>
							<MissingEntities centered>
								<Icon name="file-generic" />
								<p>{t('No submission for the contact found')}</p>
							</MissingEntities>
						</Card.Body>
					</CardAnatomy>
				)}

				{pagination.hasNext && !isLoading && (
					<Button onClick={pagination.fetchNext} transparent>
						{t('Show more')}
					</Button>
				)}
			</LargeScreenContent.Inner>
		</Fragment>
	);
};

export default ContactSubmissions;
